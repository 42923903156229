import mapboxgl from 'mapbox-gl';

class MapManager{
  constructor(){
    if(!localStorage.getItem('map_zoom')){
      localStorage.setItem('map_zoom', JSON.stringify(5));
    }
    
    if(!localStorage.getItem('map_center')){
      localStorage.setItem("map_center", JSON.stringify([0,0]))
    }

    if(!localStorage.getItem('map_pitch')){
      localStorage.setItem('map_pitch', JSON.stringify(0));
    }
    this.map = null
    this.style = {
      "version": 8,
      "name": "Meteorites",
      "metadata": {
        "mapbox:origin": "basic-template-v1",
        "mapbox:autocomposite": true,
        "mapbox:type": "template",
        "mapbox:sdk-support": {
          "js": "0.45.0",
          "android": "6.0.0",
          "ios": "4.0.0"
        }
      },
      "center": [
        74.24426803763072,
        -2.2507114487818853
      ],
      "zoom": 0.6851443156248076,
      "bearing": 0,
      "pitch": 0,
      "sources": {
  
      },
      "sprite": "mapbox://sprites/examples/cjikt35x83t1z2rnxpdmjs7y7",
      "glyphs": "mapbox://fonts/{username}/{fontstack}/{range}.pbf",
      "layers": [],
      "created": "2015-10-30T22:18:31.111Z",
      "id": "cjikt35x83t1z2rnxpdmjs7y7",
      "modified": "2015-10-30T22:22:06.077Z",
      "owner": "examples",
      "visibility": "public",
      "draft": false
    }
  }
  addTerrain(map){
    map.addSource('mapbox-dem', {
      'type': 'raster-dem',
      'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
      'tileSize': 512,
      'maxzoom': 14,
    });
    map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1 });
  }
  addBuildings(map){
    // Insert the layer beneath any symbol layer.
    const layers = map.getStyle().layers;
    const labelLayerId = layers.find(
      (layer) => layer.type === 'symbol' && layer.layout['text-field']
    ).id;
    
    map.addLayer(
    {
      'id': 'add-3d-buildings',
      'source': 'composite',
      'source-layer': 'building',
      'filter': ['==', 'extrude', 'true'],
      'type': 'fill-extrusion',
      'minzoom': 15,
      'paint': {
        'fill-extrusion-color': '#aaa',
        'fill-extrusion-height': [
          'interpolate',
          ['linear'],
          ['zoom'],
          15,
          0,
          15.05,
          ['get', 'height']
        ],
        'fill-extrusion-base': [
          'interpolate',
          ['linear'],
          ['zoom'],
          15,
          0,
          15.05,
          ['get', 'min_height']
        ],
        'fill-extrusion-opacity': 0.6
      }
    }, labelLayerId)
  ;}
  createMap(mapContainer){
    let map = new Map({
      container: mapContainer,
      style: 'mapbox://styles/mapbox/satellite-streets-v11',
      center: [37.6174994, 55.7520233],
      zoom: 18,
      pitch: 0,
      preserveDrawingBuffer: true,
      antialias: true,
      });

    map.on("style.load", () => {
      this.addTerrain(map)
      this.addBuildings(map)
    })
    this.map = map
    return map
  }
  buildStyle(layers, settings){
    
  }
  updateStyle(layers, settings){
    let [style, lazy_layers] = this.buildStyle(layers, settings)
    // console.log(this.map.getStyle())
    // console.log(style)
    this.map.setStyle(style)
    // console.log(this.map.getStyle())
    this.map.once('styledata', () => {
      // console.log(this.map)
      for(let layer_id in lazy_layers){
        // рельеф
        if(layer_id == 6){
          if(lazy_layers[layer_id].layer.active == "active"){
            this.map.setTerrain({ 'source': lazy_layers[layer_id].id, 'exaggeration': 1 });
          }
        }
        // здания
        else if(layer_id == 8){
          const layers = this.map.getStyle().layers;
          const labelLayerId = layers.find(
          (layer) => layer.type === 'symbol' && layer.layout['text-field']
          )
          this.map.addLayer(
            {
            'id': 'add-3d-buildings',
            'source': lazy_layers[layer_id].id,
            'source-layer': 'building',
            'filter': ['==', 'extrude', 'true'],
            'type': 'fill-extrusion',
            'minzoom': lazy_layers[layer_id].layer.minzoom,
            'maxzoom': lazy_layers[layer_id].layer.maxzoom,
            'paint': {
              'fill-extrusion-color': 
              [
                'case',
                ['boolean', ['feature-state', 'select'], false],
                "red",
                ['boolean', ['feature-state', 'hover'], false],
                "lightblue",
                '#aaa'
              ],
              'fill-extrusion-height': [
                'interpolate',
                ['linear'],
                ['zoom'],
                15,
                0,
                15.05,
                ['get', 'height']
              ],
              'fill-extrusion-base': [
                'interpolate',
                ['linear'],
                ['zoom'],
                15,
                0,
                15.05,
                ['get', 'min_height']
              ],
              'fill-extrusion-opacity': 0.8,
            },
            'layout': {
              'visibility': lazy_layers[layer_id].layer.active == "active" ? 'visible' : 'none'
            }
            },
            labelLayerId.id
            )
        }
      }
      });
  }

  // goToPos(target){
  //   let data = new FormData()
  //   data.append("target", target)
  //   axios.post("/api/gotoPos/", data).then(response => {
  //       if (response.data.result == true){
  //         return true
  //       }else{
  //         return false
  //       }
  //       // if (response.data.result == true){
  //       //     this.addTemporaryMarker(response.data.coords)
  //       // }else{
  //       //     this.props.update_modal_message({
  //       //         active: true,
  //       //         heading: "Ошибка названия",
  //       //         message: this.errors[response.data.errorCode]
  //       //     })
  //       // }
  //   })
  // }
}

class Map extends mapboxgl.Map{
  constructor(...args){
    super(...args)
    this.currentEventListener = null
    this.setEventListener = this.setEventListener.bind(this);
    this.unsetEventListener = this.unsetEventListener.bind(this);
  }
  setEventListener(map_, eventListener){
    if(this.currentEventListener != null) this.off('click', this.currentEventListener)
    this.currentEventListener = eventListener
    this.on("click", eventListener)
  }
  unsetEventListener(map_){
    if(this.currentEventListener != null) this.off('click', this.currentEventListener)
    this.currentEventListener = null
  }
}

export default MapManager