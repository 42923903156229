import React from "react";
import emailIcon from "./img/email.png"
import vkIcon from "./img/vk.png"
import addressIcon from "./img/address.png"
import orgIcon from "./img/org.png"
import background from "./img/aboutBackground.png"
import "./About.css"
import poster from "./video/preview.jpg"
import LRVideo from "./video/LR.mp4"
import RCVideo from "./video/RC.mp4"
import MVideo from "./video/monitoring.mp4"

class About extends React.Component{
    constructor(props){
        super(props);
        this.versionTitles = [
            "Текущая версия программы:",
            "Изменения:",
        ]
        this.lastKey = 0
        this.state = {
            versionGit: "v0.0.0",
            messageGit: [],
            video: RCVideo,
            videoID: 0
        }
        this.namesVideo = [
            "Модуль создания полетных заданий.",
            "Модуль мониторинга",
            "Модуль просмотра логов"
        ]
        this.videosPath = [
            RCVideo,
            MVideo,
            LRVideo
        ]
        this.videoHTML = null

        this.changeVideo = this.changeVideo.bind(this)
    }

    componentDidMount(){
        
    }
    changeVideo(e){
        let videoType = e.target.value
        this.setState(state => ({
            video: this.videosPath[videoType],
            videoID: e.target.value
        }))
        console.log(this.videoHTML.currentTime)
        this.videoHTML.load()
    }
    render(){
        return(
            <div className="about--main">
                <img className="background" src={background}/>
                <div className="descriptionSection">
                    <h2 className="about--main_name">Комплекс управления БПЛА "Ласточка"</h2>
                    <span className="about--main_description">
                        Комплекс позволяет создавать гибко настраиваемые полетные задания для БПЛА с открытой архитектурой,
                        производить запуск и мониторинг уже созданных заданий, просматривать результаты выполненных заданий
                    </span>
                </div>
                <div className="instructionSection">
                    <div className="videoSection">
                        <a href="https://www.youtube.com/watch?v=67HsiCnz5G8&list=PLylqFa9vTL96gXmnVz4DGjjm-Z6QKI9IS&ab_channel=mr.GoldenBee">Инструкции по работе с модулями</a>
                        <select className="videoSelect" onChange={this.changeVideo} value={this.state.videoID}>
                            {this.namesVideo.map((item, index) => {
                                return <option key={this.lastKey++} value={index}>{item}</option>
                            })}
                        </select>
                        <video className="video" controls controlsList="nodownload" poster={poster} onLoadedData={(e) => {this.videoHTML = e.target; this.videoHTML.addEventListener("timeupdate", () => {console.log(1)}, false)}} src={this.state.video} type="video/mp4" >
                            <source src={this.state.video} type="video/mp4"/>
                        </video>
                    </div>
                    <div className="contacts">
                        <h3>Возникли вопросы? Свяжитесь с нами!</h3>
                        <ul className="about--contacts_list">
                            <li>
                                <a className="icon" href="mailto:tsl-UAS@yandex.ru"><img src={emailIcon}/></a>
                                <a href="mailto:tsl-UAS@yandex.ru">tsl-UAS@yandex.ru</a>
                            </li>
                            <li>
                                <a className="icon" href="https://vk.com/ssscps"><img src={vkIcon}/></a>
                                <a href="https://vk.com/ssscps">СНО "Безопасность киберфизических систем" ИКТИБ</a>
                            </li>
                            <li>
                                <a className="icon" href="https://vk.com/tsl_uas"><img src={vkIcon}/></a>
                                <a href="https://vk.com/tsl_uas">ЦПЛ «Перспективные беспилотные автоматизированные системы» ИКТИБ</a>
                            </li>
                            <li>
                                <a className="icon" href="https://yandex.ru/maps/971/taganrog/house/ulitsa_chekhova_2/Z0EYfwNjQEQAQFptfX5xcnVnbA==/?ll=38.943917%2C47.203948&z=17.11"><img src={addressIcon}/></a>
                                <a href="https://yandex.ru/maps/971/taganrog/house/ulitsa_chekhova_2/Z0EYfwNjQEQAQFptfX5xcnVnbA==/?ll=38.943917%2C47.203948&z=17.11">улица Чехова, 2, Таганрог, Ростовская область</a>
                            </li>
                        </ul>
                        <div className="about--version">
                            <div className="version">
                                <span>Текущая версия программы:</span>
                                <span className="info">Пробная</span>
                            </div>
                            <div className="changes">
                                <h3 className="title">Преимущества полной версии:</h3>
                                <ul>
                                    <li>Мониторинг выполнения ПЗ</li>
                                    <li>Отправка команд на БПЛА</li>
                                    <li>Сохранение, редактирование и управление ПЗ</li>
                                    <li>Локальное сохранение картографических данных</li>
                                    <li>Возможность настройки отображаемых на карте слоев</li>
                                    <li>Возможность выбора основного картографического источника</li>
                                    <li>Настройка пользовательского интерфейса</li>
                                    <li>Экспорт ПЗ из других систем</li>
                                    <li>Системная помощь при создании ПЗ</li>
                                    <li>Возможность сохранения разметки записанного полета</li>
                                    <li>Разметка древонасаждений в реальном времени</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        )
    }
}

export default About