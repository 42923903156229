import React from "react";
import "./defaultCMP.css";

class DefaultCMP extends React.Component{
    constructor(props){
        super(props)
    }

    render(){
        return(
            <div className="default_CMP">
                <div className="trans-back"></div>
                <div className="window">
                    <h3 className="main-title">Используется пробная web-версия ПО</h3>
                    <span className="descr">Вы используете пробную web-версию ПО - некоторые функции недоступны, пожалуйста, обратитесь к разработчику, чтобы получить полную версию</span>
                    <h3 className="pluses-title">Преимущества полной версии:</h3>
                    <ul className="pluses">
                        <li>Мониторинг выполнения ПЗ</li>
                        <li>Отправка команд на БПЛА</li>
                        <li>Сохранение, редактирование и управление ПЗ</li>
                        <li>Локальное сохранение картографических данных</li>
                        <li>Возможность настройки отображаемых на карте слоев</li>
                        <li>Возможность выбора основного картографического источника</li>
                        <li>Настройка пользовательского интерфейса</li>
                        <li>Экспорт ПЗ из других систем</li>
                        <li>Системная помощь при создании ПЗ</li>
                        <li>Возможность сохранения разметки записанного полета</li>
                        <li>Разметка древонасаждений в реальном времени</li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default DefaultCMP