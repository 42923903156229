import React from "react";
import "./badDevice.css";

class BadDevice extends React.Component{
    render(){
        return(
            <div className="bad-device">
                <div className="trans-back"></div>
                <div className="window">
                    <h3 className="main-title">Используется пробная web-версия ПО</h3>
                    <span className="descr">Ваше устройство не поддерживается ПО, пожалуйста, откройте данную страницу на другом устройстве</span>
                    <h3 className="pluses-title">Преимущества полной версии:</h3>
                    <ul className="pluses">
                        <li>Мониторинг выполнения ПЗ</li>
                        <li>Отправка команд на БПЛА</li>
                        <li>Сохранение, редактирование и управление ПЗ</li>
                        <li>Локальное сохранение картографических данных</li>
                        <li>Возможность настройки отображаемых на карте слоев</li>
                        <li>Возможность выбора основного картографического источника</li>
                        <li>Настройка пользовательского интерфейса</li>
                        <li>Экспорт ПЗ из других систем</li>
                        <li>Системная помощь при создании ПЗ</li>
                        <li>Возможность сохранения разметки записанного полета</li>
                        <li>Разметка древонасаждений в реальном времени</li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default BadDevice