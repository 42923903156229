import React from 'react';
import './LogReader.css';
import axios from 'axios';
import { connect } from 'react-redux';
import logFile from "./imgs/log_file.png"
import { logReaderManager3d } from './logReaderManager3d';
import LogPanel from './logPanel/LogPanel';
import MarkupPanel from './MarkupPanel/MarkupPanel';
import NavigateLog from './navigateLog/NavigateLog';
import {response} from "./log"

import { set_loadedLR, update_modal_message } from '../AppSlice';

class LogReader extends React.Component  {
  constructor(props){
    super(props);
    this.state = {
      is_log_set:false,
      status_message:"",
      precalculation_accept:true,
      data:{},
      currentLog: null,
      current_counter:null,
      route_elements_markup: [],
      log_name:""
    }
    this.currentID = 0;
    this.isAnimateIntervalPlay = false;
    this.interval = 5;
    this.dataLength = 100;
    this.cordArr = [];
    this.logReaderManager3d = new logReaderManager3d(
      this.props.manager3D
    )
    this.props.manager3D.addManager(this.logReaderManager3d, "logreader")
    this.setFile = this.setFile.bind(this);
    this.nextLog = this.nextLog.bind(this);
    this.prevLog = this.prevLog.bind(this);
    this.showLog = this.showLog.bind(this);
    this.intervalOnChange = this.intervalOnChange.bind(this);
    this.playPauseAnimateInterval = this.playPauseAnimateInterval.bind(this);
    this.setCurrentLog = this.setCurrentLog.bind(this);
    this.keywordPressed = this.keywordPressed.bind(this);
    this.deleteTail = this.deleteTail.bind(this)
    this.componentRefresh = this.componentRefresh.bind(this)
  }
  initLogManager(data){
    let logs = data.log;
    this.props.map.once('moveend',()=>{
      this.logReaderManager3d.addModel([
        parseFloat(logs[0].position_lon_deg), 
        parseFloat(logs[0].position_lat_deg),
        parseFloat(logs[0].position_real_alt),
      ], data.route_elements)
      this.dataLength = logs.length;
      for (let log of logs){
        this.cordArr.push([
          parseFloat(log.position_lon_deg), 
          parseFloat(log.position_lat_deg),
          parseFloat(log.position_real_alt)
      ])
      }
    })
    setTimeout(this.showLog, 1000/this.interval);
    this.props.map.flyTo({
      center: [logs[0].position_lon_deg, logs[0].position_lat_deg],
      zoom: 19
    });
  }

  showLog(){
    if (this.isAnimateIntervalPlay){
      this.nextLog()
    }
    setTimeout(this.showLog, 1000/this.interval)
  }
  intervalOnChange(value){
    this.interval = value
  }
  playPauseAnimateInterval(choice){
    this.isAnimateIntervalPlay = choice
  }
  nextLog(){
    this.currentID++;
    let logs = this.state.data.log
    this.currentID = (this.currentID > logs.length-1)?logs.length-1:this.currentID
    this.setCurrentLog(this.currentID)
  }
  prevLog(){
    this.currentID--;
    this.currentID = (this.currentID < 0)?0:this.currentID
    this.setCurrentLog(this.currentID)
  }

  setCurrentLog(counter=null){
    this.currentID = parseInt(counter)
    counter = parseInt(counter)
    if(counter >=0 && counter < this.state.data.log.length){
      let shareCordsArr = this.cordArr.slice(0, counter+1)
    
      let logs = this.state.data.log;
      this.logReaderManager3d.setModelState({
        coords: [logs[counter].position_lon_deg, logs[counter].position_lat_deg, Number(logs[counter].position_real_alt)],
        angles: {
          x: logs[counter].pitch_deg,
          y: logs[counter].roll_deg,
          z: 360-logs[counter].heading_deg
        },
        tailArr: shareCordsArr
      }, true)
      this.setState(state => ({
        currentLog: this.state.data.log[counter],
        current_counter: counter
      }))
    }
  }

  keywordPressed(e){
    if (e.key == "," || e.key == "б"){
      this.prevLog()
    }
    if (e.key == "." || e.key == "ю"){
      this.nextLog()
    }
  }

  setFile(){
    let route_elements_markup = []
    for(let element of response.data.route_elements){
      let z = {...element}
      z.data = JSON.parse(z.data)
      route_elements_markup.push(z)
    }

    this.setState(state => ({
      precalculation_accept: response.data.state_calc_message == "" ? true : false,
      is_log_set: true,
      data: response.data,
      currentLog: response.data.log[0],
      current_counter:0,
      route_elements_markup:route_elements_markup,
      log_name:"test_log"
    }))
    this.initLogManager(response.data)
  }

  deleteTail(e){
    e.preventDefault()
    this.logReaderManager3d.deleteTail()
  }

  componentDidMount(){
    this.setFile()
    document.addEventListener("keydown", this.keywordPressed)
    this.props.map.resize()
  }

  componentWillUnmount(){
    this.props.set_loadedLR(false)
    document.removeEventListener("keydown", this.keywordPressed)

    this.logReaderManager3d.clearTb()
  }
  componentRefresh(){
    this.props.update_modal_message({
      active: true,
      heading: "Пробная версия ПО",
      message: "Данная функция не доступна в данной версии ПО, пожалуйста, обратитесь к разработчику, чтобы получить полную версию ПО"
    })
  }

  render(){
    return (
      <div className={' ' + (this.state.is_log_set == true ? "hide" : "")}>
        {this.state.data.log == null ? "" :
          <NavigateLog
            maxLength={this.dataLength}
            data={this.state.currentLog}
            current_counter={this.state.current_counter}
            nextLog={this.nextLog}
            prevLog={this.prevLog}
            playPause={this.playPauseAnimateInterval}
            intervalOnChange={this.intervalOnChange}
            setCurrentLog={this.setCurrentLog}
            deleteTail={this.deleteTail}
            componentRefresh={this.componentRefresh}
          />
        }
        {this.state.data.log == null ? "" : 
          <LogPanel 
            setFile={this.setFile}
            precalculation_accept={this.state.precalculation_accept}
            maxLength={this.dataLength}
            data={this.state.currentLog} 
            current_counter={this.state.current_counter}
            log_name={this.state.log_name}
          />
        }
        {this.state.data.log == null ? "" : 
          <MarkupPanel 
          log={this.state.data} 
          route_elements_markup={this.state.route_elements_markup}
          log_name={this.state.log_name}
          />
        }
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return state;
}
const mapDispatchToProps =  (dispatch) => {
  return {
    "update_modal_message": (data) => dispatch(update_modal_message(data)),
    "set_loadedLR": (data) => dispatch(set_loadedLR(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LogReader)

