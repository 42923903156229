import React from "react";
import "./App.css";
import Header from "./layouts/Header/Header";
import MapManager from "./MapManager";
import './buttons.css';
import './bootstrap.min.css';
import ModalMessage from "./ModalMessage/ModalMessage";
import { connect } from 'react-redux';
import DefaultCMP from "./DefaultCMP/DefaultCMP";
import RouteConstructor from "./RouteConstructor/RouteConstructor";
import { manager3D } from "./manager3D";
import LogReader from "./LogReader/LogReader"
import About from "./About/About"
import BadDevice from "./BadDevice/BadDevice";

import mapboxgl from 'mapbox-gl';
mapboxgl.accessToken = 'pk.eyJ1IjoiY2FyZGFub2wiLCJhIjoiY2xraW02OWtyMGR1MDNkdDh6em93NDBwcyJ9.n3DKdQ5M_ulcZbaoik-CBg';

let pages_with_map = [0, 3]

let pages = {
  0:{
    cmp: RouteConstructor,
    name:"Создание полетных заданий"
  },
  1:{
    cmp: DefaultCMP,
    name:"Мониторинг"
  },
  2:{
    cmp: DefaultCMP,
    name:"Управление полетными заданиями"
  },
  3:{
    cmp: LogReader,
    name:"Просмотр логов"
  },
  5:{
    cmp: DefaultCMP,
    name:"Настройки"
  },
  6:{
    cmp: About,
    name:"О программе"
  }
}

class App extends React.Component{
  constructor(props){
    super(props)

    this.state = {
      is_interface_ready: false,
      map: null,
      is_device_valid: false
    }

    this.mapManager = null
    this.mapContainer = React.createRef();
    this.manager3D = null
  }

  checkDevice(){
    if (Number(window.screen.width) > 1250){
      return true 
    }else{
      return false
    }
  }

  componentDidMount(){
    if (this.checkDevice() === true){
      this.setState(state => ({
        is_device_valid: true
      }), () => {
        this.mapManager = new MapManager()
        let map = this.mapManager.createMap(this.mapContainer.current)
        map.on("style.load", () => {
          this.setState(state => ({
            map: map,
            is_interface_ready: true
          }))
          
          this.manager3D = new manager3D(map)
          map.moveLayer("3d-threebox-layer", "add-3d-buildings")
        })
      })
    }
  }

  render(){
    let CurrentCmp = pages[this.props.app.active_page_id].cmp
    let rendered_component = <CurrentCmp map={this.state.map} manager3D={this.manager3D}/>
    return(
      <div className="App">
        {this.state.is_device_valid===true?<Header/>:""}
        <main className="container--main d-flex">
          {this.state.is_device_valid===true?<ModalMessage/>:""}
          {this.state.is_device_valid===true?
            <div ref={this.mapContainer} className={pages_with_map.includes(this.props.app.active_page_id) === true ? "container--map" : "container--map hidden"}/>:""
          }
          {this.state.is_device_valid==true?
            this.state.is_interface_ready==true?rendered_component:""
          :<BadDevice/>}
        </main>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return state;
}
const mapDispatchToProps =  (dispatch) => {
  return {
    
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(App)