import React from 'react';
import "./RoutePanel.css"
import { connect } from 'react-redux';
import { toggle_page_block, update_modal_message, change_RC_mission_name } from '../../AppSlice';

class RoutePanel extends React.Component  {
  constructor(props){
    super(props);
    this.mission_name = "mission"
    this.onClickHandler = this.onClickHandler.bind(this);
    this.importOnClickHandler = this.importOnClickHandler.bind(this);
    // this.makePrtScreen = this.makePrtScreen.bind(this);
    this.getMissionData = this.getMissionData.bind(this)
    this.downloadFile = this.downloadFile.bind(this)
  }
  onClickHandler(e){
    e.preventDefault()
  }
  getMissionData(){
    let mission = this.props.sequence.map(item1=>{
      let params = {
        id:item1.params.module.id
      }
      for(let key in item1.params){
        let index = this.props.app.route_parameters_default.findIndex(item=>{
            if(key === item.name) return item
        })
        if(index != -1){
          params[key] = item1.params[key].value
        }
      }
      return params
    })
    let result = {
      route_info:{
        distance:this.props.app.distance,
        time:this.props.app.time
      },
      mission:mission
    }
    return result
  }
  downloadFile(data){
    let json_data = JSON.stringify(data, null, 2)
    let a = document.createElement("a")
    let file = new Blob([json_data], {type:"application/json"})
    a.href = URL.createObjectURL(file)
    a.download = (this.mission_name==""?"mission":this.mission_name)+".json"
    a.click()
  }
  onClickHandler(e) {
    this.props.toggle_page_block({
      'is_page_blocked':false,
      'name':"",
      'description':""
    })
    e.preventDefault()
    if (this.props.route.head.next){
      let mission_data = this.getMissionData()
      this.downloadFile(mission_data)
    }else{
      this.props.update_modal_message({
        active: true,
        heading: 'Пустое полетное задание', 
        message: 'Нельзя сохранить пустое полетное задание'
      })
    }
  }

  importOnClickHandler(e){
    console.log(1)
    this.props.update_modal_message({
      active: true,
      heading: "Пробная версия ПО",
      message: "Данная функция не доступна в данной версии ПО, пожалуйста, обратитесь к разработчику, чтобы получить полную версию ПО"
    })
  }
  onNameChangeHandler(e) {
    e.preventDefault()
    let name = e.target.value
    let spec_symbols = [' ', '/', '\\', ':', '?', '"', '<', '>', '|']
    spec_symbols.map(item => {
      name = name.replaceAll(item, '')
    })
    this.props.change_RC_mission_name(name);
  }
  render(){
    return (
      <div className="column--right flysequence__panel">
          <div className='flysequence--heading__container'>
          <h4 className=''>
            Миссия
          </h4>
            <input 
                  maxLength={30}
                  onChange={(e) => {this.mission_name = e.target.value}}
                  type="text" 
                  placeholder='без имени'
                />
          </div>
          <form method="post" action="" className="mission_form" data-task-id="0">
              <input type="hidden" name={"csrf"} id={"csrf"} defaultValue={this.props.csrf_token}/>
              <ul className="list__task--seguence">
                {this.props.sequence.map((item, index) =>{
                  let Cmp = item.cmp
                  return <li key={index}>{<Cmp params={item.params} tbManager={this.props.tbManager} route={this.props.route}/>}</li>
                })}
              </ul>
              <button onClick={this.onClickHandler} type="submit" className="button button--save-mission" id="button--save-mission">Сохранить</button>
          </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
}
const mapDispatchToProps =  (dispatch) => {
  return {
    'toggle_page_block': (data) => dispatch(toggle_page_block(data)),
    'update_modal_message': (data) => dispatch(update_modal_message(data)),
    'change_RC_mission_name': (data) => dispatch(change_RC_mission_name(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoutePanel)

