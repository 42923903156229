import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const appSlice = createSlice({
    name: 'app',
    initialState: {
      active_page_id:0,
        page_names:[
            {id:0,name:"Создание полетных заданий"},
            {id:1,name:"Мониторинг"},
            {id:2,name:"Управление полетными заданиями"},
            {id:3,name:"Просмотр логов"},
            // {id:4,name:"Разметка изображений"},
            {id:5,name:"Настройки"},
            {id:6,name:"О программе"}
        ],
        header_content:{
            0:[0,1,3],
            1:[0,2,3],
            2:[0],
            3:[3,],
            4:[],
            5:[],
            6:[]
        },
        is_menu_opened:false,

        is_page_blocked:false,
        modal:{
            'name':"",
            'description':""
        },

        modal_message:{
            active:"",
            heading:""
        },
        active_RC_button:null,

        settings:[
            {
                value: "5"
            },
            {
                value: "1"
            },
            {
                value: "0"
            },
            {
                value: "0"
            },
            {
                value: ""
            }
        ],
        route_elements:[
            {
                id: 1,
                label: "Стартовые параметры",
                name: "MissionStart",
                type: 0,
            },
            {
                id: 2,
                label: "Точка взлета",
                name: "Takeoff",
                type: 1,
            },
            {
                id: 3,
                label: "Путевая точка",
                name: "Waypoint",
                type: 1,
            },
            {
                id: 4,
                label: "Облет полигона",
                name: "Polygon",
                type: 2,
            },
            {
                id: 5,
                label: "Возврат на точку взлета",
                name: "RTL",
                type: 3,
            },
            {
                id: 6,
                label: "Точка приземления",
                name: "Land",
                type: 1,
            }
        ],
        route_parameters_default:[
            {
                id: 1,
                max: 100,
                min: 1,
                name: "alt",
                value: "3"
            },
            {
                id: 2,
                max: 359,
                min: -359,
                name: "yaw",
                value: ""
            },
            {
                id: 3,
                max: 1000,
                min: 0,
                name: "hold",
                value: "0"
            },
            {
                id: 4,
                max: 100,
                min: 5,
                name: "speed",
                value: "1"
            },
            {
                id: 5,
                max: 200,
                min: 1,
                name: "line_distance",
                value: "10"
            },
            {
                id: 6,
                max: 200,
                min: 0,
                name: "turnaround_distance",
                value: "0"
            },
            {
                id: 7,
                max: 359,
                min: 0,
                name: "distance_angle",
                value: "0"
            },
            {
                id: 8,
                max: 0,
                min: 0,
                name: "lng",
                value: ""
            },
            {
                id: 9,
                max: 0,
                min: 0,
                name: "lat",
                value: ""
            },
            {
                id: 10,
                max: 0,
                min: 0,
                name: "onture",
                value: ""
            },
            {
                id: 11,
                max: 1,
                min: 0,
                name: "reverse",
                value: "0"
            },
            {
                id: 12,
                max: 359,
                min: -359,
                name: "angle",
                value: "0"
            },
            {
                id: 13,
                max: 0,
                min: 0,
                name: "markup",
                value: ""
            },
        ],
        // wp_azimuth:0,
        // wp_distance:0,
        // distance:0,
        // time:0,
        
        current_azimuth:0,

        RC_mission_id: null,
        RC_mission_name: "",

        alt_route:[{x:1,y:1}],
    },
    reducers: {
        change_active_RC_button: (state, action) => {
            state.active_RC_button = action.payload
        },
        update_modal_message: (state, action) => {
            state.modal_message = {
                ...(state.modal_message),
                active:action.payload.active,
                heading:action.payload.heading,
                message:action.payload.message,
                aftermath:action.payload.aftermath?action.payload.aftermath:"",
                actions:action.payload.actions?action.payload.actions:false,
                dataForUpload:action.payload.dataForUpload?action.payload.dataForUpload:""
            }
        },
        set_info_mission: (state, action) => {
            state.wp_azimuth = action.payload.current_azimuth
            state.wp_distance = action.payload.current_distance

            state.distance = action.payload.all_distance
            state.time = action.payload.all_time
        },
        get_route_elements: (state, action) => {
            state.route_elements = action.payload
        },
        get_route_parameters_default: (state, action) => {
            state.route_parameters_default = action.payload
        },
        change_page: (state, action) => {
            localStorage.setItem('active_page_id', JSON.stringify(action.payload));
            state.active_page_id = action.payload
            localStorage.setItem('active_page_id', JSON.stringify({
              active_page_id:action.payload
            }));
        },
        get_settings: (state, action) => {
            state.settings = action.payload
        },
        get_group_of_settings: (state, action) => {
            state.group_of_settings = action.payload
        },
        toggle_menu: (state, action) => {
            state.is_menu_opened = action.payload
        },
        toggle_page_block: (state, action) => {
            state.is_page_blocked = action.payload.is_page_blocked
            state.modal = {
                'name':action.payload.name,
                'description':action.payload.description
            }
        },
        update_setting:  (state, action) => {
            let settings = state.settings
            let index = state.settings.findIndex(i=>{if(i.id == action.payload.index) return i})
            let obj1 = state.settings[index]
            let obj = {...obj1, value:action.payload.value}
            settings[index] = obj

            state = {...state, settings:settings}
        },
        change_RC_mission_id: (state, action) => {
            state.RC_mission_id = action.payload
        },
        change_RC_mission_name: (state, action) => {
            state.RC_mission_name = action.payload
        },
        set_currentIDConstr: (state, action) => {
            state.currentIDConstr = action.payload
        },
        set_loadedLR: (state, action) => {
          state.loadedLR = action.payload
        }
    }
  })
  
  // Action creators are generated for each case reducer function
  export const { change_page, get_settings, get_group_of_settings, toggle_menu, update_setting,
    toggle_page_block, get_route_elements, get_route_parameters_default, set_info_mission, update_modal_message,
    change_active_RC_button, change_RC_mission_id, set_AMS_alt_route, set_layers, set_layer, set_uavs, update_uav,
    update_telemetry, set_current_command, set_monitoring_info, set_mission_completing, set_currentIDConstr, set_loadedLR,
    change_RC_mission_name, clear_telemetry } = appSlice.actions
  
  export default appSlice.reducer