export let response = {
  "data": {
    "state_calc_message": "Вы пытаетесь просмотреть неразмеченный лог старой версии. Для расчета состояний Машиной состояний необходимо разметить его вручную, воспользовавшись панелью разметки.",
    "log": [
      {
        "id": 0,
        "datetime": "21/02/2023 02:03:33",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa5a5ee50>, <mavsdk.mission_raw.MissionItem object at 0xa5910da8>, <mavsdk.mission_raw.MissionItem object at 0xa5910d60>, <mavsdk.mission_raw.MissionItem object at 0xa5910df0>]",
        "mission_pause": 0,
        "time_usec": 58707545,
        "odometry_pos_body": "PositionBody: [x_m: -0.2623150646686554, y_m: -0.743628203868866, z_m: 0.44802042841911316]",
        "Command": "None",
        "navigation_system": "GPS",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 0,
        "alg_manual_gps_lon_start": 0,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 0,
        "alg_manual_gps_lon_next": 0,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 0,
        "alg_manual_y_now": 0,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 58732522,
        "flight_mode": "HOLD",
        "landed_state": "ON_GROUND",
        "in_air": 0,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0,
        "current_climb_rate": -0.010231330059468746,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3064623,
        "gps_raw_lon_deg": 38.8900151,
        "gps_raw_abs_alt": 76.82000732421875,
        "gps_raw_velocity": 0.029999999329447743,
        "position_lat_deg": 47.306462,
        "position_lon_deg": 38.8900158,
        "position_abs_alt": 78.73800659179688,
        "position_real_alt": 0.01600000075995922,
        "heading_deg": 351.76,
        "odometry_pos_body_x": -0.26186197996139526,
        "odometry_pos_body_y": -0.7467585802078247,
        "odometry_pos_body_z": 0.4446080923080444,
        "odometry_vel_body_x": 0.009232996962964536,
        "odometry_vel_body_y": 0.04445573687553406,
        "odometry_vel_body_z": 0.015381106175482271,
        "odometry_ang_vel_body_roll": -0.10298310220241548,
        "odometry_ang_vel_body_pitch": -0.008302693255245686,
        "odometry_ang_vel_body_yaw": -0.004459562711417675,
        "velocity_north_m_s": 0.009999999776482582,
        "velocity_east_m_s": 0.03999999910593033,
        "velocity_down_m_s": 0.009999999776482582,
        "acceleration_forward": -0.1446535736322403,
        "acceleration_right": -0.24593091011047363,
        "acceleration_down": -11.494792938232422,
        "battery_id": 0,
        "battery_voltage": 14.433000564575195,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 0,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "INFO",
        "status_text": "Takeoff to 3.0 meters above home.",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 1919,
        "udp_total": 50,
        "arp_total": 2,
        "icmp_total": 1,
        "other_total": 37
      },
      {
        "id": 1,
        "datetime": "21/02/2023 02:03:33",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa591ee50>, <mavsdk.mission_raw.MissionItem object at 0xa591eb08>, <mavsdk.mission_raw.MissionItem object at 0xa5913328>, <mavsdk.mission_raw.MissionItem object at 0xa5910148>]",
        "mission_pause": 0,
        "time_usec": 58937322,
        "odometry_pos_body": "PositionBody: [x_m: -0.2608562111854553, y_m: -0.7484177947044373, z_m: 0.43844613432884216]",
        "Command": "None",
        "navigation_system": "GPS",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 0,
        "alg_manual_gps_lon_start": 0,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 0,
        "alg_manual_gps_lon_next": 0,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 0,
        "alg_manual_y_now": 0,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 59037228,
        "flight_mode": "HOLD",
        "landed_state": "ON_GROUND",
        "in_air": 0,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5899999737739563,
        "current_climb_rate": -0.0006836370448581874,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3064623,
        "gps_raw_lon_deg": 38.8900152,
        "gps_raw_abs_alt": 76.81900024414062,
        "gps_raw_velocity": 0.029999999329447743,
        "position_lat_deg": 47.306462,
        "position_lon_deg": 38.8900157,
        "position_abs_alt": 78.75200653076172,
        "position_real_alt": 0.006000000052154064,
        "heading_deg": 351.58,
        "odometry_pos_body_x": -0.25941628217697144,
        "odometry_pos_body_y": -0.7477338910102844,
        "odometry_pos_body_z": 0.43062084913253784,
        "odometry_vel_body_x": 0.004066453780978918,
        "odometry_vel_body_y": 0.05178940296173096,
        "odometry_vel_body_z": 0.0010874608997255564,
        "odometry_ang_vel_body_roll": -0.1223217025399208,
        "odometry_ang_vel_body_pitch": -0.31379175186157227,
        "odometry_ang_vel_body_yaw": 0.05853632837533951,
        "velocity_north_m_s": 0.009999999776482582,
        "velocity_east_m_s": 0.03999999910593033,
        "velocity_down_m_s": 0,
        "acceleration_forward": 0.09304510802030563,
        "acceleration_right": -1.010926365852356,
        "acceleration_down": -9.31171417236328,
        "battery_id": 0,
        "battery_voltage": 14.301000595092772,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 0,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "INFO",
        "status_text": "Takeoff detected",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 1941,
        "udp_total": 50,
        "arp_total": 2,
        "icmp_total": 1,
        "other_total": 38
      },
      {
        "id": 2,
        "datetime": "21/02/2023 02:03:33",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa58fc670>, <mavsdk.mission_raw.MissionItem object at 0xa58fce68>, <mavsdk.mission_raw.MissionItem object at 0xa58fc388>, <mavsdk.mission_raw.MissionItem object at 0xa58fcac0>]",
        "mission_pause": 0,
        "time_usec": 59237031,
        "odometry_pos_body": "PositionBody: [x_m: -0.2619525194168091, y_m: -0.7440425157546997, z_m: 0.42029133439064026]",
        "Command": "None",
        "navigation_system": "GPS",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 0,
        "alg_manual_gps_lon_start": 0,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 0,
        "alg_manual_gps_lon_next": 0,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 0,
        "alg_manual_y_now": 0,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 59271997,
        "flight_mode": "MISSION",
        "landed_state": "ON_GROUND",
        "in_air": 0,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.04999999701976776,
        "current_climb_rate": -0.004760322626680136,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3064623,
        "gps_raw_lon_deg": 38.8900153,
        "gps_raw_abs_alt": 76.82500457763672,
        "gps_raw_velocity": 0,
        "position_lat_deg": 47.306462,
        "position_lon_deg": 38.8900158,
        "position_abs_alt": 78.76600646972656,
        "position_real_alt": 0.020000001415610313,
        "heading_deg": 351.42,
        "odometry_pos_body_x": -0.262867271900177,
        "odometry_pos_body_y": -0.7417933344841003,
        "odometry_pos_body_z": 0.4159059524536133,
        "odometry_vel_body_x": -0.0025069331750273705,
        "odometry_vel_body_y": 0.0442284420132637,
        "odometry_vel_body_z": 0.003630842547863722,
        "odometry_ang_vel_body_roll": 0.07562122493982315,
        "odometry_ang_vel_body_pitch": -0.03111365996301174,
        "odometry_ang_vel_body_yaw": -0.031226906925439835,
        "velocity_north_m_s": 0,
        "velocity_east_m_s": 0.03999999910593033,
        "velocity_down_m_s": 0,
        "acceleration_forward": -0.3367486000061035,
        "acceleration_right": -0.2365243285894394,
        "acceleration_down": -11.060389518737791,
        "battery_id": 0,
        "battery_voltage": 14.301000595092772,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 0,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 26,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 1965,
        "udp_total": 51,
        "arp_total": 2,
        "icmp_total": 1,
        "other_total": 38
      },
      {
        "id": 3,
        "datetime": "21/02/2023 02:03:34",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa591e958>, <mavsdk.mission_raw.MissionItem object at 0xa591e7a8>, <mavsdk.mission_raw.MissionItem object at 0xa591eb68>, <mavsdk.mission_raw.MissionItem object at 0xa591edc0>]",
        "mission_pause": 0,
        "time_usec": 59511792,
        "odometry_pos_body": "PositionBody: [x_m: -0.26382288336753845, y_m: -0.7390606999397278, z_m: 0.40230631828308105]",
        "Command": "None",
        "navigation_system": "GPS",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 0,
        "alg_manual_gps_lon_start": 0,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 0,
        "alg_manual_gps_lon_next": 0,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 0,
        "alg_manual_y_now": 0,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 59556737,
        "flight_mode": "MISSION",
        "landed_state": "ON_GROUND",
        "in_air": 0,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.08999999612569809,
        "current_climb_rate": 0.0006042954046279192,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3064623,
        "gps_raw_lon_deg": 38.8900152,
        "gps_raw_abs_alt": 76.81100463867188,
        "gps_raw_velocity": 0,
        "position_lat_deg": 47.306462,
        "position_lon_deg": 38.8900158,
        "position_abs_alt": 78.78400421142578,
        "position_real_alt": 0.03800000250339508,
        "heading_deg": 351.36,
        "odometry_pos_body_x": -0.26403573155403137,
        "odometry_pos_body_y": -0.7395356297492981,
        "odometry_pos_body_z": 0.3985850214958191,
        "odometry_vel_body_x": -0.005629073828458786,
        "odometry_vel_body_y": 0.04270995408296585,
        "odometry_vel_body_z": -0.0026096769142895937,
        "odometry_ang_vel_body_roll": 0.2522434592247009,
        "odometry_ang_vel_body_pitch": -0.09141836315393448,
        "odometry_ang_vel_body_yaw": -0.02303103543817997,
        "velocity_north_m_s": 0,
        "velocity_east_m_s": 0.03999999910593033,
        "velocity_down_m_s": 0,
        "acceleration_forward": -1.457269549369812,
        "acceleration_right": -0.4377495348453522,
        "acceleration_down": -11.879348754882812,
        "battery_id": 0,
        "battery_voltage": 14.301000595092772,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 0,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 0,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 1965,
        "udp_total": 51,
        "arp_total": 2,
        "icmp_total": 1,
        "other_total": 38
      },
      {
        "id": 4,
        "datetime": "21/02/2023 02:03:34",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa5913df0>, <mavsdk.mission_raw.MissionItem object at 0xa5913988>, <mavsdk.mission_raw.MissionItem object at 0xa59135c8>, <mavsdk.mission_raw.MissionItem object at 0xa5913850>]",
        "mission_pause": 0,
        "time_usec": 59671610,
        "odometry_pos_body": "PositionBody: [x_m: -0.2641541361808777, y_m: -0.7389546036720276, z_m: 0.39332181215286255]",
        "Command": "None",
        "navigation_system": "GPS",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 0,
        "alg_manual_gps_lon_start": 0,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 0,
        "alg_manual_gps_lon_next": 0,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 0,
        "alg_manual_y_now": 0,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 59756527,
        "flight_mode": "MISSION",
        "landed_state": "ON_GROUND",
        "in_air": 0,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.1599999964237213,
        "current_climb_rate": 0.003181841690093279,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3064624,
        "gps_raw_lon_deg": 38.8900151,
        "gps_raw_abs_alt": 76.80900573730469,
        "gps_raw_velocity": 0.019999999552965164,
        "position_lat_deg": 47.306462,
        "position_lon_deg": 38.8900158,
        "position_abs_alt": 78.80400085449219,
        "position_real_alt": 0.058000002056360245,
        "heading_deg": 351.34000000000003,
        "odometry_pos_body_x": -0.2628522515296936,
        "odometry_pos_body_y": -0.7428979277610779,
        "odometry_pos_body_z": 0.37729284167289734,
        "odometry_vel_body_x": -0.01190551184117794,
        "odometry_vel_body_y": 0.03578217327594757,
        "odometry_vel_body_z": -0.0002717094903346151,
        "odometry_ang_vel_body_roll": -0.1317117065191269,
        "odometry_ang_vel_body_pitch": 0.2822374403476715,
        "odometry_ang_vel_body_yaw": 0.005796487908810377,
        "velocity_north_m_s": 0,
        "velocity_east_m_s": 0.029999999329447743,
        "velocity_down_m_s": -0.009999999776482582,
        "acceleration_forward": 0.42895764112472534,
        "acceleration_right": -1.3735778331756592,
        "acceleration_down": -7.802639961242676,
        "battery_id": 0,
        "battery_voltage": 14.301000595092772,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 0,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 22,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 1987,
        "udp_total": 51,
        "arp_total": 2,
        "icmp_total": 1,
        "other_total": 38
      },
      {
        "id": 5,
        "datetime": "21/02/2023 02:03:34",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xb2018280>, <mavsdk.mission_raw.MissionItem object at 0xb2018028>, <mavsdk.mission_raw.MissionItem object at 0xb20180b8>, <mavsdk.mission_raw.MissionItem object at 0xb2018520>]",
        "mission_pause": 0,
        "time_usec": 59971318,
        "odometry_pos_body": "PositionBody: [x_m: -0.2607298195362091, y_m: -0.7460601329803467, z_m: 0.3633723258972168]",
        "Command": "None",
        "navigation_system": "GPS",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 0,
        "alg_manual_gps_lon_start": 0,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 0,
        "alg_manual_gps_lon_next": 0,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 0,
        "alg_manual_y_now": 0,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 60016275,
        "flight_mode": "MISSION",
        "landed_state": "TAKING_OFF",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.2199999988079071,
        "current_climb_rate": 0.010427040979266168,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3064624,
        "gps_raw_lon_deg": 38.8900151,
        "gps_raw_abs_alt": 76.8070068359375,
        "gps_raw_velocity": 0.009999999776482582,
        "position_lat_deg": 47.306462,
        "position_lon_deg": 38.8900157,
        "position_abs_alt": 78.83300018310547,
        "position_real_alt": 0.0860000029206276,
        "heading_deg": 351.22,
        "odometry_pos_body_x": -0.25788694620132446,
        "odometry_pos_body_y": -0.7490684986114502,
        "odometry_pos_body_z": 0.34753915667533875,
        "odometry_vel_body_x": 0.0035419927444308996,
        "odometry_vel_body_y": 0.025081725791096687,
        "odometry_vel_body_z": 0.009744630195200443,
        "odometry_ang_vel_body_roll": 0.6503670811653137,
        "odometry_ang_vel_body_pitch": -0.0868343785405159,
        "odometry_ang_vel_body_yaw": -0.01928350515663624,
        "velocity_north_m_s": 0.009999999776482582,
        "velocity_east_m_s": 0.019999999552965164,
        "velocity_down_m_s": -0.019999999552965164,
        "acceleration_forward": 0.3062743544578552,
        "acceleration_right": 0.5756444931030273,
        "acceleration_down": -3.240777015686035,
        "battery_id": 0,
        "battery_voltage": 14.301000595092772,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 0,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 2011,
        "udp_total": 51,
        "arp_total": 2,
        "icmp_total": 1,
        "other_total": 38
      },
      {
        "id": 6,
        "datetime": "21/02/2023 02:03:34",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa58f2538>, <mavsdk.mission_raw.MissionItem object at 0xa58f2b68>, <mavsdk.mission_raw.MissionItem object at 0xa58f2fd0>, <mavsdk.mission_raw.MissionItem object at 0xa5913580>]",
        "mission_pause": 0,
        "time_usec": 60241058,
        "odometry_pos_body": "PositionBody: [x_m: -0.25543585419654846, y_m: -0.7524278163909912, z_m: 0.336928129196167]",
        "Command": "None",
        "navigation_system": "GPS",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 0,
        "alg_manual_gps_lon_start": 0,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 0,
        "alg_manual_gps_lon_next": 0,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 0,
        "alg_manual_y_now": 0,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 60276023,
        "flight_mode": "MISSION",
        "landed_state": "TAKING_OFF",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.3199999928474426,
        "current_climb_rate": -0.014737951569259168,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306462499999995,
        "gps_raw_lon_deg": 38.890015,
        "gps_raw_abs_alt": 76.79200744628906,
        "gps_raw_velocity": 0.019999999552965164,
        "position_lat_deg": 47.3064621,
        "position_lon_deg": 38.8900157,
        "position_abs_alt": 78.85000610351562,
        "position_real_alt": 0.1040000021457672,
        "heading_deg": 351.07,
        "odometry_pos_body_x": -0.25405848026275635,
        "odometry_pos_body_y": -0.7539893984794617,
        "odometry_pos_body_z": 0.33073142170906067,
        "odometry_vel_body_x": 0.004914372693747282,
        "odometry_vel_body_y": 0.017627619206905365,
        "odometry_vel_body_z": -0.030872533097863197,
        "odometry_ang_vel_body_roll": -0.4470247030258178,
        "odometry_ang_vel_body_pitch": 0.314596563577652,
        "odometry_ang_vel_body_yaw": 0.04538872465491295,
        "velocity_north_m_s": 0,
        "velocity_east_m_s": 0.009999999776482582,
        "velocity_down_m_s": -0.029999999329447743,
        "acceleration_forward": -1.2448396682739258,
        "acceleration_right": 1.327690362930298,
        "acceleration_down": -19.84566879272461,
        "battery_id": 0,
        "battery_voltage": 14.301000595092772,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 0,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 2011,
        "udp_total": 52,
        "arp_total": 2,
        "icmp_total": 1,
        "other_total": 39
      },
      {
        "id": 7,
        "datetime": "21/02/2023 02:03:35",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa58f2250>, <mavsdk.mission_raw.MissionItem object at 0xb2018460>, <mavsdk.mission_raw.MissionItem object at 0xb0ec2778>, <mavsdk.mission_raw.MissionItem object at 0xa4772868>]",
        "mission_pause": 0,
        "time_usec": 60410900,
        "odometry_pos_body": "PositionBody: [x_m: -0.25210869312286377, y_m: -0.7565357089042664, z_m: 0.325590044260025]",
        "Command": "None",
        "navigation_system": "GPS",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 0,
        "alg_manual_gps_lon_start": 0,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 0,
        "alg_manual_gps_lon_next": 0,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 0,
        "alg_manual_y_now": 0,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 60455849,
        "flight_mode": "MISSION",
        "landed_state": "TAKING_OFF",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.38999998569488525,
        "current_climb_rate": 0.0419740229845047,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306462499999995,
        "gps_raw_lon_deg": 38.8900149,
        "gps_raw_abs_alt": 76.78600311279297,
        "gps_raw_velocity": 0,
        "position_lat_deg": 47.3064621,
        "position_lon_deg": 38.8900155,
        "position_abs_alt": 78.86900329589844,
        "position_real_alt": 0.12300000339746477,
        "heading_deg": 350.73,
        "odometry_pos_body_x": -0.2469756156206131,
        "odometry_pos_body_y": -0.7638615965843201,
        "odometry_pos_body_z": 0.3119541108608246,
        "odometry_vel_body_x": 0.010980738326907158,
        "odometry_vel_body_y": -0.012608300894498823,
        "odometry_vel_body_z": -0.01580679789185524,
        "odometry_ang_vel_body_roll": 0.6691018342971802,
        "odometry_ang_vel_body_pitch": 0.4128682613372803,
        "odometry_ang_vel_body_yaw": -0.0030429803300648928,
        "velocity_north_m_s": 0,
        "velocity_east_m_s": 0,
        "velocity_down_m_s": -0.03999999910593033,
        "acceleration_forward": 0.9015947580337524,
        "acceleration_right": -1.2000410556793213,
        "acceleration_down": -4.6738739013671875,
        "battery_id": 0,
        "battery_voltage": 14.301000595092772,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 0,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 2033,
        "udp_total": 52,
        "arp_total": 2,
        "icmp_total": 1,
        "other_total": 39
      },
      {
        "id": 8,
        "datetime": "21/02/2023 02:03:35",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa591e3d0>, <mavsdk.mission_raw.MissionItem object at 0xa591e9e8>, <mavsdk.mission_raw.MissionItem object at 0xa591e340>, <mavsdk.mission_raw.MissionItem object at 0xa591eb80>]",
        "mission_pause": 0,
        "time_usec": 60670642,
        "odometry_pos_body": "PositionBody: [x_m: -0.24592748284339905, y_m: -0.7666714191436768, z_m: 0.3025951385498047]",
        "Command": "None",
        "navigation_system": "GPS",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 0,
        "alg_manual_gps_lon_start": 0,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 0,
        "alg_manual_gps_lon_next": 0,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 0,
        "alg_manual_y_now": 0,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 60710604,
        "flight_mode": "MISSION",
        "landed_state": "TAKING_OFF",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.4699999988079071,
        "current_climb_rate": 0.13427650928497314,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306462499999995,
        "gps_raw_lon_deg": 38.8900149,
        "gps_raw_abs_alt": 76.7760009765625,
        "gps_raw_velocity": 0,
        "position_lat_deg": 47.3064621,
        "position_lon_deg": 38.8900154,
        "position_abs_alt": 78.89500427246094,
        "position_real_alt": 0.14900000393390656,
        "heading_deg": 349.8,
        "odometry_pos_body_x": -0.24616782367229464,
        "odometry_pos_body_y": -0.7698829770088196,
        "odometry_pos_body_z": 0.28241974115371704,
        "odometry_vel_body_x": -0.0300812516361475,
        "odometry_vel_body_y": 0.01928747072815895,
        "odometry_vel_body_z": -0.16333690285682678,
        "odometry_ang_vel_body_roll": 0.801373302936554,
        "odometry_ang_vel_body_pitch": 0.3974861800670624,
        "odometry_ang_vel_body_yaw": 0.0028170160949230194,
        "velocity_north_m_s": -0.019999999552965164,
        "velocity_east_m_s": 0.019999999552965164,
        "velocity_down_m_s": -0.1599999964237213,
        "acceleration_forward": -0.6446852684020996,
        "acceleration_right": -2.3476908206939697,
        "acceleration_down": -3.667109727859497,
        "battery_id": 0,
        "battery_voltage": 14.301000595092772,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 0,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 0,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 2057,
        "udp_total": 52,
        "arp_total": 2,
        "icmp_total": 1,
        "other_total": 39
      },
      {
        "id": 9,
        "datetime": "21/02/2023 02:03:35",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa59109a0>, <mavsdk.mission_raw.MissionItem object at 0xa5910e98>, <mavsdk.mission_raw.MissionItem object at 0xa5910430>, <mavsdk.mission_raw.MissionItem object at 0xa5910af0>]",
        "mission_pause": 0,
        "time_usec": 60940381,
        "odometry_pos_body": "PositionBody: [x_m: -0.25080734491348267, y_m: -0.7695266008377075, z_m: 0.2310323864221573]",
        "Command": "None",
        "navigation_system": "GPS",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 0,
        "alg_manual_gps_lon_start": 0,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 0,
        "alg_manual_gps_lon_next": 0,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 0,
        "alg_manual_y_now": 0,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 60990333,
        "flight_mode": "MISSION",
        "landed_state": "TAKING_OFF",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.47999998927116394,
        "current_climb_rate": 0.28636524081230164,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306462499999995,
        "gps_raw_lon_deg": 38.8900148,
        "gps_raw_abs_alt": 76.7800064086914,
        "gps_raw_velocity": 0.029999999329447743,
        "position_lat_deg": 47.3064621,
        "position_lon_deg": 38.8900155,
        "position_abs_alt": 78.96800231933594,
        "position_real_alt": 0.22200001776218417,
        "heading_deg": 348.74,
        "odometry_pos_body_x": -0.2528285086154938,
        "odometry_pos_body_y": -0.7687332630157471,
        "odometry_pos_body_z": 0.2144152671098709,
        "odometry_vel_body_x": -0.06845586001873016,
        "odometry_vel_body_y": 0.024551350623369217,
        "odometry_vel_body_z": -0.2849118113517761,
        "odometry_ang_vel_body_roll": -0.30663588643074036,
        "odometry_ang_vel_body_pitch": -0.2742352783679962,
        "odometry_ang_vel_body_yaw": -0.06853458285331726,
        "velocity_north_m_s": -0.05999999865889549,
        "velocity_east_m_s": 0.04999999701976776,
        "velocity_down_m_s": -0.28999999165534973,
        "acceleration_forward": -1.1839059591293335,
        "acceleration_right": 1.928216814994812,
        "acceleration_down": -11.459675788879396,
        "battery_id": 0,
        "battery_voltage": 14.301000595092772,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 0,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 21,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 2080,
        "udp_total": 53,
        "arp_total": 2,
        "icmp_total": 1,
        "other_total": 39
      },
      {
        "id": 10,
        "datetime": "21/02/2023 02:03:35",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa590c778>, <mavsdk.mission_raw.MissionItem object at 0xa590cbc8>, <mavsdk.mission_raw.MissionItem object at 0xa590c310>, <mavsdk.mission_raw.MissionItem object at 0xa590cad8>]",
        "mission_pause": 0,
        "time_usec": 61210121,
        "odometry_pos_body": "PositionBody: [x_m: -0.2679944932460785, y_m: -0.7555080652236938, z_m: 0.12112753093242645]",
        "Command": "None",
        "navigation_system": "GPS",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 0,
        "alg_manual_gps_lon_start": 0,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 0,
        "alg_manual_gps_lon_next": 0,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 0,
        "alg_manual_y_now": 0,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 61230101,
        "flight_mode": "MISSION",
        "landed_state": "TAKING_OFF",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5099999904632568,
        "current_climb_rate": 0.4016693532466888,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3064623,
        "gps_raw_lon_deg": 38.890015,
        "gps_raw_abs_alt": 76.81400299072266,
        "gps_raw_velocity": 0.09999999403953552,
        "position_lat_deg": 47.306461899999995,
        "position_lon_deg": 38.8900157,
        "position_abs_alt": 79.09400177001953,
        "position_real_alt": 0.34700000286102295,
        "heading_deg": 347.62,
        "odometry_pos_body_x": -0.272823303937912,
        "odometry_pos_body_y": -0.7481063008308411,
        "odometry_pos_body_z": 0.08858490735292435,
        "odometry_vel_body_x": -0.11990272998809814,
        "odometry_vel_body_y": 0.11269828677177428,
        "odometry_vel_body_z": -0.47467565536499023,
        "odometry_ang_vel_body_roll": -0.05393675714731216,
        "odometry_ang_vel_body_pitch": -0.26258566975593567,
        "odometry_ang_vel_body_yaw": -0.07506512850522995,
        "velocity_north_m_s": -0.05999999865889549,
        "velocity_east_m_s": 0.14999999105930328,
        "velocity_down_m_s": -0.4899999797344208,
        "acceleration_forward": -1.5106500387191772,
        "acceleration_right": -1.9124820232391355,
        "acceleration_down": -10.396624565124512,
        "battery_id": 0,
        "battery_voltage": 13.908000946044922,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 0,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 1,
        "arp": 2,
        "icmp": 0,
        "other": 1,
        "tcp_total": 2080,
        "udp_total": 54,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 40
      },
      {
        "id": 11,
        "datetime": "21/02/2023 02:03:36",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa591e490>, <mavsdk.mission_raw.MissionItem object at 0xa591e820>, <mavsdk.mission_raw.MissionItem object at 0xa591ea30>, <mavsdk.mission_raw.MissionItem object at 0xa591e1f0>]",
        "mission_pause": 0,
        "time_usec": 61409928,
        "odometry_pos_body": "PositionBody: [x_m: -0.2804308533668518, y_m: -0.7244229912757874, z_m: -0.013947591185569763]",
        "Command": "None",
        "navigation_system": "GPS",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 0,
        "alg_manual_gps_lon_start": 0,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 0,
        "alg_manual_gps_lon_next": 0,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 0,
        "alg_manual_y_now": 0,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 61514826,
        "flight_mode": "MISSION",
        "landed_state": "TAKING_OFF",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5299999713897705,
        "current_climb_rate": 0.6509107947349548,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3064621,
        "gps_raw_lon_deg": 38.8900157,
        "gps_raw_abs_alt": 76.98600006103516,
        "gps_raw_velocity": 0.19999998807907104,
        "position_lat_deg": 47.306461899999995,
        "position_lon_deg": 38.8900164,
        "position_abs_alt": 79.29900360107422,
        "position_real_alt": 0.5670000314712524,
        "heading_deg": 347.38,
        "odometry_pos_body_x": -0.2766270041465759,
        "odometry_pos_body_y": -0.6907447576522827,
        "odometry_pos_body_z": -0.13106216490268707,
        "odometry_vel_body_x": 0.00804167054593563,
        "odometry_vel_body_y": 0.25448042154312134,
        "odometry_vel_body_z": -0.7667624354362488,
        "odometry_ang_vel_body_roll": -0.25831589102745056,
        "odometry_ang_vel_body_pitch": -0.014205098152160645,
        "odometry_ang_vel_body_yaw": 0.06031874567270279,
        "velocity_north_m_s": 0.08999999612569809,
        "velocity_east_m_s": 0.2199999988079071,
        "velocity_down_m_s": -0.7999999523162842,
        "acceleration_forward": 0.845828115940094,
        "acceleration_right": 1.3611674308776855,
        "acceleration_down": -8.677616119384766,
        "battery_id": 0,
        "battery_voltage": 13.908000946044922,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 0,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 26,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 2108,
        "udp_total": 54,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 40
      },
      {
        "id": 12,
        "datetime": "21/02/2023 02:03:36",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa591e940>, <mavsdk.mission_raw.MissionItem object at 0xa591e610>, <mavsdk.mission_raw.MissionItem object at 0xa591e850>, <mavsdk.mission_raw.MissionItem object at 0xa591ee68>]",
        "mission_pause": 0,
        "time_usec": 61869484,
        "odometry_pos_body": "PositionBody: [x_m: -0.2644656300544739, y_m: -0.6321043372154236, z_m: -0.3475606143474579]",
        "Command": "None",
        "navigation_system": "GPS",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 0,
        "alg_manual_gps_lon_start": 0,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 0,
        "alg_manual_gps_lon_next": 0,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 0,
        "alg_manual_y_now": 0,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 61934419,
        "flight_mode": "MISSION",
        "landed_state": "TAKING_OFF",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.550000011920929,
        "current_climb_rate": 1.0336294174194336,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3064621,
        "gps_raw_lon_deg": 38.890017,
        "gps_raw_abs_alt": 77.26500701904297,
        "gps_raw_velocity": 0.2299999892711639,
        "position_lat_deg": 47.306462,
        "position_lon_deg": 38.8900176,
        "position_abs_alt": 79.66800689697266,
        "position_real_alt": 0.9420000314712524,
        "heading_deg": 349.38,
        "odometry_pos_body_x": -0.25905582308769226,
        "odometry_pos_body_y": -0.6042904257774353,
        "odometry_pos_body_z": -0.4853738248348236,
        "odometry_vel_body_x": 0.06406799703836441,
        "odometry_vel_body_y": 0.2431609183549881,
        "odometry_vel_body_z": -1.036284565925598,
        "odometry_ang_vel_body_roll": -0.5949726700782776,
        "odometry_ang_vel_body_pitch": 0.07804122567176819,
        "odometry_ang_vel_body_yaw": 0.1597582846879959,
        "velocity_north_m_s": 0.07999999821186066,
        "velocity_east_m_s": 0.1599999964237213,
        "velocity_down_m_s": -1.0499999523162842,
        "acceleration_forward": 0.4411945044994354,
        "acceleration_right": -1.2800939083099363,
        "acceleration_down": -10.53808307647705,
        "battery_id": 0,
        "battery_voltage": 13.908000946044922,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 0,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 1,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 2126,
        "udp_total": 54,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 40
      },
      {
        "id": 13,
        "datetime": "21/02/2023 02:03:36",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xb20184a8>, <mavsdk.mission_raw.MissionItem object at 0xa5913ad8>, <mavsdk.mission_raw.MissionItem object at 0xa59136d0>, <mavsdk.mission_raw.MissionItem object at 0xa5913838>]",
        "mission_pause": 0,
        "time_usec": 62139222,
        "odometry_pos_body": "PositionBody: [x_m: -0.25573739409446716, y_m: -0.5814855694770813, z_m: -0.6234644651412964]",
        "Command": "None",
        "navigation_system": "GPS",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 0,
        "alg_manual_gps_lon_start": 0,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 0,
        "alg_manual_gps_lon_next": 0,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 0,
        "alg_manual_y_now": 0,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 62194169,
        "flight_mode": "MISSION",
        "landed_state": "TAKING_OFF",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5,
        "current_climb_rate": 1.1097607612609863,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3064623,
        "gps_raw_lon_deg": 38.890017799999995,
        "gps_raw_abs_alt": 77.46700286865234,
        "gps_raw_velocity": 0.28999999165534973,
        "position_lat_deg": 47.306462,
        "position_lon_deg": 38.8900181,
        "position_abs_alt": 79.90400695800781,
        "position_real_alt": 1.158000111579895,
        "heading_deg": 352.47,
        "odometry_pos_body_x": -0.25763562321662903,
        "odometry_pos_body_y": -0.5662233233451843,
        "odometry_pos_body_z": -0.7666140794754028,
        "odometry_vel_body_x": 0.02174328826367855,
        "odometry_vel_body_y": 0.10280942916870116,
        "odometry_vel_body_z": -1.1013576984405518,
        "odometry_ang_vel_body_roll": -0.4679273962974548,
        "odometry_ang_vel_body_pitch": -0.053223252296447754,
        "odometry_ang_vel_body_yaw": 0.3262394964694977,
        "velocity_north_m_s": 0,
        "velocity_east_m_s": 0,
        "velocity_down_m_s": -1.100000023841858,
        "acceleration_forward": -0.45457208156585693,
        "acceleration_right": -0.7312511801719666,
        "acceleration_down": -10.34549617767334,
        "battery_id": 0,
        "battery_voltage": 13.908000946044922,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 0,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 9,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 2151,
        "udp_total": 55,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 41
      },
      {
        "id": 14,
        "datetime": "21/02/2023 02:03:37",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa590c148>, <mavsdk.mission_raw.MissionItem object at 0xa590cfb8>, <mavsdk.mission_raw.MissionItem object at 0xa590c9e8>, <mavsdk.mission_raw.MissionItem object at 0xa590c7f0>]",
        "mission_pause": 0,
        "time_usec": 62408964,
        "odometry_pos_body": "PositionBody: [x_m: -0.26518478989601135, y_m: -0.5703265070915222, z_m: -0.9194180965423584]",
        "Command": "None",
        "navigation_system": "GPS",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 0,
        "alg_manual_gps_lon_start": 0,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 0,
        "alg_manual_gps_lon_next": 0,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 0,
        "alg_manual_y_now": 0,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 62458934,
        "flight_mode": "MISSION",
        "landed_state": "TAKING_OFF",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5,
        "current_climb_rate": 0.9698614478111268,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3064622,
        "gps_raw_lon_deg": 38.8900179,
        "gps_raw_abs_alt": 77.92000579833984,
        "gps_raw_velocity": 0.07999999821186066,
        "position_lat_deg": 47.306461899999995,
        "position_lon_deg": 38.8900179,
        "position_abs_alt": 80.22900390625,
        "position_real_alt": 1.4820001125335691,
        "heading_deg": 355.46,
        "odometry_pos_body_x": -0.2770766317844391,
        "odometry_pos_body_y": -0.5885250568389893,
        "odometry_pos_body_z": -1.0570622682571411,
        "odometry_vel_body_x": -0.07226334512233734,
        "odometry_vel_body_y": -0.19659873843193057,
        "odometry_vel_body_z": -0.9676605463027954,
        "odometry_ang_vel_body_roll": 0.2503754496574402,
        "odometry_ang_vel_body_pitch": -0.12316935509443284,
        "odometry_ang_vel_body_yaw": 0.0231783427298069,
        "velocity_north_m_s": -0.09999999403953552,
        "velocity_east_m_s": -0.2299999892711639,
        "velocity_down_m_s": -0.949999988079071,
        "acceleration_forward": -0.3661612868309021,
        "acceleration_right": 0.4009888172149658,
        "acceleration_down": -7.971587181091309,
        "battery_id": 0,
        "battery_voltage": 13.908000946044922,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 0,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 2173,
        "udp_total": 55,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 41
      },
      {
        "id": 15,
        "datetime": "21/02/2023 02:03:37",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa59134d8>, <mavsdk.mission_raw.MissionItem object at 0xa59131f0>, <mavsdk.mission_raw.MissionItem object at 0xa5913ce8>, <mavsdk.mission_raw.MissionItem object at 0xa5913e80>]",
        "mission_pause": 0,
        "time_usec": 62708677,
        "odometry_pos_body": "PositionBody: [x_m: -0.2942531108856201, y_m: -0.6235162615776062, z_m: -1.2307769060134888]",
        "Command": "None",
        "navigation_system": "GPS",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 0,
        "alg_manual_gps_lon_start": 0,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 0,
        "alg_manual_gps_lon_next": 0,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 0,
        "alg_manual_y_now": 0,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 62753656,
        "flight_mode": "MISSION",
        "landed_state": "TAKING_OFF",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5,
        "current_climb_rate": 0.9293966889381408,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306462,
        "gps_raw_lon_deg": 38.8900176,
        "gps_raw_abs_alt": 78.07600402832031,
        "gps_raw_velocity": 0.1599999964237213,
        "position_lat_deg": 47.3064617,
        "position_lon_deg": 38.8900172,
        "position_abs_alt": 80.48200225830078,
        "position_real_alt": 1.7360000610351562,
        "heading_deg": 348.49,
        "odometry_pos_body_x": -0.2982981204986572,
        "odometry_pos_body_y": -0.6375934481620789,
        "odometry_pos_body_z": -1.3000226020812988,
        "odometry_vel_body_x": -0.061615943908691406,
        "odometry_vel_body_y": -0.32130348682403564,
        "odometry_vel_body_z": -0.8909975290298462,
        "odometry_ang_vel_body_roll": -0.1274494826793671,
        "odometry_ang_vel_body_pitch": 0.1429367959499359,
        "odometry_ang_vel_body_yaw": -0.16526508331298828,
        "velocity_north_m_s": -0.08999999612569809,
        "velocity_east_m_s": -0.29999998211860657,
        "velocity_down_m_s": -0.8899999856948853,
        "acceleration_forward": 2.274665594100952,
        "acceleration_right": -3.111746311187744,
        "acceleration_down": -7.119218826293945,
        "battery_id": 0,
        "battery_voltage": 13.908000946044922,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 0,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 1,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 2197,
        "udp_total": 55,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 41
      },
      {
        "id": 16,
        "datetime": "21/02/2023 02:03:37",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa591ecb8>, <mavsdk.mission_raw.MissionItem object at 0xa591e988>, <mavsdk.mission_raw.MissionItem object at 0xa591ed60>, <mavsdk.mission_raw.MissionItem object at 0xa591e5e0>]",
        "mission_pause": 0,
        "time_usec": 62948451,
        "odometry_pos_body": "PositionBody: [x_m: -0.304325133562088, y_m: -0.6742032170295715, z_m: -1.4623092412948608]",
        "Command": "None",
        "navigation_system": "GPS",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 0,
        "alg_manual_gps_lon_start": 0,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 0,
        "alg_manual_gps_lon_next": 0,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 0,
        "alg_manual_y_now": 0,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 63008387,
        "flight_mode": "MISSION",
        "landed_state": "TAKING_OFF",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.47999998927116394,
        "current_climb_rate": 0.8655877709388733,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3064618,
        "gps_raw_lon_deg": 38.8900168,
        "gps_raw_abs_alt": 78.27100372314453,
        "gps_raw_velocity": 0.3100000023841858,
        "position_lat_deg": 47.3064616,
        "position_lon_deg": 38.8900164,
        "position_abs_alt": 80.74700164794922,
        "position_real_alt": 2.001000165939331,
        "heading_deg": 345.82,
        "odometry_pos_body_x": -0.3036113679409027,
        "odometry_pos_body_y": -0.7033953070640564,
        "odometry_pos_body_z": -1.5827847719192505,
        "odometry_vel_body_x": 0.03280269354581833,
        "odometry_vel_body_y": -0.25690504908561707,
        "odometry_vel_body_z": -0.8436238765716553,
        "odometry_ang_vel_body_roll": -1.059669852256775,
        "odometry_ang_vel_body_pitch": -0.13478776812553406,
        "odometry_ang_vel_body_yaw": -0.19119560718536377,
        "velocity_north_m_s": 0,
        "velocity_east_m_s": -0.2299999892711639,
        "velocity_down_m_s": -0.8399999737739563,
        "acceleration_forward": 0.28714117407798767,
        "acceleration_right": -2.044410467147827,
        "acceleration_down": -2.976470947265625,
        "battery_id": 0,
        "battery_voltage": 13.908000946044922,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 0,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 13,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 2213,
        "udp_total": 56,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 42
      },
      {
        "id": 17,
        "datetime": "21/02/2023 02:03:38",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa5913b08>, <mavsdk.mission_raw.MissionItem object at 0xa5913ce8>, <mavsdk.mission_raw.MissionItem object at 0xa591e778>, <mavsdk.mission_raw.MissionItem object at 0xa591ec28>]",
        "mission_pause": 0,
        "time_usec": 63238166,
        "odometry_pos_body": "PositionBody: [x_m: -0.2999687194824219, y_m: -0.7375592589378357, z_m: -1.7267003059387207]",
        "Command": "None",
        "navigation_system": "GPS",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 0,
        "alg_manual_gps_lon_start": 0,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 0,
        "alg_manual_gps_lon_next": 0,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 0,
        "alg_manual_y_now": 0,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 63303119,
        "flight_mode": "MISSION",
        "landed_state": "TAKING_OFF",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.4599999785423279,
        "current_climb_rate": 0.8123207688331604,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3064621,
        "gps_raw_lon_deg": 38.8900155,
        "gps_raw_abs_alt": 78.6500015258789,
        "gps_raw_velocity": 0.28999999165534973,
        "position_lat_deg": 47.3064617,
        "position_lon_deg": 38.8900156,
        "position_abs_alt": 80.98600006103516,
        "position_real_alt": 2.240000009536743,
        "heading_deg": 344.25,
        "odometry_pos_body_x": -0.2926042079925537,
        "odometry_pos_body_y": -0.7610458731651306,
        "odometry_pos_body_z": -1.8295891284942627,
        "odometry_vel_body_x": 0.07728185504674911,
        "odometry_vel_body_y": -0.16477355360984802,
        "odometry_vel_body_z": -0.7434727549552917,
        "odometry_ang_vel_body_roll": -0.7193202972412109,
        "odometry_ang_vel_body_pitch": 0.48923346400260925,
        "odometry_ang_vel_body_yaw": 0.30901336669921875,
        "velocity_north_m_s": 0.009999999776482582,
        "velocity_east_m_s": -0.14999999105930328,
        "velocity_down_m_s": -0.7400000095367432,
        "acceleration_forward": 0.2680712640285492,
        "acceleration_right": -4.571735382080078,
        "acceleration_down": -7.452772617340088,
        "battery_id": 0,
        "battery_voltage": 13.68600082397461,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 0,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 0,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 2219,
        "udp_total": 56,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 42
      },
      {
        "id": 18,
        "datetime": "21/02/2023 02:03:38",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xb2018478>, <mavsdk.mission_raw.MissionItem object at 0xa476ce20>, <mavsdk.mission_raw.MissionItem object at 0xa476c9d0>, <mavsdk.mission_raw.MissionItem object at 0xa476c190>]",
        "mission_pause": 0,
        "time_usec": 63477932,
        "odometry_pos_body": "PositionBody: [x_m: -0.28183457255363464, y_m: -0.7807051539421082, z_m: -1.931506872177124]",
        "Command": "None",
        "navigation_system": "GPS",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 0,
        "alg_manual_gps_lon_start": 0,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 0,
        "alg_manual_gps_lon_next": 0,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 0,
        "alg_manual_y_now": 0,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 63522889,
        "flight_mode": "MISSION",
        "landed_state": "TAKING_OFF",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.4099999964237213,
        "current_climb_rate": 0.6418873071670532,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3064621,
        "gps_raw_lon_deg": 38.8900152,
        "gps_raw_abs_alt": 78.81100463867188,
        "gps_raw_velocity": 0.14000000059604645,
        "position_lat_deg": 47.306461899999995,
        "position_lon_deg": 38.8900152,
        "position_abs_alt": 81.21900177001953,
        "position_real_alt": 2.4730000495910645,
        "heading_deg": 353.47,
        "odometry_pos_body_x": -0.27194744348526,
        "odometry_pos_body_y": -0.7927104234695435,
        "odometry_pos_body_z": -2.0369489192962646,
        "odometry_vel_body_x": 0.030195601284503937,
        "odometry_vel_body_y": -0.0630996897816658,
        "odometry_vel_body_z": -0.6392835974693298,
        "odometry_ang_vel_body_roll": 0.5929153561592102,
        "odometry_ang_vel_body_pitch": -0.139099583029747,
        "odometry_ang_vel_body_yaw": 0.7805227041244507,
        "velocity_north_m_s": 0.05999999865889549,
        "velocity_east_m_s": -0.009999999776482582,
        "velocity_down_m_s": -0.6399999856948853,
        "acceleration_forward": 0.7149759531021118,
        "acceleration_right": -2.3326210975646973,
        "acceleration_down": -9.333433151245115,
        "battery_id": 0,
        "battery_voltage": 13.68600082397461,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 0,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 2243,
        "udp_total": 56,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 42
      },
      {
        "id": 19,
        "datetime": "21/02/2023 02:03:38",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa5910508>, <mavsdk.mission_raw.MissionItem object at 0xa5910838>, <mavsdk.mission_raw.MissionItem object at 0xa59106d0>, <mavsdk.mission_raw.MissionItem object at 0xa59102f8>]",
        "mission_pause": 0,
        "time_usec": 63737682,
        "odometry_pos_body": "PositionBody: [x_m: -0.2573812007904053, y_m: -0.7931411266326904, z_m: -2.1294190883636475]",
        "Command": "None",
        "navigation_system": "GPS",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 0,
        "alg_manual_gps_lon_start": 0,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 0,
        "alg_manual_gps_lon_next": 0,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 0,
        "alg_manual_y_now": 0,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 63797635,
        "flight_mode": "MISSION",
        "landed_state": "TAKING_OFF",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.38999998569488525,
        "current_climb_rate": 0.4457886815071106,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3064622,
        "gps_raw_lon_deg": 38.8900153,
        "gps_raw_abs_alt": 78.9590072631836,
        "gps_raw_velocity": 0.07999999821186066,
        "position_lat_deg": 47.3064621,
        "position_lon_deg": 38.8900152,
        "position_abs_alt": 81.34800720214844,
        "position_real_alt": 2.6020002365112305,
        "heading_deg": 2.51,
        "odometry_pos_body_x": -0.2429315000772476,
        "odometry_pos_body_y": -0.7864155173301697,
        "odometry_pos_body_z": -2.171746015548706,
        "odometry_vel_body_x": 0.16101902723312378,
        "odometry_vel_body_y": 0.08825709670782089,
        "odometry_vel_body_z": -0.4981108605861664,
        "odometry_ang_vel_body_roll": 0.2988276779651642,
        "odometry_ang_vel_body_pitch": -0.7203146815299988,
        "odometry_ang_vel_body_yaw": 0.9353790283203124,
        "velocity_north_m_s": 0.19999998807907104,
        "velocity_east_m_s": 0.17000000178813934,
        "velocity_down_m_s": -0.41999998688697815,
        "acceleration_forward": -1.711276292800903,
        "acceleration_right": 4.368948936462402,
        "acceleration_down": -14.970932006835938,
        "battery_id": 0,
        "battery_voltage": 13.68600082397461,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 0,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 14,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 2257,
        "udp_total": 56,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 42
      },
      {
        "id": 20,
        "datetime": "21/02/2023 02:03:38",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa5913550>, <mavsdk.mission_raw.MissionItem object at 0xa5913640>, <mavsdk.mission_raw.MissionItem object at 0xa5913040>, <mavsdk.mission_raw.MissionItem object at 0xa5913028>]",
        "mission_pause": 0,
        "time_usec": 63977452,
        "odometry_pos_body": "PositionBody: [x_m: -0.19693098962306976, y_m: -0.7484056353569031, z_m: -2.2501299381256104]",
        "Command": "None",
        "navigation_system": "GPS",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 0,
        "alg_manual_gps_lon_start": 0,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 0,
        "alg_manual_gps_lon_next": 0,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 0,
        "alg_manual_y_now": 0,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 64017412,
        "flight_mode": "MISSION",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.38999998569488525,
        "current_climb_rate": 0.23439079523086548,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3064624,
        "gps_raw_lon_deg": 38.8900157,
        "gps_raw_abs_alt": 79.06700134277344,
        "gps_raw_velocity": 0.119999997317791,
        "position_lat_deg": 47.3064627,
        "position_lon_deg": 38.8900159,
        "position_abs_alt": 81.44900512695312,
        "position_real_alt": 2.702000141143799,
        "heading_deg": 14.42,
        "odometry_pos_body_x": -0.18396207690238953,
        "odometry_pos_body_y": -0.735038697719574,
        "odometry_pos_body_z": -2.2691667079925537,
        "odometry_vel_body_x": 0.4578339159488678,
        "odometry_vel_body_y": 0.2385662943124771,
        "odometry_vel_body_z": -0.2656753361225128,
        "odometry_ang_vel_body_roll": -0.17561443150043488,
        "odometry_ang_vel_body_pitch": 0.5202894806861877,
        "odometry_ang_vel_body_yaw": 0.7464557886123657,
        "velocity_north_m_s": 0.4099999964237213,
        "velocity_east_m_s": 0.38999998569488525,
        "velocity_down_m_s": -0.12999999523162842,
        "acceleration_forward": 0.6109403371810913,
        "acceleration_right": -2.75907039642334,
        "acceleration_down": -7.08959436416626,
        "battery_id": 0,
        "battery_voltage": 13.68600082397461,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 0,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 2265,
        "udp_total": 58,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 42
      },
      {
        "id": 21,
        "datetime": "21/02/2023 02:03:38",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa58f2958>, <mavsdk.mission_raw.MissionItem object at 0xa5910730>, <mavsdk.mission_raw.MissionItem object at 0xa59102c8>, <mavsdk.mission_raw.MissionItem object at 0xa5910838>]",
        "mission_pause": 0,
        "time_usec": 64137297,
        "odometry_pos_body": "PositionBody: [x_m: -0.1407552808523178, y_m: -0.6893748641014099, z_m: -2.294299840927124]",
        "Command": "None",
        "navigation_system": "GPS",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 0,
        "alg_manual_gps_lon_start": 0,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 0,
        "alg_manual_gps_lon_next": 0,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 0,
        "alg_manual_y_now": 0,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 64192245,
        "flight_mode": "MISSION",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.44999998807907104,
        "current_climb_rate": -0.01284334436058998,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3064628,
        "gps_raw_lon_deg": 38.8900164,
        "gps_raw_abs_alt": 79.13500213623047,
        "gps_raw_velocity": 0.3499999940395355,
        "position_lat_deg": 47.3064635,
        "position_lon_deg": 38.8900173,
        "position_abs_alt": 81.50100708007812,
        "position_real_alt": 2.755000114440918,
        "heading_deg": 26.77,
        "odometry_pos_body_x": -0.07835452258586884,
        "odometry_pos_body_y": -0.6145468950271606,
        "odometry_pos_body_z": -2.3239521980285645,
        "odometry_vel_body_x": 0.6267833113670349,
        "odometry_vel_body_y": 0.22805246710777283,
        "odometry_vel_body_z": 0.040035877376794815,
        "odometry_ang_vel_body_roll": -0.5290265083312988,
        "odometry_ang_vel_body_pitch": 0.5889614224433899,
        "odometry_ang_vel_body_yaw": 0.6655348539352417,
        "velocity_north_m_s": 0.41999998688697815,
        "velocity_east_m_s": 0.5099999904632568,
        "velocity_down_m_s": 0.009999999776482582,
        "acceleration_forward": -1.7393434047698977,
        "acceleration_right": 3.2697865962982178,
        "acceleration_down": -16.923620223999023,
        "battery_id": 0,
        "battery_voltage": 13.68600082397461,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 0,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 2290,
        "udp_total": 59,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 43
      },
      {
        "id": 22,
        "datetime": "21/02/2023 02:03:39",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa476ccb8>, <mavsdk.mission_raw.MissionItem object at 0xa476cee0>, <mavsdk.mission_raw.MissionItem object at 0xa476c3b8>, <mavsdk.mission_raw.MissionItem object at 0xa476ce20>]",
        "mission_pause": 0,
        "time_usec": 64437009,
        "odometry_pos_body": "PositionBody: [x_m: -0.038426123559474945, y_m: -0.557033360004425, z_m: -2.3513991832733154]",
        "Command": "None",
        "navigation_system": "GPS",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 0,
        "alg_manual_gps_lon_start": 0,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 0,
        "alg_manual_gps_lon_next": 0,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 0,
        "alg_manual_y_now": 0,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 64506943,
        "flight_mode": "MISSION",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.44999998807907104,
        "current_climb_rate": -0.04196666553616524,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3064634,
        "gps_raw_lon_deg": 38.8900177,
        "gps_raw_abs_alt": 79.1830062866211,
        "gps_raw_velocity": 0.6200000047683716,
        "position_lat_deg": 47.3064642,
        "position_lon_deg": 38.8900187,
        "position_abs_alt": 81.54500579833984,
        "position_real_alt": 2.799000024795532,
        "heading_deg": 35.65,
        "odometry_pos_body_x": -0.0176448505371809,
        "odometry_pos_body_y": -0.5273579955101013,
        "odometry_pos_body_z": -2.362623691558838,
        "odometry_vel_body_x": 0.5603011250495911,
        "odometry_vel_body_y": 0.13697771728038788,
        "odometry_vel_body_z": 0.08294426649808884,
        "odometry_ang_vel_body_roll": -1.6149572134017944,
        "odometry_ang_vel_body_pitch": -0.6150810718536377,
        "odometry_ang_vel_body_yaw": 0.7412996292114258,
        "velocity_north_m_s": 0.3700000047683716,
        "velocity_east_m_s": 0.4399999976158142,
        "velocity_down_m_s": 0.05999999865889549,
        "acceleration_forward": -1.3668678998947144,
        "acceleration_right": 2.4612181186676025,
        "acceleration_down": -10.96473217010498,
        "battery_id": 0,
        "battery_voltage": 13.68600082397461,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 0,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 14,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 2304,
        "udp_total": 59,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 43
      },
      {
        "id": 23,
        "datetime": "21/02/2023 02:03:39",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa5913928>, <mavsdk.mission_raw.MissionItem object at 0xa5913748>, <mavsdk.mission_raw.MissionItem object at 0xa5913e68>, <mavsdk.mission_raw.MissionItem object at 0xa5913868>]",
        "mission_pause": 0,
        "time_usec": 64636821,
        "odometry_pos_body": "PositionBody: [x_m: 0.02459222450852394, y_m: -0.4542349874973297, z_m: -2.3949625492095947]",
        "Command": "None",
        "navigation_system": "GPS",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 0,
        "alg_manual_gps_lon_start": 0,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 0,
        "alg_manual_gps_lon_next": 0,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 0,
        "alg_manual_y_now": 0,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 64731780,
        "flight_mode": "MISSION",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.4399999976158142,
        "current_climb_rate": -0.028081398457288746,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3064647,
        "gps_raw_lon_deg": 38.8900205,
        "gps_raw_abs_alt": 79.1780014038086,
        "gps_raw_velocity": 0.5899999737739563,
        "position_lat_deg": 47.3064647,
        "position_lon_deg": 38.8900199,
        "position_abs_alt": 81.58700561523438,
        "position_real_alt": 2.8410000801086426,
        "heading_deg": 43.36,
        "odometry_pos_body_x": 0.0466914065182209,
        "odometry_pos_body_y": -0.4258019030094147,
        "odometry_pos_body_z": -2.4080591201782227,
        "odometry_vel_body_x": 0.45013582706451416,
        "odometry_vel_body_y": 0.020746180787682533,
        "odometry_vel_body_z": -0.0026151754427701235,
        "odometry_ang_vel_body_roll": -0.5356327295303345,
        "odometry_ang_vel_body_pitch": -0.295559823513031,
        "odometry_ang_vel_body_yaw": 0.09109029173851012,
        "velocity_north_m_s": 0.3100000023841858,
        "velocity_east_m_s": 0.3199999928474426,
        "velocity_down_m_s": 0,
        "acceleration_forward": -1.2024754285812378,
        "acceleration_right": 1.1449960470199585,
        "acceleration_down": -10.373066902160645,
        "battery_id": 0,
        "battery_voltage": 13.68600082397461,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 0,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 2312,
        "udp_total": 59,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 43
      },
      {
        "id": 24,
        "datetime": "21/02/2023 02:03:39",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa476c850>, <mavsdk.mission_raw.MissionItem object at 0xa476cc40>, <mavsdk.mission_raw.MissionItem object at 0xa476c730>, <mavsdk.mission_raw.MissionItem object at 0xa476c118>]",
        "mission_pause": 0,
        "time_usec": 64841620,
        "odometry_pos_body": "PositionBody: [x_m: 0.07450935989618301, y_m: -0.39250296354293823, z_m: -2.4186487197875977]",
        "Command": "None",
        "navigation_system": "GPS",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 0,
        "alg_manual_gps_lon_start": 0,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 0,
        "alg_manual_gps_lon_next": 0,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 0,
        "alg_manual_y_now": 0,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 64936529,
        "flight_mode": "MISSION",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.4399999976158142,
        "current_climb_rate": -0.05474806949496269,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3064652,
        "gps_raw_lon_deg": 38.8900216,
        "gps_raw_abs_alt": 79.16300201416016,
        "gps_raw_velocity": 0.5,
        "position_lat_deg": 47.306465499999995,
        "position_lon_deg": 38.8900213,
        "position_abs_alt": 81.60700225830078,
        "position_real_alt": 2.8600001335144043,
        "heading_deg": 43.55,
        "odometry_pos_body_x": 0.1266155242919922,
        "odometry_pos_body_y": -0.329067587852478,
        "odometry_pos_body_z": -2.424373149871826,
        "odometry_vel_body_x": 0.4269327223300934,
        "odometry_vel_body_y": 0.0025214117486029863,
        "odometry_vel_body_z": 0.06565317511558533,
        "odometry_ang_vel_body_roll": -0.2722093462944031,
        "odometry_ang_vel_body_pitch": -0.30812275409698486,
        "odometry_ang_vel_body_yaw": -0.037397608160972595,
        "velocity_north_m_s": 0.29999998211860657,
        "velocity_east_m_s": 0.2800000011920929,
        "velocity_down_m_s": 0.07999999821186066,
        "acceleration_forward": -0.029085569083690643,
        "acceleration_right": 3.5674962997436523,
        "acceleration_down": -14.046833038330078,
        "battery_id": 0,
        "battery_voltage": 13.68600082397461,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 0,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 2336,
        "udp_total": 59,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 43
      },
      {
        "id": 25,
        "datetime": "21/02/2023 02:03:39",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa5913448>, <mavsdk.mission_raw.MissionItem object at 0xb5df1688>, <mavsdk.mission_raw.MissionItem object at 0xa590c508>, <mavsdk.mission_raw.MissionItem object at 0xa590cbf8>]",
        "mission_pause": 0,
        "time_usec": 65101370,
        "odometry_pos_body": "PositionBody: [x_m: 0.1540812849998474, y_m: -0.29606810212135315, z_m: -2.4210526943206787]",
        "Command": "None",
        "navigation_system": "GPS",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 0,
        "alg_manual_gps_lon_start": 0,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 0,
        "alg_manual_gps_lon_next": 0,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 0,
        "alg_manual_y_now": 0,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 65206273,
        "flight_mode": "MISSION",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.44999998807907104,
        "current_climb_rate": -0.10939807444810867,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3064659,
        "gps_raw_lon_deg": 38.8900227,
        "gps_raw_abs_alt": 79.15400695800781,
        "gps_raw_velocity": 0.5,
        "position_lat_deg": 47.306466099999994,
        "position_lon_deg": 38.8900224,
        "position_abs_alt": 81.59500122070312,
        "position_real_alt": 2.8490002155303955,
        "heading_deg": 42.69,
        "odometry_pos_body_x": 0.19030410051345825,
        "odometry_pos_body_y": -0.2505442500114441,
        "odometry_pos_body_z": -2.413299798965454,
        "odometry_vel_body_x": 0.4404063522815704,
        "odometry_vel_body_y": 0.03246685117483139,
        "odometry_vel_body_z": 0.07276779413223267,
        "odometry_ang_vel_body_roll": -1.073553442955017,
        "odometry_ang_vel_body_pitch": -0.1902524381875992,
        "odometry_ang_vel_body_yaw": -0.12356985360383987,
        "velocity_north_m_s": 0.28999999165534973,
        "velocity_east_m_s": 0.3199999928474426,
        "velocity_down_m_s": 0.08999999612569809,
        "acceleration_forward": 1.1708558797836304,
        "acceleration_right": -2.65543270111084,
        "acceleration_down": -6.915591239929199,
        "battery_id": 0,
        "battery_voltage": 13.677000045776367,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 0,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 10,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 2346,
        "udp_total": 60,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 44
      },
      {
        "id": 26,
        "datetime": "21/02/2023 02:03:40",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa590cf28>, <mavsdk.mission_raw.MissionItem object at 0xb2025058>, <mavsdk.mission_raw.MissionItem object at 0xa58fcb38>, <mavsdk.mission_raw.MissionItem object at 0xa58fc670>]",
        "mission_pause": 0,
        "time_usec": 65421064,
        "odometry_pos_body": "PositionBody: [x_m: 0.2615601718425751, y_m: -0.16387413442134857, z_m: -2.394894599914551]",
        "Command": "None",
        "navigation_system": "GPS",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 0,
        "alg_manual_gps_lon_start": 0,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 0,
        "alg_manual_gps_lon_next": 0,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 0,
        "alg_manual_y_now": 0,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 65456031,
        "flight_mode": "MISSION",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.4599999785423279,
        "current_climb_rate": -0.10138880461454391,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3064665,
        "gps_raw_lon_deg": 38.8900237,
        "gps_raw_abs_alt": 79.1290054321289,
        "gps_raw_velocity": 0.47999998927116394,
        "position_lat_deg": 47.3064669,
        "position_lon_deg": 38.8900237,
        "position_abs_alt": 81.57300567626953,
        "position_real_alt": 2.825000047683716,
        "heading_deg": 40.88,
        "odometry_pos_body_x": 0.2793460488319397,
        "odometry_pos_body_y": -0.14380642771720886,
        "odometry_pos_body_z": -2.3909285068511963,
        "odometry_vel_body_x": 0.4789547622203827,
        "odometry_vel_body_y": 0.03380769118666649,
        "odometry_vel_body_z": 0.03317449614405632,
        "odometry_ang_vel_body_roll": 0.05061455816030502,
        "odometry_ang_vel_body_pitch": 0.2004026174545288,
        "odometry_ang_vel_body_yaw": -0.1792987883090973,
        "velocity_north_m_s": 0.32999998331069946,
        "velocity_east_m_s": 0.32999998331069946,
        "velocity_down_m_s": 0.04999999701976776,
        "acceleration_forward": -0.205020934343338,
        "acceleration_right": -3.4439828395843506,
        "acceleration_down": -11.583568572998049,
        "battery_id": 0,
        "battery_voltage": 13.677000045776367,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 0,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 2358,
        "udp_total": 60,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 44
      },
      {
        "id": 27,
        "datetime": "21/02/2023 02:03:40",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa5910688>, <mavsdk.mission_raw.MissionItem object at 0xa5910508>, <mavsdk.mission_raw.MissionItem object at 0xa5910ce8>, <mavsdk.mission_raw.MissionItem object at 0xa5910d00>]",
        "mission_pause": 0,
        "time_usec": 65610885,
        "odometry_pos_body": "PositionBody: [x_m: 0.3370787501335144, y_m: -0.09001392871141434, z_m: -2.374575614929199]",
        "Command": "None",
        "navigation_system": "GPS",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 0,
        "alg_manual_gps_lon_start": 0,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 0,
        "alg_manual_gps_lon_next": 0,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 0,
        "alg_manual_y_now": 0,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 65640854,
        "flight_mode": "MISSION",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.4599999785423279,
        "current_climb_rate": -0.08147361874580383,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306467999999995,
        "gps_raw_lon_deg": 38.8900255,
        "gps_raw_abs_alt": 79.12000274658203,
        "gps_raw_velocity": 0.5,
        "position_lat_deg": 47.3064677,
        "position_lon_deg": 38.8900248,
        "position_abs_alt": 81.54400634765625,
        "position_real_alt": 2.7980000972747803,
        "heading_deg": 38.83,
        "odometry_pos_body_x": 0.3983634114265442,
        "odometry_pos_body_y": -0.04469667747616768,
        "odometry_pos_body_z": -2.358375072479248,
        "odometry_vel_body_x": 0.5416898131370544,
        "odometry_vel_body_y": -0.06361070275306702,
        "odometry_vel_body_z": 0.06255201995372772,
        "odometry_ang_vel_body_roll": 1.690213918685913,
        "odometry_ang_vel_body_pitch": 0.337379515171051,
        "odometry_ang_vel_body_yaw": -0.07788664847612381,
        "velocity_north_m_s": 0.44999998807907104,
        "velocity_east_m_s": 0.2800000011920929,
        "velocity_down_m_s": 0.07999999821186066,
        "acceleration_forward": -0.7580177783966064,
        "acceleration_right": 2.8957250118255615,
        "acceleration_down": -14.694488525390623,
        "battery_id": 0,
        "battery_voltage": 13.677000045776367,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 0,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 0,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 2382,
        "udp_total": 60,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 44
      },
      {
        "id": 28,
        "datetime": "21/02/2023 02:03:40",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa476c6e8>, <mavsdk.mission_raw.MissionItem object at 0xa476c970>, <mavsdk.mission_raw.MissionItem object at 0xa476c028>, <mavsdk.mission_raw.MissionItem object at 0xa476c4d8>]",
        "mission_pause": 0,
        "time_usec": 65870637,
        "odometry_pos_body": "PositionBody: [x_m: 0.48930397629737854, y_m: 0.0065236943773925304, z_m: -2.342830181121826]",
        "Command": "None",
        "navigation_system": "GPS",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 0,
        "alg_manual_gps_lon_start": 0,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 0,
        "alg_manual_gps_lon_next": 0,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 0,
        "alg_manual_y_now": 0,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 65935574,
        "flight_mode": "MISSION",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.4699999988079071,
        "current_climb_rate": -0.09392514079809187,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3064689,
        "gps_raw_lon_deg": 38.8900266,
        "gps_raw_abs_alt": 79.07600402832031,
        "gps_raw_velocity": 0.6399999856948853,
        "position_lat_deg": 47.3064689,
        "position_lon_deg": 38.8900258,
        "position_abs_alt": 81.52200317382812,
        "position_real_alt": 2.7760000228881836,
        "heading_deg": 37.64,
        "odometry_pos_body_x": 0.5068866610527039,
        "odometry_pos_body_y": 0.018137970939278603,
        "odometry_pos_body_z": -2.338801145553589,
        "odometry_vel_body_x": 0.5965344905853271,
        "odometry_vel_body_y": -0.10828831791877748,
        "odometry_vel_body_z": 0.08620176464319229,
        "odometry_ang_vel_body_roll": -0.9362027645111084,
        "odometry_ang_vel_body_pitch": -0.19681580364704132,
        "odometry_ang_vel_body_yaw": -0.08781798928976059,
        "velocity_north_m_s": 0.5399999618530273,
        "velocity_east_m_s": 0.26999998092651367,
        "velocity_down_m_s": 0.10999999940395357,
        "acceleration_forward": 1.3888808488845823,
        "acceleration_right": 2.9220662117004395,
        "acceleration_down": -13.678980827331545,
        "battery_id": 0,
        "battery_voltage": 13.677000045776367,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 0,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 6,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 2394,
        "udp_total": 60,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 44
      },
      {
        "id": 29,
        "datetime": "21/02/2023 02:03:40",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa476c6a0>, <mavsdk.mission_raw.MissionItem object at 0xa476c178>, <mavsdk.mission_raw.MissionItem object at 0xa5913070>, <mavsdk.mission_raw.MissionItem object at 0xa5913400>]",
        "mission_pause": 0,
        "time_usec": 66110402,
        "odometry_pos_body": "PositionBody: [x_m: 0.6099421977996826, y_m: 0.0803360790014267, z_m: -2.317716121673584]",
        "Command": "None",
        "navigation_system": "GPS",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 0,
        "alg_manual_gps_lon_start": 0,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 0,
        "alg_manual_gps_lon_next": 0,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 0,
        "alg_manual_y_now": 0,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 66180336,
        "flight_mode": "MISSION",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.4899999797344208,
        "current_climb_rate": -0.09532668441534042,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3064701,
        "gps_raw_lon_deg": 38.890027499999995,
        "gps_raw_abs_alt": 79.08200073242188,
        "gps_raw_velocity": 0.6699999570846558,
        "position_lat_deg": 47.3064703,
        "position_lon_deg": 38.8900271,
        "position_abs_alt": 81.48900604248047,
        "position_real_alt": 2.743000030517578,
        "heading_deg": 37.6,
        "odometry_pos_body_x": 0.6548355221748352,
        "odometry_pos_body_y": 0.1084865853190422,
        "odometry_pos_body_z": -2.3078737258911133,
        "odometry_vel_body_x": 0.6670919060707092,
        "odometry_vel_body_y": -0.08875277638435364,
        "odometry_vel_body_z": 0.06527552008628845,
        "odometry_ang_vel_body_roll": 1.2627277374267578,
        "odometry_ang_vel_body_pitch": -0.4135991632938385,
        "odometry_ang_vel_body_yaw": 0.10473805665969849,
        "velocity_north_m_s": 0.5899999737739563,
        "velocity_east_m_s": 0.3199999928474426,
        "velocity_down_m_s": 0.119999997317791,
        "acceleration_forward": 0.8617786169052124,
        "acceleration_right": -2.8962910175323486,
        "acceleration_down": -6.552382469177246,
        "battery_id": 0,
        "battery_voltage": 13.677000045776367,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 0,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 1,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 2404,
        "udp_total": 61,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 45
      },
      {
        "id": 30,
        "datetime": "21/02/2023 02:03:41",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xb0ed1028>, <mavsdk.mission_raw.MissionItem object at 0xb0ed1370>, <mavsdk.mission_raw.MissionItem object at 0xb0ed1280>, <mavsdk.mission_raw.MissionItem object at 0xb0ed1088>]",
        "mission_pause": 0,
        "time_usec": 66310213,
        "odometry_pos_body": "PositionBody: [x_m: 0.737769365310669, y_m: 0.1605539470911026, z_m: -2.2901394367218018]",
        "Command": "None",
        "navigation_system": "GPS",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 0,
        "alg_manual_gps_lon_start": 0,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 0,
        "alg_manual_gps_lon_next": 0,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 0,
        "alg_manual_y_now": 0,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 66420105,
        "flight_mode": "MISSION",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.4899999797344208,
        "current_climb_rate": -0.06595133244991302,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3064712,
        "gps_raw_lon_deg": 38.8900284,
        "gps_raw_abs_alt": 79.06300354003906,
        "gps_raw_velocity": 0.6800000071525574,
        "position_lat_deg": 47.3064717,
        "position_lon_deg": 38.8900285,
        "position_abs_alt": 81.45700073242188,
        "position_real_alt": 2.7080001831054688,
        "heading_deg": 39.29,
        "odometry_pos_body_x": 0.8211599588394165,
        "odometry_pos_body_y": 0.21608370542526245,
        "odometry_pos_body_z": -2.27368426322937,
        "odometry_vel_body_x": 0.7251634001731873,
        "odometry_vel_body_y": -0.0680525153875351,
        "odometry_vel_body_z": 0.012596499174833298,
        "odometry_ang_vel_body_roll": 0.9204534292221068,
        "odometry_ang_vel_body_pitch": 0.16261714696884155,
        "odometry_ang_vel_body_yaw": 0.08819009363651276,
        "velocity_north_m_s": 0.5999999642372131,
        "velocity_east_m_s": 0.3999999761581421,
        "velocity_down_m_s": 0.03999999910593033,
        "acceleration_forward": 0.4655294418334961,
        "acceleration_right": -1.9151504039764404,
        "acceleration_down": -4.290649890899658,
        "battery_id": 0,
        "battery_voltage": 13.677000045776367,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 0,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 2428,
        "udp_total": 61,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 45
      },
      {
        "id": 31,
        "datetime": "21/02/2023 02:03:41",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa5913ef8>, <mavsdk.mission_raw.MissionItem object at 0xa5913868>, <mavsdk.mission_raw.MissionItem object at 0xa59134d8>, <mavsdk.mission_raw.MissionItem object at 0xa5913c10>]",
        "mission_pause": 0,
        "time_usec": 66609925,
        "odometry_pos_body": "PositionBody: [x_m: 0.9357388615608215, y_m: 0.297800749540329, z_m: -2.257979154586792]",
        "Command": "None",
        "navigation_system": "GPS",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 0,
        "alg_manual_gps_lon_start": 0,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 0,
        "alg_manual_gps_lon_next": 0,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 0,
        "alg_manual_y_now": 0,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 66639896,
        "flight_mode": "MISSION",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5,
        "current_climb_rate": -0.042218878865242,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3064724,
        "gps_raw_lon_deg": 38.8900296,
        "gps_raw_abs_alt": 79.06000518798828,
        "gps_raw_velocity": 0.75,
        "position_lat_deg": 47.3064731,
        "position_lon_deg": 38.890029899999995,
        "position_abs_alt": 81.43700408935547,
        "position_real_alt": 2.69100022315979,
        "heading_deg": 40.01,
        "odometry_pos_body_x": 0.983736515045166,
        "odometry_pos_body_y": 0.3327350914478302,
        "odometry_pos_body_z": -2.2537612915039062,
        "odometry_vel_body_x": 0.7902440428733826,
        "odometry_vel_body_y": -0.07577575743198395,
        "odometry_vel_body_z": -0.03789058327674866,
        "odometry_ang_vel_body_roll": -0.8736108541488647,
        "odometry_ang_vel_body_pitch": -0.07148078829050064,
        "odometry_ang_vel_body_yaw": 0.05956191569566727,
        "velocity_north_m_s": 0.6499999761581421,
        "velocity_east_m_s": 0.44999998807907104,
        "velocity_down_m_s": 0,
        "acceleration_forward": -0.14150622487068176,
        "acceleration_right": 0.4795619547367096,
        "acceleration_down": -6.622807025909424,
        "battery_id": 0,
        "battery_voltage": 13.677000045776367,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 0,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 2450,
        "udp_total": 61,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 45
      },
      {
        "id": 32,
        "datetime": "21/02/2023 02:03:41",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa5910280>, <mavsdk.mission_raw.MissionItem object at 0xa591ead8>, <mavsdk.mission_raw.MissionItem object at 0xa591e9d0>, <mavsdk.mission_raw.MissionItem object at 0xa591e850>]",
        "mission_pause": 0,
        "time_usec": 66879718,
        "odometry_pos_body": "PositionBody: [x_m: 1.1318198442459106, y_m: 0.43494904041290283, z_m: -2.250767230987549]",
        "Command": "None",
        "navigation_system": "GPS",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 0,
        "alg_manual_gps_lon_start": 0,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 0,
        "alg_manual_gps_lon_next": 0,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 0,
        "alg_manual_y_now": 0,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 66904645,
        "flight_mode": "MISSION",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.4899999797344208,
        "current_climb_rate": 0.05769003927707672,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3064749,
        "gps_raw_lon_deg": 38.8900321,
        "gps_raw_abs_alt": 79.07400512695312,
        "gps_raw_velocity": 0.8399999737739563,
        "position_lat_deg": 47.3064749,
        "position_lon_deg": 38.8900319,
        "position_abs_alt": 81.4320068359375,
        "position_real_alt": 2.686000108718872,
        "heading_deg": 42.76,
        "odometry_pos_body_x": 1.1767213344573977,
        "odometry_pos_body_y": 0.4668924808502197,
        "odometry_pos_body_z": -2.249939203262329,
        "odometry_vel_body_x": 0.8333426713943481,
        "odometry_vel_body_y": -0.13196486234664917,
        "odometry_vel_body_z": -0.052269477397203445,
        "odometry_ang_vel_body_roll": -0.684895932674408,
        "odometry_ang_vel_body_pitch": -0.3891964852809906,
        "odometry_ang_vel_body_yaw": 0.10942701995372772,
        "velocity_north_m_s": 0.6899999976158142,
        "velocity_east_m_s": 0.4599999785423279,
        "velocity_down_m_s": -0.009999999776482582,
        "acceleration_forward": -0.7276422381401062,
        "acceleration_right": -2.462742805480957,
        "acceleration_down": -4.2559638023376465,
        "battery_id": 0,
        "battery_voltage": 13.677000045776367,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 0,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 2450,
        "udp_total": 61,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 45
      },
      {
        "id": 33,
        "datetime": "21/02/2023 02:03:41",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa476cc70>, <mavsdk.mission_raw.MissionItem object at 0xa476c640>, <mavsdk.mission_raw.MissionItem object at 0xa476c5c8>, <mavsdk.mission_raw.MissionItem object at 0xa476c430>]",
        "mission_pause": 0,
        "time_usec": 67069484,
        "odometry_pos_body": "PositionBody: [x_m: 1.2746412754058838, y_m: 0.5359366536140442, z_m: -2.2482998371124268]",
        "Command": "None",
        "navigation_system": "GPS",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 0,
        "alg_manual_gps_lon_start": 0,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 0,
        "alg_manual_gps_lon_next": 0,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 0,
        "alg_manual_y_now": 0,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 67149410,
        "flight_mode": "MISSION",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5,
        "current_climb_rate": 0.022526973858475685,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3064763,
        "gps_raw_lon_deg": 38.890033599999995,
        "gps_raw_abs_alt": 79.08700561523438,
        "gps_raw_velocity": 0.9099999666213988,
        "position_lat_deg": 47.3064766,
        "position_lon_deg": 38.890033599999995,
        "position_abs_alt": 81.43000030517578,
        "position_real_alt": 2.683000087738037,
        "heading_deg": 46.45,
        "odometry_pos_body_x": 1.3595287799835205,
        "odometry_pos_body_y": 0.596871554851532,
        "odometry_pos_body_z": -2.2473554611206055,
        "odometry_vel_body_x": 0.8582360148429871,
        "odometry_vel_body_y": -0.17570754885673523,
        "odometry_vel_body_z": -0.06968075037002563,
        "odometry_ang_vel_body_roll": 0.2135402411222458,
        "odometry_ang_vel_body_pitch": 0.16974791884422302,
        "odometry_ang_vel_body_yaw": 0.18811435997486117,
        "velocity_north_m_s": 0.7199999690055847,
        "velocity_east_m_s": 0.5,
        "velocity_down_m_s": -0.029999999329447743,
        "acceleration_forward": 0.966769516468048,
        "acceleration_right": -3.0946226119995117,
        "acceleration_down": -6.359447479248047,
        "battery_id": 0,
        "battery_voltage": 13.593000411987305,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 0,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 2474,
        "udp_total": 62,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 46
      },
      {
        "id": 34,
        "datetime": "21/02/2023 02:03:42",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa476c2c8>, <mavsdk.mission_raw.MissionItem object at 0xa476cd60>, <mavsdk.mission_raw.MissionItem object at 0xa476c358>, <mavsdk.mission_raw.MissionItem object at 0xa476c220>]",
        "mission_pause": 0,
        "time_usec": 67279284,
        "odometry_pos_body": "PositionBody: [x_m: 1.4373517036437988, y_m: 0.6543172597885132, z_m: -2.247767448425293]",
        "Command": "None",
        "navigation_system": "GPS",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 0,
        "alg_manual_gps_lon_start": 0,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 0,
        "alg_manual_gps_lon_next": 0,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 0,
        "alg_manual_y_now": 0,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 67359207,
        "flight_mode": "MISSION",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5,
        "current_climb_rate": 0.018146036192774773,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306477699999995,
        "gps_raw_lon_deg": 38.8900349,
        "gps_raw_abs_alt": 79.09300231933594,
        "gps_raw_velocity": 0.9099999666213988,
        "position_lat_deg": 47.306478,
        "position_lon_deg": 38.8900351,
        "position_abs_alt": 81.43000030517578,
        "position_real_alt": 2.684000015258789,
        "heading_deg": 48.07,
        "odometry_pos_body_x": 1.5431795120239258,
        "odometry_pos_body_y": 0.7299201488494873,
        "odometry_pos_body_z": -2.2480716705322266,
        "odometry_vel_body_x": 0.9137412309646606,
        "odometry_vel_body_y": -0.17538702487945557,
        "odometry_vel_body_z": -0.06579788029193878,
        "odometry_ang_vel_body_roll": -0.06319435685873032,
        "odometry_ang_vel_body_pitch": -0.2874681055545807,
        "odometry_ang_vel_body_yaw": 0.11758165806531906,
        "velocity_north_m_s": 0.7299999594688416,
        "velocity_east_m_s": 0.5600000023841858,
        "velocity_down_m_s": -0.019999999552965164,
        "acceleration_forward": -0.9229018688201904,
        "acceleration_right": -4.088949203491211,
        "acceleration_down": -12.039176940917969,
        "battery_id": 0,
        "battery_voltage": 13.593000411987305,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 0,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 2496,
        "udp_total": 62,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 46
      },
      {
        "id": 35,
        "datetime": "21/02/2023 02:03:42",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa5913dd8>, <mavsdk.mission_raw.MissionItem object at 0xa5913598>, <mavsdk.mission_raw.MissionItem object at 0xa5913ec8>, <mavsdk.mission_raw.MissionItem object at 0xb20126a0>]",
        "mission_pause": 0,
        "time_usec": 67544039,
        "odometry_pos_body": "PositionBody: [x_m: 1.6508961915969849, y_m: 0.8121463060379028, z_m: -2.2474477291107178]",
        "Command": "None",
        "navigation_system": "GPS",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 0,
        "alg_manual_gps_lon_start": 0,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 0,
        "alg_manual_gps_lon_next": 0,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 0,
        "alg_manual_y_now": 0,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 67603977,
        "flight_mode": "MISSION",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5099999904632568,
        "current_climb_rate": 0.05324023216962814,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3064805,
        "gps_raw_lon_deg": 38.8900381,
        "gps_raw_abs_alt": 79.125,
        "gps_raw_velocity": 0.949999988079071,
        "position_lat_deg": 47.3064804,
        "position_lon_deg": 38.890038,
        "position_abs_alt": 81.42900085449219,
        "position_real_alt": 2.683000087738037,
        "heading_deg": 50.88,
        "odometry_pos_body_x": 1.8246750831604004,
        "odometry_pos_body_y": 0.96457839012146,
        "odometry_pos_body_z": -2.248473882675171,
        "odometry_vel_body_x": 0.9355897903442384,
        "odometry_vel_body_y": -0.0978926494717598,
        "odometry_vel_body_z": -0.08376703411340714,
        "odometry_ang_vel_body_roll": -0.3122231066226959,
        "odometry_ang_vel_body_pitch": 0.14294591546058655,
        "odometry_ang_vel_body_yaw": 0.0749824121594429,
        "velocity_north_m_s": 0.6499999761581421,
        "velocity_east_m_s": 0.6699999570846558,
        "velocity_down_m_s": -0.07000000029802322,
        "acceleration_forward": 1.0917826890945437,
        "acceleration_right": -2.9571080207824707,
        "acceleration_down": -11.496500968933104,
        "battery_id": 0,
        "battery_voltage": 13.593000411987305,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 0,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 2520,
        "udp_total": 62,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 46
      },
      {
        "id": 36,
        "datetime": "21/02/2023 02:03:42",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xb2012718>, <mavsdk.mission_raw.MissionItem object at 0xa5910e50>, <mavsdk.mission_raw.MissionItem object at 0xa5910148>, <mavsdk.mission_raw.MissionItem object at 0xa5910130>]",
        "mission_pause": 0,
        "time_usec": 68003593,
        "odometry_pos_body": "PositionBody: [x_m: 1.9873559474945068, y_m: 1.1217759847640991, z_m: -2.2616851329803467]",
        "Command": "None",
        "navigation_system": "GPS",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 0,
        "alg_manual_gps_lon_start": 0,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 0,
        "alg_manual_gps_lon_next": 0,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 0,
        "alg_manual_y_now": 0,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 67978622,
        "flight_mode": "MISSION",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.4899999797344208,
        "current_climb_rate": 0.13262848556041718,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306483,
        "gps_raw_lon_deg": 38.8900417,
        "gps_raw_abs_alt": 79.1830062866211,
        "gps_raw_velocity": 0.9799999594688416,
        "position_lat_deg": 47.3064829,
        "position_lon_deg": 38.8900416,
        "position_abs_alt": 81.45600128173828,
        "position_real_alt": 2.7090001106262207,
        "heading_deg": 51.38,
        "odometry_pos_body_x": 2.096729516983032,
        "odometry_pos_body_y": 1.2362446784973145,
        "odometry_pos_body_z": -2.279825448989868,
        "odometry_vel_body_x": 0.9435372352600098,
        "odometry_vel_body_y": -0.009203560650348663,
        "odometry_vel_body_z": -0.1699894219636917,
        "odometry_ang_vel_body_roll": 0.061236750334501266,
        "odometry_ang_vel_body_pitch": -0.08427552133798599,
        "odometry_ang_vel_body_yaw": -0.10367687046527864,
        "velocity_north_m_s": 0.5999999642372131,
        "velocity_east_m_s": 0.7299999594688416,
        "velocity_down_m_s": -0.12999999523162842,
        "acceleration_forward": -0.6631360650062561,
        "acceleration_right": -5.894695281982422,
        "acceleration_down": -7.735370635986328,
        "battery_id": 0,
        "battery_voltage": 13.593000411987305,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 14,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 2534,
        "udp_total": 63,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 47
      },
      {
        "id": 37,
        "datetime": "21/02/2023 02:03:43",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xb20258e0>, <mavsdk.mission_raw.MissionItem object at 0xa476caf0>, <mavsdk.mission_raw.MissionItem object at 0xa476c0a0>, <mavsdk.mission_raw.MissionItem object at 0xa476cf28>]",
        "mission_pause": 0,
        "time_usec": 68463154,
        "odometry_pos_body": "PositionBody: [x_m: 2.302433967590332, y_m: 1.462813138961792, z_m: -2.3258979320526123]",
        "Command": "None",
        "navigation_system": "GPS",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 0,
        "alg_manual_gps_lon_start": 0,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 0,
        "alg_manual_gps_lon_next": 0,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 0,
        "alg_manual_y_now": 0,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 68538083,
        "flight_mode": "MISSION",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.47999998927116394,
        "current_climb_rate": 0.17963987588882446,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3064855,
        "gps_raw_lon_deg": 38.8900458,
        "gps_raw_abs_alt": 79.25,
        "gps_raw_velocity": 0.9799999594688416,
        "position_lat_deg": 47.3064858,
        "position_lon_deg": 38.8900462,
        "position_abs_alt": 81.52900695800781,
        "position_real_alt": 2.783000230789185,
        "heading_deg": 49.44,
        "odometry_pos_body_x": 2.3754098415374756,
        "odometry_pos_body_y": 1.5389553308486938,
        "odometry_pos_body_z": -2.344503402709961,
        "odometry_vel_body_x": 1.01275372505188,
        "odometry_vel_body_y": -0.029052680358290672,
        "odometry_vel_body_z": -0.25977566838264465,
        "odometry_ang_vel_body_roll": -0.3942742347717285,
        "odometry_ang_vel_body_pitch": 0.13295939564704895,
        "odometry_ang_vel_body_yaw": -0.15621259808540344,
        "velocity_north_m_s": 0.6899999976158142,
        "velocity_east_m_s": 0.75,
        "velocity_down_m_s": -0.19999998807907104,
        "acceleration_forward": -0.9199846386909484,
        "acceleration_right": 3.333974123001098,
        "acceleration_down": -11.675951957702637,
        "battery_id": 0,
        "battery_voltage": 13.593000411987305,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 3,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 2543,
        "udp_total": 63,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 47
      },
      {
        "id": 38,
        "datetime": "21/02/2023 02:03:43",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa58f2b68>, <mavsdk.mission_raw.MissionItem object at 0xa58f2af0>, <mavsdk.mission_raw.MissionItem object at 0xa58fc2e0>, <mavsdk.mission_raw.MissionItem object at 0xa58fc9d0>]",
        "mission_pause": 0,
        "time_usec": 68663004,
        "odometry_pos_body": "PositionBody: [x_m: 2.4507057666778564, y_m: 1.6153291463851929, z_m: -2.363734722137451]",
        "Command": "None",
        "navigation_system": "GPS",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 0,
        "alg_manual_gps_lon_start": 0,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 0,
        "alg_manual_gps_lon_next": 0,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 0,
        "alg_manual_y_now": 0,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 68707923,
        "flight_mode": "MISSION",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.4599999785423279,
        "current_climb_rate": 0.21161015331745148,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3064867,
        "gps_raw_lon_deg": 38.8900478,
        "gps_raw_abs_alt": 79.31000518798828,
        "gps_raw_velocity": 0.9899999499320984,
        "position_lat_deg": 47.3064869,
        "position_lon_deg": 38.890048,
        "position_abs_alt": 81.56300354003906,
        "position_real_alt": 2.817000150680542,
        "heading_deg": 48.26,
        "odometry_pos_body_x": 2.4960482120513916,
        "odometry_pos_body_y": 1.66110360622406,
        "odometry_pos_body_z": -2.3750882148742676,
        "odometry_vel_body_x": 1.0458303689956665,
        "odometry_vel_body_y": -0.017276743426918983,
        "odometry_vel_body_z": -0.21018987894058228,
        "odometry_ang_vel_body_roll": -0.37621450424194336,
        "odometry_ang_vel_body_pitch": -0.24713072180747983,
        "odometry_ang_vel_body_yaw": -0.1700371503829956,
        "velocity_north_m_s": 0.699999988079071,
        "velocity_east_m_s": 0.7799999713897705,
        "velocity_down_m_s": -0.19999998807907104,
        "acceleration_forward": 0.5938912034034729,
        "acceleration_right": 3.541660785675049,
        "acceleration_down": -9.624627113342283,
        "battery_id": 0,
        "battery_voltage": 13.593000411987305,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 2567,
        "udp_total": 63,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 47
      },
      {
        "id": 39,
        "datetime": "21/02/2023 02:03:43",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xb0ed1028>, <mavsdk.mission_raw.MissionItem object at 0xb203ee68>, <mavsdk.mission_raw.MissionItem object at 0xa58f2b68>, <mavsdk.mission_raw.MissionItem object at 0xa58f2fb8>]",
        "mission_pause": 0,
        "time_usec": 68862772,
        "odometry_pos_body": "PositionBody: [x_m: 2.6009368896484375, y_m: 1.7682790756225586, z_m: -2.3979718685150146]",
        "Command": "None",
        "navigation_system": "GPS",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 0,
        "alg_manual_gps_lon_start": 0,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 0,
        "alg_manual_gps_lon_next": 0,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 0,
        "alg_manual_y_now": 0,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 68907729,
        "flight_mode": "MISSION",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.4599999785423279,
        "current_climb_rate": 0.16656193137168884,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3064881,
        "gps_raw_lon_deg": 38.8900499,
        "gps_raw_abs_alt": 79.36000061035156,
        "gps_raw_velocity": 1.090000033378601,
        "position_lat_deg": 47.3064881,
        "position_lon_deg": 38.8900497,
        "position_abs_alt": 81.58900451660156,
        "position_real_alt": 2.8420002460479736,
        "heading_deg": 47.07,
        "odometry_pos_body_x": 2.6805830001831055,
        "odometry_pos_body_y": 1.8454689979553225,
        "odometry_pos_body_z": -2.4118170738220215,
        "odometry_vel_body_x": 1.063405156135559,
        "odometry_vel_body_y": -0.03703463822603226,
        "odometry_vel_body_z": -0.1559034138917923,
        "odometry_ang_vel_body_roll": -0.2932978570461273,
        "odometry_ang_vel_body_pitch": 0.18032896518707275,
        "odometry_ang_vel_body_yaw": -0.14528506994247437,
        "velocity_north_m_s": 0.7599999904632568,
        "velocity_east_m_s": 0.7400000095367432,
        "velocity_down_m_s": -0.119999997317791,
        "acceleration_forward": -1.2612459659576416,
        "acceleration_right": 0.3941054344177246,
        "acceleration_down": -9.954474449157717,
        "battery_id": 0,
        "battery_voltage": 13.593000411987305,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 24,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 2589,
        "udp_total": 63,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 47
      },
      {
        "id": 40,
        "datetime": "21/02/2023 02:03:43",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xb0ed12c8>, <mavsdk.mission_raw.MissionItem object at 0xb0ed15e0>, <mavsdk.mission_raw.MissionItem object at 0xb0ed12e0>, <mavsdk.mission_raw.MissionItem object at 0xa58fc0b8>]",
        "mission_pause": 0,
        "time_usec": 69162485,
        "odometry_pos_body": "PositionBody: [x_m: 2.84590220451355, y_m: 1.9917153120040894, z_m: -2.4413065910339355]",
        "Command": "None",
        "navigation_system": "GPS",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 0,
        "alg_manual_gps_lon_start": 0,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 0,
        "alg_manual_gps_lon_next": 0,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 0,
        "alg_manual_y_now": 0,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 69197454,
        "flight_mode": "MISSION",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.4599999785423279,
        "current_climb_rate": 0.1414482295513153,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3064896,
        "gps_raw_lon_deg": 38.8900519,
        "gps_raw_abs_alt": 79.42300415039062,
        "gps_raw_velocity": 1.079999923706055,
        "position_lat_deg": 47.3064904,
        "position_lon_deg": 38.8900526,
        "position_abs_alt": 81.63300323486328,
        "position_real_alt": 2.88700008392334,
        "heading_deg": 38.99,
        "odometry_pos_body_x": 2.8976070880889893,
        "odometry_pos_body_y": 2.0321218967437744,
        "odometry_pos_body_z": -2.450901746749878,
        "odometry_vel_body_x": 1.035631775856018,
        "odometry_vel_body_y": -0.011409718543291092,
        "odometry_vel_body_z": -0.14650756120681763,
        "odometry_ang_vel_body_roll": 0.7371178269386292,
        "odometry_ang_vel_body_pitch": 0.22225695848464969,
        "odometry_ang_vel_body_yaw": -0.7245296835899353,
        "velocity_north_m_s": 0.8100000023841858,
        "velocity_east_m_s": 0.6299999952316284,
        "velocity_down_m_s": -0.12999999523162842,
        "acceleration_forward": -2.077343225479126,
        "acceleration_right": -0.5465285778045654,
        "acceleration_down": -17.505155563354492,
        "battery_id": 0,
        "battery_voltage": 13.54800033569336,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 25,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 2590,
        "udp_total": 64,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 48
      },
      {
        "id": 41,
        "datetime": "21/02/2023 02:03:44",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa476ca00>, <mavsdk.mission_raw.MissionItem object at 0xa5910820>, <mavsdk.mission_raw.MissionItem object at 0xa5910280>, <mavsdk.mission_raw.MissionItem object at 0xa5910ad8>]",
        "mission_pause": 0,
        "time_usec": 69372286,
        "odometry_pos_body": "PositionBody: [x_m: 3.0315048694610596, y_m: 2.1240150928497314, z_m: -2.4697113037109375]",
        "Command": "None",
        "navigation_system": "GPS",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 0,
        "alg_manual_gps_lon_start": 0,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 0,
        "alg_manual_gps_lon_next": 0,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 0,
        "alg_manual_y_now": 0,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 69417243,
        "flight_mode": "MISSION",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.4699999988079071,
        "current_climb_rate": 0.061995234340429306,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306491099999995,
        "gps_raw_lon_deg": 38.8900537,
        "gps_raw_abs_alt": 79.45700073242188,
        "gps_raw_velocity": 1.059999942779541,
        "position_lat_deg": 47.3064922,
        "position_lon_deg": 38.8900543,
        "position_abs_alt": 81.65900421142578,
        "position_real_alt": 2.9120001792907715,
        "heading_deg": 28.23,
        "odometry_pos_body_x": 3.115370750427246,
        "odometry_pos_body_y": 2.169872999191284,
        "odometry_pos_body_z": -2.477813720703125,
        "odometry_vel_body_x": 1.0063387155532837,
        "odometry_vel_body_y": -0.00986174028366804,
        "odometry_vel_body_z": -0.03344713896512985,
        "odometry_ang_vel_body_roll": -0.4923093318939209,
        "odometry_ang_vel_body_pitch": -0.013096507638692856,
        "odometry_ang_vel_body_yaw": -0.9111323356628418,
        "velocity_north_m_s": 0.8999999761581421,
        "velocity_east_m_s": 0.4399999976158142,
        "velocity_down_m_s": -0.029999999329447743,
        "acceleration_forward": 0.10271161794662476,
        "acceleration_right": -2.2213852405548096,
        "acceleration_down": -6.876949310302734,
        "battery_id": 0,
        "battery_voltage": 13.54800033569336,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 1,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 2614,
        "udp_total": 64,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 48
      },
      {
        "id": 42,
        "datetime": "21/02/2023 02:03:44",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xb0ec24d8>, <mavsdk.mission_raw.MissionItem object at 0xa476c2e0>, <mavsdk.mission_raw.MissionItem object at 0xa476c6b8>, <mavsdk.mission_raw.MissionItem object at 0xa476cac0>]",
        "mission_pause": 0,
        "time_usec": 69562104,
        "odometry_pos_body": "PositionBody: [x_m: 3.2133400440216064, y_m: 2.2140276432037354, z_m: -2.484760046005249]",
        "Command": "None",
        "navigation_system": "GPS",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 0,
        "alg_manual_gps_lon_start": 0,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 0,
        "alg_manual_gps_lon_next": 0,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 0,
        "alg_manual_y_now": 0,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 69617055,
        "flight_mode": "MISSION",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.47999998927116394,
        "current_climb_rate": -0.003087211400270462,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3064927,
        "gps_raw_lon_deg": 38.8900552,
        "gps_raw_abs_alt": 79.46800231933594,
        "gps_raw_velocity": 1.0499999523162842,
        "position_lat_deg": 47.3064939,
        "position_lon_deg": 38.8900554,
        "position_abs_alt": 81.66900634765625,
        "position_real_alt": 2.9230000972747803,
        "heading_deg": 16.68,
        "odometry_pos_body_x": 3.314990997314453,
        "odometry_pos_body_y": 2.250108242034912,
        "odometry_pos_body_z": -2.487745523452759,
        "odometry_vel_body_x": 1.0489839315414429,
        "odometry_vel_body_y": -0.021249599754810333,
        "odometry_vel_body_z": -0.005393053404986858,
        "odometry_ang_vel_body_roll": 0.33901092410087585,
        "odometry_ang_vel_body_pitch": 0.15841937065124512,
        "odometry_ang_vel_body_yaw": -0.979773223400116,
        "velocity_north_m_s": 1.0099999904632568,
        "velocity_east_m_s": 0.23999999463558197,
        "velocity_down_m_s": 0.029999999329447743,
        "acceleration_forward": -0.6607157588005066,
        "acceleration_right": -0.39486584067344666,
        "acceleration_down": -9.49508285522461,
        "battery_id": 0,
        "battery_voltage": 13.54800033569336,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 0,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 2636,
        "udp_total": 64,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 48
      },
      {
        "id": 43,
        "datetime": "21/02/2023 02:03:44",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa590c3a0>, <mavsdk.mission_raw.MissionItem object at 0xb2018520>, <mavsdk.mission_raw.MissionItem object at 0xb2012730>, <mavsdk.mission_raw.MissionItem object at 0xa476ca48>]",
        "mission_pause": 0,
        "time_usec": 69821858,
        "odometry_pos_body": "PositionBody: [x_m: 3.493476629257202, y_m: 2.2897512912750244, z_m: -2.4879467487335205]",
        "Command": "None",
        "navigation_system": "GPS",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 0,
        "alg_manual_gps_lon_start": 0,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 0,
        "alg_manual_gps_lon_next": 0,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 0,
        "alg_manual_y_now": 0,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 69856825,
        "flight_mode": "MISSION",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.47999998927116394,
        "current_climb_rate": -0.03572804480791092,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3064946,
        "gps_raw_lon_deg": 38.8900562,
        "gps_raw_abs_alt": 79.48400115966797,
        "gps_raw_velocity": 1.090000033378601,
        "position_lat_deg": 47.3064962,
        "position_lon_deg": 38.890056099999995,
        "position_abs_alt": 81.67000579833984,
        "position_real_alt": 2.9230000972747803,
        "heading_deg": 4.2,
        "odometry_pos_body_x": 3.574347972869873,
        "odometry_pos_body_y": 2.299102783203125,
        "odometry_pos_body_z": -2.4871022701263428,
        "odometry_vel_body_x": 1.1032263040542605,
        "odometry_vel_body_y": -0.008082812651991844,
        "odometry_vel_body_z": -0.0043907854706048965,
        "odometry_ang_vel_body_roll": 0.7804906964302063,
        "odometry_ang_vel_body_pitch": 0.4309069216251374,
        "odometry_ang_vel_body_yaw": -0.8817547559738159,
        "velocity_north_m_s": 1.100000023841858,
        "velocity_east_m_s": 0.07000000029802322,
        "velocity_down_m_s": 0.029999999329447743,
        "acceleration_forward": -1.004552721977234,
        "acceleration_right": 1.2588300704956057,
        "acceleration_down": -13.688204765319824,
        "battery_id": 0,
        "battery_voltage": 13.54800033569336,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 0,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 2636,
        "udp_total": 64,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 48
      },
      {
        "id": 44,
        "datetime": "21/02/2023 02:03:44",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xb0ec2520>, <mavsdk.mission_raw.MissionItem object at 0xa5910838>, <mavsdk.mission_raw.MissionItem object at 0xa5910568>, <mavsdk.mission_raw.MissionItem object at 0xa59106d0>]",
        "mission_pause": 0,
        "time_usec": 69991697,
        "odometry_pos_body": "PositionBody: [x_m: 3.6965417861938477, y_m: 2.314789056777954, z_m: -2.48473858833313]",
        "Command": "None",
        "navigation_system": "GPS",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 0,
        "alg_manual_gps_lon_start": 0,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 0,
        "alg_manual_gps_lon_next": 0,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 0,
        "alg_manual_y_now": 0,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 70036654,
        "flight_mode": "MISSION",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.47999998927116394,
        "current_climb_rate": -0.023161210119724274,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3064966,
        "gps_raw_lon_deg": 38.8900571,
        "gps_raw_abs_alt": 79.48300170898438,
        "gps_raw_velocity": 1.149999976158142,
        "position_lat_deg": 47.306498399999995,
        "position_lon_deg": 38.8900564,
        "position_abs_alt": 81.66200256347656,
        "position_real_alt": 2.9160001277923584,
        "heading_deg": 356.39,
        "odometry_pos_body_x": 3.783302068710327,
        "odometry_pos_body_y": 2.3218955993652344,
        "odometry_pos_body_z": -2.481539249420166,
        "odometry_vel_body_x": 1.17344331741333,
        "odometry_vel_body_y": 0.07177566736936569,
        "odometry_vel_body_z": 0.025102917104959488,
        "odometry_ang_vel_body_roll": 0.10617044568061829,
        "odometry_ang_vel_body_pitch": 0.08685800433158875,
        "odometry_ang_vel_body_yaw": -0.7585501670837402,
        "velocity_north_m_s": 1.1699999570846558,
        "velocity_east_m_s": -0.009999999776482582,
        "velocity_down_m_s": 0.04999999701976776,
        "acceleration_forward": 0.6532430052757263,
        "acceleration_right": 0.9825051426887512,
        "acceleration_down": -9.186141967773438,
        "battery_id": 0,
        "battery_voltage": 13.54800033569336,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 2660,
        "udp_total": 65,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 49
      },
      {
        "id": 45,
        "datetime": "21/02/2023 02:03:44",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa5910a48>, <mavsdk.mission_raw.MissionItem object at 0xa59100d0>, <mavsdk.mission_raw.MissionItem object at 0xa59100a0>, <mavsdk.mission_raw.MissionItem object at 0xa5910628>]",
        "mission_pause": 0,
        "time_usec": 70221476,
        "odometry_pos_body": "PositionBody: [x_m: 3.985938549041748, y_m: 2.323737144470215, z_m: -2.4701485633850098]",
        "Command": "None",
        "navigation_system": "GPS",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 0,
        "alg_manual_gps_lon_start": 0,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 0,
        "alg_manual_gps_lon_next": 0,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 0,
        "alg_manual_y_now": 0,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 70256451,
        "flight_mode": "MISSION",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5099999904632568,
        "current_climb_rate": -0.11127207428216934,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3064988,
        "gps_raw_lon_deg": 38.8900571,
        "gps_raw_abs_alt": 79.4800033569336,
        "gps_raw_velocity": 1.2300000190734863,
        "position_lat_deg": 47.3065005,
        "position_lon_deg": 38.8900565,
        "position_abs_alt": 81.6500015258789,
        "position_real_alt": 2.9040000438690186,
        "heading_deg": 352.92,
        "odometry_pos_body_x": 4.03585147857666,
        "odometry_pos_body_y": 2.3222761154174805,
        "odometry_pos_body_z": -2.466742992401123,
        "odometry_vel_body_x": 1.163718581199646,
        "odometry_vel_body_y": 0.07656645029783249,
        "odometry_vel_body_z": 0.10771861672401428,
        "odometry_ang_vel_body_roll": -0.95498788356781,
        "odometry_ang_vel_body_pitch": 0.04175393283367157,
        "odometry_ang_vel_body_yaw": -0.20350952446460724,
        "velocity_north_m_s": 1.149999976158142,
        "velocity_east_m_s": -0.07000000029802322,
        "velocity_down_m_s": 0.119999997317791,
        "acceleration_forward": -0.5107839703559875,
        "acceleration_right": 1.6865403652191162,
        "acceleration_down": -16.402530670166016,
        "battery_id": 0,
        "battery_voltage": 13.54800033569336,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 2689,
        "udp_total": 65,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 49
      },
      {
        "id": 46,
        "datetime": "21/02/2023 02:03:45",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa5913370>, <mavsdk.mission_raw.MissionItem object at 0xa5913850>, <mavsdk.mission_raw.MissionItem object at 0xa5913a78>, <mavsdk.mission_raw.MissionItem object at 0xa5913580>]",
        "mission_pause": 0,
        "time_usec": 70471240,
        "odometry_pos_body": "PositionBody: [x_m: 4.293986797332764, y_m: 2.308882236480713, z_m: -2.4497127532958984]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.308882236480713,
        "alg_manual_y_now": 4.293986797332764,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "go 47.3066362 38.8900423 3.0 8",
        "alg_manual_inputs": "[0.0, 0.0, 1.0, 0.0]",
        "timestamp": 70526189,
        "flight_mode": "MISSION",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5299999713897705,
        "current_climb_rate": -0.16253377497196198,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306503199999995,
        "gps_raw_lon_deg": 38.890056699999995,
        "gps_raw_abs_alt": 79.46000671386719,
        "gps_raw_velocity": 1.1799999475479126,
        "position_lat_deg": 47.3065034,
        "position_lon_deg": 38.8900562,
        "position_abs_alt": 81.62800598144531,
        "position_real_alt": 2.882000207901001,
        "heading_deg": 353.01,
        "odometry_pos_body_x": 4.36417818069458,
        "odometry_pos_body_y": 2.302711248397827,
        "odometry_pos_body_z": -2.4442131519317627,
        "odometry_vel_body_x": 1.0436919927597046,
        "odometry_vel_body_y": 0.016190949827432632,
        "odometry_vel_body_z": 0.1991373598575592,
        "odometry_ang_vel_body_roll": 0.9110981225967408,
        "odometry_ang_vel_body_pitch": -0.08804658055305481,
        "odometry_ang_vel_body_yaw": 0.06851646304130554,
        "velocity_north_m_s": 1.0299999713897705,
        "velocity_east_m_s": -0.10999999940395357,
        "velocity_down_m_s": 0.17999999225139618,
        "acceleration_forward": 0.43380826711654663,
        "acceleration_right": -0.268039345741272,
        "acceleration_down": -11.282964706420898,
        "battery_id": 0,
        "battery_voltage": 13.54800033569336,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "INFO",
        "status_text": "Pilot took over position control using sticks",
        "tcp": 4,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 2689,
        "udp_total": 65,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 49
      },
      {
        "id": 47,
        "datetime": "21/02/2023 02:03:45",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xb20182b0>, <mavsdk.mission_raw.MissionItem object at 0xa59139e8>, <mavsdk.mission_raw.MissionItem object at 0xa5913988>, <mavsdk.mission_raw.MissionItem object at 0xa5913208>]",
        "mission_pause": 0,
        "time_usec": 70671052,
        "odometry_pos_body": "PositionBody: [x_m: 4.517232894897461, y_m: 2.2844929695129395, z_m: -2.4332163333892822]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.2901017665863037,
        "alg_manual_y_now": 4.47580623626709,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "go 47.3066362 38.8900423 3.0 8",
        "alg_manual_inputs": "[0.0, 0.0, 1.0, 0.0]",
        "timestamp": 70730995,
        "flight_mode": "MISSION",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.7199999690055847,
        "current_climb_rate": -0.015758128836750984,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065053,
        "gps_raw_lon_deg": 38.8900562,
        "gps_raw_abs_alt": 79.41600036621094,
        "gps_raw_velocity": 1.1699999570846558,
        "position_lat_deg": 47.3065062,
        "position_lon_deg": 38.8900556,
        "position_abs_alt": 81.61800384521484,
        "position_real_alt": 2.872000217437744,
        "heading_deg": 351.94,
        "odometry_pos_body_x": 4.638931751251221,
        "odometry_pos_body_y": 2.2609825134277344,
        "odometry_pos_body_z": -2.4346842765808105,
        "odometry_vel_body_x": 0.7990805506706238,
        "odometry_vel_body_y": -0.06568349897861481,
        "odometry_vel_body_z": 0.010715760290622711,
        "odometry_ang_vel_body_roll": -0.5283361673355103,
        "odometry_ang_vel_body_pitch": 0.1701647937297821,
        "odometry_ang_vel_body_yaw": -0.18814563751220703,
        "velocity_north_m_s": 0.7799999713897705,
        "velocity_east_m_s": -0.17000000178813934,
        "velocity_down_m_s": -0.03999999910593033,
        "acceleration_forward": -1.7118756771087646,
        "acceleration_right": -1.263298511505127,
        "acceleration_down": -10.517489433288574,
        "battery_id": 0,
        "battery_voltage": 13.54800033569336,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 2713,
        "udp_total": 65,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 49
      },
      {
        "id": 48,
        "datetime": "21/02/2023 02:03:45",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa476c028>, <mavsdk.mission_raw.MissionItem object at 0xa476c7a8>, <mavsdk.mission_raw.MissionItem object at 0xa476c8c8>, <mavsdk.mission_raw.MissionItem object at 0xa476c790>]",
        "mission_pause": 0,
        "time_usec": 70940799,
        "odometry_pos_body": "PositionBody: [x_m: 4.764946460723877, y_m: 2.2254271507263184, z_m: -2.4691669940948486]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 0,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.2254271507263184,
        "alg_manual_y_now": 4.764946460723877,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 0,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "go 47.3066362 38.8900423 3.0 8",
        "alg_manual_inputs": "[0.0, 0.0, 1.0, 0.0]",
        "timestamp": 70965773,
        "flight_mode": "MISSION",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.8499999642372131,
        "current_climb_rate": 0.5436544418334961,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306506899999995,
        "gps_raw_lon_deg": 38.890055499999995,
        "gps_raw_abs_alt": 79.40300750732422,
        "gps_raw_velocity": 0.9399999976158142,
        "position_lat_deg": 47.3065076,
        "position_lon_deg": 38.8900549,
        "position_abs_alt": 81.68600463867188,
        "position_real_alt": 2.940000057220459,
        "heading_deg": 350.19,
        "odometry_pos_body_x": 4.800724029541016,
        "odometry_pos_body_y": 2.210815191268921,
        "odometry_pos_body_z": -2.4922728538513184,
        "odometry_vel_body_x": 0.6366533637046814,
        "odometry_vel_body_y": -0.12163763493299484,
        "odometry_vel_body_z": -0.4859297275543213,
        "odometry_ang_vel_body_roll": -0.3694155216217041,
        "odometry_ang_vel_body_pitch": 0.013020380400121212,
        "odometry_ang_vel_body_yaw": -0.18090297281742096,
        "velocity_north_m_s": 0.5099999904632568,
        "velocity_east_m_s": -0.2299999892711639,
        "velocity_down_m_s": -0.5999999642372131,
        "acceleration_forward": -2.4240522384643555,
        "acceleration_right": -0.293642520904541,
        "acceleration_down": -14.1392822265625,
        "battery_id": 0,
        "battery_voltage": 13.54800033569336,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 1,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 2735,
        "udp_total": 65,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 49
      },
      {
        "id": 49,
        "datetime": "21/02/2023 02:03:45",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa5913028>, <mavsdk.mission_raw.MissionItem object at 0xa59134c0>, <mavsdk.mission_raw.MissionItem object at 0xa591ea18>, <mavsdk.mission_raw.MissionItem object at 0xa591e8b0>]",
        "mission_pause": 0,
        "time_usec": 71150593,
        "odometry_pos_body": "PositionBody: [x_m: 4.890460968017578, y_m: 2.1622354984283447, z_m: -2.608736991882324]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 1.0, 0.0]",
        "timestamp": 71140606,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.8700000047683716,
        "current_climb_rate": 1.014789581298828,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306508099999995,
        "gps_raw_lon_deg": 38.8900546,
        "gps_raw_abs_alt": 79.4590072631836,
        "gps_raw_velocity": 0.7599999904632568,
        "position_lat_deg": 47.306508699999995,
        "position_lon_deg": 38.890054,
        "position_abs_alt": 81.86000061035156,
        "position_real_alt": 3.114000082015991,
        "heading_deg": 348.13,
        "odometry_pos_body_x": 4.945810317993164,
        "odometry_pos_body_y": 2.1201095581054688,
        "odometry_pos_body_z": -2.72979736328125,
        "odometry_vel_body_x": 0.37089499831199646,
        "odometry_vel_body_y": -0.23605138063430783,
        "odometry_vel_body_z": -1.2083152532577517,
        "odometry_ang_vel_body_roll": -0.47792017459869385,
        "odometry_ang_vel_body_pitch": -0.2636795938014984,
        "odometry_ang_vel_body_yaw": -0.09591690450906754,
        "velocity_north_m_s": 0.2299999892711639,
        "velocity_east_m_s": -0.25,
        "velocity_down_m_s": -1.1899999380111694,
        "acceleration_forward": 0.8630272746086121,
        "acceleration_right": 2.080397605895996,
        "acceleration_down": -6.941202163696289,
        "battery_id": 0,
        "battery_voltage": 13.326001167297363,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 18,
        "udp": 3,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 2755,
        "udp_total": 68,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 50
      },
      {
        "id": 50,
        "datetime": "21/02/2023 02:03:46",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa591ea18>, <mavsdk.mission_raw.MissionItem object at 0xb2025718>, <mavsdk.mission_raw.MissionItem object at 0xb2024970>, <mavsdk.mission_raw.MissionItem object at 0xb2024910>]",
        "mission_pause": 0,
        "time_usec": 71480281,
        "odometry_pos_body": "PositionBody: [x_m: 4.99771785736084, y_m: 2.052443265914917, z_m: -2.9672627449035645]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.25]",
        "timestamp": 71525238,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.7699999809265137,
        "current_climb_rate": 1.6865302324295044,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065092,
        "gps_raw_lon_deg": 38.8900528,
        "gps_raw_abs_alt": 79.92900085449219,
        "gps_raw_velocity": 0.3499999940395355,
        "position_lat_deg": 47.306509299999995,
        "position_lon_deg": 38.8900527,
        "position_abs_alt": 82.21900177001953,
        "position_real_alt": 3.4730000495910645,
        "heading_deg": 345.2,
        "odometry_pos_body_x": 5.006308078765869,
        "odometry_pos_body_y": 2.0201148986816406,
        "odometry_pos_body_z": -3.1090378761291504,
        "odometry_vel_body_x": 0.1664351522922516,
        "odometry_vel_body_y": -0.2854173183441162,
        "odometry_vel_body_z": -1.8416986465454104,
        "odometry_ang_vel_body_roll": 0.1095099002122879,
        "odometry_ang_vel_body_pitch": -0.4308631122112274,
        "odometry_ang_vel_body_yaw": -0.14436674118041992,
        "velocity_north_m_s": 0,
        "velocity_east_m_s": -0.20999999344348907,
        "velocity_down_m_s": -1.8799999952316284,
        "acceleration_forward": 0.2773323953151703,
        "acceleration_right": -3.7866382598876953,
        "acceleration_down": -10.761112213134766,
        "battery_id": 0,
        "battery_voltage": 13.326001167297363,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 0,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 2759,
        "udp_total": 68,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 50
      },
      {
        "id": 51,
        "datetime": "21/02/2023 02:03:46",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa476c520>, <mavsdk.mission_raw.MissionItem object at 0xa476c160>, <mavsdk.mission_raw.MissionItem object at 0xa476cc28>, <mavsdk.mission_raw.MissionItem object at 0xa476ce20>]",
        "mission_pause": 0,
        "time_usec": 71710064,
        "odometry_pos_body": "PositionBody: [x_m: 5.008429527282715, y_m: 1.9819892644882202, z_m: -3.3199918270111084]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.25]",
        "timestamp": 71735042,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5899999737739563,
        "current_climb_rate": 2.0250723361968994,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306509299999995,
        "gps_raw_lon_deg": 38.890051799999995,
        "gps_raw_abs_alt": 80.3080062866211,
        "gps_raw_velocity": 0.3799999952316284,
        "position_lat_deg": 47.3065094,
        "position_lon_deg": 38.890051799999995,
        "position_abs_alt": 82.56700134277344,
        "position_real_alt": 3.821000099182129,
        "heading_deg": 343.59000000000003,
        "odometry_pos_body_x": 5.005004405975342,
        "odometry_pos_body_y": 1.9499255418777464,
        "odometry_pos_body_z": -3.50276517868042,
        "odometry_vel_body_x": 0.04908378794789314,
        "odometry_vel_body_y": -0.303070604801178,
        "odometry_vel_body_z": -1.9755984544754028,
        "odometry_ang_vel_body_roll": -0.7836835384368896,
        "odometry_ang_vel_body_pitch": -0.1882803589105606,
        "odometry_ang_vel_body_yaw": -0.0409407950937748,
        "velocity_north_m_s": -0.019999999552965164,
        "velocity_east_m_s": -0.14000000059604645,
        "velocity_down_m_s": -2.0199999809265137,
        "acceleration_forward": 1.7951509952545166,
        "acceleration_right": 3.2651968002319336,
        "acceleration_down": -10.662341117858888,
        "battery_id": 0,
        "battery_voltage": 13.326001167297363,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 0,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 2781,
        "udp_total": 68,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 50
      },
      {
        "id": 52,
        "datetime": "21/02/2023 02:03:46",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa5913100>, <mavsdk.mission_raw.MissionItem object at 0xa476cdf0>, <mavsdk.mission_raw.MissionItem object at 0xa476cd60>, <mavsdk.mission_raw.MissionItem object at 0xa476c988>]",
        "mission_pause": 0,
        "time_usec": 71949838,
        "odometry_pos_body": "PositionBody: [x_m: 5.002114295959473, y_m: 1.915718913078308, z_m: -3.718982458114624]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.25]",
        "timestamp": 72014773,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.4599999785423279,
        "current_climb_rate": 1.8100770711898804,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306509299999995,
        "gps_raw_lon_deg": 38.8900508,
        "gps_raw_abs_alt": 80.73500061035156,
        "gps_raw_velocity": 0.3700000047683716,
        "position_lat_deg": 47.306509299999995,
        "position_lon_deg": 38.8900508,
        "position_abs_alt": 83.00800323486328,
        "position_real_alt": 4.26200008392334,
        "heading_deg": 343.23,
        "odometry_pos_body_x": 5.002121448516846,
        "odometry_pos_body_y": 1.887791872024536,
        "odometry_pos_body_z": -3.910523653030395,
        "odometry_vel_body_x": 0.02528236992657185,
        "odometry_vel_body_y": -0.19651354849338531,
        "odometry_vel_body_z": -1.5467262268066406,
        "odometry_ang_vel_body_roll": 0.6998258233070374,
        "odometry_ang_vel_body_pitch": 0.0975809171795845,
        "odometry_ang_vel_body_yaw": 0.045667942613363266,
        "velocity_north_m_s": 0.009999999776482582,
        "velocity_east_m_s": -0.09999999403953552,
        "velocity_down_m_s": -1.6100000143051147,
        "acceleration_forward": -1.8214889764785769,
        "acceleration_right": -1.727304220199585,
        "acceleration_down": -2.272411584854126,
        "battery_id": 0,
        "battery_voltage": 13.326001167297363,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 25,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 2806,
        "udp_total": 69,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 51
      },
      {
        "id": 53,
        "datetime": "21/02/2023 02:03:47",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa5910df0>, <mavsdk.mission_raw.MissionItem object at 0xa591ed90>, <mavsdk.mission_raw.MissionItem object at 0xa591e448>, <mavsdk.mission_raw.MissionItem object at 0xa591ebc8>]",
        "mission_pause": 0,
        "time_usec": 72239559,
        "odometry_pos_body": "PositionBody: [x_m: 4.996479034423828, y_m: 1.8345274925231934, z_m: -4.116177558898926]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.25]",
        "timestamp": 72294507,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.38999998569488525,
        "current_climb_rate": 0.8935992121696472,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065091,
        "gps_raw_lon_deg": 38.8900493,
        "gps_raw_abs_alt": 81.50200653076172,
        "gps_raw_velocity": 0.25,
        "position_lat_deg": 47.306509299999995,
        "position_lon_deg": 38.8900496,
        "position_abs_alt": 83.4070053100586,
        "position_real_alt": 4.6610002517700195,
        "heading_deg": 345.2,
        "odometry_pos_body_x": 4.993500709533691,
        "odometry_pos_body_y": 1.7913984060287476,
        "odometry_pos_body_z": -4.2931365966796875,
        "odometry_vel_body_x": 0.068632572889328,
        "odometry_vel_body_y": -0.08146563172340393,
        "odometry_vel_body_z": -0.7205517292022705,
        "odometry_ang_vel_body_roll": -0.1187245100736618,
        "odometry_ang_vel_body_pitch": 0.13708771765232086,
        "odometry_ang_vel_body_yaw": 0.06499656289815903,
        "velocity_north_m_s": 0.04999999701976776,
        "velocity_east_m_s": -0.04999999701976776,
        "velocity_down_m_s": -0.8799999952316284,
        "acceleration_forward": 1.7177056074142456,
        "acceleration_right": -2.6948559284210205,
        "acceleration_down": -1.1463059186935425,
        "battery_id": 0,
        "battery_voltage": 13.326001167297363,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 1,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 2828,
        "udp_total": 69,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 51
      },
      {
        "id": 54,
        "datetime": "21/02/2023 02:03:47",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4775058>, <mavsdk.mission_raw.MissionItem object at 0xa4775970>, <mavsdk.mission_raw.MissionItem object at 0xa47750a0>, <mavsdk.mission_raw.MissionItem object at 0xa47758c8>]",
        "mission_pause": 0,
        "time_usec": 72609209,
        "odometry_pos_body": "PositionBody: [x_m: 5.001320838928223, y_m: 1.7411473989486694, z_m: -4.442381858825684]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.12]",
        "timestamp": 72614226,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.41999998688697815,
        "current_climb_rate": 0.43551763892173767,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065092,
        "gps_raw_lon_deg": 38.8900484,
        "gps_raw_abs_alt": 81.74500274658203,
        "gps_raw_velocity": 0.3700000047683716,
        "position_lat_deg": 47.306509299999995,
        "position_lon_deg": 38.8900487,
        "position_abs_alt": 83.6300048828125,
        "position_real_alt": 4.884000301361084,
        "heading_deg": 347.15000000000003,
        "odometry_pos_body_x": 5.006327152252197,
        "odometry_pos_body_y": 1.7273590564727783,
        "odometry_pos_body_z": -4.472194194793701,
        "odometry_vel_body_x": 0.09979419410228728,
        "odometry_vel_body_y": -0.014908907003700731,
        "odometry_vel_body_z": -0.22298023104667664,
        "odometry_ang_vel_body_roll": -0.1977226436138153,
        "odometry_ang_vel_body_pitch": -0.35198888182640076,
        "odometry_ang_vel_body_yaw": 0.15728279948234558,
        "velocity_north_m_s": 0.08999999612569809,
        "velocity_east_m_s": -0.019999999552965164,
        "velocity_down_m_s": -0.28999999165534973,
        "acceleration_forward": 0.4829462170600891,
        "acceleration_right": 1.274624943733215,
        "acceleration_down": -8.795470237731934,
        "battery_id": 0,
        "battery_voltage": 13.326001167297363,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 2852,
        "udp_total": 69,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 51
      },
      {
        "id": 55,
        "datetime": "21/02/2023 02:03:47",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa590caa8>, <mavsdk.mission_raw.MissionItem object at 0xa590ca78>, <mavsdk.mission_raw.MissionItem object at 0xa590c898>, <mavsdk.mission_raw.MissionItem object at 0xa590c7f0>]",
        "mission_pause": 0,
        "time_usec": 72873957,
        "odometry_pos_body": "PositionBody: [x_m: 5.023915767669678, y_m: 1.6930798292160034, z_m: -4.55309534072876]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.12]",
        "timestamp": 72948887,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5699999928474426,
        "current_climb_rate": 0.07245782017707825,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306509299999995,
        "gps_raw_lon_deg": 38.8900475,
        "gps_raw_abs_alt": 81.97300720214844,
        "gps_raw_velocity": 0.25999999046325684,
        "position_lat_deg": 47.3065096,
        "position_lon_deg": 38.8900478,
        "position_abs_alt": 83.79600524902344,
        "position_real_alt": 5.050000190734863,
        "heading_deg": 348.02,
        "odometry_pos_body_x": 5.037064552307129,
        "odometry_pos_body_y": 1.6700892448425293,
        "odometry_pos_body_z": -4.621222019195557,
        "odometry_vel_body_x": 0.08186269551515579,
        "odometry_vel_body_y": 0.1147567257285118,
        "odometry_vel_body_z": -0.11449076980352402,
        "odometry_ang_vel_body_roll": 0.4107783138751984,
        "odometry_ang_vel_body_pitch": 0.06358092278242111,
        "odometry_ang_vel_body_yaw": 0.02463148534297943,
        "velocity_north_m_s": 0.09999999403953552,
        "velocity_east_m_s": 0.09999999403953552,
        "velocity_down_m_s": -0.09999999403953552,
        "acceleration_forward": -1.119566798210144,
        "acceleration_right": -1.9832122325897217,
        "acceleration_down": -10.671222686767578,
        "battery_id": 0,
        "battery_voltage": 13.326001167297363,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 22,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 2873,
        "udp_total": 69,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 51
      },
      {
        "id": 56,
        "datetime": "21/02/2023 02:03:47",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa5913df0>, <mavsdk.mission_raw.MissionItem object at 0xa5913b08>, <mavsdk.mission_raw.MissionItem object at 0xa5913dc0>, <mavsdk.mission_raw.MissionItem object at 0xa5913550>]",
        "mission_pause": 0,
        "time_usec": 73173679,
        "odometry_pos_body": "PositionBody: [x_m: 5.047403812408447, y_m: 1.6602264642715454, z_m: -4.674249172210693]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.12]",
        "timestamp": 73233617,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5099999904632568,
        "current_climb_rate": 0.15255355834960938,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 2,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065094,
        "gps_raw_lon_deg": 38.8900473,
        "gps_raw_abs_alt": 82.00200653076172,
        "gps_raw_velocity": 0.14999999105930328,
        "position_lat_deg": 47.3065098,
        "position_lon_deg": 38.890047599999995,
        "position_abs_alt": 83.87600708007812,
        "position_real_alt": 5.129000186920166,
        "heading_deg": 347.96,
        "odometry_pos_body_x": 5.053123950958252,
        "odometry_pos_body_y": 1.6585248708724976,
        "odometry_pos_body_z": -4.700806617736816,
        "odometry_vel_body_x": 0.06806866824626923,
        "odometry_vel_body_y": 0.17374490201473236,
        "odometry_vel_body_z": -0.17465756833553314,
        "odometry_ang_vel_body_roll": 0.1462203711271286,
        "odometry_ang_vel_body_pitch": -0.11416834592819214,
        "odometry_ang_vel_body_yaw": -0.02003687620162964,
        "velocity_north_m_s": 0.09999999403953552,
        "velocity_east_m_s": 0.1599999964237213,
        "velocity_down_m_s": -0.1599999964237213,
        "acceleration_forward": 2.5315022468566895,
        "acceleration_right": 1.5472733974456787,
        "acceleration_down": -14.010019302368164,
        "battery_id": 0,
        "battery_voltage": 13.37700080871582,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 2874,
        "udp_total": 70,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 52
      },
      {
        "id": 57,
        "datetime": "21/02/2023 02:03:48",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4775a78>, <mavsdk.mission_raw.MissionItem object at 0xa5913ce8>, <mavsdk.mission_raw.MissionItem object at 0xa5913df0>, <mavsdk.mission_raw.MissionItem object at 0xa5913b50>]",
        "mission_pause": 0,
        "time_usec": 73343514,
        "odometry_pos_body": "PositionBody: [x_m: 5.066052436828613, y_m: 1.6560301780700684, z_m: -4.7446160316467285]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.12]",
        "timestamp": 73433429,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5099999904632568,
        "current_climb_rate": 0.03503572568297386,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065096,
        "gps_raw_lon_deg": 38.8900475,
        "gps_raw_abs_alt": 82.07400512695312,
        "gps_raw_velocity": 0.05999999865889549,
        "position_lat_deg": 47.30651,
        "position_lon_deg": 38.890047599999995,
        "position_abs_alt": 83.9520034790039,
        "position_real_alt": 5.206000328063965,
        "heading_deg": 347.75,
        "odometry_pos_body_x": 5.086688995361328,
        "odometry_pos_body_y": 1.6581727266311646,
        "odometry_pos_body_z": -4.783105850219727,
        "odometry_vel_body_x": 0.11379339545965196,
        "odometry_vel_body_y": 0.218217134475708,
        "odometry_vel_body_z": -0.02844220958650112,
        "odometry_ang_vel_body_roll": 0.4365716576576233,
        "odometry_ang_vel_body_pitch": 0.03908396512269974,
        "odometry_ang_vel_body_yaw": -0.011903427541255953,
        "velocity_north_m_s": 0.14000000059604645,
        "velocity_east_m_s": 0.1599999964237213,
        "velocity_down_m_s": -0.019999999552965164,
        "acceleration_forward": -0.31053727865219116,
        "acceleration_right": -2.192599058151245,
        "acceleration_down": -5.924076080322266,
        "battery_id": 0,
        "battery_voltage": 13.37700080871582,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 2898,
        "udp_total": 70,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 52
      },
      {
        "id": 58,
        "datetime": "21/02/2023 02:03:48",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4775508>, <mavsdk.mission_raw.MissionItem object at 0xa5910e20>, <mavsdk.mission_raw.MissionItem object at 0xa5910268>, <mavsdk.mission_raw.MissionItem object at 0xa5910130>]",
        "mission_pause": 0,
        "time_usec": 73713164,
        "odometry_pos_body": "PositionBody: [x_m: 5.109908103942871, y_m: 1.6639195680618286, z_m: -4.806347370147705]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.12]",
        "timestamp": 73653223,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5099999904632568,
        "current_climb_rate": -0.03988521546125412,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065098,
        "gps_raw_lon_deg": 38.890047599999995,
        "gps_raw_abs_alt": 82.10600280761719,
        "gps_raw_velocity": 0.119999997317791,
        "position_lat_deg": 47.3065103,
        "position_lon_deg": 38.8900477,
        "position_abs_alt": 83.98500061035156,
        "position_real_alt": 5.23900032043457,
        "heading_deg": 347.65000000000003,
        "odometry_pos_body_x": 5.133246421813965,
        "odometry_pos_body_y": 1.6671162843704224,
        "odometry_pos_body_z": -4.818880558013916,
        "odometry_vel_body_x": 0.14364920556545258,
        "odometry_vel_body_y": 0.22806499898433685,
        "odometry_vel_body_z": 0.0775677040219307,
        "odometry_ang_vel_body_roll": 0.4797537922859192,
        "odometry_ang_vel_body_pitch": 0.09784606844186784,
        "odometry_ang_vel_body_yaw": 0.03936196118593216,
        "velocity_north_m_s": 0.17999999225139618,
        "velocity_east_m_s": 0.17000000178813934,
        "velocity_down_m_s": 0.009999999776482582,
        "acceleration_forward": 0.9606339335441588,
        "acceleration_right": -2.111201047897339,
        "acceleration_down": -14.198938369750977,
        "battery_id": 0,
        "battery_voltage": 13.37700080871582,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 2920,
        "udp_total": 70,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 52
      },
      {
        "id": 59,
        "datetime": "21/02/2023 02:03:48",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa47779b8>, <mavsdk.mission_raw.MissionItem object at 0xa4777bb0>, <mavsdk.mission_raw.MissionItem object at 0xa47779d0>, <mavsdk.mission_raw.MissionItem object at 0xa47779e8>]",
        "mission_pause": 0,
        "time_usec": 74012879,
        "odometry_pos_body": "PositionBody: [x_m: 5.152876853942871, y_m: 1.6776574850082397, z_m: -4.821671009063721]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.12]",
        "timestamp": 73833049,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5199999809265137,
        "current_climb_rate": -0.11322229355573654,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065103,
        "gps_raw_lon_deg": 38.8900483,
        "gps_raw_abs_alt": 82.09600067138672,
        "gps_raw_velocity": 0.17000000178813934,
        "position_lat_deg": 47.306510499999995,
        "position_lon_deg": 38.8900478,
        "position_abs_alt": 84.00100708007812,
        "position_real_alt": 5.255000114440918,
        "heading_deg": 347.87,
        "odometry_pos_body_x": 5.173304080963135,
        "odometry_pos_body_y": 1.7001714706420898,
        "odometry_pos_body_z": -4.824222564697266,
        "odometry_vel_body_x": 0.109902024269104,
        "odometry_vel_body_y": 0.26264405250549316,
        "odometry_vel_body_z": 0.092913880944252,
        "odometry_ang_vel_body_roll": -0.3587600290775299,
        "odometry_ang_vel_body_pitch": 0.04911530017852783,
        "odometry_ang_vel_body_yaw": 0.052581414580345154,
        "velocity_north_m_s": 0.17999999225139618,
        "velocity_east_m_s": 0.1899999976158142,
        "velocity_down_m_s": 0.07999999821186066,
        "acceleration_forward": 0.6555282473564148,
        "acceleration_right": -0.09218670427799223,
        "acceleration_down": -15.043706893920898,
        "battery_id": 0,
        "battery_voltage": 13.37700080871582,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 2944,
        "udp_total": 71,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 53
      },
      {
        "id": 60,
        "datetime": "21/02/2023 02:03:49",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa591e5f8>, <mavsdk.mission_raw.MissionItem object at 0xa591ef28>, <mavsdk.mission_raw.MissionItem object at 0xa591ec10>, <mavsdk.mission_raw.MissionItem object at 0xa591eb80>]",
        "mission_pause": 0,
        "time_usec": 74282629,
        "odometry_pos_body": "PositionBody: [x_m: 5.1893110275268555, y_m: 1.7203906774520874, z_m: -4.82486629486084]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.12]",
        "timestamp": 73992898,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5,
        "current_climb_rate": -0.07188602536916733,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065109,
        "gps_raw_lon_deg": 38.890049,
        "gps_raw_abs_alt": 82.07100677490234,
        "gps_raw_velocity": 0.1899999976158142,
        "position_lat_deg": 47.3065107,
        "position_lon_deg": 38.8900479,
        "position_abs_alt": 84.0040054321289,
        "position_real_alt": 5.258000373840332,
        "heading_deg": 348.04,
        "odometry_pos_body_x": 5.209463596343994,
        "odometry_pos_body_y": 1.7377854585647583,
        "odometry_pos_body_z": -4.825437068939209,
        "odometry_vel_body_x": 0.13933564722537994,
        "odometry_vel_body_y": 0.2687796354293823,
        "odometry_vel_body_z": 0.07861337065696716,
        "odometry_ang_vel_body_roll": -0.3438072204589844,
        "odometry_ang_vel_body_pitch": 0.13958998024463654,
        "odometry_ang_vel_body_yaw": 0.030581124126911163,
        "velocity_north_m_s": 0.17000000178813934,
        "velocity_east_m_s": 0.2199999988079071,
        "velocity_down_m_s": 0.119999997317791,
        "acceleration_forward": -1.0422488451004028,
        "acceleration_right": 1.3277485370635986,
        "acceleration_down": -10.797160148620604,
        "battery_id": 0,
        "battery_voltage": 13.37700080871582,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 24,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 2966,
        "udp_total": 71,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 53
      },
      {
        "id": 61,
        "datetime": "21/02/2023 02:03:49",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xb2018460>, <mavsdk.mission_raw.MissionItem object at 0xb20184c0>, <mavsdk.mission_raw.MissionItem object at 0xa4777fb8>, <mavsdk.mission_raw.MissionItem object at 0xa591ea90>]",
        "mission_pause": 0,
        "time_usec": 74642300,
        "odometry_pos_body": "PositionBody: [x_m: 5.243685722351074, y_m: 1.7630831003189087, z_m: -4.832568645477295]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.12]",
        "timestamp": 74172730,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.4899999797344208,
        "current_climb_rate": -0.043846871703863144,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065112,
        "gps_raw_lon_deg": 38.890049399999995,
        "gps_raw_abs_alt": 82.05500030517578,
        "gps_raw_velocity": 0.20999999344348907,
        "position_lat_deg": 47.3065109,
        "position_lon_deg": 38.8900483,
        "position_abs_alt": 84.00700378417969,
        "position_real_alt": 5.261000156402588,
        "heading_deg": 348.07,
        "odometry_pos_body_x": 5.262405872344971,
        "odometry_pos_body_y": 1.7752279043197632,
        "odometry_pos_body_z": -4.83435583114624,
        "odometry_vel_body_x": 0.18376551568508148,
        "odometry_vel_body_y": 0.21584707498550415,
        "odometry_vel_body_z": 0.059596240520477295,
        "odometry_ang_vel_body_roll": 0.27147382497787476,
        "odometry_ang_vel_body_pitch": 0.1597847044467926,
        "odometry_ang_vel_body_yaw": 0.12228262424468994,
        "velocity_north_m_s": 0.1599999964237213,
        "velocity_east_m_s": 0.2299999892711639,
        "velocity_down_m_s": 0.08999999612569809,
        "acceleration_forward": 0.24187543988227844,
        "acceleration_right": 1.211215615272522,
        "acceleration_down": -14.725712776184082,
        "battery_id": 0,
        "battery_voltage": 13.37700080871582,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 2990,
        "udp_total": 71,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 53
      },
      {
        "id": 62,
        "datetime": "21/02/2023 02:03:49",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa59102c8>, <mavsdk.mission_raw.MissionItem object at 0xa5910ef8>, <mavsdk.mission_raw.MissionItem object at 0xa59100b8>, <mavsdk.mission_raw.MissionItem object at 0xa5910748>]",
        "mission_pause": 0,
        "time_usec": 74912029,
        "odometry_pos_body": "PositionBody: [x_m: 5.29568338394165, y_m: 1.794006109237671, z_m: -4.830805778503418]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.12]",
        "timestamp": 74477448,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5,
        "current_climb_rate": -0.08858150243759155,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065115,
        "gps_raw_lon_deg": 38.8900497,
        "gps_raw_abs_alt": 82.04600524902344,
        "gps_raw_velocity": 0.19999998807907104,
        "position_lat_deg": 47.3065113,
        "position_lon_deg": 38.8900489,
        "position_abs_alt": 84.01200103759766,
        "position_real_alt": 5.266000270843506,
        "heading_deg": 348.76,
        "odometry_pos_body_x": 5.313054084777832,
        "odometry_pos_body_y": 1.8032959699630735,
        "odometry_pos_body_z": -4.826181411743164,
        "odometry_vel_body_x": 0.19884781539440155,
        "odometry_vel_body_y": 0.1679656207561493,
        "odometry_vel_body_z": 0.09647487848997116,
        "odometry_ang_vel_body_roll": 0.0652688592672348,
        "odometry_ang_vel_body_pitch": -0.12803860008716583,
        "odometry_ang_vel_body_yaw": 0.11808260530233385,
        "velocity_north_m_s": 0.1899999976158142,
        "velocity_east_m_s": 0.19999998807907104,
        "velocity_down_m_s": 0.04999999701976776,
        "acceleration_forward": -0.4068759083747864,
        "acceleration_right": 1.2985481023788452,
        "acceleration_down": -7.661637783050537,
        "battery_id": 0,
        "battery_voltage": 13.37700080871582,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 1,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 3012,
        "udp_total": 72,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 54
      },
      {
        "id": 63,
        "datetime": "21/02/2023 02:03:49",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4777d18>, <mavsdk.mission_raw.MissionItem object at 0xa4777790>, <mavsdk.mission_raw.MissionItem object at 0xa4777fd0>, <mavsdk.mission_raw.MissionItem object at 0xa47777d8>]",
        "mission_pause": 0,
        "time_usec": 75211748,
        "odometry_pos_body": "PositionBody: [x_m: 5.3448100090026855, y_m: 1.8094764947891235, z_m: -4.818747520446777]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.12]",
        "timestamp": 74872069,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5099999904632568,
        "current_climb_rate": -0.13441023230552673,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065121,
        "gps_raw_lon_deg": 38.8900499,
        "gps_raw_abs_alt": 82.0040054321289,
        "gps_raw_velocity": 0.2299999892711639,
        "position_lat_deg": 47.306512,
        "position_lon_deg": 38.8900495,
        "position_abs_alt": 84.01300048828125,
        "position_real_alt": 5.266000270843506,
        "heading_deg": 350.57,
        "odometry_pos_body_x": 5.362570762634277,
        "odometry_pos_body_y": 1.811130404472351,
        "odometry_pos_body_z": -4.8094987869262695,
        "odometry_vel_body_x": 0.12135320901870728,
        "odometry_vel_body_y": 0.07716073840856552,
        "odometry_vel_body_z": 0.15754051506519318,
        "odometry_ang_vel_body_roll": 0.23841868340969088,
        "odometry_ang_vel_body_pitch": 0.015710769221186638,
        "odometry_ang_vel_body_yaw": 0.17870934307575226,
        "velocity_north_m_s": 0.2299999892711639,
        "velocity_east_m_s": 0.12999999523162842,
        "velocity_down_m_s": 0.08999999612569809,
        "acceleration_forward": 2.560169219970703,
        "acceleration_right": -0.23004913330078125,
        "acceleration_down": -4.640233516693115,
        "battery_id": 0,
        "battery_voltage": 13.416000366210938,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 15,
        "udp": 2,
        "arp": 0,
        "icmp": 0,
        "other": 2,
        "tcp_total": 3028,
        "udp_total": 73,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 55
      },
      {
        "id": 64,
        "datetime": "21/02/2023 02:03:50",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xb5df15f8>, <mavsdk.mission_raw.MissionItem object at 0xb5df1598>, <mavsdk.mission_raw.MissionItem object at 0xa590c550>, <mavsdk.mission_raw.MissionItem object at 0xa590c430>]",
        "mission_pause": 0,
        "time_usec": 75541435,
        "odometry_pos_body": "PositionBody: [x_m: 5.380346775054932, y_m: 1.8059169054031372, z_m: -4.784453868865967]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.12]",
        "timestamp": 75211748,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5399999618530273,
        "current_climb_rate": -0.2267388254404068,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306512399999995,
        "gps_raw_lon_deg": 38.8900498,
        "gps_raw_abs_alt": 81.9530029296875,
        "gps_raw_velocity": 0.119999997317791,
        "position_lat_deg": 47.3065125,
        "position_lon_deg": 38.8900497,
        "position_abs_alt": 83.99500274658203,
        "position_real_alt": 5.248000144958496,
        "heading_deg": 353.67,
        "odometry_pos_body_x": 5.372227191925049,
        "odometry_pos_body_y": 1.79621422290802,
        "odometry_pos_body_z": -4.765820026397705,
        "odometry_vel_body_x": -0.14739102125167847,
        "odometry_vel_body_y": -0.014755398966372011,
        "odometry_vel_body_z": 0.20879878103733063,
        "odometry_ang_vel_body_roll": 0.3791244924068451,
        "odometry_ang_vel_body_pitch": 0.09030112624168396,
        "odometry_ang_vel_body_yaw": 0.17166027426719666,
        "velocity_north_m_s": 0.14000000059604645,
        "velocity_east_m_s": 0.04999999701976776,
        "velocity_down_m_s": 0.14000000059604645,
        "acceleration_forward": -0.5795537829399109,
        "acceleration_right": 1.4476430416107178,
        "acceleration_down": -8.41922378540039,
        "battery_id": 0,
        "battery_voltage": 13.416000366210938,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 1,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 3058,
        "udp_total": 73,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 55
      },
      {
        "id": 65,
        "datetime": "21/02/2023 02:03:50",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa47750b8>, <mavsdk.mission_raw.MissionItem object at 0xa4775c10>, <mavsdk.mission_raw.MissionItem object at 0xa4775d78>, <mavsdk.mission_raw.MissionItem object at 0xa4775e68>]",
        "mission_pause": 0,
        "time_usec": 75911087,
        "odometry_pos_body": "PositionBody: [x_m: 5.343321323394775, y_m: 1.778649091720581, z_m: -4.745454788208008]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.12]",
        "timestamp": 75511463,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.550000011920929,
        "current_climb_rate": -0.23777562379837036,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065121,
        "gps_raw_lon_deg": 38.8900496,
        "gps_raw_abs_alt": 81.91000366210938,
        "gps_raw_velocity": 0.1599999964237213,
        "position_lat_deg": 47.3065127,
        "position_lon_deg": 38.8900496,
        "position_abs_alt": 83.96500396728516,
        "position_real_alt": 5.218000411987305,
        "heading_deg": 356.61,
        "odometry_pos_body_x": 5.31723165512085,
        "odometry_pos_body_y": 1.7635526657104492,
        "odometry_pos_body_z": -4.735438823699951,
        "odometry_vel_body_x": -0.3347753882408142,
        "odometry_vel_body_y": -0.06484347581863403,
        "odometry_vel_body_z": 0.21152132749557495,
        "odometry_ang_vel_body_roll": -0.09056121855974196,
        "odometry_ang_vel_body_pitch": -0.5112288594245911,
        "odometry_ang_vel_body_yaw": 0.01751132495701313,
        "velocity_north_m_s": -0.03999999910593033,
        "velocity_east_m_s": 0.009999999776482582,
        "velocity_down_m_s": 0.2299999892711639,
        "acceleration_forward": 1.83638858795166,
        "acceleration_right": 3.9835596084594727,
        "acceleration_down": -6.639975070953369,
        "battery_id": 0,
        "battery_voltage": 13.416000366210938,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 1,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 3058,
        "udp_total": 73,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 56
      },
      {
        "id": 66,
        "datetime": "21/02/2023 02:03:50",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xb2025f28>, <mavsdk.mission_raw.MissionItem object at 0xb203e0d0>, <mavsdk.mission_raw.MissionItem object at 0xa5913580>, <mavsdk.mission_raw.MissionItem object at 0xa591e670>]",
        "mission_pause": 0,
        "time_usec": 76180834,
        "odometry_pos_body": "PositionBody: [x_m: 5.264278888702393, y_m: 1.7354518175125122, z_m: -4.720843315124512]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.12]",
        "timestamp": 75831162,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5399999618530273,
        "current_climb_rate": -0.19967003166675568,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306511699999994,
        "gps_raw_lon_deg": 38.8900492,
        "gps_raw_abs_alt": 81.84200286865234,
        "gps_raw_velocity": 0.25,
        "position_lat_deg": 47.3065125,
        "position_lon_deg": 38.8900493,
        "position_abs_alt": 83.92900085449219,
        "position_real_alt": 5.182000160217285,
        "heading_deg": 358.44,
        "odometry_pos_body_x": 5.23430871963501,
        "odometry_pos_body_y": 1.7213213443756104,
        "odometry_pos_body_z": -4.7129950523376465,
        "odometry_vel_body_x": -0.3939772844314575,
        "odometry_vel_body_y": -0.08977163583040237,
        "odometry_vel_body_z": 0.14669369161128998,
        "odometry_ang_vel_body_roll": 0.34667208790779114,
        "odometry_ang_vel_body_pitch": -0.21188929677009585,
        "odometry_ang_vel_body_yaw": -0.0398268923163414,
        "velocity_north_m_s": -0.2800000011920929,
        "velocity_east_m_s": -0.05999999865889549,
        "velocity_down_m_s": 0.23999999463558197,
        "acceleration_forward": -0.15547677874565125,
        "acceleration_right": 0.8290976881980896,
        "acceleration_down": -5.7846879959106445,
        "battery_id": 0,
        "battery_voltage": 13.416000366210938,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 1,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 3083,
        "udp_total": 76,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 57
      },
      {
        "id": 67,
        "datetime": "21/02/2023 02:03:51",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa590c688>, <mavsdk.mission_raw.MissionItem object at 0xb0ed1370>, <mavsdk.mission_raw.MissionItem object at 0xa476c3d0>, <mavsdk.mission_raw.MissionItem object at 0xa476cc10>]",
        "mission_pause": 0,
        "time_usec": 76440587,
        "odometry_pos_body": "PositionBody: [x_m: 5.173882484436035, y_m: 1.6888904571533203, z_m: -4.709414005279541]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.12]",
        "timestamp": 76230787,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5199999809265137,
        "current_climb_rate": -0.05207503959536552,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065102,
        "gps_raw_lon_deg": 38.8900481,
        "gps_raw_abs_alt": 81.7560043334961,
        "gps_raw_velocity": 0.5,
        "position_lat_deg": 47.3065113,
        "position_lon_deg": 38.8900484,
        "position_abs_alt": 83.89300537109375,
        "position_real_alt": 5.147000312805176,
        "heading_deg": 358.15000000000003,
        "odometry_pos_body_x": 5.1119160652160645,
        "odometry_pos_body_y": 1.6574437618255615,
        "odometry_pos_body_z": -4.718292236328125,
        "odometry_vel_body_x": -0.3171005845069885,
        "odometry_vel_body_y": -0.07472538203001022,
        "odometry_vel_body_z": 0.0608646459877491,
        "odometry_ang_vel_body_roll": -0.17928828299045563,
        "odometry_ang_vel_body_pitch": 0.4550311863422394,
        "odometry_ang_vel_body_yaw": 0.09576613456010818,
        "velocity_north_m_s": -0.38999998569488525,
        "velocity_east_m_s": -0.07999999821186066,
        "velocity_down_m_s": 0.12999999523162842,
        "acceleration_forward": 1.4135311841964722,
        "acceleration_right": -2.5118024349212646,
        "acceleration_down": -8.872895240783691,
        "battery_id": 0,
        "battery_voltage": 13.416000366210938,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 1,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 3105,
        "udp_total": 76,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 57
      },
      {
        "id": 68,
        "datetime": "21/02/2023 02:03:51",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa58fcbb0>, <mavsdk.mission_raw.MissionItem object at 0xa59109e8>, <mavsdk.mission_raw.MissionItem object at 0xa590c4f0>, <mavsdk.mission_raw.MissionItem object at 0xa590c310>]",
        "mission_pause": 0,
        "time_usec": 76740305,
        "odometry_pos_body": "PositionBody: [x_m: 5.0646867752075195, y_m: 1.6356877088546753, z_m: -4.734134674072266]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.12]",
        "timestamp": 76590452,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5199999809265137,
        "current_climb_rate": -0.11640089005231856,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065089,
        "gps_raw_lon_deg": 38.8900472,
        "gps_raw_abs_alt": 81.72000122070312,
        "gps_raw_velocity": 0.38999998569488525,
        "position_lat_deg": 47.3065102,
        "position_lon_deg": 38.8900475,
        "position_abs_alt": 83.9070053100586,
        "position_real_alt": 5.1610002517700195,
        "heading_deg": 359.12,
        "odometry_pos_body_x": 5.005117893218994,
        "odometry_pos_body_y": 1.608306646347046,
        "odometry_pos_body_z": -4.751300811767578,
        "odometry_vel_body_x": -0.3001236319541931,
        "odometry_vel_body_y": -0.04177483543753624,
        "odometry_vel_body_z": 0.0994047224521637,
        "odometry_ang_vel_body_roll": -0.21821211278438568,
        "odometry_ang_vel_body_pitch": -0.10131212323904036,
        "odometry_ang_vel_body_yaw": 0.16190555691719055,
        "velocity_north_m_s": -0.29999998211860657,
        "velocity_east_m_s": -0.04999999701976776,
        "velocity_down_m_s": 0.07000000029802322,
        "acceleration_forward": 0.9257107377052308,
        "acceleration_right": 1.648258090019226,
        "acceleration_down": -12.676202774047852,
        "battery_id": 0,
        "battery_voltage": 13.416000366210938,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 3129,
        "udp_total": 76,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 57
      },
      {
        "id": 69,
        "datetime": "21/02/2023 02:03:51",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa59101d8>, <mavsdk.mission_raw.MissionItem object at 0xa59109e8>, <mavsdk.mission_raw.MissionItem object at 0xa4777778>, <mavsdk.mission_raw.MissionItem object at 0xa4777e38>]",
        "mission_pause": 0,
        "time_usec": 77079986,
        "odometry_pos_body": "PositionBody: [x_m: 4.941165924072266, y_m: 1.5885169506072998, z_m: -4.768340587615967]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 77015049,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5199999809265137,
        "current_climb_rate": -0.1584385484457016,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065084,
        "gps_raw_lon_deg": 38.8900469,
        "gps_raw_abs_alt": 81.68000030517578,
        "gps_raw_velocity": 0.3100000023841858,
        "position_lat_deg": 47.306508699999995,
        "position_lon_deg": 38.8900467,
        "position_abs_alt": 83.9520034790039,
        "position_real_alt": 5.206000328063965,
        "heading_deg": 1.57,
        "odometry_pos_body_x": 4.88973331451416,
        "odometry_pos_body_y": 1.5757837295532229,
        "odometry_pos_body_z": -4.7755446434021,
        "odometry_vel_body_x": -0.2591169774532318,
        "odometry_vel_body_y": 0.055425312370061874,
        "odometry_vel_body_z": 0.16034972667694092,
        "odometry_ang_vel_body_roll": 0.7923041582107544,
        "odometry_ang_vel_body_pitch": 0.16171212494373322,
        "odometry_ang_vel_body_yaw": 0.2553400993347168,
        "velocity_north_m_s": -0.2800000011920929,
        "velocity_east_m_s": 0,
        "velocity_down_m_s": 0.12999999523162842,
        "acceleration_forward": 1.0897916555404663,
        "acceleration_right": -2.3077409267425537,
        "acceleration_down": -9.169148445129396,
        "battery_id": 0,
        "battery_voltage": 13.371000289916992,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 24,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 3151,
        "udp_total": 77,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 58
      },
      {
        "id": 70,
        "datetime": "21/02/2023 02:03:52",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4775028>, <mavsdk.mission_raw.MissionItem object at 0xa4775910>, <mavsdk.mission_raw.MissionItem object at 0xa47750e8>, <mavsdk.mission_raw.MissionItem object at 0xa4775628>]",
        "mission_pause": 0,
        "time_usec": 77409675,
        "odometry_pos_body": "PositionBody: [x_m: 4.841799259185791, y_m: 1.573709487915039, z_m: -4.773637771606445]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 77414670,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5299999713897705,
        "current_climb_rate": -0.2052263468503952,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065072,
        "gps_raw_lon_deg": 38.8900466,
        "gps_raw_abs_alt": 81.58500671386719,
        "gps_raw_velocity": 0.3100000023841858,
        "position_lat_deg": 47.3065078,
        "position_lon_deg": 38.8900466,
        "position_abs_alt": 83.9520034790039,
        "position_real_alt": 5.206000328063965,
        "heading_deg": 6.69,
        "odometry_pos_body_x": 4.81799840927124,
        "odometry_pos_body_y": 1.577495813369751,
        "odometry_pos_body_z": -4.767482280731201,
        "odometry_vel_body_x": -0.13956014811992645,
        "odometry_vel_body_y": 0.1639542579650879,
        "odometry_vel_body_z": 0.1891063302755356,
        "odometry_ang_vel_body_roll": -0.393982470035553,
        "odometry_ang_vel_body_pitch": -0.06259971112012863,
        "odometry_ang_vel_body_yaw": 0.3136371672153473,
        "velocity_north_m_s": -0.17999999225139618,
        "velocity_east_m_s": 0.08999999612569809,
        "velocity_down_m_s": 0.1899999976158142,
        "acceleration_forward": -0.915863573551178,
        "acceleration_right": -0.8544469475746155,
        "acceleration_down": -10.396016120910645,
        "battery_id": 0,
        "battery_voltage": 13.371000289916992,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 3175,
        "udp_total": 77,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 58
      },
      {
        "id": 71,
        "datetime": "21/02/2023 02:03:52",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa59104f0>, <mavsdk.mission_raw.MissionItem object at 0xa591e028>, <mavsdk.mission_raw.MissionItem object at 0xa4775eb0>, <mavsdk.mission_raw.MissionItem object at 0xa4775ad8>]",
        "mission_pause": 0,
        "time_usec": 77669516,
        "odometry_pos_body": "PositionBody: [x_m: 4.782938003540039, y_m: 1.5850971937179565, z_m: -4.754738807678223]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 77689414,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5399999618530273,
        "current_climb_rate": -0.21607892215251925,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306507,
        "gps_raw_lon_deg": 38.8900466,
        "gps_raw_abs_alt": 81.5260009765625,
        "gps_raw_velocity": 0.12999999523162842,
        "position_lat_deg": 47.3065072,
        "position_lon_deg": 38.8900467,
        "position_abs_alt": 83.9280014038086,
        "position_real_alt": 5.181000232696533,
        "heading_deg": 13.37,
        "odometry_pos_body_x": 4.763738632202148,
        "odometry_pos_body_y": 1.5915319919586182,
        "odometry_pos_body_z": -4.743881702423096,
        "odometry_vel_body_x": -0.01874709129333496,
        "odometry_vel_body_y": 0.1474030464887619,
        "odometry_vel_body_z": 0.20695599913597107,
        "odometry_ang_vel_body_roll": 0.13575872778892517,
        "odometry_ang_vel_body_pitch": 0.14325375854969025,
        "odometry_ang_vel_body_yaw": 0.3908984661102295,
        "velocity_north_m_s": -0.07000000029802322,
        "velocity_east_m_s": 0.119999997317791,
        "velocity_down_m_s": 0.2199999988079071,
        "acceleration_forward": -0.0531802661716938,
        "acceleration_right": -0.8684366345405579,
        "acceleration_down": -3.777787923812866,
        "battery_id": 0,
        "battery_voltage": 13.371000289916992,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 0,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 3197,
        "udp_total": 77,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 58
      },
      {
        "id": 72,
        "datetime": "21/02/2023 02:03:52",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa58fc748>, <mavsdk.mission_raw.MissionItem object at 0xa58fca78>, <mavsdk.mission_raw.MissionItem object at 0xa58fcfd0>, <mavsdk.mission_raw.MissionItem object at 0xa4777850>]",
        "mission_pause": 0,
        "time_usec": 77984134,
        "odometry_pos_body": "PositionBody: [x_m: 4.746464729309082, y_m: 1.5979299545288086, z_m: -4.736941337585449]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 78054069,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5399999618530273,
        "current_climb_rate": -0.2022155076265335,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065066,
        "gps_raw_lon_deg": 38.8900469,
        "gps_raw_abs_alt": 81.41800689697266,
        "gps_raw_velocity": 0.09999999403953552,
        "position_lat_deg": 47.306507,
        "position_lon_deg": 38.8900469,
        "position_abs_alt": 83.91700744628906,
        "position_real_alt": 5.170000076293945,
        "heading_deg": 19.66,
        "odometry_pos_body_x": 4.737717151641846,
        "odometry_pos_body_y": 1.5995608568191528,
        "odometry_pos_body_z": -4.732973575592041,
        "odometry_vel_body_x": 0.031050225719809532,
        "odometry_vel_body_y": 0.09511329233646391,
        "odometry_vel_body_z": 0.19717004895210263,
        "odometry_ang_vel_body_roll": 0.5227334499359131,
        "odometry_ang_vel_body_pitch": -0.05487342178821564,
        "odometry_ang_vel_body_yaw": 0.2550225555896759,
        "velocity_north_m_s": 0,
        "velocity_east_m_s": 0.04999999701976776,
        "velocity_down_m_s": 0.19999998807907104,
        "acceleration_forward": 1.7383917570114136,
        "acceleration_right": -3.7587270736694336,
        "acceleration_down": -7.029090404510498,
        "battery_id": 0,
        "battery_voltage": 13.371000289916992,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 21,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 3221,
        "udp_total": 78,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 59
      },
      {
        "id": 73,
        "datetime": "21/02/2023 02:03:53",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa590c8e0>, <mavsdk.mission_raw.MissionItem object at 0xa590c040>, <mavsdk.mission_raw.MissionItem object at 0xa591e898>, <mavsdk.mission_raw.MissionItem object at 0xa591ebc8>]",
        "mission_pause": 0,
        "time_usec": 78313827,
        "odometry_pos_body": "PositionBody: [x_m: 4.7300896644592285, y_m: 1.6003626585006714, z_m: -4.710089206695557]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 78388755,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5600000023841858,
        "current_climb_rate": -0.2456142455339432,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065066,
        "gps_raw_lon_deg": 38.890047,
        "gps_raw_abs_alt": 81.35900115966797,
        "gps_raw_velocity": 0.029999999329447743,
        "position_lat_deg": 47.306506899999995,
        "position_lon_deg": 38.8900469,
        "position_abs_alt": 83.8810043334961,
        "position_real_alt": 5.135000228881836,
        "heading_deg": 24.94,
        "odometry_pos_body_x": 4.7280683517456055,
        "odometry_pos_body_y": 1.5991113185882568,
        "odometry_pos_body_z": -4.698963165283203,
        "odometry_vel_body_x": 0.01974906586110592,
        "odometry_vel_body_y": 0.03109157830476761,
        "odometry_vel_body_z": 0.21048963069915771,
        "odometry_ang_vel_body_roll": 0.5721738338470459,
        "odometry_ang_vel_body_pitch": 0.10683289915323256,
        "odometry_ang_vel_body_yaw": 0.2971038818359375,
        "velocity_north_m_s": 0,
        "velocity_east_m_s": 0.009999999776482582,
        "velocity_down_m_s": 0.20999999344348907,
        "acceleration_forward": -0.8922894597053528,
        "acceleration_right": -0.4735279977321625,
        "acceleration_down": -6.370486259460449,
        "battery_id": 0,
        "battery_voltage": 13.371000289916992,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 3221,
        "udp_total": 78,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 59
      },
      {
        "id": 74,
        "datetime": "21/02/2023 02:03:53",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa591e238>, <mavsdk.mission_raw.MissionItem object at 0xa591e670>, <mavsdk.mission_raw.MissionItem object at 0xa591edd8>, <mavsdk.mission_raw.MissionItem object at 0xa590c3e8>]",
        "mission_pause": 0,
        "time_usec": 78513638,
        "odometry_pos_body": "PositionBody: [x_m: 4.72556209564209, y_m: 1.596272349357605, z_m: -4.6791605949401855]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 78583579,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5600000023841858,
        "current_climb_rate": -0.1810721457004547,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065065,
        "gps_raw_lon_deg": 38.890047,
        "gps_raw_abs_alt": 81.29600524902344,
        "gps_raw_velocity": 0.04999999701976776,
        "position_lat_deg": 47.3065068,
        "position_lon_deg": 38.8900468,
        "position_abs_alt": 83.84100341796875,
        "position_real_alt": 5.095000267028809,
        "heading_deg": 29.03,
        "odometry_pos_body_x": 4.719419956207275,
        "odometry_pos_body_y": 1.591922640800476,
        "odometry_pos_body_z": -4.659977436065674,
        "odometry_vel_body_x": 0.00397796044126153,
        "odometry_vel_body_y": 0.03230123594403267,
        "odometry_vel_body_z": 0.22331783175468445,
        "odometry_ang_vel_body_roll": -0.44057849049568176,
        "odometry_ang_vel_body_pitch": -0.4801647663116455,
        "odometry_ang_vel_body_yaw": 0.2379312515258789,
        "velocity_north_m_s": 0,
        "velocity_east_m_s": 0,
        "velocity_down_m_s": 0.2199999988079071,
        "acceleration_forward": -1.8906060457229616,
        "acceleration_right": -2.271014928817749,
        "acceleration_down": -19.28243827819824,
        "battery_id": 0,
        "battery_voltage": 13.371000289916992,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 3243,
        "udp_total": 78,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 59
      },
      {
        "id": 75,
        "datetime": "21/02/2023 02:03:53",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xb0ec2b38>, <mavsdk.mission_raw.MissionItem object at 0xa4775fa0>, <mavsdk.mission_raw.MissionItem object at 0xa4775310>, <mavsdk.mission_raw.MissionItem object at 0xa4775dd8>]",
        "mission_pause": 0,
        "time_usec": 78813356,
        "odometry_pos_body": "PositionBody: [x_m: 4.713413238525391, y_m: 1.5888947248458862, z_m: -4.633533954620361]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 78853318,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5600000023841858,
        "current_climb_rate": -0.13890942931175232,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065065,
        "gps_raw_lon_deg": 38.8900471,
        "gps_raw_abs_alt": 81.19900512695312,
        "gps_raw_velocity": 0.009999999776482582,
        "position_lat_deg": 47.3065067,
        "position_lon_deg": 38.8900467,
        "position_abs_alt": 83.8030014038086,
        "position_real_alt": 5.057000160217285,
        "heading_deg": 32.34,
        "odometry_pos_body_x": 4.70955753326416,
        "odometry_pos_body_y": 1.5882954597473145,
        "odometry_pos_body_z": -4.619445323944092,
        "odometry_vel_body_x": -0.01065768301486969,
        "odometry_vel_body_y": 0.04209166765213013,
        "odometry_vel_body_z": 0.11194761097431184,
        "odometry_ang_vel_body_roll": 0.024388182908296585,
        "odometry_ang_vel_body_pitch": -0.05470195412635803,
        "odometry_ang_vel_body_yaw": 0.20231673121452332,
        "velocity_north_m_s": -0.029999999329447743,
        "velocity_east_m_s": 0.009999999776482582,
        "velocity_down_m_s": 0.09999999403953552,
        "acceleration_forward": 1.3801276683807373,
        "acceleration_right": 4.328196048736572,
        "acceleration_down": -12.826411247253418,
        "battery_id": 0,
        "battery_voltage": 13.371000289916992,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 26,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 3267,
        "udp_total": 78,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 59
      },
      {
        "id": 76,
        "datetime": "21/02/2023 02:03:53",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4775d30>, <mavsdk.mission_raw.MissionItem object at 0xa4775868>, <mavsdk.mission_raw.MissionItem object at 0xa476c5b0>, <mavsdk.mission_raw.MissionItem object at 0xa476c250>]",
        "mission_pause": 0,
        "time_usec": 79073113,
        "odometry_pos_body": "PositionBody: [x_m: 4.706360340118408, y_m: 1.5932536125183105, z_m: -4.595407485961914]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 79128060,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5600000023841858,
        "current_climb_rate": -0.07615582644939423,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065064,
        "gps_raw_lon_deg": 38.8900471,
        "gps_raw_abs_alt": 81.1810073852539,
        "gps_raw_velocity": 0.07000000029802322,
        "position_lat_deg": 47.3065067,
        "position_lon_deg": 38.8900468,
        "position_abs_alt": 83.77000427246094,
        "position_real_alt": 5.02400016784668,
        "heading_deg": 35.9,
        "odometry_pos_body_x": 4.704320907592773,
        "odometry_pos_body_y": 1.5962047576904297,
        "odometry_pos_body_z": -4.589105606079102,
        "odometry_vel_body_x": 0.04557694494724274,
        "odometry_vel_body_y": 0.062286026775836945,
        "odometry_vel_body_z": 0.038947924971580505,
        "odometry_ang_vel_body_roll": 0.579828679561615,
        "odometry_ang_vel_body_pitch": 0.2967926859855652,
        "odometry_ang_vel_body_yaw": 0.26061323285102844,
        "velocity_north_m_s": 0,
        "velocity_east_m_s": 0.08999999612569809,
        "velocity_down_m_s": 0.029999999329447743,
        "acceleration_forward": 1.4617348909378052,
        "acceleration_right": 5.569280624389648,
        "acceleration_down": -15.070289611816406,
        "battery_id": 0,
        "battery_voltage": 13.305001258850098,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 40,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 3285,
        "udp_total": 79,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 60
      },
      {
        "id": 77,
        "datetime": "21/02/2023 02:03:54",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa590c238>, <mavsdk.mission_raw.MissionItem object at 0xa4777358>, <mavsdk.mission_raw.MissionItem object at 0xa4777100>, <mavsdk.mission_raw.MissionItem object at 0xa4777e98>]",
        "mission_pause": 0,
        "time_usec": 79342863,
        "odometry_pos_body": "PositionBody: [x_m: 4.703850269317627, y_m: 1.6086387634277344, z_m: -4.590818405151367]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 79407797,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.550000011920929,
        "current_climb_rate": -0.03471536561846733,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065065,
        "gps_raw_lon_deg": 38.8900471,
        "gps_raw_abs_alt": 81.156005859375,
        "gps_raw_velocity": 0.05999999865889549,
        "position_lat_deg": 47.3065067,
        "position_lon_deg": 38.890047,
        "position_abs_alt": 83.77200317382812,
        "position_real_alt": 5.026000022888184,
        "heading_deg": 40.01,
        "odometry_pos_body_x": 4.709621906280518,
        "odometry_pos_body_y": 1.6133524179458618,
        "odometry_pos_body_z": -4.589958667755127,
        "odometry_vel_body_x": 0.11649253964424132,
        "odometry_vel_body_y": 0.04427091404795647,
        "odometry_vel_body_z": 0.04747716709971428,
        "odometry_ang_vel_body_roll": -0.7599088549613953,
        "odometry_ang_vel_body_pitch": 0.0009318681550212204,
        "odometry_ang_vel_body_yaw": 0.28356263041496277,
        "velocity_north_m_s": 0.04999999701976776,
        "velocity_east_m_s": 0.09999999403953552,
        "velocity_down_m_s": 0.04999999701976776,
        "acceleration_forward": -3.4938199520111084,
        "acceleration_right": -3.3278002738952637,
        "acceleration_down": -7.251607418060303,
        "battery_id": 0,
        "battery_voltage": 13.305001258850098,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 49,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 3290,
        "udp_total": 79,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 60
      },
      {
        "id": 78,
        "datetime": "21/02/2023 02:03:54",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa590cee0>, <mavsdk.mission_raw.MissionItem object at 0xa5910130>, <mavsdk.mission_raw.MissionItem object at 0xa4777a00>, <mavsdk.mission_raw.MissionItem object at 0xa4778aa8>]",
        "mission_pause": 0,
        "time_usec": 79542672,
        "odometry_pos_body": "PositionBody: [x_m: 4.71891975402832, y_m: 1.6180180311203003, z_m: -4.579897880554199]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 79612605,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5600000023841858,
        "current_climb_rate": -0.0417349748313427,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065066,
        "gps_raw_lon_deg": 38.8900473,
        "gps_raw_abs_alt": 81.13900756835938,
        "gps_raw_velocity": 0.07000000029802322,
        "position_lat_deg": 47.3065068,
        "position_lon_deg": 38.8900471,
        "position_abs_alt": 83.75800323486328,
        "position_real_alt": 5.01200008392334,
        "heading_deg": 43.57,
        "odometry_pos_body_x": 4.7287373542785645,
        "odometry_pos_body_y": 1.623083472251892,
        "odometry_pos_body_z": -4.5660600662231445,
        "odometry_vel_body_x": 0.12258189171552658,
        "odometry_vel_body_y": 0.01189992856234312,
        "odometry_vel_body_z": 0.05914336442947388,
        "odometry_ang_vel_body_roll": -0.2341114282608032,
        "odometry_ang_vel_body_pitch": -0.038362253457307816,
        "odometry_ang_vel_body_yaw": 0.28227731585502625,
        "velocity_north_m_s": 0.07999999821186066,
        "velocity_east_m_s": 0.08999999612569809,
        "velocity_down_m_s": 0.08999999612569809,
        "acceleration_forward": 0.6987736225128174,
        "acceleration_right": -1.135578751564026,
        "acceleration_down": -6.427705764770508,
        "battery_id": 0,
        "battery_voltage": 13.305001258850098,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 3314,
        "udp_total": 79,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 60
      },
      {
        "id": 79,
        "datetime": "21/02/2023 02:03:54",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4775868>, <mavsdk.mission_raw.MissionItem object at 0xa47759a0>, <mavsdk.mission_raw.MissionItem object at 0xa58f2fb8>, <mavsdk.mission_raw.MissionItem object at 0xb20180d0>]",
        "mission_pause": 0,
        "time_usec": 79812418,
        "odometry_pos_body": "PositionBody: [x_m: 4.739724159240723, y_m: 1.6248359680175781, z_m: -4.543425559997559]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 79842389,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5799999833106995,
        "current_climb_rate": -0.11034174263477324,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065067,
        "gps_raw_lon_deg": 38.8900475,
        "gps_raw_abs_alt": 81.12300109863281,
        "gps_raw_velocity": 0.07999999821186066,
        "position_lat_deg": 47.306507,
        "position_lon_deg": 38.8900472,
        "position_abs_alt": 83.71600341796875,
        "position_real_alt": 4.969000339508057,
        "heading_deg": 47.24,
        "odometry_pos_body_x": 4.7473931312561035,
        "odometry_pos_body_y": 1.6298357248306274,
        "odometry_pos_body_z": -4.522216796875,
        "odometry_vel_body_x": 0.16094227135181427,
        "odometry_vel_body_y": 0.01651226170361042,
        "odometry_vel_body_z": 0.11225016415119173,
        "odometry_ang_vel_body_roll": -0.0004392020055092871,
        "odometry_ang_vel_body_pitch": 0.07978393137454987,
        "odometry_ang_vel_body_yaw": 0.2170787453651428,
        "velocity_north_m_s": 0.08999999612569809,
        "velocity_east_m_s": 0.119999997317791,
        "velocity_down_m_s": 0.14000000059604645,
        "acceleration_forward": -0.9716718196868896,
        "acceleration_right": -1.1343120336532593,
        "acceleration_down": -6.833646297454834,
        "battery_id": 0,
        "battery_voltage": 13.305001258850098,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 24,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 3336,
        "udp_total": 79,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 60
      },
      {
        "id": 80,
        "datetime": "21/02/2023 02:03:54",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xb20256a0>, <mavsdk.mission_raw.MissionItem object at 0xa4778298>, <mavsdk.mission_raw.MissionItem object at 0xa4778f10>, <mavsdk.mission_raw.MissionItem object at 0xa4778c28>]",
        "mission_pause": 0,
        "time_usec": 80142113,
        "odometry_pos_body": "PositionBody: [x_m: 4.78092622756958, y_m: 1.6446418762207031, z_m: -4.472937107086182]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 80077222,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5999999642372131,
        "current_climb_rate": -0.07321878522634506,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065071,
        "gps_raw_lon_deg": 38.8900478,
        "gps_raw_abs_alt": 81.08700561523438,
        "gps_raw_velocity": 0.12999999523162842,
        "position_lat_deg": 47.3065073,
        "position_lon_deg": 38.8900474,
        "position_abs_alt": 83.65900421142578,
        "position_real_alt": 4.9120001792907715,
        "heading_deg": 50.72,
        "odometry_pos_body_x": 4.792657852172852,
        "odometry_pos_body_y": 1.6486304998397827,
        "odometry_pos_body_z": -4.466779708862305,
        "odometry_vel_body_x": 0.23139777779579165,
        "odometry_vel_body_y": -0.007648571860045195,
        "odometry_vel_body_z": 0.03035734035074711,
        "odometry_ang_vel_body_roll": -0.4147008657455444,
        "odometry_ang_vel_body_pitch": -0.06064650043845177,
        "odometry_ang_vel_body_yaw": 0.18976226449012756,
        "velocity_north_m_s": 0.119999997317791,
        "velocity_east_m_s": 0.14999999105930328,
        "velocity_down_m_s": 0.09999999403953552,
        "acceleration_forward": -1.208551287651062,
        "acceleration_right": 0.47800374031066895,
        "acceleration_down": -13.667425155639648,
        "battery_id": 0,
        "battery_voltage": 13.305001258850098,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 3360,
        "udp_total": 80,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 61
      },
      {
        "id": 81,
        "datetime": "21/02/2023 02:03:55",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4777070>, <mavsdk.mission_raw.MissionItem object at 0xa47770d0>, <mavsdk.mission_raw.MissionItem object at 0xa4777f70>, <mavsdk.mission_raw.MissionItem object at 0xa590c100>]",
        "mission_pause": 0,
        "time_usec": 80411856,
        "odometry_pos_body": "PositionBody: [x_m: 4.82990026473999, y_m: 1.6687572002410889, z_m: -4.473228454589844]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 80307000,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5,
        "current_climb_rate": 0.29137271642684937,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065078,
        "gps_raw_lon_deg": 38.8900483,
        "gps_raw_abs_alt": 81.07400512695312,
        "gps_raw_velocity": 0.25999999046325684,
        "position_lat_deg": 47.3065077,
        "position_lon_deg": 38.8900477,
        "position_abs_alt": 83.64600372314453,
        "position_real_alt": 4.901000022888184,
        "heading_deg": 52.31,
        "odometry_pos_body_x": 4.875949382781982,
        "odometry_pos_body_y": 1.683116436004639,
        "odometry_pos_body_z": -4.541150093078613,
        "odometry_vel_body_x": 0.20421123504638672,
        "odometry_vel_body_y": -0.12099190056324004,
        "odometry_vel_body_z": -0.29839950799942017,
        "odometry_ang_vel_body_roll": -0.16698351502418518,
        "odometry_ang_vel_body_pitch": -0.2167786210775375,
        "odometry_ang_vel_body_yaw": 0.26668205857276917,
        "velocity_north_m_s": 0.17000000178813934,
        "velocity_east_m_s": 0.1599999964237213,
        "velocity_down_m_s": -0.10999999940395357,
        "acceleration_forward": 1.802329182624817,
        "acceleration_right": 0.2971111834049225,
        "acceleration_down": -4.597218036651611,
        "battery_id": 0,
        "battery_voltage": 13.305001258850098,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 19,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 3381,
        "udp_total": 80,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 61
      },
      {
        "id": 82,
        "datetime": "21/02/2023 02:03:55",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4777250>, <mavsdk.mission_raw.MissionItem object at 0xa4777220>, <mavsdk.mission_raw.MissionItem object at 0xa4777310>, <mavsdk.mission_raw.MissionItem object at 0xa47770e8>]",
        "mission_pause": 0,
        "time_usec": 80811482,
        "odometry_pos_body": "PositionBody: [x_m: 4.916337490081787, y_m: 1.7003284692764282, z_m: -4.6294403076171875]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 80526748,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.4599999785423279,
        "current_climb_rate": 0.3039468228816986,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306508699999995,
        "gps_raw_lon_deg": 38.890049,
        "gps_raw_abs_alt": 81.1760025024414,
        "gps_raw_velocity": 0.25999999046325684,
        "position_lat_deg": 47.3065082,
        "position_lon_deg": 38.890048,
        "position_abs_alt": 83.71900177001953,
        "position_real_alt": 4.9730000495910645,
        "heading_deg": 54.2,
        "odometry_pos_body_x": 4.9367475509643555,
        "odometry_pos_body_y": 1.7107664346694946,
        "odometry_pos_body_z": -4.678167343139648,
        "odometry_vel_body_x": 0.10513105243444444,
        "odometry_vel_body_y": -0.01809365302324295,
        "odometry_vel_body_z": -0.3031686544418335,
        "odometry_ang_vel_body_roll": 1.4623466730117798,
        "odometry_ang_vel_body_pitch": 0.007698323577642441,
        "odometry_ang_vel_body_yaw": 0.26784658432006836,
        "velocity_north_m_s": 0.20999999344348907,
        "velocity_east_m_s": 0.10999999940395357,
        "velocity_down_m_s": -0.2800000011920929,
        "acceleration_forward": 0.4144552946090698,
        "acceleration_right": -6.744225025177002,
        "acceleration_down": -15.688155174255373,
        "battery_id": 0,
        "battery_voltage": 13.305001258850098,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 0,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 3405,
        "udp_total": 80,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 61
      },
      {
        "id": 83,
        "datetime": "21/02/2023 02:03:55",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477af88>, <mavsdk.mission_raw.MissionItem object at 0xa477a820>, <mavsdk.mission_raw.MissionItem object at 0xa477a760>, <mavsdk.mission_raw.MissionItem object at 0xa477a178>]",
        "mission_pause": 0,
        "time_usec": 81141172,
        "odometry_pos_body": "PositionBody: [x_m: 4.955270767211914, y_m: 1.723141074180603, z_m: -4.738537311553955]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 80786504,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.4699999988079071,
        "current_climb_rate": 0.12644827365875244,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065089,
        "gps_raw_lon_deg": 38.8900491,
        "gps_raw_abs_alt": 81.22300720214844,
        "gps_raw_velocity": 0.09999999403953552,
        "position_lat_deg": 47.3065086,
        "position_lon_deg": 38.890048199999995,
        "position_abs_alt": 83.81600189208984,
        "position_real_alt": 5.069000244140625,
        "heading_deg": 58.05,
        "odometry_pos_body_x": 4.957296371459961,
        "odometry_pos_body_y": 1.7280261516571045,
        "odometry_pos_body_z": -4.765254020690918,
        "odometry_vel_body_x": 0.03099771775305271,
        "odometry_vel_body_y": 0.10228011757135393,
        "odometry_vel_body_z": -0.10477155447006226,
        "odometry_ang_vel_body_roll": 0.30451515316963196,
        "odometry_ang_vel_body_pitch": 0.26880350708961487,
        "odometry_ang_vel_body_yaw": 0.34332793951034546,
        "velocity_north_m_s": 0.08999999612569809,
        "velocity_east_m_s": 0.08999999612569809,
        "velocity_down_m_s": -0.29999998211860657,
        "acceleration_forward": -0.21854691207408905,
        "acceleration_right": -2.522768020629883,
        "acceleration_down": -3.207632303237915,
        "battery_id": 0,
        "battery_voltage": 13.293001174926758,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 3,
        "udp": 2,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 3406,
        "udp_total": 82,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 62
      },
      {
        "id": 84,
        "datetime": "21/02/2023 02:03:56",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa47789d0>, <mavsdk.mission_raw.MissionItem object at 0xa4778130>, <mavsdk.mission_raw.MissionItem object at 0xa4778fd0>, <mavsdk.mission_raw.MissionItem object at 0xa4778fa0>]",
        "mission_pause": 0,
        "time_usec": 81380955,
        "odometry_pos_body": "PositionBody: [x_m: 4.954366207122803, y_m: 1.731781244277954, z_m: -4.791221618652344]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 81146167,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5099999904632568,
        "current_climb_rate": -0.1355953961610794,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065089,
        "gps_raw_lon_deg": 38.890049,
        "gps_raw_abs_alt": 81.23700714111328,
        "gps_raw_velocity": 0.03999999910593033,
        "position_lat_deg": 47.3065089,
        "position_lon_deg": 38.8900486,
        "position_abs_alt": 83.9540023803711,
        "position_real_alt": 5.208000183105469,
        "heading_deg": 66.53,
        "odometry_pos_body_x": 4.941481590270996,
        "odometry_pos_body_y": 1.737156867980957,
        "odometry_pos_body_z": -4.7942633628845215,
        "odometry_vel_body_x": 0.037868957966566086,
        "odometry_vel_body_y": 0.21411970257759097,
        "odometry_vel_body_z": 0.16787227988243103,
        "odometry_ang_vel_body_roll": -0.024219177663326263,
        "odometry_ang_vel_body_pitch": -0.160060852766037,
        "odometry_ang_vel_body_yaw": 0.3637035489082336,
        "velocity_north_m_s": -0.119999997317791,
        "velocity_east_m_s": 0.07999999821186066,
        "velocity_down_m_s": -0.009999999776482582,
        "acceleration_forward": -0.28431522846221924,
        "acceleration_right": -3.0412027835845947,
        "acceleration_down": -12.598247528076172,
        "battery_id": 0,
        "battery_voltage": 13.293001174926758,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 1,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 3428,
        "udp_total": 82,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 62
      },
      {
        "id": 85,
        "datetime": "21/02/2023 02:03:56",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa476c538>, <mavsdk.mission_raw.MissionItem object at 0xa476c880>, <mavsdk.mission_raw.MissionItem object at 0xa476c340>, <mavsdk.mission_raw.MissionItem object at 0xa476cc28>]",
        "mission_pause": 0,
        "time_usec": 81650698,
        "odometry_pos_body": "PositionBody: [x_m: 4.932548522949219, y_m: 1.740149736404419, z_m: -4.787740230560303]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 81410921,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5199999809265137,
        "current_climb_rate": -0.1750403642654419,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306508699999995,
        "gps_raw_lon_deg": 38.8900491,
        "gps_raw_abs_alt": 81.2020034790039,
        "gps_raw_velocity": 0.07000000029802322,
        "position_lat_deg": 47.3065089,
        "position_lon_deg": 38.8900486,
        "position_abs_alt": 83.9780044555664,
        "position_real_alt": 5.232000350952148,
        "heading_deg": 71.35000000000001,
        "odometry_pos_body_x": 4.918114185333252,
        "odometry_pos_body_y": 1.7454438209533691,
        "odometry_pos_body_z": -4.776514053344727,
        "odometry_vel_body_x": 0.0634775385260582,
        "odometry_vel_body_y": 0.2300170660018921,
        "odometry_vel_body_z": 0.15191037952899933,
        "odometry_ang_vel_body_roll": 0.2408900409936905,
        "odometry_ang_vel_body_pitch": 0.2630220651626587,
        "odometry_ang_vel_body_yaw": 0.4736162424087525,
        "velocity_north_m_s": -0.1599999964237213,
        "velocity_east_m_s": 0.08999999612569809,
        "velocity_down_m_s": 0.12999999523162842,
        "acceleration_forward": -0.8588882684707642,
        "acceleration_right": -5.72497034072876,
        "acceleration_down": -5.743658065795898,
        "battery_id": 0,
        "battery_voltage": 13.293001174926758,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 3450,
        "udp_total": 82,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 62
      },
      {
        "id": 86,
        "datetime": "21/02/2023 02:03:56",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa47772e0>, <mavsdk.mission_raw.MissionItem object at 0xa590c8e0>, <mavsdk.mission_raw.MissionItem object at 0xa590c130>, <mavsdk.mission_raw.MissionItem object at 0xa590cb08>]",
        "mission_pause": 0,
        "time_usec": 81950414,
        "odometry_pos_body": "PositionBody: [x_m: 4.89162540435791, y_m: 1.7516729831695557, z_m: -4.765275001525879]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 81725624,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5199999809265137,
        "current_climb_rate": -0.16146151721477509,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065084,
        "gps_raw_lon_deg": 38.890049,
        "gps_raw_abs_alt": 81.1570053100586,
        "gps_raw_velocity": 0.14000000059604645,
        "position_lat_deg": 47.3065086,
        "position_lon_deg": 38.890048799999995,
        "position_abs_alt": 83.9590072631836,
        "position_real_alt": 5.213000297546387,
        "heading_deg": 78.83,
        "odometry_pos_body_x": 4.873775959014893,
        "odometry_pos_body_y": 1.7539772987365725,
        "odometry_pos_body_z": -4.757757663726807,
        "odometry_vel_body_x": 0.09236873686313628,
        "odometry_vel_body_y": 0.27976909279823303,
        "odometry_vel_body_z": 0.15725308656692505,
        "odometry_ang_vel_body_roll": -0.9753859639167786,
        "odometry_ang_vel_body_pitch": -0.16046498715877533,
        "odometry_ang_vel_body_yaw": 0.476220428943634,
        "velocity_north_m_s": -0.20999999344348907,
        "velocity_east_m_s": 0.08999999612569809,
        "velocity_down_m_s": 0.1599999964237213,
        "acceleration_forward": -1.3359085321426392,
        "acceleration_right": -5.967958450317383,
        "acceleration_down": -5.422050952911377,
        "battery_id": 0,
        "battery_voltage": 13.293001174926758,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 4,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 3472,
        "udp_total": 82,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 62
      },
      {
        "id": 87,
        "datetime": "21/02/2023 02:03:56",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa58f2f40>, <mavsdk.mission_raw.MissionItem object at 0xa477abf8>, <mavsdk.mission_raw.MissionItem object at 0xa477a9e8>, <mavsdk.mission_raw.MissionItem object at 0xa477ad00>]",
        "mission_pause": 0,
        "time_usec": 82190191,
        "odometry_pos_body": "PositionBody: [x_m: 4.837639331817627, y_m: 1.762216329574585, z_m: -4.739240646362305]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 81955410,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5399999618530273,
        "current_climb_rate": -0.19254761934280396,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065079,
        "gps_raw_lon_deg": 38.8900492,
        "gps_raw_abs_alt": 81.13200378417969,
        "gps_raw_velocity": 0.26999998092651367,
        "position_lat_deg": 47.3065082,
        "position_lon_deg": 38.8900489,
        "position_abs_alt": 83.93900299072266,
        "position_real_alt": 5.193000316619873,
        "heading_deg": 85.7,
        "odometry_pos_body_x": 4.821411609649658,
        "odometry_pos_body_y": 1.7688732147216797,
        "odometry_pos_body_z": -4.725194454193115,
        "odometry_vel_body_x": 0.12460212409496307,
        "odometry_vel_body_y": 0.2955839931964874,
        "odometry_vel_body_z": 0.1754239797592163,
        "odometry_ang_vel_body_roll": 0.5562658309936523,
        "odometry_ang_vel_body_pitch": 0.10558798164129256,
        "odometry_ang_vel_body_yaw": 0.4642066955566406,
        "velocity_north_m_s": -0.25,
        "velocity_east_m_s": 0.09999999403953552,
        "velocity_down_m_s": 0.17999999225139618,
        "acceleration_forward": -0.4620932936668396,
        "acceleration_right": -2.8424251079559326,
        "acceleration_down": -6.805935859680176,
        "battery_id": 0,
        "battery_voltage": 13.293001174926758,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 7,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 3479,
        "udp_total": 83,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 63
      },
      {
        "id": 88,
        "datetime": "21/02/2023 02:03:57",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4778088>, <mavsdk.mission_raw.MissionItem object at 0xa4778448>, <mavsdk.mission_raw.MissionItem object at 0xa4778d78>, <mavsdk.mission_raw.MissionItem object at 0xa4778d00>]",
        "mission_pause": 0,
        "time_usec": 82439956,
        "odometry_pos_body": "PositionBody: [x_m: 4.770815372467041, y_m: 1.777535319328308, z_m: -4.704430103302002]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 82195185,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5399999618530273,
        "current_climb_rate": -0.16060799360275269,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306507,
        "gps_raw_lon_deg": 38.8900495,
        "gps_raw_abs_alt": 81.04100036621094,
        "gps_raw_velocity": 0.26999998092651367,
        "position_lat_deg": 47.3065078,
        "position_lon_deg": 38.8900491,
        "position_abs_alt": 83.91500091552734,
        "position_real_alt": 5.169000148773193,
        "heading_deg": 90.88,
        "odometry_pos_body_x": 4.742165565490723,
        "odometry_pos_body_y": 1.7827938795089722,
        "odometry_pos_body_z": -4.690670013427734,
        "odometry_vel_body_x": 0.1268111914396286,
        "odometry_vel_body_y": 0.23579414188861847,
        "odometry_vel_body_z": 0.13538049161434174,
        "odometry_ang_vel_body_roll": -0.6283296346664429,
        "odometry_ang_vel_body_pitch": 0.18486863374710083,
        "odometry_ang_vel_body_yaw": 0.3430871069431305,
        "velocity_north_m_s": -0.29999998211860657,
        "velocity_east_m_s": 0.119999997317791,
        "velocity_down_m_s": 0.17999999225139618,
        "acceleration_forward": -2.55224609375,
        "acceleration_right": -1.1223304271697998,
        "acceleration_down": -22.070520401000977,
        "battery_id": 0,
        "battery_voltage": 13.293001174926758,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 28,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 3496,
        "udp_total": 83,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 63
      },
      {
        "id": 89,
        "datetime": "21/02/2023 02:03:57",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa590c370>, <mavsdk.mission_raw.MissionItem object at 0xa590c658>, <mavsdk.mission_raw.MissionItem object at 0xa590cb38>, <mavsdk.mission_raw.MissionItem object at 0xa47781f0>]",
        "mission_pause": 0,
        "time_usec": 82679761,
        "odometry_pos_body": "PositionBody: [x_m: 4.704952239990234, y_m: 1.7871397733688354, z_m: -4.6718645095825195]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 82424970,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5399999618530273,
        "current_climb_rate": -0.12083353102207184,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065066,
        "gps_raw_lon_deg": 38.8900497,
        "gps_raw_abs_alt": 81.01100158691406,
        "gps_raw_velocity": 0.20999999344348907,
        "position_lat_deg": 47.3065071,
        "position_lon_deg": 38.8900493,
        "position_abs_alt": 83.87600708007812,
        "position_real_alt": 5.129000186920166,
        "heading_deg": 98.18,
        "odometry_pos_body_x": 4.6896653175354,
        "odometry_pos_body_y": 1.791704177856445,
        "odometry_pos_body_z": -4.657712936401367,
        "odometry_vel_body_x": 0.09839564561843872,
        "odometry_vel_body_y": 0.05965036526322365,
        "odometry_vel_body_z": 0.16059190034866333,
        "odometry_ang_vel_body_roll": -0.9197489619255066,
        "odometry_ang_vel_body_pitch": 0.2046026736497879,
        "odometry_ang_vel_body_yaw": 0.35926300287246704,
        "velocity_north_m_s": -0.25,
        "velocity_east_m_s": 0.07999999821186066,
        "velocity_down_m_s": 0.12999999523162842,
        "acceleration_forward": -1.0871754884719849,
        "acceleration_right": -6.584201335906982,
        "acceleration_down": -4.555057048797607,
        "battery_id": 0,
        "battery_voltage": 13.293001174926758,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 3520,
        "udp_total": 83,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 63
      },
      {
        "id": 90,
        "datetime": "21/02/2023 02:03:57",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4777a90>, <mavsdk.mission_raw.MissionItem object at 0xa4777f40>, <mavsdk.mission_raw.MissionItem object at 0xa4777748>, <mavsdk.mission_raw.MissionItem object at 0xa4777a00>]",
        "mission_pause": 0,
        "time_usec": 83039396,
        "odometry_pos_body": "PositionBody: [x_m: 4.680984973907471, y_m: 1.7986700534820557, z_m: -4.623851299285889]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 82769669,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5600000023841858,
        "current_climb_rate": -0.1398693174123764,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065062,
        "gps_raw_lon_deg": 38.890049999999995,
        "gps_raw_abs_alt": 80.95500183105469,
        "gps_raw_velocity": 0.07000000029802322,
        "position_lat_deg": 47.3065066,
        "position_lon_deg": 38.890049399999995,
        "position_abs_alt": 83.84400177001953,
        "position_real_alt": 5.0980000495910645,
        "heading_deg": 103.71,
        "odometry_pos_body_x": 4.681497097015381,
        "odometry_pos_body_y": 1.8047293424606323,
        "odometry_pos_body_z": -4.60332727432251,
        "odometry_vel_body_x": 0.06752089411020279,
        "odometry_vel_body_y": -0.0497465580701828,
        "odometry_vel_body_z": 0.14065977931022644,
        "odometry_ang_vel_body_roll": -0.1886320412158966,
        "odometry_ang_vel_body_pitch": -0.0954415127635002,
        "odometry_ang_vel_body_yaw": 0.4011305570602417,
        "velocity_north_m_s": -0.08999999612569809,
        "velocity_east_m_s": 0.07000000029802322,
        "velocity_down_m_s": 0.14999999105930328,
        "acceleration_forward": -0.9731488823890686,
        "acceleration_right": -1.0625792741775513,
        "acceleration_down": -7.42501974105835,
        "battery_id": 0,
        "battery_voltage": 13.266000747680664,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 1,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 3542,
        "udp_total": 84,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 64
      },
      {
        "id": 91,
        "datetime": "21/02/2023 02:03:58",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa476cf28>, <mavsdk.mission_raw.MissionItem object at 0xa476c0a0>, <mavsdk.mission_raw.MissionItem object at 0xa476ca18>, <mavsdk.mission_raw.MissionItem object at 0xa476c160>]",
        "mission_pause": 0,
        "time_usec": 83339118,
        "odometry_pos_body": "PositionBody: [x_m: 4.682666301727295, y_m: 1.816419243812561, z_m: -4.572597980499268]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 83099400,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5600000023841858,
        "current_climb_rate": -0.06452765315771103,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306506299999995,
        "gps_raw_lon_deg": 38.8900502,
        "gps_raw_abs_alt": 80.88400268554688,
        "gps_raw_velocity": 0.04999999701976776,
        "position_lat_deg": 47.3065065,
        "position_lon_deg": 38.8900496,
        "position_abs_alt": 83.77800750732422,
        "position_real_alt": 5.0320000648498535,
        "heading_deg": 113.8,
        "odometry_pos_body_x": 4.678510665893555,
        "odometry_pos_body_y": 1.8239046335220337,
        "odometry_pos_body_z": -4.546037673950195,
        "odometry_vel_body_x": 0.06138027459383011,
        "odometry_vel_body_y": 0.0036946304608136415,
        "odometry_vel_body_z": 0.02251691371202469,
        "odometry_ang_vel_body_roll": -0.30386459827423096,
        "odometry_ang_vel_body_pitch": -0.27708691358566284,
        "odometry_ang_vel_body_yaw": 0.4863162338733673,
        "velocity_north_m_s": 0.009999999776482582,
        "velocity_east_m_s": 0.05999999865889549,
        "velocity_down_m_s": 0.12999999523162842,
        "acceleration_forward": -0.7612641453742981,
        "acceleration_right": 5.41547966003418,
        "acceleration_down": -12.851333618164062,
        "battery_id": 0,
        "battery_voltage": 13.266000747680664,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 3566,
        "udp_total": 84,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 64
      },
      {
        "id": 92,
        "datetime": "21/02/2023 02:03:58",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa476cc28>, <mavsdk.mission_raw.MissionItem object at 0xa476caf0>, <mavsdk.mission_raw.MissionItem object at 0xa476cb68>, <mavsdk.mission_raw.MissionItem object at 0xb20258b0>]",
        "mission_pause": 0,
        "time_usec": 83678798,
        "odometry_pos_body": "PositionBody: [x_m: 4.669826507568359, y_m: 1.827978491783142, z_m: -4.529276371002197]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 83508958,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5600000023841858,
        "current_climb_rate": 0.01515963301062584,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306506299999995,
        "gps_raw_lon_deg": 38.8900503,
        "gps_raw_abs_alt": 80.87800598144531,
        "gps_raw_velocity": 0.029999999329447743,
        "position_lat_deg": 47.3065064,
        "position_lon_deg": 38.8900498,
        "position_abs_alt": 83.72700500488281,
        "position_real_alt": 4.9810004234313965,
        "heading_deg": 123.45,
        "odometry_pos_body_x": 4.664630889892578,
        "odometry_pos_body_y": 1.8285452127456665,
        "odometry_pos_body_z": -4.51663875579834,
        "odometry_vel_body_x": 0.09132774919271468,
        "odometry_vel_body_y": 0.09160296618938446,
        "odometry_vel_body_z": -0.007193554192781448,
        "odometry_ang_vel_body_roll": 0.30290687084198,
        "odometry_ang_vel_body_pitch": 0.07160340249538422,
        "odometry_ang_vel_body_yaw": 0.5117062926292419,
        "velocity_north_m_s": -0.029999999329447743,
        "velocity_east_m_s": 0.03999999910593033,
        "velocity_down_m_s": 0.019999999552965164,
        "acceleration_forward": -1.412821888923645,
        "acceleration_right": 1.705508589744568,
        "acceleration_down": -10.933696746826172,
        "battery_id": 0,
        "battery_voltage": 13.266000747680664,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 3588,
        "udp_total": 84,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 64
      },
      {
        "id": 93,
        "datetime": "21/02/2023 02:03:58",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa47777f0>, <mavsdk.mission_raw.MissionItem object at 0xa4778388>, <mavsdk.mission_raw.MissionItem object at 0xa4778a48>, <mavsdk.mission_raw.MissionItem object at 0xa4778700>]",
        "mission_pause": 0,
        "time_usec": 83978518,
        "odometry_pos_body": "PositionBody: [x_m: 4.650217056274414, y_m: 1.8310246467590332, z_m: -4.486143589019775]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 83853635,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5799999833106995,
        "current_climb_rate": -0.06438663601875305,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065062,
        "gps_raw_lon_deg": 38.8900504,
        "gps_raw_abs_alt": 80.89600372314453,
        "gps_raw_velocity": 0.04999999701976776,
        "position_lat_deg": 47.3065062,
        "position_lon_deg": 38.8900499,
        "position_abs_alt": 83.6810073852539,
        "position_real_alt": 4.935000419616699,
        "heading_deg": 133.16,
        "odometry_pos_body_x": 4.640089511871338,
        "odometry_pos_body_y": 1.833232879638672,
        "odometry_pos_body_z": -4.463336944580078,
        "odometry_vel_body_x": 0.12290680408477785,
        "odometry_vel_body_y": 0.06916781514883041,
        "odometry_vel_body_z": 0.03354499489068985,
        "odometry_ang_vel_body_roll": 0.8161583542823792,
        "odometry_ang_vel_body_pitch": 0.2587912976741791,
        "odometry_ang_vel_body_yaw": 0.5351973176002502,
        "velocity_north_m_s": -0.12999999523162842,
        "velocity_east_m_s": 0.019999999552965164,
        "velocity_down_m_s": 0.029999999329447743,
        "acceleration_forward": -0.6862791776657104,
        "acceleration_right": 1.905673623085022,
        "acceleration_down": -4.133641719818115,
        "battery_id": 0,
        "battery_voltage": 13.266000747680664,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 15,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 3605,
        "udp_total": 85,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 65
      },
      {
        "id": 94,
        "datetime": "21/02/2023 02:03:59",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa58f2298>, <mavsdk.mission_raw.MissionItem object at 0xa5910130>, <mavsdk.mission_raw.MissionItem object at 0xa5910e20>, <mavsdk.mission_raw.MissionItem object at 0xa5910568>]",
        "mission_pause": 0,
        "time_usec": 84278238,
        "odometry_pos_body": "PositionBody: [x_m: 4.627683162689209, y_m: 1.8427751064300537, z_m: -4.427241325378418]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 84083422,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5799999833106995,
        "current_climb_rate": 0.030535196885466576,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306506,
        "gps_raw_lon_deg": 38.8900504,
        "gps_raw_abs_alt": 80.89100646972656,
        "gps_raw_velocity": 0.03999999910593033,
        "position_lat_deg": 47.306506,
        "position_lon_deg": 38.890049999999995,
        "position_abs_alt": 83.63200378417969,
        "position_real_alt": 4.886000156402588,
        "heading_deg": 139.71,
        "odometry_pos_body_x": 4.625143527984619,
        "odometry_pos_body_y": 1.8442033529281616,
        "odometry_pos_body_z": -4.418869495391846,
        "odometry_vel_body_x": 0.12032844126224518,
        "odometry_vel_body_y": 0.015777386724948883,
        "odometry_vel_body_z": -0.0853976458311081,
        "odometry_ang_vel_body_roll": 0.02401069737970829,
        "odometry_ang_vel_body_pitch": -0.048264216631650925,
        "odometry_ang_vel_body_yaw": 0.42653948068618774,
        "velocity_north_m_s": -0.12999999523162842,
        "velocity_east_m_s": 0.04999999701976776,
        "velocity_down_m_s": 0.009999999776482582,
        "acceleration_forward": 0.5795870423316956,
        "acceleration_right": -1.2881196737289429,
        "acceleration_down": -10.817903518676758,
        "battery_id": 0,
        "battery_voltage": 13.266000747680664,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 3613,
        "udp_total": 85,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 65
      },
      {
        "id": 95,
        "datetime": "21/02/2023 02:03:59",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477a100>, <mavsdk.mission_raw.MissionItem object at 0xa477a3d0>, <mavsdk.mission_raw.MissionItem object at 0xa477a2f8>, <mavsdk.mission_raw.MissionItem object at 0xa477ab50>]",
        "mission_pause": 0,
        "time_usec": 84488043,
        "odometry_pos_body": "PositionBody: [x_m: 4.619699001312256, y_m: 1.8503129482269287, z_m: -4.412411212921143]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 84318204,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.550000011920929,
        "current_climb_rate": 0.16541795432567596,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065059,
        "gps_raw_lon_deg": 38.8900507,
        "gps_raw_abs_alt": 80.92300415039062,
        "gps_raw_velocity": 0.08999999612569809,
        "position_lat_deg": 47.3065059,
        "position_lon_deg": 38.8900501,
        "position_abs_alt": 83.60200500488281,
        "position_real_alt": 4.8560004234313965,
        "heading_deg": 145.29,
        "odometry_pos_body_x": 4.619171142578125,
        "odometry_pos_body_y": 1.857403039932251,
        "odometry_pos_body_z": -4.415379524230957,
        "odometry_vel_body_x": 0.07308785617351532,
        "odometry_vel_body_y": -0.03830331936478615,
        "odometry_vel_body_z": -0.18974298238754272,
        "odometry_ang_vel_body_roll": -0.11314291507005692,
        "odometry_ang_vel_body_pitch": 0.1663866937160492,
        "odometry_ang_vel_body_yaw": 0.4493953287601471,
        "velocity_north_m_s": -0.09999999403953552,
        "velocity_east_m_s": 0.07000000029802322,
        "velocity_down_m_s": -0.04999999701976776,
        "acceleration_forward": -0.863701581954956,
        "acceleration_right": 2.410686731338501,
        "acceleration_down": -13.435922622680664,
        "battery_id": 0,
        "battery_voltage": 13.266000747680664,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 3635,
        "udp_total": 85,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 65
      },
      {
        "id": 96,
        "datetime": "21/02/2023 02:03:59",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa47774d8>, <mavsdk.mission_raw.MissionItem object at 0xa4777b20>, <mavsdk.mission_raw.MissionItem object at 0xa476cc58>, <mavsdk.mission_raw.MissionItem object at 0xa591e400>]",
        "mission_pause": 0,
        "time_usec": 84747802,
        "odometry_pos_body": "PositionBody: [x_m: 4.620725631713867, y_m: 1.8691517114639282, z_m: -4.424367427825928]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 84572965,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5399999618530273,
        "current_climb_rate": 0.2217303067445755,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065058,
        "gps_raw_lon_deg": 38.8900509,
        "gps_raw_abs_alt": 80.97000122070312,
        "gps_raw_velocity": 0.07000000029802322,
        "position_lat_deg": 47.3065059,
        "position_lon_deg": 38.8900503,
        "position_abs_alt": 83.59900665283203,
        "position_real_alt": 4.853000164031982,
        "heading_deg": 151.88,
        "odometry_pos_body_x": 4.623161792755127,
        "odometry_pos_body_y": 1.876587986946106,
        "odometry_pos_body_z": -4.430103302001953,
        "odometry_vel_body_x": 0.026679737493395805,
        "odometry_vel_body_y": -0.08108695596456528,
        "odometry_vel_body_z": -0.23038999736309052,
        "odometry_ang_vel_body_roll": 0.23952265083789823,
        "odometry_ang_vel_body_pitch": -0.21223534643650055,
        "odometry_ang_vel_body_yaw": 0.4605998694896698,
        "velocity_north_m_s": -0.029999999329447743,
        "velocity_east_m_s": 0.08999999612569809,
        "velocity_down_m_s": -0.17000000178813934,
        "acceleration_forward": -1.6819093227386477,
        "acceleration_right": 1.4365036487579346,
        "acceleration_down": -12.935626983642578,
        "battery_id": 0,
        "battery_voltage": 13.266000747680664,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 3653,
        "udp_total": 85,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 65
      },
      {
        "id": 97,
        "datetime": "21/02/2023 02:03:59",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477cb38>, <mavsdk.mission_raw.MissionItem object at 0xa477c208>, <mavsdk.mission_raw.MissionItem object at 0xa477c310>, <mavsdk.mission_raw.MissionItem object at 0xa477c5b0>]",
        "mission_pause": 0,
        "time_usec": 85017551,
        "odometry_pos_body": "PositionBody: [x_m: 4.628902912139893, y_m: 1.8886357545852661, z_m: -4.455620288848877]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 84832722,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5399999618530273,
        "current_climb_rate": 0.18663853406906128,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306506,
        "gps_raw_lon_deg": 38.8900514,
        "gps_raw_abs_alt": 81.0770034790039,
        "gps_raw_velocity": 0.10999999940395357,
        "position_lat_deg": 47.3065059,
        "position_lon_deg": 38.890050599999995,
        "position_abs_alt": 83.62200164794922,
        "position_real_alt": 4.87600040435791,
        "heading_deg": 159.52,
        "odometry_pos_body_x": 4.631566524505615,
        "odometry_pos_body_y": 1.8931407928466797,
        "odometry_pos_body_z": -4.468273162841797,
        "odometry_vel_body_x": -0.0015792130725458264,
        "odometry_vel_body_y": -0.038839954882860184,
        "odometry_vel_body_z": -0.1830759048461914,
        "odometry_ang_vel_body_roll": -0.9923166036605836,
        "odometry_ang_vel_body_pitch": 0.12958115339279175,
        "odometry_ang_vel_body_yaw": 0.39267441630363464,
        "velocity_north_m_s": 0.009999999776482582,
        "velocity_east_m_s": 0.07999999821186066,
        "velocity_down_m_s": -0.19999998807907104,
        "acceleration_forward": -0.1639285385608673,
        "acceleration_right": -2.7658255100250244,
        "acceleration_down": -8.865511894226074,
        "battery_id": 0,
        "battery_voltage": 13.23000144958496,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 19,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 3673,
        "udp_total": 86,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 66
      },
      {
        "id": 98,
        "datetime": "21/02/2023 02:04:00",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa590c628>, <mavsdk.mission_raw.MissionItem object at 0xa4777448>, <mavsdk.mission_raw.MissionItem object at 0xa4777040>, <mavsdk.mission_raw.MissionItem object at 0xa4777af0>]",
        "mission_pause": 0,
        "time_usec": 85312274,
        "odometry_pos_body": "PositionBody: [x_m: 4.6405558586120605, y_m: 1.900972604751587, z_m: -4.492550373077393]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 85092477,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5299999713897705,
        "current_climb_rate": 0.1630990207195282,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065061,
        "gps_raw_lon_deg": 38.8900517,
        "gps_raw_abs_alt": 81.1300048828125,
        "gps_raw_velocity": 0.09999999403953552,
        "position_lat_deg": 47.306506,
        "position_lon_deg": 38.8900508,
        "position_abs_alt": 83.66100311279297,
        "position_real_alt": 4.9170002937316895,
        "heading_deg": 169.34,
        "odometry_pos_body_x": 4.648202896118164,
        "odometry_pos_body_y": 1.9071221351623535,
        "odometry_pos_body_z": -4.509416103363037,
        "odometry_vel_body_x": -0.02091154456138611,
        "odometry_vel_body_y": 0.00708160363137722,
        "odometry_vel_body_z": -0.15046344697475433,
        "odometry_ang_vel_body_roll": 0.17403781414031982,
        "odometry_ang_vel_body_pitch": -0.42891326546669006,
        "odometry_ang_vel_body_yaw": 0.5163798928260803,
        "velocity_north_m_s": 0.019999999552965164,
        "velocity_east_m_s": 0.009999999776482582,
        "velocity_down_m_s": -0.1599999964237213,
        "acceleration_forward": 1.2000529766082764,
        "acceleration_right": 5.827291011810303,
        "acceleration_down": -14.541202545166016,
        "battery_id": 0,
        "battery_voltage": 13.23000144958496,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 15,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 3693,
        "udp_total": 86,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 66
      },
      {
        "id": 99,
        "datetime": "21/02/2023 02:04:00",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4778370>, <mavsdk.mission_raw.MissionItem object at 0xa4778b38>, <mavsdk.mission_raw.MissionItem object at 0xa4778418>, <mavsdk.mission_raw.MissionItem object at 0xb5df1610>]",
        "mission_pause": 0,
        "time_usec": 85641966,
        "odometry_pos_body": "PositionBody: [x_m: 4.663093566894531, y_m: 1.9136046171188354, z_m: -4.54389762878418]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 85462134,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5199999809265137,
        "current_climb_rate": 0.1574801653623581,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065064,
        "gps_raw_lon_deg": 38.890052,
        "gps_raw_abs_alt": 81.24600219726562,
        "gps_raw_velocity": 0.10999999940395357,
        "position_lat_deg": 47.3065062,
        "position_lon_deg": 38.890051,
        "position_abs_alt": 83.70800018310547,
        "position_real_alt": 4.962000370025635,
        "heading_deg": 178.63,
        "odometry_pos_body_x": 4.67416524887085,
        "odometry_pos_body_y": 1.916732668876648,
        "odometry_pos_body_z": -4.565756320953369,
        "odometry_vel_body_x": -0.04840578883886337,
        "odometry_vel_body_y": 0.05866285413503647,
        "odometry_vel_body_z": -0.130243182182312,
        "odometry_ang_vel_body_roll": 0.9365890026092528,
        "odometry_ang_vel_body_pitch": 0.0353042408823967,
        "odometry_ang_vel_body_yaw": 0.5429760217666626,
        "velocity_north_m_s": 0.029999999329447743,
        "velocity_east_m_s": -0.029999999329447743,
        "velocity_down_m_s": -0.14999999105930328,
        "acceleration_forward": 0.965251326560974,
        "acceleration_right": -3.4981613159179688,
        "acceleration_down": -3.879964590072632,
        "battery_id": 0,
        "battery_voltage": 13.23000144958496,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 3699,
        "udp_total": 86,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 66
      },
      {
        "id": 100,
        "datetime": "21/02/2023 02:04:00",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477cbf8>, <mavsdk.mission_raw.MissionItem object at 0xa477caa8>, <mavsdk.mission_raw.MissionItem object at 0xa59109e8>, <mavsdk.mission_raw.MissionItem object at 0xa59101d8>]",
        "mission_pause": 0,
        "time_usec": 85891734,
        "odometry_pos_body": "PositionBody: [x_m: 4.689437389373779, y_m: 1.9204148054122925, z_m: -4.5927510261535645]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 85821798,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5199999809265137,
        "current_climb_rate": 0.07989387959241867,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065067,
        "gps_raw_lon_deg": 38.890052,
        "gps_raw_abs_alt": 81.2820053100586,
        "gps_raw_velocity": 0.12999999523162842,
        "position_lat_deg": 47.3065067,
        "position_lon_deg": 38.890051199999995,
        "position_abs_alt": 83.79200744628906,
        "position_real_alt": 5.045000076293945,
        "heading_deg": 191.65,
        "odometry_pos_body_x": 4.718174934387207,
        "odometry_pos_body_y": 1.9267642498016355,
        "odometry_pos_body_z": -4.622480392456055,
        "odometry_vel_body_x": -0.09297897666692734,
        "odometry_vel_body_y": 0.05166775733232498,
        "odometry_vel_body_z": -0.038665685802698135,
        "odometry_ang_vel_body_roll": 0.592274010181427,
        "odometry_ang_vel_body_pitch": -0.08707710355520248,
        "odometry_ang_vel_body_yaw": 0.4986678659915924,
        "velocity_north_m_s": 0.07999999821186066,
        "velocity_east_m_s": -0.029999999329447743,
        "velocity_down_m_s": -0.07999999821186066,
        "acceleration_forward": 0.25659051537513733,
        "acceleration_right": 0.644088089466095,
        "acceleration_down": -3.056403160095215,
        "battery_id": 0,
        "battery_voltage": 13.23000144958496,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 24,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 3721,
        "udp_total": 87,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 67
      },
      {
        "id": 101,
        "datetime": "21/02/2023 02:04:01",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa476c100>, <mavsdk.mission_raw.MissionItem object at 0xa476c040>, <mavsdk.mission_raw.MissionItem object at 0xa476cc88>, <mavsdk.mission_raw.MissionItem object at 0xa476caf0>]",
        "mission_pause": 0,
        "time_usec": 86241406,
        "odometry_pos_body": "PositionBody: [x_m: 4.743838787078857, y_m: 1.9361318349838257, z_m: -4.635365962982178]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 86151493,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5199999809265137,
        "current_climb_rate": 0.0123373968526721,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306507,
        "gps_raw_lon_deg": 38.890052,
        "gps_raw_abs_alt": 81.32400512695312,
        "gps_raw_velocity": 0.08999999612569809,
        "position_lat_deg": 47.306507,
        "position_lon_deg": 38.8900513,
        "position_abs_alt": 83.81600189208984,
        "position_real_alt": 5.071000099182129,
        "heading_deg": 198.43,
        "odometry_pos_body_x": 4.75409460067749,
        "odometry_pos_body_y": 1.940884470939636,
        "odometry_pos_body_z": -4.638338088989258,
        "odometry_vel_body_x": -0.04421120882034302,
        "odometry_vel_body_y": 0.0056194718927145,
        "odometry_vel_body_z": 0.004068729002028704,
        "odometry_ang_vel_body_roll": -0.6251587867736816,
        "odometry_ang_vel_body_pitch": -0.17257964611053467,
        "odometry_ang_vel_body_yaw": 0.43417003750801086,
        "velocity_north_m_s": 0.07999999821186066,
        "velocity_east_m_s": 0,
        "velocity_down_m_s": -0.029999999329447743,
        "acceleration_forward": -0.7271045446395874,
        "acceleration_right": -2.1272435188293457,
        "acceleration_down": -16.761510848999023,
        "battery_id": 0,
        "battery_voltage": 13.23000144958496,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 3745,
        "udp_total": 87,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 67
      },
      {
        "id": 102,
        "datetime": "21/02/2023 02:04:01",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4775b20>, <mavsdk.mission_raw.MissionItem object at 0xa4775538>, <mavsdk.mission_raw.MissionItem object at 0xa4775c88>, <mavsdk.mission_raw.MissionItem object at 0xa4775f28>]",
        "mission_pause": 0,
        "time_usec": 86541131,
        "odometry_pos_body": "PositionBody: [x_m: 4.764895439147949, y_m: 1.945007085800171, z_m: -4.648470401763916]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 86376281,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5099999904632568,
        "current_climb_rate": 0.06691788882017136,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065073,
        "gps_raw_lon_deg": 38.8900521,
        "gps_raw_abs_alt": 81.35400390625,
        "gps_raw_velocity": 0.10999999940395357,
        "position_lat_deg": 47.3065071,
        "position_lon_deg": 38.8900514,
        "position_abs_alt": 83.82400512695312,
        "position_real_alt": 5.078000068664551,
        "heading_deg": 203.46,
        "odometry_pos_body_x": 4.766252517700195,
        "odometry_pos_body_y": 1.9460612535476685,
        "odometry_pos_body_z": -4.651329517364502,
        "odometry_vel_body_x": 0.018382934853434563,
        "odometry_vel_body_y": -0.006617532577365637,
        "odometry_vel_body_z": -0.07704741507768631,
        "odometry_ang_vel_body_roll": 0.023119933903217316,
        "odometry_ang_vel_body_pitch": 0.46013161540031433,
        "odometry_ang_vel_body_yaw": 0.4743060469627381,
        "velocity_north_m_s": 0.019999999552965164,
        "velocity_east_m_s": 0,
        "velocity_down_m_s": -0.03999999910593033,
        "acceleration_forward": -0.1863292157649994,
        "acceleration_right": -4.933644771575928,
        "acceleration_down": -6.289100646972656,
        "battery_id": 0,
        "battery_voltage": 13.23000144958496,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 3768,
        "udp_total": 87,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 67
      },
      {
        "id": 103,
        "datetime": "21/02/2023 02:04:01",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4778d60>, <mavsdk.mission_raw.MissionItem object at 0xa4775718>, <mavsdk.mission_raw.MissionItem object at 0xa476cb68>, <mavsdk.mission_raw.MissionItem object at 0xa476c940>]",
        "mission_pause": 0,
        "time_usec": 86770915,
        "odometry_pos_body": "PositionBody: [x_m: 4.7698798179626465, y_m: 1.9488186836242676, z_m: -4.675454139709473]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 86556115,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5,
        "current_climb_rate": 0.05669280141592026,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065074,
        "gps_raw_lon_deg": 38.8900522,
        "gps_raw_abs_alt": 81.44700622558594,
        "gps_raw_velocity": 0.03999999910593033,
        "position_lat_deg": 47.3065072,
        "position_lon_deg": 38.8900515,
        "position_abs_alt": 83.83600616455078,
        "position_real_alt": 5.090000152587891,
        "heading_deg": 208.31,
        "odometry_pos_body_x": 4.769520282745361,
        "odometry_pos_body_y": 1.949236512184143,
        "odometry_pos_body_z": -4.687978267669678,
        "odometry_vel_body_x": 0.05775432661175728,
        "odometry_vel_body_y": -0.06721339374780655,
        "odometry_vel_body_z": -0.03579632192850113,
        "odometry_ang_vel_body_roll": 0.7659184336662292,
        "odometry_ang_vel_body_pitch": -0.10788673162460329,
        "odometry_ang_vel_body_yaw": 0.5005688071250916,
        "velocity_north_m_s": -0.019999999552965164,
        "velocity_east_m_s": 0,
        "velocity_down_m_s": -0.07999999821186066,
        "acceleration_forward": -0.22642368078231812,
        "acceleration_right": -0.6947239637374878,
        "acceleration_down": -14.15748119354248,
        "battery_id": 0,
        "battery_voltage": 13.23000144958496,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 16,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 3788,
        "udp_total": 87,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 67
      },
      {
        "id": 104,
        "datetime": "21/02/2023 02:04:02",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa591e598>, <mavsdk.mission_raw.MissionItem object at 0xa477a220>, <mavsdk.mission_raw.MissionItem object at 0xa477a8e0>, <mavsdk.mission_raw.MissionItem object at 0xa477ad48>]",
        "mission_pause": 0,
        "time_usec": 87310412,
        "odometry_pos_body": "PositionBody: [x_m: 4.767183780670166, y_m: 1.9845576286315918, z_m: -4.73367166519165]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 86890801,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.47999998927116394,
        "current_climb_rate": 0.04259128496050835,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065073,
        "gps_raw_lon_deg": 38.8900525,
        "gps_raw_abs_alt": 81.52100372314453,
        "gps_raw_velocity": 0.10999999940395357,
        "position_lat_deg": 47.3065072,
        "position_lon_deg": 38.8900516,
        "position_abs_alt": 83.8790054321289,
        "position_real_alt": 5.133000373840332,
        "heading_deg": 218.28,
        "odometry_pos_body_x": 4.771633148193359,
        "odometry_pos_body_y": 1.9931459426879885,
        "odometry_pos_body_z": -4.754238128662109,
        "odometry_vel_body_x": -0.030275626108050343,
        "odometry_vel_body_y": -0.006082172971218824,
        "odometry_vel_body_z": -0.0579398050904274,
        "odometry_ang_vel_body_roll": -0.8734118938446045,
        "odometry_ang_vel_body_pitch": -0.10936306416988371,
        "odometry_ang_vel_body_yaw": 0.4472897946834564,
        "velocity_north_m_s": -0.07999999821186066,
        "velocity_east_m_s": 0.029999999329447743,
        "velocity_down_m_s": 0,
        "acceleration_forward": -0.5271046757698059,
        "acceleration_right": 0.30710649490356445,
        "acceleration_down": -2.510669708251953,
        "battery_id": 0,
        "battery_voltage": 13.260000228881836,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 3812,
        "udp_total": 88,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 68
      },
      {
        "id": 105,
        "datetime": "21/02/2023 02:04:02",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477e958>, <mavsdk.mission_raw.MissionItem object at 0xa477e580>, <mavsdk.mission_raw.MissionItem object at 0xa477ebc8>, <mavsdk.mission_raw.MissionItem object at 0xa477a7a8>]",
        "mission_pause": 0,
        "time_usec": 87645100,
        "odometry_pos_body": "PositionBody: [x_m: 4.7800116539001465, y_m: 2.0036873817443848, z_m: -4.77952766418457]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 87190526,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.4699999988079071,
        "current_climb_rate": 0.00875362940132618,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065073,
        "gps_raw_lon_deg": 38.890053,
        "gps_raw_abs_alt": 81.5570068359375,
        "gps_raw_velocity": 0.05999999865889549,
        "position_lat_deg": 47.3065072,
        "position_lon_deg": 38.8900519,
        "position_abs_alt": 83.91000366210938,
        "position_real_alt": 5.164000034332275,
        "heading_deg": 226.57,
        "odometry_pos_body_x": 4.790621280670166,
        "odometry_pos_body_y": 2.007207155227661,
        "odometry_pos_body_z": -4.783644199371338,
        "odometry_vel_body_x": -0.022345008328557014,
        "odometry_vel_body_y": 0.10286279022693634,
        "odometry_vel_body_z": 0.006282626185566187,
        "odometry_ang_vel_body_roll": 0.357963889837265,
        "odometry_ang_vel_body_pitch": 0.10462358593940736,
        "odometry_ang_vel_body_yaw": 0.5131322145462036,
        "velocity_north_m_s": -0.03999999910593033,
        "velocity_east_m_s": 0.03999999910593033,
        "velocity_down_m_s": -0.009999999776482582,
        "acceleration_forward": 0.7564923167228699,
        "acceleration_right": 4.319337368011475,
        "acceleration_down": -14.405839920043944,
        "battery_id": 0,
        "battery_voltage": 13.260000228881836,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 0,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 3834,
        "udp_total": 88,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 68
      },
      {
        "id": 106,
        "datetime": "21/02/2023 02:04:02",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa476c0a0>, <mavsdk.mission_raw.MissionItem object at 0xa477e370>, <mavsdk.mission_raw.MissionItem object at 0xa477eb20>, <mavsdk.mission_raw.MissionItem object at 0xa477e610>]",
        "mission_pause": 0,
        "time_usec": 87944834,
        "odometry_pos_body": "PositionBody: [x_m: 4.8132734298706055, y_m: 2.0056591033935547, z_m: -4.772704601287842]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 87610134,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.4899999797344208,
        "current_climb_rate": -0.10639934986829758,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306507499999995,
        "gps_raw_lon_deg": 38.8900531,
        "gps_raw_abs_alt": 81.55900573730469,
        "gps_raw_velocity": 0.08999999612569809,
        "position_lat_deg": 47.3065074,
        "position_lon_deg": 38.8900522,
        "position_abs_alt": 83.96400451660156,
        "position_real_alt": 5.218000411987305,
        "heading_deg": 237.4,
        "odometry_pos_body_x": 4.8366804122924805,
        "odometry_pos_body_y": 1.9992709159851072,
        "odometry_pos_body_z": -4.758619785308838,
        "odometry_vel_body_x": 0.02123447135090828,
        "odometry_vel_body_y": 0.24203580617904663,
        "odometry_vel_body_z": 0.11118119210004808,
        "odometry_ang_vel_body_roll": 0.046134043484926224,
        "odometry_ang_vel_body_pitch": -0.01779361069202423,
        "odometry_ang_vel_body_yaw": 0.4778045117855072,
        "velocity_north_m_s": 0.09999999403953552,
        "velocity_east_m_s": -0.029999999329447743,
        "velocity_down_m_s": 0.009999999776482582,
        "acceleration_forward": 0.4868215024471283,
        "acceleration_right": 7.864917755126953,
        "acceleration_down": -13.011998176574709,
        "battery_id": 0,
        "battery_voltage": 13.260000228881836,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 1,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 3856,
        "udp_total": 89,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 69
      },
      {
        "id": 107,
        "datetime": "21/02/2023 02:04:03",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4778610>, <mavsdk.mission_raw.MissionItem object at 0xa47787c0>, <mavsdk.mission_raw.MissionItem object at 0xa4778820>, <mavsdk.mission_raw.MissionItem object at 0xa4778b08>]",
        "mission_pause": 0,
        "time_usec": 88244542,
        "odometry_pos_body": "PositionBody: [x_m: 4.864490509033203, y_m: 1.989054560661316, z_m: -4.754113674163818]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 87839956,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5,
        "current_climb_rate": -0.12864600121974945,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065077,
        "gps_raw_lon_deg": 38.8900529,
        "gps_raw_abs_alt": 81.552001953125,
        "gps_raw_velocity": 0.119999997317791,
        "position_lat_deg": 47.3065076,
        "position_lon_deg": 38.8900523,
        "position_abs_alt": 83.96100616455078,
        "position_real_alt": 5.214000225067139,
        "heading_deg": 241.6,
        "odometry_pos_body_x": 4.870704174041748,
        "odometry_pos_body_y": 1.9856749773025513,
        "odometry_pos_body_z": -4.753489017486572,
        "odometry_vel_body_x": 0.06483197957277298,
        "odometry_vel_body_y": 0.22533036768436432,
        "odometry_vel_body_z": 0.13340701162815094,
        "odometry_ang_vel_body_roll": -0.23729915916919708,
        "odometry_ang_vel_body_pitch": -0.04483173042535782,
        "odometry_ang_vel_body_yaw": 0.4382261633872986,
        "velocity_north_m_s": 0.14999999105930328,
        "velocity_east_m_s": -0.07000000029802322,
        "velocity_down_m_s": 0.07999999821186066,
        "acceleration_forward": 0.6352582573890686,
        "acceleration_right": -3.437032461166382,
        "acceleration_down": -5.950554847717285,
        "battery_id": 0,
        "battery_voltage": 13.260000228881836,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 3,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 3857,
        "udp_total": 89,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 69
      },
      {
        "id": 108,
        "datetime": "21/02/2023 02:04:03",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa590c238>, <mavsdk.mission_raw.MissionItem object at 0xa590ce80>, <mavsdk.mission_raw.MissionItem object at 0xb2025130>, <mavsdk.mission_raw.MissionItem object at 0xb2025b98>]",
        "mission_pause": 0,
        "time_usec": 88414384,
        "odometry_pos_body": "PositionBody: [x_m: 4.886643886566162, y_m: 1.976353406906128, z_m: -4.755694389343262]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 88099676,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5,
        "current_climb_rate": -0.12267407029867172,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306508,
        "gps_raw_lon_deg": 38.890052399999995,
        "gps_raw_abs_alt": 81.49800109863281,
        "gps_raw_velocity": 0.14000000059604645,
        "position_lat_deg": 47.306508,
        "position_lon_deg": 38.8900521,
        "position_abs_alt": 83.93700408935547,
        "position_real_alt": 5.191000461578369,
        "heading_deg": 250.27,
        "odometry_pos_body_x": 4.900633811950684,
        "odometry_pos_body_y": 1.9627232551574707,
        "odometry_pos_body_z": -4.7589545249938965,
        "odometry_vel_body_x": 0.1038476824760437,
        "odometry_vel_body_y": 0.10466866940259932,
        "odometry_vel_body_z": 0.14256182312965393,
        "odometry_ang_vel_body_roll": -0.5084241032600403,
        "odometry_ang_vel_body_pitch": -0.05949494242668152,
        "odometry_ang_vel_body_yaw": 0.41874513030052185,
        "velocity_north_m_s": 0.17999999225139618,
        "velocity_east_m_s": -0.12999999523162842,
        "velocity_down_m_s": 0.12999999523162842,
        "acceleration_forward": 0.7062193751335144,
        "acceleration_right": 5.8859710693359375,
        "acceleration_down": -15.713623046875,
        "battery_id": 0,
        "battery_voltage": 13.260000228881836,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 3879,
        "udp_total": 89,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 69
      },
      {
        "id": 109,
        "datetime": "21/02/2023 02:04:03",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4777370>, <mavsdk.mission_raw.MissionItem object at 0xa4777448>, <mavsdk.mission_raw.MissionItem object at 0xa4777bb0>, <mavsdk.mission_raw.MissionItem object at 0xa47773e8>]",
        "mission_pause": 0,
        "time_usec": 88714109,
        "odometry_pos_body": "PositionBody: [x_m: 4.906014919281006, y_m: 1.9522578716278076, z_m: -4.758187770843506]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 88429373,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5,
        "current_climb_rate": -0.10988638550043106,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306508099999995,
        "gps_raw_lon_deg": 38.8900521,
        "gps_raw_abs_alt": 81.46800231933594,
        "gps_raw_velocity": 0.12999999523162842,
        "position_lat_deg": 47.3065084,
        "position_lon_deg": 38.890051799999995,
        "position_abs_alt": 83.94000244140625,
        "position_real_alt": 5.194000244140625,
        "heading_deg": 259.42,
        "odometry_pos_body_x": 4.9111738204956055,
        "odometry_pos_body_y": 1.9408018589019775,
        "odometry_pos_body_z": -4.749291896820068,
        "odometry_vel_body_x": 0.10311736166477203,
        "odometry_vel_body_y": 0.0701332539319992,
        "odometry_vel_body_z": 0.113549642264843,
        "odometry_ang_vel_body_roll": -0.581933856010437,
        "odometry_ang_vel_body_pitch": 0.2035607099533081,
        "odometry_ang_vel_body_yaw": 0.4100995361804962,
        "velocity_north_m_s": 0.14000000059604645,
        "velocity_east_m_s": -0.14000000059604645,
        "velocity_down_m_s": 0.14999999105930328,
        "acceleration_forward": 0.2589866816997528,
        "acceleration_right": -3.481348752975464,
        "acceleration_down": -12.416911125183104,
        "battery_id": 0,
        "battery_voltage": 13.260000228881836,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 1,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 3903,
        "udp_total": 89,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 69
      },
      {
        "id": 110,
        "datetime": "21/02/2023 02:04:03",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477eec8>, <mavsdk.mission_raw.MissionItem object at 0xa477e928>, <mavsdk.mission_raw.MissionItem object at 0xa477eb68>, <mavsdk.mission_raw.MissionItem object at 0xa477ef10>]",
        "mission_pause": 0,
        "time_usec": 89043800,
        "odometry_pos_body": "PositionBody: [x_m: 4.915882587432861, y_m: 1.9212428331375122, z_m: -4.717640399932861]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 88794032,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5099999904632568,
        "current_climb_rate": -0.08320052176713943,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065082,
        "gps_raw_lon_deg": 38.8900515,
        "gps_raw_abs_alt": 81.4070053100586,
        "gps_raw_velocity": 0.03999999910593033,
        "position_lat_deg": 47.3065085,
        "position_lon_deg": 38.8900513,
        "position_abs_alt": 83.9260025024414,
        "position_real_alt": 5.180000305175781,
        "heading_deg": 266.87,
        "odometry_pos_body_x": 4.917303085327148,
        "odometry_pos_body_y": 1.9129220247268677,
        "odometry_pos_body_z": -4.695556163787842,
        "odometry_vel_body_x": 0.0669964924454689,
        "odometry_vel_body_y": 0.04398669674992561,
        "odometry_vel_body_z": 0.08762305974960327,
        "odometry_ang_vel_body_roll": 0.5610801577568054,
        "odometry_ang_vel_body_pitch": 0.09868265688419342,
        "odometry_ang_vel_body_yaw": 0.45164138078689575,
        "velocity_north_m_s": 0.04999999701976776,
        "velocity_east_m_s": -0.10999999940395357,
        "velocity_down_m_s": 0.119999997317791,
        "acceleration_forward": 3.6219732761383057,
        "acceleration_right": -0.42496514320373535,
        "acceleration_down": -12.117215156555176,
        "battery_id": 0,
        "battery_voltage": 13.296000480651855,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 7,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 3911,
        "udp_total": 90,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 70
      },
      {
        "id": 111,
        "datetime": "21/02/2023 02:04:04",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4777c88>, <mavsdk.mission_raw.MissionItem object at 0xa4777070>, <mavsdk.mission_raw.MissionItem object at 0xa4777370>, <mavsdk.mission_raw.MissionItem object at 0xa47773b8>]",
        "mission_pause": 0,
        "time_usec": 89313550,
        "odometry_pos_body": "PositionBody: [x_m: 4.915851593017578, y_m: 1.9103184938430786, z_m: -4.677333354949951]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 89048795,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.4899999797344208,
        "current_climb_rate": -0.03489258512854576,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065082,
        "gps_raw_lon_deg": 38.890051199999995,
        "gps_raw_abs_alt": 81.39200592041016,
        "gps_raw_velocity": 0.10999999940395357,
        "position_lat_deg": 47.3065086,
        "position_lon_deg": 38.8900511,
        "position_abs_alt": 83.89200592041016,
        "position_real_alt": 5.146000385284424,
        "heading_deg": 270.29,
        "odometry_pos_body_x": 4.914158344268799,
        "odometry_pos_body_y": 1.9088670015335083,
        "odometry_pos_body_z": -4.669890403747559,
        "odometry_vel_body_x": 0.01989274844527245,
        "odometry_vel_body_y": 0.0390000119805336,
        "odometry_vel_body_z": 0.04020507261157036,
        "odometry_ang_vel_body_roll": 1.0879677534103394,
        "odometry_ang_vel_body_pitch": -0.1355156451463699,
        "odometry_ang_vel_body_yaw": 0.39842841029167175,
        "velocity_north_m_s": 0.04999999701976776,
        "velocity_east_m_s": -0.08999999612569809,
        "velocity_down_m_s": 0.10999999940395357,
        "acceleration_forward": -0.07782541215419769,
        "acceleration_right": 2.8936824798583984,
        "acceleration_down": -7.794356346130371,
        "battery_id": 0,
        "battery_voltage": 13.296000480651855,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 1,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 3925,
        "udp_total": 90,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 70
      },
      {
        "id": 112,
        "datetime": "21/02/2023 02:04:04",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa591ef70>, <mavsdk.mission_raw.MissionItem object at 0xa591e058>, <mavsdk.mission_raw.MissionItem object at 0xb2012ce8>, <mavsdk.mission_raw.MissionItem object at 0xa476cec8>]",
        "mission_pause": 0,
        "time_usec": 89543334,
        "odometry_pos_body": "PositionBody: [x_m: 4.909879684448242, y_m: 1.9109575748443604, z_m: -4.649706840515137]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 89403464,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5,
        "current_climb_rate": -0.03706897795200348,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306508099999995,
        "gps_raw_lon_deg": 38.890051199999995,
        "gps_raw_abs_alt": 81.38800048828125,
        "gps_raw_velocity": 0.03999999910593033,
        "position_lat_deg": 47.3065085,
        "position_lon_deg": 38.890051,
        "position_abs_alt": 83.84400177001953,
        "position_real_alt": 5.0970001220703125,
        "heading_deg": 278.44,
        "odometry_pos_body_x": 4.90736722946167,
        "odometry_pos_body_y": 1.9145197868347168,
        "odometry_pos_body_z": -4.642402648925781,
        "odometry_vel_body_x": -0.05238143354654312,
        "odometry_vel_body_y": 0.050875771790742874,
        "odometry_vel_body_z": 0.020973030477762222,
        "odometry_ang_vel_body_roll": -0.007642702199518681,
        "odometry_ang_vel_body_pitch": -0.1192796155810356,
        "odometry_ang_vel_body_yaw": 0.37293219566345215,
        "velocity_north_m_s": 0.029999999329447743,
        "velocity_east_m_s": 0,
        "velocity_down_m_s": 0.03999999910593033,
        "acceleration_forward": -0.6433398127555847,
        "acceleration_right": -2.09895396232605,
        "acceleration_down": -6.851815223693848,
        "battery_id": 0,
        "battery_voltage": 13.296000480651855,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 3950,
        "udp_total": 90,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 70
      },
      {
        "id": 113,
        "datetime": "21/02/2023 02:04:04",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa58fca78>, <mavsdk.mission_raw.MissionItem object at 0xa477caf0>, <mavsdk.mission_raw.MissionItem object at 0xa477c6d0>, <mavsdk.mission_raw.MissionItem object at 0xa477c718>]",
        "mission_pause": 0,
        "time_usec": 89843056,
        "odometry_pos_body": "PositionBody: [x_m: 4.901488780975342, y_m: 1.924601435661316, z_m: -4.629310131072998]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 89703188,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.4899999797344208,
        "current_climb_rate": -0.011100176721811296,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306508,
        "gps_raw_lon_deg": 38.8900515,
        "gps_raw_abs_alt": 81.38300323486328,
        "gps_raw_velocity": 0.07999999821186066,
        "position_lat_deg": 47.3065084,
        "position_lon_deg": 38.890051199999995,
        "position_abs_alt": 83.81100463867188,
        "position_real_alt": 5.065000057220459,
        "heading_deg": 286.31,
        "odometry_pos_body_x": 4.893976211547852,
        "odometry_pos_body_y": 1.9356753826141355,
        "odometry_pos_body_z": -4.641276836395264,
        "odometry_vel_body_x": -0.024447046220302585,
        "odometry_vel_body_y": 0.07179827243089676,
        "odometry_vel_body_z": 0.015990281477570534,
        "odometry_ang_vel_body_roll": 0.2903798818588257,
        "odometry_ang_vel_body_pitch": 0.29510870575904846,
        "odometry_ang_vel_body_yaw": 0.28280818462371826,
        "velocity_north_m_s": 0.029999999329447743,
        "velocity_east_m_s": 0.04999999701976776,
        "velocity_down_m_s": 0,
        "acceleration_forward": -2.1242377758026123,
        "acceleration_right": -3.887176275253296,
        "acceleration_down": -11.464951515197754,
        "battery_id": 0,
        "battery_voltage": 13.296000480651855,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 3972,
        "udp_total": 90,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 70
      },
      {
        "id": 114,
        "datetime": "21/02/2023 02:04:04",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa59132b0>, <mavsdk.mission_raw.MissionItem object at 0xa4775d78>, <mavsdk.mission_raw.MissionItem object at 0xa5910bf8>, <mavsdk.mission_raw.MissionItem object at 0xa59109d0>]",
        "mission_pause": 0,
        "time_usec": 90182744,
        "odometry_pos_body": "PositionBody: [x_m: 4.893372058868408, y_m: 1.9469176530838013, z_m: -4.643692970275879]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 90162760,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5099999904632568,
        "current_climb_rate": -0.09548764675855637,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306508099999995,
        "gps_raw_lon_deg": 38.890052,
        "gps_raw_abs_alt": 81.38900756835938,
        "gps_raw_velocity": 0.03999999910593033,
        "position_lat_deg": 47.3065084,
        "position_lon_deg": 38.8900515,
        "position_abs_alt": 83.82600402832031,
        "position_real_alt": 5.080000400543213,
        "heading_deg": 293.64,
        "odometry_pos_body_x": 4.895984172821045,
        "odometry_pos_body_y": 1.9557340145111084,
        "odometry_pos_body_z": -4.640865802764893,
        "odometry_vel_body_x": -0.0005632565589621663,
        "odometry_vel_body_y": 0.1290561854839325,
        "odometry_vel_body_z": 0.09619693458080292,
        "odometry_ang_vel_body_roll": -0.510032057762146,
        "odometry_ang_vel_body_pitch": 0.20592352747917175,
        "odometry_ang_vel_body_yaw": 0.18550683557987213,
        "velocity_north_m_s": 0.07000000029802322,
        "velocity_east_m_s": 0.03999999910593033,
        "velocity_down_m_s": 0.08999999612569809,
        "acceleration_forward": 0.9585005640983582,
        "acceleration_right": -0.1077251136302948,
        "acceleration_down": -4.035089015960693,
        "battery_id": 0,
        "battery_voltage": 13.296000480651855,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 0,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 3996,
        "udp_total": 91,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 71
      },
      {
        "id": 115,
        "datetime": "21/02/2023 02:04:05",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4775e38>, <mavsdk.mission_raw.MissionItem object at 0xa4775a30>, <mavsdk.mission_raw.MissionItem object at 0xa4775b68>, <mavsdk.mission_raw.MissionItem object at 0xa47751f0>]",
        "mission_pause": 0,
        "time_usec": 90572382,
        "odometry_pos_body": "PositionBody: [x_m: 4.916728496551514, y_m: 1.9739389419555664, z_m: -4.631488800048828]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 90552398,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5099999904632568,
        "current_climb_rate": -0.09493722021579742,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306508099999995,
        "gps_raw_lon_deg": 38.8900521,
        "gps_raw_abs_alt": 81.3740005493164,
        "gps_raw_velocity": 0.029999999329447743,
        "position_lat_deg": 47.306508699999995,
        "position_lon_deg": 38.8900519,
        "position_abs_alt": 83.81000518798828,
        "position_real_alt": 5.063000202178955,
        "heading_deg": 298.40000000000003,
        "odometry_pos_body_x": 4.936967372894287,
        "odometry_pos_body_y": 1.9853461980819704,
        "odometry_pos_body_z": -4.62578010559082,
        "odometry_vel_body_x": 0.03862639144062996,
        "odometry_vel_body_y": 0.32080674171447754,
        "odometry_vel_body_z": 0.05092794820666313,
        "odometry_ang_vel_body_roll": 0.773761510848999,
        "odometry_ang_vel_body_pitch": 0.1868048906326294,
        "odometry_ang_vel_body_yaw": 0.2418215125799179,
        "velocity_north_m_s": 0.25999999046325684,
        "velocity_east_m_s": 0.09999999403953552,
        "velocity_down_m_s": 0.08999999612569809,
        "acceleration_forward": -1.8491944074630735,
        "acceleration_right": -5.239989757537842,
        "acceleration_down": -5.15944242477417,
        "battery_id": 0,
        "battery_voltage": 13.296000480651855,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 12,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 4008,
        "udp_total": 91,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 71
      },
      {
        "id": 116,
        "datetime": "21/02/2023 02:04:05",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4778208>, <mavsdk.mission_raw.MissionItem object at 0xa4777c40>, <mavsdk.mission_raw.MissionItem object at 0xa47777f0>, <mavsdk.mission_raw.MissionItem object at 0xa4780448>]",
        "mission_pause": 0,
        "time_usec": 90842129,
        "odometry_pos_body": "PositionBody: [x_m: 4.973596572875977, y_m: 2.0074868202209473, z_m: -4.615224838256836]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 90827144,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5099999904632568,
        "current_climb_rate": -0.07511118799448013,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065088,
        "gps_raw_lon_deg": 38.8900528,
        "gps_raw_abs_alt": 81.33200073242188,
        "gps_raw_velocity": 0.23999999463558197,
        "position_lat_deg": 47.3065092,
        "position_lon_deg": 38.890052399999995,
        "position_abs_alt": 83.79300689697266,
        "position_real_alt": 5.046000003814697,
        "heading_deg": 302.22,
        "odometry_pos_body_x": 4.992738246917725,
        "odometry_pos_body_y": 2.0185484886169434,
        "odometry_pos_body_z": -4.610984802246094,
        "odometry_vel_body_x": 0.06447537243366241,
        "odometry_vel_body_y": 0.40601471066474915,
        "odometry_vel_body_z": 0.06631776690483093,
        "odometry_ang_vel_body_roll": -0.4048267900943756,
        "odometry_ang_vel_body_pitch": 0.01750156842172146,
        "odometry_ang_vel_body_yaw": 0.25478577613830566,
        "velocity_north_m_s": 0.38999998569488525,
        "velocity_east_m_s": 0.14999999105930328,
        "velocity_down_m_s": 0.07000000029802322,
        "acceleration_forward": 1.228766679763794,
        "acceleration_right": 5.746469020843506,
        "acceleration_down": -10.154223442077637,
        "battery_id": 0,
        "battery_voltage": 13.296000480651855,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 4018,
        "udp_total": 91,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 71
      },
      {
        "id": 117,
        "datetime": "21/02/2023 02:04:05",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa5910fa0>, <mavsdk.mission_raw.MissionItem object at 0xa477aa18>, <mavsdk.mission_raw.MissionItem object at 0xa477a490>, <mavsdk.mission_raw.MissionItem object at 0xa477a628>]",
        "mission_pause": 0,
        "time_usec": 91041946,
        "odometry_pos_body": "PositionBody: [x_m: 5.035396099090576, y_m: 2.0455217361450195, z_m: -4.598665714263916]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 91116878,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5199999809265137,
        "current_climb_rate": -0.07787425816059113,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065094,
        "gps_raw_lon_deg": 38.8900533,
        "gps_raw_abs_alt": 81.31500244140625,
        "gps_raw_velocity": 0.3400000035762787,
        "position_lat_deg": 47.306509899999995,
        "position_lon_deg": 38.890053,
        "position_abs_alt": 83.77100372314453,
        "position_real_alt": 5.022000312805176,
        "heading_deg": 306.72,
        "odometry_pos_body_x": 5.071488857269287,
        "odometry_pos_body_y": 2.071458578109741,
        "odometry_pos_body_z": -4.584013938903809,
        "odometry_vel_body_x": 0.04982251673936844,
        "odometry_vel_body_y": 0.35127392411231995,
        "odometry_vel_body_z": 0.0818195566534996,
        "odometry_ang_vel_body_roll": 0.31671571731567383,
        "odometry_ang_vel_body_pitch": -0.06198405101895332,
        "odometry_ang_vel_body_yaw": 0.24436093866825104,
        "velocity_north_m_s": 0.3100000023841858,
        "velocity_east_m_s": 0.1599999964237213,
        "velocity_down_m_s": 0.07999999821186066,
        "acceleration_forward": 0.20510494709014893,
        "acceleration_right": 2.3730411529541016,
        "acceleration_down": -12.912569999694824,
        "battery_id": 0,
        "battery_voltage": 13.293001174926758,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 4042,
        "udp_total": 93,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 72
      },
      {
        "id": 118,
        "datetime": "21/02/2023 02:04:06",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa5913070>, <mavsdk.mission_raw.MissionItem object at 0xa59131d8>, <mavsdk.mission_raw.MissionItem object at 0xa477c7d8>, <mavsdk.mission_raw.MissionItem object at 0xa477e688>]",
        "mission_pause": 0,
        "time_usec": 91371638,
        "odometry_pos_body": "PositionBody: [x_m: 5.109278678894043, y_m: 2.101283550262451, z_m: -4.564131259918213]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 91426588,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5299999713897705,
        "current_climb_rate": -0.08975125849246979,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065104,
        "gps_raw_lon_deg": 38.8900544,
        "gps_raw_abs_alt": 81.28300476074219,
        "gps_raw_velocity": 0.2800000011920929,
        "position_lat_deg": 47.306510499999995,
        "position_lon_deg": 38.8900539,
        "position_abs_alt": 83.72500610351562,
        "position_real_alt": 4.979000091552734,
        "heading_deg": 313.14,
        "odometry_pos_body_x": 5.136965751647949,
        "odometry_pos_body_y": 2.127815961837769,
        "odometry_pos_body_z": -4.542725563049316,
        "odometry_vel_body_x": 0.012686856091022491,
        "odometry_vel_body_y": 0.2355973720550537,
        "odometry_vel_body_z": 0.06653731316328049,
        "odometry_ang_vel_body_roll": 0.9899393916130066,
        "odometry_ang_vel_body_pitch": 0.05940081179141998,
        "odometry_ang_vel_body_yaw": 0.39213132858276367,
        "velocity_north_m_s": 0.1599999964237213,
        "velocity_east_m_s": 0.14000000059604645,
        "velocity_down_m_s": 0.03999999910593033,
        "acceleration_forward": 0.050924427807331085,
        "acceleration_right": -1.883214831352234,
        "acceleration_down": -12.085419654846191,
        "battery_id": 0,
        "battery_voltage": 13.293001174926758,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 28,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 4068,
        "udp_total": 93,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 72
      },
      {
        "id": 119,
        "datetime": "21/02/2023 02:04:06",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa58fcb68>, <mavsdk.mission_raw.MissionItem object at 0xa58fca78>, <mavsdk.mission_raw.MissionItem object at 0xa591e490>, <mavsdk.mission_raw.MissionItem object at 0xa591e130>]",
        "mission_pause": 0,
        "time_usec": 91671361,
        "odometry_pos_body": "PositionBody: [x_m: 5.154780864715576, y_m: 2.151217222213745, z_m: -4.521930694580078]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 91721316,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5299999713897705,
        "current_climb_rate": -0.042106300592422485,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065107,
        "gps_raw_lon_deg": 38.8900548,
        "gps_raw_abs_alt": 81.27900695800781,
        "gps_raw_velocity": 0.2299999892711639,
        "position_lat_deg": 47.3065108,
        "position_lon_deg": 38.8900544,
        "position_abs_alt": 83.68600463867188,
        "position_real_alt": 4.940000057220459,
        "heading_deg": 319.90000000000003,
        "odometry_pos_body_x": 5.171567440032959,
        "odometry_pos_body_y": 2.1761014461517334,
        "odometry_pos_body_z": -4.499440670013428,
        "odometry_vel_body_x": -0.008114262484014034,
        "odometry_vel_body_y": 0.07381932437419891,
        "odometry_vel_body_z": 0.000932961527723819,
        "odometry_ang_vel_body_roll": 0.4473819732666016,
        "odometry_ang_vel_body_pitch": 0.08159688115119934,
        "odometry_ang_vel_body_yaw": 0.3875371813774109,
        "velocity_north_m_s": 0.03999999910593033,
        "velocity_east_m_s": 0.07000000029802322,
        "velocity_down_m_s": 0.019999999552965164,
        "acceleration_forward": -0.14351390302181244,
        "acceleration_right": -1.6138434410095217,
        "acceleration_down": -6.479918956756592,
        "battery_id": 0,
        "battery_voltage": 13.293001174926758,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 4092,
        "udp_total": 93,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 72
      },
      {
        "id": 120,
        "datetime": "21/02/2023 02:04:06",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4778340>, <mavsdk.mission_raw.MissionItem object at 0xa477c7d8>, <mavsdk.mission_raw.MissionItem object at 0xa477c3a0>, <mavsdk.mission_raw.MissionItem object at 0xa476c790>]",
        "mission_pause": 0,
        "time_usec": 92041026,
        "odometry_pos_body": "PositionBody: [x_m: 5.177953243255615, y_m: 2.193237781524658, z_m: -4.489254951477051]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 92080982,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5199999809265137,
        "current_climb_rate": 0.06287793815135956,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065109,
        "gps_raw_lon_deg": 38.8900552,
        "gps_raw_abs_alt": 81.2820053100586,
        "gps_raw_velocity": 0.03999999910593033,
        "position_lat_deg": 47.3065109,
        "position_lon_deg": 38.8900548,
        "position_abs_alt": 83.67000579833984,
        "position_real_alt": 4.924000263214111,
        "heading_deg": 325.28000000000003,
        "odometry_pos_body_x": 5.177883625030518,
        "odometry_pos_body_y": 2.198312759399414,
        "odometry_pos_body_z": -4.487616062164307,
        "odometry_vel_body_x": -0.02718610130250454,
        "odometry_vel_body_y": 0.03416785970330238,
        "odometry_vel_body_z": -0.06362279504537582,
        "odometry_ang_vel_body_roll": -0.13043200969696045,
        "odometry_ang_vel_body_pitch": -0.04490893706679344,
        "odometry_ang_vel_body_yaw": 0.20505806803703308,
        "velocity_north_m_s": 0,
        "velocity_east_m_s": 0.03999999910593033,
        "velocity_down_m_s": -0.04999999701976776,
        "acceleration_forward": 0.5686115026473999,
        "acceleration_right": 0.9046711921691896,
        "acceleration_down": -6.265449523925781,
        "battery_id": 0,
        "battery_voltage": 13.293001174926758,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 3,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 4114,
        "udp_total": 94,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 73
      },
      {
        "id": 121,
        "datetime": "21/02/2023 02:04:07",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa590cb08>, <mavsdk.mission_raw.MissionItem object at 0xa58fcc88>, <mavsdk.mission_raw.MissionItem object at 0xa58f2328>, <mavsdk.mission_raw.MissionItem object at 0xa476c4d8>]",
        "mission_pause": 0,
        "time_usec": 92280796,
        "odometry_pos_body": "PositionBody: [x_m: 5.172351837158203, y_m: 2.207692861557007, z_m: -4.486316680908203]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 92300781,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5199999809265137,
        "current_climb_rate": 0.07613340020179749,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065108,
        "gps_raw_lon_deg": 38.8900554,
        "gps_raw_abs_alt": 81.3030014038086,
        "gps_raw_velocity": 0.07000000029802322,
        "position_lat_deg": 47.3065108,
        "position_lon_deg": 38.890055,
        "position_abs_alt": 83.66900634765625,
        "position_real_alt": 4.923000335693359,
        "heading_deg": 329.2,
        "odometry_pos_body_x": 5.167631149291992,
        "odometry_pos_body_y": 2.2132842540740967,
        "odometry_pos_body_z": -4.487696647644043,
        "odometry_vel_body_x": -0.04605168476700783,
        "odometry_vel_body_y": -0.005656297318637371,
        "odometry_vel_body_z": -0.07488357275724411,
        "odometry_ang_vel_body_roll": 0.09374230355024338,
        "odometry_ang_vel_body_pitch": -0.226073756814003,
        "odometry_ang_vel_body_yaw": 0.4093915820121765,
        "velocity_north_m_s": -0.03999999910593033,
        "velocity_east_m_s": 0.009999999776482582,
        "velocity_down_m_s": -0.07999999821186066,
        "acceleration_forward": 2.580387830734253,
        "acceleration_right": 0.7048031091690063,
        "acceleration_down": -9.426692962646484,
        "battery_id": 0,
        "battery_voltage": 13.293001174926758,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 3,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 4114,
        "udp_total": 94,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 73
      },
      {
        "id": 122,
        "datetime": "21/02/2023 02:04:07",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477cd48>, <mavsdk.mission_raw.MissionItem object at 0xa477ca90>, <mavsdk.mission_raw.MissionItem object at 0xa477ccb8>, <mavsdk.mission_raw.MissionItem object at 0xa477c400>]",
        "mission_pause": 0,
        "time_usec": 92475660,
        "odometry_pos_body": "PositionBody: [x_m: 5.162675380706787, y_m: 2.2174572944641113, z_m: -4.489339351654053]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 92540558,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5199999809265137,
        "current_climb_rate": 0.07789786159992218,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065107,
        "gps_raw_lon_deg": 38.8900553,
        "gps_raw_abs_alt": 81.32500457763672,
        "gps_raw_velocity": 0.04999999701976776,
        "position_lat_deg": 47.3065107,
        "position_lon_deg": 38.8900551,
        "position_abs_alt": 83.67500305175781,
        "position_real_alt": 4.929000377655029,
        "heading_deg": 333.44,
        "odometry_pos_body_x": 5.150991439819336,
        "odometry_pos_body_y": 2.2221193313598633,
        "odometry_pos_body_z": -4.496662139892578,
        "odometry_vel_body_x": -0.048186007887125015,
        "odometry_vel_body_y": -0.025125281885266304,
        "odometry_vel_body_z": -0.1009279489517212,
        "odometry_ang_vel_body_roll": -0.38024768233299255,
        "odometry_ang_vel_body_pitch": -0.11202094703912736,
        "odometry_ang_vel_body_yaw": 0.3261992931365967,
        "velocity_north_m_s": -0.04999999701976776,
        "velocity_east_m_s": 0,
        "velocity_down_m_s": -0.07000000029802322,
        "acceleration_forward": -0.07310882955789566,
        "acceleration_right": 0.2989862561225891,
        "acceleration_down": -12.593429565429688,
        "battery_id": 0,
        "battery_voltage": 13.293001174926758,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 1,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 4139,
        "udp_total": 94,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 73
      },
      {
        "id": 123,
        "datetime": "21/02/2023 02:04:07",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa476ce68>, <mavsdk.mission_raw.MissionItem object at 0xa476c5b0>, <mavsdk.mission_raw.MissionItem object at 0xa476c4a8>, <mavsdk.mission_raw.MissionItem object at 0xa476cf58>]",
        "mission_pause": 0,
        "time_usec": 92845277,
        "odometry_pos_body": "PositionBody: [x_m: 5.136733055114746, y_m: 2.221837282180786, z_m: -4.507547855377197]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 92740374,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5099999904632568,
        "current_climb_rate": 0.0960431545972824,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065104,
        "gps_raw_lon_deg": 38.8900554,
        "gps_raw_abs_alt": 81.3740005493164,
        "gps_raw_velocity": 0.12999999523162842,
        "position_lat_deg": 47.3065106,
        "position_lon_deg": 38.8900551,
        "position_abs_alt": 83.68400573730469,
        "position_real_alt": 4.938000202178955,
        "heading_deg": 336.79,
        "odometry_pos_body_x": 5.127907752990723,
        "odometry_pos_body_y": 2.222008228302002,
        "odometry_pos_body_z": -4.512719631195068,
        "odometry_vel_body_x": -0.04301807284355163,
        "odometry_vel_body_y": -0.028139326721429825,
        "odometry_vel_body_z": -0.08522144705057144,
        "odometry_ang_vel_body_roll": 0.5056944489479065,
        "odometry_ang_vel_body_pitch": 0.2011670023202896,
        "odometry_ang_vel_body_yaw": 0.36747464537620544,
        "velocity_north_m_s": -0.05999999865889549,
        "velocity_east_m_s": -0.019999999552965164,
        "velocity_down_m_s": -0.08999999612569809,
        "acceleration_forward": -0.9961220622062684,
        "acceleration_right": -2.5314249992370605,
        "acceleration_down": -12.779813766479492,
        "battery_id": 0,
        "battery_voltage": 13.293001174926758,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 4161,
        "udp_total": 94,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 73
      },
      {
        "id": 124,
        "datetime": "21/02/2023 02:04:07",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa476c940>, <mavsdk.mission_raw.MissionItem object at 0xa4777f40>, <mavsdk.mission_raw.MissionItem object at 0xa4777df0>, <mavsdk.mission_raw.MissionItem object at 0xa47773e8>]",
        "mission_pause": 0,
        "time_usec": 93144997,
        "odometry_pos_body": "PositionBody: [x_m: 5.109704494476318, y_m: 2.222994327545166, z_m: -4.521762847900391]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 92990141,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5099999904632568,
        "current_climb_rate": 0.05721235275268555,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065102,
        "gps_raw_lon_deg": 38.8900552,
        "gps_raw_abs_alt": 81.44300079345703,
        "gps_raw_velocity": 0.029999999329447743,
        "position_lat_deg": 47.3065104,
        "position_lon_deg": 38.8900551,
        "position_abs_alt": 83.70000457763672,
        "position_real_alt": 4.953000068664551,
        "heading_deg": 341.78000000000003,
        "odometry_pos_body_x": 5.098323345184326,
        "odometry_pos_body_y": 2.223073720932007,
        "odometry_pos_body_z": -4.527631759643555,
        "odometry_vel_body_x": 0.018564125522971153,
        "odometry_vel_body_y": 0.022630030289292336,
        "odometry_vel_body_z": -0.0655001774430275,
        "odometry_ang_vel_body_roll": 0.5708696246147156,
        "odometry_ang_vel_body_pitch": 0.22877246141433716,
        "odometry_ang_vel_body_yaw": 0.2771871089935303,
        "velocity_north_m_s": -0.03999999910593033,
        "velocity_east_m_s": 0,
        "velocity_down_m_s": -0.07999999821186066,
        "acceleration_forward": 2.0058274269104004,
        "acceleration_right": 0.5045047998428345,
        "acceleration_down": -13.824261665344238,
        "battery_id": 0,
        "battery_voltage": 13.277999877929688,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 0,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 4185,
        "udp_total": 95,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 74
      },
      {
        "id": 125,
        "datetime": "21/02/2023 02:04:08",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa5910fa0>, <mavsdk.mission_raw.MissionItem object at 0xa5910748>, <mavsdk.mission_raw.MissionItem object at 0xa5910088>, <mavsdk.mission_raw.MissionItem object at 0xa4777f28>]",
        "mission_pause": 0,
        "time_usec": 93574598,
        "odometry_pos_body": "PositionBody: [x_m: 5.099360942840576, y_m: 2.2237584590911865, z_m: -4.5311150550842285]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.25]",
        "timestamp": 93219928,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5199999809265137,
        "current_climb_rate": 0.03507641330361366,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065102,
        "gps_raw_lon_deg": 38.8900553,
        "gps_raw_abs_alt": 81.49700164794922,
        "gps_raw_velocity": 0.009999999776482582,
        "position_lat_deg": 47.3065103,
        "position_lon_deg": 38.8900551,
        "position_abs_alt": 83.70700073242188,
        "position_real_alt": 4.960000038146973,
        "heading_deg": 345.48,
        "odometry_pos_body_x": 5.101536750793457,
        "odometry_pos_body_y": 2.225825071334839,
        "odometry_pos_body_z": -4.533217430114746,
        "odometry_vel_body_x": 0.0393262580037117,
        "odometry_vel_body_y": 0.04259046912193298,
        "odometry_vel_body_z": -0.03637066483497619,
        "odometry_ang_vel_body_roll": 0.2537856698036194,
        "odometry_ang_vel_body_pitch": 0.0770966112613678,
        "odometry_ang_vel_body_yaw": 0.10705577582120895,
        "velocity_north_m_s": 0,
        "velocity_east_m_s": 0.009999999776482582,
        "velocity_down_m_s": -0.08999999612569809,
        "acceleration_forward": -0.1188305765390396,
        "acceleration_right": 3.3127121925354004,
        "acceleration_down": -13.395037651062012,
        "battery_id": 0,
        "battery_voltage": 13.277999877929688,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 1,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 4207,
        "udp_total": 95,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 74
      },
      {
        "id": 126,
        "datetime": "21/02/2023 02:04:08",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa59101d8>, <mavsdk.mission_raw.MissionItem object at 0xa5910da8>, <mavsdk.mission_raw.MissionItem object at 0xa5910580>, <mavsdk.mission_raw.MissionItem object at 0xa58f2550>]",
        "mission_pause": 0,
        "time_usec": 93914285,
        "odometry_pos_body": "PositionBody: [x_m: 5.106074333190918, y_m: 2.2351858615875244, z_m: -4.536348342895508]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.12]",
        "timestamp": 93399761,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5199999809265137,
        "current_climb_rate": 0.007838954217731953,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065102,
        "gps_raw_lon_deg": 38.8900554,
        "gps_raw_abs_alt": 81.531005859375,
        "gps_raw_velocity": 0.009999999776482582,
        "position_lat_deg": 47.3065102,
        "position_lon_deg": 38.8900551,
        "position_abs_alt": 83.71000671386719,
        "position_real_alt": 4.964000225067139,
        "heading_deg": 348.53000000000003,
        "odometry_pos_body_x": 5.106534481048584,
        "odometry_pos_body_y": 2.2372636795043945,
        "odometry_pos_body_z": -4.537756443023682,
        "odometry_vel_body_x": 0.05937033146619797,
        "odometry_vel_body_y": 0.08062174171209335,
        "odometry_vel_body_z": -0.00852987077087164,
        "odometry_ang_vel_body_roll": -0.878093957901001,
        "odometry_ang_vel_body_pitch": -0.05654195323586464,
        "odometry_ang_vel_body_yaw": 0.028495967388153076,
        "velocity_north_m_s": 0.019999999552965164,
        "velocity_east_m_s": 0.009999999776482582,
        "velocity_down_m_s": -0.04999999701976776,
        "acceleration_forward": -2.0413622856140137,
        "acceleration_right": 3.265204668045044,
        "acceleration_down": -13.218260765075684,
        "battery_id": 0,
        "battery_voltage": 13.277999877929688,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 22,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 4231,
        "udp_total": 96,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 75
      },
      {
        "id": 127,
        "datetime": "21/02/2023 02:04:08",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477e028>, <mavsdk.mission_raw.MissionItem object at 0xa477ad78>, <mavsdk.mission_raw.MissionItem object at 0xa477ae20>, <mavsdk.mission_raw.MissionItem object at 0xa477a2f8>]",
        "mission_pause": 0,
        "time_usec": 94214008,
        "odometry_pos_body": "PositionBody: [x_m: 5.116761207580566, y_m: 2.245187997817993, z_m: -4.534573078155518]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.12]",
        "timestamp": 93799392,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5299999713897705,
        "current_climb_rate": -0.002287724055349827,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065104,
        "gps_raw_lon_deg": 38.8900556,
        "gps_raw_abs_alt": 81.54400634765625,
        "gps_raw_velocity": 0.05999999865889549,
        "position_lat_deg": 47.3065103,
        "position_lon_deg": 38.8900553,
        "position_abs_alt": 83.71800231933594,
        "position_real_alt": 4.9720001220703125,
        "heading_deg": 353.01,
        "odometry_pos_body_x": 5.125272274017334,
        "odometry_pos_body_y": 2.246762990951538,
        "odometry_pos_body_z": -4.531332015991211,
        "odometry_vel_body_x": 0.07425887882709503,
        "odometry_vel_body_y": 0.031469229608774185,
        "odometry_vel_body_z": 0.012844270095229149,
        "odometry_ang_vel_body_roll": -0.7855562567710876,
        "odometry_ang_vel_body_pitch": -0.10335202515125276,
        "odometry_ang_vel_body_yaw": -0.008306384086608887,
        "velocity_north_m_s": 0.04999999701976776,
        "velocity_east_m_s": 0.05999999865889549,
        "velocity_down_m_s": -0.019999999552965164,
        "acceleration_forward": 0.1835639327764511,
        "acceleration_right": 2.2339069843292236,
        "acceleration_down": -13.28327178955078,
        "battery_id": 0,
        "battery_voltage": 13.277999877929688,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 0,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 4253,
        "udp_total": 96,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 75
      },
      {
        "id": 128,
        "datetime": "21/02/2023 02:04:09",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477a250>, <mavsdk.mission_raw.MissionItem object at 0xa477a6a0>, <mavsdk.mission_raw.MissionItem object at 0xa477a040>, <mavsdk.mission_raw.MissionItem object at 0xa4778b80>]",
        "mission_pause": 0,
        "time_usec": 94593658,
        "odometry_pos_body": "PositionBody: [x_m: 5.138214588165283, y_m: 2.2472403049468994, z_m: -4.526381492614746]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.12]",
        "timestamp": 94069143,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5299999713897705,
        "current_climb_rate": -0.006122360471636057,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065106,
        "gps_raw_lon_deg": 38.8900557,
        "gps_raw_abs_alt": 81.5580062866211,
        "gps_raw_velocity": 0.07999999821186066,
        "position_lat_deg": 47.3065103,
        "position_lon_deg": 38.8900554,
        "position_abs_alt": 83.71900177001953,
        "position_real_alt": 4.9730000495910645,
        "heading_deg": 353.83,
        "odometry_pos_body_x": 5.143093585968018,
        "odometry_pos_body_y": 2.2472376823425293,
        "odometry_pos_body_z": -4.523142337799072,
        "odometry_vel_body_x": 0.06424186378717422,
        "odometry_vel_body_y": 0.004386083222925663,
        "odometry_vel_body_z": -0.005463398061692715,
        "odometry_ang_vel_body_roll": -0.05274056643247605,
        "odometry_ang_vel_body_pitch": 0.07922926545143127,
        "odometry_ang_vel_body_yaw": 0.02511393465101719,
        "velocity_north_m_s": 0.07000000029802322,
        "velocity_east_m_s": 0.04999999701976776,
        "velocity_down_m_s": 0.009999999776482582,
        "acceleration_forward": 0.2922855615615845,
        "acceleration_right": -1.0195379257202148,
        "acceleration_down": -11.732504844665527,
        "battery_id": 0,
        "battery_voltage": 13.277999877929688,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 0,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 4253,
        "udp_total": 96,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 75
      },
      {
        "id": 129,
        "datetime": "21/02/2023 02:04:09",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477e178>, <mavsdk.mission_raw.MissionItem object at 0xa477eca0>, <mavsdk.mission_raw.MissionItem object at 0xa477e190>, <mavsdk.mission_raw.MissionItem object at 0xa477e5e0>]",
        "mission_pause": 0,
        "time_usec": 94763500,
        "odometry_pos_body": "PositionBody: [x_m: 5.1479668617248535, y_m: 2.2447924613952637, z_m: -4.520913124084473]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.12]",
        "timestamp": 94308926,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5299999713897705,
        "current_climb_rate": 0.001886626589111984,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065107,
        "gps_raw_lon_deg": 38.8900557,
        "gps_raw_abs_alt": 81.56800079345703,
        "gps_raw_velocity": 0.07000000029802322,
        "position_lat_deg": 47.3065104,
        "position_lon_deg": 38.890055499999995,
        "position_abs_alt": 83.71500396728516,
        "position_real_alt": 4.969000339508057,
        "heading_deg": 353.73,
        "odometry_pos_body_x": 5.154517650604248,
        "odometry_pos_body_y": 2.2384846210479736,
        "odometry_pos_body_z": -4.516915321350098,
        "odometry_vel_body_x": 0.05181589722633362,
        "odometry_vel_body_y": -0.03938400000333786,
        "odometry_vel_body_z": 0.014954890124499798,
        "odometry_ang_vel_body_roll": 0.2173153460025787,
        "odometry_ang_vel_body_pitch": -0.21927274763584137,
        "odometry_ang_vel_body_yaw": -0.014030877500772476,
        "velocity_north_m_s": 0.07000000029802322,
        "velocity_east_m_s": 0.029999999329447743,
        "velocity_down_m_s": -0.009999999776482582,
        "acceleration_forward": -1.4874979257583618,
        "acceleration_right": -1.0138295888900757,
        "acceleration_down": -13.949418067932127,
        "battery_id": 0,
        "battery_voltage": 13.277999877929688,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 1,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 4277,
        "udp_total": 96,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 75
      },
      {
        "id": 130,
        "datetime": "21/02/2023 02:04:09",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477d1d8>, <mavsdk.mission_raw.MissionItem object at 0xa477d130>, <mavsdk.mission_raw.MissionItem object at 0xa477d1c0>, <mavsdk.mission_raw.MissionItem object at 0xa477df40>]",
        "mission_pause": 0,
        "time_usec": 95023267,
        "odometry_pos_body": "PositionBody: [x_m: 5.158275127410889, y_m: 2.2309470176696777, z_m: -4.514887809753418]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.12]",
        "timestamp": 94638627,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5399999618530273,
        "current_climb_rate": -0.001700853114016354,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065109,
        "gps_raw_lon_deg": 38.8900556,
        "gps_raw_abs_alt": 81.61200714111328,
        "gps_raw_velocity": 0.07000000029802322,
        "position_lat_deg": 47.3065106,
        "position_lon_deg": 38.890055499999995,
        "position_abs_alt": 83.70500183105469,
        "position_real_alt": 4.959000110626221,
        "heading_deg": 353.77,
        "odometry_pos_body_x": 5.161457061767578,
        "odometry_pos_body_y": 2.220384359359741,
        "odometry_pos_body_z": -4.511180400848389,
        "odometry_vel_body_x": 0.03006530739367008,
        "odometry_vel_body_y": -0.06889116764068604,
        "odometry_vel_body_z": 0.029623480513691906,
        "odometry_ang_vel_body_roll": -0.7387427687644958,
        "odometry_ang_vel_body_pitch": -0.3080902695655823,
        "odometry_ang_vel_body_yaw": -0.0648617297410965,
        "velocity_north_m_s": 0.05999999865889549,
        "velocity_east_m_s": -0.009999999776482582,
        "velocity_down_m_s": 0.009999999776482582,
        "acceleration_forward": 0.40040403604507446,
        "acceleration_right": 1.073830246925354,
        "acceleration_down": -15.94693088531494,
        "battery_id": 0,
        "battery_voltage": 13.266000747680664,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 4299,
        "udp_total": 97,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 76
      },
      {
        "id": 131,
        "datetime": "21/02/2023 02:04:10",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4780778>, <mavsdk.mission_raw.MissionItem object at 0xa4780e38>, <mavsdk.mission_raw.MissionItem object at 0xa4780868>, <mavsdk.mission_raw.MissionItem object at 0xa4780aa8>]",
        "mission_pause": 0,
        "time_usec": 95333013,
        "odometry_pos_body": "PositionBody: [x_m: 5.164496898651123, y_m: 2.2080068588256836, z_m: -4.505093097686768]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.12]",
        "timestamp": 94918364,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5299999713897705,
        "current_climb_rate": 0.03354083001613617,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065108,
        "gps_raw_lon_deg": 38.8900554,
        "gps_raw_abs_alt": 81.61600494384766,
        "gps_raw_velocity": 0.07999999821186066,
        "position_lat_deg": 47.3065107,
        "position_lon_deg": 38.8900552,
        "position_abs_alt": 83.69700622558594,
        "position_real_alt": 4.951000213623047,
        "heading_deg": 354.07,
        "odometry_pos_body_x": 5.163778781890869,
        "odometry_pos_body_y": 2.199845552444458,
        "odometry_pos_body_z": -4.504827499389648,
        "odometry_vel_body_x": -0.007420547306537628,
        "odometry_vel_body_y": -0.0573909766972065,
        "odometry_vel_body_z": -0.05900612473487854,
        "odometry_ang_vel_body_roll": 0.6739976406097412,
        "odometry_ang_vel_body_pitch": 0.22882388532161713,
        "odometry_ang_vel_body_yaw": 0.03478527441620827,
        "velocity_north_m_s": 0.019999999552965164,
        "velocity_east_m_s": -0.05999999865889549,
        "velocity_down_m_s": 0,
        "acceleration_forward": -0.8281115293502808,
        "acceleration_right": 0.75657719373703,
        "acceleration_down": -8.62182903289795,
        "battery_id": 0,
        "battery_voltage": 13.266000747680664,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 26,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 4323,
        "udp_total": 97,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 76
      },
      {
        "id": 132,
        "datetime": "21/02/2023 02:04:10",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa5910088>, <mavsdk.mission_raw.MissionItem object at 0xa5910bf8>, <mavsdk.mission_raw.MissionItem object at 0xb5df1610>, <mavsdk.mission_raw.MissionItem object at 0xa4778bc8>]",
        "mission_pause": 0,
        "time_usec": 95722614,
        "odometry_pos_body": "PositionBody: [x_m: 5.157780647277832, y_m: 2.185147285461426, z_m: -4.502597808837891]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.12]",
        "timestamp": 95278025,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5299999713897705,
        "current_climb_rate": 0.045049648731946945,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065107,
        "gps_raw_lon_deg": 38.8900553,
        "gps_raw_abs_alt": 81.67500305175781,
        "gps_raw_velocity": 0.05999999865889549,
        "position_lat_deg": 47.3065108,
        "position_lon_deg": 38.890055,
        "position_abs_alt": 83.68800354003906,
        "position_real_alt": 4.941000461578369,
        "heading_deg": 353.38,
        "odometry_pos_body_x": 5.154308319091797,
        "odometry_pos_body_y": 2.1805121898651123,
        "odometry_pos_body_z": -4.502630233764648,
        "odometry_vel_body_x": -0.018841631710529327,
        "odometry_vel_body_y": -0.04131539165973663,
        "odometry_vel_body_z": -0.05583035200834274,
        "odometry_ang_vel_body_roll": 0.39600539207458496,
        "odometry_ang_vel_body_pitch": 0.22557727992534635,
        "odometry_ang_vel_body_yaw": -0.03310630843043327,
        "velocity_north_m_s": 0,
        "velocity_east_m_s": -0.07000000029802322,
        "velocity_down_m_s": 0,
        "acceleration_forward": 2.107447862625122,
        "acceleration_right": 2.582895994186402,
        "acceleration_down": -12.133228302001951,
        "battery_id": 0,
        "battery_voltage": 13.266000747680664,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 30,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 4332,
        "udp_total": 97,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 76
      },
      {
        "id": 133,
        "datetime": "21/02/2023 02:04:10",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4778190>, <mavsdk.mission_raw.MissionItem object at 0xa477cc28>, <mavsdk.mission_raw.MissionItem object at 0xa477ca18>, <mavsdk.mission_raw.MissionItem object at 0xa477c8b0>]",
        "mission_pause": 0,
        "time_usec": 96022338,
        "odometry_pos_body": "PositionBody: [x_m: 5.147712707519531, y_m: 2.1691970825195312, z_m: -4.508084297180176]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.12]",
        "timestamp": 95577749,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5299999713897705,
        "current_climb_rate": 0.048552822321653366,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065106,
        "gps_raw_lon_deg": 38.890055,
        "gps_raw_abs_alt": 81.74800109863281,
        "gps_raw_velocity": 0.07000000029802322,
        "position_lat_deg": 47.3065108,
        "position_lon_deg": 38.8900547,
        "position_abs_alt": 83.68600463867188,
        "position_real_alt": 4.940000057220459,
        "heading_deg": 352.42,
        "odometry_pos_body_x": 5.144982814788818,
        "odometry_pos_body_y": 2.159076452255249,
        "odometry_pos_body_z": -4.5126519203186035,
        "odometry_vel_body_x": -0.002949652262032032,
        "odometry_vel_body_y": -0.07536081969738007,
        "odometry_vel_body_z": -0.060596466064453125,
        "odometry_ang_vel_body_roll": -0.3660135865211487,
        "odometry_ang_vel_body_pitch": -0.10474108904600143,
        "odometry_ang_vel_body_yaw": 0.06309657543897629,
        "velocity_north_m_s": -0.009999999776482582,
        "velocity_east_m_s": -0.029999999329447743,
        "velocity_down_m_s": -0.03999999910593033,
        "acceleration_forward": 0.21674075722694397,
        "acceleration_right": 2.51184606552124,
        "acceleration_down": -10.1680269241333,
        "battery_id": 0,
        "battery_voltage": 13.266000747680664,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 3,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 4345,
        "udp_total": 100,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 77
      },
      {
        "id": 134,
        "datetime": "21/02/2023 02:04:11",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4780718>, <mavsdk.mission_raw.MissionItem object at 0xa4780640>, <mavsdk.mission_raw.MissionItem object at 0xa4780ce8>, <mavsdk.mission_raw.MissionItem object at 0xa4780ac0>]",
        "mission_pause": 0,
        "time_usec": 96262116,
        "odometry_pos_body": "PositionBody: [x_m: 5.141841888427734, y_m: 2.151024580001831, z_m: -4.515228271484375]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.12]",
        "timestamp": 95857490,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5299999713897705,
        "current_climb_rate": 0.059718530625104904,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065106,
        "gps_raw_lon_deg": 38.8900549,
        "gps_raw_abs_alt": 81.77900695800781,
        "gps_raw_velocity": 0.07999999821186066,
        "position_lat_deg": 47.3065107,
        "position_lon_deg": 38.8900545,
        "position_abs_alt": 83.68600463867188,
        "position_real_alt": 4.939000129699707,
        "heading_deg": 352.27,
        "odometry_pos_body_x": 5.13974666595459,
        "odometry_pos_body_y": 2.139282464981079,
        "odometry_pos_body_z": -4.521106719970703,
        "odometry_vel_body_x": 0.010616406798362732,
        "odometry_vel_body_y": -0.09265033155679704,
        "odometry_vel_body_z": -0.03387456387281418,
        "odometry_ang_vel_body_roll": -0.0736464262008667,
        "odometry_ang_vel_body_pitch": -0.2488674372434616,
        "odometry_ang_vel_body_yaw": 0.05974940583109856,
        "velocity_north_m_s": -0.019999999552965164,
        "velocity_east_m_s": -0.03999999910593033,
        "velocity_down_m_s": -0.05999999865889549,
        "acceleration_forward": 2.178833246231079,
        "acceleration_right": -1.6015652418136597,
        "acceleration_down": -9.39721393585205,
        "battery_id": 0,
        "battery_voltage": 13.266000747680664,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 4369,
        "udp_total": 100,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 77
      },
      {
        "id": 135,
        "datetime": "21/02/2023 02:04:11",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477c418>, <mavsdk.mission_raw.MissionItem object at 0xa477c2f8>, <mavsdk.mission_raw.MissionItem object at 0xa477ccb8>, <mavsdk.mission_raw.MissionItem object at 0xa58f2550>]",
        "mission_pause": 0,
        "time_usec": 96521878,
        "odometry_pos_body": "PositionBody: [x_m: 5.137524127960205, y_m: 2.1254122257232666, z_m: -4.526612281799316]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.12]",
        "timestamp": 96197178,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5299999713897705,
        "current_climb_rate": 0.05843206495046616,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065106,
        "gps_raw_lon_deg": 38.8900546,
        "gps_raw_abs_alt": 81.81300354003906,
        "gps_raw_velocity": 0.119999997317791,
        "position_lat_deg": 47.3065106,
        "position_lon_deg": 38.8900542,
        "position_abs_alt": 83.69700622558594,
        "position_real_alt": 4.951000213623047,
        "heading_deg": 353.25,
        "odometry_pos_body_x": 5.132079124450684,
        "odometry_pos_body_y": 2.1052443981170654,
        "odometry_pos_body_z": -4.536532402038574,
        "odometry_vel_body_x": -0.014739543199539185,
        "odometry_vel_body_y": -0.112101249396801,
        "odometry_vel_body_z": -0.04910663515329361,
        "odometry_ang_vel_body_roll": 0.9092940092086792,
        "odometry_ang_vel_body_pitch": 0.20521719753742215,
        "odometry_ang_vel_body_yaw": 0.12092388421297072,
        "velocity_north_m_s": 0,
        "velocity_east_m_s": -0.07999999821186066,
        "velocity_down_m_s": -0.07000000029802322,
        "acceleration_forward": -0.8663634657859802,
        "acceleration_right": -0.8842679262161255,
        "acceleration_down": -5.44897985458374,
        "battery_id": 0,
        "battery_voltage": 13.266000747680664,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 24,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 4391,
        "udp_total": 100,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 77
      },
      {
        "id": 136,
        "datetime": "21/02/2023 02:04:11",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477c9b8>, <mavsdk.mission_raw.MissionItem object at 0xa477e6e8>, <mavsdk.mission_raw.MissionItem object at 0xa477ef58>, <mavsdk.mission_raw.MissionItem object at 0xa477e3a0>]",
        "mission_pause": 0,
        "time_usec": 96831592,
        "odometry_pos_body": "PositionBody: [x_m: 5.1243767738342285, y_m: 2.0841574668884277, z_m: -4.547953128814697]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.12]",
        "timestamp": 96596810,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5299999713897705,
        "current_climb_rate": 0.02330034412443638,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065104,
        "gps_raw_lon_deg": 38.8900538,
        "gps_raw_abs_alt": 81.86500549316406,
        "gps_raw_velocity": 0.17999999225139618,
        "position_lat_deg": 47.306510499999995,
        "position_lon_deg": 38.8900536,
        "position_abs_alt": 83.71700286865234,
        "position_real_alt": 4.9710001945495605,
        "heading_deg": 355.18,
        "odometry_pos_body_x": 5.1164116859436035,
        "odometry_pos_body_y": 2.06154465675354,
        "odometry_pos_body_z": -4.55404806137085,
        "odometry_vel_body_x": -0.014951452612876892,
        "odometry_vel_body_y": -0.09870995581150056,
        "odometry_vel_body_z": -0.014534499496221542,
        "odometry_ang_vel_body_roll": 0.16855661571025848,
        "odometry_ang_vel_body_pitch": -0.1240139752626419,
        "odometry_ang_vel_body_yaw": 0.19207969307899475,
        "velocity_north_m_s": -0.019999999552965164,
        "velocity_east_m_s": -0.10999999940395357,
        "velocity_down_m_s": -0.04999999701976776,
        "acceleration_forward": 0.0565936304628849,
        "acceleration_right": -1.288375735282898,
        "acceleration_down": -5.746195316314697,
        "battery_id": 0,
        "battery_voltage": 13.266000747680664,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 49,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 4416,
        "udp_total": 100,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 77
      },
      {
        "id": 137,
        "datetime": "21/02/2023 02:04:11",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4777b80>, <mavsdk.mission_raw.MissionItem object at 0xa47777f0>, <mavsdk.mission_raw.MissionItem object at 0xa477b928>, <mavsdk.mission_raw.MissionItem object at 0xa477bb68>]",
        "mission_pause": 0,
        "time_usec": 97161288,
        "odometry_pos_body": "PositionBody: [x_m: 5.112466335296631, y_m: 2.0449085235595703, z_m: -4.556820392608643]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.12]",
        "timestamp": 96901527,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5299999713897705,
        "current_climb_rate": 0.021426796913146973,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065104,
        "gps_raw_lon_deg": 38.8900534,
        "gps_raw_abs_alt": 81.88700103759766,
        "gps_raw_velocity": 0.14999999105930328,
        "position_lat_deg": 47.3065104,
        "position_lon_deg": 38.8900532,
        "position_abs_alt": 83.73300170898438,
        "position_real_alt": 4.987000465393066,
        "heading_deg": 357.40000000000003,
        "odometry_pos_body_x": 5.1111531257629395,
        "odometry_pos_body_y": 2.0327959060668945,
        "odometry_pos_body_z": -4.556943893432617,
        "odometry_vel_body_x": 0.00437442259863019,
        "odometry_vel_body_y": -0.08171240985393524,
        "odometry_vel_body_z": -0.03730592504143715,
        "odometry_ang_vel_body_roll": 0.3451862931251526,
        "odometry_ang_vel_body_pitch": 0.28869906067848206,
        "odometry_ang_vel_body_yaw": 0.15606831014156342,
        "velocity_north_m_s": -0.019999999552965164,
        "velocity_east_m_s": -0.09999999403953552,
        "velocity_down_m_s": -0.019999999552965164,
        "acceleration_forward": -0.11731775104999542,
        "acceleration_right": 0.1575927436351776,
        "acceleration_down": -13.284561157226562,
        "battery_id": 0,
        "battery_voltage": 13.242000579833984,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 4438,
        "udp_total": 101,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 78
      },
      {
        "id": 138,
        "datetime": "21/02/2023 02:04:12",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4778d00>, <mavsdk.mission_raw.MissionItem object at 0xa477e550>, <mavsdk.mission_raw.MissionItem object at 0xa477e0e8>, <mavsdk.mission_raw.MissionItem object at 0xa477e5b0>]",
        "mission_pause": 0,
        "time_usec": 97431040,
        "odometry_pos_body": "PositionBody: [x_m: 5.112617492675781, y_m: 2.0178029537200928, z_m: -4.55861759185791]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.12]",
        "timestamp": 97216237,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5299999713897705,
        "current_climb_rate": 0.01999647915363312,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065104,
        "gps_raw_lon_deg": 38.8900528,
        "gps_raw_abs_alt": 81.94500732421875,
        "gps_raw_velocity": 0.09999999403953552,
        "position_lat_deg": 47.3065103,
        "position_lon_deg": 38.8900526,
        "position_abs_alt": 83.73900604248047,
        "position_real_alt": 4.993000030517578,
        "heading_deg": 0.86,
        "odometry_pos_body_x": 5.114719390869141,
        "odometry_pos_body_y": 2.011253833770752,
        "odometry_pos_body_z": -4.560973167419434,
        "odometry_vel_body_x": 0.03440340980887413,
        "odometry_vel_body_y": -0.03183369338512421,
        "odometry_vel_body_z": -0.01750125363469124,
        "odometry_ang_vel_body_roll": -0.40338850021362305,
        "odometry_ang_vel_body_pitch": 0.4465623795986175,
        "odometry_ang_vel_body_yaw": 0.23178158700466156,
        "velocity_north_m_s": 0.009999999776482582,
        "velocity_east_m_s": -0.04999999701976776,
        "velocity_down_m_s": -0.029999999329447743,
        "acceleration_forward": 0.12519623339176178,
        "acceleration_right": -6.212891578674316,
        "acceleration_down": -5.9463210105896,
        "battery_id": 0,
        "battery_voltage": 13.242000579833984,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 20,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 4460,
        "udp_total": 101,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 78
      },
      {
        "id": 139,
        "datetime": "21/02/2023 02:04:12",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa47802e0>, <mavsdk.mission_raw.MissionItem object at 0xa47800a0>, <mavsdk.mission_raw.MissionItem object at 0xa4780958>, <mavsdk.mission_raw.MissionItem object at 0xa4780058>]",
        "mission_pause": 0,
        "time_usec": 97690802,
        "odometry_pos_body": "PositionBody: [x_m: 5.118576526641846, y_m: 2.0078070163726807, z_m: -4.565002918243408]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 97535944,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5299999713897705,
        "current_climb_rate": 0.01624350994825363,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065104,
        "gps_raw_lon_deg": 38.8900527,
        "gps_raw_abs_alt": 81.96400451660156,
        "gps_raw_velocity": 0.07000000029802322,
        "position_lat_deg": 47.3065104,
        "position_lon_deg": 38.8900523,
        "position_abs_alt": 83.74400329589844,
        "position_real_alt": 4.998000144958496,
        "heading_deg": 5.73,
        "odometry_pos_body_x": 5.122299671173096,
        "odometry_pos_body_y": 2.0046398639678955,
        "odometry_pos_body_z": -4.569555282592773,
        "odometry_vel_body_x": 0.05821969732642174,
        "odometry_vel_body_y": 0.015018374659121037,
        "odometry_vel_body_z": -0.0034427084028720856,
        "odometry_ang_vel_body_roll": -0.4037836790084839,
        "odometry_ang_vel_body_pitch": 0.3218759596347809,
        "odometry_ang_vel_body_yaw": 0.5069690942764282,
        "velocity_north_m_s": 0.03999999910593033,
        "velocity_east_m_s": 0.019999999552965164,
        "velocity_down_m_s": -0.029999999329447743,
        "acceleration_forward": -1.123304843902588,
        "acceleration_right": -4.773984909057617,
        "acceleration_down": -4.795219421386719,
        "battery_id": 0,
        "battery_voltage": 13.242000579833984,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 4462,
        "udp_total": 101,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 78
      },
      {
        "id": 140,
        "datetime": "21/02/2023 02:04:12",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa476c658>, <mavsdk.mission_raw.MissionItem object at 0xa476c940>, <mavsdk.mission_raw.MissionItem object at 0xa5913ce8>, <mavsdk.mission_raw.MissionItem object at 0xa5913a78>]",
        "mission_pause": 0,
        "time_usec": 97960553,
        "odometry_pos_body": "PositionBody: [x_m: 5.126979351043701, y_m: 2.004143238067627, z_m: -4.574069499969482]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 97890621,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5199999809265137,
        "current_climb_rate": 0.016291776672005653,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306510499999995,
        "gps_raw_lon_deg": 38.8900525,
        "gps_raw_abs_alt": 81.98900604248047,
        "gps_raw_velocity": 0.04999999701976776,
        "position_lat_deg": 47.3065104,
        "position_lon_deg": 38.8900522,
        "position_abs_alt": 83.7550048828125,
        "position_real_alt": 5.008000373840332,
        "heading_deg": 16.54,
        "odometry_pos_body_x": 5.13339376449585,
        "odometry_pos_body_y": 2.004579544067383,
        "odometry_pos_body_z": -4.5799880027771,
        "odometry_vel_body_x": 0.08171465247869492,
        "odometry_vel_body_y": 0.023523997515439987,
        "odometry_vel_body_z": -0.011627146042883396,
        "odometry_ang_vel_body_roll": 0.15136398375034332,
        "odometry_ang_vel_body_pitch": -0.020012205466628075,
        "odometry_ang_vel_body_yaw": 0.5851661562919617,
        "velocity_north_m_s": 0.04999999701976776,
        "velocity_east_m_s": 0.03999999910593033,
        "velocity_down_m_s": -0.019999999552965164,
        "acceleration_forward": -1.1340512037277222,
        "acceleration_right": 3.8190295696258545,
        "acceleration_down": -12.98880100250244,
        "battery_id": 0,
        "battery_voltage": 13.242000579833984,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 4484,
        "udp_total": 102,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 79
      },
      {
        "id": 141,
        "datetime": "21/02/2023 02:04:13",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4778670>, <mavsdk.mission_raw.MissionItem object at 0xa4778a90>, <mavsdk.mission_raw.MissionItem object at 0xa4778370>, <mavsdk.mission_raw.MissionItem object at 0xb2024958>]",
        "mission_pause": 0,
        "time_usec": 98360185,
        "odometry_pos_body": "PositionBody: [x_m: 5.151005744934082, y_m: 2.008402109146118, z_m: -4.5914130210876465]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 98425126,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5199999809265137,
        "current_climb_rate": 0.006502143107354641,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065106,
        "gps_raw_lon_deg": 38.8900527,
        "gps_raw_abs_alt": 82.01500701904297,
        "gps_raw_velocity": 0.03999999910593033,
        "position_lat_deg": 47.3065107,
        "position_lon_deg": 38.8900523,
        "position_abs_alt": 83.7760009765625,
        "position_real_alt": 5.03000020980835,
        "heading_deg": 28.92,
        "odometry_pos_body_x": 5.160207748413086,
        "odometry_pos_body_y": 2.0105326175689697,
        "odometry_pos_body_z": -4.595409870147705,
        "odometry_vel_body_x": 0.11023741215467452,
        "odometry_vel_body_y": -0.005322742275893688,
        "odometry_vel_body_z": -0.006679602432996035,
        "odometry_ang_vel_body_roll": 0.27032577991485596,
        "odometry_ang_vel_body_pitch": -0.33485978841781616,
        "odometry_ang_vel_body_yaw": 0.6072983145713806,
        "velocity_north_m_s": 0.08999999612569809,
        "velocity_east_m_s": 0.04999999701976776,
        "velocity_down_m_s": -0.009999999776482582,
        "acceleration_forward": -0.054872553795576096,
        "acceleration_right": 3.860870838165283,
        "acceleration_down": -11.890396118164062,
        "battery_id": 0,
        "battery_voltage": 13.242000579833984,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 4508,
        "udp_total": 102,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 79
      },
      {
        "id": 142,
        "datetime": "21/02/2023 02:04:13",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4780fd0>, <mavsdk.mission_raw.MissionItem object at 0xa4777f40>, <mavsdk.mission_raw.MissionItem object at 0xa4777ad8>, <mavsdk.mission_raw.MissionItem object at 0xa4777748>]",
        "mission_pause": 0,
        "time_usec": 98659911,
        "odometry_pos_body": "PositionBody: [x_m: 5.168570041656494, y_m: 2.0130107402801514, z_m: -4.601953983306885]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 98734840,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5199999809265137,
        "current_climb_rate": 0.019381634891033173,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065109,
        "gps_raw_lon_deg": 38.8900531,
        "gps_raw_abs_alt": 82.07200622558594,
        "gps_raw_velocity": 0.07000000029802322,
        "position_lat_deg": 47.3065109,
        "position_lon_deg": 38.8900523,
        "position_abs_alt": 83.78900146484375,
        "position_real_alt": 5.043000221252441,
        "heading_deg": 39.92,
        "odometry_pos_body_x": 5.17516565322876,
        "odometry_pos_body_y": 2.0115737915039062,
        "odometry_pos_body_z": -4.607946872711182,
        "odometry_vel_body_x": 0.022310158237814903,
        "odometry_vel_body_y": -0.051482610404491425,
        "odometry_vel_body_z": -0.019853046163916588,
        "odometry_ang_vel_body_roll": 0.12969361245632172,
        "odometry_ang_vel_body_pitch": 0.03959567844867706,
        "odometry_ang_vel_body_yaw": 0.5577036142349243,
        "velocity_north_m_s": 0.03999999910593033,
        "velocity_east_m_s": -0.019999999552965164,
        "velocity_down_m_s": -0.009999999776482582,
        "acceleration_forward": 0.1843213587999344,
        "acceleration_right": -1.4875092506408691,
        "acceleration_down": -12.573238372802734,
        "battery_id": 0,
        "battery_voltage": 13.242000579833984,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 14,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 4544,
        "udp_total": 102,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 79
      },
      {
        "id": 143,
        "datetime": "21/02/2023 02:04:13",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477cc70>, <mavsdk.mission_raw.MissionItem object at 0xa477e310>, <mavsdk.mission_raw.MissionItem object at 0xa477e2f8>, <mavsdk.mission_raw.MissionItem object at 0xa477e100>]",
        "mission_pause": 0,
        "time_usec": 99059607,
        "odometry_pos_body": "PositionBody: [x_m: 5.18140172958374, y_m: 1.9969605207443237, z_m: -4.624164581298828]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 99089514,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5099999904632568,
        "current_climb_rate": 0.01836533471941948,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065113,
        "gps_raw_lon_deg": 38.8900528,
        "gps_raw_abs_alt": 82.1820068359375,
        "gps_raw_velocity": 0.09999999403953552,
        "position_lat_deg": 47.306511,
        "position_lon_deg": 38.890052,
        "position_abs_alt": 83.81100463867188,
        "position_real_alt": 5.065000057220459,
        "heading_deg": 49.32,
        "odometry_pos_body_x": 5.182266712188721,
        "odometry_pos_body_y": 1.980716109275818,
        "odometry_pos_body_z": -4.635136604309082,
        "odometry_vel_body_x": -0.13816583156585693,
        "odometry_vel_body_y": -0.02546890638768673,
        "odometry_vel_body_z": -0.008209116756916046,
        "odometry_ang_vel_body_roll": -0.09657344222068788,
        "odometry_ang_vel_body_pitch": -0.11936119198799132,
        "odometry_ang_vel_body_yaw": 0.422306627035141,
        "velocity_north_m_s": -0.05999999865889549,
        "velocity_east_m_s": -0.10999999940395357,
        "velocity_down_m_s": 0,
        "acceleration_forward": 0.7116661071777344,
        "acceleration_right": -0.2482471764087677,
        "acceleration_down": -10.362812995910645,
        "battery_id": 0,
        "battery_voltage": 13.254000663757324,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 12,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 4568,
        "udp_total": 103,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 80
      },
      {
        "id": 144,
        "datetime": "21/02/2023 02:04:14",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa58fc9d0>, <mavsdk.mission_raw.MissionItem object at 0xa58fcb68>, <mavsdk.mission_raw.MissionItem object at 0xa477b748>, <mavsdk.mission_raw.MissionItem object at 0xa59109d0>]",
        "mission_pause": 0,
        "time_usec": 99429202,
        "odometry_pos_body": "PositionBody: [x_m: 5.173332214355469, y_m: 1.9444071054458618, z_m: -4.648777961730957]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 99489149,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5199999809265137,
        "current_climb_rate": -0.0302677433937788,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306511,
        "gps_raw_lon_deg": 38.8900519,
        "gps_raw_abs_alt": 82.20100402832031,
        "gps_raw_velocity": 0.20999999344348907,
        "position_lat_deg": 47.3065108,
        "position_lon_deg": 38.8900511,
        "position_abs_alt": 83.83300018310547,
        "position_real_alt": 5.087000370025635,
        "heading_deg": 59.98,
        "odometry_pos_body_x": 5.160831451416016,
        "odometry_pos_body_y": 1.9135873317718504,
        "odometry_pos_body_z": -4.651247024536133,
        "odometry_vel_body_x": -0.2193961441516876,
        "odometry_vel_body_y": 0.01040851604193449,
        "odometry_vel_body_z": 0.039709266275167465,
        "odometry_ang_vel_body_roll": -0.10320086777210236,
        "odometry_ang_vel_body_pitch": -0.0779440626502037,
        "odometry_ang_vel_body_yaw": 0.3245280086994171,
        "velocity_north_m_s": -0.09999999403953552,
        "velocity_east_m_s": -0.17999999225139618,
        "velocity_down_m_s": 0.03999999910593033,
        "acceleration_forward": -0.014507830142974854,
        "acceleration_right": -5.26519250869751,
        "acceleration_down": -6.927225112915039,
        "battery_id": 0,
        "battery_voltage": 13.254000663757324,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 4600,
        "udp_total": 103,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 80
      },
      {
        "id": 145,
        "datetime": "21/02/2023 02:04:14",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4778610>, <mavsdk.mission_raw.MissionItem object at 0xa4782fe8>, <mavsdk.mission_raw.MissionItem object at 0xa4782c58>, <mavsdk.mission_raw.MissionItem object at 0xa4782130>]",
        "mission_pause": 0,
        "time_usec": 99828836,
        "odometry_pos_body": "PositionBody: [x_m: 5.136529445648193, y_m: 1.8680859804153442, z_m: -4.645625114440918]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 99823838,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5299999713897705,
        "current_climb_rate": -0.07106655091047287,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065104,
        "gps_raw_lon_deg": 38.8900509,
        "gps_raw_abs_alt": 82.1820068359375,
        "gps_raw_velocity": 0.29999998211860657,
        "position_lat_deg": 47.3065106,
        "position_lon_deg": 38.8900505,
        "position_abs_alt": 83.8280029296875,
        "position_real_alt": 5.082000255584717,
        "heading_deg": 65.48,
        "odometry_pos_body_x": 5.118703365325928,
        "odometry_pos_body_y": 1.8402997255325315,
        "odometry_pos_body_z": -4.641029357910156,
        "odometry_vel_body_x": -0.23861688375473025,
        "odometry_vel_body_y": 0.03574194759130478,
        "odometry_vel_body_z": 0.0670282244682312,
        "odometry_ang_vel_body_roll": -0.699533224105835,
        "odometry_ang_vel_body_pitch": 0.23409830033779144,
        "odometry_ang_vel_body_yaw": 0.3695645034313202,
        "velocity_north_m_s": -0.119999997317791,
        "velocity_east_m_s": -0.19999998807907104,
        "velocity_down_m_s": 0.07000000029802322,
        "acceleration_forward": 0.9211212396621704,
        "acceleration_right": -0.9926400184631348,
        "acceleration_down": -6.701847076416016,
        "battery_id": 0,
        "battery_voltage": 13.254000663757324,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 4622,
        "udp_total": 106,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 81
      },
      {
        "id": 146,
        "datetime": "21/02/2023 02:04:15",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4778b80>, <mavsdk.mission_raw.MissionItem object at 0xa4778fd0>, <mavsdk.mission_raw.MissionItem object at 0xa4780838>, <mavsdk.mission_raw.MissionItem object at 0xa4780b80>]",
        "mission_pause": 0,
        "time_usec": 100293408,
        "odometry_pos_body": "PositionBody: [x_m: 5.064446926116943, y_m: 1.7602837085723877, z_m: -4.627824306488037]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 100078606,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5299999713897705,
        "current_climb_rate": -0.051281459629535675,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065097,
        "gps_raw_lon_deg": 38.8900496,
        "gps_raw_abs_alt": 82.1780014038086,
        "gps_raw_velocity": 0.28999999165534973,
        "position_lat_deg": 47.3065102,
        "position_lon_deg": 38.8900497,
        "position_abs_alt": 83.81600189208984,
        "position_real_alt": 5.070000171661377,
        "heading_deg": 71.55,
        "odometry_pos_body_x": 5.049036502838135,
        "odometry_pos_body_y": 1.7363545894622805,
        "odometry_pos_body_z": -4.624231815338135,
        "odometry_vel_body_x": -0.2183963805437088,
        "odometry_vel_body_y": 0.07738455384969711,
        "odometry_vel_body_z": 0.042978305369615555,
        "odometry_ang_vel_body_roll": -0.6553996801376343,
        "odometry_ang_vel_body_pitch": 0.1043177992105484,
        "odometry_ang_vel_body_yaw": 0.31887301802635193,
        "velocity_north_m_s": -0.119999997317791,
        "velocity_east_m_s": -0.20999999344348907,
        "velocity_down_m_s": 0.03999999910593033,
        "acceleration_forward": -0.895290195941925,
        "acceleration_right": 4.320785045623779,
        "acceleration_down": -12.727614402770996,
        "battery_id": 0,
        "battery_voltage": 13.254000663757324,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 4646,
        "udp_total": 106,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 81
      },
      {
        "id": 147,
        "datetime": "21/02/2023 02:04:15",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa47805b0>, <mavsdk.mission_raw.MissionItem object at 0xa4780e98>, <mavsdk.mission_raw.MissionItem object at 0xa47802f8>, <mavsdk.mission_raw.MissionItem object at 0xa4780c10>]",
        "mission_pause": 0,
        "time_usec": 100563161,
        "odometry_pos_body": "PositionBody: [x_m: 5.023253917694092, y_m: 1.6997557878494263, z_m: -4.621898174285889]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 100263435,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5199999809265137,
        "current_climb_rate": 0.008821433410048485,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065091,
        "gps_raw_lon_deg": 38.8900483,
        "gps_raw_abs_alt": 82.19100189208984,
        "gps_raw_velocity": 0.26999998092651367,
        "position_lat_deg": 47.306509899999995,
        "position_lon_deg": 38.890049,
        "position_abs_alt": 83.81000518798828,
        "position_real_alt": 5.064000129699707,
        "heading_deg": 76.14,
        "odometry_pos_body_x": 5.003320693969727,
        "odometry_pos_body_y": 1.6754229068756104,
        "odometry_pos_body_z": -4.6251935958862305,
        "odometry_vel_body_x": -0.1605910062789917,
        "odometry_vel_body_y": 0.09666097909212112,
        "odometry_vel_body_z": 0.0012317327782511711,
        "odometry_ang_vel_body_roll": 0.14888522028923035,
        "odometry_ang_vel_body_pitch": 0.010290594771504402,
        "odometry_ang_vel_body_yaw": 0.412302166223526,
        "velocity_north_m_s": -0.12999999523162842,
        "velocity_east_m_s": -0.19999998807907104,
        "velocity_down_m_s": 0.009999999776482582,
        "acceleration_forward": 0.013273620046675203,
        "acceleration_right": 4.15660285949707,
        "acceleration_down": -12.670855522155762,
        "battery_id": 0,
        "battery_voltage": 13.254000663757324,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 4668,
        "udp_total": 106,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 81
      },
      {
        "id": 148,
        "datetime": "21/02/2023 02:04:15",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4778220>, <mavsdk.mission_raw.MissionItem object at 0xa4780bf8>, <mavsdk.mission_raw.MissionItem object at 0xa4780ac0>, <mavsdk.mission_raw.MissionItem object at 0xa4780070>]",
        "mission_pause": 0,
        "time_usec": 100922834,
        "odometry_pos_body": "PositionBody: [x_m: 4.973328113555908, y_m: 1.6354924440383911, z_m: -4.633406162261963]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 100578151,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5099999904632568,
        "current_climb_rate": 0.02430485188961029,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065089,
        "gps_raw_lon_deg": 38.8900478,
        "gps_raw_abs_alt": 82.20600128173828,
        "gps_raw_velocity": 0.23999999463558197,
        "position_lat_deg": 47.3065094,
        "position_lon_deg": 38.890048,
        "position_abs_alt": 83.80500030517578,
        "position_real_alt": 5.059000015258789,
        "heading_deg": 84,
        "odometry_pos_body_x": 4.9627203941345215,
        "odometry_pos_body_y": 1.6165149211883545,
        "odometry_pos_body_z": -4.635000228881836,
        "odometry_vel_body_x": -0.13580600917339325,
        "odometry_vel_body_y": 0.028776979073882103,
        "odometry_vel_body_z": 0.003219227772206068,
        "odometry_ang_vel_body_roll": 0.6226080060005188,
        "odometry_ang_vel_body_pitch": -0.11884576827287674,
        "odometry_ang_vel_body_yaw": 0.4508228302001953,
        "velocity_north_m_s": -0.08999999612569809,
        "velocity_east_m_s": -0.14999999105930328,
        "velocity_down_m_s": 0,
        "acceleration_forward": 0.8307246565818787,
        "acceleration_right": -4.6043219566345215,
        "acceleration_down": -5.791799545288086,
        "battery_id": 0,
        "battery_voltage": 13.254000663757324,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 4692,
        "udp_total": 106,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 81
      },
      {
        "id": 149,
        "datetime": "21/02/2023 02:04:15",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4778610>, <mavsdk.mission_raw.MissionItem object at 0xa47788f8>, <mavsdk.mission_raw.MissionItem object at 0xa4778e98>, <mavsdk.mission_raw.MissionItem object at 0xa477cbf8>]",
        "mission_pause": 0,
        "time_usec": 101162611,
        "odometry_pos_body": "PositionBody: [x_m: 4.952173233032227, y_m: 1.5944310426712036, z_m: -4.63287878036499]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 100807944,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5099999904632568,
        "current_climb_rate": -0.0014081948902457952,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306508699999995,
        "gps_raw_lon_deg": 38.8900473,
        "gps_raw_abs_alt": 82.21900177001953,
        "gps_raw_velocity": 0.2199999988079071,
        "position_lat_deg": 47.3065091,
        "position_lon_deg": 38.8900475,
        "position_abs_alt": 83.81400299072266,
        "position_real_alt": 5.067000389099121,
        "heading_deg": 90.9,
        "odometry_pos_body_x": 4.9456353187561035,
        "odometry_pos_body_y": 1.5779223442077637,
        "odometry_pos_body_z": -4.630150318145752,
        "odometry_vel_body_x": -0.11742853373289108,
        "odometry_vel_body_y": 0.022572770714759827,
        "odometry_vel_body_z": 0.04532610252499581,
        "odometry_ang_vel_body_roll": -1.0500167608261108,
        "odometry_ang_vel_body_pitch": -0.1148049533367157,
        "odometry_ang_vel_body_yaw": 0.35834771394729614,
        "velocity_north_m_s": -0.029999999329447743,
        "velocity_east_m_s": -0.14000000059604645,
        "velocity_down_m_s": -0.009999999776482582,
        "acceleration_forward": 0.08306475728750229,
        "acceleration_right": 6.527222633361816,
        "acceleration_down": -13.352749824523926,
        "battery_id": 0,
        "battery_voltage": 13.236000061035156,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 14,
        "udp": 3,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 4711,
        "udp_total": 109,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 82
      },
      {
        "id": 150,
        "datetime": "21/02/2023 02:04:16",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4778088>, <mavsdk.mission_raw.MissionItem object at 0xa4782d60>, <mavsdk.mission_raw.MissionItem object at 0xa4782820>, <mavsdk.mission_raw.MissionItem object at 0xa4782838>]",
        "mission_pause": 0,
        "time_usec": 101432364,
        "odometry_pos_body": "PositionBody: [x_m: 4.929847717285156, y_m: 1.5554002523422241, z_m: -4.628602981567383]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 101147691,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5399999618530273,
        "current_climb_rate": -0.09083783626556396,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065084,
        "gps_raw_lon_deg": 38.8900467,
        "gps_raw_abs_alt": 82.22500610351562,
        "gps_raw_velocity": 0.14999999105930328,
        "position_lat_deg": 47.3065089,
        "position_lon_deg": 38.8900467,
        "position_abs_alt": 83.81400299072266,
        "position_real_alt": 5.067000389099121,
        "heading_deg": 99.16,
        "odometry_pos_body_x": 4.918475151062012,
        "odometry_pos_body_y": 1.539409041404724,
        "odometry_pos_body_z": -4.6251749992370605,
        "odometry_vel_body_x": -0.08648134768009186,
        "odometry_vel_body_y": 0.05820408463478088,
        "odometry_vel_body_z": 0.1350436806678772,
        "odometry_ang_vel_body_roll": -0.28393635153770447,
        "odometry_ang_vel_body_pitch": -0.4402964413166046,
        "odometry_ang_vel_body_yaw": 0.3196258246898651,
        "velocity_north_m_s": 0,
        "velocity_east_m_s": -0.119999997317791,
        "velocity_down_m_s": 0.03999999910593033,
        "acceleration_forward": -0.9922629594802856,
        "acceleration_right": -1.2135319709777832,
        "acceleration_down": -13.472372055053713,
        "battery_id": 0,
        "battery_voltage": 13.236000061035156,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 6,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 4727,
        "udp_total": 109,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 82
      },
      {
        "id": 151,
        "datetime": "21/02/2023 02:04:16",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4777e98>, <mavsdk.mission_raw.MissionItem object at 0xa477ab50>, <mavsdk.mission_raw.MissionItem object at 0xa477aa60>, <mavsdk.mission_raw.MissionItem object at 0xa4775d30>]",
        "mission_pause": 0,
        "time_usec": 101792035,
        "odometry_pos_body": "PositionBody: [x_m: 4.9009294509887695, y_m: 1.5146466493606567, z_m: -4.611263275146484]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 101567240,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5600000023841858,
        "current_climb_rate": -0.1308601200580597,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306508,
        "gps_raw_lon_deg": 38.8900462,
        "gps_raw_abs_alt": 82.19100189208984,
        "gps_raw_velocity": 0.17000000178813934,
        "position_lat_deg": 47.3065085,
        "position_lon_deg": 38.890046,
        "position_abs_alt": 83.80500030517578,
        "position_real_alt": 5.059000015258789,
        "heading_deg": 109.12,
        "odometry_pos_body_x": 4.893280982971191,
        "odometry_pos_body_y": 1.5009435415267944,
        "odometry_pos_body_z": -4.592968940734863,
        "odometry_vel_body_x": -0.07764862477779388,
        "odometry_vel_body_y": 0.03810298815369606,
        "odometry_vel_body_z": 0.1573447585105896,
        "odometry_ang_vel_body_roll": 0.8244481682777405,
        "odometry_ang_vel_body_pitch": 0.12411166727542876,
        "odometry_ang_vel_body_yaw": 0.4619709551334381,
        "velocity_north_m_s": -0.029999999329447743,
        "velocity_east_m_s": -0.08999999612569809,
        "velocity_down_m_s": 0.119999997317791,
        "acceleration_forward": -0.0017207972705364227,
        "acceleration_right": 0.9938550591468812,
        "acceleration_down": -7.094106197357178,
        "battery_id": 0,
        "battery_voltage": 13.236000061035156,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 1,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 4739,
        "udp_total": 109,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 82
      },
      {
        "id": 152,
        "datetime": "21/02/2023 02:04:16",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477c748>, <mavsdk.mission_raw.MissionItem object at 0xa477ce20>, <mavsdk.mission_raw.MissionItem object at 0xa477cd90>, <mavsdk.mission_raw.MissionItem object at 0xa4775760>]",
        "mission_pause": 0,
        "time_usec": 102071779,
        "odometry_pos_body": "PositionBody: [x_m: 4.878783226013184, y_m: 1.4902807474136353, z_m: -4.579416751861572]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 101856995,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.550000011920929,
        "current_climb_rate": -0.07166960835456848,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065079,
        "gps_raw_lon_deg": 38.8900458,
        "gps_raw_abs_alt": 82.16800689697266,
        "gps_raw_velocity": 0.14000000059604645,
        "position_lat_deg": 47.3065083,
        "position_lon_deg": 38.8900455,
        "position_abs_alt": 83.76800537109375,
        "position_real_alt": 5.022000312805176,
        "heading_deg": 117.2,
        "odometry_pos_body_x": 4.866436958312988,
        "odometry_pos_body_y": 1.4733365774154663,
        "odometry_pos_body_z": -4.577079772949219,
        "odometry_vel_body_x": -0.07423990219831467,
        "odometry_vel_body_y": 0.05190999433398247,
        "odometry_vel_body_z": 0.06928768008947372,
        "odometry_ang_vel_body_roll": -0.7816159129142761,
        "odometry_ang_vel_body_pitch": -0.3848282992839813,
        "odometry_ang_vel_body_yaw": 0.3946758210659027,
        "velocity_north_m_s": 0,
        "velocity_east_m_s": -0.08999999612569809,
        "velocity_down_m_s": 0.09999999403953552,
        "acceleration_forward": 0.5011036396026611,
        "acceleration_right": 4.238794803619385,
        "acceleration_down": -17.210920333862305,
        "battery_id": 0,
        "battery_voltage": 13.236000061035156,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 3,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 4762,
        "udp_total": 110,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 83
      },
      {
        "id": 153,
        "datetime": "21/02/2023 02:04:17",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477e760>, <mavsdk.mission_raw.MissionItem object at 0xa477ec70>, <mavsdk.mission_raw.MissionItem object at 0xa477b298>, <mavsdk.mission_raw.MissionItem object at 0xa477b550>]",
        "mission_pause": 0,
        "time_usec": 102361527,
        "odometry_pos_body": "PositionBody: [x_m: 4.859920024871826, y_m: 1.4603826999664307, z_m: -4.576998233795166]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 102256650,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.550000011920929,
        "current_climb_rate": -0.0419146716594696,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065078,
        "gps_raw_lon_deg": 38.8900452,
        "gps_raw_abs_alt": 82.12500762939453,
        "gps_raw_velocity": 0.07999999821186066,
        "position_lat_deg": 47.306508099999995,
        "position_lon_deg": 38.890045099999995,
        "position_abs_alt": 83.76000213623047,
        "position_real_alt": 5.014000415802002,
        "heading_deg": 125.12,
        "odometry_pos_body_x": 4.8567023277282715,
        "odometry_pos_body_y": 1.4505343437194824,
        "odometry_pos_body_z": -4.5717549324035645,
        "odometry_vel_body_x": -0.04224230721592903,
        "odometry_vel_body_y": -0.02838422730565071,
        "odometry_vel_body_z": 0.06270366907119751,
        "odometry_ang_vel_body_roll": -1.0035631656646729,
        "odometry_ang_vel_body_pitch": -0.019303010776638985,
        "odometry_ang_vel_body_yaw": 0.3508780002593994,
        "velocity_north_m_s": 0.009999999776482582,
        "velocity_east_m_s": -0.07000000029802322,
        "velocity_down_m_s": 0.009999999776482582,
        "acceleration_forward": -1.3865957260131836,
        "acceleration_right": -1.2152204513549805,
        "acceleration_down": -15.808966636657717,
        "battery_id": 0,
        "battery_voltage": 13.236000061035156,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 4786,
        "udp_total": 110,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 83
      },
      {
        "id": 154,
        "datetime": "21/02/2023 02:04:17",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477a460>, <mavsdk.mission_raw.MissionItem object at 0xa591ea90>, <mavsdk.mission_raw.MissionItem object at 0xa591e490>, <mavsdk.mission_raw.MissionItem object at 0xa591ef70>]",
        "mission_pause": 0,
        "time_usec": 102691219,
        "odometry_pos_body": "PositionBody: [x_m: 4.8705878257751465, y_m: 1.4551303386688232, z_m: -4.549765110015869]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 102571321,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5799999833106995,
        "current_climb_rate": -0.132552370429039,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065078,
        "gps_raw_lon_deg": 38.890045099999995,
        "gps_raw_abs_alt": 82.11200714111328,
        "gps_raw_velocity": 0.05999999865889549,
        "position_lat_deg": 47.306508099999995,
        "position_lon_deg": 38.8900449,
        "position_abs_alt": 83.74500274658203,
        "position_real_alt": 4.999000072479248,
        "heading_deg": 130.69,
        "odometry_pos_body_x": 4.8835835456848145,
        "odometry_pos_body_y": 1.4624695777893066,
        "odometry_pos_body_z": -4.53497838973999,
        "odometry_vel_body_x": -0.02480008825659752,
        "odometry_vel_body_y": -0.24738149344921112,
        "odometry_vel_body_z": 0.11753522604703905,
        "odometry_ang_vel_body_roll": 1.4805562496185305,
        "odometry_ang_vel_body_pitch": -0.35281136631965637,
        "odometry_ang_vel_body_yaw": 0.3417678773403168,
        "velocity_north_m_s": 0.14000000059604645,
        "velocity_east_m_s": 0.05999999865889549,
        "velocity_down_m_s": 0.07000000029802322,
        "acceleration_forward": -0.5537599325180054,
        "acceleration_right": -1.2323323488235474,
        "acceleration_down": -12.925275802612305,
        "battery_id": 0,
        "battery_voltage": 13.236000061035156,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 4808,
        "udp_total": 110,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 83
      },
      {
        "id": 155,
        "datetime": "21/02/2023 02:04:17",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477c4f0>, <mavsdk.mission_raw.MissionItem object at 0xa477cb50>, <mavsdk.mission_raw.MissionItem object at 0xa477c640>, <mavsdk.mission_raw.MissionItem object at 0xa477c040>]",
        "mission_pause": 0,
        "time_usec": 102930992,
        "odometry_pos_body": "PositionBody: [x_m: 4.902204513549805, y_m: 1.478740930557251, z_m: -4.508902072906494]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 102836079,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5899999737739563,
        "current_climb_rate": -0.18040816485881803,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306508099999995,
        "gps_raw_lon_deg": 38.8900453,
        "gps_raw_abs_alt": 82.08000183105469,
        "gps_raw_velocity": 0.17999999225139618,
        "position_lat_deg": 47.3065084,
        "position_lon_deg": 38.8900452,
        "position_abs_alt": 83.70100402832031,
        "position_real_alt": 4.955000400543213,
        "heading_deg": 135.98,
        "odometry_pos_body_x": 4.910879611968994,
        "odometry_pos_body_y": 1.4929648637771606,
        "odometry_pos_body_z": -4.486380100250244,
        "odometry_vel_body_x": 0.08413900434970856,
        "odometry_vel_body_y": -0.2361054569482803,
        "odometry_vel_body_z": 0.20184502005577087,
        "odometry_ang_vel_body_roll": -1.30109965801239,
        "odometry_ang_vel_body_pitch": 0.10005200654268263,
        "odometry_ang_vel_body_yaw": 0.4142500162124634,
        "velocity_north_m_s": 0.17000000178813934,
        "velocity_east_m_s": 0.17999999225139618,
        "velocity_down_m_s": 0.1899999976158142,
        "acceleration_forward": -0.3662879467010498,
        "acceleration_right": 2.3014652729034424,
        "acceleration_down": -9.642969131469728,
        "battery_id": 0,
        "battery_voltage": 13.236000061035156,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 4832,
        "udp_total": 111,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 84
      },
      {
        "id": 156,
        "datetime": "21/02/2023 02:04:17",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477eda8>, <mavsdk.mission_raw.MissionItem object at 0xa4777940>, <mavsdk.mission_raw.MissionItem object at 0xa4777e98>, <mavsdk.mission_raw.MissionItem object at 0xa59134c0>]",
        "mission_pause": 0,
        "time_usec": 103190756,
        "odometry_pos_body": "PositionBody: [x_m: 4.904529571533203, y_m: 1.5250613689422607, z_m: -4.440819263458252]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 103205743,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.6100000143051147,
        "current_climb_rate": -0.08930719643831253,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306508099999995,
        "gps_raw_lon_deg": 38.8900457,
        "gps_raw_abs_alt": 82.03700256347656,
        "gps_raw_velocity": 0.14999999105930328,
        "position_lat_deg": 47.3065085,
        "position_lon_deg": 38.8900459,
        "position_abs_alt": 83.62100219726562,
        "position_real_alt": 4.875,
        "heading_deg": 143.21,
        "odometry_pos_body_x": 4.897990703582764,
        "odometry_pos_body_y": 1.541741967201233,
        "odometry_pos_body_z": -4.422177314758301,
        "odometry_vel_body_x": 0.16880269348621368,
        "odometry_vel_body_y": -0.1480066478252411,
        "odometry_vel_body_z": 0.06899401545524597,
        "odometry_ang_vel_body_roll": 0.02093038894236088,
        "odometry_ang_vel_body_pitch": 0.001515285694040358,
        "odometry_ang_vel_body_yaw": 0.31060269474983215,
        "velocity_north_m_s": -0.03999999910593033,
        "velocity_east_m_s": 0.20999999344348907,
        "velocity_down_m_s": 0.07999999821186066,
        "acceleration_forward": 0.8790839910507202,
        "acceleration_right": 1.7723757028579712,
        "acceleration_down": -14.482766151428224,
        "battery_id": 0,
        "battery_voltage": 13.142999649047852,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 22,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 4854,
        "udp_total": 111,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 84
      },
      {
        "id": 157,
        "datetime": "21/02/2023 02:04:18",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa591e238>, <mavsdk.mission_raw.MissionItem object at 0xa591ea90>, <mavsdk.mission_raw.MissionItem object at 0xa477c928>, <mavsdk.mission_raw.MissionItem object at 0xa477c3e8>]",
        "mission_pause": 0,
        "time_usec": 103530445,
        "odometry_pos_body": "PositionBody: [x_m: 4.896713733673096, y_m: 1.575594425201416, z_m: -4.404850959777832]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 103495476,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5999999642372131,
        "current_climb_rate": -0.0025201821699738503,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065084,
        "gps_raw_lon_deg": 38.8900466,
        "gps_raw_abs_alt": 81.98100280761719,
        "gps_raw_velocity": 0.2199999988079071,
        "position_lat_deg": 47.3065084,
        "position_lon_deg": 38.8900465,
        "position_abs_alt": 83.58800506591797,
        "position_real_alt": 4.8420000076293945,
        "heading_deg": 147.21,
        "odometry_pos_body_x": 4.892101287841797,
        "odometry_pos_body_y": 1.5887327194213867,
        "odometry_pos_body_z": -4.402117729187012,
        "odometry_vel_body_x": 0.17801585793495178,
        "odometry_vel_body_y": -0.12141621857881546,
        "odometry_vel_body_z": -0.06624225527048111,
        "odometry_ang_vel_body_roll": 0.16061459481716156,
        "odometry_ang_vel_body_pitch": 0.1862768679857254,
        "odometry_ang_vel_body_yaw": 0.24350208044052124,
        "velocity_north_m_s": -0.05999999865889549,
        "velocity_east_m_s": 0.17999999225139618,
        "velocity_down_m_s": -0.019999999552965164,
        "acceleration_forward": -0.3791154325008392,
        "acceleration_right": 4.226263999938965,
        "acceleration_down": -14.03959846496582,
        "battery_id": 0,
        "battery_voltage": 13.142999649047852,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 1,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 4854,
        "udp_total": 111,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 84
      },
      {
        "id": 158,
        "datetime": "21/02/2023 02:04:18",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477b880>, <mavsdk.mission_raw.MissionItem object at 0xa477b970>, <mavsdk.mission_raw.MissionItem object at 0xa4778ac0>, <mavsdk.mission_raw.MissionItem object at 0xa477ce80>]",
        "mission_pause": 0,
        "time_usec": 103730265,
        "odometry_pos_body": "PositionBody: [x_m: 4.882933616638184, y_m: 1.614425539970398, z_m: -4.400645732879639]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 103655331,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5699999928474426,
        "current_climb_rate": 0.13634367287158966,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065084,
        "gps_raw_lon_deg": 38.8900471,
        "gps_raw_abs_alt": 81.98800659179688,
        "gps_raw_velocity": 0.1899999976158142,
        "position_lat_deg": 47.3065083,
        "position_lon_deg": 38.890047,
        "position_abs_alt": 83.58300018310547,
        "position_real_alt": 4.837000370025635,
        "heading_deg": 150.11,
        "odometry_pos_body_x": 4.875884056091309,
        "odometry_pos_body_y": 1.6405551433563232,
        "odometry_pos_body_z": -4.407749652862549,
        "odometry_vel_body_x": 0.15490899980068207,
        "odometry_vel_body_y": -0.15665268898010254,
        "odometry_vel_body_z": -0.16090860962867737,
        "odometry_ang_vel_body_roll": 0.10831744968891144,
        "odometry_ang_vel_body_pitch": 0.1897822767496109,
        "odometry_ang_vel_body_yaw": 0.31139251589775085,
        "velocity_north_m_s": -0.07999999821186066,
        "velocity_east_m_s": 0.19999998807907104,
        "velocity_down_m_s": -0.09999999403953552,
        "acceleration_forward": -2.5441200733184814,
        "acceleration_right": -5.553234577178955,
        "acceleration_down": -6.14304780960083,
        "battery_id": 0,
        "battery_voltage": 13.142999649047852,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 4878,
        "udp_total": 111,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 84
      },
      {
        "id": 159,
        "datetime": "21/02/2023 02:04:18",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477d370>, <mavsdk.mission_raw.MissionItem object at 0xa477d4c0>, <mavsdk.mission_raw.MissionItem object at 0xa477da90>, <mavsdk.mission_raw.MissionItem object at 0xa477d718>]",
        "mission_pause": 0,
        "time_usec": 103990025,
        "odometry_pos_body": "PositionBody: [x_m: 4.8696770668029785, y_m: 1.6681208610534668, z_m: -4.425143718719482]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 103910145,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5399999618530273,
        "current_climb_rate": 0.25051379203796387,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065082,
        "gps_raw_lon_deg": 38.890047599999995,
        "gps_raw_abs_alt": 82.01100158691406,
        "gps_raw_velocity": 0.23999999463558197,
        "position_lat_deg": 47.3065082,
        "position_lon_deg": 38.890047599999995,
        "position_abs_alt": 83.59900665283203,
        "position_real_alt": 4.853000164031982,
        "heading_deg": 153.88,
        "odometry_pos_body_x": 4.867865562438965,
        "odometry_pos_body_y": 1.689601182937622,
        "odometry_pos_body_z": -4.44317102432251,
        "odometry_vel_body_x": 0.07492553442716599,
        "odometry_vel_body_y": -0.17046546936035156,
        "odometry_vel_body_z": -0.24684734642505649,
        "odometry_ang_vel_body_roll": -0.0367872416973114,
        "odometry_ang_vel_body_pitch": -0.0959598571062088,
        "odometry_ang_vel_body_yaw": 0.36983558535575867,
        "velocity_north_m_s": -0.03999999910593033,
        "velocity_east_m_s": 0.19999998807907104,
        "velocity_down_m_s": -0.20999999344348907,
        "acceleration_forward": 0.4434167742729187,
        "acceleration_right": 4.972096920013428,
        "acceleration_down": -10.635680198669434,
        "battery_id": 0,
        "battery_voltage": 13.142999649047852,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 25,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 4901,
        "udp_total": 112,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 85
      },
      {
        "id": 160,
        "datetime": "21/02/2023 02:04:19",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4781100>, <mavsdk.mission_raw.MissionItem object at 0xa4781a90>, <mavsdk.mission_raw.MissionItem object at 0xa47813b8>, <mavsdk.mission_raw.MissionItem object at 0xa47810b8>]",
        "mission_pause": 0,
        "time_usec": 104259779,
        "odometry_pos_body": "PositionBody: [x_m: 4.875977993011475, y_m: 1.7249765396118164, z_m: -4.478061199188232]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 104214818,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5099999904632568,
        "current_climb_rate": 0.2746044099330902,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065082,
        "gps_raw_lon_deg": 38.8900487,
        "gps_raw_abs_alt": 82.11000061035156,
        "gps_raw_velocity": 0.2199999988079071,
        "position_lat_deg": 47.3065082,
        "position_lon_deg": 38.8900486,
        "position_abs_alt": 83.66300201416016,
        "position_real_alt": 4.921000003814697,
        "heading_deg": 161.21,
        "odometry_pos_body_x": 4.913397312164307,
        "odometry_pos_body_y": 1.775134563446045,
        "odometry_pos_body_z": -4.530151844024658,
        "odometry_vel_body_x": -0.195499986410141,
        "odometry_vel_body_y": -0.22622136771678925,
        "odometry_vel_body_z": -0.2565871775150299,
        "odometry_ang_vel_body_roll": 1.052242875099182,
        "odometry_ang_vel_body_pitch": 0.2552509307861328,
        "odometry_ang_vel_body_yaw": 0.49495887756347656,
        "velocity_north_m_s": 0.14000000059604645,
        "velocity_east_m_s": 0.19999998807907104,
        "velocity_down_m_s": -0.25,
        "acceleration_forward": -0.7345557808876038,
        "acceleration_right": -5.536837100982666,
        "acceleration_down": -5.89998197555542,
        "battery_id": 0,
        "battery_voltage": 13.142999649047852,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 4925,
        "udp_total": 112,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 85
      },
      {
        "id": 161,
        "datetime": "21/02/2023 02:04:19",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xb2025b98>, <mavsdk.mission_raw.MissionItem object at 0xa5913ce8>, <mavsdk.mission_raw.MissionItem object at 0xa477daa8>, <mavsdk.mission_raw.MissionItem object at 0xa477dbb0>]",
        "mission_pause": 0,
        "time_usec": 104629440,
        "odometry_pos_body": "PositionBody: [x_m: 4.952722072601318, y_m: 1.805181860923767, z_m: -4.5510406494140625]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 104594471,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5399999618530273,
        "current_climb_rate": 0.08252875506877899,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065091,
        "gps_raw_lon_deg": 38.8900497,
        "gps_raw_abs_alt": 82.21700286865234,
        "gps_raw_velocity": 0.3100000023841858,
        "position_lat_deg": 47.3065088,
        "position_lon_deg": 38.8900495,
        "position_abs_alt": 83.72900390625,
        "position_real_alt": 4.9830002784729,
        "heading_deg": 168.78,
        "odometry_pos_body_x": 4.985215187072754,
        "odometry_pos_body_y": 1.8263487815856936,
        "odometry_pos_body_z": -4.55830717086792,
        "odometry_vel_body_x": -0.3456428647041321,
        "odometry_vel_body_y": -0.21956060826778412,
        "odometry_vel_body_z": -0.050713568925857544,
        "odometry_ang_vel_body_roll": -0.29110532999038696,
        "odometry_ang_vel_body_pitch": -0.32046952843666077,
        "odometry_ang_vel_body_yaw": 0.42560282349586487,
        "velocity_north_m_s": 0.3199999928474426,
        "velocity_east_m_s": 0.17999999225139618,
        "velocity_down_m_s": -0.12999999523162842,
        "acceleration_forward": -1.1738892793655396,
        "acceleration_right": 3.1364388465881348,
        "acceleration_down": -18.823375701904297,
        "battery_id": 0,
        "battery_voltage": 13.142999649047852,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 24,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 4947,
        "udp_total": 112,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 85
      },
      {
        "id": 162,
        "datetime": "21/02/2023 02:04:19",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4780280>, <mavsdk.mission_raw.MissionItem object at 0xa591e670>, <mavsdk.mission_raw.MissionItem object at 0xa4778358>, <mavsdk.mission_raw.MissionItem object at 0xa477ead8>]",
        "mission_pause": 0,
        "time_usec": 104889205,
        "odometry_pos_body": "PositionBody: [x_m: 5.048699855804443, y_m: 1.8609305620193481, z_m: -4.567349910736084]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 104934161,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5600000023841858,
        "current_climb_rate": -0.02779879793524742,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065097,
        "gps_raw_lon_deg": 38.8900501,
        "gps_raw_abs_alt": 82.23900604248047,
        "gps_raw_velocity": 0.35999998450279236,
        "position_lat_deg": 47.306509899999995,
        "position_lon_deg": 38.8900504,
        "position_abs_alt": 83.75100708007812,
        "position_real_alt": 5.005000114440918,
        "heading_deg": 179.38,
        "odometry_pos_body_x": 5.066126346588135,
        "odometry_pos_body_y": 1.8683947324752808,
        "odometry_pos_body_z": -4.568964004516602,
        "odometry_vel_body_x": -0.31961789727211,
        "odometry_vel_body_y": -0.09921672195196152,
        "odometry_vel_body_z": 0.038906581699848175,
        "odometry_ang_vel_body_roll": 0.9623570442199708,
        "odometry_ang_vel_body_pitch": 0.16336680948734283,
        "odometry_ang_vel_body_yaw": 0.5206586718559265,
        "velocity_north_m_s": 0.29999998211860657,
        "velocity_east_m_s": 0.07999999821186066,
        "velocity_down_m_s": 0.009999999776482582,
        "acceleration_forward": 0.660302460193634,
        "acceleration_right": 0.046315208077430725,
        "acceleration_down": -11.307741165161133,
        "battery_id": 0,
        "battery_voltage": 13.142999649047852,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 40,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 4965,
        "udp_total": 112,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 85
      },
      {
        "id": 163,
        "datetime": "21/02/2023 02:04:19",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4777940>, <mavsdk.mission_raw.MissionItem object at 0xa5913ce8>, <mavsdk.mission_raw.MissionItem object at 0xa4780718>, <mavsdk.mission_raw.MissionItem object at 0xa4780bf8>]",
        "mission_pause": 0,
        "time_usec": 105158957,
        "odometry_pos_body": "PositionBody: [x_m: 5.116612434387207, y_m: 1.882018804550171, z_m: -4.571901798248291]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 105173943,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5600000023841858,
        "current_climb_rate": -0.025034699589014053,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065104,
        "gps_raw_lon_deg": 38.8900504,
        "gps_raw_abs_alt": 82.23300170898438,
        "gps_raw_velocity": 0.41999998688697815,
        "position_lat_deg": 47.3065106,
        "position_lon_deg": 38.8900507,
        "position_abs_alt": 83.7560043334961,
        "position_real_alt": 5.010000228881836,
        "heading_deg": 184.2,
        "odometry_pos_body_x": 5.162951946258545,
        "odometry_pos_body_y": 1.8886566162109375,
        "odometry_pos_body_z": -4.574540615081787,
        "odometry_vel_body_x": -0.30351579189300537,
        "odometry_vel_body_y": 0.0006711668684147298,
        "odometry_vel_body_z": 0.01613394170999527,
        "odometry_ang_vel_body_roll": -0.32758957147598267,
        "odometry_ang_vel_body_pitch": 0.012042964808642864,
        "odometry_ang_vel_body_yaw": 0.2696731388568878,
        "velocity_north_m_s": 0.29999998211860657,
        "velocity_east_m_s": 0.029999999329447743,
        "velocity_down_m_s": 0.019999999552965164,
        "acceleration_forward": -2.6413371562957764,
        "acceleration_right": -3.529268741607666,
        "acceleration_down": -6.351783275604248,
        "battery_id": 0,
        "battery_voltage": 13.158000946044922,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 3,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 4971,
        "udp_total": 115,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 86
      },
      {
        "id": 164,
        "datetime": "21/02/2023 02:04:20",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477a250>, <mavsdk.mission_raw.MissionItem object at 0xa4782568>, <mavsdk.mission_raw.MissionItem object at 0xa4782fa0>, <mavsdk.mission_raw.MissionItem object at 0xa47822b0>]",
        "mission_pause": 0,
        "time_usec": 105398738,
        "odometry_pos_body": "PositionBody: [x_m: 5.200881004333496, y_m: 1.8934056758880615, z_m: -4.5752034187316895]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 105423714,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5600000023841858,
        "current_climb_rate": -0.014314244501292706,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306511699999994,
        "gps_raw_lon_deg": 38.8900509,
        "gps_raw_abs_alt": 82.23300170898438,
        "gps_raw_velocity": 0.3400000035762787,
        "position_lat_deg": 47.3065113,
        "position_lon_deg": 38.8900508,
        "position_abs_alt": 83.75700378417969,
        "position_real_alt": 5.010000228881836,
        "heading_deg": 189.05,
        "odometry_pos_body_x": 5.246371746063232,
        "odometry_pos_body_y": 1.9016519784927368,
        "odometry_pos_body_z": -4.574090003967285,
        "odometry_vel_body_x": -0.2887054681777954,
        "odometry_vel_body_y": -0.03539654240012169,
        "odometry_vel_body_z": 0.03169026970863342,
        "odometry_ang_vel_body_roll": 0.6072372794151306,
        "odometry_ang_vel_body_pitch": 0.2796252965927124,
        "odometry_ang_vel_body_yaw": 0.399976909160614,
        "velocity_north_m_s": 0.2800000011920929,
        "velocity_east_m_s": 0.04999999701976776,
        "velocity_down_m_s": 0,
        "acceleration_forward": 1.844030499458313,
        "acceleration_right": 1.8959904909133911,
        "acceleration_down": -10.384381294250488,
        "battery_id": 0,
        "battery_voltage": 13.158000946044922,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 4993,
        "udp_total": 115,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 86
      },
      {
        "id": 165,
        "datetime": "21/02/2023 02:04:20",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4781418>, <mavsdk.mission_raw.MissionItem object at 0xa4781220>, <mavsdk.mission_raw.MissionItem object at 0xa4781e20>, <mavsdk.mission_raw.MissionItem object at 0xa4781e50>]",
        "mission_pause": 0,
        "time_usec": 105658502,
        "odometry_pos_body": "PositionBody: [x_m: 5.286570072174072, y_m: 1.9178026914596558, z_m: -4.572739124298096]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 105703461,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.550000011920929,
        "current_climb_rate": 0.007608114741742611,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065123,
        "gps_raw_lon_deg": 38.8900511,
        "gps_raw_abs_alt": 82.23500061035156,
        "gps_raw_velocity": 0.3400000035762787,
        "position_lat_deg": 47.306512,
        "position_lon_deg": 38.890051199999995,
        "position_abs_alt": 83.7540054321289,
        "position_real_alt": 5.008000373840332,
        "heading_deg": 194.33,
        "odometry_pos_body_x": 5.297683238983154,
        "odometry_pos_body_y": 1.9233274459838867,
        "odometry_pos_body_z": -4.572099208831787,
        "odometry_vel_body_x": -0.24400651454925537,
        "odometry_vel_body_y": -0.07057122141122818,
        "odometry_vel_body_z": 0.012298992834985256,
        "odometry_ang_vel_body_roll": -0.0617290623486042,
        "odometry_ang_vel_body_pitch": -0.007545691449195147,
        "odometry_ang_vel_body_yaw": 0.4324282705783844,
        "velocity_north_m_s": 0.20999999344348907,
        "velocity_east_m_s": 0.119999997317791,
        "velocity_down_m_s": 0,
        "acceleration_forward": 1.367039442062378,
        "acceleration_right": 1.4568676948547363,
        "acceleration_down": -8.912630081176758,
        "battery_id": 0,
        "battery_voltage": 13.158000946044922,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 0,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 5017,
        "udp_total": 115,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 86
      },
      {
        "id": 166,
        "datetime": "21/02/2023 02:04:20",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4781280>, <mavsdk.mission_raw.MissionItem object at 0xa4781118>, <mavsdk.mission_raw.MissionItem object at 0xa4781670>, <mavsdk.mission_raw.MissionItem object at 0xa4781340>]",
        "mission_pause": 0,
        "time_usec": 105958231,
        "odometry_pos_body": "PositionBody: [x_m: 5.359792709350586, y_m: 1.9599549770355225, z_m: -4.562106609344482]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 105883297,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5600000023841858,
        "current_climb_rate": -0.025651928037405018,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065128,
        "gps_raw_lon_deg": 38.8900515,
        "gps_raw_abs_alt": 82.23300170898438,
        "gps_raw_velocity": 0.29999998211860657,
        "position_lat_deg": 47.306512399999995,
        "position_lon_deg": 38.8900515,
        "position_abs_alt": 83.74600219726562,
        "position_real_alt": 5,
        "heading_deg": 199.08,
        "odometry_pos_body_x": 5.374189376831055,
        "odometry_pos_body_y": 1.969730854034424,
        "odometry_pos_body_z": -4.559898853302002,
        "odometry_vel_body_x": -0.12382320314645769,
        "odometry_vel_body_y": -0.08734782785177231,
        "odometry_vel_body_z": 0.009005196392536163,
        "odometry_ang_vel_body_roll": 0.5056101679801941,
        "odometry_ang_vel_body_pitch": -0.2700137197971344,
        "odometry_ang_vel_body_yaw": 0.4496861696243286,
        "velocity_north_m_s": 0.14999999105930328,
        "velocity_east_m_s": 0.14000000059604645,
        "velocity_down_m_s": 0.03999999910593033,
        "acceleration_forward": -0.6585175395011902,
        "acceleration_right": -1.7880423069000244,
        "acceleration_down": -6.791474342346191,
        "battery_id": 0,
        "battery_voltage": 13.158000946044922,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 0,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 5017,
        "udp_total": 115,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 86
      },
      {
        "id": 167,
        "datetime": "21/02/2023 02:04:20",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4780238>, <mavsdk.mission_raw.MissionItem object at 0xa47805b0>, <mavsdk.mission_raw.MissionItem object at 0xa4780e68>, <mavsdk.mission_raw.MissionItem object at 0xa4780d48>]",
        "mission_pause": 0,
        "time_usec": 106168039,
        "odometry_pos_body": "PositionBody: [x_m: 5.390751838684082, y_m: 1.9816304445266724, z_m: -4.56006383895874]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 106038154,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.550000011920929,
        "current_climb_rate": 0.04848834127187729,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065131,
        "gps_raw_lon_deg": 38.890051799999995,
        "gps_raw_abs_alt": 82.21900177001953,
        "gps_raw_velocity": 0.20999999344348907,
        "position_lat_deg": 47.3065127,
        "position_lon_deg": 38.890051799999995,
        "position_abs_alt": 83.74200439453125,
        "position_real_alt": 4.996000289916992,
        "heading_deg": 203.27,
        "odometry_pos_body_x": 5.397519111633301,
        "odometry_pos_body_y": 1.9864652156829832,
        "odometry_pos_body_z": -4.567344665527344,
        "odometry_vel_body_x": -0.012569006532430649,
        "odometry_vel_body_y": -0.06129023805260658,
        "odometry_vel_body_z": -0.06104396656155586,
        "odometry_ang_vel_body_roll": 0.9436526298522948,
        "odometry_ang_vel_body_pitch": 0.4444388747215271,
        "odometry_ang_vel_body_yaw": 0.4500889182090759,
        "velocity_north_m_s": 0.07000000029802322,
        "velocity_east_m_s": 0.10999999940395357,
        "velocity_down_m_s": 0,
        "acceleration_forward": -0.9796162843704224,
        "acceleration_right": -4.498095512390137,
        "acceleration_down": -6.67301607131958,
        "battery_id": 0,
        "battery_voltage": 13.158000946044922,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 1,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 5039,
        "udp_total": 116,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 87
      },
      {
        "id": 168,
        "datetime": "21/02/2023 02:04:21",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa590c880>, <mavsdk.mission_raw.MissionItem object at 0xa5913c10>, <mavsdk.mission_raw.MissionItem object at 0xa5913b50>, <mavsdk.mission_raw.MissionItem object at 0xa5913d90>]",
        "mission_pause": 0,
        "time_usec": 106387835,
        "odometry_pos_body": "PositionBody: [x_m: 5.401217937469482, y_m: 1.9968438148498535, z_m: -4.584540843963623]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 106277962,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5299999713897705,
        "current_climb_rate": 0.07407616823911667,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065131,
        "gps_raw_lon_deg": 38.8900522,
        "gps_raw_abs_alt": 82.21200561523438,
        "gps_raw_velocity": 0.14999999105930328,
        "position_lat_deg": 47.3065129,
        "position_lon_deg": 38.890052,
        "position_abs_alt": 83.75200653076172,
        "position_real_alt": 5.00600004196167,
        "heading_deg": 209.34,
        "odometry_pos_body_x": 5.400778770446777,
        "odometry_pos_body_y": 2.0035016536712646,
        "odometry_pos_body_z": -4.594934463500977,
        "odometry_vel_body_x": 0.022528929635882378,
        "odometry_vel_body_y": -0.08633969724178314,
        "odometry_vel_body_z": -0.0899352952837944,
        "odometry_ang_vel_body_roll": -0.45246216654777527,
        "odometry_ang_vel_body_pitch": 0.044535715132951736,
        "odometry_ang_vel_body_yaw": 0.4385315179824829,
        "velocity_north_m_s": -0.029999999329447743,
        "velocity_east_m_s": 0.04999999701976776,
        "velocity_down_m_s": -0.05999999865889549,
        "acceleration_forward": -1.3622000217437744,
        "acceleration_right": -2.360966205596924,
        "acceleration_down": -10.229007720947266,
        "battery_id": 0,
        "battery_voltage": 13.158000946044922,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 5063,
        "udp_total": 116,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 87
      },
      {
        "id": 169,
        "datetime": "21/02/2023 02:04:21",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477c4a8>, <mavsdk.mission_raw.MissionItem object at 0xa477c580>, <mavsdk.mission_raw.MissionItem object at 0xa476caf0>, <mavsdk.mission_raw.MissionItem object at 0xa4781718>]",
        "mission_pause": 0,
        "time_usec": 106627617,
        "odometry_pos_body": "PositionBody: [x_m: 5.401471138000488, y_m: 2.0202648639678955, z_m: -4.611789226531982]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 106447781,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5399999618530273,
        "current_climb_rate": 0.05799085646867752,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065131,
        "gps_raw_lon_deg": 38.8900528,
        "gps_raw_abs_alt": 82.23700714111328,
        "gps_raw_velocity": 0.119999997317791,
        "position_lat_deg": 47.3065129,
        "position_lon_deg": 38.8900522,
        "position_abs_alt": 83.7760009765625,
        "position_real_alt": 5.03000020980835,
        "heading_deg": 213.45,
        "odometry_pos_body_x": 5.401975154876709,
        "odometry_pos_body_y": 2.0321409702301025,
        "odometry_pos_body_z": -4.618534088134766,
        "odometry_vel_body_x": -0.04397612065076828,
        "odometry_vel_body_y": -0.1157098338007927,
        "odometry_vel_body_z": -0.036254044622182846,
        "odometry_ang_vel_body_roll": 1.2856321334838867,
        "odometry_ang_vel_body_pitch": 0.12743115425109863,
        "odometry_ang_vel_body_yaw": 0.331900954246521,
        "velocity_north_m_s": -0.07000000029802322,
        "velocity_east_m_s": 0.07000000029802322,
        "velocity_down_m_s": -0.05999999865889549,
        "acceleration_forward": -0.2783529460430145,
        "acceleration_right": -0.7964262366294861,
        "acceleration_down": -8.576091766357422,
        "battery_id": 0,
        "battery_voltage": 13.158000946044922,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 5063,
        "udp_total": 116,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 87
      },
      {
        "id": 170,
        "datetime": "21/02/2023 02:04:21",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4781178>, <mavsdk.mission_raw.MissionItem object at 0xa47812c8>, <mavsdk.mission_raw.MissionItem object at 0xa4781310>, <mavsdk.mission_raw.MissionItem object at 0xa477c508>]",
        "mission_pause": 0,
        "time_usec": 106867405,
        "odometry_pos_body": "PositionBody: [x_m: 5.400533199310303, y_m: 2.0550694465637207, z_m: -4.625644683837891]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 106577667,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5299999713897705,
        "current_climb_rate": 0.020201176404953003,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306512999999995,
        "gps_raw_lon_deg": 38.890053,
        "gps_raw_abs_alt": 82.25000762939453,
        "gps_raw_velocity": 0.119999997317791,
        "position_lat_deg": 47.3065129,
        "position_lon_deg": 38.8900525,
        "position_abs_alt": 83.79500579833984,
        "position_real_alt": 5.050000190734863,
        "heading_deg": 218.61,
        "odometry_pos_body_x": 5.397616863250732,
        "odometry_pos_body_y": 2.0763065814971924,
        "odometry_pos_body_z": -4.627752304077148,
        "odometry_vel_body_x": -0.10056877881288528,
        "odometry_vel_body_y": -0.15129874646663666,
        "odometry_vel_body_z": -0.01534826960414648,
        "odometry_ang_vel_body_roll": -0.025754190981388092,
        "odometry_ang_vel_body_pitch": 0.328421413898468,
        "odometry_ang_vel_body_yaw": 0.3996644914150238,
        "velocity_north_m_s": -0.03999999910593033,
        "velocity_east_m_s": 0.10999999940395357,
        "velocity_down_m_s": -0.029999999329447743,
        "acceleration_forward": -0.7400725483894348,
        "acceleration_right": -0.2828427255153656,
        "acceleration_down": -10.08335018157959,
        "battery_id": 0,
        "battery_voltage": 13.158000946044922,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 5085,
        "udp_total": 116,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 87
      },
      {
        "id": 171,
        "datetime": "21/02/2023 02:04:21",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4780388>, <mavsdk.mission_raw.MissionItem object at 0xa4780160>, <mavsdk.mission_raw.MissionItem object at 0xa47800a0>, <mavsdk.mission_raw.MissionItem object at 0xa47808e0>]",
        "mission_pause": 0,
        "time_usec": 107192111,
        "odometry_pos_body": "PositionBody: [x_m: 5.387052059173584, y_m: 2.1209216117858887, z_m: -4.631161689758301]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 106942329,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5299999713897705,
        "current_climb_rate": 0.02648777887225151,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065127,
        "gps_raw_lon_deg": 38.8900541,
        "gps_raw_abs_alt": 82.25300598144531,
        "gps_raw_velocity": 0.23999999463558197,
        "position_lat_deg": 47.3065129,
        "position_lon_deg": 38.8900531,
        "position_abs_alt": 83.80900573730469,
        "position_real_alt": 5.063000202178955,
        "heading_deg": 224.15,
        "odometry_pos_body_x": 5.380895614624023,
        "odometry_pos_body_y": 2.138842821121216,
        "odometry_pos_body_z": -4.630720138549805,
        "odometry_vel_body_x": -0.1465691328048706,
        "odometry_vel_body_y": -0.2199483811855316,
        "odometry_vel_body_z": -0.026252171024680138,
        "odometry_ang_vel_body_roll": 1.1786928176879885,
        "odometry_ang_vel_body_pitch": -0.21857325732707977,
        "odometry_ang_vel_body_yaw": 0.31887876987457275,
        "velocity_north_m_s": -0.029999999329447743,
        "velocity_east_m_s": 0.1599999964237213,
        "velocity_down_m_s": -0.03999999910593033,
        "acceleration_forward": -1.172387719154358,
        "acceleration_right": 1.0812559127807615,
        "acceleration_down": -9.697672843933104,
        "battery_id": 0,
        "battery_voltage": 13.163999557495115,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 5109,
        "udp_total": 117,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 88
      },
      {
        "id": 172,
        "datetime": "21/02/2023 02:04:22",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477d628>, <mavsdk.mission_raw.MissionItem object at 0xa477d328>, <mavsdk.mission_raw.MissionItem object at 0xa476ca60>, <mavsdk.mission_raw.MissionItem object at 0xa5910bf8>]",
        "mission_pause": 0,
        "time_usec": 107501822,
        "odometry_pos_body": "PositionBody: [x_m: 5.351877689361572, y_m: 2.202230215072632, z_m: -4.625943660736084]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 107212084,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5399999618530273,
        "current_climb_rate": -0.009633847512304785,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065126,
        "gps_raw_lon_deg": 38.8900548,
        "gps_raw_abs_alt": 82.25700378417969,
        "gps_raw_velocity": 0.2800000011920929,
        "position_lat_deg": 47.3065128,
        "position_lon_deg": 38.890054,
        "position_abs_alt": 83.81300354003906,
        "position_real_alt": 5.067000389099121,
        "heading_deg": 231.16,
        "odometry_pos_body_x": 5.332819938659668,
        "odometry_pos_body_y": 2.230332612991333,
        "odometry_pos_body_z": -4.620856761932373,
        "odometry_vel_body_x": -0.07361535727977753,
        "odometry_vel_body_y": -0.29808247089385986,
        "odometry_vel_body_z": 0.01913655735552311,
        "odometry_ang_vel_body_roll": -0.8187959790229797,
        "odometry_ang_vel_body_pitch": -0.3151569068431854,
        "odometry_ang_vel_body_yaw": 0.4499249160289765,
        "velocity_north_m_s": -0.07999999821186066,
        "velocity_east_m_s": 0.25,
        "velocity_down_m_s": -0.019999999552965164,
        "acceleration_forward": -0.31099733710289,
        "acceleration_right": -0.8482208847999573,
        "acceleration_down": -4.811134338378906,
        "battery_id": 0,
        "battery_voltage": 13.163999557495115,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 5131,
        "udp_total": 117,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 88
      },
      {
        "id": 173,
        "datetime": "21/02/2023 02:04:22",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa47814d8>, <mavsdk.mission_raw.MissionItem object at 0xa4781700>, <mavsdk.mission_raw.MissionItem object at 0xa4781820>, <mavsdk.mission_raw.MissionItem object at 0xa4781f10>]",
        "mission_pause": 0,
        "time_usec": 107791556,
        "odometry_pos_body": "PositionBody: [x_m: 5.29538631439209, y_m: 2.26496958732605, z_m: -4.611996173858643]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 107456860,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5399999618530273,
        "current_climb_rate": -0.014014482498168944,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306512399999995,
        "gps_raw_lon_deg": 38.890055499999995,
        "gps_raw_abs_alt": 82.26400756835938,
        "gps_raw_velocity": 0.29999998211860657,
        "position_lat_deg": 47.3065125,
        "position_lon_deg": 38.8900548,
        "position_abs_alt": 83.80900573730469,
        "position_real_alt": 5.063000202178955,
        "heading_deg": 236.06,
        "odometry_pos_body_x": 5.266686916351318,
        "odometry_pos_body_y": 2.2807891368865967,
        "odometry_pos_body_z": -4.608374118804932,
        "odometry_vel_body_x": 0.03851675987243652,
        "odometry_vel_body_y": -0.3604144752025604,
        "odometry_vel_body_z": -0.006992548704147339,
        "odometry_ang_vel_body_roll": -0.16040633618831637,
        "odometry_ang_vel_body_pitch": -0.0964287891983986,
        "odometry_ang_vel_body_yaw": 0.43165239691734314,
        "velocity_north_m_s": -0.14000000059604645,
        "velocity_east_m_s": 0.23999999463558197,
        "velocity_down_m_s": -0.009999999776482582,
        "acceleration_forward": 1.270412564277649,
        "acceleration_right": -5.123631954193115,
        "acceleration_down": -5.032351016998291,
        "battery_id": 0,
        "battery_voltage": 13.163999557495115,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 0,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 5155,
        "udp_total": 117,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 88
      },
      {
        "id": 174,
        "datetime": "21/02/2023 02:04:22",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa58fc1d8>, <mavsdk.mission_raw.MissionItem object at 0xa4781880>, <mavsdk.mission_raw.MissionItem object at 0xa4781da8>, <mavsdk.mission_raw.MissionItem object at 0xa4781bf8>]",
        "mission_pause": 0,
        "time_usec": 108121258,
        "odometry_pos_body": "PositionBody: [x_m: 5.178369045257568, y_m: 2.3021774291992188, z_m: -4.60206937789917]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 107791556,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5199999809265137,
        "current_climb_rate": 0.0692974254488945,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065112,
        "gps_raw_lon_deg": 38.8900562,
        "gps_raw_abs_alt": 82.25700378417969,
        "gps_raw_velocity": 0.32999998331069946,
        "position_lat_deg": 47.3065118,
        "position_lon_deg": 38.8900558,
        "position_abs_alt": 83.79200744628906,
        "position_real_alt": 5.046000003814697,
        "heading_deg": 245.63,
        "odometry_pos_body_x": 5.129363536834717,
        "odometry_pos_body_y": 2.305042266845703,
        "odometry_pos_body_z": -4.603643894195557,
        "odometry_vel_body_x": 0.1866205930709839,
        "odometry_vel_body_y": -0.484151691198349,
        "odometry_vel_body_z": -0.07207699865102768,
        "odometry_ang_vel_body_roll": -0.3625512421131134,
        "odometry_ang_vel_body_pitch": 0.6362210512161255,
        "odometry_ang_vel_body_yaw": 0.3201054036617279,
        "velocity_north_m_s": -0.3100000023841858,
        "velocity_east_m_s": 0.119999997317791,
        "velocity_down_m_s": 0,
        "acceleration_forward": -0.8074206113815308,
        "acceleration_right": -4.134585857391357,
        "acceleration_down": -19.662097930908203,
        "battery_id": 0,
        "battery_voltage": 13.163999557495115,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 23,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 5178,
        "udp_total": 118,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 89
      },
      {
        "id": 175,
        "datetime": "21/02/2023 02:04:23",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477de20>, <mavsdk.mission_raw.MissionItem object at 0xa477d100>, <mavsdk.mission_raw.MissionItem object at 0xa4781478>, <mavsdk.mission_raw.MissionItem object at 0xa4781748>]",
        "mission_pause": 0,
        "time_usec": 108430973,
        "odometry_pos_body": "PositionBody: [x_m: 5.025307655334473, y_m: 2.300579309463501, z_m: -4.616601943969727]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 108156223,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5199999809265137,
        "current_climb_rate": 0.06710776686668396,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065094,
        "gps_raw_lon_deg": 38.890056,
        "gps_raw_abs_alt": 82.2750015258789,
        "gps_raw_velocity": 0.5799999833106995,
        "position_lat_deg": 47.306510499999995,
        "position_lon_deg": 38.8900562,
        "position_abs_alt": 83.78600311279297,
        "position_real_alt": 5.039000034332275,
        "heading_deg": 255.29,
        "odometry_pos_body_x": 4.951817512512207,
        "odometry_pos_body_y": 2.2894976139068604,
        "odometry_pos_body_z": -4.620917320251465,
        "odometry_vel_body_x": 0.2333664298057556,
        "odometry_vel_body_y": -0.5629441142082214,
        "odometry_vel_body_z": -0.03071613982319832,
        "odometry_ang_vel_body_roll": 1.639575481414795,
        "odometry_ang_vel_body_pitch": 0.2117859572172165,
        "odometry_ang_vel_body_yaw": 0.3279409110546112,
        "velocity_north_m_s": -0.5199999809265137,
        "velocity_east_m_s": -0.04999999701976776,
        "velocity_down_m_s": -0.05999999865889549,
        "acceleration_forward": 0.5484771728515625,
        "acceleration_right": 1.0069222450256348,
        "acceleration_down": -16.862253189086914,
        "battery_id": 0,
        "battery_voltage": 13.163999557495115,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 29,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 5188,
        "udp_total": 118,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 89
      },
      {
        "id": 176,
        "datetime": "21/02/2023 02:04:23",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4780ac0>, <mavsdk.mission_raw.MissionItem object at 0xa4780718>, <mavsdk.mission_raw.MissionItem object at 0xa47829b8>, <mavsdk.mission_raw.MissionItem object at 0xa4782610>]",
        "mission_pause": 0,
        "time_usec": 108730701,
        "odometry_pos_body": "PositionBody: [x_m: 4.854183673858643, y_m: 2.2650034427642822, z_m: -4.618430137634277]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 108490919,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5299999713897705,
        "current_climb_rate": 0.027669506147503853,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065084,
        "gps_raw_lon_deg": 38.8900556,
        "gps_raw_abs_alt": 82.2820053100586,
        "gps_raw_velocity": 0.5799999833106995,
        "position_lat_deg": 47.3065088,
        "position_lon_deg": 38.890056,
        "position_abs_alt": 83.8030014038086,
        "position_real_alt": 5.057000160217285,
        "heading_deg": 261.22,
        "odometry_pos_body_x": 4.789331436157227,
        "odometry_pos_body_y": 2.237859010696411,
        "odometry_pos_body_z": -4.6201934814453125,
        "odometry_vel_body_x": 0.2882317304611206,
        "odometry_vel_body_y": -0.4349845349788666,
        "odometry_vel_body_z": -0.015929097309708595,
        "odometry_ang_vel_body_roll": 0.9264227151870728,
        "odometry_ang_vel_body_pitch": 0.256186842918396,
        "odometry_ang_vel_body_yaw": 0.0803816020488739,
        "velocity_north_m_s": -0.5999999642372131,
        "velocity_east_m_s": -0.14999999105930328,
        "velocity_down_m_s": 0,
        "acceleration_forward": -0.33215534687042236,
        "acceleration_right": 4.325467586517334,
        "acceleration_down": -14.399245262145996,
        "battery_id": 0,
        "battery_voltage": 13.163999557495115,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 47,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 5202,
        "udp_total": 118,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 89
      },
      {
        "id": 177,
        "datetime": "21/02/2023 02:04:23",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477b388>, <mavsdk.mission_raw.MissionItem object at 0xa477bcd0>, <mavsdk.mission_raw.MissionItem object at 0xa477b148>, <mavsdk.mission_raw.MissionItem object at 0xa477b2b0>]",
        "mission_pause": 0,
        "time_usec": 109030428,
        "odometry_pos_body": "PositionBody: [x_m: 4.697656154632568, y_m: 2.1841001510620117, z_m: -4.6288251876831055]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 108810658,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5199999809265137,
        "current_climb_rate": 0.04856659471988678,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065064,
        "gps_raw_lon_deg": 38.8900543,
        "gps_raw_abs_alt": 82.28900146484375,
        "gps_raw_velocity": 0.5399999618530273,
        "position_lat_deg": 47.306507499999995,
        "position_lon_deg": 38.8900554,
        "position_abs_alt": 83.802001953125,
        "position_real_alt": 5.055000305175781,
        "heading_deg": 263.42,
        "odometry_pos_body_x": 4.673708915710449,
        "odometry_pos_body_y": 2.166363000869751,
        "odometry_pos_body_z": -4.631740570068359,
        "odometry_vel_body_x": 0.3399695158004761,
        "odometry_vel_body_y": -0.28920915722846985,
        "odometry_vel_body_z": -0.035316597670316696,
        "odometry_ang_vel_body_roll": -0.6360405683517456,
        "odometry_ang_vel_body_pitch": -0.0921306312084198,
        "odometry_ang_vel_body_yaw": 0.144020676612854,
        "velocity_north_m_s": -0.4699999988079071,
        "velocity_east_m_s": -0.2299999892711639,
        "velocity_down_m_s": -0.03999999910593033,
        "acceleration_forward": 1.5264753103256226,
        "acceleration_right": -0.3956297636032105,
        "acceleration_down": -10.387469291687012,
        "battery_id": 0,
        "battery_voltage": 13.170001029968262,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 5224,
        "udp_total": 119,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 90
      },
      {
        "id": 178,
        "datetime": "21/02/2023 02:04:24",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa47805b0>, <mavsdk.mission_raw.MissionItem object at 0xa47751a8>, <mavsdk.mission_raw.MissionItem object at 0xa4775f58>, <mavsdk.mission_raw.MissionItem object at 0xa4775310>]",
        "mission_pause": 0,
        "time_usec": 109290196,
        "odometry_pos_body": "PositionBody: [x_m: 4.619258403778076, y_m: 2.114574909210205, z_m: -4.636529922485352]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 109145323,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5199999809265137,
        "current_climb_rate": 0.036739856004714966,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306505699999995,
        "gps_raw_lon_deg": 38.8900536,
        "gps_raw_abs_alt": 82.3030014038086,
        "gps_raw_velocity": 0.5,
        "position_lat_deg": 47.3065062,
        "position_lon_deg": 38.8900542,
        "position_abs_alt": 83.81600189208984,
        "position_real_alt": 5.070000171661377,
        "heading_deg": 266.94,
        "odometry_pos_body_x": 4.569138050079346,
        "odometry_pos_body_y": 2.0714175701141357,
        "odometry_pos_body_z": -4.641890525817871,
        "odometry_vel_body_x": 0.3240058720111847,
        "odometry_vel_body_y": -0.22078591585159305,
        "odometry_vel_body_z": -0.006062374450266361,
        "odometry_ang_vel_body_roll": -0.8148795962333679,
        "odometry_ang_vel_body_pitch": 0.011381027288734911,
        "odometry_ang_vel_body_yaw": 0.26316824555397034,
        "velocity_north_m_s": -0.25999999046325684,
        "velocity_east_m_s": -0.32999998331069946,
        "velocity_down_m_s": -0.05999999865889549,
        "acceleration_forward": 0.1773790866136551,
        "acceleration_right": 0.6653183102607727,
        "acceleration_down": -8.241275787353516,
        "battery_id": 0,
        "battery_voltage": 13.170001029968262,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 5248,
        "udp_total": 119,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 90
      },
      {
        "id": 179,
        "datetime": "21/02/2023 02:04:24",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4781958>, <mavsdk.mission_raw.MissionItem object at 0xa4780190>, <mavsdk.mission_raw.MissionItem object at 0xa477dfa0>, <mavsdk.mission_raw.MissionItem object at 0xa477d5c8>]",
        "mission_pause": 0,
        "time_usec": 109559947,
        "odometry_pos_body": "PositionBody: [x_m: 4.535714626312256, y_m: 2.0320634841918945, z_m: -4.6445136070251465]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 109395129,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5199999809265137,
        "current_climb_rate": 0.03448018431663513,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065053,
        "gps_raw_lon_deg": 38.8900527,
        "gps_raw_abs_alt": 82.29700469970703,
        "gps_raw_velocity": 0.4099999964237213,
        "position_lat_deg": 47.3065055,
        "position_lon_deg": 38.8900532,
        "position_abs_alt": 83.82400512695312,
        "position_real_alt": 5.078000068664551,
        "heading_deg": 270.02,
        "odometry_pos_body_x": 4.519235134124756,
        "odometry_pos_body_y": 2.0028834342956543,
        "odometry_pos_body_z": -4.643555164337158,
        "odometry_vel_body_x": 0.26553574204444885,
        "odometry_vel_body_y": -0.09896396845579147,
        "odometry_vel_body_z": 0.013809540309011936,
        "odometry_ang_vel_body_roll": -0.04812749847769737,
        "odometry_ang_vel_body_pitch": -0.007818976417183876,
        "odometry_ang_vel_body_yaw": 0.3179517686367035,
        "velocity_north_m_s": -0.1899999976158142,
        "velocity_east_m_s": -0.29999998211860657,
        "velocity_down_m_s": -0.029999999329447743,
        "acceleration_forward": 2.0345749855041504,
        "acceleration_right": -3.088122844696045,
        "acceleration_down": -7.625247955322266,
        "battery_id": 0,
        "battery_voltage": 13.170001029968262,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 0,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 5270,
        "udp_total": 119,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 90
      },
      {
        "id": 180,
        "datetime": "21/02/2023 02:04:24",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4781328>, <mavsdk.mission_raw.MissionItem object at 0xa4781c28>, <mavsdk.mission_raw.MissionItem object at 0xa4781838>, <mavsdk.mission_raw.MissionItem object at 0xa4775d30>]",
        "mission_pause": 0,
        "time_usec": 109829701,
        "odometry_pos_body": "PositionBody: [x_m: 4.501254081726074, y_m: 1.9579728841781616, z_m: -4.639459609985352]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 109754770,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5199999809265137,
        "current_climb_rate": 0.004726335406303406,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065047,
        "gps_raw_lon_deg": 38.8900513,
        "gps_raw_abs_alt": 82.29700469970703,
        "gps_raw_velocity": 0.25999999046325684,
        "position_lat_deg": 47.3065048,
        "position_lon_deg": 38.8900516,
        "position_abs_alt": 83.82200622558594,
        "position_real_alt": 5.076000213623047,
        "heading_deg": 278.34000000000003,
        "odometry_pos_body_x": 4.498798370361328,
        "odometry_pos_body_y": 1.9345532655715945,
        "odometry_pos_body_z": -4.63767671585083,
        "odometry_vel_body_x": 0.22414876520633695,
        "odometry_vel_body_y": 0.04482526332139969,
        "odometry_vel_body_z": 0.013573636300861835,
        "odometry_ang_vel_body_roll": -0.3641162812709808,
        "odometry_ang_vel_body_pitch": -0.20203737914562225,
        "odometry_ang_vel_body_yaw": 0.3808883130550385,
        "velocity_north_m_s": 0.07000000029802322,
        "velocity_east_m_s": -0.20999999344348907,
        "velocity_down_m_s": 0,
        "acceleration_forward": 2.4077889919281006,
        "acceleration_right": 6.230981349945068,
        "acceleration_down": -4.588770866394043,
        "battery_id": 0,
        "battery_voltage": 13.170001029968262,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 0,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 5271,
        "udp_total": 119,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 90
      },
      {
        "id": 181,
        "datetime": "21/02/2023 02:04:24",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa47820d0>, <mavsdk.mission_raw.MissionItem object at 0xa4775760>, <mavsdk.mission_raw.MissionItem object at 0xa591ea90>, <mavsdk.mission_raw.MissionItem object at 0xa477d748>]",
        "mission_pause": 0,
        "time_usec": 110129430,
        "odometry_pos_body": "PositionBody: [x_m: 4.506080627441406, y_m: 1.8920793533325195, z_m: -4.6328511238098145]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 110049502,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5199999809265137,
        "current_climb_rate": -0.00919320248067379,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065048,
        "gps_raw_lon_deg": 38.8900508,
        "gps_raw_abs_alt": 82.30400085449219,
        "gps_raw_velocity": 0.1899999976158142,
        "position_lat_deg": 47.3065049,
        "position_lon_deg": 38.8900507,
        "position_abs_alt": 83.81500244140625,
        "position_real_alt": 5.069000244140625,
        "heading_deg": 283.14,
        "odometry_pos_body_x": 4.5119123458862305,
        "odometry_pos_body_y": 1.8820246458053589,
        "odometry_pos_body_z": -4.631683826446533,
        "odometry_vel_body_x": 0.19102612137794495,
        "odometry_vel_body_y": 0.12986843287944794,
        "odometry_vel_body_z": 0.007755454629659653,
        "odometry_ang_vel_body_roll": 1.1047497987747192,
        "odometry_ang_vel_body_pitch": 0.14826123416423798,
        "odometry_ang_vel_body_yaw": 0.4362659752368927,
        "velocity_north_m_s": 0.14999999105930328,
        "velocity_east_m_s": -0.14999999105930328,
        "velocity_down_m_s": 0,
        "acceleration_forward": 0.7816007733345032,
        "acceleration_right": -0.5364863872528076,
        "acceleration_down": -13.16863250732422,
        "battery_id": 0,
        "battery_voltage": 13.170001029968262,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 5294,
        "udp_total": 120,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 91
      },
      {
        "id": 182,
        "datetime": "21/02/2023 02:04:25",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4780b80>, <mavsdk.mission_raw.MissionItem object at 0xa477b958>, <mavsdk.mission_raw.MissionItem object at 0xa477b3e8>, <mavsdk.mission_raw.MissionItem object at 0xa477b700>]",
        "mission_pause": 0,
        "time_usec": 110299276,
        "odometry_pos_body": "PositionBody: [x_m: 4.5272932052612305, y_m: 1.8657323122024536, z_m: -4.626389503479004]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 110304269,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5299999713897705,
        "current_climb_rate": -0.04452461004257202,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306505,
        "gps_raw_lon_deg": 38.8900504,
        "gps_raw_abs_alt": 82.29800415039062,
        "gps_raw_velocity": 0.17000000178813934,
        "position_lat_deg": 47.3065052,
        "position_lon_deg": 38.8900503,
        "position_abs_alt": 83.80400085449219,
        "position_real_alt": 5.058000087738037,
        "heading_deg": 288.49,
        "odometry_pos_body_x": 4.539745807647705,
        "odometry_pos_body_y": 1.855804443359375,
        "odometry_pos_body_z": -4.620712757110596,
        "odometry_vel_body_x": 0.14791899919509888,
        "odometry_vel_body_y": 0.1606462001800537,
        "odometry_vel_body_z": 0.0891471803188324,
        "odometry_ang_vel_body_roll": -0.21468332409858704,
        "odometry_ang_vel_body_pitch": 0.05187495052814483,
        "odometry_ang_vel_body_yaw": 0.41314640641212463,
        "velocity_north_m_s": 0.19999998807907104,
        "velocity_east_m_s": -0.09999999403953552,
        "velocity_down_m_s": 0.07999999821186066,
        "acceleration_forward": 1.3831535577774048,
        "acceleration_right": 3.8058674335479736,
        "acceleration_down": -8.144354820251465,
        "battery_id": 0,
        "battery_voltage": 13.170001029968262,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 5317,
        "udp_total": 120,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 91
      },
      {
        "id": 183,
        "datetime": "21/02/2023 02:04:25",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa58fcbe0>, <mavsdk.mission_raw.MissionItem object at 0xa58fc100>, <mavsdk.mission_raw.MissionItem object at 0xa476cfd0>, <mavsdk.mission_raw.MissionItem object at 0xa477bfa0>]",
        "mission_pause": 0,
        "time_usec": 110559038,
        "odometry_pos_body": "PositionBody: [x_m: 4.566736698150635, y_m: 1.8419036865234375, z_m: -4.604132175445557]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 110643965,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5600000023841858,
        "current_climb_rate": -0.11798219382762908,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065054,
        "gps_raw_lon_deg": 38.8900499,
        "gps_raw_abs_alt": 82.26900482177734,
        "gps_raw_velocity": 0.19999998807907104,
        "position_lat_deg": 47.306505699999995,
        "position_lon_deg": 38.890049999999995,
        "position_abs_alt": 83.77300262451172,
        "position_real_alt": 5.026000022888184,
        "heading_deg": 296.03000000000003,
        "odometry_pos_body_x": 4.5928754806518555,
        "odometry_pos_body_y": 1.8336848020553589,
        "odometry_pos_body_z": -4.59038782119751,
        "odometry_vel_body_x": 0.1338377147912979,
        "odometry_vel_body_y": 0.17629261314868927,
        "odometry_vel_body_z": 0.11904875934123992,
        "odometry_ang_vel_body_roll": -1.116236686706543,
        "odometry_ang_vel_body_pitch": -0.0995507836341858,
        "odometry_ang_vel_body_yaw": 0.4075581133365631,
        "velocity_north_m_s": 0.19999998807907104,
        "velocity_east_m_s": -0.029999999329447743,
        "velocity_down_m_s": 0.12999999523162842,
        "acceleration_forward": 0.15960994362831116,
        "acceleration_right": 1.423486351966858,
        "acceleration_down": -16.429039001464844,
        "battery_id": 0,
        "battery_voltage": 13.170001029968262,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 5341,
        "udp_total": 120,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 91
      },
      {
        "id": 184,
        "datetime": "21/02/2023 02:04:25",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4781c40>, <mavsdk.mission_raw.MissionItem object at 0xa4781220>, <mavsdk.mission_raw.MissionItem object at 0xa4781e68>, <mavsdk.mission_raw.MissionItem object at 0xa47814d8>]",
        "mission_pause": 0,
        "time_usec": 110908721,
        "odometry_pos_body": "PositionBody: [x_m: 4.6316304206848145, y_m: 1.8273874521255493, z_m: -4.577065944671631]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 110968697,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5600000023841858,
        "current_climb_rate": -0.1087413653731346,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306506299999995,
        "gps_raw_lon_deg": 38.8900499,
        "gps_raw_abs_alt": 82.24000549316406,
        "gps_raw_velocity": 0.2299999892711639,
        "position_lat_deg": 47.3065062,
        "position_lon_deg": 38.8900499,
        "position_abs_alt": 83.7550048828125,
        "position_real_alt": 5.008000373840332,
        "heading_deg": 303.82,
        "odometry_pos_body_x": 4.648655891418457,
        "odometry_pos_body_y": 1.82725191116333,
        "odometry_pos_body_z": -4.572393894195557,
        "odometry_vel_body_x": 0.09302936494350432,
        "odometry_vel_body_y": 0.168210968375206,
        "odometry_vel_body_z": 0.11107636243104936,
        "odometry_ang_vel_body_roll": -0.5251500606536865,
        "odometry_ang_vel_body_pitch": 0.11049380898475648,
        "odometry_ang_vel_body_yaw": 0.4876577854156494,
        "velocity_north_m_s": 0.17999999225139618,
        "velocity_east_m_s": 0,
        "velocity_down_m_s": 0.10999999940395357,
        "acceleration_forward": -1.0273802280426023,
        "acceleration_right": -0.7319947481155396,
        "acceleration_down": -13.56163215637207,
        "battery_id": 0,
        "battery_voltage": 13.170001029968262,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 14,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 5357,
        "udp_total": 120,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 91
      },
      {
        "id": 185,
        "datetime": "21/02/2023 02:04:25",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa5910da8>, <mavsdk.mission_raw.MissionItem object at 0xa4781880>, <mavsdk.mission_raw.MissionItem object at 0xa4781490>, <mavsdk.mission_raw.MissionItem object at 0xa4777610>]",
        "mission_pause": 0,
        "time_usec": 111158494,
        "odometry_pos_body": "PositionBody: [x_m: 4.682765483856201, y_m: 1.8314197063446045, z_m: -4.561570167541504]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 111203454,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5600000023841858,
        "current_climb_rate": -0.075627900660038,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065066,
        "gps_raw_lon_deg": 38.890049999999995,
        "gps_raw_abs_alt": 82.22500610351562,
        "gps_raw_velocity": 0.17999999225139618,
        "position_lat_deg": 47.3065065,
        "position_lon_deg": 38.890049999999995,
        "position_abs_alt": 83.74200439453125,
        "position_real_alt": 4.996000289916992,
        "heading_deg": 309.67,
        "odometry_pos_body_x": 4.695549488067627,
        "odometry_pos_body_y": 1.8351068496704104,
        "odometry_pos_body_z": -4.558785438537598,
        "odometry_vel_body_x": 0.0619385689496994,
        "odometry_vel_body_y": 0.16285333037376404,
        "odometry_vel_body_z": 0.030487043783068657,
        "odometry_ang_vel_body_roll": 0.782553493976593,
        "odometry_ang_vel_body_pitch": 0.23553702235221863,
        "odometry_ang_vel_body_yaw": 0.47975534200668335,
        "velocity_north_m_s": 0.1599999964237213,
        "velocity_east_m_s": 0.04999999701976776,
        "velocity_down_m_s": 0.03999999910593033,
        "acceleration_forward": 0.16509635746479034,
        "acceleration_right": 1.8958673477172852,
        "acceleration_down": -10.7440185546875,
        "battery_id": 0,
        "battery_voltage": 13.137001037597656,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 3,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 5364,
        "udp_total": 121,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 92
      },
      {
        "id": 186,
        "datetime": "21/02/2023 02:04:26",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4777418>, <mavsdk.mission_raw.MissionItem object at 0xa4777af0>, <mavsdk.mission_raw.MissionItem object at 0xa4777700>, <mavsdk.mission_raw.MissionItem object at 0xa477d340>]",
        "mission_pause": 0,
        "time_usec": 111388285,
        "odometry_pos_body": "PositionBody: [x_m: 4.7219767570495605, y_m: 1.8499044179916382, z_m: -4.55272912979126]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 111463218,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5600000023841858,
        "current_climb_rate": -0.04240378364920616,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065071,
        "gps_raw_lon_deg": 38.890050599999995,
        "gps_raw_abs_alt": 82.19000244140625,
        "gps_raw_velocity": 0.1899999976158142,
        "position_lat_deg": 47.306507,
        "position_lon_deg": 38.8900504,
        "position_abs_alt": 83.73100280761719,
        "position_real_alt": 4.985000133514404,
        "heading_deg": 317.45,
        "odometry_pos_body_x": 4.741729259490967,
        "odometry_pos_body_y": 1.866657018661499,
        "odometry_pos_body_z": -4.548741817474365,
        "odometry_vel_body_x": 0.01846289448440075,
        "odometry_vel_body_y": 0.17365506291389465,
        "odometry_vel_body_z": 0.05421540886163711,
        "odometry_ang_vel_body_roll": 0.2456589937210083,
        "odometry_ang_vel_body_pitch": -0.18653109669685364,
        "odometry_ang_vel_body_yaw": 0.40155142545700073,
        "velocity_north_m_s": 0.119999997317791,
        "velocity_east_m_s": 0.10999999940395357,
        "velocity_down_m_s": 0.04999999701976776,
        "acceleration_forward": 0.8954460620880127,
        "acceleration_right": -2.336076498031616,
        "acceleration_down": -11.98850440979004,
        "battery_id": 0,
        "battery_voltage": 13.137001037597656,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 5389,
        "udp_total": 121,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 92
      },
      {
        "id": 187,
        "datetime": "21/02/2023 02:04:26",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4778730>, <mavsdk.mission_raw.MissionItem object at 0xa4778bc8>, <mavsdk.mission_raw.MissionItem object at 0xa5913388>, <mavsdk.mission_raw.MissionItem object at 0xa5913b50>]",
        "mission_pause": 0,
        "time_usec": 111698004,
        "odometry_pos_body": "PositionBody: [x_m: 4.7641801834106445, y_m: 1.8941484689712524, z_m: -4.552427291870117]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 111747960,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5399999618530273,
        "current_climb_rate": 0.016297386959195137,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065073,
        "gps_raw_lon_deg": 38.890051,
        "gps_raw_abs_alt": 82.18400573730469,
        "gps_raw_velocity": 0.1599999964237213,
        "position_lat_deg": 47.3065073,
        "position_lon_deg": 38.890051,
        "position_abs_alt": 83.74400329589844,
        "position_real_alt": 4.998000144958496,
        "heading_deg": 323.52,
        "odometry_pos_body_x": 4.775649070739746,
        "odometry_pos_body_y": 1.916426420211792,
        "odometry_pos_body_z": -4.571572780609131,
        "odometry_vel_body_x": -0.029984692111611366,
        "odometry_vel_body_y": 0.1593286246061325,
        "odometry_vel_body_z": 0.0006396202952601016,
        "odometry_ang_vel_body_roll": -0.08400151133537292,
        "odometry_ang_vel_body_pitch": 0.22562459111213684,
        "odometry_ang_vel_body_yaw": 0.2742626667022705,
        "velocity_north_m_s": 0.07000000029802322,
        "velocity_east_m_s": 0.14999999105930328,
        "velocity_down_m_s": 0,
        "acceleration_forward": -1.1677730083465576,
        "acceleration_right": -2.4420807361602783,
        "acceleration_down": -7.0051164627075195,
        "battery_id": 0,
        "battery_voltage": 13.137001037597656,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 5411,
        "udp_total": 121,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 92
      },
      {
        "id": 188,
        "datetime": "21/02/2023 02:04:26",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477b628>, <mavsdk.mission_raw.MissionItem object at 0xa477b520>, <mavsdk.mission_raw.MissionItem object at 0xa477b0d0>, <mavsdk.mission_raw.MissionItem object at 0xa477b5c8>]",
        "mission_pause": 0,
        "time_usec": 112032702,
        "odometry_pos_body": "PositionBody: [x_m: 4.7897748947143555, y_m: 1.9575958251953125, z_m: -4.587597370147705]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 112102638,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.550000011920929,
        "current_climb_rate": -0.044534169137477875,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065073,
        "gps_raw_lon_deg": 38.8900521,
        "gps_raw_abs_alt": 82.16200256347656,
        "gps_raw_velocity": 0.17999999225139618,
        "position_lat_deg": 47.306507499999995,
        "position_lon_deg": 38.8900519,
        "position_abs_alt": 83.7820053100586,
        "position_real_alt": 5.035000324249268,
        "heading_deg": 330.01,
        "odometry_pos_body_x": 4.793859004974365,
        "odometry_pos_body_y": 1.993686318397522,
        "odometry_pos_body_z": -4.6041460037231445,
        "odometry_vel_body_x": -0.06531684100627899,
        "odometry_vel_body_y": 0.18118402361869812,
        "odometry_vel_body_z": 0.03043161891400814,
        "odometry_ang_vel_body_roll": -0.2613929510116577,
        "odometry_ang_vel_body_pitch": -0.011110574007034302,
        "odometry_ang_vel_body_yaw": 0.23219096660614016,
        "velocity_north_m_s": 0.029999999329447743,
        "velocity_east_m_s": 0.1899999976158142,
        "velocity_down_m_s": 0.029999999329447743,
        "acceleration_forward": 1.4270634651184082,
        "acceleration_right": -1.9457676410675049,
        "acceleration_down": -8.370293617248535,
        "battery_id": 0,
        "battery_voltage": 13.137001037597656,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 3,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 5436,
        "udp_total": 122,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 93
      },
      {
        "id": 189,
        "datetime": "21/02/2023 02:04:27",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477a9e8>, <mavsdk.mission_raw.MissionItem object at 0xa4777040>, <mavsdk.mission_raw.MissionItem object at 0xa477b040>, <mavsdk.mission_raw.MissionItem object at 0xa477b958>]",
        "mission_pause": 0,
        "time_usec": 112322437,
        "odometry_pos_body": "PositionBody: [x_m: 4.795988082885742, y_m: 2.0240907669067383, z_m: -4.6103386878967285]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 112377388,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.550000011920929,
        "current_climb_rate": -0.05207668617367744,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065074,
        "gps_raw_lon_deg": 38.8900527,
        "gps_raw_abs_alt": 82.15400695800781,
        "gps_raw_velocity": 0.2199999988079071,
        "position_lat_deg": 47.306507499999995,
        "position_lon_deg": 38.8900528,
        "position_abs_alt": 83.79300689697266,
        "position_real_alt": 5.047000408172607,
        "heading_deg": 334.83,
        "odometry_pos_body_x": 4.800661087036133,
        "odometry_pos_body_y": 2.0512256622314453,
        "odometry_pos_body_z": -4.6106343269348145,
        "odometry_vel_body_x": -0.030322259292006493,
        "odometry_vel_body_y": 0.2166292369365692,
        "odometry_vel_body_z": 0.09169945120811462,
        "odometry_ang_vel_body_roll": -1.071849346160889,
        "odometry_ang_vel_body_pitch": -0.1044679954648018,
        "odometry_ang_vel_body_yaw": 0.34734025597572327,
        "velocity_north_m_s": 0.04999999701976776,
        "velocity_east_m_s": 0.19999998807907104,
        "velocity_down_m_s": 0.07999999821186066,
        "acceleration_forward": 0.23961293697357175,
        "acceleration_right": -3.213538408279419,
        "acceleration_down": -2.516324996948242,
        "battery_id": 0,
        "battery_voltage": 13.137001037597656,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 23,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 5458,
        "udp_total": 122,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 93
      },
      {
        "id": 190,
        "datetime": "21/02/2023 02:04:27",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477cf10>, <mavsdk.mission_raw.MissionItem object at 0xa477cc70>, <mavsdk.mission_raw.MissionItem object at 0xa477d970>, <mavsdk.mission_raw.MissionItem object at 0xa5913430>]",
        "mission_pause": 0,
        "time_usec": 112632157,
        "odometry_pos_body": "PositionBody: [x_m: 4.810708999633789, y_m: 2.1013925075531006, z_m: -4.615077018737793]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.4]",
        "timestamp": 112662133,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.550000011920929,
        "current_climb_rate": -0.06393551826477051,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065074,
        "gps_raw_lon_deg": 38.8900542,
        "gps_raw_abs_alt": 82.11600494384766,
        "gps_raw_velocity": 0.26999998092651367,
        "position_lat_deg": 47.3065077,
        "position_lon_deg": 38.8900538,
        "position_abs_alt": 83.8010025024414,
        "position_real_alt": 5.055000305175781,
        "heading_deg": 342,
        "odometry_pos_body_x": 4.818267345428467,
        "odometry_pos_body_y": 2.1238226890563965,
        "odometry_pos_body_z": -4.61882209777832,
        "odometry_vel_body_x": 0.06882593780755997,
        "odometry_vel_body_y": 0.19109196960926056,
        "odometry_vel_body_z": 0.07087496668100357,
        "odometry_ang_vel_body_roll": -0.7833737730979919,
        "odometry_ang_vel_body_pitch": 0.15881609916687012,
        "odometry_ang_vel_body_yaw": 0.2813045382499695,
        "velocity_north_m_s": 0.12999999523162842,
        "velocity_east_m_s": 0.14000000059604645,
        "velocity_down_m_s": 0.07999999821186066,
        "acceleration_forward": -0.16025185585021973,
        "acceleration_right": -6.792705059051514,
        "acceleration_down": -10.71707820892334,
        "battery_id": 0,
        "battery_voltage": 13.137001037597656,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 28,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 5464,
        "udp_total": 122,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 93
      },
      {
        "id": 191,
        "datetime": "21/02/2023 02:04:27",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4777af0>, <mavsdk.mission_raw.MissionItem object at 0xa4777610>, <mavsdk.mission_raw.MissionItem object at 0xa58fc3b8>, <mavsdk.mission_raw.MissionItem object at 0xa477c640>]",
        "mission_pause": 0,
        "time_usec": 112921897,
        "odometry_pos_body": "PositionBody: [x_m: 4.840639114379883, y_m: 2.162363290786743, z_m: -4.62654972076416]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.12]",
        "timestamp": 112986835,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.550000011920929,
        "current_climb_rate": -0.06301940232515335,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306507499999995,
        "gps_raw_lon_deg": 38.8900547,
        "gps_raw_abs_alt": 82.10000610351562,
        "gps_raw_velocity": 0.1899999976158142,
        "position_lat_deg": 47.306508,
        "position_lon_deg": 38.8900545,
        "position_abs_alt": 83.81400299072266,
        "position_real_alt": 5.068000316619873,
        "heading_deg": 348.11,
        "odometry_pos_body_x": 4.855579376220703,
        "odometry_pos_body_y": 2.1749789714813232,
        "odometry_pos_body_z": -4.630866527557373,
        "odometry_vel_body_x": 0.20978549122810364,
        "odometry_vel_body_y": 0.0901540219783783,
        "odometry_vel_body_z": 0.036054469645023346,
        "odometry_ang_vel_body_roll": 0.37486669421195984,
        "odometry_ang_vel_body_pitch": 0.542195737361908,
        "odometry_ang_vel_body_yaw": 0.31834104657173157,
        "velocity_north_m_s": 0.2199999988079071,
        "velocity_east_m_s": 0.029999999329447743,
        "velocity_down_m_s": 0.029999999329447743,
        "acceleration_forward": 0.841370165348053,
        "acceleration_right": -0.6118138432502747,
        "acceleration_down": -10.551651000976562,
        "battery_id": 0,
        "battery_voltage": 13.137001037597656,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 5482,
        "udp_total": 122,
        "arp_total": 4,
        "icmp_total": 1,
        "other_total": 93
      },
      {
        "id": 192,
        "datetime": "21/02/2023 02:04:27",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477bcd0>, <mavsdk.mission_raw.MissionItem object at 0xa477b268>, <mavsdk.mission_raw.MissionItem object at 0xa477b730>, <mavsdk.mission_raw.MissionItem object at 0xa477be08>]",
        "mission_pause": 0,
        "time_usec": 113161677,
        "odometry_pos_body": "PositionBody: [x_m: 4.889808177947998, y_m: 2.1863365173339844, z_m: -4.640628814697266]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.12]",
        "timestamp": 113226620,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5399999618530273,
        "current_climb_rate": -0.06117568165063858,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306508099999995,
        "gps_raw_lon_deg": 38.8900554,
        "gps_raw_abs_alt": 82.0530014038086,
        "gps_raw_velocity": 0.19999998807907104,
        "position_lat_deg": 47.3065085,
        "position_lon_deg": 38.8900547,
        "position_abs_alt": 83.8280029296875,
        "position_real_alt": 5.082000255584717,
        "heading_deg": 350.01,
        "odometry_pos_body_x": 4.920601844787598,
        "odometry_pos_body_y": 2.186563014984131,
        "odometry_pos_body_z": -4.650450706481934,
        "odometry_vel_body_x": 0.3047853112220764,
        "odometry_vel_body_y": -0.04629112407565117,
        "odometry_vel_body_z": 0.04578427970409393,
        "odometry_ang_vel_body_roll": 0.22458843886852264,
        "odometry_ang_vel_body_pitch": 0.01463976874947548,
        "odometry_ang_vel_body_yaw": 0.066288523375988,
        "velocity_north_m_s": 0.28999999165534973,
        "velocity_east_m_s": -0.05999999865889549,
        "velocity_down_m_s": 0.04999999701976776,
        "acceleration_forward": 0.3511658012866974,
        "acceleration_right": 0.414781391620636,
        "acceleration_down": -6.431020259857178,
        "battery_id": 0,
        "battery_voltage": 13.121999740600586,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 1,
        "udp": 0,
        "arp": 2,
        "icmp": 0,
        "other": 0,
        "tcp_total": 5505,
        "udp_total": 123,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 94
      },
      {
        "id": 193,
        "datetime": "21/02/2023 02:04:28",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477a130>, <mavsdk.mission_raw.MissionItem object at 0xa4782fb8>, <mavsdk.mission_raw.MissionItem object at 0xa4782d00>, <mavsdk.mission_raw.MissionItem object at 0xa4782568>]",
        "mission_pause": 0,
        "time_usec": 113531342,
        "odometry_pos_body": "PositionBody: [x_m: 4.975259304046631, y_m: 2.1785285472869873, z_m: -4.669222354888916]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.12]",
        "timestamp": 113461409,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5399999618530273,
        "current_climb_rate": -0.046043314039707184,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065091,
        "gps_raw_lon_deg": 38.8900553,
        "gps_raw_abs_alt": 82.0260009765625,
        "gps_raw_velocity": 0.3100000023841858,
        "position_lat_deg": 47.306509,
        "position_lon_deg": 38.8900546,
        "position_abs_alt": 83.84800720214844,
        "position_real_alt": 5.1020002365112305,
        "heading_deg": 350.97,
        "odometry_pos_body_x": 5.0169878005981445,
        "odometry_pos_body_y": 2.1668453216552734,
        "odometry_pos_body_z": -4.671755313873291,
        "odometry_vel_body_x": 0.35436776280403137,
        "odometry_vel_body_y": -0.14698544144630432,
        "odometry_vel_body_z": 0.06748700886964798,
        "odometry_ang_vel_body_roll": 0.19987982511520383,
        "odometry_ang_vel_body_pitch": 0.1710904836654663,
        "odometry_ang_vel_body_yaw": 0.3418450653553009,
        "velocity_north_m_s": 0.32999998331069946,
        "velocity_east_m_s": -0.12999999523162842,
        "velocity_down_m_s": 0.04999999701976776,
        "acceleration_forward": 0.5517280697822571,
        "acceleration_right": 3.3646507263183594,
        "acceleration_down": -10.92745590209961,
        "battery_id": 0,
        "battery_voltage": 13.121999740600586,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 25,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 5529,
        "udp_total": 123,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 94
      },
      {
        "id": 194,
        "datetime": "21/02/2023 02:04:28",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4781688>, <mavsdk.mission_raw.MissionItem object at 0xa4781958>, <mavsdk.mission_raw.MissionItem object at 0xa4781d00>, <mavsdk.mission_raw.MissionItem object at 0xa4781928>]",
        "mission_pause": 0,
        "time_usec": 113930981,
        "odometry_pos_body": "PositionBody: [x_m: 5.098454475402832, y_m: 2.1248064041137695, z_m: -4.650179386138916]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.12]",
        "timestamp": 113711179,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.550000011920929,
        "current_climb_rate": -0.0990556925535202,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065096,
        "gps_raw_lon_deg": 38.8900548,
        "gps_raw_abs_alt": 82.0040054321289,
        "gps_raw_velocity": 0.3999999761581421,
        "position_lat_deg": 47.3065097,
        "position_lon_deg": 38.8900543,
        "position_abs_alt": 83.85100555419922,
        "position_real_alt": 5.104000091552734,
        "heading_deg": 354,
        "odometry_pos_body_x": 5.130960464477539,
        "odometry_pos_body_y": 2.09375524520874,
        "odometry_pos_body_z": -4.6384711265563965,
        "odometry_vel_body_x": 0.24909308552742004,
        "odometry_vel_body_y": -0.2730330228805542,
        "odometry_vel_body_z": 0.13772952556610107,
        "odometry_ang_vel_body_roll": 0.11538738012313844,
        "odometry_ang_vel_body_pitch": 0.0868583470582962,
        "odometry_ang_vel_body_yaw": 0.17445529997348783,
        "velocity_north_m_s": 0.32999998331069946,
        "velocity_east_m_s": -0.2199999988079071,
        "velocity_down_m_s": 0.07999999821186066,
        "acceleration_forward": -0.3820200860500336,
        "acceleration_right": -4.441024303436279,
        "acceleration_down": -10.64484977722168,
        "battery_id": 0,
        "battery_voltage": 13.121999740600586,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 47,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 5551,
        "udp_total": 124,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 95
      },
      {
        "id": 195,
        "datetime": "21/02/2023 02:04:29",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477a808>, <mavsdk.mission_raw.MissionItem object at 0xa477a910>, <mavsdk.mission_raw.MissionItem object at 0xa4778cd0>, <mavsdk.mission_raw.MissionItem object at 0xa4778220>]",
        "mission_pause": 0,
        "time_usec": 114160817,
        "odometry_pos_body": "PositionBody: [x_m: 5.152334213256836, y_m: 2.070276975631714, z_m: -4.626226902008057]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 0,
        "alg_manual_y": 0,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 0,
        "alg_manual_y_start": 0,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 0,
        "alg_manual_zero_x": 0,
        "alg_manual_zero_y": 0,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 0,
        "alg_manual_dist_to_point_old": 0,
        "alg_manual_angle_to_point_old": 0,
        "alg_manual_command": "rotate 355.95796494474024",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.12]",
        "timestamp": 113861045,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5600000023841858,
        "current_climb_rate": -0.0945829376578331,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065106,
        "gps_raw_lon_deg": 38.8900537,
        "gps_raw_abs_alt": 81.9330062866211,
        "gps_raw_velocity": 0.3999999761581421,
        "position_lat_deg": 47.30651,
        "position_lon_deg": 38.890054,
        "position_abs_alt": 83.84000396728516,
        "position_real_alt": 5.094000339508057,
        "heading_deg": 355.92,
        "odometry_pos_body_x": 5.170571327209473,
        "odometry_pos_body_y": 2.046557664871216,
        "odometry_pos_body_z": -4.613168239593506,
        "odometry_vel_body_x": 0.1547200083732605,
        "odometry_vel_body_y": -0.29828914999961853,
        "odometry_vel_body_z": 0.12602393329143524,
        "odometry_ang_vel_body_roll": 0.7458692789077759,
        "odometry_ang_vel_body_pitch": -0.2524162232875824,
        "odometry_ang_vel_body_yaw": 0.28357017040252686,
        "velocity_north_m_s": 0.3199999928474426,
        "velocity_east_m_s": -0.2299999892711639,
        "velocity_down_m_s": 0.12999999523162842,
        "acceleration_forward": -0.3549218773841858,
        "acceleration_right": -1.0359408855438232,
        "acceleration_down": -11.764654159545898,
        "battery_id": 0,
        "battery_voltage": 13.121999740600586,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 50,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 5555,
        "udp_total": 124,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 95
      },
      {
        "id": 196,
        "datetime": "21/02/2023 02:04:29",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477b208>, <mavsdk.mission_raw.MissionItem object at 0xa477bb08>, <mavsdk.mission_raw.MissionItem object at 0xa477be80>, <mavsdk.mission_raw.MissionItem object at 0xa477b1c0>]",
        "mission_pause": 0,
        "time_usec": 114390563,
        "odometry_pos_body": "PositionBody: [x_m: 5.196262359619141, y_m: 2.013033390045166, z_m: -4.591695308685303]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 19,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "off",
        "alg_manual_inputs": "[0.8, 0.0, 0.5, 0.0]",
        "timestamp": 114040940,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5899999737739563,
        "current_climb_rate": -0.1368832290172577,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065108,
        "gps_raw_lon_deg": 38.890053,
        "gps_raw_abs_alt": 81.89700317382812,
        "gps_raw_velocity": 0.3499999940395355,
        "position_lat_deg": 47.3065106,
        "position_lon_deg": 38.8900532,
        "position_abs_alt": 83.81600189208984,
        "position_real_alt": 5.069000244140625,
        "heading_deg": 359.47,
        "odometry_pos_body_x": 5.248406410217285,
        "odometry_pos_body_y": 1.9451329708099363,
        "odometry_pos_body_z": -4.5601091384887695,
        "odometry_vel_body_x": 0.16419750452041626,
        "odometry_vel_body_y": -0.28988564014434814,
        "odometry_vel_body_z": 0.15465779602527618,
        "odometry_ang_vel_body_roll": -0.24919307231903076,
        "odometry_ang_vel_body_pitch": -0.055531617254018784,
        "odometry_ang_vel_body_yaw": 0.155186727643013,
        "velocity_north_m_s": 0.2299999892711639,
        "velocity_east_m_s": -0.28999999165534973,
        "velocity_down_m_s": 0.10999999940395357,
        "acceleration_forward": -0.5699273347854614,
        "acceleration_right": -4.233300685882568,
        "acceleration_down": -4.2987847328186035,
        "battery_id": 0,
        "battery_voltage": 13.121999740600586,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 5578,
        "udp_total": 124,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 95
      },
      {
        "id": 197,
        "datetime": "21/02/2023 02:04:29",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4777d18>, <mavsdk.mission_raw.MissionItem object at 0xa4777af0>, <mavsdk.mission_raw.MissionItem object at 0xa477db98>, <mavsdk.mission_raw.MissionItem object at 0xa477dd78>]",
        "mission_pause": 0,
        "time_usec": 114920085,
        "odometry_pos_body": "PositionBody: [x_m: 5.328464984893799, y_m: 1.8722925186157227, z_m: -4.555840015411377]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 19,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "off",
        "alg_manual_inputs": "[0.8, 0.0, 0.5, 0.02]",
        "timestamp": 114355603,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5899999737739563,
        "current_climb_rate": -0.05701543763279915,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065114,
        "gps_raw_lon_deg": 38.8900514,
        "gps_raw_abs_alt": 81.79800415039062,
        "gps_raw_velocity": 0.3400000035762787,
        "position_lat_deg": 47.306511099999994,
        "position_lon_deg": 38.8900523,
        "position_abs_alt": 83.77000427246094,
        "position_real_alt": 5.019000053405762,
        "heading_deg": 3.06,
        "odometry_pos_body_x": 5.386880397796631,
        "odometry_pos_body_y": 1.843929648399353,
        "odometry_pos_body_z": -4.570338726043701,
        "odometry_vel_body_x": 0.6020264029502869,
        "odometry_vel_body_y": -0.3257197439670563,
        "odometry_vel_body_z": -0.050039760768413544,
        "odometry_ang_vel_body_roll": -0.06543365865945816,
        "odometry_ang_vel_body_pitch": -0.1908647418022156,
        "odometry_ang_vel_body_yaw": 0.16360801458358765,
        "velocity_north_m_s": 0.14999999105930328,
        "velocity_east_m_s": -0.29999998211860657,
        "velocity_down_m_s": 0.12999999523162842,
        "acceleration_forward": -0.5810897946357727,
        "acceleration_right": 1.7674533128738403,
        "acceleration_down": -10.674038887023926,
        "battery_id": 0,
        "battery_voltage": 13.083000183105469,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 11,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 5610,
        "udp_total": 125,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 96
      },
      {
        "id": 198,
        "datetime": "21/02/2023 02:04:30",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4780460>, <mavsdk.mission_raw.MissionItem object at 0xa47805e0>, <mavsdk.mission_raw.MissionItem object at 0xa4780898>, <mavsdk.mission_raw.MissionItem object at 0xa47801d8>]",
        "mission_pause": 0,
        "time_usec": 115319725,
        "odometry_pos_body": "PositionBody: [x_m: 5.654830455780029, y_m: 1.7943063974380493, z_m: -4.609157562255859]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 19,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "off",
        "alg_manual_inputs": "[0.8, 0.025, 0.5, 0.0]",
        "timestamp": 114670311,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5699999928474426,
        "current_climb_rate": -0.017193831503391266,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065137,
        "gps_raw_lon_deg": 38.8900503,
        "gps_raw_abs_alt": 81.72300720214844,
        "gps_raw_velocity": 0.85999995470047,
        "position_lat_deg": 47.306511699999994,
        "position_lon_deg": 38.890051199999995,
        "position_abs_alt": 83.73900604248047,
        "position_real_alt": 4.992000102996826,
        "heading_deg": 6.2700000000000005,
        "odometry_pos_body_x": 5.79532527923584,
        "odometry_pos_body_y": 1.7848106622695925,
        "odometry_pos_body_z": -4.631466388702393,
        "odometry_vel_body_x": 1.5210026502609253,
        "odometry_vel_body_y": -0.37480154633522034,
        "odometry_vel_body_z": -0.205396831035614,
        "odometry_ang_vel_body_roll": -0.7510747313499451,
        "odometry_ang_vel_body_pitch": 0.06985601782798767,
        "odometry_ang_vel_body_yaw": -0.037469178438186646,
        "velocity_north_m_s": 0.2299999892711639,
        "velocity_east_m_s": -0.23999999463558197,
        "velocity_down_m_s": 0.10999999940395357,
        "acceleration_forward": -0.09139366447925568,
        "acceleration_right": -4.849057674407959,
        "acceleration_down": -14.133431434631348,
        "battery_id": 0,
        "battery_voltage": 13.083000183105469,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 22,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 5643,
        "udp_total": 125,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 96
      },
      {
        "id": 199,
        "datetime": "21/02/2023 02:04:30",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4782070>, <mavsdk.mission_raw.MissionItem object at 0xa4782e08>, <mavsdk.mission_raw.MissionItem object at 0xa47822c8>, <mavsdk.mission_raw.MissionItem object at 0xa4782100>]",
        "mission_pause": 0,
        "time_usec": 115679399,
        "odometry_pos_body": "PositionBody: [x_m: 6.151360511779785, y_m: 1.7803397178649902, z_m: -4.642734050750732]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 19,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "off",
        "alg_manual_inputs": "[0.8, 0.025, 0.5, 0.0]",
        "timestamp": 115034985,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5799999833106995,
        "current_climb_rate": -0.08863744884729385,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065186,
        "gps_raw_lon_deg": 38.8900501,
        "gps_raw_abs_alt": 81.67500305175781,
        "gps_raw_velocity": 1.6200000047683716,
        "position_lat_deg": 47.3065132,
        "position_lon_deg": 38.890049999999995,
        "position_abs_alt": 83.75700378417969,
        "position_real_alt": 5.015000343322754,
        "heading_deg": 9.5,
        "odometry_pos_body_x": 6.389250755310059,
        "odometry_pos_body_y": 1.7814910411834717,
        "odometry_pos_body_z": -4.651618480682373,
        "odometry_vel_body_x": 2.012622117996216,
        "odometry_vel_body_y": -0.3120579123497009,
        "odometry_vel_body_z": -0.2574799656867981,
        "odometry_ang_vel_body_roll": -0.3019566833972931,
        "odometry_ang_vel_body_pitch": -0.03235346078872681,
        "odometry_ang_vel_body_yaw": -0.08580488711595535,
        "velocity_north_m_s": 0.7699999809265137,
        "velocity_east_m_s": -0.17999999225139618,
        "velocity_down_m_s": 0.04999999701976776,
        "acceleration_forward": 0.1619226634502411,
        "acceleration_right": 0.9328832626342772,
        "acceleration_down": -13.82862377166748,
        "battery_id": 0,
        "battery_voltage": 13.083000183105469,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 24,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 5644,
        "udp_total": 125,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 96
      },
      {
        "id": 200,
        "datetime": "21/02/2023 02:04:30",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4775370>, <mavsdk.mission_raw.MissionItem object at 0xa477b700>, <mavsdk.mission_raw.MissionItem object at 0xa477b880>, <mavsdk.mission_raw.MissionItem object at 0xa4781880>]",
        "mission_pause": 0,
        "time_usec": 115979127,
        "odometry_pos_body": "PositionBody: [x_m: 6.737135887145996, y_m: 1.7913457155227661, z_m: -4.670251846313477]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 19,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "off",
        "alg_manual_inputs": "[0.8, 0.0, 0.5, 0.02]",
        "timestamp": 115304739,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5699999928474426,
        "current_climb_rate": -0.06065043807029724,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306522,
        "gps_raw_lon_deg": 38.8900502,
        "gps_raw_abs_alt": 81.62600708007812,
        "gps_raw_velocity": 1.8899999856948853,
        "position_lat_deg": 47.306515,
        "position_lon_deg": 38.8900495,
        "position_abs_alt": 83.78900146484375,
        "position_real_alt": 5.043000221252441,
        "heading_deg": 11.16,
        "odometry_pos_body_x": 6.959841728210449,
        "odometry_pos_body_y": 1.80179226398468,
        "odometry_pos_body_z": -4.680800437927246,
        "odometry_vel_body_x": 2.3542001247406006,
        "odometry_vel_body_y": -0.23065699636936188,
        "odometry_vel_body_z": -0.2915743887424469,
        "odometry_ang_vel_body_roll": 0.466508686542511,
        "odometry_ang_vel_body_pitch": -0.16760648787021637,
        "odometry_ang_vel_body_yaw": -0.042619217187166214,
        "velocity_north_m_s": 1.25,
        "velocity_east_m_s": -0.08999999612569809,
        "velocity_down_m_s": 0.019999999552965164,
        "acceleration_forward": -0.6271315217018127,
        "acceleration_right": -3.600613832473755,
        "acceleration_down": -6.490604400634766,
        "battery_id": 0,
        "battery_voltage": 13.083000183105469,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 48,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 5668,
        "udp_total": 125,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 96
      },
      {
        "id": 201,
        "datetime": "21/02/2023 02:04:30",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477c358>, <mavsdk.mission_raw.MissionItem object at 0xa477cd00>, <mavsdk.mission_raw.MissionItem object at 0xa477c2f8>, <mavsdk.mission_raw.MissionItem object at 0xa477b178>]",
        "mission_pause": 0,
        "time_usec": 116188940,
        "odometry_pos_body": "PositionBody: [x_m: 7.221395015716553, y_m: 1.8243030309677124, z_m: -4.688870906829834]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 19,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "off",
        "alg_manual_inputs": "[0.8, 0.0, 0.5, 0.02]",
        "timestamp": 115549515,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5600000023841858,
        "current_climb_rate": -0.051621150225400925,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065304,
        "gps_raw_lon_deg": 38.890051199999995,
        "gps_raw_abs_alt": 81.57200622558594,
        "gps_raw_velocity": 2.4700000286102295,
        "position_lat_deg": 47.3065185,
        "position_lon_deg": 38.8900493,
        "position_abs_alt": 83.82100677490234,
        "position_real_alt": 5.075000286102295,
        "heading_deg": 10.8,
        "odometry_pos_body_x": 7.6280388832092285,
        "odometry_pos_body_y": 1.8678406476974487,
        "odometry_pos_body_z": -4.696776866912842,
        "odometry_vel_body_x": 2.6720845699310303,
        "odometry_vel_body_y": -0.04280879721045494,
        "odometry_vel_body_z": -0.25204503536224365,
        "odometry_ang_vel_body_roll": 0.5767355561256409,
        "odometry_ang_vel_body_pitch": 0.1600978821516037,
        "odometry_ang_vel_body_yaw": -0.14844360947608948,
        "velocity_north_m_s": 1.7799999713897705,
        "velocity_east_m_s": 0,
        "velocity_down_m_s": 0.07000000029802322,
        "acceleration_forward": 0.340442031621933,
        "acceleration_right": -2.4906129837036133,
        "acceleration_down": -9.601072311401367,
        "battery_id": 0,
        "battery_voltage": 13.083000183105469,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 5690,
        "udp_total": 126,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 97
      },
      {
        "id": 202,
        "datetime": "21/02/2023 02:04:31",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477dfb8>, <mavsdk.mission_raw.MissionItem object at 0xa477da78>, <mavsdk.mission_raw.MissionItem object at 0xa477da30>, <mavsdk.mission_raw.MissionItem object at 0xa477dd18>]",
        "mission_pause": 0,
        "time_usec": 116478750,
        "odometry_pos_body": "PositionBody: [x_m: 7.984678745269775, y_m: 1.9145593643188477, z_m: -4.697335243225098]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 19,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "off",
        "alg_manual_inputs": "[0.8, 0.0, 0.5, 0.02]",
        "timestamp": 115844249,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5699999928474426,
        "current_climb_rate": -0.08965499699115753,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065351,
        "gps_raw_lon_deg": 38.890051799999995,
        "gps_raw_abs_alt": 81.54600524902344,
        "gps_raw_velocity": 2.6499998569488525,
        "position_lat_deg": 47.3065223,
        "position_lon_deg": 38.8900493,
        "position_abs_alt": 83.83700561523438,
        "position_real_alt": 5.091000080108643,
        "heading_deg": 8.86,
        "odometry_pos_body_x": 8.171669960021973,
        "odometry_pos_body_y": 1.9380393028259275,
        "odometry_pos_body_z": -4.699521064758301,
        "odometry_vel_body_x": 2.9256200790405273,
        "odometry_vel_body_y": 0.06479646265506744,
        "odometry_vel_body_z": -0.48126691579818726,
        "odometry_ang_vel_body_roll": -0.4986293613910675,
        "odometry_ang_vel_body_pitch": -0.4351552128791809,
        "odometry_ang_vel_body_yaw": -0.09695498645305634,
        "velocity_north_m_s": 2.0899999141693115,
        "velocity_east_m_s": 0.03999999910593033,
        "velocity_down_m_s": 0.05999999865889549,
        "acceleration_forward": -0.46120792627334595,
        "acceleration_right": -2.1975600719451904,
        "acceleration_down": -9.946528434753418,
        "battery_id": 0,
        "battery_voltage": 13.083000183105469,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 18,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 5710,
        "udp_total": 126,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 97
      },
      {
        "id": 203,
        "datetime": "21/02/2023 02:04:31",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4775538>, <mavsdk.mission_raw.MissionItem object at 0xa4775490>, <mavsdk.mission_raw.MissionItem object at 0xa47759b8>, <mavsdk.mission_raw.MissionItem object at 0xa4775310>]",
        "mission_pause": 0,
        "time_usec": 116743446,
        "odometry_pos_body": "PositionBody: [x_m: 8.785494804382324, y_m: 2.0115158557891846, z_m: -4.72446346282959]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 19,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "stop",
        "alg_manual_inputs": "[0.8, 0.0, 0.5, 0.0]",
        "timestamp": 116024087,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5399999618530273,
        "current_climb_rate": -0.0333171971142292,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065405,
        "gps_raw_lon_deg": 38.8900528,
        "gps_raw_abs_alt": 81.49400329589844,
        "gps_raw_velocity": 3.0199999809265137,
        "position_lat_deg": 47.3065261,
        "position_lon_deg": 38.8900495,
        "position_abs_alt": 83.86100769042969,
        "position_real_alt": 5.115000247955322,
        "heading_deg": 7.55,
        "odometry_pos_body_x": 9.013586044311523,
        "odometry_pos_body_y": 2.0377306938171387,
        "odometry_pos_body_z": -4.7388811111450195,
        "odometry_vel_body_x": 3.324263572692871,
        "odometry_vel_body_y": 0.08475591987371445,
        "odometry_vel_body_z": -0.4085591733455658,
        "odometry_ang_vel_body_roll": -0.16590522229671478,
        "odometry_ang_vel_body_pitch": 0.06937694549560547,
        "odometry_ang_vel_body_yaw": -0.07968909293413162,
        "velocity_north_m_s": 2.3899998664855957,
        "velocity_east_m_s": 0.119999997317791,
        "velocity_down_m_s": 0.05999999865889549,
        "acceleration_forward": -1.2025171518325806,
        "acceleration_right": -1.935352087020874,
        "acceleration_down": -3.705206871032715,
        "battery_id": 0,
        "battery_voltage": 13.083000183105469,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 5714,
        "udp_total": 126,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 97
      },
      {
        "id": 204,
        "datetime": "21/02/2023 02:04:31",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477a808>, <mavsdk.mission_raw.MissionItem object at 0xa476ca60>, <mavsdk.mission_raw.MissionItem object at 0xa4782f70>, <mavsdk.mission_raw.MissionItem object at 0xa5913df0>]",
        "mission_pause": 0,
        "time_usec": 116983237,
        "odometry_pos_body": "PositionBody: [x_m: 9.588844299316406, y_m: 2.1068644523620605, z_m: -4.758893013000488]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 19,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "stop",
        "alg_manual_inputs": "[0.23190333138064667, 0.0, 0.5, 0.0]",
        "timestamp": 116383765,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5199999809265137,
        "current_climb_rate": -0.04154504835605621,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306552499999995,
        "gps_raw_lon_deg": 38.8900552,
        "gps_raw_abs_alt": 81.44900512695312,
        "gps_raw_velocity": 3.5,
        "position_lat_deg": 47.3065349,
        "position_lon_deg": 38.8900508,
        "position_abs_alt": 83.8790054321289,
        "position_real_alt": 5.133000373840332,
        "heading_deg": 5.47,
        "odometry_pos_body_x": 10.04149341583252,
        "odometry_pos_body_y": 2.1645727157592773,
        "odometry_pos_body_z": -4.775856971740723,
        "odometry_vel_body_x": 3.498459815979004,
        "odometry_vel_body_y": 0.13980868458747864,
        "odometry_vel_body_z": -0.2743447422981262,
        "odometry_ang_vel_body_roll": 0.4353605806827545,
        "odometry_ang_vel_body_pitch": 0.2187885344028473,
        "odometry_ang_vel_body_yaw": -0.016395198181271553,
        "velocity_north_m_s": 2.7799999713897705,
        "velocity_east_m_s": 0.3400000035762787,
        "velocity_down_m_s": 0.07999999821186066,
        "acceleration_forward": 1.4570127725601196,
        "acceleration_right": -2.2416911125183105,
        "acceleration_down": -3.4395124912261963,
        "battery_id": 0,
        "battery_voltage": 13.092000961303713,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 5736,
        "udp_total": 127,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 98
      },
      {
        "id": 205,
        "datetime": "21/02/2023 02:04:32",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa47800e8>, <mavsdk.mission_raw.MissionItem object at 0xa4775d30>, <mavsdk.mission_raw.MissionItem object at 0xa4775e20>, <mavsdk.mission_raw.MissionItem object at 0xa4775928>]",
        "mission_pause": 0,
        "time_usec": 117242985,
        "odometry_pos_body": "PositionBody: [x_m: 10.4989013671875, y_m: 2.228297472000122, z_m: -4.784343242645264]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 19,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "stop",
        "alg_manual_inputs": "[0.23190333138064667, 0.0, 0.5, 0.0]",
        "timestamp": 116678497,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5399999618530273,
        "current_climb_rate": -0.1458093374967575,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065589,
        "gps_raw_lon_deg": 38.8900565,
        "gps_raw_abs_alt": 81.406005859375,
        "gps_raw_velocity": 3.609999895095825,
        "position_lat_deg": 47.3065431,
        "position_lon_deg": 38.8900523,
        "position_abs_alt": 83.9050064086914,
        "position_real_alt": 5.159000396728516,
        "heading_deg": 4.61,
        "odometry_pos_body_x": 11.090060234069824,
        "odometry_pos_body_y": 2.315321445465088,
        "odometry_pos_body_z": -4.781611442565918,
        "odometry_vel_body_x": 3.242881774902344,
        "odometry_vel_body_y": 0.20293954014778137,
        "odometry_vel_body_z": 0.5773070454597473,
        "odometry_ang_vel_body_roll": 0.10356071591377258,
        "odometry_ang_vel_body_pitch": 0.898313045501709,
        "odometry_ang_vel_body_yaw": 0.010102519765496254,
        "velocity_north_m_s": 3.179999828338623,
        "velocity_east_m_s": 0.35999998450279236,
        "velocity_down_m_s": 0.019999999552965164,
        "acceleration_forward": 0.22244590520858765,
        "acceleration_right": 1.24913227558136,
        "acceleration_down": -9.573107719421388,
        "battery_id": 0,
        "battery_voltage": 13.092000961303713,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 5760,
        "udp_total": 127,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 98
      },
      {
        "id": 206,
        "datetime": "21/02/2023 02:04:32",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4781568>, <mavsdk.mission_raw.MissionItem object at 0xa4781430>, <mavsdk.mission_raw.MissionItem object at 0xa4781dc0>, <mavsdk.mission_raw.MissionItem object at 0xa4781868>]",
        "mission_pause": 0,
        "time_usec": 117572690,
        "odometry_pos_body": "PositionBody: [x_m: 11.608642578125, y_m: 2.3994674682617188, z_m: -4.7768635749816895]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 19,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "stop",
        "alg_manual_inputs": "[0.23190333138064667, 0.0, 0.5, 0.0]",
        "timestamp": 116943256,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.550000011920929,
        "current_climb_rate": -0.18184657394886017,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065649,
        "gps_raw_lon_deg": 38.8900578,
        "gps_raw_abs_alt": 81.39100646972656,
        "gps_raw_velocity": 3.40999984741211,
        "position_lat_deg": 47.3065515,
        "position_lon_deg": 38.8900538,
        "position_abs_alt": 83.94500732421875,
        "position_real_alt": 5.199000358581543,
        "heading_deg": 4.55,
        "odometry_pos_body_x": 11.821572303771973,
        "odometry_pos_body_y": 2.4364476203918457,
        "odometry_pos_body_z": -4.773930549621582,
        "odometry_vel_body_x": 2.829015731811523,
        "odometry_vel_body_y": 0.234578937292099,
        "odometry_vel_body_z": 0.687989354133606,
        "odometry_ang_vel_body_roll": -0.5972214937210083,
        "odometry_ang_vel_body_pitch": -0.8205260634422302,
        "odometry_ang_vel_body_yaw": -0.010492662899196148,
        "velocity_north_m_s": 3.429999828338623,
        "velocity_east_m_s": 0.3799999952316284,
        "velocity_down_m_s": 0.029999999329447743,
        "acceleration_forward": -2.2590489387512207,
        "acceleration_right": -0.45626506209373474,
        "acceleration_down": -10.909581184387209,
        "battery_id": 0,
        "battery_voltage": 13.092000961303713,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 21,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 5781,
        "udp_total": 127,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 98
      },
      {
        "id": 207,
        "datetime": "21/02/2023 02:04:32",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477af70>, <mavsdk.mission_raw.MissionItem object at 0xa477a910>, <mavsdk.mission_raw.MissionItem object at 0xa477a838>, <mavsdk.mission_raw.MissionItem object at 0xa477ada8>]",
        "mission_pause": 0,
        "time_usec": 117812474,
        "odometry_pos_body": "PositionBody: [x_m: 12.312731742858887, y_m: 2.5271551609039307, z_m: -4.749805927276611]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 19,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "stop",
        "alg_manual_inputs": "[0.23190333138064667, 0.0, 0.5, 0.0]",
        "timestamp": 117257971,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5600000023841858,
        "current_climb_rate": -0.18451960384845736,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065758,
        "gps_raw_lon_deg": 38.8900602,
        "gps_raw_abs_alt": 81.26100158691406,
        "gps_raw_velocity": 2.93999981880188,
        "position_lat_deg": 47.306562199999995,
        "position_lon_deg": 38.8900559,
        "position_abs_alt": 83.96600341796875,
        "position_real_alt": 5.220000267028809,
        "heading_deg": 5.08,
        "odometry_pos_body_x": 12.687707901000977,
        "odometry_pos_body_y": 2.6037425994873047,
        "odometry_pos_body_z": -4.710389137268066,
        "odometry_vel_body_x": 2.417353868484497,
        "odometry_vel_body_y": 0.33743858337402344,
        "odometry_vel_body_z": 0.4175026714801788,
        "odometry_ang_vel_body_roll": 0.927003026008606,
        "odometry_ang_vel_body_pitch": 0.07286162674427032,
        "odometry_ang_vel_body_yaw": 0.1613717824220657,
        "velocity_north_m_s": 3.359999895095825,
        "velocity_east_m_s": 0.429999977350235,
        "velocity_down_m_s": 0.10999999940395357,
        "acceleration_forward": -0.23487405478954315,
        "acceleration_right": 2.9566128253936768,
        "acceleration_down": -12.55869197845459,
        "battery_id": 0,
        "battery_voltage": 13.092000961303713,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 5785,
        "udp_total": 127,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 98
      },
      {
        "id": 208,
        "datetime": "21/02/2023 02:04:32",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4782100>, <mavsdk.mission_raw.MissionItem object at 0xa47818b0>, <mavsdk.mission_raw.MissionItem object at 0xa4781580>, <mavsdk.mission_raw.MissionItem object at 0xa4781fb8>]",
        "mission_pause": 0,
        "time_usec": 118072237,
        "odometry_pos_body": "PositionBody: [x_m: 13.093469619750977, y_m: 2.6955392360687256, z_m: -4.665771484375]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 19,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "stop",
        "alg_manual_inputs": "[0.23190333138064667, 0.0, 0.5, 0.0]",
        "timestamp": 117537722,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5699999928474426,
        "current_climb_rate": -0.13837499916553497,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065802,
        "gps_raw_lon_deg": 38.8900616,
        "gps_raw_abs_alt": 81.2040023803711,
        "gps_raw_velocity": 2.5199999809265137,
        "position_lat_deg": 47.3065699,
        "position_lon_deg": 38.890057899999995,
        "position_abs_alt": 83.95700073242188,
        "position_real_alt": 5.211000442504883,
        "heading_deg": 5.65,
        "odometry_pos_body_x": 13.353184700012209,
        "odometry_pos_body_y": 2.762331008911133,
        "odometry_pos_body_z": -4.63358211517334,
        "odometry_vel_body_x": 2.212308168411255,
        "odometry_vel_body_y": 0.29497504234313965,
        "odometry_vel_body_z": 0.25209909677505493,
        "odometry_ang_vel_body_roll": -0.25624123215675354,
        "odometry_ang_vel_body_pitch": -0.19267672300338745,
        "odometry_ang_vel_body_yaw": 0.23686359822750092,
        "velocity_north_m_s": 2.90999984741211,
        "velocity_east_m_s": 0.4699999988079071,
        "velocity_down_m_s": 0.17000000178813934,
        "acceleration_forward": 0.19664016366004944,
        "acceleration_right": 3.150031805038452,
        "acceleration_down": -17.171180725097656,
        "battery_id": 0,
        "battery_voltage": 13.092000961303713,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 5806,
        "udp_total": 128,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 99
      },
      {
        "id": 209,
        "datetime": "21/02/2023 02:04:33",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa591e448>, <mavsdk.mission_raw.MissionItem object at 0xa477a7f0>, <mavsdk.mission_raw.MissionItem object at 0xa477a0d0>, <mavsdk.mission_raw.MissionItem object at 0xa4780910>]",
        "mission_pause": 0,
        "time_usec": 118341995,
        "odometry_pos_body": "PositionBody: [x_m: 13.625182151794434, y_m: 2.836603879928589, z_m: -4.596813201904297]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 19,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "stop",
        "alg_manual_inputs": "[0.23190333138064667, 0.0, 0.5, 0.0]",
        "timestamp": 117817466,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5799999833106995,
        "current_climb_rate": -0.1097460240125656,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306588,
        "gps_raw_lon_deg": 38.8900647,
        "gps_raw_abs_alt": 81.10900115966797,
        "gps_raw_velocity": 2.25,
        "position_lat_deg": 47.3065765,
        "position_lon_deg": 38.8900596,
        "position_abs_alt": 83.91700744628906,
        "position_real_alt": 5.1710004806518555,
        "heading_deg": 6.55,
        "odometry_pos_body_x": 13.912935256958008,
        "odometry_pos_body_y": 2.913515567779541,
        "odometry_pos_body_z": -4.5579915046691895,
        "odometry_vel_body_x": 2.1759252548217773,
        "odometry_vel_body_y": 0.1303153932094574,
        "odometry_vel_body_z": 0.05796864628791809,
        "odometry_ang_vel_body_roll": -0.520832896232605,
        "odometry_ang_vel_body_pitch": -0.03792640566825867,
        "odometry_ang_vel_body_yaw": 0.2211209386587143,
        "velocity_north_m_s": 2.5799999237060547,
        "velocity_east_m_s": 0.5199999809265137,
        "velocity_down_m_s": 0.17000000178813934,
        "acceleration_forward": 0.7315189242362976,
        "acceleration_right": -0.05037589371204376,
        "acceleration_down": -12.227054595947266,
        "battery_id": 0,
        "battery_voltage": 13.092000961303713,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 5828,
        "udp_total": 128,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 99
      },
      {
        "id": 210,
        "datetime": "21/02/2023 02:04:33",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4780850>, <mavsdk.mission_raw.MissionItem object at 0xa477deb0>, <mavsdk.mission_raw.MissionItem object at 0xa477d3e8>, <mavsdk.mission_raw.MissionItem object at 0xa477db98>]",
        "mission_pause": 0,
        "time_usec": 118651716,
        "odometry_pos_body": "PositionBody: [x_m: 14.304018020629883, y_m: 3.012974262237549, z_m: -4.509288311004639]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 19,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "stop",
        "alg_manual_inputs": "[0.23190333138064667, 0.0, 0.5, 0.0]",
        "timestamp": 118127191,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5999999642372131,
        "current_climb_rate": -0.06942609697580338,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065917,
        "gps_raw_lon_deg": 38.8900661,
        "gps_raw_abs_alt": 81.0840072631836,
        "gps_raw_velocity": 2.129999876022339,
        "position_lat_deg": 47.3065838,
        "position_lon_deg": 38.890062,
        "position_abs_alt": 83.82500457763672,
        "position_real_alt": 5.079000473022461,
        "heading_deg": 9.61,
        "odometry_pos_body_x": 14.57542324066162,
        "odometry_pos_body_y": 3.0799753665924072,
        "odometry_pos_body_z": -4.478890419006348,
        "odometry_vel_body_x": 2.0921335220336914,
        "odometry_vel_body_y": -0.09497560560703278,
        "odometry_vel_body_z": 0.16080965101718905,
        "odometry_ang_vel_body_roll": 0.5057175755500793,
        "odometry_ang_vel_body_pitch": -0.04690738022327423,
        "odometry_ang_vel_body_yaw": 0.12785328924655914,
        "velocity_north_m_s": 2.180000066757202,
        "velocity_east_m_s": 0.6200000047683716,
        "velocity_down_m_s": 0.12999999523162842,
        "acceleration_forward": 0.9734911918640136,
        "acceleration_right": 2.903266429901123,
        "acceleration_down": -4.807660102844238,
        "battery_id": 0,
        "battery_voltage": 13.092000961303713,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 0,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 5852,
        "udp_total": 128,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 99
      },
      {
        "id": 211,
        "datetime": "21/02/2023 02:04:33",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477a220>, <mavsdk.mission_raw.MissionItem object at 0xa477a898>, <mavsdk.mission_raw.MissionItem object at 0xa477a550>, <mavsdk.mission_raw.MissionItem object at 0xa477ab50>]",
        "mission_pause": 0,
        "time_usec": 118981419,
        "odometry_pos_body": "PositionBody: [x_m: 14.976881980895996, y_m: 3.182091236114502, z_m: -4.4450531005859375]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 19,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "stop",
        "alg_manual_inputs": "[0.23190333138064667, 0.0, 0.5, 0.0]",
        "timestamp": 118451895,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5799999833106995,
        "current_climb_rate": 0.0931430235505104,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3065988,
        "gps_raw_lon_deg": 38.8900686,
        "gps_raw_abs_alt": 81.03300476074219,
        "gps_raw_velocity": 1.96999990940094,
        "position_lat_deg": 47.3065907,
        "position_lon_deg": 38.8900647,
        "position_abs_alt": 83.71500396728516,
        "position_real_alt": 4.968000411987305,
        "heading_deg": 14.66,
        "odometry_pos_body_x": 15.227945327758787,
        "odometry_pos_body_y": 3.2490360736846924,
        "odometry_pos_body_z": -4.431137561798096,
        "odometry_vel_body_x": 2.0048508644104004,
        "odometry_vel_body_y": -0.07686763256788254,
        "odometry_vel_body_z": -0.09632628411054613,
        "odometry_ang_vel_body_roll": 0.4471885859966278,
        "odometry_ang_vel_body_pitch": -0.17312480509281158,
        "odometry_ang_vel_body_yaw": -0.04773310571908951,
        "velocity_north_m_s": 2.0799999237060547,
        "velocity_east_m_s": 0.5899999737739563,
        "velocity_down_m_s": 0.09999999403953552,
        "acceleration_forward": -1.5024105310440063,
        "acceleration_right": -2.5727617740631104,
        "acceleration_down": -10.12969970703125,
        "battery_id": 0,
        "battery_voltage": 13.071001052856444,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 0,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 5874,
        "udp_total": 129,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 100
      },
      {
        "id": 212,
        "datetime": "21/02/2023 02:04:34",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477dc70>, <mavsdk.mission_raw.MissionItem object at 0xa477d868>, <mavsdk.mission_raw.MissionItem object at 0xa477dad8>, <mavsdk.mission_raw.MissionItem object at 0xa477ddd8>]",
        "mission_pause": 0,
        "time_usec": 119281148,
        "odometry_pos_body": "PositionBody: [x_m: 15.553032875061035, y_m: 3.328076124191284, z_m: -4.423970699310303]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 19,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "stop",
        "alg_manual_inputs": "[0.23190333138064667, 0.0, 0.5, 0.0]",
        "timestamp": 118796650,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.550000011920929,
        "current_climb_rate": 0.22483167052268985,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066022,
        "gps_raw_lon_deg": 38.8900698,
        "gps_raw_abs_alt": 81.04800415039062,
        "gps_raw_velocity": 1.96999990940094,
        "position_lat_deg": 47.3065967,
        "position_lon_deg": 38.8900669,
        "position_abs_alt": 83.64700317382812,
        "position_real_alt": 4.901000022888184,
        "heading_deg": 17.080000000000002,
        "odometry_pos_body_x": 15.73987865447998,
        "odometry_pos_body_y": 3.375743865966797,
        "odometry_pos_body_z": -4.422770977020264,
        "odometry_vel_body_x": 1.9606114625930784,
        "odometry_vel_body_y": -0.013703086413443089,
        "odometry_vel_body_z": -0.13558411598205566,
        "odometry_ang_vel_body_roll": -0.5949167609214783,
        "odometry_ang_vel_body_pitch": 0.3324172794818878,
        "odometry_ang_vel_body_yaw": -0.14079779386520386,
        "velocity_north_m_s": 2.0199999809265137,
        "velocity_east_m_s": 0.4899999797344208,
        "velocity_down_m_s": 0.03999999910593033,
        "acceleration_forward": -1.3966842889785769,
        "acceleration_right": 2.725987195968628,
        "acceleration_down": -5.047169208526611,
        "battery_id": 0,
        "battery_voltage": 13.071001052856444,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 10,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 5886,
        "udp_total": 129,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 100
      },
      {
        "id": 213,
        "datetime": "21/02/2023 02:04:34",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa59134c0>, <mavsdk.mission_raw.MissionItem object at 0xa5913d90>, <mavsdk.mission_raw.MissionItem object at 0xa5913b50>, <mavsdk.mission_raw.MissionItem object at 0xa4775f58>]",
        "mission_pause": 0,
        "time_usec": 119580876,
        "odometry_pos_body": "PositionBody: [x_m: 16.09652328491211, y_m: 3.473614454269409, z_m: -4.432089805603027]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 19,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "stop",
        "alg_manual_inputs": "[0.23190333138064667, 0.0, 0.5, 0.0]",
        "timestamp": 119036370,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5399999618530273,
        "current_climb_rate": 0.2581951320171356,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306605399999995,
        "gps_raw_lon_deg": 38.8900711,
        "gps_raw_abs_alt": 81.09300231933594,
        "gps_raw_velocity": 1.8600000143051147,
        "position_lat_deg": 47.306601,
        "position_lon_deg": 38.8900686,
        "position_abs_alt": 83.61800384521484,
        "position_real_alt": 4.87000036239624,
        "heading_deg": 16.27,
        "odometry_pos_body_x": 16.263553619384766,
        "odometry_pos_body_y": 3.5241661071777344,
        "odometry_pos_body_z": -4.440646171569824,
        "odometry_vel_body_x": 1.7701911926269531,
        "odometry_vel_body_y": 0.161760151386261,
        "odometry_vel_body_z": -0.11465422064065932,
        "odometry_ang_vel_body_roll": -0.3223225176334381,
        "odometry_ang_vel_body_pitch": -0.22990694642066956,
        "odometry_ang_vel_body_yaw": -0.17400237917900083,
        "velocity_north_m_s": 1.9299999475479128,
        "velocity_east_m_s": 0.47999998927116394,
        "velocity_down_m_s": -0.08999999612569809,
        "acceleration_forward": 2.1436855792999268,
        "acceleration_right": -1.838372826576233,
        "acceleration_down": -6.007614612579346,
        "battery_id": 0,
        "battery_voltage": 13.071001052856444,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 5898,
        "udp_total": 129,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 100
      },
      {
        "id": 214,
        "datetime": "21/02/2023 02:04:34",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa47829a0>, <mavsdk.mission_raw.MissionItem object at 0xa47815e0>, <mavsdk.mission_raw.MissionItem object at 0xa4781c40>, <mavsdk.mission_raw.MissionItem object at 0xa4781a90>]",
        "mission_pause": 0,
        "time_usec": 119810668,
        "odometry_pos_body": "PositionBody: [x_m: 16.471906661987305, y_m: 3.5867583751678467, z_m: -4.450948715209961]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 19,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "stop",
        "alg_manual_inputs": "[0.23190333138064667, 0.0, 0.5, 0.0]",
        "timestamp": 119286151,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5299999713897705,
        "current_climb_rate": 0.2505857050418854,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306611499999995,
        "gps_raw_lon_deg": 38.8900735,
        "gps_raw_abs_alt": 81.17900085449219,
        "gps_raw_velocity": 1.7400000095367432,
        "position_lat_deg": 47.3066061,
        "position_lon_deg": 38.8900705,
        "position_abs_alt": 83.6050033569336,
        "position_real_alt": 4.859000205993652,
        "heading_deg": 14.85,
        "odometry_pos_body_x": 16.735654830932617,
        "odometry_pos_body_y": 3.675748586654663,
        "odometry_pos_body_z": -4.462387561798096,
        "odometry_vel_body_x": 1.6235636472702026,
        "odometry_vel_body_y": 0.31708410382270813,
        "odometry_vel_body_z": -0.24488244950771332,
        "odometry_ang_vel_body_roll": 0.2611904740333557,
        "odometry_ang_vel_body_pitch": 0.268856406211853,
        "odometry_ang_vel_body_yaw": -0.25114864110946655,
        "velocity_north_m_s": 1.8799999952316284,
        "velocity_east_m_s": 0.5099999904632568,
        "velocity_down_m_s": -0.23999999463558197,
        "acceleration_forward": -0.12405045330524445,
        "acceleration_right": 2.5779263973236084,
        "acceleration_down": -4.602765083312988,
        "battery_id": 0,
        "battery_voltage": 13.071001052856444,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 5920,
        "udp_total": 129,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 100
      },
      {
        "id": 215,
        "datetime": "21/02/2023 02:04:34",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4781a30>, <mavsdk.mission_raw.MissionItem object at 0xa4781070>, <mavsdk.mission_raw.MissionItem object at 0xa4780250>, <mavsdk.mission_raw.MissionItem object at 0xa4780ca0>]",
        "mission_pause": 0,
        "time_usec": 120150362,
        "odometry_pos_body": "PositionBody: [x_m: 16.991775512695312, y_m: 3.770689010620117, z_m: -4.475100994110107]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 19,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "stop",
        "alg_manual_inputs": "[0.23190333138064667, 0.0, 0.5, 0.0]",
        "timestamp": 119695775,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5299999713897705,
        "current_climb_rate": 0.2230369448661804,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306616899999995,
        "gps_raw_lon_deg": 38.8900762,
        "gps_raw_abs_alt": 81.29400634765625,
        "gps_raw_velocity": 1.5,
        "position_lat_deg": 47.3066135,
        "position_lon_deg": 38.890073699999995,
        "position_abs_alt": 83.64000701904297,
        "position_real_alt": 4.895000457763672,
        "heading_deg": 10.04,
        "odometry_pos_body_x": 17.366104125976562,
        "odometry_pos_body_y": 3.919682025909424,
        "odometry_pos_body_z": -4.499902725219727,
        "odometry_vel_body_x": 1.5204213857650757,
        "odometry_vel_body_y": 0.523689866065979,
        "odometry_vel_body_z": -0.1866843104362488,
        "odometry_ang_vel_body_roll": -0.24100789427757263,
        "odometry_ang_vel_body_pitch": -0.04081524908542633,
        "odometry_ang_vel_body_yaw": -0.09936737269163132,
        "velocity_north_m_s": 1.579999923706055,
        "velocity_east_m_s": 0.5899999737739563,
        "velocity_down_m_s": -0.25,
        "acceleration_forward": -0.0744815245270729,
        "acceleration_right": -2.9348320960998535,
        "acceleration_down": -11.744636535644531,
        "battery_id": 0,
        "battery_voltage": 13.071001052856444,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 12,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 5966,
        "udp_total": 130,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 101
      },
      {
        "id": 216,
        "datetime": "21/02/2023 02:04:35",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477c160>, <mavsdk.mission_raw.MissionItem object at 0xa477c1d8>, <mavsdk.mission_raw.MissionItem object at 0xa58fc5e0>, <mavsdk.mission_raw.MissionItem object at 0xa4777508>]",
        "mission_pause": 0,
        "time_usec": 120679886,
        "odometry_pos_body": "PositionBody: [x_m: 17.733421325683594, y_m: 4.077739715576172, z_m: -4.544035911560059]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 19,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "stop",
        "alg_manual_inputs": "[0.23190333138064667, 0.0, 0.5, 0.0]",
        "timestamp": 120060443,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5099999904632568,
        "current_climb_rate": 0.21410998702049255,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306621799999995,
        "gps_raw_lon_deg": 38.8900795,
        "gps_raw_abs_alt": 81.36600494384766,
        "gps_raw_velocity": 1.46999990940094,
        "position_lat_deg": 47.3066168,
        "position_lon_deg": 38.8900754,
        "position_abs_alt": 83.6550064086914,
        "position_real_alt": 4.909000396728516,
        "heading_deg": 7.98,
        "odometry_pos_body_x": 17.772390365600586,
        "odometry_pos_body_y": 4.095622539520264,
        "odometry_pos_body_z": -4.548428535461426,
        "odometry_vel_body_x": 1.4384013414382937,
        "odometry_vel_body_y": 0.5395994186401367,
        "odometry_vel_body_z": -0.12544316053390503,
        "odometry_ang_vel_body_roll": -0.12655285000801086,
        "odometry_ang_vel_body_pitch": -0.2461531162261963,
        "odometry_ang_vel_body_yaw": 0.06062614917755127,
        "velocity_north_m_s": 1.5199999809265137,
        "velocity_east_m_s": 0.6100000143051147,
        "velocity_down_m_s": -0.25,
        "acceleration_forward": -0.768627405166626,
        "acceleration_right": -5.722377300262451,
        "acceleration_down": -5.107429027557373,
        "battery_id": 0,
        "battery_voltage": 13.071001052856444,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 4,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 5967,
        "udp_total": 130,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 101
      },
      {
        "id": 217,
        "datetime": "21/02/2023 02:04:35",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4782238>, <mavsdk.mission_raw.MissionItem object at 0xa4782f40>, <mavsdk.mission_raw.MissionItem object at 0xa4782640>, <mavsdk.mission_raw.MissionItem object at 0xa4782880>]",
        "mission_pause": 0,
        "time_usec": 120879709,
        "odometry_pos_body": "PositionBody: [x_m: 17.988433837890625, y_m: 4.200279712677002, z_m: -4.565593719482422]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 19,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "stop",
        "alg_manual_inputs": "[0.23190333138064667, 0.0, 0.5, 0.0]",
        "timestamp": 120260262,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5299999713897705,
        "current_climb_rate": 0.07633588463068008,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066264,
        "gps_raw_lon_deg": 38.8900829,
        "gps_raw_abs_alt": 81.45000457763672,
        "gps_raw_velocity": 1.3899999856948853,
        "position_lat_deg": 47.3066204,
        "position_lon_deg": 38.8900776,
        "position_abs_alt": 83.6810073852539,
        "position_real_alt": 4.937000274658203,
        "heading_deg": 5.98,
        "odometry_pos_body_x": 18.34467887878418,
        "odometry_pos_body_y": 4.388787746429443,
        "odometry_pos_body_z": -4.581826686859131,
        "odometry_vel_body_x": 1.2878590822219849,
        "odometry_vel_body_y": 0.4605852961540222,
        "odometry_vel_body_z": -0.01267870981246233,
        "odometry_ang_vel_body_roll": -0.8228904008865356,
        "odometry_ang_vel_body_pitch": 0.0013132937019690871,
        "odometry_ang_vel_body_yaw": 0.1483093798160553,
        "velocity_north_m_s": 1.4399999380111694,
        "velocity_east_m_s": 0.6899999976158142,
        "velocity_down_m_s": -0.19999998807907104,
        "acceleration_forward": 0.07254183292388916,
        "acceleration_right": 1.6139068603515625,
        "acceleration_down": -0.6425182223320007,
        "battery_id": 0,
        "battery_voltage": 13.140000343322754,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 38,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 6003,
        "udp_total": 131,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 102
      },
      {
        "id": 218,
        "datetime": "21/02/2023 02:04:36",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa5910130>, <mavsdk.mission_raw.MissionItem object at 0xa59104f0>, <mavsdk.mission_raw.MissionItem object at 0xa5910508>, <mavsdk.mission_raw.MissionItem object at 0xa5910748>]",
        "mission_pause": 0,
        "time_usec": 121379256,
        "odometry_pos_body": "PositionBody: [x_m: 18.56250762939453, y_m: 4.506471157073975, z_m: -4.583608150482178]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 19,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "stop",
        "alg_manual_inputs": "[0.23190333138064667, 0.0, 0.5, 0.0]",
        "timestamp": 120599957,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5399999618530273,
        "current_climb_rate": 0.006195931229740381,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306630299999995,
        "gps_raw_lon_deg": 38.890085899999995,
        "gps_raw_abs_alt": 81.46800231933594,
        "gps_raw_velocity": 1.1799999475479126,
        "position_lat_deg": 47.306623599999995,
        "position_lon_deg": 38.8900796,
        "position_abs_alt": 83.72300720214844,
        "position_real_alt": 4.9770002365112305,
        "heading_deg": 6.2700000000000005,
        "odometry_pos_body_x": 18.694278717041016,
        "odometry_pos_body_y": 4.576225280761719,
        "odometry_pos_body_z": -4.580406665802002,
        "odometry_vel_body_x": 1.105737566947937,
        "odometry_vel_body_y": 0.3663485646247864,
        "odometry_vel_body_z": 0.06927380710840225,
        "odometry_ang_vel_body_roll": 0.08190441131591797,
        "odometry_ang_vel_body_pitch": -0.011935056187212467,
        "odometry_ang_vel_body_yaw": -0.009994551539421082,
        "velocity_north_m_s": 1.3799999952316284,
        "velocity_east_m_s": 0.6800000071525574,
        "velocity_down_m_s": -0.20999999344348907,
        "acceleration_forward": -0.3009940981864929,
        "acceleration_right": 5.420670986175537,
        "acceleration_down": -14.991354942321776,
        "battery_id": 0,
        "battery_voltage": 13.140000343322754,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 60,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 6024,
        "udp_total": 131,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 102
      },
      {
        "id": 219,
        "datetime": "21/02/2023 02:04:36",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4778bc8>, <mavsdk.mission_raw.MissionItem object at 0xa4778640>, <mavsdk.mission_raw.MissionItem object at 0xa5910fa0>, <mavsdk.mission_raw.MissionItem object at 0xa477bf28>]",
        "mission_pause": 0,
        "time_usec": 121719016,
        "odometry_pos_body": "PositionBody: [x_m: 18.88399887084961, y_m: 4.675321578979492, z_m: -4.578227519989014]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 19,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "stop",
        "alg_manual_inputs": "[0.23190333138064667, 0.0, 0.5, 0.0]",
        "timestamp": 120799777,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5299999713897705,
        "current_climb_rate": 0.03003908693790436,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306632099999995,
        "gps_raw_lon_deg": 38.8900872,
        "gps_raw_abs_alt": 81.5050048828125,
        "gps_raw_velocity": 0.9199999570846558,
        "position_lat_deg": 47.3066257,
        "position_lon_deg": 38.890080999999995,
        "position_abs_alt": 83.74500274658203,
        "position_real_alt": 4.999000072479248,
        "heading_deg": 7.07,
        "odometry_pos_body_x": 18.959001541137695,
        "odometry_pos_body_y": 4.714470863342285,
        "odometry_pos_body_z": -4.578121662139893,
        "odometry_vel_body_x": 0.9154714345932008,
        "odometry_vel_body_y": 0.30886873602867126,
        "odometry_vel_body_z": 0.025659509003162384,
        "odometry_ang_vel_body_roll": -0.3667328953742981,
        "odometry_ang_vel_body_pitch": 0.04586755484342575,
        "odometry_ang_vel_body_yaw": -0.22862353920936584,
        "velocity_north_m_s": 1.329999923706055,
        "velocity_east_m_s": 0.6800000071525574,
        "velocity_down_m_s": -0.1599999964237213,
        "acceleration_forward": 0.07589055597782135,
        "acceleration_right": 1.374824047088623,
        "acceleration_down": -0.7165255546569824,
        "battery_id": 0,
        "battery_voltage": 13.140000343322754,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 3,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 6039,
        "udp_total": 131,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 102
      },
      {
        "id": 220,
        "datetime": "21/02/2023 02:04:36",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4777a48>, <mavsdk.mission_raw.MissionItem object at 0xa4777df0>, <mavsdk.mission_raw.MissionItem object at 0xa4777058>, <mavsdk.mission_raw.MissionItem object at 0xa4777b80>]",
        "mission_pause": 0,
        "time_usec": 121978715,
        "odometry_pos_body": "PositionBody: [x_m: 19.08929443359375, y_m: 4.784396171569824, z_m: -4.579504489898682]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065009,
        "alg_manual_gps_lon_start": 38.8900564,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066362,
        "alg_manual_gps_lon_next": 38.8900423,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 2.1726131439208984,
        "alg_manual_y_now": 4.873988151550293,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 19,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "stop",
        "alg_manual_inputs": "[0.23190333138064667, 0.0, 0.5, 0.0]",
        "timestamp": 120959689,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5199999809265137,
        "current_climb_rate": 0.09516080468893053,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306635,
        "gps_raw_lon_deg": 38.890089499999995,
        "gps_raw_abs_alt": 81.49600219726562,
        "gps_raw_velocity": 0.8999999761581421,
        "position_lat_deg": 47.3066281,
        "position_lon_deg": 38.8900829,
        "position_abs_alt": 83.75800323486328,
        "position_real_alt": 5.01200008392334,
        "heading_deg": 8.86,
        "odometry_pos_body_x": 19.22921562194824,
        "odometry_pos_body_y": 4.867404937744141,
        "odometry_pos_body_z": -4.588791847229004,
        "odometry_vel_body_x": 0.7251355051994324,
        "odometry_vel_body_y": 0.39529332518577576,
        "odometry_vel_body_z": -0.07686743885278702,
        "odometry_ang_vel_body_roll": -0.6837121844291687,
        "odometry_ang_vel_body_pitch": 0.36572226881980896,
        "odometry_ang_vel_body_yaw": -0.29813098907470703,
        "velocity_north_m_s": 1.2200000286102295,
        "velocity_east_m_s": 0.6699999570846558,
        "velocity_down_m_s": -0.07000000029802322,
        "acceleration_forward": -1.1998800039291382,
        "acceleration_right": 0.8795430064201355,
        "acceleration_down": -9.784623146057127,
        "battery_id": 0,
        "battery_voltage": 13.140000343322754,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 3,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 6062,
        "udp_total": 132,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 103
      },
      {
        "id": 221,
        "datetime": "21/02/2023 02:04:37",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477d220>, <mavsdk.mission_raw.MissionItem object at 0xa477a1f0>, <mavsdk.mission_raw.MissionItem object at 0xa477a658>, <mavsdk.mission_raw.MissionItem object at 0xa477cf28>]",
        "mission_pause": 0,
        "time_usec": 122378355,
        "odometry_pos_body": "PositionBody: [x_m: 19.362245559692383, y_m: 4.9484477043151855, z_m: -4.597214221954346]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066362,
        "alg_manual_gps_lon_start": 38.8900423,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066322,
        "alg_manual_gps_lon_next": 38.8898405,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 4.9484477043151855,
        "alg_manual_y_now": 19.362245559692383,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 19,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "go 47.3066322 38.8898405 3.0 8",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 121259363,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5099999904632568,
        "current_climb_rate": 0.07546970993280411,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066375,
        "gps_raw_lon_deg": 38.8900913,
        "gps_raw_abs_alt": 81.55900573730469,
        "gps_raw_velocity": 0.7599999904632568,
        "position_lat_deg": 47.3066307,
        "position_lon_deg": 38.890085,
        "position_abs_alt": 83.76600646972656,
        "position_real_alt": 5.019000053405762,
        "heading_deg": 10.39,
        "odometry_pos_body_x": 19.467432022094727,
        "odometry_pos_body_y": 5.008720397949219,
        "odometry_pos_body_z": -4.603620529174805,
        "odometry_vel_body_x": 0.6943451762199402,
        "odometry_vel_body_y": 0.44614389538764954,
        "odometry_vel_body_z": -0.09036135673522948,
        "odometry_ang_vel_body_roll": 0.6450239419937134,
        "odometry_ang_vel_body_pitch": 0.09180179238319396,
        "odometry_ang_vel_body_yaw": -0.0672115907073021,
        "velocity_north_m_s": 1.1299999952316284,
        "velocity_east_m_s": 0.6299999952316284,
        "velocity_down_m_s": -0.019999999552965164,
        "acceleration_forward": -0.5081508755683899,
        "acceleration_right": 2.0492358207702637,
        "acceleration_down": -8.030195236206055,
        "battery_id": 0,
        "battery_voltage": 13.140000343322754,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 2,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 6086,
        "udp_total": 132,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 103
      },
      {
        "id": 222,
        "datetime": "21/02/2023 02:04:37",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4781898>, <mavsdk.mission_raw.MissionItem object at 0xa4781dc0>, <mavsdk.mission_raw.MissionItem object at 0xa4781358>, <mavsdk.mission_raw.MissionItem object at 0xa4782ee0>]",
        "mission_pause": 0,
        "time_usec": 122723046,
        "odometry_pos_body": "PositionBody: [x_m: 19.5850830078125, y_m: 5.0754008293151855, z_m: -4.608677387237549]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066362,
        "alg_manual_gps_lon_start": 38.8900423,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066322,
        "alg_manual_gps_lon_next": 38.8898405,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 5.0754008293151855,
        "alg_manual_y_now": 19.5850830078125,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 19,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "go 47.3066322 38.8898405 3.0 8",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 121499148,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.38999998569488525,
        "current_climb_rate": -0.01181149948388338,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066387,
        "gps_raw_lon_deg": 38.8900923,
        "gps_raw_abs_alt": 81.58200073242188,
        "gps_raw_velocity": 0.7400000095367432,
        "position_lat_deg": 47.3066331,
        "position_lon_deg": 38.8900868,
        "position_abs_alt": 83.76100158691406,
        "position_real_alt": 5.015000343322754,
        "heading_deg": 10.11,
        "odometry_pos_body_x": 19.657869338989254,
        "odometry_pos_body_y": 5.112568378448486,
        "odometry_pos_body_z": -4.6023759841918945,
        "odometry_vel_body_x": 0.6387838125228882,
        "odometry_vel_body_y": 0.3958061635494232,
        "odometry_vel_body_z": 0.15513508021831512,
        "odometry_ang_vel_body_roll": 0.2645905613899231,
        "odometry_ang_vel_body_pitch": 0.3950410485267639,
        "odometry_ang_vel_body_yaw": -0.1441991627216339,
        "velocity_north_m_s": 1.0099999904632568,
        "velocity_east_m_s": 0.5399999618530273,
        "velocity_down_m_s": 0,
        "acceleration_forward": 0.10529719293117525,
        "acceleration_right": 1.015629529953003,
        "acceleration_down": -3.5468506813049316,
        "battery_id": 0,
        "battery_voltage": 13.140000343322754,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 2,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 1,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 6108,
        "udp_total": 132,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 103
      },
      {
        "id": 223,
        "datetime": "21/02/2023 02:04:37",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4775fe8>, <mavsdk.mission_raw.MissionItem object at 0xa477b8b0>, <mavsdk.mission_raw.MissionItem object at 0xa477b730>, <mavsdk.mission_raw.MissionItem object at 0xa477b6d0>]",
        "mission_pause": 0,
        "time_usec": 122982812,
        "odometry_pos_body": "PositionBody: [x_m: 19.733501434326172, y_m: 5.153090476989746, z_m: -4.573118686676025]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066362,
        "alg_manual_gps_lon_start": 38.8900423,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066322,
        "alg_manual_gps_lon_next": 38.8898405,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 5.153090476989746,
        "alg_manual_y_now": 19.733501434326172,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 19,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "go 47.3066322 38.8898405 3.0 8",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 121818859,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.47999998927116394,
        "current_climb_rate": -0.4093202650547027,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066409,
        "gps_raw_lon_deg": 38.8900939,
        "gps_raw_abs_alt": 81.56000518798828,
        "gps_raw_velocity": 0.6599999666213989,
        "position_lat_deg": 47.3066354,
        "position_lon_deg": 38.8900886,
        "position_abs_alt": 83.75900268554688,
        "position_real_alt": 5.013000011444092,
        "heading_deg": 6.91,
        "odometry_pos_body_x": 19.79607582092285,
        "odometry_pos_body_y": 5.1875786781311035,
        "odometry_pos_body_z": -4.5269060134887695,
        "odometry_vel_body_x": 0.4558035433292389,
        "odometry_vel_body_y": 0.3334853947162628,
        "odometry_vel_body_z": 0.4916139841079712,
        "odometry_ang_vel_body_roll": -0.732422947883606,
        "odometry_ang_vel_body_pitch": 0.09187956899404526,
        "odometry_ang_vel_body_yaw": -0.07140353322029114,
        "velocity_north_m_s": 0.8299999833106995,
        "velocity_east_m_s": 0.4399999976158142,
        "velocity_down_m_s": -0.009999999776482582,
        "acceleration_forward": -0.93271142244339,
        "acceleration_right": 0.3493550419807434,
        "acceleration_down": -10.09238624572754,
        "battery_id": 0,
        "battery_voltage": 13.182000160217283,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 2,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 1,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 6132,
        "udp_total": 133,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 104
      },
      {
        "id": 224,
        "datetime": "21/02/2023 02:04:38",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4775e38>, <mavsdk.mission_raw.MissionItem object at 0xa4777c58>, <mavsdk.mission_raw.MissionItem object at 0xa47779d0>, <mavsdk.mission_raw.MissionItem object at 0xa4777370>]",
        "mission_pause": 0,
        "time_usec": 123282567,
        "odometry_pos_body": "PositionBody: [x_m: 19.8609676361084, y_m: 5.232120037078857, z_m: -4.4533891677856445]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066362,
        "alg_manual_gps_lon_start": 38.8900423,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066322,
        "alg_manual_gps_lon_next": 38.8898405,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 5.221065998077393,
        "alg_manual_y_now": 19.847196578979492,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 19,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "go 47.3066322 38.8898405 3.0 8",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 122118589,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5099999904632568,
        "current_climb_rate": -0.538732647895813,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066417,
        "gps_raw_lon_deg": 38.8900945,
        "gps_raw_abs_alt": 81.47700500488281,
        "gps_raw_velocity": 0.550000011920929,
        "position_lat_deg": 47.3066375,
        "position_lon_deg": 38.8900904,
        "position_abs_alt": 83.77300262451172,
        "position_real_alt": 5.027000427246094,
        "heading_deg": 1.98,
        "odometry_pos_body_x": 19.889440536499023,
        "odometry_pos_body_y": 5.253969669342041,
        "odometry_pos_body_z": -4.414654731750488,
        "odometry_vel_body_x": 0.2359504401683807,
        "odometry_vel_body_y": 0.3155202269554138,
        "odometry_vel_body_z": 0.594528317451477,
        "odometry_ang_vel_body_roll": -0.5137526988983154,
        "odometry_ang_vel_body_pitch": -0.01769203506410122,
        "odometry_ang_vel_body_yaw": 0.0576460063457489,
        "velocity_north_m_s": 0.7099999785423279,
        "velocity_east_m_s": 0.41999998688697815,
        "velocity_down_m_s": -0.08999999612569809,
        "acceleration_forward": -0.1273072361946106,
        "acceleration_right": -0.9628000259399414,
        "acceleration_down": -11.803451538085938,
        "battery_id": 0,
        "battery_voltage": 13.182000160217283,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 2,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 6154,
        "udp_total": 133,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 104
      },
      {
        "id": 225,
        "datetime": "21/02/2023 02:04:38",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477a4a8>, <mavsdk.mission_raw.MissionItem object at 0xa477dcb8>, <mavsdk.mission_raw.MissionItem object at 0xa477dad8>, <mavsdk.mission_raw.MissionItem object at 0xa477d1a8>]",
        "mission_pause": 0,
        "time_usec": 123582272,
        "odometry_pos_body": "PositionBody: [x_m: 19.941213607788086, y_m: 5.298957824707031, z_m: -4.310744762420654]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066362,
        "alg_manual_gps_lon_start": 38.8900423,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066322,
        "alg_manual_gps_lon_next": 38.8898405,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 5.291609287261963,
        "alg_manual_y_now": 19.93192481994629,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 19,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "go 47.3066322 38.8898405 3.0 8",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 122463279,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5099999904632568,
        "current_climb_rate": -0.576140284538269,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066424,
        "gps_raw_lon_deg": 38.8900951,
        "gps_raw_abs_alt": 81.36700439453125,
        "gps_raw_velocity": 0.4399999976158142,
        "position_lat_deg": 47.306639399999995,
        "position_lon_deg": 38.890092,
        "position_abs_alt": 83.78600311279297,
        "position_real_alt": 5.0400004386901855,
        "heading_deg": 359.40000000000003,
        "odometry_pos_body_x": 19.963157653808594,
        "odometry_pos_body_y": 5.324971675872803,
        "odometry_pos_body_z": -4.243724346160889,
        "odometry_vel_body_x": 0.17119184136390686,
        "odometry_vel_body_y": 0.26230326294898987,
        "odometry_vel_body_z": 0.5680311322212219,
        "odometry_ang_vel_body_roll": 0.07735991477966309,
        "odometry_ang_vel_body_pitch": -0.2923717498779297,
        "odometry_ang_vel_body_yaw": -0.059988271445035934,
        "velocity_north_m_s": 0.6899999976158142,
        "velocity_east_m_s": 0.429999977350235,
        "velocity_down_m_s": -0.07999999821186066,
        "acceleration_forward": -1.1722829341888428,
        "acceleration_right": -4.7000603675842285,
        "acceleration_down": -9.798994064331056,
        "battery_id": 0,
        "battery_voltage": 13.182000160217283,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 2,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 14,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 6167,
        "udp_total": 133,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 104
      },
      {
        "id": 226,
        "datetime": "21/02/2023 02:04:38",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477ac40>, <mavsdk.mission_raw.MissionItem object at 0xa477a298>, <mavsdk.mission_raw.MissionItem object at 0xa4782568>, <mavsdk.mission_raw.MissionItem object at 0xa591e778>]",
        "mission_pause": 0,
        "time_usec": 123911991,
        "odometry_pos_body": "PositionBody: [x_m: 19.995315551757812, y_m: 5.3599090576171875, z_m: -4.149673938751221]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066362,
        "alg_manual_gps_lon_start": 38.8900423,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066322,
        "alg_manual_gps_lon_next": 38.8898405,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 5.3399977684021,
        "alg_manual_y_now": 19.981861114501953,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 19,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "go 47.3066322 38.8898405 3.0 8",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 122748023,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5099999904632568,
        "current_climb_rate": -0.5670214295387268,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066429,
        "gps_raw_lon_deg": 38.890096199999995,
        "gps_raw_abs_alt": 81.13800048828125,
        "gps_raw_velocity": 0.29999998211860657,
        "position_lat_deg": 47.3066411,
        "position_lon_deg": 38.8900935,
        "position_abs_alt": 83.78500366210938,
        "position_real_alt": 5.038000106811523,
        "heading_deg": 357.96,
        "odometry_pos_body_x": 20.01028060913086,
        "odometry_pos_body_y": 5.383781909942627,
        "odometry_pos_body_z": -4.099191188812256,
        "odometry_vel_body_x": 0.15682946145534515,
        "odometry_vel_body_y": 0.3344935476779938,
        "odometry_vel_body_z": 0.5434523224830627,
        "odometry_ang_vel_body_roll": 0.45238441228866577,
        "odometry_ang_vel_body_pitch": -0.18750110268592832,
        "odometry_ang_vel_body_yaw": 0.06433138251304626,
        "velocity_north_m_s": 0.6499999761581421,
        "velocity_east_m_s": 0.3799999952316284,
        "velocity_down_m_s": 0.12999999523162842,
        "acceleration_forward": -0.14873990416526794,
        "acceleration_right": 0.5259786248207092,
        "acceleration_down": -8.489133834838867,
        "battery_id": 0,
        "battery_voltage": 13.182000160217283,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 2,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 6179,
        "udp_total": 133,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 104
      },
      {
        "id": 227,
        "datetime": "21/02/2023 02:04:38",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa47814d8>, <mavsdk.mission_raw.MissionItem object at 0xa47817d8>, <mavsdk.mission_raw.MissionItem object at 0xa47816b8>, <mavsdk.mission_raw.MissionItem object at 0xa47818c8>]",
        "mission_pause": 0,
        "time_usec": 124181734,
        "odometry_pos_body": "PositionBody: [x_m: 20.02682876586914, y_m: 5.41618013381958, z_m: -4.034193515777588]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066362,
        "alg_manual_gps_lon_start": 38.8900423,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066322,
        "alg_manual_gps_lon_next": 38.8898405,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 5.434791564941406,
        "alg_manual_y_now": 20.03532600402832,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 19,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "go 47.3066322 38.8898405 3.0 8",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 123112695,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5099999904632568,
        "current_climb_rate": -0.5762084722518921,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066435,
        "gps_raw_lon_deg": 38.8900972,
        "gps_raw_abs_alt": 80.92200469970703,
        "gps_raw_velocity": 0.23999999463558197,
        "position_lat_deg": 47.3066428,
        "position_lon_deg": 38.8900949,
        "position_abs_alt": 83.66000366210938,
        "position_real_alt": 4.914000034332275,
        "heading_deg": 359.99,
        "odometry_pos_body_x": 20.055662155151367,
        "odometry_pos_body_y": 5.468410015106201,
        "odometry_pos_body_z": -3.925592184066773,
        "odometry_vel_body_x": 0.20213541388511655,
        "odometry_vel_body_y": 0.3196469843387604,
        "odometry_vel_body_z": 0.5804324150085449,
        "odometry_ang_vel_body_roll": -0.11481621116399764,
        "odometry_ang_vel_body_pitch": -0.272943913936615,
        "odometry_ang_vel_body_yaw": 0.14915762841701508,
        "velocity_north_m_s": 0.3999999761581421,
        "velocity_east_m_s": 0.28999999165534973,
        "velocity_down_m_s": 0.5199999809265137,
        "acceleration_forward": -1.1440892219543457,
        "acceleration_right": 1.8719438314437864,
        "acceleration_down": -5.815889835357666,
        "battery_id": 0,
        "battery_voltage": 13.182000160217283,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 2,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 6202,
        "udp_total": 134,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 105
      },
      {
        "id": 228,
        "datetime": "21/02/2023 02:04:39",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa591e238>, <mavsdk.mission_raw.MissionItem object at 0xa477d4d8>, <mavsdk.mission_raw.MissionItem object at 0xa477d4f0>, <mavsdk.mission_raw.MissionItem object at 0xa477d6d0>]",
        "mission_pause": 0,
        "time_usec": 124491455,
        "odometry_pos_body": "PositionBody: [x_m: 20.0828800201416, y_m: 5.499117374420166, z_m: -3.8376994132995605]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066362,
        "alg_manual_gps_lon_start": 38.8900423,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066322,
        "alg_manual_gps_lon_next": 38.8898405,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 5.499117374420166,
        "alg_manual_y_now": 20.0828800201416,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 19,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "go 47.3066322 38.8898405 3.0 8",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 123492353,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5,
        "current_climb_rate": -0.5600812435150146,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306643699999995,
        "gps_raw_lon_deg": 38.8900979,
        "gps_raw_abs_alt": 80.78800201416016,
        "gps_raw_velocity": 0.3199999928474426,
        "position_lat_deg": 47.3066436,
        "position_lon_deg": 38.8900958,
        "position_abs_alt": 83.51300048828125,
        "position_real_alt": 4.767000198364258,
        "heading_deg": 0.88,
        "odometry_pos_body_x": 20.10127830505371,
        "odometry_pos_body_y": 5.520267009735107,
        "odometry_pos_body_z": -3.777723789215088,
        "odometry_vel_body_x": 0.25699377059936523,
        "odometry_vel_body_y": 0.25474733114242554,
        "odometry_vel_body_z": 0.5884377956390381,
        "odometry_ang_vel_body_roll": 0.3373280167579651,
        "odometry_ang_vel_body_pitch": 0.18884316086769104,
        "odometry_ang_vel_body_yaw": 0.12631680071353912,
        "velocity_north_m_s": 0.20999999344348907,
        "velocity_east_m_s": 0.25,
        "velocity_down_m_s": 0.5999999642372131,
        "acceleration_forward": 1.3584848642349243,
        "acceleration_right": -3.70371675491333,
        "acceleration_down": -6.292095184326172,
        "battery_id": 0,
        "battery_voltage": 13.182000160217283,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 2,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 1,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 6226,
        "udp_total": 134,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 105
      },
      {
        "id": 229,
        "datetime": "21/02/2023 02:04:39",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477cc58>, <mavsdk.mission_raw.MissionItem object at 0xa477c298>, <mavsdk.mission_raw.MissionItem object at 0xa477ce20>, <mavsdk.mission_raw.MissionItem object at 0xa4775b98>]",
        "mission_pause": 0,
        "time_usec": 124701268,
        "odometry_pos_body": "PositionBody: [x_m: 20.12869644165039, y_m: 5.546042442321777, z_m: -3.6904821395874023]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066362,
        "alg_manual_gps_lon_start": 38.8900423,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066322,
        "alg_manual_gps_lon_next": 38.8898405,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 5.541698932647705,
        "alg_manual_y_now": 20.12430191040039,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 19,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "go 47.3066322 38.8898405 3.0 8",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 123787091,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5099999904632568,
        "current_climb_rate": -0.5675358176231384,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306644,
        "gps_raw_lon_deg": 38.8900985,
        "gps_raw_abs_alt": 80.67500305175781,
        "gps_raw_velocity": 0.25,
        "position_lat_deg": 47.3066441,
        "position_lon_deg": 38.8900966,
        "position_abs_alt": 83.35600280761719,
        "position_real_alt": 4.610000133514404,
        "heading_deg": 358.09000000000003,
        "odometry_pos_body_x": 20.151756286621097,
        "odometry_pos_body_y": 5.564316749572754,
        "odometry_pos_body_z": -3.6069204807281494,
        "odometry_vel_body_x": 0.26503729820251465,
        "odometry_vel_body_y": 0.15174658596515656,
        "odometry_vel_body_z": 0.6053529977798462,
        "odometry_ang_vel_body_roll": -0.4360714852809906,
        "odometry_ang_vel_body_pitch": -0.09554237872362135,
        "odometry_ang_vel_body_yaw": 0.1559876650571823,
        "velocity_north_m_s": 0.1599999964237213,
        "velocity_east_m_s": 0.26999998092651367,
        "velocity_down_m_s": 0.5399999618530273,
        "acceleration_forward": -1.2126610279083252,
        "acceleration_right": 1.123318433761597,
        "acceleration_down": -10.167275428771973,
        "battery_id": 0,
        "battery_voltage": 13.182000160217283,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 2,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 23,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 6248,
        "udp_total": 134,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 105
      },
      {
        "id": 230,
        "datetime": "21/02/2023 02:04:39",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa5910508>, <mavsdk.mission_raw.MissionItem object at 0xa591efb8>, <mavsdk.mission_raw.MissionItem object at 0xa477d868>, <mavsdk.mission_raw.MissionItem object at 0xa477d100>]",
        "mission_pause": 0,
        "time_usec": 125030971,
        "odometry_pos_body": "PositionBody: [x_m: 20.185205459594727, y_m: 5.5902814865112305, z_m: -3.47721529006958]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 349.46,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066362,
        "alg_manual_gps_lon_start": 38.8900423,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066322,
        "alg_manual_gps_lon_next": 38.8898405,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 5.582026958465576,
        "alg_manual_y_now": 20.190587997436523,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "rotate 268.3256698047717",
        "alg_manual_inputs": "[0.0, 0.0, 0.0, 0.0]",
        "timestamp": 124191727,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5199999809265137,
        "current_climb_rate": -0.5798324942588806,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306644899999995,
        "gps_raw_lon_deg": 38.8900992,
        "gps_raw_abs_alt": 80.43700408935547,
        "gps_raw_velocity": 0.29999998211860657,
        "position_lat_deg": 47.3066446,
        "position_lon_deg": 38.8900979,
        "position_abs_alt": 83.15300750732422,
        "position_real_alt": 4.396000385284424,
        "heading_deg": 358.08,
        "odometry_pos_body_x": 20.203882217407227,
        "odometry_pos_body_y": 5.595453262329102,
        "odometry_pos_body_z": -3.41092848777771,
        "odometry_vel_body_x": 0.2169482558965683,
        "odometry_vel_body_y": 0.06873171031475067,
        "odometry_vel_body_z": 0.6266485452651978,
        "odometry_ang_vel_body_roll": -0.2434306293725967,
        "odometry_ang_vel_body_pitch": 0.25490036606788635,
        "odometry_ang_vel_body_yaw": 0.1317855417728424,
        "velocity_north_m_s": 0.19999998807907104,
        "velocity_east_m_s": 0.32999998331069946,
        "velocity_down_m_s": 0.5600000023841858,
        "acceleration_forward": -0.965062141418457,
        "acceleration_right": -3.2846992015838623,
        "acceleration_down": -16.508459091186523,
        "battery_id": 0,
        "battery_voltage": 13.146001815795898,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 2,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 37,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 6263,
        "udp_total": 135,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 106
      },
      {
        "id": 231,
        "datetime": "21/02/2023 02:04:40",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4781160>, <mavsdk.mission_raw.MissionItem object at 0xa477d298>, <mavsdk.mission_raw.MissionItem object at 0xa477d5e0>, <mavsdk.mission_raw.MissionItem object at 0xa477d418>]",
        "mission_pause": 0,
        "time_usec": 125360736,
        "odometry_pos_body": "PositionBody: [x_m: 20.237529754638672, y_m: 5.591419696807861, z_m: -3.2780604362487793]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 357.86,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066362,
        "alg_manual_gps_lon_start": 38.8900423,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066322,
        "alg_manual_gps_lon_next": 38.8898405,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 5.593540191650391,
        "alg_manual_y_now": 20.190587997436523,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "rotate 268.3256698047717",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.4]",
        "timestamp": 124486461,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5799999833106995,
        "current_climb_rate": -0.6321433186531067,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066452,
        "gps_raw_lon_deg": 38.8900991,
        "gps_raw_abs_alt": 80.32100677490234,
        "gps_raw_velocity": 0.1599999964237213,
        "position_lat_deg": 47.306645,
        "position_lon_deg": 38.8900987,
        "position_abs_alt": 82.99100494384766,
        "position_real_alt": 4.244000434875488,
        "heading_deg": 0.52,
        "odometry_pos_body_x": 20.249801635742188,
        "odometry_pos_body_y": 5.580078601837158,
        "odometry_pos_body_z": -3.2130839824676514,
        "odometry_vel_body_x": 0.12145628780126572,
        "odometry_vel_body_y": -0.10542203485965727,
        "odometry_vel_body_z": 0.5865337252616882,
        "odometry_ang_vel_body_roll": 0.03700430318713188,
        "odometry_ang_vel_body_pitch": 0.004924426320940256,
        "odometry_ang_vel_body_yaw": -0.115478053689003,
        "velocity_north_m_s": 0.25,
        "velocity_east_m_s": 0.2800000011920929,
        "velocity_down_m_s": 0.550000011920929,
        "acceleration_forward": 1.5236202478408811,
        "acceleration_right": 0.6906819343566895,
        "acceleration_down": -11.773052215576172,
        "battery_id": 0,
        "battery_voltage": 13.146001815795898,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 2,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 1,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 6272,
        "udp_total": 135,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 106
      },
      {
        "id": 232,
        "datetime": "21/02/2023 02:04:40",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4782c58>, <mavsdk.mission_raw.MissionItem object at 0xa4782490>, <mavsdk.mission_raw.MissionItem object at 0xa47829a0>, <mavsdk.mission_raw.MissionItem object at 0xa47825f8>]",
        "mission_pause": 0,
        "time_usec": 125560495,
        "odometry_pos_body": "PositionBody: [x_m: 20.257862091064453, y_m: 5.566112995147705, z_m: -3.156714916229248]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 357.86,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066362,
        "alg_manual_gps_lon_start": 38.8900423,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066322,
        "alg_manual_gps_lon_next": 38.8898405,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 5.593540191650391,
        "alg_manual_y_now": 20.190587997436523,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "rotate 268.3256698047717",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.4]",
        "timestamp": 124661302,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.6800000071525574,
        "current_climb_rate": -0.4514263868331909,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066454,
        "gps_raw_lon_deg": 38.8900991,
        "gps_raw_abs_alt": 80.1780014038086,
        "gps_raw_velocity": 0.17999999225139618,
        "position_lat_deg": 47.3066454,
        "position_lon_deg": 38.8900992,
        "position_abs_alt": 82.86600494384766,
        "position_real_alt": 4.12000036239624,
        "heading_deg": 2.71,
        "odometry_pos_body_x": 20.262561798095703,
        "odometry_pos_body_y": 5.54897928237915,
        "odometry_pos_body_z": -3.1148202419281006,
        "odometry_vel_body_x": 0.038093116134405136,
        "odometry_vel_body_y": -0.16364561021327972,
        "odometry_vel_body_z": 0.2914999723434448,
        "odometry_ang_vel_body_roll": 0.6006456017494202,
        "odometry_ang_vel_body_pitch": -0.08862390369176865,
        "odometry_ang_vel_body_yaw": -0.15417338907718658,
        "velocity_north_m_s": 0.25999999046325684,
        "velocity_east_m_s": 0.20999999344348907,
        "velocity_down_m_s": 0.5799999833106995,
        "acceleration_forward": -2.186777114868164,
        "acceleration_right": -1.6478304862976074,
        "acceleration_down": -9.154494285583496,
        "battery_id": 0,
        "battery_voltage": 13.146001815795898,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 2,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 1,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 6294,
        "udp_total": 135,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 106
      },
      {
        "id": 233,
        "datetime": "21/02/2023 02:04:40",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4778358>, <mavsdk.mission_raw.MissionItem object at 0xa4781f70>, <mavsdk.mission_raw.MissionItem object at 0xa4781b80>, <mavsdk.mission_raw.MissionItem object at 0xa4781238>]",
        "mission_pause": 0,
        "time_usec": 125800280,
        "odometry_pos_body": "PositionBody: [x_m: 20.265947341918945, y_m: 5.523716926574707, z_m: -3.0754780769348145]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 357.86,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066362,
        "alg_manual_gps_lon_start": 38.8900423,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066322,
        "alg_manual_gps_lon_next": 38.8898405,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 5.593540191650391,
        "alg_manual_y_now": 20.190587997436523,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "rotate 268.3256698047717",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.4]",
        "timestamp": 124921070,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5399999618530273,
        "current_climb_rate": -0.04163185879588127,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066458,
        "gps_raw_lon_deg": 38.8900984,
        "gps_raw_abs_alt": 80.03800201416016,
        "gps_raw_velocity": 0.23999999463558197,
        "position_lat_deg": 47.3066458,
        "position_lon_deg": 38.8900997,
        "position_abs_alt": 82.69900512695312,
        "position_real_alt": 3.939000129699707,
        "heading_deg": 5.89,
        "odometry_pos_body_x": 20.26595115661621,
        "odometry_pos_body_y": 5.507574081420898,
        "odometry_pos_body_z": -3.0604288578033447,
        "odometry_vel_body_x": -0.006200969219207764,
        "odometry_vel_body_y": -0.1943586766719818,
        "odometry_vel_body_z": 0.021581122651696205,
        "odometry_ang_vel_body_roll": 0.7173675894737244,
        "odometry_ang_vel_body_pitch": 0.19666318595409393,
        "odometry_ang_vel_body_yaw": -0.2459164410829544,
        "velocity_north_m_s": 0.25999999046325684,
        "velocity_east_m_s": 0.12999999523162842,
        "velocity_down_m_s": 0.5799999833106995,
        "acceleration_forward": 0.7079936861991882,
        "acceleration_right": 1.6736427545547483,
        "acceleration_down": -5.273925304412842,
        "battery_id": 0,
        "battery_voltage": 13.146001815795898,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 2,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 6318,
        "udp_total": 135,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 106
      },
      {
        "id": 234,
        "datetime": "21/02/2023 02:04:40",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477ca90>, <mavsdk.mission_raw.MissionItem object at 0xa477c148>, <mavsdk.mission_raw.MissionItem object at 0xa477cc28>, <mavsdk.mission_raw.MissionItem object at 0xa477ce98>]",
        "mission_pause": 0,
        "time_usec": 126129987,
        "odometry_pos_body": "PositionBody: [x_m: 20.251426696777344, y_m: 5.455669403076172, z_m: -3.0133111476898193]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 357.86,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066362,
        "alg_manual_gps_lon_start": 38.8900423,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066322,
        "alg_manual_gps_lon_next": 38.8898405,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 5.593540191650391,
        "alg_manual_y_now": 20.190587997436523,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "rotate 268.3256698047717",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.4]",
        "timestamp": 125340694,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.550000011920929,
        "current_climb_rate": -0.048655759543180466,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066458,
        "gps_raw_lon_deg": 38.8900977,
        "gps_raw_abs_alt": 80.0320053100586,
        "gps_raw_velocity": 0.25,
        "position_lat_deg": 47.3066465,
        "position_lon_deg": 38.8900996,
        "position_abs_alt": 82.38300323486328,
        "position_real_alt": 3.63700008392334,
        "heading_deg": 5.36,
        "odometry_pos_body_x": 20.2359561920166,
        "odometry_pos_body_y": 5.431487083435059,
        "odometry_pos_body_z": -2.985976457595825,
        "odometry_vel_body_x": -0.10312488675117493,
        "odometry_vel_body_y": -0.0983380526304245,
        "odometry_vel_body_z": 0.06710811704397202,
        "odometry_ang_vel_body_roll": -0.14299626648426056,
        "odometry_ang_vel_body_pitch": -0.04081970825791359,
        "odometry_ang_vel_body_yaw": -0.19793282449245453,
        "velocity_north_m_s": 0.1599999964237213,
        "velocity_east_m_s": -0.05999999865889549,
        "velocity_down_m_s": 0.5600000023841858,
        "acceleration_forward": -0.46501925587654114,
        "acceleration_right": 2.0295395851135254,
        "acceleration_down": -10.611836433410645,
        "battery_id": 0,
        "battery_voltage": 13.146001815795898,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 2,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 22,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 6340,
        "udp_total": 136,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 107
      },
      {
        "id": 235,
        "datetime": "21/02/2023 02:04:41",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4781328>, <mavsdk.mission_raw.MissionItem object at 0xa4781760>, <mavsdk.mission_raw.MissionItem object at 0xa47817f0>, <mavsdk.mission_raw.MissionItem object at 0xa4781c58>]",
        "mission_pause": 0,
        "time_usec": 126459689,
        "odometry_pos_body": "PositionBody: [x_m: 20.20454978942871, y_m: 5.401663780212402, z_m: -2.948127269744873]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 357.86,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066362,
        "alg_manual_gps_lon_start": 38.8900423,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066322,
        "alg_manual_gps_lon_next": 38.8898405,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 5.593540191650391,
        "alg_manual_y_now": 20.190587997436523,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "rotate 268.3256698047717",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.4]",
        "timestamp": 125665404,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5600000023841858,
        "current_climb_rate": -0.02416679821908474,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306645499999995,
        "gps_raw_lon_deg": 38.8900967,
        "gps_raw_abs_alt": 80.01700592041016,
        "gps_raw_velocity": 0.19999998807907104,
        "position_lat_deg": 47.3066466,
        "position_lon_deg": 38.8900991,
        "position_abs_alt": 82.27200317382812,
        "position_real_alt": 3.5210001468658447,
        "heading_deg": 1.54,
        "odometry_pos_body_x": 20.184730529785156,
        "odometry_pos_body_y": 5.387472152709961,
        "odometry_pos_body_z": -2.9339675903320312,
        "odometry_vel_body_x": -0.1930861324071884,
        "odometry_vel_body_y": -0.058884184807538986,
        "odometry_vel_body_z": -0.010342905297875404,
        "odometry_ang_vel_body_roll": -0.49268290400505066,
        "odometry_ang_vel_body_pitch": 0.0010606542928144336,
        "odometry_ang_vel_body_yaw": -0.13256458938121796,
        "velocity_north_m_s": 0.03999999910593033,
        "velocity_east_m_s": -0.17000000178813934,
        "velocity_down_m_s": 0.14000000059604645,
        "acceleration_forward": 1.2639601230621338,
        "acceleration_right": -4.046446323394775,
        "acceleration_down": -11.341890335083008,
        "battery_id": 0,
        "battery_voltage": 13.146001815795898,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 2,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 24,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 6340,
        "udp_total": 136,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 107
      },
      {
        "id": 236,
        "datetime": "21/02/2023 02:04:41",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477d418>, <mavsdk.mission_raw.MissionItem object at 0xa477d3e8>, <mavsdk.mission_raw.MissionItem object at 0xa477dad8>, <mavsdk.mission_raw.MissionItem object at 0xa477dd18>]",
        "mission_pause": 0,
        "time_usec": 126729448,
        "odometry_pos_body": "PositionBody: [x_m: 20.141847610473633, y_m: 5.36469841003418, z_m: -2.917311191558838]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 357.86,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066362,
        "alg_manual_gps_lon_start": 38.8900423,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066322,
        "alg_manual_gps_lon_next": 38.8898405,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 5.593540191650391,
        "alg_manual_y_now": 20.190587997436523,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "rotate 268.3256698047717",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.4]",
        "timestamp": 125980122,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5699999928474426,
        "current_climb_rate": 0.015733925625681877,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066451,
        "gps_raw_lon_deg": 38.8900964,
        "gps_raw_abs_alt": 80.01700592041016,
        "gps_raw_velocity": 0.25999999046325684,
        "position_lat_deg": 47.3066465,
        "position_lon_deg": 38.8900982,
        "position_abs_alt": 82.20600128173828,
        "position_real_alt": 3.460000276565552,
        "heading_deg": 357.46,
        "odometry_pos_body_x": 20.10266494750977,
        "odometry_pos_body_y": 5.3453826904296875,
        "odometry_pos_body_z": -2.9029738903045654,
        "odometry_vel_body_x": -0.2683182954788208,
        "odometry_vel_body_y": -0.10572805255651474,
        "odometry_vel_body_z": -0.0005878059309907258,
        "odometry_ang_vel_body_roll": -0.33473581075668335,
        "odometry_ang_vel_body_pitch": -0.3240257799625397,
        "odometry_ang_vel_body_yaw": -0.1181458979845047,
        "velocity_north_m_s": -0.03999999910593033,
        "velocity_east_m_s": -0.12999999523162842,
        "velocity_down_m_s": 0.009999999776482582,
        "acceleration_forward": 0.3215337097644806,
        "acceleration_right": 4.038546562194824,
        "acceleration_down": -13.047198295593262,
        "battery_id": 0,
        "battery_voltage": 13.146001815795898,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 2,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 6364,
        "udp_total": 136,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 107
      },
      {
        "id": 237,
        "datetime": "21/02/2023 02:04:41",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477a7f0>, <mavsdk.mission_raw.MissionItem object at 0xa4781028>, <mavsdk.mission_raw.MissionItem object at 0xa4781d18>, <mavsdk.mission_raw.MissionItem object at 0xa4781868>]",
        "mission_pause": 0,
        "time_usec": 126999204,
        "odometry_pos_body": "PositionBody: [x_m: 20.052753448486328, y_m: 5.320553302764893, z_m: -2.889467716217041]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 357.86,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066362,
        "alg_manual_gps_lon_start": 38.8900423,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066322,
        "alg_manual_gps_lon_next": 38.8898405,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 5.593540191650391,
        "alg_manual_y_now": 20.190587997436523,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "rotate 268.3256698047717",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.4]",
        "timestamp": 126324813,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5899999737739563,
        "current_climb_rate": -0.03152807801961899,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306644,
        "gps_raw_lon_deg": 38.8900958,
        "gps_raw_abs_alt": 80.04200744628906,
        "gps_raw_velocity": 0.32999998331069946,
        "position_lat_deg": 47.306646099999995,
        "position_lon_deg": 38.8900973,
        "position_abs_alt": 82.13300323486328,
        "position_real_alt": 3.384000062942505,
        "heading_deg": 353.71,
        "odometry_pos_body_x": 19.97745704650879,
        "odometry_pos_body_y": 5.2760186195373535,
        "odometry_pos_body_z": -2.8776257038116455,
        "odometry_vel_body_x": -0.30606842041015625,
        "odometry_vel_body_y": -0.25140905380249023,
        "odometry_vel_body_z": 0.010674861259758472,
        "odometry_ang_vel_body_roll": 1.097112774848938,
        "odometry_ang_vel_body_pitch": -0.4439762830734253,
        "odometry_ang_vel_body_yaw": -0.1955195516347885,
        "velocity_north_m_s": -0.17000000178813934,
        "velocity_east_m_s": -0.03999999910593033,
        "velocity_down_m_s": 0.019999999552965164,
        "acceleration_forward": -0.4653718471527099,
        "acceleration_right": 0.33727172017097473,
        "acceleration_down": -10.66953182220459,
        "battery_id": 0,
        "battery_voltage": 13.10099983215332,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 2,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 6386,
        "udp_total": 137,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 108
      },
      {
        "id": 238,
        "datetime": "21/02/2023 02:04:42",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa59132b0>, <mavsdk.mission_raw.MissionItem object at 0xa5913d00>, <mavsdk.mission_raw.MissionItem object at 0xa4782d30>, <mavsdk.mission_raw.MissionItem object at 0xa4782bc8>]",
        "mission_pause": 0,
        "time_usec": 127328911,
        "odometry_pos_body": "PositionBody: [x_m: 19.9151668548584, y_m: 5.228363513946533, z_m: -2.87925124168396]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 357.86,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066362,
        "alg_manual_gps_lon_start": 38.8900423,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066322,
        "alg_manual_gps_lon_next": 38.8898405,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 5.593540191650391,
        "alg_manual_y_now": 20.190587997436523,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "rotate 268.3256698047717",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.4]",
        "timestamp": 126644522,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5899999737739563,
        "current_climb_rate": 0.01049132738262415,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066434,
        "gps_raw_lon_deg": 38.8900945,
        "gps_raw_abs_alt": 80.04100036621094,
        "gps_raw_velocity": 0.41999998688697815,
        "position_lat_deg": 47.3066454,
        "position_lon_deg": 38.890096799999995,
        "position_abs_alt": 82.09700012207031,
        "position_real_alt": 3.3510000705718994,
        "heading_deg": 351.2,
        "odometry_pos_body_x": 19.87177467346191,
        "odometry_pos_body_y": 5.178610324859619,
        "odometry_pos_body_z": -2.8910443782806396,
        "odometry_vel_body_x": -0.19054895639419556,
        "odometry_vel_body_y": -0.3430961072444916,
        "odometry_vel_body_z": -0.04132435843348503,
        "odometry_ang_vel_body_roll": 0.2892349362373352,
        "odometry_ang_vel_body_pitch": -0.1999646872282028,
        "odometry_ang_vel_body_yaw": -0.27830180525779724,
        "velocity_north_m_s": -0.25999999046325684,
        "velocity_east_m_s": -0.03999999910593033,
        "velocity_down_m_s": 0,
        "acceleration_forward": -1.138263702392578,
        "acceleration_right": -0.9980166554450988,
        "acceleration_down": -7.5256266593933105,
        "battery_id": 0,
        "battery_voltage": 13.10099983215332,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 2,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 26,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 6410,
        "udp_total": 137,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 108
      },
      {
        "id": 239,
        "datetime": "21/02/2023 02:04:42",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4780c88>, <mavsdk.mission_raw.MissionItem object at 0xa4780238>, <mavsdk.mission_raw.MissionItem object at 0xa4780e98>, <mavsdk.mission_raw.MissionItem object at 0xa4780bf8>]",
        "mission_pause": 0,
        "time_usec": 127668604,
        "odometry_pos_body": "PositionBody: [x_m: 19.824020385742188, y_m: 5.134520530700684, z_m: -2.9072062969207764]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 357.86,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066362,
        "alg_manual_gps_lon_start": 38.8900423,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066322,
        "alg_manual_gps_lon_next": 38.8898405,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 5.593540191650391,
        "alg_manual_y_now": 20.190587997436523,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "rotate 268.3256698047717",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.4]",
        "timestamp": 127024182,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5699999928474426,
        "current_climb_rate": 0.09981352090835573,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066423,
        "gps_raw_lon_deg": 38.8900935,
        "gps_raw_abs_alt": 80.04100036621094,
        "gps_raw_velocity": 0.4599999785423279,
        "position_lat_deg": 47.3066444,
        "position_lon_deg": 38.890096,
        "position_abs_alt": 82.06500244140625,
        "position_real_alt": 3.319000244140625,
        "heading_deg": 347.53000000000003,
        "odometry_pos_body_x": 19.783632278442383,
        "odometry_pos_body_y": 5.08069372177124,
        "odometry_pos_body_z": -2.929657459259033,
        "odometry_vel_body_x": -0.046803805977106094,
        "odometry_vel_body_y": -0.305143803358078,
        "odometry_vel_body_z": -0.05604619160294533,
        "odometry_ang_vel_body_roll": -0.7398400902748108,
        "odometry_ang_vel_body_pitch": -0.1122296378016472,
        "odometry_ang_vel_body_yaw": -0.34744834899902344,
        "velocity_north_m_s": -0.3400000035762787,
        "velocity_east_m_s": -0.14999999105930328,
        "velocity_down_m_s": 0.009999999776482582,
        "acceleration_forward": 0.5523068904876709,
        "acceleration_right": -2.2376387119293213,
        "acceleration_down": -7.025916576385498,
        "battery_id": 0,
        "battery_voltage": 13.10099983215332,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 2,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 1,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 6433,
        "udp_total": 137,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 108
      },
      {
        "id": 240,
        "datetime": "21/02/2023 02:04:42",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4781b50>, <mavsdk.mission_raw.MissionItem object at 0xa4781f88>, <mavsdk.mission_raw.MissionItem object at 0xa4781f10>, <mavsdk.mission_raw.MissionItem object at 0xa47811c0>]",
        "mission_pause": 0,
        "time_usec": 127998313,
        "odometry_pos_body": "PositionBody: [x_m: 19.75489044189453, y_m: 5.031970977783203, z_m: -2.9449105262756348]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 357.86,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066362,
        "alg_manual_gps_lon_start": 38.8900423,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066322,
        "alg_manual_gps_lon_next": 38.8898405,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 5.593540191650391,
        "alg_manual_y_now": 20.190587997436523,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "rotate 268.3256698047717",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.4]",
        "timestamp": 127328911,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5699999928474426,
        "current_climb_rate": 0.08507488667964935,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066417,
        "gps_raw_lon_deg": 38.8900919,
        "gps_raw_abs_alt": 80.09000396728516,
        "gps_raw_velocity": 0.3199999928474426,
        "position_lat_deg": 47.3066435,
        "position_lon_deg": 38.890094999999995,
        "position_abs_alt": 82.06200408935547,
        "position_real_alt": 3.315000057220459,
        "heading_deg": 342.91,
        "odometry_pos_body_x": 19.7469482421875,
        "odometry_pos_body_y": 5.015194416046143,
        "odometry_pos_body_z": -2.9509854316711426,
        "odometry_vel_body_x": 0.04791051149368286,
        "odometry_vel_body_y": -0.21071797609329224,
        "odometry_vel_body_z": -0.04261330887675285,
        "odometry_ang_vel_body_roll": 0.34726691246032715,
        "odometry_ang_vel_body_pitch": -0.16675615310668945,
        "odometry_ang_vel_body_yaw": -0.3764885365962982,
        "velocity_north_m_s": -0.29999998211860657,
        "velocity_east_m_s": -0.25,
        "velocity_down_m_s": -0.04999999701976776,
        "acceleration_forward": -0.016365494579076767,
        "acceleration_right": 3.7819395065307617,
        "acceleration_down": -13.54757308959961,
        "battery_id": 0,
        "battery_voltage": 13.10099983215332,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 2,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 3,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 6458,
        "udp_total": 138,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 109
      },
      {
        "id": 241,
        "datetime": "21/02/2023 02:04:43",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4782040>, <mavsdk.mission_raw.MissionItem object at 0xa47820a0>, <mavsdk.mission_raw.MissionItem object at 0xa4782e98>, <mavsdk.mission_raw.MissionItem object at 0xa4782670>]",
        "mission_pause": 0,
        "time_usec": 128392956,
        "odometry_pos_body": "PositionBody: [x_m: 19.721752166748047, y_m: 4.932969093322754, z_m: -2.9718737602233887]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 357.86,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066362,
        "alg_manual_gps_lon_start": 38.8900423,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066322,
        "alg_manual_gps_lon_next": 38.8898405,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 5.593540191650391,
        "alg_manual_y_now": 20.190587997436523,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "rotate 268.3256698047717",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.4]",
        "timestamp": 127603663,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5600000023841858,
        "current_climb_rate": 0.08624547719955444,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066417,
        "gps_raw_lon_deg": 38.8900906,
        "gps_raw_abs_alt": 80.14000701904297,
        "gps_raw_velocity": 0.25,
        "position_lat_deg": 47.3066426,
        "position_lon_deg": 38.8900937,
        "position_abs_alt": 82.08900451660156,
        "position_real_alt": 3.3430001735687256,
        "heading_deg": 336.77,
        "odometry_pos_body_x": 19.719655990600582,
        "odometry_pos_body_y": 4.899367809295654,
        "odometry_pos_body_z": -2.9863088130950928,
        "odometry_vel_body_x": 0.09110525995492937,
        "odometry_vel_body_y": -0.053507834672927856,
        "odometry_vel_body_z": -0.07845842093229294,
        "odometry_ang_vel_body_roll": 1.2577120065689087,
        "odometry_ang_vel_body_pitch": -0.02334200032055378,
        "odometry_ang_vel_body_yaw": -0.38215214014053345,
        "velocity_north_m_s": -0.2299999892711639,
        "velocity_east_m_s": -0.25999999046325684,
        "velocity_down_m_s": -0.07000000029802322,
        "acceleration_forward": -0.4731079936027527,
        "acceleration_right": 1.0486072301864624,
        "acceleration_down": -8.7770414352417,
        "battery_id": 0,
        "battery_voltage": 13.10099983215332,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 2,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 6480,
        "udp_total": 138,
        "arp_total": 6,
        "icmp_total": 1,
        "other_total": 109
      },
      {
        "id": 242,
        "datetime": "21/02/2023 02:04:43",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xb0ed14f0>, <mavsdk.mission_raw.MissionItem object at 0xa476c298>, <mavsdk.mission_raw.MissionItem object at 0xa4780130>, <mavsdk.mission_raw.MissionItem object at 0xa4780a00>]",
        "mission_pause": 0,
        "time_usec": 128832564,
        "odometry_pos_body": "PositionBody: [x_m: 19.737586975097656, y_m: 4.864910125732422, z_m: -3.0012309551239014]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 357.86,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066362,
        "alg_manual_gps_lon_start": 38.8900423,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066322,
        "alg_manual_gps_lon_next": 38.8898405,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 5.593540191650391,
        "alg_manual_y_now": 20.190587997436523,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "rotate 268.3256698047717",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.4]",
        "timestamp": 127838454,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5699999928474426,
        "current_climb_rate": 0.02432764694094658,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306641899999995,
        "gps_raw_lon_deg": 38.8900899,
        "gps_raw_abs_alt": 80.14300537109375,
        "gps_raw_velocity": 0.12999999523162842,
        "position_lat_deg": 47.3066422,
        "position_lon_deg": 38.8900929,
        "position_abs_alt": 82.11900329589844,
        "position_real_alt": 3.373000144958496,
        "heading_deg": 332.32,
        "odometry_pos_body_x": 19.744857788085938,
        "odometry_pos_body_y": 4.857603073120117,
        "odometry_pos_body_z": -3.0046050548553467,
        "odometry_vel_body_x": 0.07692337781190872,
        "odometry_vel_body_y": 0.11286497861146928,
        "odometry_vel_body_z": -0.058072529733181,
        "odometry_ang_vel_body_roll": 0.2825741171836853,
        "odometry_ang_vel_body_pitch": -0.03083137795329094,
        "odometry_ang_vel_body_yaw": -0.3379122018814087,
        "velocity_north_m_s": -0.12999999523162842,
        "velocity_east_m_s": -0.23999999463558197,
        "velocity_down_m_s": -0.07000000029802322,
        "acceleration_forward": 0.9814573526382446,
        "acceleration_right": 0.9372236728668212,
        "acceleration_down": -8.233000755310059,
        "battery_id": 0,
        "battery_voltage": 13.10099983215332,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 2,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 26,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 6504,
        "udp_total": 138,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 109
      },
      {
        "id": 243,
        "datetime": "21/02/2023 02:04:43",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4777100>, <mavsdk.mission_raw.MissionItem object at 0xa4777dc0>, <mavsdk.mission_raw.MissionItem object at 0xa4775640>, <mavsdk.mission_raw.MissionItem object at 0xa4775760>]",
        "mission_pause": 0,
        "time_usec": 129092333,
        "odometry_pos_body": "PositionBody: [x_m: 19.77190589904785, y_m: 4.851719856262207, z_m: -3.0137102603912354]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 357.86,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066362,
        "alg_manual_gps_lon_start": 38.8900423,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066322,
        "alg_manual_gps_lon_next": 38.8898405,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 5.593540191650391,
        "alg_manual_y_now": 20.190587997436523,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "rotate 268.3256698047717",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.4]",
        "timestamp": 128058256,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5600000023841858,
        "current_climb_rate": 0.0686400830745697,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306642,
        "gps_raw_lon_deg": 38.8900897,
        "gps_raw_abs_alt": 80.15400695800781,
        "gps_raw_velocity": 0.119999997317791,
        "position_lat_deg": 47.306641899999995,
        "position_lon_deg": 38.8900919,
        "position_abs_alt": 82.13600158691406,
        "position_real_alt": 3.390000104904175,
        "heading_deg": 326.83,
        "odometry_pos_body_x": 19.79585838317871,
        "odometry_pos_body_y": 4.8450541496276855,
        "odometry_pos_body_z": -3.0314290523529053,
        "odometry_vel_body_x": 0.06065217778086662,
        "odometry_vel_body_y": 0.18124863505363464,
        "odometry_vel_body_z": -0.06446623057126999,
        "odometry_ang_vel_body_roll": -0.07160518318414688,
        "odometry_ang_vel_body_pitch": -0.0537431538105011,
        "odometry_ang_vel_body_yaw": -0.3555772602558136,
        "velocity_north_m_s": -0.029999999329447743,
        "velocity_east_m_s": -0.1899999976158142,
        "velocity_down_m_s": -0.04999999701976776,
        "acceleration_forward": 0.9395901560783386,
        "acceleration_right": 0.7725764513015747,
        "acceleration_down": -11.477340698242188,
        "battery_id": 0,
        "battery_voltage": 13.053001403808594,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 2,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 1,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 6526,
        "udp_total": 139,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 110
      },
      {
        "id": 244,
        "datetime": "21/02/2023 02:04:44",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477ecb8>, <mavsdk.mission_raw.MissionItem object at 0xa477ec70>, <mavsdk.mission_raw.MissionItem object at 0xa477e6a0>, <mavsdk.mission_raw.MissionItem object at 0xa477e0d0>]",
        "mission_pause": 0,
        "time_usec": 129462001,
        "odometry_pos_body": "PositionBody: [x_m: 19.831525802612305, y_m: 4.850182056427002, z_m: -3.0474095344543457]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 357.86,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066362,
        "alg_manual_gps_lon_start": 38.8900423,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066322,
        "alg_manual_gps_lon_next": 38.8898405,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 5.593540191650391,
        "alg_manual_y_now": 20.190587997436523,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "rotate 268.3256698047717",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.4]",
        "timestamp": 128328015,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5600000023841858,
        "current_climb_rate": 0.051377493888139725,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066427,
        "gps_raw_lon_deg": 38.8900899,
        "gps_raw_abs_alt": 80.21900177001953,
        "gps_raw_velocity": 0.1899999976158142,
        "position_lat_deg": 47.3066417,
        "position_lon_deg": 38.8900909,
        "position_abs_alt": 82.1570053100586,
        "position_real_alt": 3.4110002517700195,
        "heading_deg": 318.73,
        "odometry_pos_body_x": 19.87044906616211,
        "odometry_pos_body_y": 4.868729591369629,
        "odometry_pos_body_z": -3.062973976135254,
        "odometry_vel_body_x": -0.06372547149658203,
        "odometry_vel_body_y": 0.22109998762607577,
        "odometry_vel_body_z": -0.007450444623827934,
        "odometry_ang_vel_body_roll": -0.05904354527592659,
        "odometry_ang_vel_body_pitch": 0.2114354223012924,
        "odometry_ang_vel_body_yaw": -0.3424142599105835,
        "velocity_north_m_s": 0.019999999552965164,
        "velocity_east_m_s": -0.09999999403953552,
        "velocity_down_m_s": -0.07000000029802322,
        "acceleration_forward": 0.33075323700904846,
        "acceleration_right": 1.572292447090149,
        "acceleration_down": -8.450176239013672,
        "battery_id": 0,
        "battery_voltage": 13.053001403808594,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 2,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 8,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 6558,
        "udp_total": 139,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 110
      },
      {
        "id": 245,
        "datetime": "21/02/2023 02:04:44",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa591efb8>, <mavsdk.mission_raw.MissionItem object at 0xa4782e50>, <mavsdk.mission_raw.MissionItem object at 0xa47819a0>, <mavsdk.mission_raw.MissionItem object at 0xa4781be0>]",
        "mission_pause": 0,
        "time_usec": 129951679,
        "odometry_pos_body": "PositionBody: [x_m: 19.913808822631836, y_m: 4.903485298156738, z_m: -3.0594797134399414]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 357.86,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066362,
        "alg_manual_gps_lon_start": 38.8900423,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066322,
        "alg_manual_gps_lon_next": 38.8898405,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 5.593540191650391,
        "alg_manual_y_now": 20.190587997436523,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "rotate 268.3256698047717",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.4]",
        "timestamp": 128762634,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5799999833106995,
        "current_climb_rate": -0.035172320902347565,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066434,
        "gps_raw_lon_deg": 38.8900905,
        "gps_raw_abs_alt": 80.23300170898438,
        "gps_raw_velocity": 0.19999998807907104,
        "position_lat_deg": 47.306641899999995,
        "position_lon_deg": 38.8900902,
        "position_abs_alt": 82.18400573730469,
        "position_real_alt": 3.437000274658203,
        "heading_deg": 310.36,
        "odometry_pos_body_x": 19.943689346313477,
        "odometry_pos_body_y": 4.933640480041504,
        "odometry_pos_body_z": -3.0502705574035645,
        "odometry_vel_body_x": -0.1629953533411026,
        "odometry_vel_body_y": 0.2608726918697357,
        "odometry_vel_body_z": 0.03622537851333618,
        "odometry_ang_vel_body_roll": 0.3913222849369049,
        "odometry_ang_vel_body_pitch": -0.2828710377216339,
        "odometry_ang_vel_body_yaw": -0.3488161563873291,
        "velocity_north_m_s": 0.119999997317791,
        "velocity_east_m_s": 0.009999999776482582,
        "velocity_down_m_s": -0.029999999329447743,
        "acceleration_forward": 1.201553225517273,
        "acceleration_right": -3.574657678604126,
        "acceleration_down": -13.003069877624512,
        "battery_id": 0,
        "battery_voltage": 13.053001403808594,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 2,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 24,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 6572,
        "udp_total": 140,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 111
      },
      {
        "id": 246,
        "datetime": "21/02/2023 02:04:45",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477e310>, <mavsdk.mission_raw.MissionItem object at 0xa47773b8>, <mavsdk.mission_raw.MissionItem object at 0xa4781340>, <mavsdk.mission_raw.MissionItem object at 0xa4781fb8>]",
        "mission_pause": 0,
        "time_usec": 130276273,
        "odometry_pos_body": "PositionBody: [x_m: 19.97519874572754, y_m: 4.963701248168945, z_m: -3.0451650619506836]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 357.86,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066362,
        "alg_manual_gps_lon_start": 38.8900423,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066322,
        "alg_manual_gps_lon_next": 38.8898405,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 5.593540191650391,
        "alg_manual_y_now": 20.190587997436523,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "rotate 268.3256698047717",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.4]",
        "timestamp": 129117308,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5799999833106995,
        "current_climb_rate": -0.04452257230877876,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306644,
        "gps_raw_lon_deg": 38.8900915,
        "gps_raw_abs_alt": 80.22600555419922,
        "gps_raw_velocity": 0.3100000023841858,
        "position_lat_deg": 47.3066424,
        "position_lon_deg": 38.8900899,
        "position_abs_alt": 82.21100616455078,
        "position_real_alt": 3.46500015258789,
        "heading_deg": 302.61,
        "odometry_pos_body_x": 20.005342483520508,
        "odometry_pos_body_y": 4.998929977416992,
        "odometry_pos_body_z": -3.0423614978790283,
        "odometry_vel_body_x": -0.24727240204811096,
        "odometry_vel_body_y": 0.2315334379673004,
        "odometry_vel_body_z": 0.06692443788051605,
        "odometry_ang_vel_body_roll": -0.4879341423511505,
        "odometry_ang_vel_body_pitch": -0.10585794597864152,
        "odometry_ang_vel_body_yaw": -0.48391327261924744,
        "velocity_north_m_s": 0.17999999225139618,
        "velocity_east_m_s": 0.07000000029802322,
        "velocity_down_m_s": -0.03999999910593033,
        "acceleration_forward": 0.9532890915870668,
        "acceleration_right": -0.7989134788513184,
        "acceleration_down": -4.360223293304443,
        "battery_id": 0,
        "battery_voltage": 13.053001403808594,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 2,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 6596,
        "udp_total": 140,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 111
      },
      {
        "id": 247,
        "datetime": "21/02/2023 02:04:45",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477c748>, <mavsdk.mission_raw.MissionItem object at 0xa477cd78>, <mavsdk.mission_raw.MissionItem object at 0xa4777610>, <mavsdk.mission_raw.MissionItem object at 0xa4782208>]",
        "mission_pause": 0,
        "time_usec": 130596015,
        "odometry_pos_body": "PositionBody: [x_m: 20.034652709960938, y_m: 5.042202472686768, z_m: -3.0330536365509033]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 357.86,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066362,
        "alg_manual_gps_lon_start": 38.8900423,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066322,
        "alg_manual_gps_lon_next": 38.8898405,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 5.593540191650391,
        "alg_manual_y_now": 20.190587997436523,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "rotate 268.3256698047717",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.4]",
        "timestamp": 129457005,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5999999642372131,
        "current_climb_rate": -0.07189138978719711,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066445,
        "gps_raw_lon_deg": 38.8900928,
        "gps_raw_abs_alt": 80.21700286865234,
        "gps_raw_velocity": 0.29999998211860657,
        "position_lat_deg": 47.3066428,
        "position_lon_deg": 38.89009,
        "position_abs_alt": 82.23300170898438,
        "position_real_alt": 3.4870002269744873,
        "heading_deg": 297.23,
        "odometry_pos_body_x": 20.0504207611084,
        "odometry_pos_body_y": 5.070512771606445,
        "odometry_pos_body_z": -3.025779962539673,
        "odometry_vel_body_x": -0.30960962176322937,
        "odometry_vel_body_y": 0.1963644772768021,
        "odometry_vel_body_z": 0.0435660220682621,
        "odometry_ang_vel_body_roll": 0.934804379940033,
        "odometry_ang_vel_body_pitch": 0.1496003270149231,
        "odometry_ang_vel_body_yaw": -0.28870686888694763,
        "velocity_north_m_s": 0.17000000178813934,
        "velocity_east_m_s": 0.12999999523162842,
        "velocity_down_m_s": -0.03999999910593033,
        "acceleration_forward": 0.047086603939533234,
        "acceleration_right": -4.707501411437988,
        "acceleration_down": -6.307771682739258,
        "battery_id": 0,
        "battery_voltage": 13.053001403808594,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 2,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 1,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 6618,
        "udp_total": 140,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 111
      },
      {
        "id": 248,
        "datetime": "21/02/2023 02:04:45",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4775640>, <mavsdk.mission_raw.MissionItem object at 0xa477b0b8>, <mavsdk.mission_raw.MissionItem object at 0xa477bce8>, <mavsdk.mission_raw.MissionItem object at 0xa477b088>]",
        "mission_pause": 0,
        "time_usec": 130825782,
        "odometry_pos_body": "PositionBody: [x_m: 20.073383331298828, y_m: 5.105118751525879, z_m: -3.016772985458374]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 357.86,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066362,
        "alg_manual_gps_lon_start": 38.8900423,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066322,
        "alg_manual_gps_lon_next": 38.8898405,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 5.593540191650391,
        "alg_manual_y_now": 20.190587997436523,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "rotate 268.3256698047717",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.4]",
        "timestamp": 129751742,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5899999737739563,
        "current_climb_rate": -0.03144952654838562,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066448,
        "gps_raw_lon_deg": 38.8900937,
        "gps_raw_abs_alt": 80.21300506591797,
        "gps_raw_velocity": 0.3499999940395355,
        "position_lat_deg": 47.3066432,
        "position_lon_deg": 38.8900905,
        "position_abs_alt": 82.24600219726562,
        "position_real_alt": 3.500000238418579,
        "heading_deg": 289.93,
        "odometry_pos_body_x": 20.09003448486328,
        "odometry_pos_body_y": 5.133219242095947,
        "odometry_pos_body_z": -3.0106258392333984,
        "odometry_vel_body_x": -0.30725720524787903,
        "odometry_vel_body_y": 0.1651310920715332,
        "odometry_vel_body_z": 0.01553754135966301,
        "odometry_ang_vel_body_roll": 0.5301961898803711,
        "odometry_ang_vel_body_pitch": 0.02378128096461296,
        "odometry_ang_vel_body_yaw": -0.20390833914279935,
        "velocity_north_m_s": 0.14999999105930328,
        "velocity_east_m_s": 0.17999999225139618,
        "velocity_down_m_s": 0.03999999910593033,
        "acceleration_forward": 1.789900779724121,
        "acceleration_right": -2.7492518424987793,
        "acceleration_down": -9.572282791137695,
        "battery_id": 0,
        "battery_voltage": 13.053001403808594,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 2,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 14,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 6632,
        "udp_total": 140,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 111
      },
      {
        "id": 249,
        "datetime": "21/02/2023 02:04:45",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4782670>, <mavsdk.mission_raw.MissionItem object at 0xa4782ce8>, <mavsdk.mission_raw.MissionItem object at 0xa4782928>, <mavsdk.mission_raw.MissionItem object at 0xa4782e98>]",
        "mission_pause": 0,
        "time_usec": 131065574,
        "odometry_pos_body": "PositionBody: [x_m: 20.109254837036133, y_m: 5.174509048461914, z_m: -3.009913921356201]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 357.86,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066362,
        "alg_manual_gps_lon_start": 38.8900423,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066322,
        "alg_manual_gps_lon_next": 38.8898405,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 5.593540191650391,
        "alg_manual_y_now": 20.190587997436523,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "rotate 268.3256698047717",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.4]",
        "timestamp": 130011509,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5799999833106995,
        "current_climb_rate": 0.04095436632633209,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306644899999995,
        "gps_raw_lon_deg": 38.8900943,
        "gps_raw_abs_alt": 80.2020034790039,
        "gps_raw_velocity": 0.25,
        "position_lat_deg": 47.306643699999995,
        "position_lon_deg": 38.8900911,
        "position_abs_alt": 82.23300170898438,
        "position_real_alt": 3.486000061035156,
        "heading_deg": 281.81,
        "odometry_pos_body_x": 20.118532180786133,
        "odometry_pos_body_y": 5.207694053649902,
        "odometry_pos_body_z": -3.0114424228668213,
        "odometry_vel_body_x": -0.2873268723487854,
        "odometry_vel_body_y": 0.0668405294418335,
        "odometry_vel_body_z": -0.055465810000896454,
        "odometry_ang_vel_body_roll": -0.10205430537462234,
        "odometry_ang_vel_body_pitch": -0.083808533847332,
        "odometry_ang_vel_body_yaw": -0.24086247384548187,
        "velocity_north_m_s": 0.17999999225139618,
        "velocity_east_m_s": 0.2299999892711639,
        "velocity_down_m_s": 0.05999999865889549,
        "acceleration_forward": 0.3171018660068512,
        "acceleration_right": 2.033923625946045,
        "acceleration_down": -9.65131950378418,
        "battery_id": 0,
        "battery_voltage": 13.011001586914062,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 2,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 3,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 6644,
        "udp_total": 141,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 112
      },
      {
        "id": 250,
        "datetime": "21/02/2023 02:04:46",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa47809d0>, <mavsdk.mission_raw.MissionItem object at 0xa4780e80>, <mavsdk.mission_raw.MissionItem object at 0xa4780ac0>, <mavsdk.mission_raw.MissionItem object at 0xa47805c8>]",
        "mission_pause": 0,
        "time_usec": 131325337,
        "odometry_pos_body": "PositionBody: [x_m: 20.12407875061035, y_m: 5.242085933685303, z_m: -3.0125198364257812]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 357.86,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066362,
        "alg_manual_gps_lon_start": 38.8900423,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066322,
        "alg_manual_gps_lon_next": 38.8898405,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 5.593540191650391,
        "alg_manual_y_now": 20.190587997436523,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "rotate 268.3256698047717",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.12]",
        "timestamp": 130316238,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5699999928474426,
        "current_climb_rate": 0.08048499375581741,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066452,
        "gps_raw_lon_deg": 38.8900952,
        "gps_raw_abs_alt": 80.23100280761719,
        "gps_raw_velocity": 0.2299999892711639,
        "position_lat_deg": 47.306644299999995,
        "position_lon_deg": 38.890092,
        "position_abs_alt": 82.22400665283203,
        "position_real_alt": 3.4770002365112305,
        "heading_deg": 274.97,
        "odometry_pos_body_x": 20.125225067138672,
        "odometry_pos_body_y": 5.298811912536621,
        "odometry_pos_body_z": -3.0154128074645996,
        "odometry_vel_body_x": -0.2722054719924927,
        "odometry_vel_body_y": -0.06407041847705841,
        "odometry_vel_body_z": -0.08344053477048874,
        "odometry_ang_vel_body_roll": -0.33400216698646545,
        "odometry_ang_vel_body_pitch": -0.03493779897689819,
        "odometry_ang_vel_body_yaw": -0.14186543226242063,
        "velocity_north_m_s": 0.1599999964237213,
        "velocity_east_m_s": 0.28999999165534973,
        "velocity_down_m_s": 0.07999999821186066,
        "acceleration_forward": 2.1112842559814453,
        "acceleration_right": 0.7185866832733154,
        "acceleration_down": -13.27805519104004,
        "battery_id": 0,
        "battery_voltage": 13.011001586914062,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 2,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 3,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 6666,
        "udp_total": 141,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 112
      },
      {
        "id": 251,
        "datetime": "21/02/2023 02:04:46",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477e898>, <mavsdk.mission_raw.MissionItem object at 0xa477e8c8>, <mavsdk.mission_raw.MissionItem object at 0xa4782a60>, <mavsdk.mission_raw.MissionItem object at 0xa4782328>]",
        "mission_pause": 0,
        "time_usec": 131675027,
        "odometry_pos_body": "PositionBody: [x_m: 20.117534637451172, y_m: 5.3370184898376465, z_m: -3.022211790084839]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 357.86,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066362,
        "alg_manual_gps_lon_start": 38.8900423,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066322,
        "alg_manual_gps_lon_next": 38.8898405,
        "alg_manual_x_start": 5.175624370574951,
        "alg_manual_y_start": 2.0388500690460205,
        "alg_manual_x_now": 5.593540191650391,
        "alg_manual_y_now": 20.190587997436523,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.895916011997187,
        "alg_manual_dist_to_point": 15,
        "alg_manual_angle_to_point": 6,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 356,
        "alg_manual_command": "rotate 268.3256698047717",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.12]",
        "timestamp": 130675917,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5699999928474426,
        "current_climb_rate": 0.07529027760028839,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306645,
        "gps_raw_lon_deg": 38.8900964,
        "gps_raw_abs_alt": 80.24800109863281,
        "gps_raw_velocity": 0.23999999463558197,
        "position_lat_deg": 47.3066447,
        "position_lon_deg": 38.8900929,
        "position_abs_alt": 82.20600128173828,
        "position_real_alt": 3.460000276565552,
        "heading_deg": 270.16,
        "odometry_pos_body_x": 20.112958908081055,
        "odometry_pos_body_y": 5.351014614105225,
        "odometry_pos_body_z": -3.026771068572998,
        "odometry_vel_body_x": -0.24134229123592377,
        "odometry_vel_body_y": -0.10000763833522797,
        "odometry_vel_body_z": -0.0833849385380745,
        "odometry_ang_vel_body_roll": 0.28222203254699707,
        "odometry_ang_vel_body_pitch": -0.23087982833385468,
        "odometry_ang_vel_body_yaw": 0.012790131382644176,
        "velocity_north_m_s": 0.17000000178813934,
        "velocity_east_m_s": 0.3199999928474426,
        "velocity_down_m_s": 0.04999999701976776,
        "acceleration_forward": 2.1271488666534424,
        "acceleration_right": -2.472622871398926,
        "acceleration_down": -4.546027183532715,
        "battery_id": 0,
        "battery_voltage": 13.011001586914062,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 2,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 1,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 6690,
        "udp_total": 141,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 112
      },
      {
        "id": 252,
        "datetime": "21/02/2023 02:04:46",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa5913430>, <mavsdk.mission_raw.MissionItem object at 0xa477c4d8>, <mavsdk.mission_raw.MissionItem object at 0xa477cdd8>, <mavsdk.mission_raw.MissionItem object at 0xa477caa8>]",
        "mission_pause": 0,
        "time_usec": 131964768,
        "odometry_pos_body": "PositionBody: [x_m: 20.091604232788086, y_m: 5.398502826690674, z_m: -3.036646604537964]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -6.221971476736826,
        "alg_manual_y": 17.00231082294338,
        "last_angle_position": 357.86,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066362,
        "alg_manual_gps_lon_start": 38.8900423,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066322,
        "alg_manual_gps_lon_next": 38.8898405,
        "alg_manual_x_start": 20.09160423278809,
        "alg_manual_y_start": 5.398502826690674,
        "alg_manual_x_now": 5.593540191650391,
        "alg_manual_y_now": 20.190587997436523,
        "alg_manual_azimut": 86,
        "alg_manual_zero_x": 1.986040802085407,
        "alg_manual_zero_y": 18.840497018646815,
        "alg_manual_dist_to_point": 23,
        "alg_manual_angle_to_point": 325,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 268,
        "alg_manual_command": "off",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.12]",
        "timestamp": 131010618,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5600000023841858,
        "current_climb_rate": 0.07323949038982391,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066447,
        "gps_raw_lon_deg": 38.8900969,
        "gps_raw_abs_alt": 80.27300262451172,
        "gps_raw_velocity": 0.20999999344348907,
        "position_lat_deg": 47.3066452,
        "position_lon_deg": 38.890094399999995,
        "position_abs_alt": 82.19300079345703,
        "position_real_alt": 3.44700026512146,
        "heading_deg": 265.01,
        "odometry_pos_body_x": 20.080276489257812,
        "odometry_pos_body_y": 5.414770603179932,
        "odometry_pos_body_z": -3.043128728866577,
        "odometry_vel_body_x": -0.17044498026371002,
        "odometry_vel_body_y": -0.11647742241621016,
        "odometry_vel_body_z": -0.07280474901199341,
        "odometry_ang_vel_body_roll": 0.5042257905006409,
        "odometry_ang_vel_body_pitch": -0.31506672501564026,
        "odometry_ang_vel_body_yaw": -0.032467398792505264,
        "velocity_north_m_s": 0.08999999612569809,
        "velocity_east_m_s": 0.2800000011920929,
        "velocity_down_m_s": -0.029999999329447743,
        "acceleration_forward": 1.095396637916565,
        "acceleration_right": 4.752268314361572,
        "acceleration_down": -10.089262008666992,
        "battery_id": 0,
        "battery_voltage": 13.011001586914062,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 2,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 1,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 6713,
        "udp_total": 142,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 113
      },
      {
        "id": 253,
        "datetime": "21/02/2023 02:04:46",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477e370>, <mavsdk.mission_raw.MissionItem object at 0xa47753e8>, <mavsdk.mission_raw.MissionItem object at 0xa4775e20>, <mavsdk.mission_raw.MissionItem object at 0xa590cb20>]",
        "mission_pause": 0,
        "time_usec": 132234525,
        "odometry_pos_body": "PositionBody: [x_m: 20.05820655822754, y_m: 5.436194896697998, z_m: -3.056962490081787]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -20.6150966833256,
        "alg_manual_y": -20.389365231977113,
        "last_angle_position": 357.86,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066362,
        "alg_manual_gps_lon_start": 38.8900423,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066322,
        "alg_manual_gps_lon_next": 38.8898405,
        "alg_manual_x_start": 20.09160423278809,
        "alg_manual_y_start": 5.398502826690674,
        "alg_manual_x_now": 5.593540191650391,
        "alg_manual_y_now": 20.190587997436523,
        "alg_manual_azimut": 88,
        "alg_manual_zero_x": -13.192258036074058,
        "alg_manual_zero_y": 18.840497018646815,
        "alg_manual_dist_to_point": 23,
        "alg_manual_angle_to_point": 325,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 268,
        "alg_manual_command": "off",
        "alg_manual_inputs": "[0.8, 0.0, 0.5, 0.0]",
        "timestamp": 131420251,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5699999928474426,
        "current_climb_rate": 0.03365027531981468,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066442,
        "gps_raw_lon_deg": 38.8900977,
        "gps_raw_abs_alt": 80.31700134277344,
        "gps_raw_velocity": 0.19999998807907104,
        "position_lat_deg": 47.3066453,
        "position_lon_deg": 38.890096,
        "position_abs_alt": 82.19800567626953,
        "position_real_alt": 3.452000141143799,
        "heading_deg": 262.42,
        "odometry_pos_body_x": 20.03648376464844,
        "odometry_pos_body_y": 5.449758529663086,
        "odometry_pos_body_z": -3.0691397190093994,
        "odometry_vel_body_x": -0.06725490838289261,
        "odometry_vel_body_y": -0.12285866588354112,
        "odometry_vel_body_z": -0.04535071551799774,
        "odometry_ang_vel_body_roll": 0.1503736525774002,
        "odometry_ang_vel_body_pitch": -0.2994384169578552,
        "odometry_ang_vel_body_yaw": 0.06872560828924179,
        "velocity_north_m_s": -0.03999999910593033,
        "velocity_east_m_s": 0.26999998092651367,
        "velocity_down_m_s": -0.05999999865889549,
        "acceleration_forward": -0.7836744785308838,
        "acceleration_right": -2.937717914581299,
        "acceleration_down": -7.289012908935547,
        "battery_id": 0,
        "battery_voltage": 13.011001586914062,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 2,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 26,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 6738,
        "udp_total": 142,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 113
      },
      {
        "id": 254,
        "datetime": "21/02/2023 02:04:47",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa5913d00>, <mavsdk.mission_raw.MissionItem object at 0xa477eaf0>, <mavsdk.mission_raw.MissionItem object at 0xa477e688>, <mavsdk.mission_raw.MissionItem object at 0xa477e2e0>]",
        "mission_pause": 0,
        "time_usec": 132564230,
        "odometry_pos_body": "PositionBody: [x_m: 20.01044273376465, y_m: 5.447097301483154, z_m: -3.076324462890625]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -20.6150966833256,
        "alg_manual_y": -20.389365231977113,
        "last_angle_position": 357.86,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066362,
        "alg_manual_gps_lon_start": 38.8900423,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066322,
        "alg_manual_gps_lon_next": 38.8898405,
        "alg_manual_x_start": 20.09160423278809,
        "alg_manual_y_start": 5.398502826690674,
        "alg_manual_x_now": 5.593540191650391,
        "alg_manual_y_now": 20.190587997436523,
        "alg_manual_azimut": 88,
        "alg_manual_zero_x": -13.192258036074058,
        "alg_manual_zero_y": 18.840497018646815,
        "alg_manual_dist_to_point": 23,
        "alg_manual_angle_to_point": 325,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 268,
        "alg_manual_command": "off",
        "alg_manual_inputs": "[0.8, 0.0, 0.5, 0.02]",
        "timestamp": 131739966,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5899999737739563,
        "current_climb_rate": 0.010480339638888836,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066441,
        "gps_raw_lon_deg": 38.890097999999995,
        "gps_raw_abs_alt": 80.33300018310547,
        "gps_raw_velocity": 0.10999999940395357,
        "position_lat_deg": 47.3066451,
        "position_lon_deg": 38.890097,
        "position_abs_alt": 82.21300506591797,
        "position_real_alt": 3.4670002460479736,
        "heading_deg": 261.68,
        "odometry_pos_body_x": 19.9921932220459,
        "odometry_pos_body_y": 5.428709030151367,
        "odometry_pos_body_z": -3.078878164291382,
        "odometry_vel_body_x": 0.28054243326187134,
        "odometry_vel_body_y": -0.16384202241897583,
        "odometry_vel_body_z": -0.09270883351564407,
        "odometry_ang_vel_body_roll": -0.7526512742042542,
        "odometry_ang_vel_body_pitch": -0.5675519704818726,
        "odometry_ang_vel_body_yaw": -0.07033569365739822,
        "velocity_north_m_s": -0.08999999612569809,
        "velocity_east_m_s": 0.2299999892711639,
        "velocity_down_m_s": -0.05999999865889549,
        "acceleration_forward": -1.107696294784546,
        "acceleration_right": 1.868241310119629,
        "acceleration_down": -8.765913009643555,
        "battery_id": 0,
        "battery_voltage": 13.011001586914062,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 2,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 26,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 6740,
        "udp_total": 142,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 113
      },
      {
        "id": 255,
        "datetime": "21/02/2023 02:04:47",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477adc0>, <mavsdk.mission_raw.MissionItem object at 0xa477c160>, <mavsdk.mission_raw.MissionItem object at 0xa477cd48>, <mavsdk.mission_raw.MissionItem object at 0xa477c598>]",
        "mission_pause": 0,
        "time_usec": 132833993,
        "odometry_pos_body": "PositionBody: [x_m: 19.941207885742188, y_m: 5.351541042327881, z_m: -3.091233730316162]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -20.6150966833256,
        "alg_manual_y": -20.389365231977113,
        "last_angle_position": 357.86,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066362,
        "alg_manual_gps_lon_start": 38.8900423,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066322,
        "alg_manual_gps_lon_next": 38.8898405,
        "alg_manual_x_start": 20.09160423278809,
        "alg_manual_y_start": 5.398502826690674,
        "alg_manual_x_now": 5.593540191650391,
        "alg_manual_y_now": 20.190587997436523,
        "alg_manual_azimut": 88,
        "alg_manual_zero_x": -13.192258036074058,
        "alg_manual_zero_y": 18.840497018646815,
        "alg_manual_dist_to_point": 23,
        "alg_manual_angle_to_point": 325,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 268,
        "alg_manual_command": "off",
        "alg_manual_inputs": "[0.8, 0.0, 0.5, 0.02]",
        "timestamp": 132059683,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5600000023841858,
        "current_climb_rate": 0.07268703728914261,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066433,
        "gps_raw_lon_deg": 38.8900965,
        "gps_raw_abs_alt": 80.3530044555664,
        "gps_raw_velocity": 0.4899999797344208,
        "position_lat_deg": 47.3066448,
        "position_lon_deg": 38.8900976,
        "position_abs_alt": 82.23100280761719,
        "position_real_alt": 3.4850001335144043,
        "heading_deg": 261.74,
        "odometry_pos_body_x": 19.90919303894043,
        "odometry_pos_body_y": 5.285599708557129,
        "odometry_pos_body_z": -3.100154161453247,
        "odometry_vel_body_x": 0.9548280239105223,
        "odometry_vel_body_y": -0.23051613569259644,
        "odometry_vel_body_z": -0.2693329453468323,
        "odometry_ang_vel_body_roll": 0.4806089401245117,
        "odometry_ang_vel_body_pitch": 0.4979568719863891,
        "odometry_ang_vel_body_yaw": 0.0005564143066294491,
        "velocity_north_m_s": -0.08999999612569809,
        "velocity_east_m_s": 0.14999999105930328,
        "velocity_down_m_s": -0.05999999865889549,
        "acceleration_forward": 1.631596326828003,
        "acceleration_right": 2.40712308883667,
        "acceleration_down": -10.187652587890623,
        "battery_id": 0,
        "battery_voltage": 13.011001586914062,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 2,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 48,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 6760,
        "udp_total": 142,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 113
      },
      {
        "id": 256,
        "datetime": "21/02/2023 02:04:47",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477bcb8>, <mavsdk.mission_raw.MissionItem object at 0xa4777418>, <mavsdk.mission_raw.MissionItem object at 0xa47771d8>, <mavsdk.mission_raw.MissionItem object at 0xa4782088>]",
        "mission_pause": 0,
        "time_usec": 133063790,
        "odometry_pos_body": "PositionBody: [x_m: 19.856517791748047, y_m: 5.151631832122803, z_m: -3.103464365005493]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -20.6150966833256,
        "alg_manual_y": -20.389365231977113,
        "last_angle_position": 357.86,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066362,
        "alg_manual_gps_lon_start": 38.8900423,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066322,
        "alg_manual_gps_lon_next": 38.8898405,
        "alg_manual_x_start": 20.09160423278809,
        "alg_manual_y_start": 5.398502826690674,
        "alg_manual_x_now": 5.593540191650391,
        "alg_manual_y_now": 20.190587997436523,
        "alg_manual_azimut": 88,
        "alg_manual_zero_x": -13.192258036074058,
        "alg_manual_zero_y": 18.840497018646815,
        "alg_manual_dist_to_point": 23,
        "alg_manual_angle_to_point": 325,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 268,
        "alg_manual_command": "off",
        "alg_manual_inputs": "[0.8, 0.025, 0.5, 0.0]",
        "timestamp": 132404372,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5699999928474426,
        "current_climb_rate": 0.0144577631726861,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066428,
        "gps_raw_lon_deg": 38.8900942,
        "gps_raw_abs_alt": 80.4010009765625,
        "gps_raw_velocity": 0.8999999761581421,
        "position_lat_deg": 47.3066444,
        "position_lon_deg": 38.8900979,
        "position_abs_alt": 82.25800323486328,
        "position_real_alt": 3.511000156402588,
        "heading_deg": 261.9,
        "odometry_pos_body_x": 19.77818298339844,
        "odometry_pos_body_y": 4.9038004875183105,
        "odometry_pos_body_z": -3.10726547241211,
        "odometry_vel_body_x": 1.4999927282333374,
        "odometry_vel_body_y": -0.2027052938938141,
        "odometry_vel_body_z": -0.21584755182266235,
        "odometry_ang_vel_body_roll": -0.5630000233650208,
        "odometry_ang_vel_body_pitch": 0.15104535222053528,
        "odometry_ang_vel_body_yaw": -0.04888409748673439,
        "velocity_north_m_s": -0.1599999964237213,
        "velocity_east_m_s": -0.14000000059604645,
        "velocity_down_m_s": -0.009999999776482582,
        "acceleration_forward": 1.5167845487594604,
        "acceleration_right": -2.859665870666504,
        "acceleration_down": -7.062441825866699,
        "battery_id": 0,
        "battery_voltage": 13.029001235961914,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 2,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 0,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 6782,
        "udp_total": 143,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 114
      },
      {
        "id": 257,
        "datetime": "21/02/2023 02:04:48",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477e0d0>, <mavsdk.mission_raw.MissionItem object at 0xa4777c40>, <mavsdk.mission_raw.MissionItem object at 0xa477bd00>, <mavsdk.mission_raw.MissionItem object at 0xa477bfd0>]",
        "mission_pause": 0,
        "time_usec": 133393492,
        "odometry_pos_body": "PositionBody: [x_m: 19.722152709960938, y_m: 4.71143913269043, z_m: -3.1128222942352295]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -20.6150966833256,
        "alg_manual_y": -20.389365231977113,
        "last_angle_position": 357.86,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066362,
        "alg_manual_gps_lon_start": 38.8900423,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066322,
        "alg_manual_gps_lon_next": 38.8898405,
        "alg_manual_x_start": 20.09160423278809,
        "alg_manual_y_start": 5.398502826690674,
        "alg_manual_x_now": 5.593540191650391,
        "alg_manual_y_now": 20.190587997436523,
        "alg_manual_azimut": 88,
        "alg_manual_zero_x": -13.192258036074058,
        "alg_manual_zero_y": 18.840497018646815,
        "alg_manual_dist_to_point": 23,
        "alg_manual_angle_to_point": 325,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 268,
        "alg_manual_command": "off",
        "alg_manual_inputs": "[0.8, 0.025, 0.5, 0.0]",
        "timestamp": 132774043,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5699999928474426,
        "current_climb_rate": 0.012754772789776323,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306641299999995,
        "gps_raw_lon_deg": 38.8900867,
        "gps_raw_abs_alt": 80.43500518798828,
        "gps_raw_velocity": 1.6399999856948853,
        "position_lat_deg": 47.306643699999995,
        "position_lon_deg": 38.8900965,
        "position_abs_alt": 82.27400207519531,
        "position_real_alt": 3.5300002098083496,
        "heading_deg": 261.47,
        "odometry_pos_body_x": 19.640727996826172,
        "odometry_pos_body_y": 4.423092365264893,
        "odometry_pos_body_z": -3.1211419105529785,
        "odometry_vel_body_x": 1.9039710760116575,
        "odometry_vel_body_y": -0.21051470935344696,
        "odometry_vel_body_z": -0.2829357981681824,
        "odometry_ang_vel_body_roll": -1.133616328239441,
        "odometry_ang_vel_body_pitch": -0.1189560741186142,
        "odometry_ang_vel_body_yaw": -0.09111212193965912,
        "velocity_north_m_s": -0.32999998331069946,
        "velocity_east_m_s": -0.7599999904632568,
        "velocity_down_m_s": -0.07000000029802322,
        "acceleration_forward": 0.7206867933273315,
        "acceleration_right": -0.5642492175102234,
        "acceleration_down": -11.895294189453123,
        "battery_id": 0,
        "battery_voltage": 13.029001235961914,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 2,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 6804,
        "udp_total": 143,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 114
      },
      {
        "id": 258,
        "datetime": "21/02/2023 02:04:48",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477d880>, <mavsdk.mission_raw.MissionItem object at 0xa477dc40>, <mavsdk.mission_raw.MissionItem object at 0xa477d4c0>, <mavsdk.mission_raw.MissionItem object at 0xa477d178>]",
        "mission_pause": 0,
        "time_usec": 133733189,
        "odometry_pos_body": "PositionBody: [x_m: 19.552234649658203, y_m: 4.092956066131592, z_m: -3.132249593734741]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -20.6150966833256,
        "alg_manual_y": -20.389365231977113,
        "last_angle_position": 357.86,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066362,
        "alg_manual_gps_lon_start": 38.8900423,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066322,
        "alg_manual_gps_lon_next": 38.8898405,
        "alg_manual_x_start": 20.09160423278809,
        "alg_manual_y_start": 5.398502826690674,
        "alg_manual_x_now": 5.593540191650391,
        "alg_manual_y_now": 20.190587997436523,
        "alg_manual_azimut": 88,
        "alg_manual_zero_x": -13.192258036074058,
        "alg_manual_zero_y": 18.840497018646815,
        "alg_manual_dist_to_point": 23,
        "alg_manual_angle_to_point": 325,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 268,
        "alg_manual_command": "off",
        "alg_manual_inputs": "[0.8, 0.15, 0.5, 0.0]",
        "timestamp": 133023823,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5600000023841858,
        "current_climb_rate": 0.023759957402944565,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066405,
        "gps_raw_lon_deg": 38.890081599999995,
        "gps_raw_abs_alt": 80.4540023803711,
        "gps_raw_velocity": 1.959999918937683,
        "position_lat_deg": 47.3066427,
        "position_lon_deg": 38.8900931,
        "position_abs_alt": 82.28700256347656,
        "position_real_alt": 3.5410001277923584,
        "heading_deg": 260.94,
        "odometry_pos_body_x": 19.473085403442383,
        "odometry_pos_body_y": 3.76547908782959,
        "odometry_pos_body_z": -3.1412737369537354,
        "odometry_vel_body_x": 2.300840377807617,
        "odometry_vel_body_y": -0.16532498598098755,
        "odometry_vel_body_z": -0.3676852881908417,
        "odometry_ang_vel_body_roll": -0.7383078336715698,
        "odometry_ang_vel_body_pitch": -0.20085595548152924,
        "odometry_ang_vel_body_yaw": -0.06970789283514023,
        "velocity_north_m_s": -0.3999999761581421,
        "velocity_east_m_s": -1.350000023841858,
        "velocity_down_m_s": -0.009999999776482582,
        "acceleration_forward": 0.057972148060798645,
        "acceleration_right": 3.584380865097046,
        "acceleration_down": -4.104591369628906,
        "battery_id": 0,
        "battery_voltage": 13.029001235961914,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 2,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 27,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 6829,
        "udp_total": 143,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 114
      },
      {
        "id": 259,
        "datetime": "21/02/2023 02:04:48",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa476cfd0>, <mavsdk.mission_raw.MissionItem object at 0xa477e838>, <mavsdk.mission_raw.MissionItem object at 0xa477e478>, <mavsdk.mission_raw.MissionItem object at 0xa4780bc8>]",
        "mission_pause": 0,
        "time_usec": 134042914,
        "odometry_pos_body": "PositionBody: [x_m: 19.380752563476562, y_m: 3.3816449642181396, z_m: -3.1477324962615967]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -20.6150966833256,
        "alg_manual_y": -20.389365231977113,
        "last_angle_position": 357.86,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066362,
        "alg_manual_gps_lon_start": 38.8900423,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066322,
        "alg_manual_gps_lon_next": 38.8898405,
        "alg_manual_x_start": 20.09160423278809,
        "alg_manual_y_start": 5.398502826690674,
        "alg_manual_x_now": 5.593540191650391,
        "alg_manual_y_now": 20.190587997436523,
        "alg_manual_azimut": 88,
        "alg_manual_zero_x": -13.192258036074058,
        "alg_manual_zero_y": 18.840497018646815,
        "alg_manual_dist_to_point": 23,
        "alg_manual_angle_to_point": 325,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 268,
        "alg_manual_command": "off",
        "alg_manual_inputs": "[0.8, 0.15, 0.5, 0.0]",
        "timestamp": 133368516,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5600000023841858,
        "current_climb_rate": -0.032754044979810715,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066383,
        "gps_raw_lon_deg": 38.8900697,
        "gps_raw_abs_alt": 80.4310073852539,
        "gps_raw_velocity": 2.4600000381469727,
        "position_lat_deg": 47.3066415,
        "position_lon_deg": 38.8900868,
        "position_abs_alt": 82.29700469970703,
        "position_real_alt": 3.5510001182556152,
        "heading_deg": 260.17,
        "odometry_pos_body_x": 19.268857955932617,
        "odometry_pos_body_y": 2.910309076309204,
        "odometry_pos_body_z": -3.146728038787842,
        "odometry_vel_body_x": 2.731870412826538,
        "odometry_vel_body_y": -0.11833427101373672,
        "odometry_vel_body_z": -0.3728191256523132,
        "odometry_ang_vel_body_roll": 1.093976974487305,
        "odometry_ang_vel_body_pitch": 0.24349741637706757,
        "odometry_ang_vel_body_yaw": 0.018892882391810417,
        "velocity_north_m_s": -0.4899999797344208,
        "velocity_east_m_s": -1.7799999713897705,
        "velocity_down_m_s": -0.019999999552965164,
        "acceleration_forward": 0.8184394836425781,
        "acceleration_right": 0.6687003970146179,
        "acceleration_down": -12.705327987670898,
        "battery_id": 0,
        "battery_voltage": 13.029001235961914,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 2,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 50,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 6852,
        "udp_total": 144,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 115
      },
      {
        "id": 260,
        "datetime": "21/02/2023 02:04:49",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa47776a0>, <mavsdk.mission_raw.MissionItem object at 0xa591ea60>, <mavsdk.mission_raw.MissionItem object at 0xa4781e80>, <mavsdk.mission_raw.MissionItem object at 0xa4781460>]",
        "mission_pause": 0,
        "time_usec": 134342648,
        "odometry_pos_body": "PositionBody: [x_m: 19.19102668762207, y_m: 2.571842908859253, z_m: -3.141305923461914]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -20.6150966833256,
        "alg_manual_y": -20.389365231977113,
        "last_angle_position": 357.86,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066362,
        "alg_manual_gps_lon_start": 38.8900423,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066322,
        "alg_manual_gps_lon_next": 38.8898405,
        "alg_manual_x_start": 20.09160423278809,
        "alg_manual_y_start": 5.398502826690674,
        "alg_manual_x_now": 5.593540191650391,
        "alg_manual_y_now": 20.190587997436523,
        "alg_manual_azimut": 88,
        "alg_manual_zero_x": -13.192258036074058,
        "alg_manual_zero_y": 18.840497018646815,
        "alg_manual_dist_to_point": 23,
        "alg_manual_angle_to_point": 325,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 268,
        "alg_manual_command": "off",
        "alg_manual_inputs": "[0.8, 0.15, 0.5, 0.0]",
        "timestamp": 133678238,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5699999928474426,
        "current_climb_rate": -0.06672406941652298,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066371,
        "gps_raw_lon_deg": 38.8900627,
        "gps_raw_abs_alt": 80.41500091552734,
        "gps_raw_velocity": 2.7200000286102295,
        "position_lat_deg": 47.3066398,
        "position_lon_deg": 38.8900777,
        "position_abs_alt": 82.31900024414062,
        "position_real_alt": 3.573000192642212,
        "heading_deg": 259.37,
        "odometry_pos_body_x": 19.119157791137695,
        "odometry_pos_body_y": 2.245595693588257,
        "odometry_pos_body_z": -3.1334147453308105,
        "odometry_vel_body_x": 3.0079500675201416,
        "odometry_vel_body_y": -0.08877839893102646,
        "odometry_vel_body_z": -0.3726971745491028,
        "odometry_ang_vel_body_roll": 0.32420244812965393,
        "odometry_ang_vel_body_pitch": -0.08218403160572052,
        "odometry_ang_vel_body_yaw": -0.01330797467380762,
        "velocity_north_m_s": -0.5600000023841858,
        "velocity_east_m_s": -2.2300000190734863,
        "velocity_down_m_s": -0.029999999329447743,
        "acceleration_forward": -1.393604755401611,
        "acceleration_right": -3.249354362487793,
        "acceleration_down": -9.19980812072754,
        "battery_id": 0,
        "battery_voltage": 13.029001235961914,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 2,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 72,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 6876,
        "udp_total": 144,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 115
      },
      {
        "id": 261,
        "datetime": "21/02/2023 02:04:49",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477d910>, <mavsdk.mission_raw.MissionItem object at 0xa477d5e0>, <mavsdk.mission_raw.MissionItem object at 0xa476ca60>, <mavsdk.mission_raw.MissionItem object at 0xa477bb08>]",
        "mission_pause": 0,
        "time_usec": 134692335,
        "odometry_pos_body": "PositionBody: [x_m: 18.96561050415039, y_m: 1.4901784658432007, z_m: -3.1117355823516846]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -20.6150966833256,
        "alg_manual_y": -20.389365231977113,
        "last_angle_position": 357.86,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066362,
        "alg_manual_gps_lon_start": 38.8900423,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066322,
        "alg_manual_gps_lon_next": 38.8898405,
        "alg_manual_x_start": 20.09160423278809,
        "alg_manual_y_start": 5.398502826690674,
        "alg_manual_x_now": 5.593540191650391,
        "alg_manual_y_now": 20.190587997436523,
        "alg_manual_azimut": 88,
        "alg_manual_zero_x": -13.192258036074058,
        "alg_manual_zero_y": 18.840497018646815,
        "alg_manual_dist_to_point": 23,
        "alg_manual_angle_to_point": 325,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 268,
        "alg_manual_command": "off",
        "alg_manual_inputs": "[0.8, 0.15, 0.5, 0.0]",
        "timestamp": 133982970,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5899999737739563,
        "current_climb_rate": -0.11480852961540222,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066348,
        "gps_raw_lon_deg": 38.8900467,
        "gps_raw_abs_alt": 80.36600494384766,
        "gps_raw_velocity": 3.1999998092651367,
        "position_lat_deg": 47.306638799999995,
        "position_lon_deg": 38.8900712,
        "position_abs_alt": 82.3290023803711,
        "position_real_alt": 3.5830001831054688,
        "heading_deg": 259.53000000000003,
        "odometry_pos_body_x": 18.9327335357666,
        "odometry_pos_body_y": 1.3232529163360596,
        "odometry_pos_body_z": -3.106520414352417,
        "odometry_vel_body_x": 3.3137502670288086,
        "odometry_vel_body_y": -0.013560416176915169,
        "odometry_vel_body_z": -0.2934569716453552,
        "odometry_ang_vel_body_roll": 0.1668906807899475,
        "odometry_ang_vel_body_pitch": 0.02278135158121586,
        "odometry_ang_vel_body_yaw": 0.03621380776166916,
        "velocity_north_m_s": -0.5799999833106995,
        "velocity_east_m_s": -2.509999990463257,
        "velocity_down_m_s": 0.029999999329447743,
        "acceleration_forward": -1.32680082321167,
        "acceleration_right": -2.0698180198669434,
        "acceleration_down": -15.00217628479004,
        "battery_id": 0,
        "battery_voltage": 13.029001235961914,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 2,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 74,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 6876,
        "udp_total": 144,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 115
      },
      {
        "id": 262,
        "datetime": "21/02/2023 02:04:49",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4781c88>, <mavsdk.mission_raw.MissionItem object at 0xa477a3d0>, <mavsdk.mission_raw.MissionItem object at 0xa47805e0>, <mavsdk.mission_raw.MissionItem object at 0xa4780c88>]",
        "mission_pause": 0,
        "time_usec": 134882168,
        "odometry_pos_body": "PositionBody: [x_m: 18.843162536621094, y_m: 0.8459882736206055, z_m: -3.089282274246216]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -20.6150966833256,
        "alg_manual_y": -20.389365231977113,
        "last_angle_position": 357.86,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066362,
        "alg_manual_gps_lon_start": 38.8900423,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066322,
        "alg_manual_gps_lon_next": 38.8898405,
        "alg_manual_x_start": 20.09160423278809,
        "alg_manual_y_start": 5.398502826690674,
        "alg_manual_x_now": 5.593540191650391,
        "alg_manual_y_now": 20.190587997436523,
        "alg_manual_azimut": 88,
        "alg_manual_zero_x": -13.192258036074058,
        "alg_manual_zero_y": 18.840497018646815,
        "alg_manual_dist_to_point": 23,
        "alg_manual_angle_to_point": 325,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 268,
        "alg_manual_command": "off",
        "alg_manual_inputs": "[0.8, 0.15, 0.5, 0.0]",
        "timestamp": 134157812,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5999999642372131,
        "current_climb_rate": -0.12785135209560394,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066337,
        "gps_raw_lon_deg": 38.8900378,
        "gps_raw_abs_alt": 80.34300231933594,
        "gps_raw_velocity": 3.419999837875366,
        "position_lat_deg": 47.306637599999995,
        "position_lon_deg": 38.8900642,
        "position_abs_alt": 82.3290023803711,
        "position_real_alt": 3.5830001831054688,
        "heading_deg": 259.67,
        "odometry_pos_body_x": 18.760469436645508,
        "odometry_pos_body_y": 0.379807710647583,
        "odometry_pos_body_z": -3.0682904720306396,
        "odometry_vel_body_x": 3.5940699577331543,
        "odometry_vel_body_y": 0.01774667762219906,
        "odometry_vel_body_z": -0.34778425097465515,
        "odometry_ang_vel_body_roll": 0.2987724244594574,
        "odometry_ang_vel_body_pitch": -0.14527572691440582,
        "odometry_ang_vel_body_yaw": 0.05126981437206268,
        "velocity_north_m_s": -0.6100000143051147,
        "velocity_east_m_s": -2.7699999809265137,
        "velocity_down_m_s": 0.05999999865889549,
        "acceleration_forward": -1.352566123008728,
        "acceleration_right": -1.9594463109970093,
        "acceleration_down": -3.4207186698913574,
        "battery_id": 0,
        "battery_voltage": 13.029001235961914,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 2,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 1,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 6898,
        "udp_total": 144,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 115
      },
      {
        "id": 263,
        "datetime": "21/02/2023 02:04:49",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4775640>, <mavsdk.mission_raw.MissionItem object at 0xa4781cd0>, <mavsdk.mission_raw.MissionItem object at 0xa4781f58>, <mavsdk.mission_raw.MissionItem object at 0xa4781ca0>]",
        "mission_pause": 0,
        "time_usec": 135181900,
        "odometry_pos_body": "PositionBody: [x_m: 18.65428352355957, y_m: -0.25829532742500305, z_m: -3.0355749130249023]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -20.6150966833256,
        "alg_manual_y": -20.389365231977113,
        "last_angle_position": 357.86,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066362,
        "alg_manual_gps_lon_start": 38.8900423,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066322,
        "alg_manual_gps_lon_next": 38.8898405,
        "alg_manual_x_start": 20.09160423278809,
        "alg_manual_y_start": 5.398502826690674,
        "alg_manual_x_now": 5.593540191650391,
        "alg_manual_y_now": 20.190587997436523,
        "alg_manual_azimut": 88,
        "alg_manual_zero_x": -13.192258036074058,
        "alg_manual_zero_y": 18.840497018646815,
        "alg_manual_dist_to_point": 23,
        "alg_manual_angle_to_point": 325,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 268,
        "alg_manual_command": "off",
        "alg_manual_inputs": "[0.8, 0.15, 0.5, 0.0]",
        "timestamp": 134412587,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.6299999952316284,
        "current_climb_rate": -0.15021297335624695,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066317,
        "gps_raw_lon_deg": 38.8900183,
        "gps_raw_abs_alt": 80.30500030517578,
        "gps_raw_velocity": 3.849999904632568,
        "position_lat_deg": 47.3066359,
        "position_lon_deg": 38.8900526,
        "position_abs_alt": 82.30900573730469,
        "position_real_alt": 3.563000202178955,
        "heading_deg": 259.76,
        "odometry_pos_body_x": 18.539031982421875,
        "odometry_pos_body_y": -1.050178289413452,
        "odometry_pos_body_z": -3.002263069152832,
        "odometry_vel_body_x": 3.983728647232056,
        "odometry_vel_body_y": 0.18694545328617096,
        "odometry_vel_body_z": -0.32411617040634155,
        "odometry_ang_vel_body_roll": -0.4913509488105774,
        "odometry_ang_vel_body_pitch": 0.23663677275180817,
        "odometry_ang_vel_body_yaw": -0.06578963249921799,
        "velocity_north_m_s": -0.6100000143051147,
        "velocity_east_m_s": -3.0999999046325684,
        "velocity_down_m_s": 0.09999999403953552,
        "acceleration_forward": 0.44565892219543457,
        "acceleration_right": 3.2604799270629883,
        "acceleration_down": -11.005626678466797,
        "battery_id": 0,
        "battery_voltage": 13.005000114440918,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 2,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 6922,
        "udp_total": 145,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 116
      },
      {
        "id": 264,
        "datetime": "21/02/2023 02:04:50",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477bf40>, <mavsdk.mission_raw.MissionItem object at 0xa477b9d0>, <mavsdk.mission_raw.MissionItem object at 0xa477b928>, <mavsdk.mission_raw.MissionItem object at 0xa477b460>]",
        "mission_pause": 0,
        "time_usec": 135541581,
        "odometry_pos_body": "PositionBody: [x_m: 18.4533634185791, y_m: -1.7188886404037476, z_m: -2.9729716777801514]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -20.6150966833256,
        "alg_manual_y": -20.389365231977113,
        "last_angle_position": 357.86,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066362,
        "alg_manual_gps_lon_start": 38.8900423,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066322,
        "alg_manual_gps_lon_next": 38.8898405,
        "alg_manual_x_start": 20.09160423278809,
        "alg_manual_y_start": 5.398502826690674,
        "alg_manual_x_now": 5.593540191650391,
        "alg_manual_y_now": 20.190587997436523,
        "alg_manual_azimut": 88,
        "alg_manual_zero_x": -13.192258036074058,
        "alg_manual_zero_y": 18.840497018646815,
        "alg_manual_dist_to_point": 23,
        "alg_manual_angle_to_point": 325,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 268,
        "alg_manual_command": "off",
        "alg_manual_inputs": "[0.8, 0.15, 0.5, 0.0]",
        "timestamp": 134647379,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.6299999952316284,
        "current_climb_rate": -0.06493927538394928,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066296,
        "gps_raw_lon_deg": 38.8899962,
        "gps_raw_abs_alt": 80.25300598144531,
        "gps_raw_velocity": 4.369999885559082,
        "position_lat_deg": 47.3066349,
        "position_lon_deg": 38.890045,
        "position_abs_alt": 82.29300689697266,
        "position_real_alt": 3.5470001697540283,
        "heading_deg": 260.13,
        "odometry_pos_body_x": 18.398462295532227,
        "odometry_pos_body_y": -2.15522837638855,
        "odometry_pos_body_z": -2.955787420272827,
        "odometry_vel_body_x": 4.3089213371276855,
        "odometry_vel_body_y": 0.27954718470573425,
        "odometry_vel_body_z": -0.6660153865814209,
        "odometry_ang_vel_body_roll": 0.2950158417224884,
        "odometry_ang_vel_body_pitch": 0.30586791038513184,
        "odometry_ang_vel_body_yaw": 0.03888196498155594,
        "velocity_north_m_s": -0.5899999737739563,
        "velocity_east_m_s": -3.2699999809265137,
        "velocity_down_m_s": 0.09999999403953552,
        "acceleration_forward": -0.3466452658176422,
        "acceleration_right": 2.292202949523926,
        "acceleration_down": -9.440302848815918,
        "battery_id": 0,
        "battery_voltage": 13.005000114440918,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 2,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 24,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 6944,
        "udp_total": 145,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 116
      },
      {
        "id": 265,
        "datetime": "21/02/2023 02:04:50",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4780910>, <mavsdk.mission_raw.MissionItem object at 0xa4780430>, <mavsdk.mission_raw.MissionItem object at 0xa4780b68>, <mavsdk.mission_raw.MissionItem object at 0xa4780310>]",
        "mission_pause": 0,
        "time_usec": 135811343,
        "odometry_pos_body": "PositionBody: [x_m: 18.306705474853516, y_m: -2.9349591732025146, z_m: -2.9325854778289795]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -20.6150966833256,
        "alg_manual_y": -20.389365231977113,
        "last_angle_position": 357.86,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066362,
        "alg_manual_gps_lon_start": 38.8900423,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066322,
        "alg_manual_gps_lon_next": 38.8898405,
        "alg_manual_x_start": 20.09160423278809,
        "alg_manual_y_start": 5.398502826690674,
        "alg_manual_x_now": 5.593540191650391,
        "alg_manual_y_now": 20.190587997436523,
        "alg_manual_azimut": 88,
        "alg_manual_zero_x": -13.192258036074058,
        "alg_manual_zero_y": 18.840497018646815,
        "alg_manual_dist_to_point": 23,
        "alg_manual_angle_to_point": 325,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 268,
        "alg_manual_command": "off",
        "alg_manual_inputs": "[0.8, 0.15, 0.5, 0.0]",
        "timestamp": 134762273,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.6399999856948853,
        "current_climb_rate": -0.024096690118312836,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066278,
        "gps_raw_lon_deg": 38.8899718,
        "gps_raw_abs_alt": 80.24300384521484,
        "gps_raw_velocity": 4.829999923706055,
        "position_lat_deg": 47.306633999999995,
        "position_lon_deg": 38.8900387,
        "position_abs_alt": 82.2770004272461,
        "position_real_alt": 3.5310001373291016,
        "heading_deg": 260.29,
        "odometry_pos_body_x": 18.25266456604004,
        "odometry_pos_body_y": -3.408418655395508,
        "odometry_pos_body_z": -2.9207942485809326,
        "odometry_vel_body_x": 4.695146083831787,
        "odometry_vel_body_y": 0.2319726198911667,
        "odometry_vel_body_z": -0.4924689829349518,
        "odometry_ang_vel_body_roll": 0.6194316744804382,
        "odometry_ang_vel_body_pitch": 0.3662857711315155,
        "odometry_ang_vel_body_yaw": 0.07845111191272736,
        "velocity_north_m_s": -0.5999999642372131,
        "velocity_east_m_s": -3.3999998569488525,
        "velocity_down_m_s": 0.119999997317791,
        "acceleration_forward": -1.769503474235535,
        "acceleration_right": 1.80114483833313,
        "acceleration_down": -13.485905647277832,
        "battery_id": 0,
        "battery_voltage": 13.005000114440918,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 2,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 6968,
        "udp_total": 146,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 117
      },
      {
        "id": 266,
        "datetime": "21/02/2023 02:04:51",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4777e98>, <mavsdk.mission_raw.MissionItem object at 0xa4777370>, <mavsdk.mission_raw.MissionItem object at 0xa4777e08>, <mavsdk.mission_raw.MissionItem object at 0xa476ce98>]",
        "mission_pause": 0,
        "time_usec": 136121066,
        "odometry_pos_body": "PositionBody: [x_m: 18.147302627563477, y_m: -4.428189754486084, z_m: -2.8983378410339355]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -20.6150966833256,
        "alg_manual_y": -20.389365231977113,
        "last_angle_position": 357.86,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066362,
        "alg_manual_gps_lon_start": 38.8900423,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066322,
        "alg_manual_gps_lon_next": 38.8898405,
        "alg_manual_x_start": 20.09160423278809,
        "alg_manual_y_start": 5.398502826690674,
        "alg_manual_x_now": 5.593540191650391,
        "alg_manual_y_now": 20.190587997436523,
        "alg_manual_azimut": 88,
        "alg_manual_zero_x": -13.192258036074058,
        "alg_manual_zero_y": 18.840497018646815,
        "alg_manual_dist_to_point": 23,
        "alg_manual_angle_to_point": 325,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 268,
        "alg_manual_command": "stop",
        "alg_manual_inputs": "[0.23318844116569348, 0.0, 0.5, 0.0]",
        "timestamp": 134987077,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.6200000047683716,
        "current_climb_rate": 0.07899229228496552,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066269,
        "gps_raw_lon_deg": 38.8899587,
        "gps_raw_abs_alt": 80.23600006103516,
        "gps_raw_velocity": 4.96999979019165,
        "position_lat_deg": 47.3066329,
        "position_lon_deg": 38.8900292,
        "position_abs_alt": 82.24500274658203,
        "position_real_alt": 3.498000144958496,
        "heading_deg": 259.65,
        "odometry_pos_body_x": 18.083250045776367,
        "odometry_pos_body_y": -5.079334259033203,
        "odometry_pos_body_z": -2.891976118087769,
        "odometry_vel_body_x": 4.978119850158691,
        "odometry_vel_body_y": 0.23476576805114743,
        "odometry_vel_body_z": -0.43147122859954834,
        "odometry_ang_vel_body_roll": -0.29687973856925964,
        "odometry_ang_vel_body_pitch": 0.6259118318557739,
        "odometry_ang_vel_body_yaw": -0.02077401429414749,
        "velocity_north_m_s": -0.5699999928474426,
        "velocity_east_m_s": -3.68999981880188,
        "velocity_down_m_s": 0.14000000059604645,
        "acceleration_forward": -0.7077664136886597,
        "acceleration_right": -3.991852283477783,
        "acceleration_down": -6.6713738441467285,
        "battery_id": 0,
        "battery_voltage": 13.005000114440918,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 2,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 14,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 6984,
        "udp_total": 146,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 117
      },
      {
        "id": 267,
        "datetime": "21/02/2023 02:04:51",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477e790>, <mavsdk.mission_raw.MissionItem object at 0xa477e3e8>, <mavsdk.mission_raw.MissionItem object at 0xa477eb20>, <mavsdk.mission_raw.MissionItem object at 0xa477e598>]",
        "mission_pause": 0,
        "time_usec": 136540707,
        "odometry_pos_body": "PositionBody: [x_m: 17.95649528503418, y_m: -6.547556400299072, z_m: -2.895684003829956]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -20.6150966833256,
        "alg_manual_y": -20.389365231977113,
        "last_angle_position": 357.86,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066362,
        "alg_manual_gps_lon_start": 38.8900423,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066322,
        "alg_manual_gps_lon_next": 38.8898405,
        "alg_manual_x_start": 20.09160423278809,
        "alg_manual_y_start": 5.398502826690674,
        "alg_manual_x_now": 5.593540191650391,
        "alg_manual_y_now": 20.190587997436523,
        "alg_manual_azimut": 88,
        "alg_manual_zero_x": -13.192258036074058,
        "alg_manual_zero_y": 18.840497018646815,
        "alg_manual_dist_to_point": 23,
        "alg_manual_angle_to_point": 325,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 268,
        "alg_manual_command": "stop",
        "alg_manual_inputs": "[0.23318844116569348, 0.0, 0.5, 0.0]",
        "timestamp": 135261829,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.6200000047683716,
        "current_climb_rate": 0.10338759422302246,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306625399999994,
        "gps_raw_lon_deg": 38.8899323,
        "gps_raw_abs_alt": 80.2750015258789,
        "gps_raw_velocity": 5.009999752044678,
        "position_lat_deg": 47.3066313,
        "position_lon_deg": 38.8900139,
        "position_abs_alt": 82.19100189208984,
        "position_real_alt": 3.445000171661377,
        "heading_deg": 259.74,
        "odometry_pos_body_x": 17.88179588317871,
        "odometry_pos_body_y": -7.570430755615234,
        "odometry_pos_body_z": -2.9082515239715576,
        "odometry_vel_body_x": 4.494380950927734,
        "odometry_vel_body_y": 0.35246846079826355,
        "odometry_vel_body_z": 0.8519383072853088,
        "odometry_ang_vel_body_roll": 0.7372083067893982,
        "odometry_ang_vel_body_pitch": 0.6877970099449158,
        "odometry_ang_vel_body_yaw": 0.04969364032149315,
        "velocity_north_m_s": -0.5199999809265137,
        "velocity_east_m_s": -3.990000009536743,
        "velocity_down_m_s": 0.14000000059604645,
        "acceleration_forward": -0.3518243432044983,
        "acceleration_right": 4.103970050811768,
        "acceleration_down": -9.855947494506836,
        "battery_id": 0,
        "battery_voltage": 13.005000114440918,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 2,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 13,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 7006,
        "udp_total": 146,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 117
      },
      {
        "id": 268,
        "datetime": "21/02/2023 02:04:51",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4781460>, <mavsdk.mission_raw.MissionItem object at 0xa4781ef8>, <mavsdk.mission_raw.MissionItem object at 0xa4781280>, <mavsdk.mission_raw.MissionItem object at 0xa47818c8>]",
        "mission_pause": 0,
        "time_usec": 136980303,
        "odometry_pos_body": "PositionBody: [x_m: 17.815277099609375, y_m: -8.598134994506836, z_m: -2.9211888313293457]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -20.6150966833256,
        "alg_manual_y": -20.389365231977113,
        "last_angle_position": 357.86,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066362,
        "alg_manual_gps_lon_start": 38.8900423,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3066322,
        "alg_manual_gps_lon_next": 38.8898405,
        "alg_manual_x_start": 20.09160423278809,
        "alg_manual_y_start": 5.398502826690674,
        "alg_manual_x_now": -8.846625328063965,
        "alg_manual_y_now": 17.79939842224121,
        "alg_manual_azimut": 88,
        "alg_manual_zero_x": -13.192258036074058,
        "alg_manual_zero_y": 18.840497018646815,
        "alg_manual_dist_to_point": 23,
        "alg_manual_angle_to_point": 325,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 268,
        "alg_manual_command": "rb",
        "alg_manual_inputs": "[0.23318844116569348, 0.0, 0.5, 0.0]",
        "timestamp": 135541581,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.6299999952316284,
        "current_climb_rate": 0.04683162644505501,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306624199999995,
        "gps_raw_lon_deg": 38.8899087,
        "gps_raw_abs_alt": 80.31300354003906,
        "gps_raw_velocity": 4.289999961853027,
        "position_lat_deg": 47.3066299,
        "position_lon_deg": 38.889996499999995,
        "position_abs_alt": 82.13700103759766,
        "position_real_alt": 3.390000104904175,
        "heading_deg": 261.41,
        "odometry_pos_body_x": 17.754045486450195,
        "odometry_pos_body_y": -9.67208480834961,
        "odometry_pos_body_z": -2.9261531829833984,
        "odometry_vel_body_x": 3.485839605331421,
        "odometry_vel_body_y": 0.23442678153514865,
        "odometry_vel_body_z": 0.4685801565647125,
        "odometry_ang_vel_body_roll": -0.07563517987728119,
        "odometry_ang_vel_body_pitch": -0.18802359700202945,
        "odometry_ang_vel_body_yaw": 0.021365268155932423,
        "velocity_north_m_s": -0.5,
        "velocity_east_m_s": -4.440000057220459,
        "velocity_down_m_s": 0.05999999865889549,
        "acceleration_forward": -0.2066224366426468,
        "acceleration_right": 0.7186471819877625,
        "acceleration_down": -7.272826194763184,
        "battery_id": 0,
        "battery_voltage": 12.921000480651855,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 24,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 7036,
        "udp_total": 147,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 118
      },
      {
        "id": 269,
        "datetime": "21/02/2023 02:04:52",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477bf10>, <mavsdk.mission_raw.MissionItem object at 0xa477b3e8>, <mavsdk.mission_raw.MissionItem object at 0xa477bf58>, <mavsdk.mission_raw.MissionItem object at 0xa4781148>]",
        "mission_pause": 0,
        "time_usec": 137479860,
        "odometry_pos_body": "PositionBody: [x_m: 17.682950973510742, y_m: -10.496788024902344, z_m: -2.942344903945923]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -20.6150966833256,
        "alg_manual_y": -20.389365231977113,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 20.09160423278809,
        "alg_manual_y_start": 5.398502826690674,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 88,
        "alg_manual_zero_x": -13.192258036074058,
        "alg_manual_zero_y": 18.840497018646815,
        "alg_manual_dist_to_point": 21,
        "alg_manual_angle_to_point": 325,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 268,
        "alg_manual_command": "rotate 133.31811184012633",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.4]",
        "timestamp": 135851306,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.6800000071525574,
        "current_climb_rate": 0.109587199985981,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066232,
        "gps_raw_lon_deg": 38.8898894,
        "gps_raw_abs_alt": 80.36100006103516,
        "gps_raw_velocity": 3.5,
        "position_lat_deg": 47.306628499999995,
        "position_lon_deg": 38.8899798,
        "position_abs_alt": 82.10200500488281,
        "position_real_alt": 3.3560001850128174,
        "heading_deg": 262.22,
        "odometry_pos_body_x": 17.66370391845703,
        "odometry_pos_body_y": -10.731066703796388,
        "odometry_pos_body_z": -2.9511075019836426,
        "odometry_vel_body_x": 3.029803991317749,
        "odometry_vel_body_y": 0.19684067368507385,
        "odometry_vel_body_z": 0.32026875019073486,
        "odometry_ang_vel_body_roll": 0.2898683547973633,
        "odometry_ang_vel_body_pitch": 0.6513147354125977,
        "odometry_ang_vel_body_yaw": -0.064617820084095,
        "velocity_north_m_s": -0.4899999797344208,
        "velocity_east_m_s": -4.779999732971191,
        "velocity_down_m_s": 0.03999999910593033,
        "acceleration_forward": -0.07041625678539276,
        "acceleration_right": 3.7807769775390625,
        "acceleration_down": -14.852132797241213,
        "battery_id": 0,
        "battery_voltage": 12.921000480651855,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 44,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 7060,
        "udp_total": 147,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 118
      },
      {
        "id": 270,
        "datetime": "21/02/2023 02:04:52",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa47817f0>, <mavsdk.mission_raw.MissionItem object at 0xa47819e8>, <mavsdk.mission_raw.MissionItem object at 0xa4781c28>, <mavsdk.mission_raw.MissionItem object at 0xa4781b98>]",
        "mission_pause": 0,
        "time_usec": 137879505,
        "odometry_pos_body": "PositionBody: [x_m: 17.581451416015625, y_m: -11.688117980957031, z_m: -2.9891183376312256]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -20.6150966833256,
        "alg_manual_y": -20.389365231977113,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 20.09160423278809,
        "alg_manual_y_start": 5.398502826690674,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 88,
        "alg_manual_zero_x": -13.192258036074058,
        "alg_manual_zero_y": 18.840497018646815,
        "alg_manual_dist_to_point": 21,
        "alg_manual_angle_to_point": 325,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 268,
        "alg_manual_command": "rotate 133.31811184012633",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.4]",
        "timestamp": 136325884,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.6299999952316284,
        "current_climb_rate": -0.0383082740008831,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066222,
        "gps_raw_lon_deg": 38.8898741,
        "gps_raw_abs_alt": 80.43600463867188,
        "gps_raw_velocity": 2.7300000190734863,
        "position_lat_deg": 47.3066264,
        "position_lon_deg": 38.8899475,
        "position_abs_alt": 82.07500457763672,
        "position_real_alt": 3.329000234603882,
        "heading_deg": 262.13,
        "odometry_pos_body_x": 17.542070388793945,
        "odometry_pos_body_y": -12.026256561279297,
        "odometry_pos_body_z": -2.983956813812256,
        "odometry_vel_body_x": 2.075211763381958,
        "odometry_vel_body_y": 0.2647550106048584,
        "odometry_vel_body_z": 0.4990431070327759,
        "odometry_ang_vel_body_roll": -1.174064040184021,
        "odometry_ang_vel_body_pitch": -0.4111801981925965,
        "odometry_ang_vel_body_yaw": -0.3191550076007843,
        "velocity_north_m_s": -0.35999998450279236,
        "velocity_east_m_s": -4.949999809265137,
        "velocity_down_m_s": -0.09999999403953552,
        "acceleration_forward": -0.40338796377182007,
        "acceleration_right": -0.8329373598098755,
        "acceleration_down": -14.440556526184082,
        "battery_id": 0,
        "battery_voltage": 12.921000480651855,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 7083,
        "udp_total": 147,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 119
      },
      {
        "id": 271,
        "datetime": "21/02/2023 02:04:52",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4781148>, <mavsdk.mission_raw.MissionItem object at 0xa4781e50>, <mavsdk.mission_raw.MissionItem object at 0xa4781340>, <mavsdk.mission_raw.MissionItem object at 0xa47811d8>]",
        "mission_pause": 0,
        "time_usec": 138209217,
        "odometry_pos_body": "PositionBody: [x_m: 17.4843692779541, y_m: -12.418787956237793, z_m: -2.9795665740966797]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -20.6150966833256,
        "alg_manual_y": -20.389365231977113,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 20.09160423278809,
        "alg_manual_y_start": 5.398502826690674,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 88,
        "alg_manual_zero_x": -13.192258036074058,
        "alg_manual_zero_y": 18.840497018646815,
        "alg_manual_dist_to_point": 21,
        "alg_manual_angle_to_point": 325,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 268,
        "alg_manual_command": "rotate 133.31811184012633",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.4]",
        "timestamp": 136615634,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.6200000047683716,
        "current_climb_rate": 0.020594554021954536,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066216,
        "gps_raw_lon_deg": 38.889868,
        "gps_raw_abs_alt": 80.45800018310547,
        "gps_raw_velocity": 2.319999933242798,
        "position_lat_deg": 47.306625399999994,
        "position_lon_deg": 38.8899297,
        "position_abs_alt": 82.08500671386719,
        "position_real_alt": 3.3390002250671387,
        "heading_deg": 262.42,
        "odometry_pos_body_x": 17.464176177978516,
        "odometry_pos_body_y": -12.545546531677246,
        "odometry_pos_body_z": -2.9824602603912354,
        "odometry_vel_body_x": 1.6386263370513916,
        "odometry_vel_body_y": 0.32651641964912415,
        "odometry_vel_body_z": 0.2919173836708069,
        "odometry_ang_vel_body_roll": 0.21232210099697113,
        "odometry_ang_vel_body_pitch": -0.09186141192913055,
        "odometry_ang_vel_body_yaw": -0.4586939215660095,
        "velocity_north_m_s": -0.25999999046325684,
        "velocity_east_m_s": -4.599999904632568,
        "velocity_down_m_s": -0.119999997317791,
        "acceleration_forward": 0.5263478755950928,
        "acceleration_right": 4.604468822479248,
        "acceleration_down": -12.604400634765623,
        "battery_id": 0,
        "battery_voltage": 12.921000480651855,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 3,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 7084,
        "udp_total": 148,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 119
      },
      {
        "id": 272,
        "datetime": "21/02/2023 02:04:53",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4780430>, <mavsdk.mission_raw.MissionItem object at 0xa477b070>, <mavsdk.mission_raw.MissionItem object at 0xa477b640>, <mavsdk.mission_raw.MissionItem object at 0xa477b148>]",
        "mission_pause": 0,
        "time_usec": 138419033,
        "odometry_pos_body": "PositionBody: [x_m: 17.411663055419922, y_m: -12.778367042541504, z_m: -2.989135265350342]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -20.6150966833256,
        "alg_manual_y": -20.389365231977113,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 20.09160423278809,
        "alg_manual_y_start": 5.398502826690674,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 88,
        "alg_manual_zero_x": -13.192258036074058,
        "alg_manual_zero_y": 18.840497018646815,
        "alg_manual_dist_to_point": 21,
        "alg_manual_angle_to_point": 325,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 268,
        "alg_manual_command": "rotate 133.31811184012633",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.4]",
        "timestamp": 136795467,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5999999642372131,
        "current_climb_rate": 0.06008761376142502,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066202,
        "gps_raw_lon_deg": 38.8898588,
        "gps_raw_abs_alt": 80.44800567626953,
        "gps_raw_velocity": 1.6200000047683716,
        "position_lat_deg": 47.3066249,
        "position_lon_deg": 38.8899192,
        "position_abs_alt": 82.09900665283203,
        "position_real_alt": 3.3530001640319824,
        "heading_deg": 263.01,
        "odometry_pos_body_x": 17.368860244750977,
        "odometry_pos_body_y": -12.96751880645752,
        "odometry_pos_body_z": -2.999216318130493,
        "odometry_vel_body_x": 1.2701683044433594,
        "odometry_vel_body_y": 0.3905858099460602,
        "odometry_vel_body_z": 0.11657507717609406,
        "odometry_ang_vel_body_roll": 0.9590580463409424,
        "odometry_ang_vel_body_pitch": 0.09150996059179306,
        "odometry_ang_vel_body_yaw": -0.28964462876319885,
        "velocity_north_m_s": -0.1899999976158142,
        "velocity_east_m_s": -4.190000057220459,
        "velocity_down_m_s": -0.08999999612569809,
        "acceleration_forward": -1.296621561050415,
        "acceleration_right": 2.900026798248291,
        "acceleration_down": -19.38115119934082,
        "battery_id": 0,
        "battery_voltage": 12.921000480651855,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 7108,
        "udp_total": 148,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 119
      },
      {
        "id": 273,
        "datetime": "21/02/2023 02:04:53",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa47809e8>, <mavsdk.mission_raw.MissionItem object at 0xa4780430>, <mavsdk.mission_raw.MissionItem object at 0xa47804a8>, <mavsdk.mission_raw.MissionItem object at 0xa4780688>]",
        "mission_pause": 0,
        "time_usec": 138678797,
        "odometry_pos_body": "PositionBody: [x_m: 17.329303741455078, y_m: -13.134360313415527, z_m: -3.008380889892578]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -20.6150966833256,
        "alg_manual_y": -20.389365231977113,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 20.09160423278809,
        "alg_manual_y_start": 5.398502826690674,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 88,
        "alg_manual_zero_x": -13.192258036074058,
        "alg_manual_zero_y": 18.840497018646815,
        "alg_manual_dist_to_point": 21,
        "alg_manual_angle_to_point": 325,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 268,
        "alg_manual_command": "rotate 133.31811184012633",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.4]",
        "timestamp": 137015276,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5999999642372131,
        "current_climb_rate": 0.02273944206535816,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066196,
        "gps_raw_lon_deg": 38.889855399999995,
        "gps_raw_abs_alt": 80.46800231933594,
        "gps_raw_velocity": 1.329999923706055,
        "position_lat_deg": 47.3066243,
        "position_lon_deg": 38.8899051,
        "position_abs_alt": 82.10600280761719,
        "position_real_alt": 3.3600001335144043,
        "heading_deg": 263.17,
        "odometry_pos_body_x": 17.27982521057129,
        "odometry_pos_body_y": -13.298590660095217,
        "odometry_pos_body_z": -3.012453317642212,
        "odometry_vel_body_x": 0.9553011655807496,
        "odometry_vel_body_y": 0.3754046559333801,
        "odometry_vel_body_z": 0.16375607252120972,
        "odometry_ang_vel_body_roll": 0.20452351868152616,
        "odometry_ang_vel_body_pitch": -0.36780527234077454,
        "odometry_ang_vel_body_yaw": -0.35353487730026245,
        "velocity_north_m_s": -0.17000000178813934,
        "velocity_east_m_s": -3.68999981880188,
        "velocity_down_m_s": -0.04999999701976776,
        "acceleration_forward": -1.1477206945419312,
        "acceleration_right": -3.4844229221343994,
        "acceleration_down": -8.178657531738281,
        "battery_id": 0,
        "battery_voltage": 12.921000480651855,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 7130,
        "udp_total": 148,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 119
      },
      {
        "id": 274,
        "datetime": "21/02/2023 02:04:53",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477b880>, <mavsdk.mission_raw.MissionItem object at 0xa477b6e8>, <mavsdk.mission_raw.MissionItem object at 0xa477b7d8>, <mavsdk.mission_raw.MissionItem object at 0xa477bf88>]",
        "mission_pause": 0,
        "time_usec": 138958551,
        "odometry_pos_body": "PositionBody: [x_m: 17.237018585205078, y_m: -13.419210433959961, z_m: -3.0097270011901855]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -20.6150966833256,
        "alg_manual_y": -20.389365231977113,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 20.09160423278809,
        "alg_manual_y_start": 5.398502826690674,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 88,
        "alg_manual_zero_x": -13.192258036074058,
        "alg_manual_zero_y": 18.840497018646815,
        "alg_manual_dist_to_point": 21,
        "alg_manual_angle_to_point": 325,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 268,
        "alg_manual_command": "rotate 133.31811184012633",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.4]",
        "timestamp": 137310011,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.6100000143051147,
        "current_climb_rate": -0.027813099324703217,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306619,
        "gps_raw_lon_deg": 38.8898527,
        "gps_raw_abs_alt": 80.47300720214844,
        "gps_raw_velocity": 0.8999999761581421,
        "position_lat_deg": 47.3066235,
        "position_lon_deg": 38.8898896,
        "position_abs_alt": 82.11900329589844,
        "position_real_alt": 3.372000217437744,
        "heading_deg": 260.56,
        "odometry_pos_body_x": 17.196317672729492,
        "odometry_pos_body_y": -13.537403106689451,
        "odometry_pos_body_z": -3.0022168159484863,
        "odometry_vel_body_x": 0.7487345337867737,
        "odometry_vel_body_y": 0.2982960045337677,
        "odometry_vel_body_z": 0.13485203683376312,
        "odometry_ang_vel_body_roll": 0.5345513224601746,
        "odometry_ang_vel_body_pitch": -0.010378299281001093,
        "odometry_ang_vel_body_yaw": -0.3033483028411865,
        "velocity_north_m_s": -0.14999999105930328,
        "velocity_east_m_s": -3.129999876022339,
        "velocity_down_m_s": -0.119999997317791,
        "acceleration_forward": -1.5129761695861816,
        "acceleration_right": 0.10766416043043137,
        "acceleration_down": -7.6486124992370605,
        "battery_id": 0,
        "battery_voltage": 12.930001258850098,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 1,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 7154,
        "udp_total": 150,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 120
      },
      {
        "id": 275,
        "datetime": "21/02/2023 02:04:54",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa476c4d8>, <mavsdk.mission_raw.MissionItem object at 0xa476c208>, <mavsdk.mission_raw.MissionItem object at 0xa477bc10>, <mavsdk.mission_raw.MissionItem object at 0xa477bfa0>]",
        "mission_pause": 0,
        "time_usec": 139278269,
        "odometry_pos_body": "PositionBody: [x_m: 17.123783111572266, y_m: -13.648439407348633, z_m: -2.9895925521850586]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -20.6150966833256,
        "alg_manual_y": -20.389365231977113,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 20.09160423278809,
        "alg_manual_y_start": 5.398502826690674,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 88,
        "alg_manual_zero_x": -13.192258036074058,
        "alg_manual_zero_y": 18.840497018646815,
        "alg_manual_dist_to_point": 21,
        "alg_manual_angle_to_point": 325,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 268,
        "alg_manual_command": "rotate 133.31811184012633",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.4]",
        "timestamp": 137639730,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.6200000047683716,
        "current_climb_rate": -0.05031025782227516,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066178,
        "gps_raw_lon_deg": 38.8898487,
        "gps_raw_abs_alt": 80.47100067138672,
        "gps_raw_velocity": 0.75,
        "position_lat_deg": 47.3066228,
        "position_lon_deg": 38.8898764,
        "position_abs_alt": 82.16000366210938,
        "position_real_alt": 3.4140002727508545,
        "heading_deg": 256.71,
        "odometry_pos_body_x": 17.08517074584961,
        "odometry_pos_body_y": -13.700834274291992,
        "odometry_pos_body_z": -2.985236883163452,
        "odometry_vel_body_x": 0.6003018021583557,
        "odometry_vel_body_y": 0.2247721254825592,
        "odometry_vel_body_z": 0.14002317190170288,
        "odometry_ang_vel_body_roll": -0.6588171720504761,
        "odometry_ang_vel_body_pitch": 0.032958220690488815,
        "odometry_ang_vel_body_yaw": -0.4089676141738891,
        "velocity_north_m_s": -0.12999999523162842,
        "velocity_east_m_s": -2.569999933242798,
        "velocity_down_m_s": -0.119999997317791,
        "acceleration_forward": 0.6245535612106323,
        "acceleration_right": 5.603333950042725,
        "acceleration_down": -10.677251815795898,
        "battery_id": 0,
        "battery_voltage": 12.930001258850098,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 11,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 7170,
        "udp_total": 150,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 120
      },
      {
        "id": 276,
        "datetime": "21/02/2023 02:04:54",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xb20126a0>, <mavsdk.mission_raw.MissionItem object at 0xa476c850>, <mavsdk.mission_raw.MissionItem object at 0xa4775538>, <mavsdk.mission_raw.MissionItem object at 0xa4775460>]",
        "mission_pause": 0,
        "time_usec": 139558018,
        "odometry_pos_body": "PositionBody: [x_m: 17.021568298339844, y_m: -13.78338623046875, z_m: -2.9814696311950684]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -20.6150966833256,
        "alg_manual_y": -20.389365231977113,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 20.09160423278809,
        "alg_manual_y_start": 5.398502826690674,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 88,
        "alg_manual_zero_x": -13.192258036074058,
        "alg_manual_zero_y": 18.840497018646815,
        "alg_manual_dist_to_point": 21,
        "alg_manual_angle_to_point": 325,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 268,
        "alg_manual_command": "rotate 133.31811184012633",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.4]",
        "timestamp": 137879505,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5999999642372131,
        "current_climb_rate": 0.014281945303082466,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066171,
        "gps_raw_lon_deg": 38.8898474,
        "gps_raw_abs_alt": 80.44800567626953,
        "gps_raw_velocity": 0.6100000143051147,
        "position_lat_deg": 47.3066222,
        "position_lon_deg": 38.8898677,
        "position_abs_alt": 82.16900634765625,
        "position_real_alt": 3.4230000972747803,
        "heading_deg": 251.2,
        "odometry_pos_body_x": 16.990598678588867,
        "odometry_pos_body_y": -13.832245826721191,
        "odometry_pos_body_z": -2.9902853965759277,
        "odometry_vel_body_x": 0.5305137634277344,
        "odometry_vel_body_y": 0.2414379119873047,
        "odometry_vel_body_z": -0.01672295294702053,
        "odometry_ang_vel_body_roll": 0.18741631507873535,
        "odometry_ang_vel_body_pitch": -0.4183143973350525,
        "odometry_ang_vel_body_yaw": -0.19361057877540588,
        "velocity_north_m_s": -0.1599999964237213,
        "velocity_east_m_s": -2.109999895095825,
        "velocity_down_m_s": 0.05999999865889549,
        "acceleration_forward": -0.7476248145103455,
        "acceleration_right": -1.8573250770568848,
        "acceleration_down": -7.747030258178711,
        "battery_id": 0,
        "battery_voltage": 12.930001258850098,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 1,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 7176,
        "udp_total": 150,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 120
      },
      {
        "id": 277,
        "datetime": "21/02/2023 02:04:54",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477e148>, <mavsdk.mission_raw.MissionItem object at 0xa477e5b0>, <mavsdk.mission_raw.MissionItem object at 0xa477ebf8>, <mavsdk.mission_raw.MissionItem object at 0xa477e070>]",
        "mission_pause": 0,
        "time_usec": 139782819,
        "odometry_pos_body": "PositionBody: [x_m: 16.939645767211914, y_m: -13.872482299804688, z_m: -3.0001182556152344]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -20.6150966833256,
        "alg_manual_y": -20.389365231977113,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 20.09160423278809,
        "alg_manual_y_start": 5.398502826690674,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 88,
        "alg_manual_zero_x": -13.192258036074058,
        "alg_manual_zero_y": 18.840497018646815,
        "alg_manual_dist_to_point": 21,
        "alg_manual_angle_to_point": 325,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 268,
        "alg_manual_command": "rotate 133.31811184012633",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.4]",
        "timestamp": 138124292,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5899999737739563,
        "current_climb_rate": 0.07203260064125061,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066158,
        "gps_raw_lon_deg": 38.8898452,
        "gps_raw_abs_alt": 80.44400024414062,
        "gps_raw_velocity": 0.5199999809265137,
        "position_lat_deg": 47.3066217,
        "position_lon_deg": 38.889862,
        "position_abs_alt": 82.16100311279297,
        "position_real_alt": 3.4150002002716064,
        "heading_deg": 246.74,
        "odometry_pos_body_x": 16.92821502685547,
        "odometry_pos_body_y": -13.88434886932373,
        "odometry_pos_body_z": -3.003805637359619,
        "odometry_vel_body_x": 0.529506504535675,
        "odometry_vel_body_y": 0.25575536489486694,
        "odometry_vel_body_z": -0.03607207536697388,
        "odometry_ang_vel_body_roll": -0.2263597548007965,
        "odometry_ang_vel_body_pitch": 0.17609116435050964,
        "odometry_ang_vel_body_yaw": -0.2133396863937378,
        "velocity_north_m_s": -0.1599999964237213,
        "velocity_east_m_s": -1.7999999523162842,
        "velocity_down_m_s": 0,
        "acceleration_forward": -1.0901141166687012,
        "acceleration_right": 0.43048518896102905,
        "acceleration_down": -15.865456581115724,
        "battery_id": 0,
        "battery_voltage": 12.930001258850098,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 7200,
        "udp_total": 150,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 121
      },
      {
        "id": 278,
        "datetime": "21/02/2023 02:04:54",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xb20126a0>, <mavsdk.mission_raw.MissionItem object at 0xa590c4f0>, <mavsdk.mission_raw.MissionItem object at 0xa4775e08>, <mavsdk.mission_raw.MissionItem object at 0xa477b8f8>]",
        "mission_pause": 0,
        "time_usec": 140042593,
        "odometry_pos_body": "PositionBody: [x_m: 16.843040466308594, y_m: -13.973189353942871, z_m: -3.0314295291900635]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -20.6150966833256,
        "alg_manual_y": -20.389365231977113,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 20.09160423278809,
        "alg_manual_y_start": 5.398502826690674,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 88,
        "alg_manual_zero_x": -13.192258036074058,
        "alg_manual_zero_y": 18.840497018646815,
        "alg_manual_dist_to_point": 21,
        "alg_manual_angle_to_point": 325,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 268,
        "alg_manual_command": "rotate 133.31811184012633",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.4]",
        "timestamp": 138349090,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5799999833106995,
        "current_climb_rate": 0.07896336913108826,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066152,
        "gps_raw_lon_deg": 38.8898441,
        "gps_raw_abs_alt": 80.44800567626953,
        "gps_raw_velocity": 0.5199999809265137,
        "position_lat_deg": 47.3066209,
        "position_lon_deg": 38.889856,
        "position_abs_alt": 82.17300415039062,
        "position_real_alt": 3.427000045776367,
        "heading_deg": 240.91,
        "odometry_pos_body_x": 16.810747146606445,
        "odometry_pos_body_y": -14.028142929077148,
        "odometry_pos_body_z": -3.055349588394165,
        "odometry_vel_body_x": 0.45492488145828247,
        "odometry_vel_body_y": 0.33960777521133423,
        "odometry_vel_body_z": -0.027364308014512066,
        "odometry_ang_vel_body_roll": 0.07058458030223846,
        "odometry_ang_vel_body_pitch": 0.4606760442256927,
        "odometry_ang_vel_body_yaw": -0.26707616448402405,
        "velocity_north_m_s": -0.14000000059604645,
        "velocity_east_m_s": -1.319999933242798,
        "velocity_down_m_s": -0.08999999612569809,
        "acceleration_forward": 0.12985625863075256,
        "acceleration_right": -1.2849056720733645,
        "acceleration_down": -9.755711555480955,
        "battery_id": 0,
        "battery_voltage": 12.930001258850098,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 7222,
        "udp_total": 151,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 122
      },
      {
        "id": 279,
        "datetime": "21/02/2023 02:04:55",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4775e08>, <mavsdk.mission_raw.MissionItem object at 0xa477d568>, <mavsdk.mission_raw.MissionItem object at 0xa477dbe0>, <mavsdk.mission_raw.MissionItem object at 0xa477b7d8>]",
        "mission_pause": 0,
        "time_usec": 140342326,
        "odometry_pos_body": "PositionBody: [x_m: 16.762317657470703, y_m: -14.08618450164795, z_m: -3.0848982334136963]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -20.6150966833256,
        "alg_manual_y": -20.389365231977113,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 20.09160423278809,
        "alg_manual_y_start": 5.398502826690674,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 88,
        "alg_manual_zero_x": -13.192258036074058,
        "alg_manual_zero_y": 18.840497018646815,
        "alg_manual_dist_to_point": 21,
        "alg_manual_angle_to_point": 325,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 268,
        "alg_manual_command": "rotate 133.31811184012633",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.4]",
        "timestamp": 138653820,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5600000023841858,
        "current_climb_rate": 0.0910254642367363,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066148,
        "gps_raw_lon_deg": 38.889843,
        "gps_raw_abs_alt": 80.46400451660156,
        "gps_raw_velocity": 0.4899999797344208,
        "position_lat_deg": 47.306619999999995,
        "position_lon_deg": 38.8898505,
        "position_abs_alt": 82.19300079345703,
        "position_real_alt": 3.44700026512146,
        "heading_deg": 235.47,
        "odometry_pos_body_x": 16.74821662902832,
        "odometry_pos_body_y": -14.121882438659668,
        "odometry_pos_body_z": -3.1109204292297363,
        "odometry_vel_body_x": 0.3595221936702728,
        "odometry_vel_body_y": 0.4038250744342804,
        "odometry_vel_body_z": -0.03548571094870567,
        "odometry_ang_vel_body_roll": 0.6684218049049377,
        "odometry_ang_vel_body_pitch": 0.41151008009910583,
        "odometry_ang_vel_body_yaw": -0.21333475410938263,
        "velocity_north_m_s": -0.119999997317791,
        "velocity_east_m_s": -1.0499999523162842,
        "velocity_down_m_s": 0,
        "acceleration_forward": 1.1020535230636597,
        "acceleration_right": 2.0074148178100586,
        "acceleration_down": -5.1941819190979,
        "battery_id": 0,
        "battery_voltage": 12.930001258850098,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 7222,
        "udp_total": 151,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 122
      },
      {
        "id": 280,
        "datetime": "21/02/2023 02:04:55",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4777ee0>, <mavsdk.mission_raw.MissionItem object at 0xa47776a0>, <mavsdk.mission_raw.MissionItem object at 0xa47779d0>, <mavsdk.mission_raw.MissionItem object at 0xa4777370>]",
        "mission_pause": 0,
        "time_usec": 140582113,
        "odometry_pos_body": "PositionBody: [x_m: 16.71945571899414, y_m: -14.165580749511719, z_m: -3.1514790058135986]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -20.6150966833256,
        "alg_manual_y": -20.389365231977113,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 20.09160423278809,
        "alg_manual_y_start": 5.398502826690674,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 88,
        "alg_manual_zero_x": -13.192258036074058,
        "alg_manual_zero_y": 18.840497018646815,
        "alg_manual_dist_to_point": 21,
        "alg_manual_angle_to_point": 325,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 268,
        "alg_manual_command": "rotate 133.31811184012633",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.4]",
        "timestamp": 138848647,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5399999618530273,
        "current_climb_rate": 0.08103743195533752,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306614499999995,
        "gps_raw_lon_deg": 38.8898419,
        "gps_raw_abs_alt": 80.47400665283203,
        "gps_raw_velocity": 0.4699999988079071,
        "position_lat_deg": 47.306619399999995,
        "position_lon_deg": 38.8898477,
        "position_abs_alt": 82.19200134277344,
        "position_real_alt": 3.446000099182129,
        "heading_deg": 231.27,
        "odometry_pos_body_x": 16.711721420288086,
        "odometry_pos_body_y": -14.183223724365234,
        "odometry_pos_body_z": -3.166804552078247,
        "odometry_vel_body_x": 0.25901010632514954,
        "odometry_vel_body_y": 0.3891203701496124,
        "odometry_vel_body_z": 0.032018113881349564,
        "odometry_ang_vel_body_roll": 1.1581021547317505,
        "odometry_ang_vel_body_pitch": -0.08471570909023285,
        "odometry_ang_vel_body_yaw": -0.29944419860839844,
        "velocity_north_m_s": -0.14999999105930328,
        "velocity_east_m_s": -0.8700000047683716,
        "velocity_down_m_s": 0.019999999552965164,
        "acceleration_forward": 1.2724769115447998,
        "acceleration_right": 0.26490697264671326,
        "acceleration_down": -10.902056694030762,
        "battery_id": 0,
        "battery_voltage": 12.930001258850098,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 0,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 7246,
        "udp_total": 151,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 122
      },
      {
        "id": 281,
        "datetime": "21/02/2023 02:04:55",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4777e08>, <mavsdk.mission_raw.MissionItem object at 0xa4777e68>, <mavsdk.mission_raw.MissionItem object at 0xa477ada8>, <mavsdk.mission_raw.MissionItem object at 0xa477d190>]",
        "mission_pause": 0,
        "time_usec": 140781938,
        "odometry_pos_body": "PositionBody: [x_m: 16.697399139404297, y_m: -14.216809272766113, z_m: -3.195556402206421]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -20.6150966833256,
        "alg_manual_y": -20.389365231977113,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 20.09160423278809,
        "alg_manual_y_start": 5.398502826690674,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 88,
        "alg_manual_zero_x": -13.192258036074058,
        "alg_manual_zero_y": 18.840497018646815,
        "alg_manual_dist_to_point": 21,
        "alg_manual_angle_to_point": 325,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 268,
        "alg_manual_command": "rotate 133.31811184012633",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.4]",
        "timestamp": 139168367,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5600000023841858,
        "current_climb_rate": -0.0632624626159668,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066142,
        "gps_raw_lon_deg": 38.889840199999995,
        "gps_raw_abs_alt": 80.48500061035156,
        "gps_raw_velocity": 0.3400000035762787,
        "position_lat_deg": 47.3066183,
        "position_lon_deg": 38.8898446,
        "position_abs_alt": 82.17200469970703,
        "position_real_alt": 3.4260001182556152,
        "heading_deg": 226.43,
        "odometry_pos_body_x": 16.692960739135742,
        "odometry_pos_body_y": -14.241430282592772,
        "odometry_pos_body_z": -3.219435453414917,
        "odometry_vel_body_x": 0.04650784283876419,
        "odometry_vel_body_y": 0.3312227427959442,
        "odometry_vel_body_z": 0.1219501569867134,
        "odometry_ang_vel_body_roll": 0.1644124984741211,
        "odometry_ang_vel_body_pitch": -0.1374184936285019,
        "odometry_ang_vel_body_yaw": -0.3324487805366516,
        "velocity_north_m_s": -0.19999998807907104,
        "velocity_east_m_s": -0.6299999952316284,
        "velocity_down_m_s": 0.05999999865889549,
        "acceleration_forward": 0.3862161934375763,
        "acceleration_right": -3.2149643898010254,
        "acceleration_down": -10.26931858062744,
        "battery_id": 0,
        "battery_voltage": 12.930001258850098,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 7268,
        "udp_total": 151,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 122
      },
      {
        "id": 282,
        "datetime": "21/02/2023 02:04:55",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477a430>, <mavsdk.mission_raw.MissionItem object at 0xa477a550>, <mavsdk.mission_raw.MissionItem object at 0xa477a808>, <mavsdk.mission_raw.MissionItem object at 0xa477a478>]",
        "mission_pause": 0,
        "time_usec": 141081671,
        "odometry_pos_body": "PositionBody: [x_m: 16.697349548339844, y_m: -14.267147064208984, z_m: -3.245548963546753]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -20.6150966833256,
        "alg_manual_y": -20.389365231977113,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 20.09160423278809,
        "alg_manual_y_start": 5.398502826690674,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 88,
        "alg_manual_zero_x": -13.192258036074058,
        "alg_manual_zero_y": 18.840497018646815,
        "alg_manual_dist_to_point": 21,
        "alg_manual_angle_to_point": 325,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 268,
        "alg_manual_command": "rotate 133.31811184012633",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.4]",
        "timestamp": 139528049,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5799999833106995,
        "current_climb_rate": -0.23961269855499268,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066142,
        "gps_raw_lon_deg": 38.8898398,
        "gps_raw_abs_alt": 80.46600341796875,
        "gps_raw_velocity": 0.1599999964237213,
        "position_lat_deg": 47.3066171,
        "position_lon_deg": 38.8898422,
        "position_abs_alt": 82.17500305175781,
        "position_real_alt": 3.42900013923645,
        "heading_deg": 221.45,
        "odometry_pos_body_x": 16.705564498901367,
        "odometry_pos_body_y": -14.27268409729004,
        "odometry_pos_body_z": -3.2559003829956055,
        "odometry_vel_body_x": -0.1648748815059662,
        "odometry_vel_body_y": 0.19648298621177673,
        "odometry_vel_body_z": 0.3128855526447296,
        "odometry_ang_vel_body_roll": -0.23728936910629272,
        "odometry_ang_vel_body_pitch": 0.12997642159461975,
        "odometry_ang_vel_body_yaw": -0.3883814811706543,
        "velocity_north_m_s": -0.1899999976158142,
        "velocity_east_m_s": -0.5399999618530273,
        "velocity_down_m_s": -0.07000000029802322,
        "acceleration_forward": 0.6976256370544434,
        "acceleration_right": 2.6432876586914062,
        "acceleration_down": -6.240279674530029,
        "battery_id": 0,
        "battery_voltage": 12.94200038909912,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 25,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 7293,
        "udp_total": 154,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 123
      },
      {
        "id": 283,
        "datetime": "21/02/2023 02:04:56",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa5913388>, <mavsdk.mission_raw.MissionItem object at 0xa477b970>, <mavsdk.mission_raw.MissionItem object at 0xa477b460>, <mavsdk.mission_raw.MissionItem object at 0xa477b5b0>]",
        "mission_pause": 0,
        "time_usec": 141411388,
        "odometry_pos_body": "PositionBody: [x_m: 16.735706329345703, y_m: -14.264559745788574, z_m: -3.2428929805755615]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -20.6150966833256,
        "alg_manual_y": -20.389365231977113,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 20.09160423278809,
        "alg_manual_y_start": 5.398502826690674,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 88,
        "alg_manual_zero_x": -13.192258036074058,
        "alg_manual_zero_y": 18.840497018646815,
        "alg_manual_dist_to_point": 21,
        "alg_manual_angle_to_point": 325,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 268,
        "alg_manual_command": "rotate 133.31811184012633",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.4]",
        "timestamp": 139892762,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.6200000047683716,
        "current_climb_rate": -0.39006680250167847,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066148,
        "gps_raw_lon_deg": 38.889839599999995,
        "gps_raw_abs_alt": 80.33600616455078,
        "gps_raw_velocity": 0.25,
        "position_lat_deg": 47.306616,
        "position_lon_deg": 38.8898406,
        "position_abs_alt": 82.20700073242188,
        "position_real_alt": 3.4610002040863037,
        "heading_deg": 217,
        "odometry_pos_body_x": 16.75766372680664,
        "odometry_pos_body_y": -14.248899459838867,
        "odometry_pos_body_z": -3.224266767501831,
        "odometry_vel_body_x": -0.3587885797023773,
        "odometry_vel_body_y": -0.047998134046792984,
        "odometry_vel_body_z": 0.3747866451740265,
        "odometry_ang_vel_body_roll": -1.0619158744812012,
        "odometry_ang_vel_body_pitch": -0.15563847124576569,
        "odometry_ang_vel_body_yaw": -0.48185649514198303,
        "velocity_north_m_s": -0.17000000178813934,
        "velocity_east_m_s": -0.5699999928474426,
        "velocity_down_m_s": -0.07000000029802322,
        "acceleration_forward": 1.489894151687622,
        "acceleration_right": -1.7425816059112549,
        "acceleration_down": -11.609941482543944,
        "battery_id": 0,
        "battery_voltage": 12.94200038909912,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 1,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 7293,
        "udp_total": 154,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 123
      },
      {
        "id": 284,
        "datetime": "21/02/2023 02:04:56",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477e040>, <mavsdk.mission_raw.MissionItem object at 0xa477e0e8>, <mavsdk.mission_raw.MissionItem object at 0xa477ee80>, <mavsdk.mission_raw.MissionItem object at 0xa477e430>]",
        "mission_pause": 0,
        "time_usec": 141621197,
        "odometry_pos_body": "PositionBody: [x_m: 16.789091110229492, y_m: -14.224886894226074, z_m: -3.2010762691497803]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -20.6150966833256,
        "alg_manual_y": -20.389365231977113,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 20.09160423278809,
        "alg_manual_y_start": 5.398502826690674,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 88,
        "alg_manual_zero_x": -13.192258036074058,
        "alg_manual_zero_y": 18.840497018646815,
        "alg_manual_dist_to_point": 21,
        "alg_manual_angle_to_point": 325,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 268,
        "alg_manual_command": "rotate 133.31811184012633",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.4]",
        "timestamp": 140172477,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5999999642372131,
        "current_climb_rate": -0.2287873923778534,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066152,
        "gps_raw_lon_deg": 38.88984,
        "gps_raw_abs_alt": 80.24300384521484,
        "gps_raw_velocity": 0.25,
        "position_lat_deg": 47.3066153,
        "position_lon_deg": 38.8898393,
        "position_abs_alt": 82.24800109863281,
        "position_real_alt": 3.502000093460083,
        "heading_deg": 212.91,
        "odometry_pos_body_x": 16.841323852539062,
        "odometry_pos_body_y": -14.18577480316162,
        "odometry_pos_body_z": -3.170457363128662,
        "odometry_vel_body_x": -0.4697139263153076,
        "odometry_vel_body_y": -0.10011056065559389,
        "odometry_vel_body_z": 0.22191812098026276,
        "odometry_ang_vel_body_roll": 0.8074851036071777,
        "odometry_ang_vel_body_pitch": 0.14048875868320465,
        "odometry_ang_vel_body_yaw": -0.29844626784324646,
        "velocity_north_m_s": -0.07000000029802322,
        "velocity_east_m_s": -0.5699999928474426,
        "velocity_down_m_s": -0.07000000029802322,
        "acceleration_forward": 1.7772296667099,
        "acceleration_right": -0.5370935201644897,
        "acceleration_down": -7.216381549835205,
        "battery_id": 0,
        "battery_voltage": 12.94200038909912,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 1,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 7315,
        "udp_total": 154,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 124
      },
      {
        "id": 285,
        "datetime": "21/02/2023 02:04:56",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477bbe0>, <mavsdk.mission_raw.MissionItem object at 0xa477b670>, <mavsdk.mission_raw.MissionItem object at 0xa477b118>, <mavsdk.mission_raw.MissionItem object at 0xa477bb20>]",
        "mission_pause": 0,
        "time_usec": 141950903,
        "odometry_pos_body": "PositionBody: [x_m: 16.904001235961914, y_m: -14.14352798461914, z_m: -3.1587209701538086]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -20.6150966833256,
        "alg_manual_y": -20.389365231977113,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 20.09160423278809,
        "alg_manual_y_start": 5.398502826690674,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 88,
        "alg_manual_zero_x": -13.192258036074058,
        "alg_manual_zero_y": 18.840497018646815,
        "alg_manual_dist_to_point": 21,
        "alg_manual_angle_to_point": 325,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 268,
        "alg_manual_command": "rotate 133.31811184012633",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.4]",
        "timestamp": 140497187,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.6100000143051147,
        "current_climb_rate": -0.267548143863678,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066158,
        "gps_raw_lon_deg": 38.8898405,
        "gps_raw_abs_alt": 80.15400695800781,
        "gps_raw_velocity": 0.429999977350235,
        "position_lat_deg": 47.3066147,
        "position_lon_deg": 38.889837799999995,
        "position_abs_alt": 82.33100128173828,
        "position_real_alt": 3.585000276565552,
        "heading_deg": 206.42,
        "odometry_pos_body_x": 16.955326080322266,
        "odometry_pos_body_y": -14.11090087890625,
        "odometry_pos_body_z": -3.1503243446350098,
        "odometry_vel_body_x": -0.4832174479961395,
        "odometry_vel_body_y": -0.15716440975666046,
        "odometry_vel_body_z": 0.26352447271347046,
        "odometry_ang_vel_body_roll": 0.7123574614524841,
        "odometry_ang_vel_body_pitch": -0.2957867681980133,
        "odometry_ang_vel_body_yaw": -0.266867458820343,
        "velocity_north_m_s": 0.009999999776482582,
        "velocity_east_m_s": -0.44999998807907104,
        "velocity_down_m_s": 0,
        "acceleration_forward": 1.032907009124756,
        "acceleration_right": 0.5429601669311523,
        "acceleration_down": -9.114089012145996,
        "battery_id": 0,
        "battery_voltage": 12.94200038909912,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 7339,
        "udp_total": 155,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 125
      },
      {
        "id": 286,
        "datetime": "21/02/2023 02:04:57",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4780070>, <mavsdk.mission_raw.MissionItem object at 0xa4780e50>, <mavsdk.mission_raw.MissionItem object at 0xa4780b80>, <mavsdk.mission_raw.MissionItem object at 0xa47802f8>]",
        "mission_pause": 0,
        "time_usec": 142215684,
        "odometry_pos_body": "PositionBody: [x_m: 17.009479522705078, y_m: -14.068229675292969, z_m: -3.125812530517578]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -20.6150966833256,
        "alg_manual_y": -20.389365231977113,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 20.09160423278809,
        "alg_manual_y_start": 5.398502826690674,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 88,
        "alg_manual_zero_x": -13.192258036074058,
        "alg_manual_zero_y": 18.840497018646815,
        "alg_manual_dist_to_point": 21,
        "alg_manual_angle_to_point": 325,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 268,
        "alg_manual_command": "rotate 133.31811184012633",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.4]",
        "timestamp": 140831891,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.6499999761581421,
        "current_climb_rate": -0.34179791808128357,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066173,
        "gps_raw_lon_deg": 38.889842,
        "gps_raw_abs_alt": 80,
        "gps_raw_velocity": 0.4899999797344208,
        "position_lat_deg": 47.306614499999995,
        "position_lon_deg": 38.8898367,
        "position_abs_alt": 82.406005859375,
        "position_real_alt": 3.659000158309937,
        "heading_deg": 197.69,
        "odometry_pos_body_x": 17.070045471191406,
        "odometry_pos_body_y": -14.014657974243164,
        "odometry_pos_body_z": -3.068031072616577,
        "odometry_vel_body_x": -0.35101598501205444,
        "odometry_vel_body_y": -0.20252080261707303,
        "odometry_vel_body_z": 0.32386380434036255,
        "odometry_ang_vel_body_roll": 0.3807491958141327,
        "odometry_ang_vel_body_pitch": -0.05166077986359596,
        "odometry_ang_vel_body_yaw": -0.21232666075229645,
        "velocity_north_m_s": 0.14999999105930328,
        "velocity_east_m_s": -0.26999998092651367,
        "velocity_down_m_s": 0.17999999225139618,
        "acceleration_forward": 2.7565581798553467,
        "acceleration_right": -0.31780028343200684,
        "acceleration_down": -2.009577989578247,
        "battery_id": 0,
        "battery_voltage": 12.94200038909912,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 7362,
        "udp_total": 155,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 125
      },
      {
        "id": 287,
        "datetime": "21/02/2023 02:04:57",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4780ce8>, <mavsdk.mission_raw.MissionItem object at 0xa4780388>, <mavsdk.mission_raw.MissionItem object at 0xa4780958>, <mavsdk.mission_raw.MissionItem object at 0xa58fcd90>]",
        "mission_pause": 0,
        "time_usec": 142555370,
        "odometry_pos_body": "PositionBody: [x_m: 17.12447166442871, y_m: -13.95936107635498, z_m: -3.0226974487304688]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -20.6150966833256,
        "alg_manual_y": -20.389365231977113,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 20.09160423278809,
        "alg_manual_y_start": 5.398502826690674,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 88,
        "alg_manual_zero_x": -13.192258036074058,
        "alg_manual_zero_y": 18.840497018646815,
        "alg_manual_dist_to_point": 21,
        "alg_manual_angle_to_point": 325,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 268,
        "alg_manual_command": "rotate 133.31811184012633",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.4]",
        "timestamp": 141176586,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.6699999570846558,
        "current_climb_rate": -0.3093695044517517,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306618,
        "gps_raw_lon_deg": 38.8898427,
        "gps_raw_abs_alt": 79.90300750732422,
        "gps_raw_velocity": 0.4899999797344208,
        "position_lat_deg": 47.3066147,
        "position_lon_deg": 38.8898363,
        "position_abs_alt": 82.43600463867188,
        "position_real_alt": 3.689000129699707,
        "heading_deg": 190.86,
        "odometry_pos_body_x": 17.164918899536133,
        "odometry_pos_body_y": -13.917088508605955,
        "odometry_pos_body_z": -2.978203773498535,
        "odometry_vel_body_x": -0.25296494364738464,
        "odometry_vel_body_y": -0.26078131794929504,
        "odometry_vel_body_z": 0.2679084837436676,
        "odometry_ang_vel_body_roll": 0.2669004797935486,
        "odometry_ang_vel_body_pitch": 0.2809605896472931,
        "odometry_ang_vel_body_yaw": -0.3389241099357605,
        "velocity_north_m_s": 0.2800000011920929,
        "velocity_east_m_s": -0.009999999776482582,
        "velocity_down_m_s": 0.3799999952316284,
        "acceleration_forward": 0.7781079411506653,
        "acceleration_right": 2.572709083557129,
        "acceleration_down": -5.942285060882568,
        "battery_id": 0,
        "battery_voltage": 12.94200038909912,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 4,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 7386,
        "udp_total": 155,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 125
      },
      {
        "id": 288,
        "datetime": "21/02/2023 02:04:57",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa476c4d8>, <mavsdk.mission_raw.MissionItem object at 0xa5913a78>, <mavsdk.mission_raw.MissionItem object at 0xa477afa0>, <mavsdk.mission_raw.MissionItem object at 0xa477e2b0>]",
        "mission_pause": 0,
        "time_usec": 142885077,
        "odometry_pos_body": "PositionBody: [x_m: 17.218486785888672, y_m: -13.849879264831543, z_m: -2.912245273590088]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -20.6150966833256,
        "alg_manual_y": -20.389365231977113,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 20.09160423278809,
        "alg_manual_y_start": 5.398502826690674,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 88,
        "alg_manual_zero_x": -13.192258036074058,
        "alg_manual_zero_y": 18.840497018646815,
        "alg_manual_dist_to_point": 21,
        "alg_manual_angle_to_point": 325,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 268,
        "alg_manual_command": "rotate 133.31811184012633",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.4]",
        "timestamp": 141431362,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.6800000071525574,
        "current_climb_rate": -0.18277917802333832,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306619,
        "gps_raw_lon_deg": 38.889844,
        "gps_raw_abs_alt": 79.79200744628906,
        "gps_raw_velocity": 0.3199999928474426,
        "position_lat_deg": 47.306615,
        "position_lon_deg": 38.889836599999995,
        "position_abs_alt": 82.41000366210938,
        "position_real_alt": 3.6640002727508545,
        "heading_deg": 187.5,
        "odometry_pos_body_x": 17.237211227416992,
        "odometry_pos_body_y": -13.821136474609377,
        "odometry_pos_body_z": -2.8734664916992188,
        "odometry_vel_body_x": -0.14548534154891968,
        "odometry_vel_body_y": -0.24974572658538816,
        "odometry_vel_body_z": 0.15104940533638,
        "odometry_ang_vel_body_roll": 0.49834179878234863,
        "odometry_ang_vel_body_pitch": 0.09573394805192947,
        "odometry_ang_vel_body_yaw": -0.3661353588104248,
        "velocity_north_m_s": 0.3700000047683716,
        "velocity_east_m_s": 0.119999997317791,
        "velocity_down_m_s": 0.32999998331069946,
        "acceleration_forward": -1.063017725944519,
        "acceleration_right": 2.030634880065918,
        "acceleration_down": -12.645316123962402,
        "battery_id": 0,
        "battery_voltage": 12.94200038909912,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 14,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 7402,
        "udp_total": 155,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 125
      },
      {
        "id": 289,
        "datetime": "21/02/2023 02:04:57",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477cf28>, <mavsdk.mission_raw.MissionItem object at 0xa4777748>, <mavsdk.mission_raw.MissionItem object at 0xa476ca60>, <mavsdk.mission_raw.MissionItem object at 0xa476c940>]",
        "mission_pause": 0,
        "time_usec": 143174821,
        "odometry_pos_body": "PositionBody: [x_m: 17.271377563476562, y_m: -13.764378547668457, z_m: -2.83280086517334]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -20.6150966833256,
        "alg_manual_y": -20.389365231977113,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 20.09160423278809,
        "alg_manual_y_start": 5.398502826690674,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 88,
        "alg_manual_zero_x": -13.192258036074058,
        "alg_manual_zero_y": 18.840497018646815,
        "alg_manual_dist_to_point": 21,
        "alg_manual_angle_to_point": 325,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 268,
        "alg_manual_command": "rotate 133.31811184012633",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.4]",
        "timestamp": 141626189,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.6699999570846558,
        "current_climb_rate": -0.029845040291547775,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066196,
        "gps_raw_lon_deg": 38.8898453,
        "gps_raw_abs_alt": 79.71200561523438,
        "gps_raw_velocity": 0.2800000011920929,
        "position_lat_deg": 47.306615699999995,
        "position_lon_deg": 38.8898374,
        "position_abs_alt": 82.35700225830078,
        "position_real_alt": 3.611000061035156,
        "heading_deg": 183.13,
        "odometry_pos_body_x": 17.29106903076172,
        "odometry_pos_body_y": -13.719958305358888,
        "odometry_pos_body_z": -2.801701307296753,
        "odometry_vel_body_x": -0.0383387915790081,
        "odometry_vel_body_y": -0.15502922236919403,
        "odometry_vel_body_z": -0.04372453689575195,
        "odometry_ang_vel_body_roll": 0.755165696144104,
        "odometry_ang_vel_body_pitch": 0.17706291377544403,
        "odometry_ang_vel_body_yaw": -0.31324681639671326,
        "velocity_north_m_s": 0.4599999785423279,
        "velocity_east_m_s": 0.12999999523162842,
        "velocity_down_m_s": 0.2299999892711639,
        "acceleration_forward": -1.3747614622116089,
        "acceleration_right": -2.517951488494873,
        "acceleration_down": -19.95944023132324,
        "battery_id": 0,
        "battery_voltage": 12.84000015258789,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 12,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 7420,
        "udp_total": 156,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 126
      },
      {
        "id": 290,
        "datetime": "21/02/2023 02:04:58",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4780718>, <mavsdk.mission_raw.MissionItem object at 0xa4780388>, <mavsdk.mission_raw.MissionItem object at 0xa47800a0>, <mavsdk.mission_raw.MissionItem object at 0xa47805b0>]",
        "mission_pause": 0,
        "time_usec": 143544493,
        "odometry_pos_body": "PositionBody: [x_m: 17.30381965637207, y_m: -13.676980972290039, z_m: -2.7891793251037598]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -20.6150966833256,
        "alg_manual_y": -20.389365231977113,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 20.09160423278809,
        "alg_manual_y_start": 5.398502826690674,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 88,
        "alg_manual_zero_x": -13.192258036074058,
        "alg_manual_zero_y": 18.840497018646815,
        "alg_manual_dist_to_point": 21,
        "alg_manual_angle_to_point": 325,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 268,
        "alg_manual_command": "rotate 133.31811184012633",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.4]",
        "timestamp": 141950903,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.6299999952316284,
        "current_climb_rate": 0.16822673380374908,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066198,
        "gps_raw_lon_deg": 38.8898462,
        "gps_raw_abs_alt": 79.74700164794922,
        "gps_raw_velocity": 0.14000000059604645,
        "position_lat_deg": 47.3066167,
        "position_lon_deg": 38.889838399999995,
        "position_abs_alt": 82.33500671386719,
        "position_real_alt": 3.5890002250671387,
        "heading_deg": 178.56,
        "odometry_pos_body_x": 17.308229446411133,
        "odometry_pos_body_y": -13.647563934326172,
        "odometry_pos_body_z": -2.7933950424194336,
        "odometry_vel_body_x": -0.013109453022480013,
        "odometry_vel_body_y": -0.06616419553756714,
        "odometry_vel_body_z": -0.16616231203079224,
        "odometry_ang_vel_body_roll": -0.11413685232400894,
        "odometry_ang_vel_body_pitch": -0.2417687326669693,
        "odometry_ang_vel_body_yaw": -0.42596203088760376,
        "velocity_north_m_s": 0.44999998807907104,
        "velocity_east_m_s": 0.12999999523162842,
        "velocity_down_m_s": 0.29999998211860657,
        "acceleration_forward": 0.22805006802082065,
        "acceleration_right": 2.0799782276153564,
        "acceleration_down": -9.966608047485352,
        "battery_id": 0,
        "battery_voltage": 12.84000015258789,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 13,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 7442,
        "udp_total": 156,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 126
      },
      {
        "id": 291,
        "datetime": "21/02/2023 02:04:58",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477e898>, <mavsdk.mission_raw.MissionItem object at 0xa477e070>, <mavsdk.mission_raw.MissionItem object at 0xa477e280>, <mavsdk.mission_raw.MissionItem object at 0xa477e1f0>]",
        "mission_pause": 0,
        "time_usec": 143884251,
        "odometry_pos_body": "PositionBody: [x_m: 17.314435958862305, y_m: -13.62582778930664, z_m: -2.7974767684936523]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -20.6150966833256,
        "alg_manual_y": -20.389365231977113,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 20.09160423278809,
        "alg_manual_y_start": 5.398502826690674,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 88,
        "alg_manual_zero_x": -13.192258036074058,
        "alg_manual_zero_y": 18.840497018646815,
        "alg_manual_dist_to_point": 21,
        "alg_manual_angle_to_point": 325,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 268,
        "alg_manual_command": "rotate 133.31811184012633",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.4]",
        "timestamp": 142140753,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.6200000047683716,
        "current_climb_rate": 0.28075283765792847,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066198,
        "gps_raw_lon_deg": 38.8898465,
        "gps_raw_abs_alt": 79.77900695800781,
        "gps_raw_velocity": 0.119999997317791,
        "position_lat_deg": 47.3066173,
        "position_lon_deg": 38.8898391,
        "position_abs_alt": 82.3080062866211,
        "position_real_alt": 3.562000274658203,
        "heading_deg": 175.38,
        "odometry_pos_body_x": 17.315778732299805,
        "odometry_pos_body_y": -13.614153861999512,
        "odometry_pos_body_z": -2.8026914596557617,
        "odometry_vel_body_x": -0.044619228690862656,
        "odometry_vel_body_y": -0.024251539260149,
        "odometry_vel_body_z": -0.25096023082733154,
        "odometry_ang_vel_body_roll": -0.1509522795677185,
        "odometry_ang_vel_body_pitch": -0.5706049799919128,
        "odometry_ang_vel_body_yaw": -0.3799702525138855,
        "velocity_north_m_s": 0.3700000047683716,
        "velocity_east_m_s": 0.17000000178813934,
        "velocity_down_m_s": 0.3199999928474426,
        "acceleration_forward": 0.4566481113433838,
        "acceleration_right": -2.1781563758850098,
        "acceleration_down": -1.3557690382003784,
        "battery_id": 0,
        "battery_voltage": 12.84000015258789,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 23,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 7450,
        "udp_total": 156,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 126
      },
      {
        "id": 292,
        "datetime": "21/02/2023 02:04:58",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4778358>, <mavsdk.mission_raw.MissionItem object at 0xa4778cb8>, <mavsdk.mission_raw.MissionItem object at 0xa4778d30>, <mavsdk.mission_raw.MissionItem object at 0xa4778190>]",
        "mission_pause": 0,
        "time_usec": 144123982,
        "odometry_pos_body": "PositionBody: [x_m: 17.316965103149414, y_m: -13.604098320007324, z_m: -2.824014186859131]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -20.6150966833256,
        "alg_manual_y": -20.389365231977113,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 20.09160423278809,
        "alg_manual_y_start": 5.398502826690674,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 88,
        "alg_manual_zero_x": -13.192258036074058,
        "alg_manual_zero_y": 18.840497018646815,
        "alg_manual_dist_to_point": 21,
        "alg_manual_angle_to_point": 325,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 268,
        "alg_manual_command": "rotate 133.31811184012633",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.4]",
        "timestamp": 142330566,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5899999737739563,
        "current_climb_rate": 0.31680309772491455,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306619999999995,
        "gps_raw_lon_deg": 38.8898466,
        "gps_raw_abs_alt": 79.8550033569336,
        "gps_raw_velocity": 0.05999999865889549,
        "position_lat_deg": 47.3066179,
        "position_lon_deg": 38.8898399,
        "position_abs_alt": 82.25300598144531,
        "position_real_alt": 3.507000207901001,
        "heading_deg": 171.94,
        "odometry_pos_body_x": 17.316621780395508,
        "odometry_pos_body_y": -13.602516174316406,
        "odometry_pos_body_z": -2.848353385925293,
        "odometry_vel_body_x": -0.055126890540122986,
        "odometry_vel_body_y": 0.06439471244812012,
        "odometry_vel_body_z": -0.3453444242477417,
        "odometry_ang_vel_body_roll": 0.34655117988586426,
        "odometry_ang_vel_body_pitch": -0.20772720873355863,
        "odometry_ang_vel_body_yaw": -0.29542696475982666,
        "velocity_north_m_s": 0.32999998331069946,
        "velocity_east_m_s": 0.1899999976158142,
        "velocity_down_m_s": 0.29999998211860657,
        "acceleration_forward": 0.02517012506723404,
        "acceleration_right": 3.670498132705689,
        "acceleration_down": -15.311989784240724,
        "battery_id": 0,
        "battery_voltage": 12.84000015258789,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 7475,
        "udp_total": 157,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 127
      },
      {
        "id": 293,
        "datetime": "21/02/2023 02:04:59",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477c880>, <mavsdk.mission_raw.MissionItem object at 0xa477c6e8>, <mavsdk.mission_raw.MissionItem object at 0xa4781928>, <mavsdk.mission_raw.MissionItem object at 0xa4781778>]",
        "mission_pause": 0,
        "time_usec": 144443721,
        "odometry_pos_body": "PositionBody: [x_m: 17.307247161865234, y_m: -13.604060173034668, z_m: -2.8970158100128174]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -20.6150966833256,
        "alg_manual_y": -20.389365231977113,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 20.09160423278809,
        "alg_manual_y_start": 5.398502826690674,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 88,
        "alg_manual_zero_x": -13.192258036074058,
        "alg_manual_zero_y": 18.840497018646815,
        "alg_manual_dist_to_point": 21,
        "alg_manual_angle_to_point": 325,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 268,
        "alg_manual_command": "rotate 133.31811184012633",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.4]",
        "timestamp": 142605324,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5600000023841858,
        "current_climb_rate": 0.3097861707210541,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066201,
        "gps_raw_lon_deg": 38.8898466,
        "gps_raw_abs_alt": 79.99600219726562,
        "gps_raw_velocity": 0.029999999329447743,
        "position_lat_deg": 47.3066189,
        "position_lon_deg": 38.8898414,
        "position_abs_alt": 82.13600158691406,
        "position_real_alt": 3.390000104904175,
        "heading_deg": 165.1,
        "odometry_pos_body_x": 17.302806854248047,
        "odometry_pos_body_y": -13.600095748901367,
        "odometry_pos_body_z": -2.9698615074157715,
        "odometry_vel_body_x": -0.033422715961933136,
        "odometry_vel_body_y": 0.06878769397735596,
        "odometry_vel_body_z": -0.2985525131225586,
        "odometry_ang_vel_body_roll": 1.1935036182403564,
        "odometry_ang_vel_body_pitch": 0.24656587839126587,
        "odometry_ang_vel_body_yaw": -0.4279596507549286,
        "velocity_north_m_s": 0.2299999892711639,
        "velocity_east_m_s": 0.1899999976158142,
        "velocity_down_m_s": 0.2199999988079071,
        "acceleration_forward": -0.5864755511283875,
        "acceleration_right": 3.131232738494873,
        "acceleration_down": -8.217802047729492,
        "battery_id": 0,
        "battery_voltage": 12.84000015258789,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 0,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 7497,
        "udp_total": 157,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 127
      },
      {
        "id": 294,
        "datetime": "21/02/2023 02:04:59",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477e508>, <mavsdk.mission_raw.MissionItem object at 0xa477e5e0>, <mavsdk.mission_raw.MissionItem object at 0xa477bfb8>, <mavsdk.mission_raw.MissionItem object at 0xa477b088>]",
        "mission_pause": 0,
        "time_usec": 144843347,
        "odometry_pos_body": "PositionBody: [x_m: 17.289201736450195, y_m: -13.586861610412598, z_m: -3.0280728340148926]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -20.6150966833256,
        "alg_manual_y": -20.389365231977113,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 20.09160423278809,
        "alg_manual_y_start": 5.398502826690674,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 88,
        "alg_manual_zero_x": -13.192258036074058,
        "alg_manual_zero_y": 18.840497018646815,
        "alg_manual_dist_to_point": 21,
        "alg_manual_angle_to_point": 325,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 268,
        "alg_manual_command": "rotate 133.31811184012633",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.4]",
        "timestamp": 143004971,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.550000011920929,
        "current_climb_rate": 0.1690336912870407,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066198,
        "gps_raw_lon_deg": 38.8898465,
        "gps_raw_abs_alt": 80.1570053100586,
        "gps_raw_velocity": 0.07999999821186066,
        "position_lat_deg": 47.3066195,
        "position_lon_deg": 38.8898426,
        "position_abs_alt": 82.04800415039062,
        "position_real_alt": 3.302000045776367,
        "heading_deg": 160.03,
        "odometry_pos_body_x": 17.279495239257812,
        "odometry_pos_body_y": -13.58137035369873,
        "odometry_pos_body_z": -3.060932159423828,
        "odometry_vel_body_x": 0.026874717324972153,
        "odometry_vel_body_y": 0.06616432964801788,
        "odometry_vel_body_z": -0.16641497611999512,
        "odometry_ang_vel_body_roll": 0.4542450308799744,
        "odometry_ang_vel_body_pitch": -0.10935191810131072,
        "odometry_ang_vel_body_yaw": -0.5454777479171753,
        "velocity_north_m_s": 0.14000000059604645,
        "velocity_east_m_s": 0.17999999225139618,
        "velocity_down_m_s": 0.05999999865889549,
        "acceleration_forward": -0.1911897361278534,
        "acceleration_right": 1.2961838245391846,
        "acceleration_down": -10.05350112915039,
        "battery_id": 0,
        "battery_voltage": 12.84000015258789,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 22,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 7519,
        "udp_total": 159,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 127
      },
      {
        "id": 295,
        "datetime": "21/02/2023 02:04:59",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4781bb0>, <mavsdk.mission_raw.MissionItem object at 0xa4781c40>, <mavsdk.mission_raw.MissionItem object at 0xa4781ca0>, <mavsdk.mission_raw.MissionItem object at 0xa47818c8>]",
        "mission_pause": 0,
        "time_usec": 145183049,
        "odometry_pos_body": "PositionBody: [x_m: 17.261262893676758, y_m: -13.577319145202637, z_m: -3.107602834701538]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -20.6150966833256,
        "alg_manual_y": -20.389365231977113,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 20.09160423278809,
        "alg_manual_y_start": 5.398502826690674,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 88,
        "alg_manual_zero_x": -13.192258036074058,
        "alg_manual_zero_y": 18.840497018646815,
        "alg_manual_dist_to_point": 21,
        "alg_manual_angle_to_point": 325,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 268,
        "alg_manual_command": "rotate 133.31811184012633",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.4]",
        "timestamp": 143189808,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.550000011920929,
        "current_climb_rate": 0.05206749960780144,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066196,
        "gps_raw_lon_deg": 38.8898466,
        "gps_raw_abs_alt": 80.25300598144531,
        "gps_raw_velocity": 0.05999999865889549,
        "position_lat_deg": 47.3066198,
        "position_lon_deg": 38.889843299999995,
        "position_abs_alt": 82.00100708007812,
        "position_real_alt": 3.255000114440918,
        "heading_deg": 155.83,
        "odometry_pos_body_x": 17.253538131713867,
        "odometry_pos_body_y": -13.571910858154297,
        "odometry_pos_body_z": -3.1378939151763916,
        "odometry_vel_body_x": 0.013112473301589487,
        "odometry_vel_body_y": 0.023190496489405632,
        "odometry_vel_body_z": -0.03267308324575424,
        "odometry_ang_vel_body_roll": 0.3995931744575501,
        "odometry_ang_vel_body_pitch": 0.3324348032474518,
        "odometry_ang_vel_body_yaw": -0.5192471146583557,
        "velocity_north_m_s": 0.07000000029802322,
        "velocity_east_m_s": 0.14999999105930328,
        "velocity_down_m_s": -0.03999999910593033,
        "acceleration_forward": 0.36947450041770935,
        "acceleration_right": 2.4129087924957275,
        "acceleration_down": -1.9242751598358152,
        "battery_id": 0,
        "battery_voltage": 12.915000915527344,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 44,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 7541,
        "udp_total": 160,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 128
      },
      {
        "id": 296,
        "datetime": "21/02/2023 02:05:00",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa476c940>, <mavsdk.mission_raw.MissionItem object at 0xa476ce98>, <mavsdk.mission_raw.MissionItem object at 0xa476c9b8>, <mavsdk.mission_raw.MissionItem object at 0xa476cec8>]",
        "mission_pause": 0,
        "time_usec": 145517752,
        "odometry_pos_body": "PositionBody: [x_m: 17.25374984741211, y_m: -13.563193321228027, z_m: -3.1662635803222656]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -20.6150966833256,
        "alg_manual_y": -20.389365231977113,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 20.09160423278809,
        "alg_manual_y_start": 5.398502826690674,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 88,
        "alg_manual_zero_x": -13.192258036074058,
        "alg_manual_zero_y": 18.840497018646815,
        "alg_manual_dist_to_point": 21,
        "alg_manual_angle_to_point": 325,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 268,
        "alg_manual_command": "rotate 133.31811184012633",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.4]",
        "timestamp": 143484549,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.550000011920929,
        "current_climb_rate": -0.018608734011650085,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066197,
        "gps_raw_lon_deg": 38.8898467,
        "gps_raw_abs_alt": 80.27200317382812,
        "gps_raw_velocity": 0.019999999552965164,
        "position_lat_deg": 47.306619999999995,
        "position_lon_deg": 38.8898444,
        "position_abs_alt": 81.97100067138672,
        "position_real_alt": 3.2250001430511475,
        "heading_deg": 149.6,
        "odometry_pos_body_x": 17.266128540039062,
        "odometry_pos_body_y": -13.553300857543944,
        "odometry_pos_body_z": -3.184580087661743,
        "odometry_vel_body_x": 0.02512909099459648,
        "odometry_vel_body_y": -0.0918201208114624,
        "odometry_vel_body_z": 0.07918613404035568,
        "odometry_ang_vel_body_roll": -0.2622641921043396,
        "odometry_ang_vel_body_pitch": 0.24393436312675476,
        "odometry_ang_vel_body_yaw": -0.5735448598861694,
        "velocity_north_m_s": 0.029999999329447743,
        "velocity_east_m_s": 0.05999999865889549,
        "velocity_down_m_s": -0.1599999964237213,
        "acceleration_forward": 0.35890305042266846,
        "acceleration_right": 3.8629753589630127,
        "acceleration_down": -11.188945770263672,
        "battery_id": 0,
        "battery_voltage": 12.915000915527344,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 7565,
        "udp_total": 160,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 128
      },
      {
        "id": 297,
        "datetime": "21/02/2023 02:05:00",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4775e08>, <mavsdk.mission_raw.MissionItem object at 0xa4781268>, <mavsdk.mission_raw.MissionItem object at 0xa4781658>, <mavsdk.mission_raw.MissionItem object at 0xa4781100>]",
        "mission_pause": 0,
        "time_usec": 145857451,
        "odometry_pos_body": "PositionBody: [x_m: 17.28483772277832, y_m: -13.54605770111084, z_m: -3.199779987335205]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -20.6150966833256,
        "alg_manual_y": -20.389365231977113,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 20.09160423278809,
        "alg_manual_y_start": 5.398502826690674,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 88,
        "alg_manual_zero_x": -13.192258036074058,
        "alg_manual_zero_y": 18.840497018646815,
        "alg_manual_dist_to_point": 21,
        "alg_manual_angle_to_point": 325,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 268,
        "alg_manual_command": "rotate 133.31811184012633",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.25]",
        "timestamp": 143814255,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5600000023841858,
        "current_climb_rate": -0.1011243611574173,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066199,
        "gps_raw_lon_deg": 38.889846899999995,
        "gps_raw_abs_alt": 80.281005859375,
        "gps_raw_velocity": 0.07999999821186066,
        "position_lat_deg": 47.3066201,
        "position_lon_deg": 38.889845,
        "position_abs_alt": 81.98200225830078,
        "position_real_alt": 3.236000061035156,
        "heading_deg": 142.27,
        "odometry_pos_body_x": 17.298200607299805,
        "odometry_pos_body_y": -13.542200088500977,
        "odometry_pos_body_z": -3.203437328338623,
        "odometry_vel_body_x": -0.0015222295187413692,
        "odometry_vel_body_y": -0.15097592771053314,
        "odometry_vel_body_z": 0.13603879511356354,
        "odometry_ang_vel_body_roll": -0.4706043303012848,
        "odometry_ang_vel_body_pitch": -0.03473712503910065,
        "odometry_ang_vel_body_yaw": -0.4050011336803436,
        "velocity_north_m_s": 0.029999999329447743,
        "velocity_east_m_s": -0.009999999776482582,
        "velocity_down_m_s": -0.28999999165534973,
        "acceleration_forward": -0.7199307084083557,
        "acceleration_right": -0.664466381072998,
        "acceleration_down": -4.829956531524658,
        "battery_id": 0,
        "battery_voltage": 12.915000915527344,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 7587,
        "udp_total": 160,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 128
      },
      {
        "id": 298,
        "datetime": "21/02/2023 02:05:00",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4781748>, <mavsdk.mission_raw.MissionItem object at 0xa47806b8>, <mavsdk.mission_raw.MissionItem object at 0xa4780a78>, <mavsdk.mission_raw.MissionItem object at 0xa4780b50>]",
        "mission_pause": 0,
        "time_usec": 146177170,
        "odometry_pos_body": "PositionBody: [x_m: 17.322956085205078, y_m: -13.535901069641113, z_m: -3.1971380710601807]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": -20.6150966833256,
        "alg_manual_y": -20.389365231977113,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 20.09160423278809,
        "alg_manual_y_start": 5.398502826690674,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 88,
        "alg_manual_zero_x": -13.192258036074058,
        "alg_manual_zero_y": 18.840497018646815,
        "alg_manual_dist_to_point": 21,
        "alg_manual_angle_to_point": 325,
        "alg_manual_dist_to_point_old": 15,
        "alg_manual_angle_to_point_old": 268,
        "alg_manual_command": "rotate 133.31811184012633",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, -0.12]",
        "timestamp": 144158951,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5699999928474426,
        "current_climb_rate": -0.13593989610671997,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066202,
        "gps_raw_lon_deg": 38.889847,
        "gps_raw_abs_alt": 80.28900146484375,
        "gps_raw_velocity": 0.12999999523162842,
        "position_lat_deg": 47.3066201,
        "position_lon_deg": 38.8898452,
        "position_abs_alt": 82.03500366210938,
        "position_real_alt": 3.2890002727508545,
        "heading_deg": 133.51,
        "odometry_pos_body_x": 17.333370208740234,
        "odometry_pos_body_y": -13.534231185913086,
        "odometry_pos_body_z": -3.190926313400269,
        "odometry_vel_body_x": 0.017548326402902603,
        "odometry_vel_body_y": -0.08286017179489136,
        "odometry_vel_body_z": 0.17508968710899353,
        "odometry_ang_vel_body_roll": 0.37688687443733215,
        "odometry_ang_vel_body_pitch": 0.03958270326256752,
        "odometry_ang_vel_body_yaw": -0.11029709130525588,
        "velocity_north_m_s": -0.04999999701976776,
        "velocity_east_m_s": -0.07999999821186066,
        "velocity_down_m_s": -0.32999998331069946,
        "acceleration_forward": -0.6636748313903809,
        "acceleration_right": -2.949465274810791,
        "acceleration_down": -3.535771131515503,
        "battery_id": 0,
        "battery_voltage": 12.915000915527344,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 10,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 7595,
        "udp_total": 161,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 129
      },
      {
        "id": 299,
        "datetime": "21/02/2023 02:05:01",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4780ad8>, <mavsdk.mission_raw.MissionItem object at 0xa4780b68>, <mavsdk.mission_raw.MissionItem object at 0xa477bd90>, <mavsdk.mission_raw.MissionItem object at 0xa477bf88>]",
        "mission_pause": 0,
        "time_usec": 146476905,
        "odometry_pos_body": "PositionBody: [x_m: 17.34650421142578, y_m: -13.525620460510254, z_m: -3.165044069290161]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "off",
        "alg_manual_inputs": "[0.8, -0.15, 0.5, 0.0]",
        "timestamp": 144503716,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5899999737739563,
        "current_climb_rate": -0.19348397850990295,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306620599999995,
        "gps_raw_lon_deg": 38.8898473,
        "gps_raw_abs_alt": 80.22600555419922,
        "gps_raw_velocity": 0.05999999865889549,
        "position_lat_deg": 47.306619999999995,
        "position_lon_deg": 38.8898452,
        "position_abs_alt": 82.1240005493164,
        "position_real_alt": 3.384000062942505,
        "heading_deg": 122.7,
        "odometry_pos_body_x": 17.342140197753906,
        "odometry_pos_body_y": -13.522932052612305,
        "odometry_pos_body_z": -3.141695737838745,
        "odometry_vel_body_x": -0.05522993952035904,
        "odometry_vel_body_y": 0.11366037279367448,
        "odometry_vel_body_z": 0.184008851647377,
        "odometry_ang_vel_body_roll": -0.097845196723938,
        "odometry_ang_vel_body_pitch": -0.3849350512027741,
        "odometry_ang_vel_body_yaw": -0.16065320372581482,
        "velocity_north_m_s": -0.029999999329447743,
        "velocity_east_m_s": -0.009999999776482582,
        "velocity_down_m_s": -0.2299999892711639,
        "acceleration_forward": -0.4385601580142975,
        "acceleration_right": 0.6918522715568542,
        "acceleration_down": -10.341870307922363,
        "battery_id": 0,
        "battery_voltage": 12.915000915527344,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 6,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 7621,
        "udp_total": 163,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 129
      },
      {
        "id": 300,
        "datetime": "21/02/2023 02:05:01",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477cf58>, <mavsdk.mission_raw.MissionItem object at 0xa477c160>, <mavsdk.mission_raw.MissionItem object at 0xa477cd00>, <mavsdk.mission_raw.MissionItem object at 0xa477c850>]",
        "mission_pause": 0,
        "time_usec": 146816605,
        "odometry_pos_body": "PositionBody: [x_m: 17.331756591796875, y_m: -13.511658668518066, z_m: -3.106149435043335]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "off",
        "alg_manual_inputs": "[0.8, -0.15, 0.5, 0.0]",
        "timestamp": 144848342,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5999999642372131,
        "current_climb_rate": -0.17105567455291748,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066205,
        "gps_raw_lon_deg": 38.8898471,
        "gps_raw_abs_alt": 80.1820068359375,
        "gps_raw_velocity": 0.03999999910593033,
        "position_lat_deg": 47.3066198,
        "position_lon_deg": 38.8898455,
        "position_abs_alt": 82.21600341796875,
        "position_real_alt": 3.4700002670288086,
        "heading_deg": 112.44,
        "odometry_pos_body_x": 17.33067512512207,
        "odometry_pos_body_y": -13.501602172851562,
        "odometry_pos_body_z": -3.0979268550872803,
        "odometry_vel_body_x": 0.1866941899061203,
        "odometry_vel_body_y": 0.08359462767839432,
        "odometry_vel_body_z": 0.051465652883052826,
        "odometry_ang_vel_body_roll": 0.4929672777652741,
        "odometry_ang_vel_body_pitch": -0.9837101101875304,
        "odometry_ang_vel_body_yaw": -0.24391135573387143,
        "velocity_north_m_s": -0.07999999821186066,
        "velocity_east_m_s": 0,
        "velocity_down_m_s": -0.12999999523162842,
        "acceleration_forward": -0.9793984293937684,
        "acceleration_right": 1.3072259426116943,
        "acceleration_down": -16.176464080810547,
        "battery_id": 0,
        "battery_voltage": 12.915000915527344,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 24,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 7633,
        "udp_total": 163,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 129
      },
      {
        "id": 301,
        "datetime": "21/02/2023 02:05:01",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4782a00>, <mavsdk.mission_raw.MissionItem object at 0xa4782c40>, <mavsdk.mission_raw.MissionItem object at 0xa4782dc0>, <mavsdk.mission_raw.MissionItem object at 0xa47823a0>]",
        "mission_pause": 0,
        "time_usec": 147046405,
        "odometry_pos_body": "PositionBody: [x_m: 17.34378433227539, y_m: -13.442692756652832, z_m: -3.0798399448394775]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "off",
        "alg_manual_inputs": "[0.8, -0.15, 0.5, 0.0]",
        "timestamp": 145098123,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5999999642372131,
        "current_climb_rate": -0.0188610702753067,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066203,
        "gps_raw_lon_deg": 38.889848,
        "gps_raw_abs_alt": 80.13400268554688,
        "gps_raw_velocity": 0.25,
        "position_lat_deg": 47.3066195,
        "position_lon_deg": 38.8898456,
        "position_abs_alt": 82.3080062866211,
        "position_real_alt": 3.562000274658203,
        "heading_deg": 102.26,
        "odometry_pos_body_x": 17.359237670898438,
        "odometry_pos_body_y": -13.29762363433838,
        "odometry_pos_body_z": -3.0623202323913574,
        "odometry_vel_body_x": 0.9578497409820556,
        "odometry_vel_body_y": 0.015133434906601906,
        "odometry_vel_body_z": -0.2466353476047516,
        "odometry_ang_vel_body_roll": -0.3042721152305603,
        "odometry_ang_vel_body_pitch": 0.2030729353427887,
        "odometry_ang_vel_body_yaw": 0.05455783382058144,
        "velocity_north_m_s": -0.009999999776482582,
        "velocity_east_m_s": 0.009999999776482582,
        "velocity_down_m_s": -0.009999999776482582,
        "acceleration_forward": 1.2749038934707642,
        "acceleration_right": 2.7406885623931885,
        "acceleration_down": -3.974453449249267,
        "battery_id": 0,
        "battery_voltage": 12.891000747680664,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 7657,
        "udp_total": 164,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 130
      },
      {
        "id": 302,
        "datetime": "21/02/2023 02:05:02",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477db38>, <mavsdk.mission_raw.MissionItem object at 0xa477b0d0>, <mavsdk.mission_raw.MissionItem object at 0xa477b208>, <mavsdk.mission_raw.MissionItem object at 0xa477b0a0>]",
        "mission_pause": 0,
        "time_usec": 147376113,
        "odometry_pos_body": "PositionBody: [x_m: 17.376142501831055, y_m: -13.175971031188965, z_m: -3.053760290145874]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "off",
        "alg_manual_inputs": "[0.8, -0.15, 0.5, 0.0]",
        "timestamp": 145447817,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5799999833106995,
        "current_climb_rate": 0.02635011449456215,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066205,
        "gps_raw_lon_deg": 38.8898529,
        "gps_raw_abs_alt": 80.11800384521484,
        "gps_raw_velocity": 1.1100000143051147,
        "position_lat_deg": 47.3066195,
        "position_lon_deg": 38.8898458,
        "position_abs_alt": 82.34700012207031,
        "position_real_alt": 3.6010000705718994,
        "heading_deg": 93.52,
        "odometry_pos_body_x": 17.388383865356445,
        "odometry_pos_body_y": -12.99856185913086,
        "odometry_pos_body_z": -3.050597906112671,
        "odometry_vel_body_x": 1.4850783348083496,
        "odometry_vel_body_y": 0.06926000863313675,
        "odometry_vel_body_z": -0.3935098648071289,
        "odometry_ang_vel_body_roll": 0.002256121952086687,
        "odometry_ang_vel_body_pitch": 0.23174969851970673,
        "odometry_ang_vel_body_yaw": 0.16399958729743958,
        "velocity_north_m_s": 0.07000000029802322,
        "velocity_east_m_s": 0.029999999329447743,
        "velocity_down_m_s": 0.07999999821186066,
        "acceleration_forward": -0.2509751617908478,
        "acceleration_right": 3.4322845935821533,
        "acceleration_down": -8.254219055175781,
        "battery_id": 0,
        "battery_voltage": 12.891000747680664,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 7679,
        "udp_total": 164,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 130
      },
      {
        "id": 303,
        "datetime": "21/02/2023 02:05:02",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4780250>, <mavsdk.mission_raw.MissionItem object at 0xa4780898>, <mavsdk.mission_raw.MissionItem object at 0xa4780970>, <mavsdk.mission_raw.MissionItem object at 0xa4780c88>]",
        "mission_pause": 0,
        "time_usec": 147675849,
        "odometry_pos_body": "PositionBody: [x_m: 17.40007972717285, y_m: -12.757611274719238, z_m: -3.054868459701538]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "off",
        "alg_manual_inputs": "[0.8, -0.15, 0.5, 0.0]",
        "timestamp": 145712579,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5600000023841858,
        "current_climb_rate": 0.05562807992100715,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066205,
        "gps_raw_lon_deg": 38.8898569,
        "gps_raw_abs_alt": 80.12100219726562,
        "gps_raw_velocity": 1.459999918937683,
        "position_lat_deg": 47.3066197,
        "position_lon_deg": 38.889846,
        "position_abs_alt": 82.37800598144531,
        "position_real_alt": 3.632000207901001,
        "heading_deg": 88.32000000000001,
        "odometry_pos_body_x": 17.397851943969727,
        "odometry_pos_body_y": -12.459762573242188,
        "odometry_pos_body_z": -3.0658702850341797,
        "odometry_vel_body_x": 1.897969365119934,
        "odometry_vel_body_y": 0.16276758909225464,
        "odometry_vel_body_z": -0.46594852209091187,
        "odometry_ang_vel_body_roll": -0.26971232891082764,
        "odometry_ang_vel_body_pitch": -0.4806232750415802,
        "odometry_ang_vel_body_yaw": 0.14603522419929504,
        "velocity_north_m_s": 0.14000000059604645,
        "velocity_east_m_s": 0,
        "velocity_down_m_s": 0.09999999403953552,
        "acceleration_forward": 0.0723281055688858,
        "acceleration_right": 3.833539724349976,
        "acceleration_down": -10.195962905883787,
        "battery_id": 0,
        "battery_voltage": 12.891000747680664,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 7703,
        "udp_total": 164,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 130
      },
      {
        "id": 304,
        "datetime": "21/02/2023 02:05:02",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477d778>, <mavsdk.mission_raw.MissionItem object at 0xa477d130>, <mavsdk.mission_raw.MissionItem object at 0xa477dbc8>, <mavsdk.mission_raw.MissionItem object at 0xa477d610>]",
        "mission_pause": 0,
        "time_usec": 147985577,
        "odometry_pos_body": "PositionBody: [x_m: 17.388641357421875, y_m: -12.178773880004883, z_m: -3.0854153633117676]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "off",
        "alg_manual_inputs": "[0.8, -0.15, 0.5, 0.0]",
        "timestamp": 146037297,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5399999618530273,
        "current_climb_rate": 0.06502079963684082,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066204,
        "gps_raw_lon_deg": 38.8898675,
        "gps_raw_abs_alt": 80.14300537109375,
        "gps_raw_velocity": 2.0999999046325684,
        "position_lat_deg": 47.306619999999995,
        "position_lon_deg": 38.8898461,
        "position_abs_alt": 82.38300323486328,
        "position_real_alt": 3.63700008392334,
        "heading_deg": 85.97,
        "odometry_pos_body_x": 17.38247299194336,
        "odometry_pos_body_y": -11.891817092895508,
        "odometry_pos_body_z": -3.107844829559326,
        "odometry_vel_body_x": 2.2520973682403564,
        "odometry_vel_body_y": 0.19588562846183777,
        "odometry_vel_body_z": -0.5493134260177612,
        "odometry_ang_vel_body_roll": -0.1961115002632141,
        "odometry_ang_vel_body_pitch": -0.24936234951019287,
        "odometry_ang_vel_body_yaw": 0.06057046353816986,
        "velocity_north_m_s": 0.119999997317791,
        "velocity_east_m_s": 0,
        "velocity_down_m_s": 0.1599999964237213,
        "acceleration_forward": -1.2576971054077148,
        "acceleration_right": 2.3121349811553955,
        "acceleration_down": -12.654479026794434,
        "battery_id": 0,
        "battery_voltage": 12.891000747680664,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 7725,
        "udp_total": 165,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 131
      },
      {
        "id": 305,
        "datetime": "21/02/2023 02:05:03",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477c430>, <mavsdk.mission_raw.MissionItem object at 0xb2025b98>, <mavsdk.mission_raw.MissionItem object at 0xa476c808>, <mavsdk.mission_raw.MissionItem object at 0xa4782748>]",
        "mission_pause": 0,
        "time_usec": 148245345,
        "odometry_pos_body": "PositionBody: [x_m: 17.370595932006836, y_m: -11.581686973571777, z_m: -3.128436326980591]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "off",
        "alg_manual_inputs": "[0.8, -0.15, 0.5, 0.0]",
        "timestamp": 146371998,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5399999618530273,
        "current_climb_rate": 0.03011579811573029,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066203,
        "gps_raw_lon_deg": 38.8898739,
        "gps_raw_abs_alt": 80.14700317382812,
        "gps_raw_velocity": 2.3899998664855957,
        "position_lat_deg": 47.3066203,
        "position_lon_deg": 38.889846299999995,
        "position_abs_alt": 82.34900665283203,
        "position_real_alt": 3.6030001640319824,
        "heading_deg": 82.41,
        "odometry_pos_body_x": 17.364604949951172,
        "odometry_pos_body_y": -11.328324317932127,
        "odometry_pos_body_z": -3.1449952125549316,
        "odometry_vel_body_x": 2.511275291442871,
        "odometry_vel_body_y": 0.17327572405338287,
        "odometry_vel_body_z": -0.5538482666015625,
        "odometry_ang_vel_body_roll": 0.5034330487251282,
        "odometry_ang_vel_body_pitch": 0.24380238354206085,
        "odometry_ang_vel_body_yaw": -0.05390164628624916,
        "velocity_north_m_s": -0.09999999403953552,
        "velocity_east_m_s": -0.03999999910593033,
        "velocity_down_m_s": 0.1899999976158142,
        "acceleration_forward": 1.035213589668274,
        "acceleration_right": -1.80854594707489,
        "acceleration_down": -9.004611015319824,
        "battery_id": 0,
        "battery_voltage": 12.891000747680664,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 26,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 7750,
        "udp_total": 165,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 131
      },
      {
        "id": 306,
        "datetime": "21/02/2023 02:05:03",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4777e08>, <mavsdk.mission_raw.MissionItem object at 0xa476c4d8>, <mavsdk.mission_raw.MissionItem object at 0xa476caf0>, <mavsdk.mission_raw.MissionItem object at 0xa4775880>]",
        "mission_pause": 0,
        "time_usec": 148545082,
        "odometry_pos_body": "PositionBody: [x_m: 17.353412628173828, y_m: -10.785200119018555, z_m: -3.1794471740722656]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "off",
        "alg_manual_inputs": "[0.8, -0.15, 0.5, 0.0]",
        "timestamp": 146711698,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5299999713897705,
        "current_climb_rate": -0.034433986991643906,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066199,
        "gps_raw_lon_deg": 38.8898886,
        "gps_raw_abs_alt": 80.14000701904297,
        "gps_raw_velocity": 2.859999895095825,
        "position_lat_deg": 47.3066202,
        "position_lon_deg": 38.8898465,
        "position_abs_alt": 82.28400421142578,
        "position_real_alt": 3.5350000858306885,
        "heading_deg": 79.48,
        "odometry_pos_body_x": 17.339649200439453,
        "odometry_pos_body_y": -10.28775119781494,
        "odometry_pos_body_z": -3.210282564163208,
        "odometry_vel_body_x": 2.8764889240264893,
        "odometry_vel_body_y": 0.2141137421131134,
        "odometry_vel_body_z": -0.5926825404167175,
        "odometry_ang_vel_body_roll": -0.5352050065994263,
        "odometry_ang_vel_body_pitch": -0.2926171123981476,
        "odometry_ang_vel_body_yaw": 0.13515150547027588,
        "velocity_north_m_s": 0.05999999865889549,
        "velocity_east_m_s": 0.4599999785423279,
        "velocity_down_m_s": 0.019999999552965164,
        "acceleration_forward": 0.2269553393125534,
        "acceleration_right": 3.037519693374634,
        "acceleration_down": -15.026795387268066,
        "battery_id": 0,
        "battery_voltage": 12.891000747680664,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 7772,
        "udp_total": 165,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 131
      },
      {
        "id": 307,
        "datetime": "21/02/2023 02:05:03",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477ef88>, <mavsdk.mission_raw.MissionItem object at 0xa4775658>, <mavsdk.mission_raw.MissionItem object at 0xa4775580>, <mavsdk.mission_raw.MissionItem object at 0xa4775370>]",
        "mission_pause": 0,
        "time_usec": 148884829,
        "odometry_pos_body": "PositionBody: [x_m: 17.32062339782715, y_m: -9.756875991821289, z_m: -3.251624345779419]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "off",
        "alg_manual_inputs": "[0.8, -0.01, 0.5, 0.0]",
        "timestamp": 146971478,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5199999809265137,
        "current_climb_rate": -0.05047494173049927,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066197,
        "gps_raw_lon_deg": 38.8898967,
        "gps_raw_abs_alt": 80.13700103759766,
        "gps_raw_velocity": 3.0399999618530273,
        "position_lat_deg": 47.3066204,
        "position_lon_deg": 38.889848099999995,
        "position_abs_alt": 82.2540054321289,
        "position_real_alt": 3.508000135421753,
        "heading_deg": 81.48,
        "odometry_pos_body_x": 17.30506134033203,
        "odometry_pos_body_y": -9.430499076843262,
        "odometry_pos_body_z": -3.2729055881500244,
        "odometry_vel_body_x": 3.130690813064575,
        "odometry_vel_body_y": 0.2752048075199127,
        "odometry_vel_body_z": -0.6786487698554993,
        "odometry_ang_vel_body_roll": -0.8100976943969727,
        "odometry_ang_vel_body_pitch": 0.01768805831670761,
        "odometry_ang_vel_body_yaw": 0.03063990548253059,
        "velocity_north_m_s": 0.1599999964237213,
        "velocity_east_m_s": 1.079999923706055,
        "velocity_down_m_s": 0,
        "acceleration_forward": -1.6096162796020508,
        "acceleration_right": -4.167027473449707,
        "acceleration_down": -11.26964282989502,
        "battery_id": 0,
        "battery_voltage": 12.891000747680664,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 14,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 7786,
        "udp_total": 165,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 131
      },
      {
        "id": 308,
        "datetime": "21/02/2023 02:05:03",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477b868>, <mavsdk.mission_raw.MissionItem object at 0xa4777e08>, <mavsdk.mission_raw.MissionItem object at 0xa4777ec8>, <mavsdk.mission_raw.MissionItem object at 0xa47777f0>]",
        "mission_pause": 0,
        "time_usec": 149184550,
        "odometry_pos_body": "PositionBody: [x_m: 17.275680541992188, y_m: -8.746231079101562, z_m: -3.296241044998169]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "off",
        "alg_manual_inputs": "[0.8, 0.025, 0.5, 0.0]",
        "timestamp": 147276200,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5299999713897705,
        "current_climb_rate": -0.17605997622013092,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066196,
        "gps_raw_lon_deg": 38.889905299999995,
        "gps_raw_abs_alt": 80.11200714111328,
        "gps_raw_velocity": 3.240000009536743,
        "position_lat_deg": 47.306620599999995,
        "position_lon_deg": 38.889851,
        "position_abs_alt": 82.23500061035156,
        "position_real_alt": 3.489000082015991,
        "heading_deg": 83.44,
        "odometry_pos_body_x": 17.26436424255371,
        "odometry_pos_body_y": -8.389002799987793,
        "odometry_pos_body_z": -3.2964060306549072,
        "odometry_vel_body_x": 3.483203649520874,
        "odometry_vel_body_y": 0.34212401509284973,
        "odometry_vel_body_z": -0.5981261134147644,
        "odometry_ang_vel_body_roll": 0.11657367646694183,
        "odometry_ang_vel_body_pitch": -0.023766741156578064,
        "odometry_ang_vel_body_yaw": -0.07820668071508408,
        "velocity_north_m_s": 0.14000000059604645,
        "velocity_east_m_s": 1.5099999904632568,
        "velocity_down_m_s": -0.029999999329447743,
        "acceleration_forward": -2.0168251991271973,
        "acceleration_right": 3.296006202697754,
        "acceleration_down": -13.631950378417969,
        "battery_id": 0,
        "battery_voltage": 12.957000732421877,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 1,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 7796,
        "udp_total": 166,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 132
      },
      {
        "id": 309,
        "datetime": "21/02/2023 02:05:04",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4781970>, <mavsdk.mission_raw.MissionItem object at 0xa47812c8>, <mavsdk.mission_raw.MissionItem object at 0xa476ce98>, <mavsdk.mission_raw.MissionItem object at 0xa477bf40>]",
        "mission_pause": 0,
        "time_usec": 149414317,
        "odometry_pos_body": "PositionBody: [x_m: 17.24959945678711, y_m: -7.906858444213867, z_m: -3.294644832611084]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "off",
        "alg_manual_inputs": "[0.8, 0.15, 0.5, 0.0]",
        "timestamp": 147525979,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.550000011920929,
        "current_climb_rate": -0.2786235213279724,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066192,
        "gps_raw_lon_deg": 38.8899244,
        "gps_raw_abs_alt": 80.02200317382812,
        "gps_raw_velocity": 3.4700000286102295,
        "position_lat_deg": 47.3066208,
        "position_lon_deg": 38.8898552,
        "position_abs_alt": 82.23400115966797,
        "position_real_alt": 3.4880001544952393,
        "heading_deg": 85.13,
        "odometry_pos_body_x": 17.244815826416016,
        "odometry_pos_body_y": -7.680245399475098,
        "odometry_pos_body_z": -3.2934350967407227,
        "odometry_vel_body_x": 3.6787166595458984,
        "odometry_vel_body_y": 0.3717691898345947,
        "odometry_vel_body_z": -0.5343420505523682,
        "odometry_ang_vel_body_roll": -1.1817400455474854,
        "odometry_ang_vel_body_pitch": -0.2396407574415207,
        "odometry_ang_vel_body_yaw": -0.1304863691329956,
        "velocity_north_m_s": 0.04999999701976776,
        "velocity_east_m_s": 1.829999923706055,
        "velocity_down_m_s": -0.05999999865889549,
        "acceleration_forward": 0.009790267795324326,
        "acceleration_right": 1.314661145210266,
        "acceleration_down": -11.328047752380373,
        "battery_id": 0,
        "battery_voltage": 12.957000732421877,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 7818,
        "udp_total": 166,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 132
      },
      {
        "id": 310,
        "datetime": "21/02/2023 02:05:04",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa47778c8>, <mavsdk.mission_raw.MissionItem object at 0xa477c580>, <mavsdk.mission_raw.MissionItem object at 0xa477de50>, <mavsdk.mission_raw.MissionItem object at 0xa477d508>]",
        "mission_pause": 0,
        "time_usec": 149644158,
        "odometry_pos_body": "PositionBody: [x_m: 17.2403621673584, y_m: -7.010236740112305, z_m: -3.280395030975342]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "off",
        "alg_manual_inputs": "[0.8, 0.15, 0.5, 0.0]",
        "timestamp": 147790748,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5699999928474426,
        "current_climb_rate": -0.35072776675224304,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066192,
        "gps_raw_lon_deg": 38.8899349,
        "gps_raw_abs_alt": 79.96000671386719,
        "gps_raw_velocity": 3.919999837875366,
        "position_lat_deg": 47.3066207,
        "position_lon_deg": 38.8898641,
        "position_abs_alt": 82.26800537109375,
        "position_real_alt": 3.5210001468658447,
        "heading_deg": 85.64,
        "odometry_pos_body_x": 17.245317459106445,
        "odometry_pos_body_y": -6.183651924133301,
        "odometry_pos_body_z": -3.249647378921509,
        "odometry_vel_body_x": 4.042677402496338,
        "odometry_vel_body_y": 0.32192176580429077,
        "odometry_vel_body_z": -0.5855057835578918,
        "odometry_ang_vel_body_roll": -0.5124390125274658,
        "odometry_ang_vel_body_pitch": 0.07392990589141846,
        "odometry_ang_vel_body_yaw": 0.004716506693512201,
        "velocity_north_m_s": 0,
        "velocity_east_m_s": 2.3299999237060547,
        "velocity_down_m_s": -0.03999999910593033,
        "acceleration_forward": -1.2854853868484497,
        "acceleration_right": -1.6058911085128784,
        "acceleration_down": -10.859297752380373,
        "battery_id": 0,
        "battery_voltage": 12.957000732421877,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 3,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 7843,
        "udp_total": 166,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 132
      },
      {
        "id": 311,
        "datetime": "21/02/2023 02:05:04",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4782af0>, <mavsdk.mission_raw.MissionItem object at 0xa47824d8>, <mavsdk.mission_raw.MissionItem object at 0xa4782a48>, <mavsdk.mission_raw.MissionItem object at 0xa4782838>]",
        "mission_pause": 0,
        "time_usec": 149983817,
        "odometry_pos_body": "PositionBody: [x_m: 17.249338150024414, y_m: -5.585693359375, z_m: -3.229276418685913]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "off",
        "alg_manual_inputs": "[0.8, 0.15, 0.5, 0.0]",
        "timestamp": 148110465,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.550000011920929,
        "current_climb_rate": -0.2314886599779129,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306619,
        "gps_raw_lon_deg": 38.8899457,
        "gps_raw_abs_alt": 79.87800598144531,
        "gps_raw_velocity": 4.079999923706055,
        "position_lat_deg": 47.306620599999995,
        "position_lon_deg": 38.8898714,
        "position_abs_alt": 82.3080062866211,
        "position_real_alt": 3.561000108718872,
        "heading_deg": 85.73,
        "odometry_pos_body_x": 17.249483108520508,
        "odometry_pos_body_y": -5.326244354248047,
        "odometry_pos_body_z": -3.2260289192199707,
        "odometry_vel_body_x": 4.182377815246582,
        "odometry_vel_body_y": 0.33408915996551514,
        "odometry_vel_body_z": -0.5355499982833862,
        "odometry_ang_vel_body_roll": 0.01234766561537981,
        "odometry_ang_vel_body_pitch": 0.0909224897623062,
        "odometry_ang_vel_body_yaw": 0.0907795876264572,
        "velocity_north_m_s": 0,
        "velocity_east_m_s": 2.609999895095825,
        "velocity_down_m_s": -0.029999999329447743,
        "acceleration_forward": -0.5342602133750916,
        "acceleration_right": -0.9492416381835938,
        "acceleration_down": -5.788280010223389,
        "battery_id": 0,
        "battery_voltage": 12.957000732421877,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 16,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 7859,
        "udp_total": 167,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 133
      },
      {
        "id": 312,
        "datetime": "21/02/2023 02:05:04",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477b178>, <mavsdk.mission_raw.MissionItem object at 0xa477b760>, <mavsdk.mission_raw.MissionItem object at 0xa477b928>, <mavsdk.mission_raw.MissionItem object at 0xa477ba90>]",
        "mission_pause": 0,
        "time_usec": 150223603,
        "odometry_pos_body": "PositionBody: [x_m: 17.252742767333984, y_m: -4.533092975616455, z_m: -3.2234537601470947]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "off",
        "alg_manual_inputs": "[0.8, 0.15, 0.5, 0.0]",
        "timestamp": 148330272,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5399999618530273,
        "current_climb_rate": -0.16118840873241425,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066193,
        "gps_raw_lon_deg": 38.8899687,
        "gps_raw_abs_alt": 79.77200317382812,
        "gps_raw_velocity": 4.429999828338623,
        "position_lat_deg": 47.3066205,
        "position_lon_deg": 38.8898793,
        "position_abs_alt": 82.34800720214844,
        "position_real_alt": 3.6020002365112305,
        "heading_deg": 86.25,
        "odometry_pos_body_x": 17.256383895874023,
        "odometry_pos_body_y": -3.989384651184082,
        "odometry_pos_body_z": -3.226496696472168,
        "odometry_vel_body_x": 4.319435119628906,
        "odometry_vel_body_y": 0.3390069007873535,
        "odometry_vel_body_z": -0.8036098480224609,
        "odometry_ang_vel_body_roll": 0.9939336180686952,
        "odometry_ang_vel_body_pitch": -0.18004605174064636,
        "odometry_ang_vel_body_yaw": -0.008763273246586323,
        "velocity_north_m_s": 0,
        "velocity_east_m_s": 2.859999895095825,
        "velocity_down_m_s": 0.029999999329447743,
        "acceleration_forward": -0.1617114394903183,
        "acceleration_right": -4.055593013763428,
        "acceleration_down": -3.5887720584869385,
        "battery_id": 0,
        "battery_voltage": 12.957000732421877,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 0,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 7865,
        "udp_total": 167,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 133
      },
      {
        "id": 313,
        "datetime": "21/02/2023 02:05:05",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477b370>, <mavsdk.mission_raw.MissionItem object at 0xa477ba60>, <mavsdk.mission_raw.MissionItem object at 0xa477bfb8>, <mavsdk.mission_raw.MissionItem object at 0xa477bb20>]",
        "mission_pause": 0,
        "time_usec": 150443409,
        "odometry_pos_body": "PositionBody: [x_m: 17.26198387145996, y_m: -3.34032940864563, z_m: -3.2371292114257812]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "off",
        "alg_manual_inputs": "[0.8, 0.15, 0.5, 0.0]",
        "timestamp": 148620016,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5099999904632568,
        "current_climb_rate": -0.09609827399253844,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306619399999995,
        "gps_raw_lon_deg": 38.889980699999995,
        "gps_raw_abs_alt": 79.72700500488281,
        "gps_raw_velocity": 4.509999752044678,
        "position_lat_deg": 47.3066203,
        "position_lon_deg": 38.88989,
        "position_abs_alt": 82.39700317382812,
        "position_real_alt": 3.650000095367432,
        "heading_deg": 85.51,
        "odometry_pos_body_x": 17.2685489654541,
        "odometry_pos_body_y": -2.7251572608947754,
        "odometry_pos_body_z": -3.2503645420074463,
        "odometry_vel_body_x": 4.555020332336426,
        "odometry_vel_body_y": 0.3522234559059143,
        "odometry_vel_body_z": -0.7067458033561707,
        "odometry_ang_vel_body_roll": -1.049172282218933,
        "odometry_ang_vel_body_pitch": -0.12662948668003082,
        "odometry_ang_vel_body_yaw": -0.08000438660383224,
        "velocity_north_m_s": -0.04999999701976776,
        "velocity_east_m_s": 3.1499998569488525,
        "velocity_down_m_s": 0.03999999910593033,
        "acceleration_forward": -0.9450384378433228,
        "acceleration_right": 5.30491304397583,
        "acceleration_down": -11.68515968322754,
        "battery_id": 0,
        "battery_voltage": 12.957000732421877,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 1,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 7889,
        "udp_total": 167,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 133
      },
      {
        "id": 314,
        "datetime": "21/02/2023 02:05:05",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa59132b0>, <mavsdk.mission_raw.MissionItem object at 0xa477a220>, <mavsdk.mission_raw.MissionItem object at 0xa477ae38>, <mavsdk.mission_raw.MissionItem object at 0xa477a898>]",
        "mission_pause": 0,
        "time_usec": 150743145,
        "odometry_pos_body": "PositionBody: [x_m: 17.273733139038086, y_m: -2.102720022201538, z_m: -3.2596256732940674]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "off",
        "alg_manual_inputs": "[0.8, 0.15, 0.5, 0.0]",
        "timestamp": 148849814,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5199999809265137,
        "current_climb_rate": -0.13480892777442932,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066195,
        "gps_raw_lon_deg": 38.8900058,
        "gps_raw_abs_alt": 79.63600158691406,
        "gps_raw_velocity": 4.929999828338623,
        "position_lat_deg": 47.306619999999995,
        "position_lon_deg": 38.8898997,
        "position_abs_alt": 82.4520034790039,
        "position_real_alt": 3.705000162124634,
        "heading_deg": 84.71000000000001,
        "odometry_pos_body_x": 17.278478622436523,
        "odometry_pos_body_y": -1.4312975406646729,
        "odometry_pos_body_z": -3.254723310470581,
        "odometry_vel_body_x": 4.716500759124756,
        "odometry_vel_body_y": 0.35717275738716125,
        "odometry_vel_body_z": -0.799807608127594,
        "odometry_ang_vel_body_roll": -0.11539017409086227,
        "odometry_ang_vel_body_pitch": 0.16705557703971863,
        "odometry_ang_vel_body_yaw": 0.06366550177335739,
        "velocity_north_m_s": -0.07000000029802322,
        "velocity_east_m_s": 3.359999895095825,
        "velocity_down_m_s": 0.12999999523162842,
        "acceleration_forward": -1.311951994895935,
        "acceleration_right": 4.930974960327148,
        "acceleration_down": -8.412592887878418,
        "battery_id": 0,
        "battery_voltage": 12.957000732421877,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 4,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 7900,
        "udp_total": 167,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 133
      },
      {
        "id": 315,
        "datetime": "21/02/2023 02:05:05",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa476ca60>, <mavsdk.mission_raw.MissionItem object at 0xa476c9b8>, <mavsdk.mission_raw.MissionItem object at 0xa477e988>, <mavsdk.mission_raw.MissionItem object at 0xa477e280>]",
        "mission_pause": 0,
        "time_usec": 151112872,
        "odometry_pos_body": "PositionBody: [x_m: 17.278179168701172, y_m: -0.2734197676181793, z_m: -3.242192029953003]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "off",
        "alg_manual_inputs": "[0.8, 0.15, 0.5, 0.0]",
        "timestamp": 149149549,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5399999618530273,
        "current_climb_rate": -0.2559393048286438,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066196,
        "gps_raw_lon_deg": 38.8900185,
        "gps_raw_abs_alt": 79.58800506591797,
        "gps_raw_velocity": 4.769999980926514,
        "position_lat_deg": 47.3066197,
        "position_lon_deg": 38.8899115,
        "position_abs_alt": 82.4800033569336,
        "position_real_alt": 3.733000278472901,
        "heading_deg": 83.96000000000001,
        "odometry_pos_body_x": 17.286996841430664,
        "odometry_pos_body_y": 0.3810921311378479,
        "odometry_pos_body_z": -3.21942138671875,
        "odometry_vel_body_x": 4.8666090965271,
        "odometry_vel_body_y": 0.33054283261299133,
        "odometry_vel_body_z": -0.7297210693359375,
        "odometry_ang_vel_body_roll": 0.22200337052345276,
        "odometry_ang_vel_body_pitch": 0.21419958770275116,
        "odometry_ang_vel_body_yaw": -0.003268217202275991,
        "velocity_north_m_s": -0.04999999701976776,
        "velocity_east_m_s": 3.639999866485596,
        "velocity_down_m_s": 0.20999999344348907,
        "acceleration_forward": -3.2481865882873535,
        "acceleration_right": -1.540696144104004,
        "acceleration_down": -14.567208290100098,
        "battery_id": 0,
        "battery_voltage": 12.984000205993652,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 5,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 7928,
        "udp_total": 168,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 134
      },
      {
        "id": 316,
        "datetime": "21/02/2023 02:05:06",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa476c880>, <mavsdk.mission_raw.MissionItem object at 0xa477bf88>, <mavsdk.mission_raw.MissionItem object at 0xa477bd78>, <mavsdk.mission_raw.MissionItem object at 0xa477bdc0>]",
        "mission_pause": 0,
        "time_usec": 151442530,
        "odometry_pos_body": "PositionBody: [x_m: 17.304210662841797, y_m: 1.4003891944885254, z_m: -3.1929287910461426]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "off",
        "alg_manual_inputs": "[0.8, 0.15, 0.5, 0.0]",
        "timestamp": 149369358,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.550000011920929,
        "current_climb_rate": -0.26376551389694214,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066199,
        "gps_raw_lon_deg": 38.8900453,
        "gps_raw_abs_alt": 79.46900177001953,
        "gps_raw_velocity": 5.059999942779541,
        "position_lat_deg": 47.306619399999995,
        "position_lon_deg": 38.8899228,
        "position_abs_alt": 82.47600555419922,
        "position_real_alt": 3.7300002574920654,
        "heading_deg": 83.57000000000001,
        "odometry_pos_body_x": 17.315624237060547,
        "odometry_pos_body_y": 1.916422128677368,
        "odometry_pos_body_z": -3.1835732460021973,
        "odometry_vel_body_x": 4.973567962646484,
        "odometry_vel_body_y": 0.22165173292160031,
        "odometry_vel_body_z": -0.8559140563011169,
        "odometry_ang_vel_body_roll": 0.12522688508033752,
        "odometry_ang_vel_body_pitch": -0.48920613527297974,
        "odometry_ang_vel_body_yaw": 0.022621508687734604,
        "velocity_north_m_s": 0.03999999910593033,
        "velocity_east_m_s": 3.879999876022339,
        "velocity_down_m_s": 0.3100000023841858,
        "acceleration_forward": -3.0527093410491943,
        "acceleration_right": -4.249563694000244,
        "acceleration_down": -10.928481101989746,
        "battery_id": 0,
        "battery_voltage": 12.984000205993652,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 7950,
        "udp_total": 168,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 134
      },
      {
        "id": 317,
        "datetime": "21/02/2023 02:05:06",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa47828e0>, <mavsdk.mission_raw.MissionItem object at 0xa4782a78>, <mavsdk.mission_raw.MissionItem object at 0xa4782220>, <mavsdk.mission_raw.MissionItem object at 0xa4782688>]",
        "mission_pause": 0,
        "time_usec": 151752258,
        "odometry_pos_body": "PositionBody: [x_m: 17.349123001098633, y_m: 3.016356945037842, z_m: -3.1438894271850586]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "off",
        "alg_manual_inputs": "[0.8, 0.15, 0.5, 0.0]",
        "timestamp": 149624135,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5299999713897705,
        "current_climb_rate": -0.07400184124708176,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306621,
        "gps_raw_lon_deg": 38.8900867,
        "gps_raw_abs_alt": 79.26700592041016,
        "gps_raw_velocity": 5.309999942779541,
        "position_lat_deg": 47.306619399999995,
        "position_lon_deg": 38.8899392,
        "position_abs_alt": 82.44500732421875,
        "position_real_alt": 3.699000120162964,
        "heading_deg": 84.19,
        "odometry_pos_body_x": 17.426342010498047,
        "odometry_pos_body_y": 4.769583225250244,
        "odometry_pos_body_z": -3.104191780090332,
        "odometry_vel_body_x": 5.185720920562744,
        "odometry_vel_body_y": 0.14158707857131958,
        "odometry_vel_body_z": -0.7934716939926147,
        "odometry_ang_vel_body_roll": 0.5031464099884033,
        "odometry_ang_vel_body_pitch": 0.08027373999357224,
        "odometry_ang_vel_body_yaw": 0.09131407737731934,
        "velocity_north_m_s": 0.09999999403953552,
        "velocity_east_m_s": 4.170000076293945,
        "velocity_down_m_s": 0.2299999892711639,
        "acceleration_forward": -1.2458475828170776,
        "acceleration_right": 1.1624959707260132,
        "acceleration_down": -9.02164077758789,
        "battery_id": 0,
        "battery_voltage": 12.984000205993652,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 13,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 7987,
        "udp_total": 169,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 135
      },
      {
        "id": 318,
        "datetime": "21/02/2023 02:05:07",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4781220>, <mavsdk.mission_raw.MissionItem object at 0xa477e028>, <mavsdk.mission_raw.MissionItem object at 0xa477b550>, <mavsdk.mission_raw.MissionItem object at 0xa477bc10>]",
        "mission_pause": 0,
        "time_usec": 152316761,
        "odometry_pos_body": "PositionBody: [x_m: 17.476402282714844, y_m: 6.028007984161377, z_m: -3.121598482131958]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "off",
        "alg_manual_inputs": "[0.8, 0.15, 0.5, 0.0]",
        "timestamp": 150008791,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5099999904632568,
        "current_climb_rate": -0.017231641337275505,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306621799999995,
        "gps_raw_lon_deg": 38.8901149,
        "gps_raw_abs_alt": 79.20800018310547,
        "gps_raw_velocity": 5.319999694824219,
        "position_lat_deg": 47.3066195,
        "position_lon_deg": 38.8899579,
        "position_abs_alt": 82.40900421142578,
        "position_real_alt": 3.662000179290771,
        "heading_deg": 84.53,
        "odometry_pos_body_x": 17.54121971130371,
        "odometry_pos_body_y": 7.605221271514893,
        "odometry_pos_body_z": -3.138320207595825,
        "odometry_vel_body_x": 5.263157367706299,
        "odometry_vel_body_y": -0.06331310421228409,
        "odometry_vel_body_z": -0.8744691610336304,
        "odometry_ang_vel_body_roll": 0.12305425852537157,
        "odometry_ang_vel_body_pitch": 0.6198729872703552,
        "odometry_ang_vel_body_yaw": 0.0993327796459198,
        "velocity_north_m_s": 0.10999999940395357,
        "velocity_east_m_s": 4.369999885559082,
        "velocity_down_m_s": 0.12999999523162842,
        "acceleration_forward": -0.19861865043640137,
        "acceleration_right": -1.1630642414093018,
        "acceleration_down": -7.254157543182373,
        "battery_id": 0,
        "battery_voltage": 12.984000205993652,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 8021,
        "udp_total": 169,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 135
      },
      {
        "id": 319,
        "datetime": "21/02/2023 02:05:07",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477b850>, <mavsdk.mission_raw.MissionItem object at 0xa477bc88>, <mavsdk.mission_raw.MissionItem object at 0xa47774d8>, <mavsdk.mission_raw.MissionItem object at 0xa4777c58>]",
        "mission_pause": 0,
        "time_usec": 152886261,
        "odometry_pos_body": "PositionBody: [x_m: 17.593412399291992, y_m: 9.260543823242188, z_m: -3.1645548343658447]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "off",
        "alg_manual_inputs": "[0.8, 0.15, 0.5, 0.0]",
        "timestamp": 150288545,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5699999928474426,
        "current_climb_rate": -0.24703647196292877,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066228,
        "gps_raw_lon_deg": 38.8901571,
        "gps_raw_abs_alt": 79.07500457763672,
        "gps_raw_velocity": 5.119999885559082,
        "position_lat_deg": 47.3066195,
        "position_lon_deg": 38.889973999999995,
        "position_abs_alt": 82.40900421142578,
        "position_real_alt": 3.6640002727508545,
        "heading_deg": 85.18,
        "odometry_pos_body_x": 17.609020233154297,
        "odometry_pos_body_y": 9.984051704406738,
        "odometry_pos_body_z": -3.1551854610443115,
        "odometry_vel_body_x": 4.682620525360107,
        "odometry_vel_body_y": -0.8636021018028259,
        "odometry_vel_body_z": -1.3028266429901123,
        "odometry_ang_vel_body_roll": 0.5140418410301208,
        "odometry_ang_vel_body_pitch": -0.4607688188552856,
        "odometry_ang_vel_body_yaw": 0.6834160089492798,
        "velocity_north_m_s": 0.09999999403953552,
        "velocity_east_m_s": 4.549999713897705,
        "velocity_down_m_s": 0.08999999612569809,
        "acceleration_forward": -1.838785171508789,
        "acceleration_right": 4.842748165130615,
        "acceleration_down": -12.849567413330078,
        "battery_id": 0,
        "battery_voltage": 12.965999603271484,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 3,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 1,
        "status_type": "None",
        "status_text": "None",
        "tcp": 1,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 8044,
        "udp_total": 170,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 136
      },
      {
        "id": 320,
        "datetime": "21/02/2023 02:05:08",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4775c58>, <mavsdk.mission_raw.MissionItem object at 0xb0ed1550>, <mavsdk.mission_raw.MissionItem object at 0xa4781628>, <mavsdk.mission_raw.MissionItem object at 0xa47814f0>]",
        "mission_pause": 0,
        "time_usec": 153355907,
        "odometry_pos_body": "PositionBody: [x_m: 17.616905212402344, y_m: 11.452810287475586, z_m: -3.034106969833374]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 1,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3066322,
        "alg_manual_gps_lon_start": 38.8898405,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "rb",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.0]",
        "timestamp": 150583286,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.7599999904632568,
        "current_climb_rate": -0.6602618098258972,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066228,
        "gps_raw_lon_deg": 38.8901821,
        "gps_raw_abs_alt": 78.88800048828125,
        "gps_raw_velocity": 4.549999713897705,
        "position_lat_deg": 47.3066197,
        "position_lon_deg": 38.8899933,
        "position_abs_alt": 82.43800354003906,
        "position_real_alt": 3.692000150680542,
        "heading_deg": 85.16,
        "odometry_pos_body_x": 17.606746673583984,
        "odometry_pos_body_y": 12.312126159667969,
        "odometry_pos_body_z": -2.895536184310913,
        "odometry_vel_body_x": 3.6896321773529057,
        "odometry_vel_body_y": -1.639360785484314,
        "odometry_vel_body_z": -1.1968035697937012,
        "odometry_ang_vel_body_roll": -0.8106691837310791,
        "odometry_ang_vel_body_pitch": -0.4778875410556793,
        "odometry_ang_vel_body_yaw": 0.6992949843406677,
        "velocity_north_m_s": 0.019999999552965164,
        "velocity_east_m_s": 4.730000019073486,
        "velocity_down_m_s": 0.17000000178813934,
        "acceleration_forward": -1.075961709022522,
        "acceleration_right": -3.5996265411376953,
        "acceleration_down": -9.769219398498535,
        "battery_id": 0,
        "battery_voltage": 12.965999603271484,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 0,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 8068,
        "udp_total": 170,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 136
      },
      {
        "id": 321,
        "datetime": "21/02/2023 02:05:08",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477dec8>, <mavsdk.mission_raw.MissionItem object at 0xa477dd90>, <mavsdk.mission_raw.MissionItem object at 0xa477d958>, <mavsdk.mission_raw.MissionItem object at 0xa477c970>]",
        "mission_pause": 0,
        "time_usec": 153675565,
        "odometry_pos_body": "PositionBody: [x_m: 17.555696487426758, y_m: 13.019692420959473, z_m: -2.7438998222351074]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065017,
        "alg_manual_gps_lon_start": 38.8900446,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "rb",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.0]",
        "timestamp": 150947969,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.7799999713897705,
        "current_climb_rate": -0.598478376865387,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066226,
        "gps_raw_lon_deg": 38.8901936,
        "gps_raw_abs_alt": 78.7020034790039,
        "gps_raw_velocity": 4.319999694824219,
        "position_lat_deg": 47.3066197,
        "position_lon_deg": 38.8900193,
        "position_abs_alt": 82.427001953125,
        "position_real_alt": 3.681000232696533,
        "heading_deg": 85.9,
        "odometry_pos_body_x": 17.528812408447266,
        "odometry_pos_body_y": 13.347752571105955,
        "odometry_pos_body_z": -2.647388458251953,
        "odometry_vel_body_x": 2.8104066848754883,
        "odometry_vel_body_y": -1.522620439529419,
        "odometry_vel_body_z": -0.7062454223632812,
        "odometry_ang_vel_body_roll": 0.15869171917438507,
        "odometry_ang_vel_body_pitch": 0.7759611010551453,
        "odometry_ang_vel_body_yaw": -0.09683001786470412,
        "velocity_north_m_s": 0.08999999612569809,
        "velocity_east_m_s": 4.929999828338623,
        "velocity_down_m_s": 0.2299999892711639,
        "acceleration_forward": -3.871864080429077,
        "acceleration_right": -3.43543004989624,
        "acceleration_down": -3.241610050201416,
        "battery_id": 0,
        "battery_voltage": 12.965999603271484,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 0,
        "status_type": "None",
        "status_text": "None",
        "tcp": 4,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 8072,
        "udp_total": 170,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 136
      },
      {
        "id": 322,
        "datetime": "21/02/2023 02:05:08",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4775460>, <mavsdk.mission_raw.MissionItem object at 0xa477e7f0>, <mavsdk.mission_raw.MissionItem object at 0xa477c8c8>, <mavsdk.mission_raw.MissionItem object at 0xa477c1d8>]",
        "mission_pause": 0,
        "time_usec": 153975303,
        "odometry_pos_body": "PositionBody: [x_m: 17.47898292541504, y_m: 13.856903076171875, z_m: -2.5573489665985107]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065017,
        "alg_manual_gps_lon_start": 38.8900446,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "rb",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.0]",
        "timestamp": 151307719,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.75,
        "current_climb_rate": -0.1638934314250946,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066214,
        "gps_raw_lon_deg": 38.8902102,
        "gps_raw_abs_alt": 78.45500183105469,
        "gps_raw_velocity": 2.9600000381469727,
        "position_lat_deg": 47.3066199,
        "position_lon_deg": 38.8900422,
        "position_abs_alt": 82.37700653076172,
        "position_real_alt": 3.631000280380249,
        "heading_deg": 85.64,
        "odometry_pos_body_x": 17.431407928466797,
        "odometry_pos_body_y": 14.204339027404783,
        "odometry_pos_body_z": -2.491086483001709,
        "odometry_vel_body_x": 2.043588638305664,
        "odometry_vel_body_y": -0.4748154282569885,
        "odometry_vel_body_z": -0.3740893304347992,
        "odometry_ang_vel_body_roll": 1.3933875560760498,
        "odometry_ang_vel_body_pitch": 0.10440587997436523,
        "odometry_ang_vel_body_yaw": -0.7205198407173157,
        "velocity_north_m_s": 0.14999999105930328,
        "velocity_east_m_s": 5.099999904632568,
        "velocity_down_m_s": 0.26999998092651367,
        "acceleration_forward": -1.1927236318588257,
        "acceleration_right": -0.6866025328636169,
        "acceleration_down": -5.394207954406738,
        "battery_id": 0,
        "battery_voltage": 12.965999603271484,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 0,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 8090,
        "udp_total": 171,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 137
      },
      {
        "id": 323,
        "datetime": "21/02/2023 02:05:09",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477c970>, <mavsdk.mission_raw.MissionItem object at 0xa477cd48>, <mavsdk.mission_raw.MissionItem object at 0xa477cef8>, <mavsdk.mission_raw.MissionItem object at 0xa477ccb8>]",
        "mission_pause": 0,
        "time_usec": 154245065,
        "odometry_pos_body": "PositionBody: [x_m: 17.390825271606445, y_m: 14.486367225646973, z_m: -2.4550933837890625]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065017,
        "alg_manual_gps_lon_start": 38.8900446,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "rb",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.0]",
        "timestamp": 151712293,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.7199999690055847,
        "current_climb_rate": 0.1272220015525818,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306620599999995,
        "gps_raw_lon_deg": 38.8902157,
        "gps_raw_abs_alt": 78.41100311279297,
        "gps_raw_velocity": 2.129999876022339,
        "position_lat_deg": 47.3066205,
        "position_lon_deg": 38.8900699,
        "position_abs_alt": 82.31200408935547,
        "position_real_alt": 3.56600022315979,
        "heading_deg": 87.85000000000001,
        "odometry_pos_body_x": 17.33098793029785,
        "odometry_pos_body_y": 14.780860900878906,
        "odometry_pos_body_z": -2.4296274185180664,
        "odometry_vel_body_x": 1.3098273277282717,
        "odometry_vel_body_y": 0.02559266611933708,
        "odometry_vel_body_z": -0.44617629051208496,
        "odometry_ang_vel_body_roll": -0.5771845579147339,
        "odometry_ang_vel_body_pitch": 0.030998757109045982,
        "odometry_ang_vel_body_yaw": -0.10349497944116592,
        "velocity_north_m_s": 0.2199999988079071,
        "velocity_east_m_s": 5.239999771118164,
        "velocity_down_m_s": 0.08999999612569809,
        "acceleration_forward": 0.0810626745223999,
        "acceleration_right": -0.8325437903404236,
        "acceleration_down": -2.971708297729492,
        "battery_id": 0,
        "battery_voltage": 12.965999603271484,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 0,
        "status_type": "None",
        "status_text": "None",
        "tcp": 1,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 8115,
        "udp_total": 171,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 137
      },
      {
        "id": 324,
        "datetime": "21/02/2023 02:05:09",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477b388>, <mavsdk.mission_raw.MissionItem object at 0xa477b8e0>, <mavsdk.mission_raw.MissionItem object at 0xa477b148>, <mavsdk.mission_raw.MissionItem object at 0xa4781358>]",
        "mission_pause": 0,
        "time_usec": 154574775,
        "odometry_pos_body": "PositionBody: [x_m: 17.27623748779297, y_m: 14.998335838317871, z_m: -2.4136815071105957]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065017,
        "alg_manual_gps_lon_start": 38.8900446,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "rb",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.0]",
        "timestamp": 152126928,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.7199999690055847,
        "current_climb_rate": 0.23640386760234833,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066195,
        "gps_raw_lon_deg": 38.8902223,
        "gps_raw_abs_alt": 78.43500518798828,
        "gps_raw_velocity": 1.079999923706055,
        "position_lat_deg": 47.306621199999995,
        "position_lon_deg": 38.8900946,
        "position_abs_alt": 82.29200744628906,
        "position_real_alt": 3.5460002422332764,
        "heading_deg": 89.98,
        "odometry_pos_body_x": 17.234445571899414,
        "odometry_pos_body_y": 15.17098331451416,
        "odometry_pos_body_z": -2.38846206665039,
        "odometry_vel_body_x": 0.796015202999115,
        "odometry_vel_body_y": 0.057723671197891235,
        "odometry_vel_body_z": -0.42971017956733704,
        "odometry_ang_vel_body_roll": -0.2360683381557465,
        "odometry_ang_vel_body_pitch": -0.17802809178829193,
        "odometry_ang_vel_body_yaw": 0.1521400809288025,
        "velocity_north_m_s": 0.17999999225139618,
        "velocity_east_m_s": 5.299999713897705,
        "velocity_down_m_s": 0.07999999821186066,
        "acceleration_forward": -0.8939489722251892,
        "acceleration_right": -2.4701099395751953,
        "acceleration_down": -3.752150774002075,
        "battery_id": 0,
        "battery_voltage": 12.965999603271484,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 0,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 8137,
        "udp_total": 171,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 137
      },
      {
        "id": 325,
        "datetime": "21/02/2023 02:05:09",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa58fc5e0>, <mavsdk.mission_raw.MissionItem object at 0xa5910508>, <mavsdk.mission_raw.MissionItem object at 0xa47828b0>, <mavsdk.mission_raw.MissionItem object at 0xa47822c8>]",
        "mission_pause": 0,
        "time_usec": 154844541,
        "odometry_pos_body": "PositionBody: [x_m: 17.2025089263916, y_m: 15.248469352722168, z_m: -2.3748466968536377]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065017,
        "alg_manual_gps_lon_start": 38.8900446,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "rb",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.0]",
        "timestamp": 152501601,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.7299999594688416,
        "current_climb_rate": 0.26478591561317444,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306619,
        "gps_raw_lon_deg": 38.8902243,
        "gps_raw_abs_alt": 78.47500610351562,
        "gps_raw_velocity": 0.7999999523162842,
        "position_lat_deg": 47.3066221,
        "position_lon_deg": 38.8901243,
        "position_abs_alt": 82.31800079345703,
        "position_real_alt": 3.57200026512146,
        "heading_deg": 103.23,
        "odometry_pos_body_x": 17.166954040527344,
        "odometry_pos_body_y": 15.370232582092283,
        "odometry_pos_body_z": -2.3491971492767334,
        "odometry_vel_body_x": 0.6477047801017761,
        "odometry_vel_body_y": -0.006041947286576033,
        "odometry_vel_body_z": -0.39128974080085754,
        "odometry_ang_vel_body_roll": -0.10733000189065932,
        "odometry_ang_vel_body_pitch": 0.30405193567276,
        "odometry_ang_vel_body_yaw": -0.2820819914340973,
        "velocity_north_m_s": 0.14999999105930328,
        "velocity_east_m_s": 5.210000038146973,
        "velocity_down_m_s": 0.05999999865889549,
        "acceleration_forward": -1.1973316669464111,
        "acceleration_right": -5.917705535888672,
        "acceleration_down": -4.849650859832764,
        "battery_id": 0,
        "battery_voltage": 12.965999603271484,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 0,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 8159,
        "udp_total": 171,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 137
      },
      {
        "id": 326,
        "datetime": "21/02/2023 02:05:09",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa47823b8>, <mavsdk.mission_raw.MissionItem object at 0xa4782598>, <mavsdk.mission_raw.MissionItem object at 0xa4782e50>, <mavsdk.mission_raw.MissionItem object at 0xa47825b0>]",
        "mission_pause": 0,
        "time_usec": 155184242,
        "odometry_pos_body": "PositionBody: [x_m: 17.14076042175293, y_m: 15.454891204833984, z_m: -2.3292369842529297]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065017,
        "alg_manual_gps_lon_start": 38.8900446,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "rb",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.0]",
        "timestamp": 152811326,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.75,
        "current_climb_rate": 0.23392826318740845,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066186,
        "gps_raw_lon_deg": 38.8902256,
        "gps_raw_abs_alt": 78.52900695800781,
        "gps_raw_velocity": 0.550000011920929,
        "position_lat_deg": 47.3066225,
        "position_lon_deg": 38.890145,
        "position_abs_alt": 82.34500122070312,
        "position_real_alt": 3.5990002155303955,
        "heading_deg": 115.5,
        "odometry_pos_body_x": 17.13211441040039,
        "odometry_pos_body_y": 15.4816312789917,
        "odometry_pos_body_z": -2.3226120471954346,
        "odometry_vel_body_x": 0.5448940396308899,
        "odometry_vel_body_y": -0.0014251488028094172,
        "odometry_vel_body_z": -0.3581732511520386,
        "odometry_ang_vel_body_roll": 0.4556867480278015,
        "odometry_ang_vel_body_pitch": -0.2547428011894226,
        "odometry_ang_vel_body_yaw": -0.4312076270580292,
        "velocity_north_m_s": 0,
        "velocity_east_m_s": 4.859999656677246,
        "velocity_down_m_s": 0.28999999165534973,
        "acceleration_forward": -5.757808208465576,
        "acceleration_right": 1.49676775932312,
        "acceleration_down": -9.643218994140623,
        "battery_id": 0,
        "battery_voltage": 12.84600067138672,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 0,
        "status_type": "None",
        "status_text": "None",
        "tcp": 16,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 8179,
        "udp_total": 172,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 138
      },
      {
        "id": 327,
        "datetime": "21/02/2023 02:05:10",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477c718>, <mavsdk.mission_raw.MissionItem object at 0xa477cf58>, <mavsdk.mission_raw.MissionItem object at 0xa477ccb8>, <mavsdk.mission_raw.MissionItem object at 0xa477c4a8>]",
        "mission_pause": 0,
        "time_usec": 155414037,
        "odometry_pos_body": "PositionBody: [x_m: 17.113407135009766, y_m: 15.543421745300293, z_m: -2.315812587738037]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065017,
        "alg_manual_gps_lon_start": 38.8900446,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "rb",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.0]",
        "timestamp": 153200985,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.7599999904632568,
        "current_climb_rate": 0.29101401567459106,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066183,
        "gps_raw_lon_deg": 38.890228,
        "gps_raw_abs_alt": 78.68800354003906,
        "gps_raw_velocity": 0.38999998569488525,
        "position_lat_deg": 47.3066228,
        "position_lon_deg": 38.8901699,
        "position_abs_alt": 82.2800064086914,
        "position_real_alt": 3.5340001583099365,
        "heading_deg": 128.59,
        "odometry_pos_body_x": 17.10173988342285,
        "odometry_pos_body_y": 15.586226463317873,
        "odometry_pos_body_z": -2.317344903945923,
        "odometry_vel_body_x": 0.4173255562782288,
        "odometry_vel_body_y": 0.03697120025753975,
        "odometry_vel_body_z": -0.387816458940506,
        "odometry_ang_vel_body_roll": 0.3578081429004669,
        "odometry_ang_vel_body_pitch": -0.027445832267403603,
        "odometry_ang_vel_body_yaw": -0.2147824764251709,
        "velocity_north_m_s": -0.2299999892711639,
        "velocity_east_m_s": 4.210000038146973,
        "velocity_down_m_s": 0.7299999594688416,
        "acceleration_forward": -4.284931659698486,
        "acceleration_right": -3.297109365463257,
        "acceleration_down": -12.842530250549316,
        "battery_id": 0,
        "battery_voltage": 12.84600067138672,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 0,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 8181,
        "udp_total": 172,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 138
      },
      {
        "id": 328,
        "datetime": "21/02/2023 02:05:10",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4782f88>, <mavsdk.mission_raw.MissionItem object at 0xa4782700>, <mavsdk.mission_raw.MissionItem object at 0xa4782e80>, <mavsdk.mission_raw.MissionItem object at 0xa4782d18>]",
        "mission_pause": 0,
        "time_usec": 155813687,
        "odometry_pos_body": "PositionBody: [x_m: 17.091148376464844, y_m: 15.6508150100708, z_m: -2.3345067501068115]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065017,
        "alg_manual_gps_lon_start": 38.8900446,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "rb",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.0]",
        "timestamp": 153555671,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.7599999904632568,
        "current_climb_rate": 0.3260442316532135,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066184,
        "gps_raw_lon_deg": 38.8902292,
        "gps_raw_abs_alt": 78.7490005493164,
        "gps_raw_velocity": 0.4399999976158142,
        "position_lat_deg": 47.3066226,
        "position_lon_deg": 38.8901906,
        "position_abs_alt": 82.0530014038086,
        "position_real_alt": 3.306000232696533,
        "heading_deg": 115.21,
        "odometry_pos_body_x": 17.09124183654785,
        "odometry_pos_body_y": 15.658051490783691,
        "odometry_pos_body_z": -2.3374130725860596,
        "odometry_vel_body_x": 0.2857499122619629,
        "odometry_vel_body_y": 0.024795081466436383,
        "odometry_vel_body_z": -0.4224986732006073,
        "odometry_ang_vel_body_roll": -0.2060002237558365,
        "odometry_ang_vel_body_pitch": -0.14121221005916595,
        "odometry_ang_vel_body_yaw": 0.2213570773601532,
        "velocity_north_m_s": -0.4399999976158142,
        "velocity_east_m_s": 3.25,
        "velocity_down_m_s": 0.5099999904632568,
        "acceleration_forward": -7.773886680603027,
        "acceleration_right": 5.41673469543457,
        "acceleration_down": -10.982502937316896,
        "battery_id": 0,
        "battery_voltage": 12.84600067138672,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 0,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 8203,
        "udp_total": 172,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 138
      },
      {
        "id": 329,
        "datetime": "21/02/2023 02:05:10",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477e130>, <mavsdk.mission_raw.MissionItem object at 0xa477e7f0>, <mavsdk.mission_raw.MissionItem object at 0xa477e850>, <mavsdk.mission_raw.MissionItem object at 0xa477dce8>]",
        "mission_pause": 0,
        "time_usec": 156013511,
        "odometry_pos_body": "PositionBody: [x_m: 17.087858200073242, y_m: 15.684575080871582, z_m: -2.3553459644317627]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065017,
        "alg_manual_gps_lon_start": 38.8900446,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "rb",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.0]",
        "timestamp": 153790465,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.7699999809265137,
        "current_climb_rate": 0.3398241400718689,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066185,
        "gps_raw_lon_deg": 38.8902303,
        "gps_raw_abs_alt": 78.89200592041016,
        "gps_raw_velocity": 0.2800000011920929,
        "position_lat_deg": 47.306622,
        "position_lon_deg": 38.8902022,
        "position_abs_alt": 81.86100769042969,
        "position_real_alt": 3.114000082015991,
        "heading_deg": 110.13,
        "odometry_pos_body_x": 17.08633804321289,
        "odometry_pos_body_y": 15.699037551879885,
        "odometry_pos_body_z": -2.36214017868042,
        "odometry_vel_body_x": 0.33300963044166565,
        "odometry_vel_body_y": -0.04215092211961746,
        "odometry_vel_body_z": -0.4421834945678711,
        "odometry_ang_vel_body_roll": -0.38712427020072937,
        "odometry_ang_vel_body_pitch": -0.2382211983203888,
        "odometry_ang_vel_body_yaw": 0.10729169845581056,
        "velocity_north_m_s": -0.44999998807907104,
        "velocity_east_m_s": 2.299999952316284,
        "velocity_down_m_s": 0.2299999892711639,
        "acceleration_forward": -5.300555229187012,
        "acceleration_right": 3.8442111015319824,
        "acceleration_down": -17.48548126220703,
        "battery_id": 0,
        "battery_voltage": 12.84600067138672,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 0,
        "status_type": "None",
        "status_text": "None",
        "tcp": 0,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 8225,
        "udp_total": 173,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 139
      },
      {
        "id": 330,
        "datetime": "21/02/2023 02:05:11",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477adc0>, <mavsdk.mission_raw.MissionItem object at 0xa477e040>, <mavsdk.mission_raw.MissionItem object at 0xa477e5e0>, <mavsdk.mission_raw.MissionItem object at 0xa477e7c0>]",
        "mission_pause": 0,
        "time_usec": 156283274,
        "odometry_pos_body": "PositionBody: [x_m: 17.10633087158203, y_m: 15.753067016601562, z_m: -2.3892006874084473]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065017,
        "alg_manual_gps_lon_start": 38.8900446,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "rb",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.0]",
        "timestamp": 154195110,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.7599999904632568,
        "current_climb_rate": 0.3481549918651581,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066184,
        "gps_raw_lon_deg": 38.8902313,
        "gps_raw_abs_alt": 78.96000671386719,
        "gps_raw_velocity": 0.3400000035762787,
        "position_lat_deg": 47.3066208,
        "position_lon_deg": 38.890217,
        "position_abs_alt": 81.64300537109375,
        "position_real_alt": 2.8950002193450928,
        "heading_deg": 112.13,
        "odometry_pos_body_x": 17.112590789794922,
        "odometry_pos_body_y": 15.782011032104492,
        "odometry_pos_body_z": -2.40356183052063,
        "odometry_vel_body_x": 0.5175067782402039,
        "odometry_vel_body_y": -0.15618519484996796,
        "odometry_vel_body_z": -0.4609121978282929,
        "odometry_ang_vel_body_roll": 0.006808002479374409,
        "odometry_ang_vel_body_pitch": 0.7579253315925598,
        "odometry_ang_vel_body_yaw": -0.3666689395904541,
        "velocity_north_m_s": -0.3799999952316284,
        "velocity_east_m_s": 1.1999999284744265,
        "velocity_down_m_s": -0.20999999344348907,
        "acceleration_forward": -3.5585477352142334,
        "acceleration_right": 4.283989429473877,
        "acceleration_down": -5.892128944396973,
        "battery_id": 0,
        "battery_voltage": 12.84600067138672,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 0,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 8247,
        "udp_total": 173,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 139
      },
      {
        "id": 331,
        "datetime": "21/02/2023 02:05:11",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477ce98>, <mavsdk.mission_raw.MissionItem object at 0xa477cd90>, <mavsdk.mission_raw.MissionItem object at 0xa477c970>, <mavsdk.mission_raw.MissionItem object at 0xa47826b8>]",
        "mission_pause": 0,
        "time_usec": 156553037,
        "odometry_pos_body": "PositionBody: [x_m: 17.134016036987305, y_m: 15.862381935119629, z_m: -2.436321258544922]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065017,
        "alg_manual_gps_lon_start": 38.8900446,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "rb",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.0]",
        "timestamp": 154409923,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.7599999904632568,
        "current_climb_rate": 0.34615397453308105,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066187,
        "gps_raw_lon_deg": 38.8902325,
        "gps_raw_abs_alt": 79.0530014038086,
        "gps_raw_velocity": 0.47999998927116394,
        "position_lat_deg": 47.3066202,
        "position_lon_deg": 38.8902215,
        "position_abs_alt": 81.61300659179688,
        "position_real_alt": 2.867000102996826,
        "heading_deg": 110.13,
        "odometry_pos_body_x": 17.139875411987305,
        "odometry_pos_body_y": 15.87773609161377,
        "odometry_pos_body_z": -2.443155527114868,
        "odometry_vel_body_x": 0.6485059857368469,
        "odometry_vel_body_y": -0.18521183729171753,
        "odometry_vel_body_z": -0.4869544208049774,
        "odometry_ang_vel_body_roll": 0.2404174208641052,
        "odometry_ang_vel_body_pitch": 0.6015797257423401,
        "odometry_ang_vel_body_yaw": -0.7828299403190613,
        "velocity_north_m_s": -0.32999998331069946,
        "velocity_east_m_s": 0.949999988079071,
        "velocity_down_m_s": -0.2299999892711639,
        "acceleration_forward": -1.954954504966736,
        "acceleration_right": 3.8637266159057617,
        "acceleration_down": -12.28407096862793,
        "battery_id": 0,
        "battery_voltage": 12.84600067138672,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 0,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 8247,
        "udp_total": 173,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 139
      },
      {
        "id": 332,
        "datetime": "21/02/2023 02:05:11",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477ca90>, <mavsdk.mission_raw.MissionItem object at 0xa477c298>, <mavsdk.mission_raw.MissionItem object at 0xa477cbf8>, <mavsdk.mission_raw.MissionItem object at 0xa477c418>]",
        "mission_pause": 0,
        "time_usec": 156672931,
        "odometry_pos_body": "PositionBody: [x_m: 17.157718658447266, y_m: 15.930981636047363, z_m: -2.4651236534118652]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065017,
        "alg_manual_gps_lon_start": 38.8900446,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "rb",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.0]",
        "timestamp": 154529816,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.7299999594688416,
        "current_climb_rate": 0.3955135047435761,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066187,
        "gps_raw_lon_deg": 38.8902339,
        "gps_raw_abs_alt": 79.11600494384766,
        "gps_raw_velocity": 0.5099999904632568,
        "position_lat_deg": 47.3066199,
        "position_lon_deg": 38.8902235,
        "position_abs_alt": 81.60400390625,
        "position_real_alt": 2.8580000400543213,
        "heading_deg": 108.11,
        "odometry_pos_body_x": 17.168275833129883,
        "odometry_pos_body_y": 15.970417022705078,
        "odometry_pos_body_z": -2.4860498905181885,
        "odometry_vel_body_x": 0.7511723041534424,
        "odometry_vel_body_y": -0.13945691287517548,
        "odometry_vel_body_z": -0.5811458826065063,
        "odometry_ang_vel_body_roll": -0.5559974908828735,
        "odometry_ang_vel_body_pitch": 0.11755461990833282,
        "odometry_ang_vel_body_yaw": -0.8208264112472534,
        "velocity_north_m_s": -0.26999998092651367,
        "velocity_east_m_s": 0.7599999904632568,
        "velocity_down_m_s": -0.25,
        "acceleration_forward": -4.642665386199951,
        "acceleration_right": 3.566961765289306,
        "acceleration_down": -7.495606422424316,
        "battery_id": 0,
        "battery_voltage": 12.84600067138672,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 0,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 8271,
        "udp_total": 173,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 139
      },
      {
        "id": 333,
        "datetime": "21/02/2023 02:05:11",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa476c700>, <mavsdk.mission_raw.MissionItem object at 0xa477cd00>, <mavsdk.mission_raw.MissionItem object at 0xa477a550>, <mavsdk.mission_raw.MissionItem object at 0xa477a4a8>]",
        "mission_pause": 0,
        "time_usec": 156922712,
        "odometry_pos_body": "PositionBody: [x_m: 17.193666458129883, y_m: 16.08550262451172, z_m: -2.567988395690918]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065017,
        "alg_manual_gps_lon_start": 38.8900446,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "rb",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.0]",
        "timestamp": 154759614,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.6599999666213989,
        "current_climb_rate": 0.6139944791793823,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306619,
        "gps_raw_lon_deg": 38.8902358,
        "gps_raw_abs_alt": 79.24600219726562,
        "gps_raw_velocity": 0.7099999785423279,
        "position_lat_deg": 47.3066192,
        "position_lon_deg": 38.890227,
        "position_abs_alt": 81.56900024414062,
        "position_real_alt": 2.828000068664551,
        "heading_deg": 102.74,
        "odometry_pos_body_x": 17.197660446166992,
        "odometry_pos_body_y": 16.10555076599121,
        "odometry_pos_body_z": -2.585992336273194,
        "odometry_vel_body_x": 0.8465397953987122,
        "odometry_vel_body_y": 0.01720656454563141,
        "odometry_vel_body_z": -0.8284353613853455,
        "odometry_ang_vel_body_roll": 0.7018573880195618,
        "odometry_ang_vel_body_pitch": 0.32957765460014343,
        "odometry_ang_vel_body_yaw": -0.6567338705062866,
        "velocity_north_m_s": -0.17999999225139618,
        "velocity_east_m_s": 0.6800000071525574,
        "velocity_down_m_s": -0.25,
        "acceleration_forward": -3.171272039413452,
        "acceleration_right": 3.648846387863159,
        "acceleration_down": -5.84041166305542,
        "battery_id": 0,
        "battery_voltage": 12.84600067138672,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 0,
        "status_type": "None",
        "status_text": "None",
        "tcp": 22,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 8293,
        "udp_total": 173,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 139
      },
      {
        "id": 334,
        "datetime": "21/02/2023 02:05:11",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477ec40>, <mavsdk.mission_raw.MissionItem object at 0xa477c4a8>, <mavsdk.mission_raw.MissionItem object at 0xa476c298>, <mavsdk.mission_raw.MissionItem object at 0xa47809e8>]",
        "mission_pause": 0,
        "time_usec": 157212456,
        "odometry_pos_body": "PositionBody: [x_m: 17.24540138244629, y_m: 16.291778564453125, z_m: -2.76365065574646]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065017,
        "alg_manual_gps_lon_start": 38.8900446,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "rb",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.0]",
        "timestamp": 155099314,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5399999618530273,
        "current_climb_rate": 0.7851022481918335,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066197,
        "gps_raw_lon_deg": 38.8902402,
        "gps_raw_abs_alt": 79.58800506591797,
        "gps_raw_velocity": 0.85999995470047,
        "position_lat_deg": 47.3066186,
        "position_lon_deg": 38.8902303,
        "position_abs_alt": 81.51800537109375,
        "position_real_alt": 2.7720000743865967,
        "heading_deg": 100.39,
        "odometry_pos_body_x": 17.266937255859375,
        "odometry_pos_body_y": 16.366432189941406,
        "odometry_pos_body_z": -2.8331048488616943,
        "odometry_vel_body_x": 0.8521649837493896,
        "odometry_vel_body_y": 0.1483236700296402,
        "odometry_vel_body_z": -0.8307335376739502,
        "odometry_ang_vel_body_roll": -0.8941876888275146,
        "odometry_ang_vel_body_pitch": -0.11764578521251678,
        "odometry_ang_vel_body_yaw": -0.3275548219680786,
        "velocity_north_m_s": -0.08999999612569809,
        "velocity_east_m_s": 0.5,
        "velocity_down_m_s": -0.26999998092651367,
        "acceleration_forward": -2.078385591506958,
        "acceleration_right": 1.8072160482406616,
        "acceleration_down": -12.098002433776855,
        "battery_id": 0,
        "battery_voltage": 12.807000160217283,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 0,
        "status_type": "None",
        "status_text": "None",
        "tcp": 1,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 8318,
        "udp_total": 174,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 140
      },
      {
        "id": 335,
        "datetime": "21/02/2023 02:05:12",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477de50>, <mavsdk.mission_raw.MissionItem object at 0xa477ebb0>, <mavsdk.mission_raw.MissionItem object at 0xa477b640>, <mavsdk.mission_raw.MissionItem object at 0xa477b628>]",
        "mission_pause": 0,
        "time_usec": 157542167,
        "odometry_pos_body": "PositionBody: [x_m: 17.308500289916992, y_m: 16.535730361938477, z_m: -2.9832000732421875]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065017,
        "alg_manual_gps_lon_start": 38.8900446,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "rb",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.0]",
        "timestamp": 155528937,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5199999809265137,
        "current_climb_rate": 0.5811414122581482,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066199,
        "gps_raw_lon_deg": 38.8902425,
        "gps_raw_abs_alt": 79.78500366210938,
        "gps_raw_velocity": 0.8700000047683716,
        "position_lat_deg": 47.3066182,
        "position_lon_deg": 38.8902322,
        "position_abs_alt": 81.49800109863281,
        "position_real_alt": 2.752000093460083,
        "heading_deg": 105.47,
        "odometry_pos_body_x": 17.319307327270508,
        "odometry_pos_body_y": 16.593008041381836,
        "odometry_pos_body_z": -3.028425931930542,
        "odometry_vel_body_x": 0.7392458319664001,
        "odometry_vel_body_y": 0.1656896471977234,
        "odometry_vel_body_z": -0.5736103653907776,
        "odometry_ang_vel_body_roll": 0.8435914516448975,
        "odometry_ang_vel_body_pitch": -0.09757577627897264,
        "odometry_ang_vel_body_yaw": -0.028074201196432114,
        "velocity_north_m_s": -0.05999999865889549,
        "velocity_east_m_s": 0.38999998569488525,
        "velocity_down_m_s": -0.32999998331069946,
        "acceleration_forward": -1.045829176902771,
        "acceleration_right": 0.16753777861595154,
        "acceleration_down": -5.196424961090088,
        "battery_id": 0,
        "battery_voltage": 12.807000160217283,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 0,
        "status_type": "None",
        "status_text": "None",
        "tcp": 1,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 8320,
        "udp_total": 174,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 140
      },
      {
        "id": 336,
        "datetime": "21/02/2023 02:05:12",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4782ec8>, <mavsdk.mission_raw.MissionItem object at 0xa4782bf8>, <mavsdk.mission_raw.MissionItem object at 0xa4782c58>, <mavsdk.mission_raw.MissionItem object at 0xa4782d30>]",
        "mission_pause": 0,
        "time_usec": 157811930,
        "odometry_pos_body": "PositionBody: [x_m: 17.346731185913086, y_m: 16.72740364074707, z_m: -3.121466875076294]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065017,
        "alg_manual_gps_lon_start": 38.8900446,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "rb",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.0]",
        "timestamp": 155733780,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5199999809265137,
        "current_climb_rate": 0.3405039310455322,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066204,
        "gps_raw_lon_deg": 38.8902467,
        "gps_raw_abs_alt": 80.04500579833984,
        "gps_raw_velocity": 0.6699999570846558,
        "position_lat_deg": 47.306618099999994,
        "position_lon_deg": 38.8902331,
        "position_abs_alt": 81.51300048828125,
        "position_real_alt": 2.767000198364258,
        "heading_deg": 101.41,
        "odometry_pos_body_x": 17.364566802978516,
        "odometry_pos_body_y": 16.79703712463379,
        "odometry_pos_body_z": -3.1632120609283447,
        "odometry_vel_body_x": 0.558238685131073,
        "odometry_vel_body_y": 0.10979921370744704,
        "odometry_vel_body_z": -0.28992971777915955,
        "odometry_ang_vel_body_roll": 0.1926705837249756,
        "odometry_ang_vel_body_pitch": 0.03646382316946983,
        "odometry_ang_vel_body_yaw": 0.0026485284324735403,
        "velocity_north_m_s": -0.009999999776482582,
        "velocity_east_m_s": 0.4699999988079071,
        "velocity_down_m_s": -0.3100000023841858,
        "acceleration_forward": -3.353597640991211,
        "acceleration_right": 1.8439193964004517,
        "acceleration_down": -4.557218551635742,
        "battery_id": 0,
        "battery_voltage": 12.807000160217283,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 0,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 8340,
        "udp_total": 174,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 140
      },
      {
        "id": 337,
        "datetime": "21/02/2023 02:05:12",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4777748>, <mavsdk.mission_raw.MissionItem object at 0xa47774a8>, <mavsdk.mission_raw.MissionItem object at 0xa4780f70>, <mavsdk.mission_raw.MissionItem object at 0xa477e688>]",
        "mission_pause": 0,
        "time_usec": 158081747,
        "odometry_pos_body": "PositionBody: [x_m: 17.390594482421875, y_m: 16.878490447998047, z_m: -3.213723659515381]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065017,
        "alg_manual_gps_lon_start": 38.8900446,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "rb",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.0]",
        "timestamp": 156158383,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5299999713897705,
        "current_climb_rate": 0.20344790816307068,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066207,
        "gps_raw_lon_deg": 38.8902483,
        "gps_raw_abs_alt": 80.13800048828125,
        "gps_raw_velocity": 0.5799999833106995,
        "position_lat_deg": 47.306618099999994,
        "position_lon_deg": 38.8902343,
        "position_abs_alt": 81.56400299072266,
        "position_real_alt": 2.817000150680542,
        "heading_deg": 84.2,
        "odometry_pos_body_x": 17.41730308532715,
        "odometry_pos_body_y": 16.931779861450195,
        "odometry_pos_body_z": -3.251133680343628,
        "odometry_vel_body_x": 0.35532116889953613,
        "odometry_vel_body_y": -0.002286062575876713,
        "odometry_vel_body_z": -0.0978844314813614,
        "odometry_ang_vel_body_roll": -1.1038014888763428,
        "odometry_ang_vel_body_pitch": -0.08952322602272034,
        "odometry_ang_vel_body_yaw": 0.024548282846808434,
        "velocity_north_m_s": 0.07999999821186066,
        "velocity_east_m_s": 0.699999988079071,
        "velocity_down_m_s": -0.3499999940395355,
        "acceleration_forward": -1.6247018575668335,
        "acceleration_right": -3.333841562271118,
        "acceleration_down": -8.767716407775879,
        "battery_id": 0,
        "battery_voltage": 12.807000160217283,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 0,
        "status_type": "None",
        "status_text": "None",
        "tcp": 4,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 8352,
        "udp_total": 175,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 141
      },
      {
        "id": 338,
        "datetime": "21/02/2023 02:05:13",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4781700>, <mavsdk.mission_raw.MissionItem object at 0xa4781160>, <mavsdk.mission_raw.MissionItem object at 0xa4781910>, <mavsdk.mission_raw.MissionItem object at 0xa4781c28>]",
        "mission_pause": 0,
        "time_usec": 158376437,
        "odometry_pos_body": "PositionBody: [x_m: 17.447656631469727, y_m: 16.98790740966797, z_m: -3.2832276821136475]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065017,
        "alg_manual_gps_lon_start": 38.8900446,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "rb",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.0]",
        "timestamp": 156478102,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.550000011920929,
        "current_climb_rate": -0.021869055926799778,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066211,
        "gps_raw_lon_deg": 38.89025,
        "gps_raw_abs_alt": 80.21900177001953,
        "gps_raw_velocity": 0.26999998092651367,
        "position_lat_deg": 47.3066184,
        "position_lon_deg": 38.8902358,
        "position_abs_alt": 81.61400604248047,
        "position_real_alt": 2.868000030517578,
        "heading_deg": 71.72,
        "odometry_pos_body_x": 17.463876724243164,
        "odometry_pos_body_y": 17.020553588867188,
        "odometry_pos_body_z": -3.292505979537964,
        "odometry_vel_body_x": 0.1111314594745636,
        "odometry_vel_body_y": 0.00804306659847498,
        "odometry_vel_body_z": 0.08795878291130066,
        "odometry_ang_vel_body_roll": -1.1888370513916016,
        "odometry_ang_vel_body_pitch": -0.19340045750141144,
        "odometry_ang_vel_body_yaw": 0.026815716177225113,
        "velocity_north_m_s": 0.14000000059604645,
        "velocity_east_m_s": 0.85999995470047,
        "velocity_down_m_s": -0.5799999833106995,
        "acceleration_forward": 0.21259160339832303,
        "acceleration_right": 0.9037538766860962,
        "acceleration_down": -2.668595314025879,
        "battery_id": 0,
        "battery_voltage": 12.807000160217283,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 0,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 8375,
        "udp_total": 175,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 141
      },
      {
        "id": 339,
        "datetime": "21/02/2023 02:05:13",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477d190>, <mavsdk.mission_raw.MissionItem object at 0xa47818c8>, <mavsdk.mission_raw.MissionItem object at 0xa4781c58>, <mavsdk.mission_raw.MissionItem object at 0xa4781ce8>]",
        "mission_pause": 0,
        "time_usec": 158716139,
        "odometry_pos_body": "PositionBody: [x_m: 17.468183517456055, y_m: 17.032827377319336, z_m: -3.286917209625244]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065017,
        "alg_manual_gps_lon_start": 38.8900446,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "rb",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.0]",
        "timestamp": 156797822,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.550000011920929,
        "current_climb_rate": -0.09146834164857864,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306621199999995,
        "gps_raw_lon_deg": 38.8902504,
        "gps_raw_abs_alt": 80.24700164794922,
        "gps_raw_velocity": 0.019999999552965164,
        "position_lat_deg": 47.306618799999995,
        "position_lon_deg": 38.890238,
        "position_abs_alt": 81.69500732421875,
        "position_real_alt": 2.949000120162964,
        "heading_deg": 66.85,
        "odometry_pos_body_x": 17.469566345214844,
        "odometry_pos_body_y": 17.035926818847656,
        "odometry_pos_body_z": -3.27444839477539,
        "odometry_vel_body_x": -0.04388362914323807,
        "odometry_vel_body_y": 0.040543362498283386,
        "odometry_vel_body_z": 0.11637374758720398,
        "odometry_ang_vel_body_roll": 0.6966404914855957,
        "odometry_ang_vel_body_pitch": -0.1271701455116272,
        "odometry_ang_vel_body_yaw": 0.09182524681091309,
        "velocity_north_m_s": 0.14999999105930328,
        "velocity_east_m_s": 0.8999999761581421,
        "velocity_down_m_s": -0.7999999523162842,
        "acceleration_forward": -2.399094820022583,
        "acceleration_right": -0.45178937911987305,
        "acceleration_down": -16.612815856933594,
        "battery_id": 0,
        "battery_voltage": 12.807000160217283,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 0,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 8387,
        "udp_total": 175,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 141
      },
      {
        "id": 340,
        "datetime": "21/02/2023 02:05:13",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4781778>, <mavsdk.mission_raw.MissionItem object at 0xa4781898>, <mavsdk.mission_raw.MissionItem object at 0xa4781628>, <mavsdk.mission_raw.MissionItem object at 0xa47816e8>]",
        "mission_pause": 0,
        "time_usec": 159055840,
        "odometry_pos_body": "PositionBody: [x_m: 17.465835571289062, y_m: 17.025577545166016, z_m: -3.2492032051086426]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065017,
        "alg_manual_gps_lon_start": 38.8900446,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "rb",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.0]",
        "timestamp": 157087570,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5799999833106995,
        "current_climb_rate": -0.1569877713918686,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306621199999995,
        "gps_raw_lon_deg": 38.8902503,
        "gps_raw_abs_alt": 80.22300720214844,
        "gps_raw_velocity": 0.07999999821186066,
        "position_lat_deg": 47.3066192,
        "position_lon_deg": 38.890240899999995,
        "position_abs_alt": 81.88300323486328,
        "position_real_alt": 3.13700008392334,
        "heading_deg": 66.22,
        "odometry_pos_body_x": 17.46254539489746,
        "odometry_pos_body_y": 17.00511932373047,
        "odometry_pos_body_z": -3.220686912536621,
        "odometry_vel_body_x": -0.14409057796001434,
        "odometry_vel_body_y": 0.03463733568787575,
        "odometry_vel_body_z": 0.14156770706176758,
        "odometry_ang_vel_body_roll": 0.4325235188007355,
        "odometry_ang_vel_body_pitch": 0.43729621171951294,
        "odometry_ang_vel_body_yaw": 0.0715409442782402,
        "velocity_north_m_s": 0.14000000059604645,
        "velocity_east_m_s": 0.8399999737739563,
        "velocity_down_m_s": -0.6699999570846558,
        "acceleration_forward": -0.8694308996200562,
        "acceleration_right": -4.652801990509033,
        "acceleration_down": -7.570774078369141,
        "battery_id": 0,
        "battery_voltage": 12.894001007080078,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 0,
        "status_type": "None",
        "status_text": "None",
        "tcp": 0,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 8409,
        "udp_total": 176,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 142
      },
      {
        "id": 341,
        "datetime": "21/02/2023 02:05:14",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477c4d8>, <mavsdk.mission_raw.MissionItem object at 0xa477a9d0>, <mavsdk.mission_raw.MissionItem object at 0xa477a658>, <mavsdk.mission_raw.MissionItem object at 0xa477a220>]",
        "mission_pause": 0,
        "time_usec": 159445498,
        "odometry_pos_body": "PositionBody: [x_m: 17.450593948364258, y_m: 16.966655731201172, z_m: -3.175642728805542]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065017,
        "alg_manual_gps_lon_start": 38.8900446,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "rb",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.0]",
        "timestamp": 157452246,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5799999833106995,
        "current_climb_rate": -0.12135904282331468,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306621,
        "gps_raw_lon_deg": 38.8902497,
        "gps_raw_abs_alt": 80.21300506591797,
        "gps_raw_velocity": 0.1599999964237213,
        "position_lat_deg": 47.306619999999995,
        "position_lon_deg": 38.8902448,
        "position_abs_alt": 82.15300750732422,
        "position_real_alt": 3.4070000648498535,
        "heading_deg": 66.45,
        "odometry_pos_body_x": 17.43620491027832,
        "odometry_pos_body_y": 16.93277931213379,
        "odometry_pos_body_z": -3.1424407958984375,
        "odometry_vel_body_x": -0.2126084268093109,
        "odometry_vel_body_y": 0.0646032765507698,
        "odometry_vel_body_z": 0.10428990423679352,
        "odometry_ang_vel_body_roll": 0.51446533203125,
        "odometry_ang_vel_body_pitch": 0.02941825240850449,
        "odometry_ang_vel_body_yaw": 0.09280899167060852,
        "velocity_north_m_s": 0.14000000059604645,
        "velocity_east_m_s": 0.6100000143051147,
        "velocity_down_m_s": -0.35999998450279236,
        "acceleration_forward": -0.7548413276672363,
        "acceleration_right": -6.356351852416992,
        "acceleration_down": -7.146731376647949,
        "battery_id": 0,
        "battery_voltage": 12.894001007080078,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 0,
        "status_type": "None",
        "status_text": "None",
        "tcp": 24,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 8433,
        "udp_total": 176,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 142
      },
      {
        "id": 342,
        "datetime": "21/02/2023 02:05:14",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4782328>, <mavsdk.mission_raw.MissionItem object at 0xa4782ef8>, <mavsdk.mission_raw.MissionItem object at 0xa4782748>, <mavsdk.mission_raw.MissionItem object at 0xa4782568>]",
        "mission_pause": 0,
        "time_usec": 159815171,
        "odometry_pos_body": "PositionBody: [x_m: 17.414634704589844, y_m: 16.88634490966797, z_m: -3.1103429794311523]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065017,
        "alg_manual_gps_lon_start": 38.8900446,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "rb",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.0]",
        "timestamp": 157986777,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5799999833106995,
        "current_climb_rate": -0.06179821118712425,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066207,
        "gps_raw_lon_deg": 38.8902485,
        "gps_raw_abs_alt": 80.19100189208984,
        "gps_raw_velocity": 0.2299999892711639,
        "position_lat_deg": 47.3066207,
        "position_lon_deg": 38.8902493,
        "position_abs_alt": 82.38800048828125,
        "position_real_alt": 3.642000198364258,
        "heading_deg": 69.06,
        "odometry_pos_body_x": 17.395179748535156,
        "odometry_pos_body_y": 16.84450340270996,
        "odometry_pos_body_z": -3.088325023651123,
        "odometry_vel_body_x": -0.23242872953414917,
        "odometry_vel_body_y": 0.0873287245631218,
        "odometry_vel_body_z": 0.05615367740392685,
        "odometry_ang_vel_body_roll": 0.3743275701999664,
        "odometry_ang_vel_body_pitch": -0.31772685050964355,
        "odometry_ang_vel_body_yaw": 0.09287556260824203,
        "velocity_north_m_s": 0.07999999821186066,
        "velocity_east_m_s": 0.2299999892711639,
        "velocity_down_m_s": -0.009999999776482582,
        "acceleration_forward": -1.1175481081008911,
        "acceleration_right": -0.3036697804927826,
        "acceleration_down": -8.659480094909668,
        "battery_id": 0,
        "battery_voltage": 12.894001007080078,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 0,
        "status_type": "None",
        "status_text": "None",
        "tcp": 1,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 8455,
        "udp_total": 176,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 142
      },
      {
        "id": 343,
        "datetime": "21/02/2023 02:05:14",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4781dd8>, <mavsdk.mission_raw.MissionItem object at 0xa4782370>, <mavsdk.mission_raw.MissionItem object at 0xa477dd90>, <mavsdk.mission_raw.MissionItem object at 0xa477df10>]",
        "mission_pause": 0,
        "time_usec": 160144931,
        "odometry_pos_body": "PositionBody: [x_m: 17.376779556274414, y_m: 16.80411720275879, z_m: -3.0730223655700684]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065017,
        "alg_manual_gps_lon_start": 38.8900446,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "rb",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.0]",
        "timestamp": 158456364,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5799999833106995,
        "current_climb_rate": -0.02546398527920246,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066204,
        "gps_raw_lon_deg": 38.8902471,
        "gps_raw_abs_alt": 80.18600463867188,
        "gps_raw_velocity": 0.25999999046325684,
        "position_lat_deg": 47.3066213,
        "position_lon_deg": 38.8902512,
        "position_abs_alt": 82.47000122070312,
        "position_real_alt": 3.724000215530395,
        "heading_deg": 70.60000000000001,
        "odometry_pos_body_x": 17.356369018554688,
        "odometry_pos_body_y": 16.75864601135254,
        "odometry_pos_body_z": -3.058394432067871,
        "odometry_vel_body_x": -0.24719062447547913,
        "odometry_vel_body_y": 0.08039659261703491,
        "odometry_vel_body_z": 0.03744220361113548,
        "odometry_ang_vel_body_roll": 0.4878157675266266,
        "odometry_ang_vel_body_pitch": -0.06355886161327362,
        "odometry_ang_vel_body_yaw": 0.045954033732414246,
        "velocity_north_m_s": -0.029999999329447743,
        "velocity_east_m_s": 0,
        "velocity_down_m_s": 0.10999999940395357,
        "acceleration_forward": -0.3894041180610657,
        "acceleration_right": 3.2485532760620117,
        "acceleration_down": -11.50540828704834,
        "battery_id": 0,
        "battery_voltage": 12.894001007080078,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 0,
        "status_type": "None",
        "status_text": "None",
        "tcp": 26,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 8480,
        "udp_total": 177,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 143
      },
      {
        "id": 344,
        "datetime": "21/02/2023 02:05:15",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa47824f0>, <mavsdk.mission_raw.MissionItem object at 0xa4782f28>, <mavsdk.mission_raw.MissionItem object at 0xa47826b8>, <mavsdk.mission_raw.MissionItem object at 0xa47821d8>]",
        "mission_pause": 0,
        "time_usec": 160474591,
        "odometry_pos_body": "PositionBody: [x_m: 17.334983825683594, y_m: 16.71486473083496, z_m: -3.047581672668457]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065017,
        "alg_manual_gps_lon_start": 38.8900446,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "rb",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.0]",
        "timestamp": 158771130,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5799999833106995,
        "current_climb_rate": -0.019869305193424225,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066201,
        "gps_raw_lon_deg": 38.890246399999995,
        "gps_raw_abs_alt": 80.20000457763672,
        "gps_raw_velocity": 0.3199999928474426,
        "position_lat_deg": 47.3066215,
        "position_lon_deg": 38.8902516,
        "position_abs_alt": 82.46300506591797,
        "position_real_alt": 3.7170002460479736,
        "heading_deg": 71.61,
        "odometry_pos_body_x": 17.31852149963379,
        "odometry_pos_body_y": 16.684499740600586,
        "odometry_pos_body_z": -3.043672800064087,
        "odometry_vel_body_x": -0.27009910345077515,
        "odometry_vel_body_y": 0.0967494174838066,
        "odometry_vel_body_z": 0.037542518228292465,
        "odometry_ang_vel_body_roll": 0.07633533328771591,
        "odometry_ang_vel_body_pitch": 0.1700907051563263,
        "odometry_ang_vel_body_yaw": 0.04735632240772247,
        "velocity_north_m_s": -0.07000000029802322,
        "velocity_east_m_s": -0.09999999403953552,
        "velocity_down_m_s": 0.14000000059604645,
        "acceleration_forward": 0.48101305961608887,
        "acceleration_right": -0.10531188547611237,
        "acceleration_down": -9.191489219665527,
        "battery_id": 0,
        "battery_voltage": 12.894001007080078,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 0,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 8502,
        "udp_total": 177,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 143
      },
      {
        "id": 345,
        "datetime": "21/02/2023 02:05:15",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa476cee0>, <mavsdk.mission_raw.MissionItem object at 0xa4775f58>, <mavsdk.mission_raw.MissionItem object at 0xa4777418>, <mavsdk.mission_raw.MissionItem object at 0xa477b970>]",
        "mission_pause": 0,
        "time_usec": 160844268,
        "odometry_pos_body": "PositionBody: [x_m: 17.27293586730957, y_m: 16.600265502929688, z_m: -3.026872396469116]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065017,
        "alg_manual_gps_lon_start": 38.8900446,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "rb",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.0]",
        "timestamp": 159320607,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5899999737739563,
        "current_climb_rate": -0.04625673592090607,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066196,
        "gps_raw_lon_deg": 38.890245,
        "gps_raw_abs_alt": 80.22000122070312,
        "gps_raw_velocity": 0.32999998331069946,
        "position_lat_deg": 47.3066213,
        "position_lon_deg": 38.8902508,
        "position_abs_alt": 82.37200164794922,
        "position_real_alt": 3.626000165939331,
        "heading_deg": 73.21000000000001,
        "odometry_pos_body_x": 17.25543212890625,
        "odometry_pos_body_y": 16.568286895751953,
        "odometry_pos_body_z": -3.0195722579956055,
        "odometry_vel_body_x": -0.30219030380249023,
        "odometry_vel_body_y": 0.11473362147808076,
        "odometry_vel_body_z": 0.0309616532176733,
        "odometry_ang_vel_body_roll": -0.11924925446510316,
        "odometry_ang_vel_body_pitch": -0.107370525598526,
        "odometry_ang_vel_body_yaw": 0.06337708979845047,
        "velocity_north_m_s": -0.12999999523162842,
        "velocity_east_m_s": -0.17999999225139618,
        "velocity_down_m_s": 0.07000000029802322,
        "acceleration_forward": -0.9840877056121826,
        "acceleration_right": 2.383828639984131,
        "acceleration_down": -16.438676834106445,
        "battery_id": 0,
        "battery_voltage": 12.894001007080078,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 0,
        "status_type": "None",
        "status_text": "None",
        "tcp": 26,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 8526,
        "udp_total": 177,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 143
      },
      {
        "id": 346,
        "datetime": "21/02/2023 02:05:15",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477d1d8>, <mavsdk.mission_raw.MissionItem object at 0xa477d418>, <mavsdk.mission_raw.MissionItem object at 0xa4777c40>, <mavsdk.mission_raw.MissionItem object at 0xa4778088>]",
        "mission_pause": 0,
        "time_usec": 161114033,
        "odometry_pos_body": "PositionBody: [x_m: 17.223957061767578, y_m: 16.51494598388672, z_m: -3.004110097885132]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065017,
        "alg_manual_gps_lon_start": 38.8900446,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "rb",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.0]",
        "timestamp": 159655311,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5899999737739563,
        "current_climb_rate": -0.02114974707365036,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066193,
        "gps_raw_lon_deg": 38.8902441,
        "gps_raw_abs_alt": 80.23100280761719,
        "gps_raw_velocity": 0.3799999952316284,
        "position_lat_deg": 47.3066211,
        "position_lon_deg": 38.8902499,
        "position_abs_alt": 82.3070068359375,
        "position_real_alt": 3.561000108718872,
        "heading_deg": 74.06,
        "odometry_pos_body_x": 17.213382720947266,
        "odometry_pos_body_y": 16.49417495727539,
        "odometry_pos_body_z": -2.9992763996124268,
        "odometry_vel_body_x": -0.2946988344192505,
        "odometry_vel_body_y": 0.12474296241998672,
        "odometry_vel_body_z": 0.03290323168039322,
        "odometry_ang_vel_body_roll": 0.06912113726139069,
        "odometry_ang_vel_body_pitch": -0.20007601380348208,
        "odometry_ang_vel_body_yaw": 0.05353431776165962,
        "velocity_north_m_s": -0.14999999105930328,
        "velocity_east_m_s": -0.1899999976158142,
        "velocity_down_m_s": 0.04999999701976776,
        "acceleration_forward": 0.16544485092163086,
        "acceleration_right": 0.2256276309490204,
        "acceleration_down": -3.748278379440307,
        "battery_id": 0,
        "battery_voltage": 12.861000061035156,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 0,
        "status_type": "None",
        "status_text": "None",
        "tcp": 33,
        "udp": 7,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 8536,
        "udp_total": 184,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 144
      },
      {
        "id": 347,
        "datetime": "21/02/2023 02:05:16",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4781a18>, <mavsdk.mission_raw.MissionItem object at 0xa4781370>, <mavsdk.mission_raw.MissionItem object at 0xa4781490>, <mavsdk.mission_raw.MissionItem object at 0xa4781958>]",
        "mission_pause": 0,
        "time_usec": 161343830,
        "odometry_pos_body": "PositionBody: [x_m: 17.18004035949707, y_m: 16.43177032470703, z_m: -2.982218027114868]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065017,
        "alg_manual_gps_lon_start": 38.8900446,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "rb",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.0]",
        "timestamp": 159975030,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5899999737739563,
        "current_climb_rate": -0.014854026027023792,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306618799999995,
        "gps_raw_lon_deg": 38.8902427,
        "gps_raw_abs_alt": 80.23400115966797,
        "gps_raw_velocity": 0.29999998211860657,
        "position_lat_deg": 47.3066207,
        "position_lon_deg": 38.890248799999995,
        "position_abs_alt": 82.26300048828125,
        "position_real_alt": 3.515000104904175,
        "heading_deg": 74.8,
        "odometry_pos_body_x": 17.15995979309082,
        "odometry_pos_body_y": 16.393295288085938,
        "odometry_pos_body_z": -2.9721455574035645,
        "odometry_vel_body_x": -0.24822761118412015,
        "odometry_vel_body_y": 0.082586370408535,
        "odometry_vel_body_z": 0.02302793599665165,
        "odometry_ang_vel_body_roll": -0.3223358690738678,
        "odometry_ang_vel_body_pitch": 0.02079077810049057,
        "odometry_ang_vel_body_yaw": 0.057441096752882,
        "velocity_north_m_s": -0.14999999105930328,
        "velocity_east_m_s": -0.20999999344348907,
        "velocity_down_m_s": 0,
        "acceleration_forward": 0.6652559638023376,
        "acceleration_right": 1.0059287548065186,
        "acceleration_down": -6.479954719543457,
        "battery_id": 0,
        "battery_voltage": 12.861000061035156,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 0,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 8552,
        "udp_total": 184,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 144
      },
      {
        "id": 348,
        "datetime": "21/02/2023 02:05:16",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477d568>, <mavsdk.mission_raw.MissionItem object at 0xa477da30>, <mavsdk.mission_raw.MissionItem object at 0xa4782f40>, <mavsdk.mission_raw.MissionItem object at 0xa4782e98>]",
        "mission_pause": 0,
        "time_usec": 161673539,
        "odometry_pos_body": "PositionBody: [x_m: 17.136308670043945, y_m: 16.341833114624023, z_m: -2.9648940563201904]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065017,
        "alg_manual_gps_lon_start": 38.8900446,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "rb",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.0]",
        "timestamp": 160394663,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5899999737739563,
        "current_climb_rate": 0.018281634896993637,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066186,
        "gps_raw_lon_deg": 38.8902419,
        "gps_raw_abs_alt": 80.24400329589844,
        "gps_raw_velocity": 0.28999999165534973,
        "position_lat_deg": 47.3066202,
        "position_lon_deg": 38.8902473,
        "position_abs_alt": 82.2280044555664,
        "position_real_alt": 3.4820001125335693,
        "heading_deg": 76.15,
        "odometry_pos_body_x": 17.12318229675293,
        "odometry_pos_body_y": 16.308460235595703,
        "odometry_pos_body_z": -2.9590706825256348,
        "odometry_vel_body_x": -0.2072570025920868,
        "odometry_vel_body_y": 0.06763336062431335,
        "odometry_vel_body_z": -0.004946679808199406,
        "odometry_ang_vel_body_roll": -0.7336717844009399,
        "odometry_ang_vel_body_pitch": 0.4964384436607361,
        "odometry_ang_vel_body_yaw": 0.006146612111479044,
        "velocity_north_m_s": -0.17999999225139618,
        "velocity_east_m_s": -0.25999999046325684,
        "velocity_down_m_s": 0.029999999329447743,
        "acceleration_forward": 1.3821697235107422,
        "acceleration_right": 3.4247639179229736,
        "acceleration_down": -12.299135208129885,
        "battery_id": 0,
        "battery_voltage": 12.861000061035156,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 0,
        "status_type": "None",
        "status_text": "None",
        "tcp": 2,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 8575,
        "udp_total": 184,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 144
      },
      {
        "id": 349,
        "datetime": "21/02/2023 02:05:16",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa477da60>, <mavsdk.mission_raw.MissionItem object at 0xa477da18>, <mavsdk.mission_raw.MissionItem object at 0xa477db80>, <mavsdk.mission_raw.MissionItem object at 0xa477d778>]",
        "mission_pause": 0,
        "time_usec": 162043214,
        "odometry_pos_body": "PositionBody: [x_m: 17.105541229248047, y_m: 16.26352310180664, z_m: -2.9542312622070312]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065017,
        "alg_manual_gps_lon_start": 38.8900446,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "rb",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.0]",
        "timestamp": 160774328,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5899999737739563,
        "current_climb_rate": 0.03258730098605156,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.3066182,
        "gps_raw_lon_deg": 38.8902407,
        "gps_raw_abs_alt": 80.29200744628906,
        "gps_raw_velocity": 0.25,
        "position_lat_deg": 47.3066198,
        "position_lon_deg": 38.8902459,
        "position_abs_alt": 82.21100616455078,
        "position_real_alt": 3.46500015258789,
        "heading_deg": 77.64,
        "odometry_pos_body_x": 17.09973907470703,
        "odometry_pos_body_y": 16.248966217041016,
        "odometry_pos_body_z": -2.955397844314575,
        "odometry_vel_body_x": -0.1846980452537537,
        "odometry_vel_body_y": 0.042880941182374954,
        "odometry_vel_body_z": -0.008929609321057796,
        "odometry_ang_vel_body_roll": 0.11166888475418092,
        "odometry_ang_vel_body_pitch": 0.1819496303796768,
        "odometry_ang_vel_body_yaw": 0.06053980067372322,
        "velocity_north_m_s": -0.17000000178813934,
        "velocity_east_m_s": -0.25999999046325684,
        "velocity_down_m_s": 0.03999999910593033,
        "acceleration_forward": -0.6549347639083862,
        "acceleration_right": 0.6280661821365356,
        "acceleration_down": -12.67309284210205,
        "battery_id": 0,
        "battery_voltage": 12.861000061035156,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 0,
        "status_type": "None",
        "status_text": "None",
        "tcp": 24,
        "udp": 1,
        "arp": 0,
        "icmp": 0,
        "other": 1,
        "tcp_total": 8595,
        "udp_total": 185,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 145
      },
      {
        "id": 350,
        "datetime": "21/02/2023 02:05:17",
        "mission_items": "[<mavsdk.mission_raw.MissionItem object at 0xa4777ec8>, <mavsdk.mission_raw.MissionItem object at 0xa4775928>, <mavsdk.mission_raw.MissionItem object at 0xa47815f8>, <mavsdk.mission_raw.MissionItem object at 0xa4781ec8>]",
        "mission_pause": 0,
        "time_usec": 162283005,
        "odometry_pos_body": "PositionBody: [x_m: 17.0873966217041, y_m: 16.209882736206055, z_m: -2.964877128601074]",
        "Command": "None",
        "navigation_system": "Inertial",
        "alg_manual_x": 32.69832679711294,
        "alg_manual_y": -0.7913430790126537,
        "last_angle_position": 261.5,
        "alg_manual_control_start": 0,
        "alg_manual_current_speed": 8,
        "alg_manual_gps_lat_start": 47.3065017,
        "alg_manual_gps_lon_start": 38.8900446,
        "alg_manual_gps_lat": 47.306461899999995,
        "alg_manual_gps_lon": 38.8900159,
        "alg_manual_gps_lat_next": 47.3065017,
        "alg_manual_gps_lon_next": 38.8900446,
        "alg_manual_x_start": 17.33989906311035,
        "alg_manual_y_start": -13.530622482299805,
        "alg_manual_x_now": -9.81911849975586,
        "alg_manual_y_now": 17.733869552612305,
        "alg_manual_azimut": 43,
        "alg_manual_zero_x": 2.1918557339453852,
        "alg_manual_zero_y": 4.493970231495835,
        "alg_manual_dist_to_point": 5,
        "alg_manual_angle_to_point": 26,
        "alg_manual_dist_to_point_old": 21,
        "alg_manual_angle_to_point_old": 133,
        "alg_manual_command": "rb",
        "alg_manual_inputs": "[0.0, 0.0, 0.5, 0.0]",
        "timestamp": 161139008,
        "flight_mode": "POSCTL",
        "landed_state": "IN_AIR",
        "in_air": 1,
        "status_accelerometer": 1,
        "status_magnetometer": 1,
        "status_gyrometer": 1,
        "status_local_position": 1,
        "status_global_position": 1,
        "status_home_position": 1,
        "status_armable": 1,
        "current_air_speed": 0,
        "current_throttle_per": 0.5899999737739563,
        "current_climb_rate": 0.03297482430934906,
        "gps_num_sat": 19,
        "gps_fix_type": "FIX_3D",
        "gps_noise": 1,
        "gps_jamming": 1,
        "gps_vdop": 0.8199999928474426,
        "gps_hdop": 0.6200000047683716,
        "gps_raw_lat_deg": 47.306618099999994,
        "gps_raw_lon_deg": 38.8902402,
        "gps_raw_abs_alt": 80.31000518798828,
        "gps_raw_velocity": 0.1899999976158142,
        "position_lat_deg": 47.3066192,
        "position_lon_deg": 38.8902444,
        "position_abs_alt": 82.1820068359375,
        "position_real_alt": 3.43500018119812,
        "heading_deg": 78.98,
        "odometry_pos_body_x": 17.082033157348633,
        "odometry_pos_body_y": 16.181140899658203,
        "odometry_pos_body_z": -2.9707560539245605,
        "odometry_vel_body_x": -0.18792960047721863,
        "odometry_vel_body_y": 0.020774608477950096,
        "odometry_vel_body_z": -0.03191675245761871,
        "odometry_ang_vel_body_roll": 0.761657178401947,
        "odometry_ang_vel_body_pitch": 0.16655844449996948,
        "odometry_ang_vel_body_yaw": 0.09475186467170715,
        "velocity_north_m_s": -0.12999999523162842,
        "velocity_east_m_s": -0.2299999892711639,
        "velocity_down_m_s": 0.009999999776482582,
        "acceleration_forward": -0.06168774887919426,
        "acceleration_right": -3.231102705001831,
        "acceleration_down": -13.853344917297363,
        "battery_id": 0,
        "battery_voltage": 12.861000061035156,
        "battery_percent": 100,
        "mission_next_point": 0,
        "mission_current_point": 1,
        "mission_total_point": 4,
        "mission_file_name": "15_15_25.plan",
        "mission_start": 0,
        "status_type": "None",
        "status_text": "None",
        "tcp": 1,
        "udp": 0,
        "arp": 0,
        "icmp": 0,
        "other": 0,
        "tcp_total": 8619,
        "udp_total": 185,
        "arp_total": 8,
        "icmp_total": 1,
        "other_total": 145
      }
    ],
    "route_elements": [
      {
        "id": 108,
        "element_id": 1,
        "mission_id": 19,
        "data": "{\"id\": 1, \"alt\": \"3\", \"speed\": \"1\"}"
      },
      {
        "id": 109,
        "element_id": 2,
        "mission_id": 19,
        "data": "{\"id\": 2, \"alt\": \"3\", \"yaw\": \"\", \"lng\": 38.89006877200774, \"lat\": 47.306500559044075}"
      },
      {
        "id": 110,
        "element_id": 3,
        "mission_id": 19,
        "data": "{\"id\": 3, \"alt\": \"3\", \"yaw\": \"\", \"hold\": \"0\", \"speed\": \"1\", \"lng\": 38.89004225887959, \"lat\": 47.306636184317995}"
      },
      {
        "id": 111,
        "element_id": 3,
        "mission_id": 19,
        "data": "{\"id\": 3, \"alt\": \"3\", \"yaw\": \"\", \"hold\": \"0\", \"speed\": \"1\", \"lng\": 38.88984049359513, \"lat\": 47.30663222939524}"
      },
      {
        "id": 112,
        "element_id": 3,
        "mission_id": 19,
        "data": "{\"id\": 3, \"alt\": \"3\", \"yaw\": \"\", \"hold\": \"0\", \"speed\": \"1\", \"lng\": 38.89004459, \"lat\": 47.30650174}"
      }
    ]
  },
  "status": 200,
  "statusText": "OK",
  "headers": {
    "accept-ranges": "bytes",
    "allow": "OPTIONS, POST",
    "content-length": "35715314",
    "content-type": "application/json",
    "referrer-policy": "same-origin",
    "vary": "Accept, Cookie",
    "x-content-type-options": "nosniff",
    "x-frame-options": "DENY"
  },
  "config": {
    "transitional": {
      "silentJSONParsing": true,
      "forcedJSONParsing": true,
      "clarifyTimeoutError": false
    },
    "adapter": [
      "xhr",
      "http"
    ],
    "transformRequest": [
      null
    ],
    "transformResponse": [
      null
    ],
    "timeout": 0,
    "xsrfCookieName": "XSRF-TOKEN",
    "xsrfHeaderName": "X-XSRF-TOKEN",
    "maxContentLength": -1,
    "maxBodyLength": -1,
    "env": {},
    "headers": {
      "Accept": "application/json, text/plain, */*"
    },
    "method": "post",
    "url": "/api/log_read/",
    "data": {}
  },
  "request": {}
}