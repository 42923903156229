import React from "react";
import './LogPanel.css';
import { update_modal_message } from "../../AppSlice";
import { connect } from 'react-redux';

class LogPanel extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            active: "active",
            logNumber: 0,
            isPause: true,
            speedValue: 1,
            logValue: 0
        };
        this.switchLogsPanelActive = this.switchLogsPanelActive.bind(this);
        this.getReasonsState = this.getReasonsState.bind(this)
        this.getFlightControllerReasons = this.getFlightControllerReasons.bind(this);
        this.attacks = {
            0 : "Отсутствует",
            1 : "Аномалия gps",
            2 : "Опасная территория",
            3 : "Погодная аномалия",
            4 : "Критическая аномалия",
            5 : "Атака tcp flood",
            6 : "Атака udp flood",
            7 : "Подмена gps",
            8 : "Глушение gps"
        };
        this.states = {
            0:"Нестабильное",
            1:"Стабильное",
            2:"Неопределенное",
        }
        this.metric1_action = {
            0:"Выход из допустимых границ",
            1:"",
        }
        this.metric2_action = {
            0:"Некорректный рост",
            1:"",
        }
        this.colors = {
            0:"#d10303",
            1:"#3a9d3a"
        }
    }
    switchLogsPanelActive(e){
        e.preventDefault();
        if (this.state.active == "inactive" || this.state.active == ""){
            this.setState(state => ({
                active: "active"
            }));
        }else{
            this.setState(state => ({
                active: "inactive"
            }));
        };
    }
    getReasonsState(key){
        let reason = "Причин"
        if (this.props.data.state){
            if(this.props.data.state.parameters_state[key].m1 == 0 && this.props.data.state.parameters_state[key].m2 == 0){
                reason += "ы: " + this.metric1_action[this.props.data.state.parameters_state[key].m1] + ", " + this.metric2_action[this.props.data.state.parameters_state[key].m2]
            }
            else if(this.props.data.state.parameters_state[key].m1 == 0){
                reason += "а: " + this.metric1_action[this.props.data.state.parameters_state[key].m1]
            }
            else if(this.props.data.state.parameters_state[key].m2 == 0){
                reason += "а: " + this.metric2_action[this.props.data.state.parameters_state[key].m2]
            }
            if(this.props.data.state.parameters_state[key].m1 != 0 && this.props.data.state.parameters_state[key].m2 != 0) return ""
            else return reason
        }else{
            return ""
        }
    }
    getFlightControllerReasons(){
        let reason = "Причин"
        if (this.props.data.state){
            if(this.props.data.state.is_verticle == 0 && this.props.data.state.is_edge == 0){
                reason += "ы: неожиданный режим и переход нелегитимен"
            }
            else if(this.props.data.state.is_verticle == 0){
                reason += "а: неожиданный режим"
            }
            else if(this.props.data.state.is_edge == 0){
                reason += "а: переход нелегитимен"
            }
            if(this.props.data.state.m0 != 0) return ""
            else return reason
        }else{
            return ""
        }
    }
    checkData(data){
        if (data){
            return data
        }else{
            return "--"
        }
    }
    getBatteryView(str){
        let percents = parseInt(str)
        return Math.floor(percents/10)
    }
    validateLog(data){
        let message = ""
        let result = true
        if (data.status_text.substring(0, 8) == "[logger]"){
            result = false
            message += "Поле 'Статус' отсутствует в данном логе. "
        }
        if (!data.state){
            result = false
            message += "Поля состояния БПЛА отсутсвуют в данном логе. "
        }
        if (!(data.pitch_deg && data.roll_deg && data.heading_deg)){
            result = false
            message += "Поля углов БПЛА отсутсвуют в данном логе."
        }
        return {
            result: result,
            message: message
        }
    }
    // componentDidMount(){}
    render(){
        let destructView = (this.props.data.status_code_attack==0)?"OK":"Danger"
        let stateView = this.props.data.precalculation_accept?(this.props.precalculation_accept == true ? (this.props.data.state.state.state_label ? "OK":"Danger") : "OK"):"Danger"
        let date = this.props.data.datetime.slice(0, 10)
        let data = this.props.data
        return(
            <div>
                <section className={"section--logs "+this.state.active}>
                    <button onClick={this.switchLogsPanelActive} className={"button--logs "+this.state.active}></button>
                    <div className="logs--blocks">
                        <h1 className="main--title">Информация о логе "{this.props.log_name.substring(0, 30)+"..."}" {date}</h1>

                        <ul className="logs--list">
                            <li className="logs--list--item">
                                <h4 className="item--title--main">Статус</h4>
                                <p className="item--description">{
                                    data.status_text.substring(0, 8)=="[logger]"?"Неопределенный":data.status_text
                                }</p>
                            </li>
                            <li className="logs--list--item">
                                <h4 className="item--title--main">Заряд аккумулятора</h4>
                                <div className="battery">
                                    <div className={"battery--color "+"batteryView"+this.getBatteryView(data.battery_percent)} style={{width: data.battery_percent+"%"}} ></div>
                                    <p className={"item--description battery--percent"}>{data.battery_percent}%</p>
                                </div>
                            </li>
                            <li className="logs--list--item">
                                <h4 className="item--title--main">Деструктивное влияние</h4>
                                <p className={"item--description "+"destructView"+destructView}>{
                                    data.status_code_attack?this.attacks[data.status_code_attack]:"Неопределенное"
                                }</p>
                            </li>
                            <li className="logs--list--item">
                                <h4 className="item--title--main">Общее состояние</h4>
                                <p className={"item--description "+"destructView"+stateView}>{
                                    data.state?this.states[data.state.state.state_label]:this.states["2"]
                                }</p>
                            </li>
                            <li className="logs--list--item">
                                <h4 className="item--title--main">Навигационная система</h4>
                                <p className="item--description">{data.navigation_system}</p>
                            </li>
                            <li className="logs--list--item">
                                <h4 className="item--title--main">Режим полета</h4>
                                <p className="item--description">{data.flight_mode}</p>
                                <p className="item--description">Состояние: {
                                    data.state?this.states[data.state.m0]:this.states["2"]
                                }</p>
                                <p className="item--description">{this.getFlightControllerReasons()}</p>
                            </li>
                            <li className="logs--list--item">
                                <h4 className="item--title--main">Координаты</h4>
                                <p className="item--description coords">Координата X: {data.position_lon_deg}°</p>
                                <p className="item--description coords">Координата Y: {data.position_lat_deg}°</p>
                                <p className="item--description coords">Состояние: {
                                    data.state?this.states[data.state.parameters_state.cords.state_label]:this.states["2"]
                                }</p>
                                <p className="item--description coords">{this.getReasonsState("cords")}</p>
                            </li>
                            <li className="logs--list--item">
                                <h4 className="item--title--main">Высота</h4>
                                <p className="item--description">{Math.round(data.position_real_alt*1000)/1000} м</p>
                                <p className="item--description">Состояние: {
                                    data.state?this.states[data.state.parameters_state.altitude.state_label]:this.states["2"]
                                }</p>
                                <p className="item--description">{this.getReasonsState("altitude")}</p>
                            </li>
                            <li className="logs--list--item">
                                <h4 className="item--title--main">Углы наклона</h4>
                                <p className="item--description coords">Тангаж: {data.odometry_ang_vel_body_pitch?data.odometry_ang_vel_body_pitch:"--"}°</p>
                                <p className="item--description coords">Рыскание: {data.odometry_ang_vel_body_yaw?data.odometry_ang_vel_body_yaw:"--"}°</p>
                                <p className="item--description coords">Крен: {data.odometry_ang_vel_body_roll?data.odometry_ang_vel_body_roll:"--"}°</p>
                            </li>
                            <li className="logs--list--item">
                                <h4 className="item--title--main">Скорость</h4>
                                <p className="item--description">{data.gps_raw_velocity} м/с</p>
                                <p className="item--description">Состояние: {
                                    data.state?this.states[data.state.parameters_state.speed.state_label]:this.states["2"]
                                }</p>
                                <p className="item--description">{this.getReasonsState("speed")}</p>
                            </li>
                            <li className="logs--list--item">
                                <h4 className="item--title--main">Кол-во спутников навигации</h4>
                                <p className="item--description">{data.gps_num_sat}м/с</p>
                                <p className="item--description">Состояние: {
                                    data.state?this.states[data.state.parameters_state.satelites.state_label]:this.states["2"]
                                }</p>
                                <p className="item--description">{this.getReasonsState("satelites")}</p>
                            </li>
                            <li className="logs--list--item">
                                <h4 className="item--title--main">Количество пакетов</h4>
                                <p className="item--description">По TCP: {data.tcp_total}</p>
                                <p className="item--description">По UDP: {data.udp_total}</p>
                                <p className="item--description">По ARP: {data.arp_total}</p>
                                <p className="item--description">По ICMP: {data.icmp_total}</p>
                            </li>
                        </ul>
                    </div>
                </section>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return state;
}
const mapDispatchToProps =  (dispatch) => {
    return {
        "update_modal_message": (data) => dispatch(update_modal_message(data))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(LogPanel)
