import React from 'react';
import './RouteConstructor.css';
import { connect } from 'react-redux';
import { set_info_mission, update_modal_message, change_RC_mission_id, change_RC_mission_name, change_active_RC_button, toggle_page_block } from '../AppSlice';

import InstrumentsPanel from './InstrumentsPanel/InstrumentsPanel';
import RoutePanel from './RoutePanel/RoutePanel';
import AltitudeGraph from './AltitudeGraph/AltitudeGraph';
import { FlyRoute } from './FlyRoute';
import MissionStart_info from './BRM/MissionStart/Parameters';
import Takeoff_info from './BRM/Takeoff/Parameters';
import Waypoint_info from './BRM/Waypoint/Parameters';
import Polygon_info from './BRM/Polygon/Parameters';
import Land_info from './BRM/Land/Parameters';
import RTL_info from './BRM/RTL/Parameters';
import MissionStartRE from './BRM/MissionStart/RouteElement';
import TakeoffME from './BRM/Takeoff/MapElement';
import TakeoffRE from './BRM/Takeoff/RouteElement';
import WaypointME from './BRM/Waypoint/MapElement';
import WaypointRE from './BRM/Waypoint/RouteElement';
import RTLRE from './BRM/RTL/RouteElement';
import LandME from './BRM/Land/MapElement';
import LandRE from './BRM/Land/RouteElement';
import PolygonME from './BRM/Polygon/MapElement';
import PolygonRE from './BRM/Polygon/RouteElement';
import { RCManager3D } from './RCManager3d';

const R = 6378.137;

class RouteConstructor extends React.Component  {
  constructor(props){
    super(props);
    this.state = {
      routeSequence:[],
      isDataLoaded:false,
      AMS_alt_route: [],
      all_altitude:[]
    }
    this.route = null;
    this.setRouteData = this.setRouteData.bind(this);
    this.setRouteSequence = this.setRouteSequence.bind(this);
    this.updateModal = this.updateModal.bind(this);
    this.keywordPressed = this.keywordPressed.bind(this);
    this.createComponent = this.createComponent.bind(this)
    this.RCManager3D = null;
  }
  updateModal(data){
    this.props.update_modal_message(data)
  }
  setRouteData(data){
    this.props.set_info_mission(data)
  }
  setRouteSequence(routeSequence){
    if (routeSequence.route_cords.length == 1){
      this.props.toggle_page_block({
        'is_page_blocked': false,
        'name': '', 
        'description': ''
      })
    } else if (routeSequence.route_cords.length == 2){
      this.props.toggle_page_block({
          'is_page_blocked': true,
          'name': 'Создано полетное задание', 
          'description': 'Полетное задание не сохранено'
      })
    }
    this.setState(state => ({
      routeSequence: routeSequence.route_sequence
    }))
    this.props.set_info_mission(routeSequence.route_info)
    
    this.setState(state => ({
      all_altitude: routeSequence.route_info.all_altitude,
      AMS_alt_route: routeSequence.route_info.all_altitude_AMS
    }))
  }

  tile2lng(x, z){
    return (x/Math.pow(2,z)*360-180);
  }
  tile2lat(y, z){
    let n = Math.PI-2*Math.PI*y/Math.pow(2,z);
    return (180/Math.PI*Math.atan(0.5*(Math.exp(n)-Math.exp(-n))));
  }

  showTiles(){
    let tiles = this.props.map.style._otherSourceCaches.composite._tiles;
    let tilesCords = [];
    let geoCords = [];
    for (let key in tiles){
      tilesCords.push({
        x: tiles[key].tileID.canonical.x,
        y: tiles[key].tileID.canonical.y, 
        z: tiles[key].tileID.canonical.z
      })
      geoCords.push({
        lng: this.tile2lng(tiles[key].tileID.canonical.x, tiles[key].tileID.canonical.z),
        lat: this.tile2lat(tiles[key].tileID.canonical.y, tiles[key].tileID.canonical.z)
      })
    }
  } 

  keywordPressed(e){
    if ((e.key == '.' || e.key == 'ю') && this.route.head.next){
      if (this.route.current_node.next){
        this.route.setCurrentNode(this.route.current_node.next)
        this.RCManager3D.setCurrentSphere(this.route.current_node.id)
      }else{
        this.route.setCurrentNode(this.route.head)
        this.RCManager3D.setCurrentSphere(this.route.head.id)
      }
    } else if ((e.key == ',' || e.key == 'б') && this.route.head.next){
      if (this.route.current_node.prev){
        this.route.setCurrentNode(this.route.current_node.prev)
        this.RCManager3D.setCurrentSphere(this.route.current_node.id)
      } else {
        this.route.setCurrentNode(this.route.tail)
        this.RCManager3D.setCurrentSphere(this.route.tail.id)
      }
    }
  }

  createComponent(){
    this.route = new FlyRoute( 
      this.props.map,
      this.setRouteData,
      this.setRouteSequence,
      this.updateModal,
      this.RCManager3D
    )
    this.route.createDefaultDicts(
      this.props.app.route_elements,
      this.props.app.route_parameters_default,
      [MissionStart_info, Takeoff_info, Waypoint_info,
        Polygon_info, Land_info, RTL_info]
    )
    this.route._append(
      "MissionStart",//module_name, 
      null,//map_element_init, 
      MissionStartRE,//route_element_init, 
      {},//extra_params, 
      false,//isMapElement, 
      {}//evt
    )

    this.setState(state => ({
      isDataLoaded: true
    }))

    document.addEventListener('keydown', this.keywordPressed);
  }
  componentDidMount(){
    this.props.map.resize()
    this.RCManager3D = new RCManager3D(this.props.manager3D)
    this.props.manager3D.addManager(this.RCManager3D, "RC")
    this.createComponent()
  }

  componentWillUnmount(){
    let currentNode = this.route.tail;
    while (currentNode){
      if (currentNode != this.route.head){
        this.route.deleteNode(currentNode)
      }
      currentNode = currentNode.prev
    }
    this.props.change_RC_mission_id(null);
    this.props.change_RC_mission_name("");
    this.props.change_active_RC_button(null);
    document.removeEventListener('keydown', this.keywordPressed)
    this.RCManager3D.clearTb()
  }
  
  render(){
    return (
      <div className="">
        {this.state.isDataLoaded === true ? <InstrumentsPanel route={this.route} tbManager={this.RCManager3D}/> : ""}
        <AltitudeGraph altAMSArr={this.state.AMS_alt_route} all_altitude={this.state.all_altitude}/>
        <RoutePanel sequence={this.state.routeSequence} map ={this.props.map} route={this.route} tbManager={this.RCManager3D}/>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return state;
}
const mapDispatchToProps =  (dispatch) => {
  return {
    'set_info_mission': (data) => dispatch(set_info_mission(data)),
    'update_modal_message': (data) => dispatch(update_modal_message(data)),
    'change_RC_mission_id': (data) => dispatch(change_RC_mission_id(data)),
    'change_RC_mission_name': (data) => dispatch(change_RC_mission_name(data)),
    'change_active_RC_button': (data) => dispatch(change_active_RC_button(data)),
    'toggle_page_block': (data) => dispatch(toggle_page_block(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RouteConstructor)


