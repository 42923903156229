import React from 'react';
import './MenuButton.css';
import Modal from '../../Modal/Modal';
import { connect } from 'react-redux';
import { change_page, toggle_menu } from '../../AppSlice';
import RCGif from "./img/RC.gif";
import LRGif from "./img/LR.gif"

class MenuButton extends React.Component  {
  constructor(props){
    super(props);
    this.state = {
      clicked:false
    }
    this.handlerClick = this.handlerClick.bind(this);
  }
  handlerClick(e){
    e.preventDefault()
    this.setState(state => ({
      clicked: true
    }));
    if(!this.props.app.is_page_blocked){
      this.setState(state => ({
        clicked: false
      }));
      this.props.change_page(this.props.id)
      this.props.toggle_menu(false)
    }
  }
  render(){
    return (
      <div className='menu--button'>
        {this.props.app.is_page_blocked === true && this.state.clicked === true ? <Modal/> : ""}
        <a className={this.props.id === this.props.app.active_page_id ? "menu__item--active" : ""} onClick={this.handlerClick}>
            {this.props.name}
        </a>
        <div className='hint'>
          <div className='hint--title'>
            <span>{this.props.hint}</span>
          </div>
          {this.props.id==0?
            <img src={RCGif}/>:this.props.id==3?
            <img src={LRGif}/>:""
          }
          <span>{this.props.id==0?"Обучающий ролик для модуля создания полетных заданий находится в разделе 'О приложении'":
            this.props.id==1?"Обучающий ролик для модуля мониторинга находится в разделе 'О приложении'":
            this.props.id==3?"Обучающий ролик для модуля просмотра логов находится в разделе 'О приложении'":""
          }</span>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return state;
}
const mapDispatchToProps =  (dispatch) => {
  return {
    'change_page': (id) => dispatch(change_page(id)),
    'toggle_menu': (data) => dispatch(toggle_menu(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuButton)

