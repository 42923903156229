import { Threebox } from "threebox-plugin";
import { lineChunk } from "@turf/turf"
export class manager3D{
    constructor(map){
        this.map = map
        this.firstMapScale = this.map.transform.scale
        this.available_meshes = {
            drone:{
                obj: window.location.origin+'/drone/drone.glb',
                type: 'gltf',
                scale: 1,
                rotation: { x: 90, y: 0, z: 0 },
                anchor: 'center',
                name: 'drone',
                clone: false
            },
            sphere_1:{
                radius: 0.5,
                units:"meters",
                color: '#ff9900',
                adjustment: {x: 0.5, y: 0.5, z: -0.5}
            },
            line_1:{
                width: 5,
                color: '#daa520'
            },
            line_help:{
                width: 5,
                color: "#00ff00"
            },
            tube_1:{
                width: 20,
                color: '#800080',
                opacity: 0.4
            },
            line_2:{
                width: 5,
                color: '#d10303',
            },
            line_3:{
                width: 2,
                color: '#bababa',
            },
            line_4:{
                width: 5,
                color: '#daa520'
            },
            tube_2:{
                width: 20,
                color: '#800080',
                opacity: 0.4
            }
        }

        this.tb = new Threebox(this.map,
            this.map.getCanvas().getContext('webgl'),
            {
                defaultLights: true
            }    
        );
        window.tb = this.tb

        this.map.addLayer({
            id:"3d-threebox-layer",
            type:"custom",
            renderingMode:"3d",
            onAdd: (map, mbxContext) => {
                
            },
            render: () => {
                this.tb.update();
            }
        })      
        this.managers = {}  
        this.getMeshOptions = this.getMeshOptions.bind(this);
        this.getAMS = this.getAMS.bind(this);
        this.getAMSline = this.getAMSline.bind(this);
        this.changeScale = this.changeScale.bind(this);

        this.map.on('zoom', this.changeScale);
        this.addManager = this.addManager.bind(this);
        this.changeMode = this.changeMode.bind(this);
    }
    getMeshOptions(key){
        if(key in this.available_meshes) return this.available_meshes[key]
        else return null
    }
    getAMS(cords){
        return this.map.queryTerrainElevation(cords)
    }
    getAMSline(traectory){
        let cords = [...lineChunk({
                type: 'Feature',
                geometry: {
                    type: 'LineString',
                    coordinates: traectory.map((c) => {
                        return [c[0], c[1]]
                    })
                },
                properties: {}
            }, 0.005).features.map((feature) => {
            return [
                ...feature.geometry.coordinates[0],
                this.map.queryTerrainElevation(feature.geometry.coordinates[0])
            ]
            })]
        return cords
    }
    addMesh(key, cords){
        let model = this.available_meshes[key].setCoords(cords);
        this.tb.add(model);
    }
    addManager(manager, key){
        this.managers[key] = manager
    }
    changeMode(mode){
        for(let key in this.managers){
            let manager = this.managers[key]
            manager.changeMode(mode)
            manager.changeScale(this.map.getZoom(), this.firstMapScale, this.map.transform.scale)
        }
        this.map.setZoom(this.map.getZoom())
    }
    changeScale(){
        for(let key in this.managers){
            let manager = this.managers[key]
            manager.changeScale(this.map.getZoom(), this.firstMapScale, this.map.transform.scale)
        }
    }
}