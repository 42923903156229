import React from 'react';
import './Header.css';
import mainLogo from "./imgs/logo.png"
import Menu from '../../Menu/Menu';
import RouteInfoPanel from './RouteInfoPanel/RouteInfoPanel';
import WeatherInfoPanel from './WeatherInfoPanel/WeatherInfoPanel';
import { connect } from 'react-redux';

let components_id = {
  0:WeatherInfoPanel,
  1:RouteInfoPanel
}

let components = {
  0:{
    'component':WeatherInfoPanel,
    parameters:{
      temperature:-10,
      weather:0,
      wind_speed:10,
      wind_direction:0
    }
  },
  1:{
    'component':RouteInfoPanel,
    parameters:{
      time:1000,
      distance:1000,
    }
  }
}
class Header extends React.Component {
  constructor(props){
    super(props);
    this.componentsWithMap = [
      0, 1, 3
    ]
  }
  render() {
    return (
      <div className="header col-md-12">
          <div className="header__panel d-flex">
              <Menu/>
              <img className="mainLogo" src={mainLogo} alt="#"/>
              <section className='header__container--custom'>
                <ul className='header__list--custom'>
                  {this.props.app.active_page_id==0?
                    <li className='header__item--custom'>
                      <RouteInfoPanel/>
                    </li>:""
                  }
                  <li className='header__item--custom'>
                    <WeatherInfoPanel/>
                  </li>
                  <li className='header__item--custom'>
                    <span className='temporary'>Пробная версия ПО</span>
                  </li>
                </ul>
              </section>
          </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
}
const mapDispatchToProps =  (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
